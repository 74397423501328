import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";

import { KnowledgebaseIcon } from "../../../../shared/icons/navbar/KnowledgebaseIcon";
import { SupportIcon } from "../../../../shared/icons/navbar/SupportIcon";
import Intercom from "../../../../shared/services/Intercom.service";
import ContextMenuItem from "../ContextMenuItem/ContextMenuItem";

// eslint-disable-next-line
const HelpMenu = React.forwardRef((props, ref) => {
  const { langId, mobile, onMenuItemClick } = props;

  const getLinkLanguage = (langId) => {
    switch (langId) {
      case LANG_KEYS.CZ:
        return "";
      case LANG_KEYS.RO:
        return "ro";
      default:
        return "en";
    }
  };

  const helpCenterLink = `http://help.cleverfarm.cz/${getLinkLanguage(langId)}`;

  return (
    <div>
      <ContextMenuItem
        component="a"
        href={helpCenterLink}
        icon={<KnowledgebaseIcon />}
        id="help-menu-knowledgebase"
        label={<FormattedMessage id="HelpMenu.knowledgebase" />}
        mobile={mobile}
        target="_blank"
        onClick={() => {
          onMenuItemClick();
        }}
      />
      <ContextMenuItem
        icon={<SupportIcon />}
        id="help-menu-support"
        label={<FormattedMessage id="HelpMenu.support" />}
        mobile={mobile}
        onClick={() => {
          onMenuItemClick();
          Intercom.show();
        }}
      />
    </div>
  );
});

HelpMenu.propTypes = {
  onMenuItemClick: PropTypes.func,
  mobile: PropTypes.bool,
  langId: PropTypes.string,
};

HelpMenu.defaultProps = {
  onMenuItemClick: () => {},
  mobile: false,
  langId: "cs-CZ",
};

export default HelpMenu;
