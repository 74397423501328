import React from "react";

import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  textField: {
    width: "100%",
  },
};

function AutosuggestInput(props) {
  const { classes, refFnc, value, ...other } = props;
  delete other.other;

  return (
    <TextField
      className={classes.textField}
      inputRef={refFnc}
      value={value}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

AutosuggestInput.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string,
  refFnc: PropTypes.func,
  other: PropTypes.object,
};

AutosuggestInput.defaultProps = {
  classes: {},
  value: "",
  refFnc: PropTypes.func,
  other: {},
};

export default withStyles(styles)(AutosuggestInput);
