import get from "lodash/get";

import * as types from "./parcels.constants";

export default (reducerContext) =>
  (
    state = {
      mapActionable: [],
      mapActionableError: {},
      isFetchingMapActionable: false,
    },
    action = {},
  ) => {
    const actionContext = get(action, "meta.context");
    if (actionContext && actionContext !== reducerContext) return state;

    switch (action.type) {
      case types.GET_PARCELS_MAP_ACTIONABLE:
        return {
          ...state,
          isFetchingMapActionable: true,
          mapActionableError: {},
        };
      case types.GET_PARCELS_MAP_ACTIONABLE_SUCCESS:
        return {
          ...state,
          isFetchingMapActionable: false,
          mapActionable: action.payload,
        };
      case types.GET_PARCELS_MAP_ACTIONABLE_FAILURE:
        return {
          ...state,
          isFetchingMapActionable: false,
          mapActionableError: action.payload,
        };
      case types.RESET_PARCELS_MAP_ACTIONABLE:
        return {
          ...state,
          mapActionable: [],
        };

      default:
        return state;
    }
  };
