import React from "react";

import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CfDatePickerTranslator from "../CfDatePickerTranslator/CfDatePickerTranslator";

const style = {
  positionEnd: {
    position: "absolute",
    right: "-42px",
  },
};

function CfFormikDatePicker(props) {
  const {
    children,
    classes,
    field,
    form,
    format,
    intl: { formatMessage, locale },
    ...restProps
  } = props;
  const error = form.errors[field.name];
  return (
    <CfDatePickerTranslator
      cancelLabel={formatMessage({ id: "common.cancel" })}
      clearLabel={formatMessage({ id: "common.clear" })}
      error={Boolean(error)}
      format={format || (locale === "cs-CZ" ? "DD.MM.YYYY" : "MM/DD/YYYY")}
      helperText={error}
      invalidDateMessage=""
      momentLocale={moment().locale()}
      name={field.name}
      okLabel={formatMessage({ id: "common.select" })}
      placeholder={moment().startOf("year").format("L")}
      value={field.value}
      onChange={(inputVal) => {
        form
          .setFieldValue(field.name, inputVal)
          .then(() => form.validateField(field.name));
      }}
      {...restProps}
    >
      {children}
    </CfDatePickerTranslator>
  );
}

CfFormikDatePicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  format: PropTypes.string,
};

CfFormikDatePicker.defaultProps = {
  children: null,
};

export default injectIntl(withStyles(style)(CfFormikDatePicker));
