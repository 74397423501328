import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const ReportsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M16.89,1.23H7.12A2.32,2.32,0,0,0,4.8,3.55v20.9a2.32,2.32,0,0,0,2.32,2.32H22.88a2.32,2.32,0,0,0,2.32-2.32V9.54ZM15.73,10.7V4.06l6.64,6.64Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { ReportsIcon };
