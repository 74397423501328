import React from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { Field, FieldArray } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ActionParcelSubtractableAreasSection from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import SubtractableAreaWithCheckbox from "../SubtractableAreaWithCheckbox/SubtractableAreaWithCheckbox";

const styles = (theme) => ({
  noAreas: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    margin: "0px 0px 10px 10px",
  },
});

function AbsoluteParcelSubtractableAreas(props) {
  const {
    classes,
    isEditing,
    maxValue,
    onParcelSubtractionDelete,
    parcelId,
    parcelIds,
    parcelIndex,
  } = props;
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.absolute`}>
      {({ form, push, remove }) => {
        const handleSubtractionDelete = (type, value) => {
          const index = form.values.parcels[
            parcelIndex
          ].subtractableAreas.absolute.findIndex(
            (area) => area.value === value,
          );
          remove(index);
          onParcelSubtractionDelete(type, value);
        };

        return (
          <ActionParcelSubtractableAreasSection
            isEditing={isEditing}
            maxValue={maxValue}
            parcelId={parcelId}
            parcelIds={parcelIds}
            subtractableAreaType="Absolute"
            handleAdd={(value) => {
              push({ ...value, isUsed: true });
            }}
            subtractableAreas={
              form.values.parcels[parcelIndex].subtractableAreas.absolute
            }
          >
            <Grid container data-test="area-subtraction-list">
              {form.values.parcels[parcelIndex].subtractableAreas.absolute
                .length ? (
                form.values.parcels[parcelIndex].subtractableAreas.absolute.map(
                  (sa, index) => (
                    <Grid
                      data-test="absolute-subtraction-item"
                      item
                      key={`absolute-${sa.value}`}
                      md={3}
                      sm={4}
                      xs={12}
                    >
                      <Field
                        component={SubtractableAreaWithCheckbox}
                        handleSubtractionDelete={handleSubtractionDelete}
                        isEditing={isEditing}
                        name={`parcels.${parcelIndex}.subtractableAreas.absolute[${index}]`}
                        parcelIndex={parcelIndex}
                      />
                    </Grid>
                  ),
                )
              ) : (
                <div className={classes.noAreas}>
                  <FormattedMessage id="SubtractableAreaDialog.noSubtractableAreas" />
                </div>
              )}
            </Grid>
          </ActionParcelSubtractableAreasSection>
        );
      }}
    </FieldArray>
  );
}

AbsoluteParcelSubtractableAreas.propTypes = {
  isEditing: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onParcelSubtractionDelete: PropTypes.func.isRequired,
};

AbsoluteParcelSubtractableAreas.defaultProps = {
  isEditing: false,
};

export default withStyles(styles)(AbsoluteParcelSubtractableAreas);
