import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const AtmosphericPressure = (props) => (
  <SvgIcon {...props}>
    <svg
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.06674 14.1202C8.06674 14.6352 8.48808 15.0565 9.00306 15.0565C9.51803 15.0565 9.93938 14.6352 9.93938 14.1202C9.93938 13.6052 9.51803 13.1839 9.00306 13.1839C8.48808 13.1839 8.06674 13.6052 8.06674 14.1202ZM8.06674 1.01172V4.757H9.93938V2.95927C13.1135 3.41806 15.5573 6.13339 15.5573 9.4386C15.5573 13.0622 12.6266 15.9928 9.00306 15.9928C5.3795 15.9928 2.44881 13.0622 2.44881 9.4386C2.44881 7.86559 3.00124 6.42365 3.9282 5.30007L9.00306 10.3749L10.3233 9.05471L3.95629 2.68773V2.70646C1.90575 4.24202 0.576172 6.67646 0.576172 9.4386C0.576172 14.0921 4.34018 17.8655 9.00306 17.8655C13.6566 17.8655 17.4299 14.0921 17.4299 9.4386C17.4299 4.78509 13.6566 1.01172 9.00306 1.01172H8.06674ZM14.621 9.4386C14.621 8.92363 14.1996 8.50228 13.6847 8.50228C13.1697 8.50228 12.7483 8.92363 12.7483 9.4386C12.7483 9.95358 13.1697 10.3749 13.6847 10.3749C14.1996 10.3749 14.621 9.95358 14.621 9.4386ZM3.38513 9.4386C3.38513 9.95358 3.80648 10.3749 4.32145 10.3749C4.83643 10.3749 5.25777 9.95358 5.25777 9.4386C5.25777 8.92363 4.83643 8.50228 4.32145 8.50228C3.80648 8.50228 3.38513 8.92363 3.38513 9.4386Z" />
    </svg>
  </SvgIcon>
);

export default AtmosphericPressure;
