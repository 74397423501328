/**
 * Created by ondrejzvara on 17.3.16.
 */

/*@ngInject*/
export default function cfRoundedInput(
  $filter,
  Localization,
  $rootScope,
  $timeout,
) {
  return {
    terminal: true,
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl) {
        return;
      }

      var decimal = attrs.cfRoundedInput || 3;

      // Listen for change events to enable binding
      element.bind("blur", function () {
        element.val(model2View(ngModelCtrl.$modelValue));
      });

      //convert data from view format to model format
      ngModelCtrl.$parsers.push(function (data) {
        return view2Model(data);
      });

      //convert data from model format to view format
      ngModelCtrl.$formatters.push(function (data) {
        return model2View(data);
      });

      $rootScope.$on("$translateChangeSuccess", function () {
        $timeout(function () {
          element.val(model2View(ngModelCtrl.$modelValue));
        }, 0);
      });

      function model2View(data) {
        return !_.isNil(data) ? $filter("number")(data, decimal) : null;
      }

      function view2Model(data) {
        return Localization.str2num(data);
      }
    },
  };
}
