import React, { Fragment } from "react";

import UpdateIcon from "@mui/icons-material/Update";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

import CfFormattedRelativeTime from "../../common/CfFormattedRelativeTime/CfFormattedRelativeTime";

const style = {
  tooltip: {
    marginBottom: 4,
  },
  itemContent: {
    display: "flex",
    alignItems: "center",
  },
  updateIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: "3px",
  },
  icon: {
    height: 20,
  },
};

function SensorsLastUpdate(props) {
  const { classes, displayIcon, lastUpdate } = props;

  return (
    <Fragment>
      {lastUpdate === "" ? (
        <span className={classes.itemContent}>
          {displayIcon && <UpdateIcon className={classes.icon} />}
          <span className={classes.spanMessageMargin}>
            <FormattedMessage id="SensorsLastUpdate.noUpdate" />
          </span>
        </span>
      ) : (
        <Tooltip
          placement="bottom"
          classes={{
            tooltip: classes.tooltip,
          }}
          title={
            <span>
              <div>
                <FormattedMessage id="SensorsLastUpdate.lastUpdate" />:
              </div>
              <div>
                <FormattedDate
                  day="numeric"
                  month="long"
                  value={lastUpdate}
                  year="numeric"
                />
                <span>, </span>
                <FormattedTime
                  hour="2-digit"
                  minute="2-digit"
                  value={lastUpdate}
                />
              </div>
            </span>
          }
        >
          <span className={classes.itemContent}>
            {displayIcon && (
              <span className={classes.updateIcon}>
                <UpdateIcon className={classes.icon} />
              </span>
            )}
            <span>
              <CfFormattedRelativeTime dateTime={lastUpdate} />
            </span>
          </span>
        </Tooltip>
      )}
    </Fragment>
  );
}

SensorsLastUpdate.propTypes = {
  lastUpdate: PropTypes.string,
  classes: PropTypes.object,
  displayIcon: PropTypes.bool,
};

SensorsLastUpdate.defaultProps = {
  lastUpdate: "",
  classes: {},
  displayIcon: true,
};

export default withStyles(style)(SensorsLastUpdate);
