import React, { FC } from "react";

import { Theme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getOperations } from "../../../shared/api/telematics/drives/drives.selectors";

import SelectionItem from "../../../shared/components/common/SelectionItem/SelectionItem";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import TelematicsOperationContent from "../TelematicsOperationContent/TelematicsOperationContent";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  OperationTo,
  TelematicsOperation as TelematicsOperationCode,
} from "../../../shared/api/telematics/telematics.types";
import { TelematicsOperationFormValues } from "../../telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.grey[500],
  },
}));

interface Props {
  disableRemoval: boolean;
  error?: string;
  isEditing: boolean;
  operations: OperationTo[];
  setSelectedOperation(selectedOperation?: TelematicsOperationCode): void;
}

const TelematicsOperation: FC<Props> = ({
  disableRemoval,
  error,
  isEditing,
  operations,
  setSelectedOperation,
}) => {
  const classes = useStyles();
  const { setFieldValue, values } =
    useFormikContext<TelematicsOperationFormValues>();

  const handleRemoveOperation = () => {
    setFieldValue("operation", "");
  };

  const handleSelectOperation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOperation(e.target.value as TelematicsOperationCode);
  };

  if (values.operation) {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (!isEditing) return;
      setSelectedOperation(values?.operation);
    };

    return (
      <>
        <div className={classes.label}>
          <FormattedMessage id="TelematicsList.operation" />:
        </div>
        <SelectionItem
          editing={isEditing && !disableRemoval}
          onRemoveItem={handleRemoveOperation}
        >
          <TelematicsOperationContent
            handleClick={handleClick}
            isEditing={isEditing}
            values={values}
          />
        </SelectionItem>
      </>
    );
  }

  return (
    <CfFormControl>
      <TextField
        disabled={!isEditing}
        error={!!error}
        helperText={error && <FormattedMessage id={error} />}
        label={<FormattedMessage id="TelematicsList.selectOperation" />}
        name="selectedOperation"
        onChange={handleSelectOperation}
        select
        value=""
      >
        {operations.map((operation) => (
          <MenuItem key={operation.code} value={operation.code}>
            {operation.name}
          </MenuItem>
        ))}
      </TextField>
    </CfFormControl>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  operations: getOperations(state),
});

export default connect(mapStateToProps)(TelematicsOperation);
