import React, { Component } from "react";

import PropTypes from "prop-types";
import {
  XAxis,
  YAxis,
  ComposedChart,
  ResponsiveContainer,
  Bar,
  Tooltip,
  CartesianGrid,
} from "recharts";

import * as chartFeatureTypes from "../../../constants/chartFeatureTypes.constants";

import CfChartArea from "../CfChartArea/CfChartArea";
import CfChartLine from "../CfChartLine/CfChartLine";
import CfChartWrapper from "../CfChartWrapper/CfChartWrapper";

class CfChartView extends Component {
  constructor(props) {
    super(props);

    this.defaultChartProps = {
      "data-test": "data",
      style: { marginLeft: -10 },
    };

    this.defaultFeatureProps = {
      isAnimationActive: false,
      fillOpacity: 1,
      strokeWidth: 2,
    };

    this.defaultXAxisProps = {
      dataKey: "time",
      tick: { fontSize: 12 },
      tickLine: false,
      domain: ["dataMin", "dataMax"],
      minTickGap: 100,
      scale: "time",
      type: "number",
      padding: { left: 10, right: 10 },
    };

    this.defaultYAxisProps = {
      tickLine: false,
      tick: { fontSize: 12 },
      allowDataOverflow: true,
      padding: { top: 1, bottom: 1 },
      width: 50,
    };

    this.defaultTooltipProps = {
      filterNull: false,
      offset: 50,
    };

    this.defaultCartesianGridProps = {
      strokeDasharray: "2 4",
      strokeLinecap: "round",
      strokeWidth: 1,
      stroke: "#CACACA",
    };
  }

  getChartFeature(chartFeatureType) {
    switch (chartFeatureType) {
      case chartFeatureTypes.LINE:
        return CfChartLine;
      case chartFeatureTypes.AREA:
        return CfChartArea;
      case chartFeatureTypes.BAR:
        return Bar;
      default:
        throw new Error(`Uknown chart feature type ${chartFeatureType}.`);
    }
  }

  render() {
    const {
      chartProps: {
        data,
        style,
        tooltipProps,
        xAxisProps,
        yAxisProps,
        ...restChartProps
      },
      features,
      hasNullData,
      isFetching,
      testId,
    } = this.props;
    return (
      <CfChartWrapper
        data={data}
        hasNullData={hasNullData}
        isFetching={isFetching}
        style={style}
        testId={testId}
      >
        {data && (
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart
              {...this.defaultChartProps}
              {...restChartProps}
              data={data}
            >
              {tooltipProps && (
                <Tooltip {...this.defaultTooltipProps} {...tooltipProps} />
              )}
              <XAxis {...this.defaultXAxisProps} {...xAxisProps} />
              <YAxis {...this.defaultYAxisProps} {...yAxisProps} />
              <CartesianGrid {...this.defaultCartesianGridProps} />

              {features.map((feature) => {
                const ChartFeature = this.getChartFeature(
                  feature.chartFeatureType,
                );
                return (
                  <ChartFeature
                    key={feature.name}
                    {...this.defaultFeatureProps}
                    {...feature}
                    dataKey={feature.name}
                    dotData={data}
                  />
                );
              })}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </CfChartWrapper>
    );
  }
}

CfChartView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  features: PropTypes.array,
  chartProps: PropTypes.object,
  testId: PropTypes.string,
  hasNullData: PropTypes.bool,
};

CfChartView.defaultProps = {
  features: [],
  chartProps: {},
  testId: "chart",
  hasNullData: false,
};

export default CfChartView;
