import React, { useEffect, FC, useState } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getLastImportState,
  getStateIsFetching,
} from "../../../shared/api/telematics/drivesImportHistory/drivesImportHistory.selectors";
import { getTelematicsAggregationsBulkEditMode } from "../../selectors/telematicsAggregations.selectors";

import { fetchImportStateHistory } from "../../actions/drivesImportHistory.actions";

import FileError from "../../../assets/img/icons/telematics/file-error.svg";
import FileLoading from "../../../assets/img/icons/telematics/file-loading.svg";
import FileSuccess from "../../../assets/img/icons/telematics/file-success.svg";
import { GetImportHistoryStateApiParams } from "../../../shared/api/telematics/drivesImportHistory/drivesImportHistory.api";
import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import { Thunk } from "../../../types";

import DrivesImportHistoryTable from "./DrivesImportHistoryTable";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { DriveImportTo } from "../../../shared/api/telematics/telematics.types";
import { DrivesImportHistoryReducer } from "../../reducer/drivesImportHistory.types";

interface Props {
  bulkEditMode: boolean;
  fetchImportStateHistory: (params: GetImportHistoryStateApiParams) => void;
  langId: string;
  lastImportState: DriveImportTo;
  stateIsFetching: boolean;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  badge: {
    top: 7,
    right: 11,
  },
  iconActive: {
    cursor: "pointer",
  },
  iconInactive: {
    opacity: 0.5,
  },
  tooltip: {
    maxWidth: 340,
  },
  dialogContent: {
    boxShadow: "unset",
  },
});

const renderImportHistoryStateIcon = (
  state: string | undefined,
  stateIsFetching: boolean,
) => {
  if (state === "STARTED" || stateIsFetching) {
    return <img alt="started" src={FileLoading} />;
  } else if (state === "ERROR") {
    return <img alt="error" src={FileError} />;
  } else if (state === "COMPLETED") {
    return <img alt="success" src={FileSuccess} />;
  } else {
    return <img alt="is-loading" src={FileLoading} />;
  }
};

const getTooltip = (state: string | undefined, stateIsFetching: boolean) => {
  if (state === "STARTED" || stateIsFetching) {
    return "loading";
  } else if (state === "ERROR") {
    return "error";
  } else if (state === "COMPLETED") {
    return "success";
  } else return "";
};

const DrivesImportHistory: FC<Props> = ({
  bulkEditMode,
  fetchImportStateHistory,
  langId,
  lastImportState,
  stateIsFetching,
}) => {
  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    fetchImportStateHistory({ language: langId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImportHistoryButton = () => (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="bottom"
      // eslint-disable-next-line max-len
      title={
        <FormattedMessage
          id={`TelematicsAggregations.drivesImportHistory.${getTooltip(
            lastImportState?.state,
            stateIsFetching,
          )}.tooltip`}
        />
      }
    >
      <IconButton
        aria-label="import history"
        className={bulkEditMode ? classes.iconInactive : undefined}
        disabled={bulkEditMode}
        onClick={() => setOpened(true)}
      >
        {renderImportHistoryStateIcon(lastImportState?.state, stateIsFetching)}
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {renderImportHistoryButton()}
      <CfDialog
        acceptText={<FormattedMessage id="common.confirm" />}
        classes={{ dialogContent: classes.dialogContent }}
        maxWidth="md"
        opened={opened}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormattedMessage id="TelematicsAggregations.drivesImportHistory.title" />
            <IconButton
              aria-label="close"
              onClick={() => setOpened(false)}
              style={{ padding: 0, width: "36px", height: "36px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        }
      >
        <DrivesImportHistoryTable langId={langId} />
      </CfDialog>
    </>
  );
};

const mapStateToProps = (state: DrivesImportHistoryReducer) => ({
  stateIsFetching: getStateIsFetching(state),
  lastImportState: getLastImportState(state),
});

const mapStateToPropsTelematics = (state: TelematicsState) => ({
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapStateToPropsCombined = (
  state: DrivesImportHistoryReducer & TelematicsState,
) => ({
  ...mapStateToProps(state),
  ...mapStateToPropsTelematics(state),
});

const mapDispatchToProps = (dispatch: Thunk<DrivesImportHistoryReducer>) =>
  bindActionCreators(
    {
      fetchImportStateHistory,
    },
    dispatch,
  );

export default connect(
  mapStateToPropsCombined,
  mapDispatchToProps,
)(DrivesImportHistory);
