/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";

import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
  Tooltip,
} from "recharts";

import CfChartWrapper from "../../../../shared/components/charts/CfChartWrapper/CfChartWrapper";
import { colorLuminance } from "../../../../shared/misc/colorLuminance";
import { CropQuality } from "../../containers/ManagementZones";
import XAxisLabel from "../XAxisLabel";

import CropQualityTooltip from "./CropQualityTooltip";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "250px",
  },
}));

const ReferenceLineLabel = (props: any) => {
  const {
    value,
    viewBox: { x, y },
  } = props;

  return (
    <text
      dy={-5}
      style={{ fontSize: 12, fontWeight: "bold", fill: grey[400] }}
      textAnchor="start"
      transform="translate(5)"
      x={x}
      y={y}
    >
      {value}
    </text>
  );
};

const EllipseBar = (props: any) => {
  const { fill, height, width, x, y } = props;
  return (
    <rect fill={fill} height={height} rx="2" ry="2" width={width} x={x} y={y} />
  );
};

const yAxisProps = {
  ticks: [],
  tickLine: false,
  tick: { fontSize: 12 },
  allowDataOverflow: true,
  padding: { top: 1, bottom: 1 },
  domain: ["auto", "auto"],
  width: 40,
};

const xAxisProps = {
  dataKey: "zoneNumber",
  tickLine: false,
  domain: ["dataMin", "dataMax"],
};

const innerChartProps = {
  isAnimationActive: false,
  dataKey: "zoneValue",
  fillOpacity: 1,
};

const tooltipProps = {
  filterNull: false,
  cursor: false,
};

interface CropQualityGraphProps {
  isFetching: boolean;
  zonesData: CropQuality;
}

const CropQualityGraph: FC<CropQualityGraphProps> = ({
  isFetching,
  zonesData,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [hoverIndex, setHoverIndex] = useState<number>();

  const formatZoneName = (value: string) =>
    `${intl.formatMessage({
      id: "ManagementZones.CropQualityGraph.zone",
    })} ${value}`;

  const { average, data } = zonesData;

  return (
    <CfChartWrapper
      data={data}
      isFetching={isFetching}
      classes={{
        wrapper: classes.wrapper,
      }}
    >
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          barCategoryGap="20%"
          data={data}
          margin={{ top: 0, right: 20, left: 0, bottom: 40 }}
          onMouseMove={(bar) => {
            if (bar.isTooltipActive) {
              setHoverIndex(bar?.activeTooltipIndex);
            } else {
              setHoverIndex(undefined);
            }
          }}
        >
          <Tooltip {...tooltipProps} content={<CropQualityTooltip />} />

          <XAxis
            tick={
              <XAxisLabel
                data={data}
                formatter={formatZoneName}
                rotation={-45}
                textAnchor="end"
              />
            }
            {...xAxisProps}
          />
          <YAxis {...yAxisProps} />
          <Bar {...innerChartProps} shape={<EllipseBar />}>
            {data &&
              data.map((entry, index) => (
                <Cell
                  key={entry.zoneNumber}
                  fill={
                    hoverIndex === index
                      ? colorLuminance(`#${entry.zoneColor}`, -0.1)
                      : `#${entry.zoneColor}`
                  }
                />
              ))}
          </Bar>

          <ReferenceLine isFront stroke="#999999" strokeDasharray="2 2" y={0} />
          <ReferenceLine
            ifOverflow="extendDomain"
            isFront
            stroke={grey[400]}
            y={average}
            label={
              <ReferenceLineLabel
                value={intl.formatMessage({
                  id: "ManagementZones.CropQualityGraph.avg",
                })}
              />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </CfChartWrapper>
  );
};

export default CropQualityGraph;
