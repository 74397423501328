export type SoilSamplesZonesColor = {
  color: string;
  id: number;
  zoneLevel: string;
  zoneStrength: number;
};

enum ZONES_LEVEL {
  COMPLIANT = "COMPLIANT",
  GOOD = "GOOD",
  HIGH = "HIGH",
  LOW = "LOW",
  VERY_HIGH = "VERY_HIGH",
}

export const zonesColors: SoilSamplesZonesColor[] = [
  { id: 1, color: "E60000", zoneStrength: 0, zoneLevel: ZONES_LEVEL.LOW },
  { id: 2, color: "FFAA00", zoneStrength: 1, zoneLevel: ZONES_LEVEL.COMPLIANT },
  { id: 3, color: "D3C235", zoneStrength: 2, zoneLevel: ZONES_LEVEL.GOOD },
  { id: 4, color: "A6D96A", zoneStrength: 3, zoneLevel: ZONES_LEVEL.HIGH },
  { id: 5, color: "70A800", zoneStrength: 4, zoneLevel: ZONES_LEVEL.VERY_HIGH },
];

enum PH_ZONES_LEVEL {
  ALKALINE = "ALKALINE",
  EXTREMELY_ACID = "EXTREMELY_ACID",
  MEDIUM_ACID = "MEDIUM_ACID",
  NEUTRAL = "NEUTRAL",
  SLIGHTLY_ACID = "SLIGHTLY_ACID",
  STRONGLY_ACID = "STRONGLY_ACID",
  STRONGLY_ALKALINE = "STRONGLY_ALKALINE",
}
export const phZonesColors: SoilSamplesZonesColor[] = [
  {
    id: 1,
    color: "E60000",
    zoneStrength: 0,
    zoneLevel: PH_ZONES_LEVEL.EXTREMELY_ACID,
  },
  {
    id: 2,
    color: "EC8219",
    zoneStrength: 1,
    zoneLevel: PH_ZONES_LEVEL.STRONGLY_ACID,
  },
  {
    id: 3,
    color: "F1C665",
    zoneStrength: 2,
    zoneLevel: PH_ZONES_LEVEL.MEDIUM_ACID,
  },
  {
    id: 4,
    color: "D3C235",
    zoneStrength: 3,
    zoneLevel: PH_ZONES_LEVEL.SLIGHTLY_ACID,
  },
  {
    id: 5,
    color: "D5E186",
    zoneStrength: 4,
    zoneLevel: PH_ZONES_LEVEL.NEUTRAL,
  },
  {
    id: 6,
    color: "A5C44E",
    zoneStrength: 5,
    zoneLevel: PH_ZONES_LEVEL.ALKALINE,
  },
  {
    id: 7,
    color: "70A800",
    zoneStrength: 6,
    zoneLevel: PH_ZONES_LEVEL.STRONGLY_ALKALINE,
  },
];

enum PERCENTAGE_ZONES_LEVEL {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  VERY_HIGH = "VERY_HIGH",
  VERY_LOW = "VERY_LOW",
}

export const percentageZonesColors: SoilSamplesZonesColor[] = [
  {
    id: 1,
    color: "E60000",
    zoneStrength: 0,
    zoneLevel: PERCENTAGE_ZONES_LEVEL.VERY_LOW,
  },
  {
    id: 2,
    color: "FFAA00",
    zoneStrength: 1,
    zoneLevel: PERCENTAGE_ZONES_LEVEL.LOW,
  },
  {
    id: 3,
    color: "D3C235",
    zoneStrength: 2,
    zoneLevel: PERCENTAGE_ZONES_LEVEL.MEDIUM,
  },
  {
    id: 4,
    color: "A6D96A",
    zoneStrength: 3,
    zoneLevel: PERCENTAGE_ZONES_LEVEL.HIGH,
  },
  {
    id: 5,
    color: "70A800",
    zoneStrength: 4,
    zoneLevel: PERCENTAGE_ZONES_LEVEL.VERY_HIGH,
  },
];

enum KMG_ZONES_LEVEL {
  COMPLIANT = "COMPLIANT",
  GOOD = "GOOD",
  NON_COMPLIANT = "NON_COMPLIANT",
}

export const kmgZonesColors: SoilSamplesZonesColor[] = [
  {
    id: 1,
    color: "E60000",
    zoneStrength: 0,
    zoneLevel: KMG_ZONES_LEVEL.NON_COMPLIANT,
  },
  {
    id: 2,
    color: "D3C235",
    zoneStrength: 1,
    zoneLevel: KMG_ZONES_LEVEL.COMPLIANT,
  },
  { id: 3, color: "70A800", zoneStrength: 2, zoneLevel: KMG_ZONES_LEVEL.GOOD },
];
