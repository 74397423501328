import React, { Component, Fragment } from "react";

import {
  DialogTitle,
  Grid,
  DialogActions,
  Button,
  Dialog,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import CfButtonPanel from "../../../../../shared/components/common/CfButtonPanel/CfButtonPanel";
import withOpen from "../../../../../shared/hocs/withOpen";
import VaMapSourceItem from "../VaMapSourceItem/VaMapSourceItem";
import VaMapSourceList from "../VaMapSourceList/VaMapSourceList";
import VaMapSourceNotAvailable from "../VaMapSourceNotAvailable/VaMapSourceNotAvailable";
import VariableFertilizationCard from "../VariableFertilizationCard/VariableFertilizationCard";

const styles = () => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  image: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    imageRendering: "pixelated",
    "&": {
      // fallback for FF
      imageRendering: "-moz-crisp-edges",
    },
  },
  variabilityGroupsWrapper: {
    margin: 6,
  },
  wrapperOneLine: {
    justifyContent: "flex-start",
  },
  variabilityGroupWrapper: {
    margin: "0 15px 10px 15px",
  },
});

class VaMapSourceDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currSatellite: null,
    };
  }

  setCurrentSatellite = (currSatellite) => {
    this.setState({
      currSatellite,
    });
  };

  render() {
    const { currSatellite } = this.state;
    const {
      classes,
      currMapZones,
      disabled,
      isOpen,
      onAccept,
      onClick,
      onClose,
      onOpen,
      onRemove,
      satellite,
      satelliteData,
    } = this.props;

    return (
      <Fragment>
        <VariableFertilizationCard
          disabled={disabled}
          onAddClick={onOpen}
          title="VaMapSourceDialog.managementZoneCard"
          onRemoveClick={() => {
            this.setCurrentSatellite(null);
            onRemove();
          }}
        >
          {satellite ? (
            <VaMapSourceItem
              isSelected={currMapZones === satellite?.zones}
              mapId="var-fert-zones-map"
              satellite={satellite}
              showType
              onClick={() =>
                onClick(
                  currMapZones === satellite?.zones ? null : satellite.zones,
                )
              }
            />
          ) : null}
        </VariableFertilizationCard>

        <Dialog
          data-test="dialog"
          maxWidth="lg"
          open={isOpen}
          onClose={() => {
            onAccept(currSatellite);
            this.setCurrentSatellite(null);
            onClose();
          }}
        >
          <DialogTitle data-test="dialog-title">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.title}>
                  <FormattedMessage id="VaMapSourceDialog.dialogTitle" />
                </span>
              </Grid>
            </Grid>
          </DialogTitle>

          <div className={`${classes.variabilityGroupsWrapper}`}>
            {Object.entries(satelliteData).map((entry) => {
              const [key, source] = entry;

              return (
                <div
                  className={classes.variabilityGroupWrapper}
                  data-test={`source-${key}`}
                  key={key}
                >
                  <CfButtonPanel data-test="source-label">
                    <FormattedMessage id={`VaMapSourceDialog.${key}`} />
                  </CfButtonPanel>

                  {!source?.length && (
                    <VaMapSourceNotAvailable source={source} type={key} />
                  )}

                  {Boolean(source?.length) && (
                    <VaMapSourceList
                      onItemClick={this.setCurrentSatellite}
                      satellite={currSatellite}
                      source={source}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <DialogActions data-test="dialog-actions">
            <Button
              id="cancel"
              onClick={() => {
                onClose();
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              color="primary"
              id="accept"
              onClick={() => {
                this.setCurrentSatellite(null);
                onAccept(currSatellite);
                onClose();
              }}
            >
              <FormattedMessage id="common.add" />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

VaMapSourceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  satelliteData: PropTypes.object.isRequired,
  satellite: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  currMapZones: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};

VaMapSourceDialog.defaultProps = {
  satellite: null,
  currMapZones: null,
};

export default compose(withOpen, withStyles(styles))(VaMapSourceDialog);
