export const GET_AGINTEGRATION_SUCCESS = "GET_AGINTEGRATION_SUCCESS";
export const GET_AGINTEGRATION_ERROR = "GET_AGINTEGRATION_ERROR";
export const GET_AGINTEGRATION_REQUEST = "GET_AGINTEGRATION_REQUEST";

export const POST_AGINTEGRATION_SUCCESS = "POST_AGINTEGRATION_SUCCESS";
export const POST_AGINTEGRATION_ERROR = "POST_AGINTEGRATION_ERROR";
export const POST_AGINTEGRATION_REQUEST = "POST_AGINTEGRATION_REQUEST";

export const PUT_AGINTEGRATION_SUCCESS = "PUT_AGINTEGRATION_SUCCESS";
export const PUT_AGINTEGRATION_ERROR = "PUT_AGINTEGRATION_ERROR";
export const PUT_AGINTEGRATION_REQUEST = "PUT_AGINTEGRATION_REQUEST";

export const DELETE_AGINTEGRATION_SUCCESS = "DELETE_AGINTEGRATION_SUCCESS";
export const DELETE_AGINTEGRATION_ERROR = "DELETE_AGINTEGRATION_ERROR";
export const DELETE_AGINTEGRATION_REQUEST = "DELETE_AGINTEGRATION_REQUEST";

export const GET_AGINTEGRATION_SETTINGS_SUCCESS =
  "GET_AGINTEGRATION_SETTINGS_SUCCESS";
export const GET_AGINTEGRATION_SETTINGS_ERROR =
  "GET_AGINTEGRATION_SETTINGS_ERROR";
export const GET_AGINTEGRATION_SETTINGS_REQUEST =
  "GET_AGINTEGRATION_SETTINGS_REQUEST";
