import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const TelematicsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1,3c-2.8,0-5,2.2-5,5c0,0.9,0.2,1.8,0.7,2.5c0.1,0.2,0.7,1,0.7,1l2.9,4.2c0.3,0.4,1,0.4,1.3,0l3-4.3 c0,0,0.5-0.7,0.6-0.9C20.8,9.7,21,8.9,21,7.9C21,5.2,18.8,3.1,16.1,3z M16.1,10.7c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7 s2.7,1.2,2.7,2.7S17.6,10.7,16.1,10.7z"
        fill="currentColor"
      />
      <path
        d="M18,19H5.4l0.7-2h5.8c0.6,0,1-0.4,1-1v0c0-0.6-0.4-1-1-1H5.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.2-1,0.7L3,19.6 c-0.2,0.5,0.1,1.1,0.6,1.3C3.8,21,3.9,21,4,21c0,0,0,0,0,0h14c0.6,0,1-0.4,1-1v0C19,19.4,18.5,19,18,19z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
export { TelematicsIcon };
