import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ImageNotSupportedIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <polygon points="21 18.18 21 12.53 16.02 12.53 16.02 13.2 21 18.18" />
      <path d="M16,16v5h3.59a1.24,1.24,0,0,0,.93-.45Z" />
      <path d="M13.64,10.82H21V5a2,2,0,0,0-2-2H9.71V6.88Z" />
      <polygon points="9.71 9.71 9.71 10.82 10.81 10.82 9.71 9.71" />
      <polygon points="12.44 12.45 9.71 12.45 9.71 21 14.29 21 14.29 14.3 12.44 12.45" />
      <polygon points="7.98 5.15 7.98 3 5.83 3 7.98 5.15" />
      <path d="M3.48,3.47A2.05,2.05,0,0,0,3,4.82V19a2,2,0,0,0,2,2H8V8Z" />
      <rect
        height="27.98"
        transform="translate(-5.68 11.72) rotate(-44.97)"
        width="2"
        x="10.31"
        y="-1.27"
      />
    </svg>
  </SvgIcon>
);

export default ImageNotSupportedIcon;
