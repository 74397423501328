import Feature from "../Feature.service";

import CommonLabelStyle from "./CommonLabelStyle.service";
import CommonPolygonStyle from "./CommonPolygonStyle.service";

export default class SplitStyles {
  static getParcelStyle(feature, hoveredId, selectedId) {
    if (feature.get("id") === selectedId) {
      return CommonPolygonStyle.getSelected();
    }

    if (feature.get("id") === hoveredId) {
      return CommonPolygonStyle.getHovered();
    }

    if (!Feature.isWithoutCrop(feature) || selectedId) {
      return CommonPolygonStyle.getDisabled();
    }
    return CommonPolygonStyle.getDefault();
  }

  static getLabelStyle(feature, resolution, hoveredId, selectedId) {
    if (resolution < 50) {
      const style = SplitStyles.resolveLabelStyle(feature, selectedId);
      style
        .getText()
        .setText(CommonLabelStyle.getLabelContent(feature, resolution));
      return style;
    }
    return null;
  }

  static resolveLabelStyle(feature, selectedId) {
    if (!Feature.isWithoutCrop(feature)) {
      return CommonLabelStyle.getDisabled();
    }

    if (feature.get("id") === selectedId) {
      return CommonLabelStyle.getSelected();
    } else if (selectedId) {
      return CommonLabelStyle.getDisabled();
    }
    return CommonLabelStyle.getDefault();
  }
}
