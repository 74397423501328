import angular from "angular";

import NotificationsController from "./notifications.controller";

const notificationsComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
  },
  controller: NotificationsController,
};

export default angular
  .module("app.notifications", [])
  .config(config)
  .component("notificationsComponent", notificationsComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider
    .state("farm.active.notifications", {
      url: "/notifications",
      views: {
        "farm@farm.active": {
          component: "notificationsComponent",
        },
      },
    })
    .state("farm.active.notifications.new", {
      url: "/new",
      views: {
        "farm@farm.active": {
          component: "notificationsComponent",
        },
      },
    })
    .state("farm.active.notifications.detail", {
      url: "/:notificationId",
      views: {
        "farm@farm.active": {
          component: "notificationsComponent",
        },
      },
    });
}
