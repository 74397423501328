import { createSelector } from "reselect";

import {
  getMonitoringData as getMonitoringDataApi,
  getMonitoringCropIntervals,
} from "../../../shared/api/sentinel/monitoring/monitoring.selectors";
import { getSatelliteProducts } from "../../../shared/api/sentinel/satelliteProducts/satelliteProducts.selectors";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import { MonitoringData } from "../../../shared/api/sentinel/monitoring/monitoring.types";

export const getIndex = (state: PrecisionState) => state.ui.monitoring.index;
export const getDateFrom = (state: PrecisionState) =>
  state.ui.monitoring.dateFrom;
export const getDateTo = (state: PrecisionState) =>
  state.ui.monitoring.dateTo ?? "";

export const getParcelGeometry = (state: PrecisionState) =>
  state.api.geometry.geometry;

export const getMonitoringStatus = createSelector(
  getSatelliteProducts,
  (products) => products.biomonitoringStatus,
);

export const getIsMonitoring = (state: PrecisionState) =>
  Boolean(getMonitoringData(state));

export const getCropIntervals = createSelector(
  getMonitoringCropIntervals,
  (cropIntervals) =>
    cropIntervals.map((cropInterval) => ({
      id: `${cropInterval.crop.legislativeCode}_${cropInterval.from}`,
      ...cropInterval,
    })),
);

export const getMonitoringData = createSelector(getMonitoringDataApi, (data) =>
  data
    .filter((item: MonitoringData) => item?.snapshots?.length)
    .map((item) => ({
      crop: item.crop,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      status: item.status,
      value: item.snapshots[0].value,
      imgPath: item.snapshots[0].imgPath,
      imgExtent: item.snapshots[0].imgExtent,
      zones: item.snapshots[0].zones,
      indexType: item.snapshots[0].indexType,
    })),
);

export const getGraphData = createSelector(getMonitoringData, (data) =>
  data.slice().reverse(),
);
