const PREFIX = "AGROEVIDENCE_CATALOGUES_EAGRI";

export const GET_CROP_USAGE_REQUEST = `${PREFIX}/GET_CROP_USAGE_REQUEST`;
export const GET_CROP_USAGE_SUCCESS = `${PREFIX}/GET_CROP_USAGE_SUCCESS`;
export const GET_CROP_USAGE_ERROR = `${PREFIX}/GET_CROP_USAGE_ERROR`;
export const RESET_CROP_USAGE = `${PREFIX}/RESET_CROP_USAGE`;

export const GET_HARVEST_PRODUCTS_REQUEST = `${PREFIX}/GET_HARVEST_PRODUCTS_REQUEST`;
export const GET_HARVEST_PRODUCTS_SUCCESS = `${PREFIX}/GET_HARVEST_PRODUCTS_SUCCESS`;
export const GET_HARVEST_PRODUCTS_ERROR = `${PREFIX}/GET_HARVEST_PRODUCTS_ERROR`;
export const RESET_HARVEST_PRODUCTS = `${PREFIX}/RESET_HARVEST_PRODUCTS`;

export const GET_FERTILIZER_NITROGEN_CATEGORIES_REQUEST = `${PREFIX}/GET_FERTILIZER_NITROGEN_CATEGORIES_REQUEST`;
export const GET_FERTILIZER_NITROGEN_CATEGORIES_SUCCESS = `${PREFIX}/GET_FERTILIZER_NITROGEN_CATEGORIES_SUCCESS`;
export const GET_FERTILIZER_NITROGEN_CATEGORIES_ERROR = `${PREFIX}/GET_FERTILIZER_NITROGEN_CATEGORIES_ERROR`;
export const RESET_FERTILIZER_NITROGEN_CATEGORIES = `${PREFIX}/RESET_FERTILIZER_NITROGEN_CATEGORIES`;

export const GET_FERTILIZER_KIND_REQUEST = `${PREFIX}/GET_FERTILIZER_KIND_REQUEST`;
export const GET_FERTILIZER_KIND_SUCCESS = `${PREFIX}/GET_FERTILIZER_KIND_SUCCESS`;
export const GET_FERTILIZER_KIND_ERROR = `${PREFIX}/GET_FERTILIZER_KIND_ERROR`;
export const RESET_FERTILIZER_KIND = `${PREFIX}/RESET_FERTILIZER_KIND`;

export const GET_FERTILIZER_REGISTRATION_TYPE_REQUEST = `${PREFIX}/GET_FERTILIZER_REGISTRATION_TYPE_REQUEST`;
export const GET_FERTILIZER_REGISTRATION_TYPE_SUCCESS = `${PREFIX}/GET_FERTILIZER_REGISTRATION_TYPE_SUCCESS`;
export const GET_FERTILIZER_REGISTRATION_TYPE_ERROR = `${PREFIX}/GET_FERTILIZER_REGISTRATION_TYPE_ERROR`;
export const RESET_FERTILIZER_REGISTRATION_TYPE = `${PREFIX}/RESET_FERTILIZER_REGISTRATION_TYPE`;
