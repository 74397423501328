/**
 * Created by ondrejzvara on 5.4.16.
 */

/*@ngInject*/
export default function TestUtils(
  $httpBackend,
  BACKEND_OPTIONS,
  API_RESPONSE,
  CREDENTIALS,
  Auth,
  $compile,
) {
  const AUTH_URL = BACKEND_OPTIONS.gatewayUrl + "/authenticate";

  var service = {
    login: login,
    logout: logout,
    isPromise: isPromise,
    compile: compile,
  };
  return service;

  /////////////////////////

  function login() {
    $httpBackend.when("POST", AUTH_URL).respond(API_RESPONSE["token"]);
    $httpBackend.expect("POST", AUTH_URL).respond(API_RESPONSE["token"]);

    Auth.login(CREDENTIALS);
    $httpBackend.flush();
  }

  function logout() {
    $httpBackend.when("DELETE", AUTH_URL).respond(200);
    $httpBackend.expect("DELETE", AUTH_URL).respond(200);
    Auth.logout();
    $httpBackend.flush();
  }

  function isPromise(promise) {
    if (
      _.isObject(promise) &&
      promise.then instanceof Function &&
      promise["catch"] instanceof Function &&
      promise["finally"] instanceof Function
    ) {
      return true;
    }

    return false;
  }

  function compile(el, scope) {
    var compiled = $compile(angular.element(el))(scope);
    scope.$digest();
    return compiled;
  }
}
