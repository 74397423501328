import React from "react";

import PropTypes from "prop-types";

import ReduxDevTools from "./redux-devtools";

const WithReduxdevtools = ({ children, environment }) => {
  const isNotProduction = environment !== "prod";
  return (
    <>
      {isNotProduction && <ReduxDevTools />}
      {children}
    </>
  );
};

WithReduxdevtools.propTypes = {
  children: PropTypes.node,
  environment: PropTypes.string,
};

export default WithReduxdevtools;
