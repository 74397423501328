import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

const styles = () => ({
  value: {
    fontSize: 13,
  },
});

function PestPredictionValue(props) {
  const { classes, pestPredictionColor, pestPredictionValue } = props;
  if (isNaN(parseFloat(pestPredictionValue))) {
    return <span style={{ color: "grey" }}>-</span>;
  }
  return (
    <span className={classes.value} style={{ color: pestPredictionColor }}>
      <CfFormattedNumber
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        value={pestPredictionValue}
      />
    </span>
  );
}

PestPredictionValue.propTypes = {
  classes: PropTypes.object,
  pestPredictionValue: PropTypes.number.isRequired,
  pestPredictionColor: PropTypes.string,
};

PestPredictionValue.defaultProps = {
  classes: {},
  pestPredictionColor: undefined,
};

export default withStyles(styles)(PestPredictionValue);
