import React from "react";

import Collapse from "@mui/material/Collapse";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  layerListContent: {
    padding: "8px 10px 8px 16px",
  },
};

function MapSwitcherBody(props) {
  const { children, classes, expanded, testId } = props;
  return (
    <Collapse in={expanded}>
      <div className={classes.layerListContent} data-test={`${testId}-content`}>
        {React.Children.only(children)}
      </div>
    </Collapse>
  );
}

MapSwitcherBody.propTypes = {
  expanded: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  classes: PropTypes.object,
  children: PropTypes.element.isRequired,
};

MapSwitcherBody.defaultProps = {
  classes: {},
};

export default withStyles(styles)(MapSwitcherBody);
