import ErrorService from "../../../services/Error.service";
import SensorsService from "../../../services/Sensors.service";

export const getIsFetchingNodes = (state) => state.api.nodes.isFetchingNodes;
export const getIsFetchingStatistics = (state) =>
  state.api.nodes.isFetchingStatistics;
export const getIsFetchingNodeTypes = (state) =>
  state.api.nodes.isFetchingNodeTypes;

export const getNodesError = (state) =>
  ErrorService.getResErrorDto(state.api.nodes.errorNodes);
export const getStatisticsError = (state) =>
  ErrorService.getResErrorDto(state.api.nodes.errorStatistics);
export const getNodeTypesError = (state) =>
  ErrorService.getResErrorDto(state.api.nodes.errorNodeTypes);

export const getNodes = (state) => state.api.nodes.items;
export const getItemsIds = (state) => state.api.nodes.itemsIds;
export const getTotalCount = (state) => state.api.nodes.totalCount;

export const getNodeTypes = (state) => state.api.nodes.types;
export const getNetworkTypes = (state) => state.api.nodes.networkTypes;
export const getNodesDefaultFarm = (state) => state.api.nodes.defaultFarm;
export const getNodesStatistics = (state) => state.api.nodes.statistics;

export const getBaraniAvailableBases = (state) =>
  state.api.nodes.baraniAvailableBases;
export const getBaraniAvailableBasesIsFetching = (state) =>
  state.api.nodes.isFetchingBaraniAvailableBases;

export const filterNodes = (nodes) =>
  nodes.filter((node) => SensorsService.isCorrectNode(node));
