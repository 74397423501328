import React, { useState, useEffect, useContext } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getAction,
  getError,
  getIsFetching,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";
import { getVariableActionIds as getVariableActionIdsSelector } from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import {
  getActionApi,
  postActionSplit,
} from "../../../../../shared/api/agroevidence/actions/actions.api";
import {
  getVariableActionIds,
  resetVariableActionIds,
} from "../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import { getActionRelatedDrivesApi } from "../../../../../shared/api/telematics/aggregations/aggregations.api";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { CfLoaderPage } from "../../../../../shared/components/common/CfLoaderPage/CfLoaderPage";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { AsyncFn, RsaaApiError, Thunk } from "../../../../../types";
import Eph from "../../../eph/containers/Eph/Eph";
import { isEph } from "../../../eph/misc/eph.helpers";
import ActionOtherDetail from "../../../others/containers/ActionOtherDetail/ActionOtherDetail";
import {
  getFormType,
  isOtherAction,
} from "../../../others/helpers/others.helpers";
import Vrf from "../../../vrf/containers/Vrf/Vrf";
import { isVrf } from "../../../vrf/helpers/vrf.helpers";
import Vrs from "../../../vrs/containers/Vrs/Vrs";
import { isVrs } from "../../../vrs/helpers/vrs.helpers";
import { getRouting } from "../../misc/action.helpers";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import {
  ActionDetailTo,
  SplitActionRequest,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

type OwnProps = {
  farmId: string;
  langId: string;
  match: matchType<{ actionId: string }>;
  ngGoToAction: (actionId: string, route: string) => void;
  ngGoToTelematicDrive: (driveId: string) => void;
};

type ReduxProps = {
  action?: ActionDetailTo;
  actionError: RsaaApiError;
  getActionApi: (actionId: string) => void;
  getActionRelatedDrivesApi: (actionId: string) => void;
  getVariableActionIds: () => void;
  isFetching: boolean;
  postActionSplit: (val: SplitActionRequest) => void;
  resetVariableActionIds: () => void;
  variableActionIds?: unknown[];
};

type ContextProps = {
  checkedItems: {
    [fieldId: string]: boolean;
  };
  handleCancelIsSplitting: () => void;
  handleCheckboxChange: (fieldId: string, checked: boolean) => void;
  handleSetIsSplittingError: (value: boolean) => void;
  handleStartIsSplitting: () => void;
  isSplitting: boolean;
  isSplittingError: boolean;
  numberOfCheckedItems: number;
  splitActionHandler: () => void;
};

type Props = OwnProps & ReduxProps & ContextProps;

export const ActionDetailContext = React.createContext<ContextProps & OwnProps>(
  {
    checkedItems: {},
    isSplittingError: false,
    farmId: "",
    handleCancelIsSplitting(): void {},
    handleSetIsSplittingError(): void {},
    handleCheckboxChange(): void {},
    handleStartIsSplitting(): void {},
    isSplitting: false,
    numberOfCheckedItems: 0,
    splitActionHandler(): void {},
    langId: "",
    match: {
      params: { actionId: "" },
      isExact: false,
      path: "",
      url: "",
    },
    ngGoToAction(): void {},
    ngGoToTelematicDrive(): void {},
  },
);

const ActionDetail = (props: Props) => {
  const {
    action = {},
    actionError,
    getActionApi,
    getActionRelatedDrivesApi,
    getVariableActionIds,
    isFetching,
    match,
    postActionSplit,
    resetVariableActionIds,
    variableActionIds = undefined,
  } = props;
  const [checkedItems, setCheckedItems] = useState<{
    [fieldId: string]: boolean;
  }>({});
  const [isSplitting, setIsSplitting] = useState(false);
  const [isSplittingError, setIsSplittingError] = useState(false);

  const showSnackbar = useContext(SnackbarContext);

  useEffect(() => {
    (getVariableActionIds as AsyncFn)().then(() => {
      getActionApi(match.params.actionId);
      getActionRelatedDrivesApi(match.params.actionId);
    });
  }, [
    getActionApi,
    getVariableActionIds,
    match.params.actionId,
    getActionRelatedDrivesApi,
  ]);

  const getCheckedItemsCount = () =>
    Object.keys(checkedItems).filter((id) => checkedItems[id]).length;

  useEffect(() => {
    const { action: newAction, variableActionIds: newVariableActionIds } =
      props;

    if (
      Array.isArray(newVariableActionIds) &&
      newAction &&
      (!props.action || newAction.id !== props.action.id)
    ) {
      if (
        !isEph(newAction, newVariableActionIds) &&
        !isVrf(newAction, newVariableActionIds) &&
        !isVrs(newAction, newVariableActionIds)
      ) {
        const route = getRouting(newAction, newVariableActionIds);
        props.ngGoToAction(newAction.id, route);
      }
    }
  }, [action, variableActionIds, props]);

  useEffect(
    () => () => {
      resetVariableActionIds();
    },
    [resetVariableActionIds],
  );

  const handleCheckboxChange = (fieldId: string, checked: boolean) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [fieldId]: checked,
    }));
  };

  const handleStartIsSplitting = () => {
    setIsSplitting(true);
  };

  const handleCancelIsSplitting = () => {
    setIsSplitting(false);
  };

  const handleResetState = () => {
    setCheckedItems({});
    setIsSplitting(false);
  };

  const handleSetIsSplittingError = (value: boolean) => {
    setIsSplittingError(value);
  };

  const splitActionHandler = async () => {
    (postActionSplit as AsyncFn<SplitActionRequest>)({
      actionId: match.params.actionId,
      parcelsToSplit: Object.keys(checkedItems).filter(
        (id) => checkedItems[id],
      ),
    })
      .then(() =>
        showSnackbar({
          message: <FormattedMessage id="splitAction.snackbar.success" />,
          isSuccess: true,
        }),
      )
      .catch(() =>
        showSnackbar({
          message: <FormattedMessage id="splitAction.snackbar.error" />,
          isError: true,
        }),
      )
      .finally(() => {
        getActionApi(match.params.actionId);
        getActionRelatedDrivesApi(match.params.actionId);
        handleResetState();
      });
  };

  if (!Object.keys(action) || isFetching) return <CfLoaderPage />;

  const isEphAction = isEph(action, variableActionIds);
  const isVrfAction = isVrf(action, variableActionIds);
  const isVrsAction = isVrs(action, variableActionIds);
  const isNeitherAction = !isEphAction && !isVrfAction && !isVrsAction;

  return (
    <CfErrorPage
      error={actionError}
      error40xHeadingTranslId="error.heading.actionDetail"
      error40xMessageTranslId="error.message.actionDetail"
      handle400s
    >
      <ActionDetailContext.Provider
        value={{
          match,
          ngGoToAction: props.ngGoToAction,
          ngGoToTelematicDrive: props.ngGoToTelematicDrive,
          farmId: props.farmId,
          langId: props.langId,
          checkedItems,
          isSplitting,
          isSplittingError,
          handleSetIsSplittingError,
          numberOfCheckedItems: getCheckedItemsCount(),
          handleCheckboxChange,
          handleStartIsSplitting,
          handleCancelIsSplitting,
          splitActionHandler,
        }}
      >
        {isEphAction && (
          <Eph
            ngGoToActions={props.ngGoToAction}
            {...props}
            match={match}
            existingAction={{
              ...action,
              isEph: true,
            }}
          />
        )}
        {isVrfAction && (
          <Vrf
            history={history}
            {...props}
            match={match}
            existingAction={{
              ...action,
              isVrf: true,
            }}
          />
        )}
        {isVrsAction && (
          <Vrs
            history={history}
            {...props}
            match={props.match}
            existingAction={{
              ...action,
              isVrs: true,
            }}
          />
        )}
        {isNeitherAction && isOtherAction(action) && (
          <ActionOtherDetail
            history={history}
            {...props}
            existingAction={action}
            formType={getFormType(action)}
            isExisting={Boolean(action)}
            match={match}
          />
        )}
      </ActionDetailContext.Provider>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  action: getAction(state),
  actionError: getError(state),
  isFetching: getIsFetching(state),
  variableActionIds: getVariableActionIdsSelector(state),
});

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      postActionSplit: postActionSplit as (val: SplitActionRequest) => void,
      getActionApi,
      getVariableActionIds: getVariableActionIds as () => void,
      resetVariableActionIds,
      getActionRelatedDrivesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionDetail);
