import * as types from "./layers.constants";

import LayersApiService from "./LayersApi.service";

export const fetchLayersConfig = (countryCode) => (dispatch) => {
  dispatch(getLayers());
  LayersApiService.getLayersConfig(countryCode)
    .then((payload) => {
      dispatch(getLayersSuccess(payload));
    })
    .catch((payload) => {
      dispatch(getLayersError(payload));
    });
};

export const getLayers = () => ({
  type: types.GET_LAYERS,
});

export const getLayersSuccess = (payload) => ({
  type: types.GET_LAYERS_SUCCESS,
  payload,
});

export const getLayersError = (payload) => ({
  type: types.GET_LAYERS_ERROR,
  payload,
});

export const resetLayers = () => ({
  type: types.RESET_LAYERS,
});
