import * as types from "./stores.constants";

export default () =>
  (
    state = {
      isFetching: false,
      error: {},
      items: [],
      totalCount: 0,
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_STORE_ITEM:
      case types.GET_STORE_ITEMS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_STORE_ITEMS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_STORE_ITEM_ERROR:
      case types.GET_STORE_ITEMS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
          totalCount: 0,
        };
      case types.GET_STORE_ITEM_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: [action.payload],
        };
      case types.RESET_STORES:
        return {
          ...state,
          items: [],
        };

      default:
        return state;
    }
  };
