import { createSelector } from "reselect";

import { getFertilizers } from "../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";
import { getPlantProtection } from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";
import { getSeeds } from "../../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors";
import { getItems } from "../../../../shared/api/stores/stores/stores.selectors";

export const getStoresPage = (state) => state.ui.stores.page;
export const getStoresOrder = (state) => state.ui.stores.order;
export const getStoresRowsPerPage = (state) => state.ui.stores.rowsPerPage;
export const getStoresMaterialType = (state) => state.ui.stores.material;
export const getStoresShowNullValues = (state) =>
  state.ui.stores.showNullValues;
export const getStoresTextFilter = (state) => state.ui.stores.textFilter;

export const getStoreItems = (state) => getItems(state);

export const getMaterialsSuggestions = createSelector(
  getPlantProtection,
  getFertilizers,
  getSeeds,
  (plantProtection, fertilizers, seeds) =>
    [
      {
        title: "common.fertilizers",
        items: fertilizers.map((item) => ({ type: "FERTILIZER", ...item })),
      },
      {
        title: "common.chemistries",
        items: plantProtection.map((item) => ({ type: "CHEMISTRY", ...item })),
      },
      {
        title: "common.seeds",
        items: seeds.map((item) => ({ type: "SEED", ...item })),
      },
    ].filter((section) => section.items.length > 0),
);
