import React, { Component } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { withStyles } from "@mui/styles";
import { withFormik, Field } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";
import * as notificationsStatus from "../../notificationsStatus";

const styles = (theme) => ({
  switchersPanel: {
    marginTop: 20,
  },
  formControlLabel: {
    margin: 0,
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  farmName: {
    color: theme.palette.grey[500],
  },
});

export class NotificationsAdminEditDialog extends Component {
  handleClose = () => {
    const { onClose, resetForm } = this.props;
    this.setState({
      active: false,
      adminEmail: "",
    });
    resetForm();
    onClose();
  };

  render() {
    const { classes, farm, handleSubmit, opened } = this.props;

    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.edit" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        maxWidth="xs"
        onAccept={handleSubmit}
        onCancel={this.handleClose}
        onClose={this.handleClose}
        onEnter={this.initializeForm}
        opened={opened}
        title={
          <div>
            <FormattedMessage id="NotificationsAdminEditDialog.farmNotifications" />
            <div className={classes.farmName}>{farm?.farmName}</div>
          </div>
        }
      >
        <form>
          <Field name="active" type="checkbox">
            {({ field, form }) => (
              <div className={classes.switchersPanel}>
                <FormControlLabel
                  labelPlacement="start"
                  classes={{
                    root: classes.formControlLabel,
                  }}
                  control={
                    <Switch
                      checked={field.value}
                      color="primary"
                      id="farm-active-notification"
                      onChange={(evt, value) => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  }
                  label={
                    <FormattedMessage id="NotificationsAdminEditDialog.activeNotifications" />
                  }
                />
              </div>
            )}
          </Field>
          <CfFormControl>
            <Field
              component={CfFormikTextField}
              name="adminEmail"
              validate={validators.requiredAndEmail}
              validateOnBlur={true}
              label={
                <FormattedMessage id="NotificationsAdminTable.adminEmail" />
              }
            />
          </CfFormControl>
        </form>
      </CfDialog>
    );
  }
}

NotificationsAdminEditDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  farm: PropTypes.object,
};

NotificationsAdminEditDialog.defaultProps = {
  opened: false,
  farm: null,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: (props) => ({
    active: props.farm?.status === notificationsStatus.ENABLED,
    adminEmail: props.farm?.adminEmail || "",
  }),
  handleSubmit: (values, { props }) => {
    props.onAccept(props.farm, values.active, values.adminEmail);
  },
})(withStyles(styles)(NotificationsAdminEditDialog));
