import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  FORM_TYPES,
  OTHER_ACTION_HARVEST_CODE,
  OTHER_ACTION_MOWING_CODE,
  OTHER_ACTION_SOWING_CODE,
} from "../../actionOther.constants";

import {
  updateMowingActionApi,
  updateHarvestActionApi,
  updateSowingActionApi,
  updateOtherActionApi,
  createMowingActionApi,
  createHarvestActionApi,
  createSowingActionApi,
  createOtherActionApi,
} from "../../../../../shared/api/agroevidence/actions/actions.api";
import LocalStorage from "../../../../../shared/services/LocalStorage.service";
import { ActionsPalette } from "../../../shared/palette/ActionsPalette";
import ActionStateMapper from "../../../shared/services/ActionStateMapper.service";
import { mapRequestBodyOtherActionTo } from "../../../shared/services/ActionToMapper.services";
import { FormContext } from "../../actionOther.context";
import ActionOther from "../../containers/ActionOther/ActionOther";
import { filterOnlySownParcels } from "../../helpers/others.helpers";

const styles = (theme) => ({
  formButtons: {},
  button: {
    margin: "10px",
  },
  helperText: {
    position: "absolute",
    bottom: -22,
  },
  date: {
    marginTop: 10,
  },
  actionInfo: {
    marginTop: 30,
    marginBottom: 30,
  },
  card: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    padding: "16px",
  },
  header: {
    padding: "16px",
    display: "inline-flex",
    color: ActionsPalette.neutral.main,
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
});

class ActionOtherFormWrapper extends Component {
  constructor(props) {
    super(props);
    if (props.action) {
      this.initialValues = ActionStateMapper.mapOtherActionState(
        props.action,
        props.intl.locale,
      );
    }
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.parcels?.length > 0) {
      errors.parcels = "error";
    }
    if (values.actionType === FORM_TYPES.SOWING && !values.seedId) {
      errors.seedId = "error";
    }
    return errors;
  };

  // TODO: Saving of newly created subtractable areas
  // When deleting of subtractable areas is ready on back end,
  // we can save the newly created subtractableAres that are applied on the parcels in this action.
  // This mechanism is already implemented in EPH form,
  // but here, we will save the new subtractable areas when we can also delete them.
  saveAction = (values) => {
    const { locale } = this.props.intl;
    const { actionType } = values;

    let parcelsToSave = values.parcels;

    // Filter only sown parcels for harvet action
    if (actionType === OTHER_ACTION_HARVEST_CODE)
      parcelsToSave = filterOnlySownParcels(values.parcels);

    const valuesToSave = {
      ...values,
      parcels: [...parcelsToSave],
    };

    const data = mapRequestBodyOtherActionTo(valuesToSave, locale);

    switch (actionType) {
      case OTHER_ACTION_MOWING_CODE:
        this.props
          .createMowingActionApi(data)
          .then((res) => this.responseProcessing(res, true));
        break;

      case OTHER_ACTION_HARVEST_CODE:
        this.props
          .createHarvestActionApi(data)
          .then((res) => this.responseProcessing(res, true));
        break;

      case OTHER_ACTION_SOWING_CODE:
        this.props
          .createSowingActionApi(data)
          .then((res) => this.responseProcessing(res, true));
        break;

      default:
        this.props
          .createOtherActionApi(data)
          .then((res) => this.responseProcessing(res, true));
    }
  };

  updateAction = (values) => {
    const { actionType } = values;
    const { locale } = this.props.intl;

    const data = mapRequestBodyOtherActionTo(values, locale, this.props.action);

    switch (actionType) {
      case OTHER_ACTION_MOWING_CODE:
        this.props
          .updateMowingActionApi(data)
          .then((res) => this.responseProcessing(res));
        break;

      case OTHER_ACTION_HARVEST_CODE:
        this.props
          .updateHarvestActionApi(data)
          .then((res) => this.responseProcessing(res));
        break;

      case OTHER_ACTION_SOWING_CODE:
        this.props
          .updateSowingActionApi(data)
          .then((res) => this.responseProcessing(res));
        break;

      default:
        this.props
          .updateOtherActionApi(data)
          .then((res) => this.responseProcessing(res));
    }
  };

  responseProcessing = (res, newAction = false) => {
    const { formatMessage } = this.props.intl;
    if (newAction) LocalStorage.removeFromLocalStorage(this.context.lsName);
    if (!res.error) {
      if (newAction) this.context.onEditingEnd();
      this.props.goToActions();
    } else {
      this.context.showSnackbar({
        message: formatMessage({ id: "action.other.error" }),
        isError: true,
      });
    }
  };

  handleSubmit = (values) => {
    if (this.context.isExisting) {
      this.updateAction(values);
    } else {
      this.saveAction(values);
    }
  };

  render() {
    const { classes, countryCode } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.initialValues ?? { ...this.context.initialValues }}
        onSubmit={this.handleSubmit}
        validate={this.validateForm}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <ActionOther classes={classes} countryCode={countryCode} />
      </Formik>
    );
  }
}

ActionOtherFormWrapper.contextType = FormContext;

ActionOtherFormWrapper.propTypes = {
  action: PropTypes.object,
  updateMowingActionApi: PropTypes.func.isRequired,
  updateHarvestActionApi: PropTypes.func.isRequired,
  updateSowingActionApi: PropTypes.func.isRequired,
  updateOtherActionApi: PropTypes.func.isRequired,
  createMowingActionApi: PropTypes.func.isRequired,
  createHarvestActionApi: PropTypes.func.isRequired,
  createSowingActionApi: PropTypes.func.isRequired,
  createOtherActionApi: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  goToActions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ActionOtherFormWrapper.defaultProps = {
  action: null,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMowingActionApi,
      updateHarvestActionApi,
      updateSowingActionApi,
      updateOtherActionApi,
      createMowingActionApi,
      createHarvestActionApi,
      createSowingActionApi,
      createOtherActionApi,
    },
    dispatch,
  );

export default compose(
  connect(null, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
)(ActionOtherFormWrapper);
