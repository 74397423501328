import React, { FC, ReactNode, useEffect, useCallback, useMemo } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getDrivers,
  getIsFetchingDrivers,
} from "../../../../shared/api/telematics/drives/drives.selectors";
import { selectDateRange } from "../../../selectors/telematicsTabs.selectors";

import { fetchDrivers } from "../../../actions/telematicsList.actions";

import * as sortOrder from "../../../../shared/constants/sortOrder.constants";

import { resetDrivers } from "../../../../shared/api/telematics/drives/drives.api";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../types";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import {
  DriverTo,
  DriverSortColumn,
} from "../../../../shared/api/telematics/telematics.types";

interface DriverSelectorProps {
  dateFrom: string;
  dateTo: string;
  defaultValues?: DriverTo[];
  externalClasses?: Record<string, string>;
  fetchDrivers(
    dateFrom: string,
    dateTo: string,
    sortCol: string,
    sortDir: string,
  ): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: DriverTo[]): void;
  options: DriverTo[];
  resetDrivers(): void;
}

const defaultDefaultValues: DriverTo[] = [];

const handleGetSelected = (option: DriverTo, value?: DriverTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const DriverSelector: FC<DriverSelectorProps> = ({
  dateFrom,
  dateTo,
  defaultValues = defaultDefaultValues,
  externalClasses,
  fetchDrivers,
  isFetching,
  label,
  onChange,
  options,
  resetDrivers,
}) => {
  const classes = useAdvancedFilterStyles();
  const intl = useIntl();

  const mergedOptions = useMemo(
    () => [
      {
        code: "nodriver",
        name: intl.formatMessage({ id: "TelematicsList.filter.withoutDriver" }),
      },
      ...options,
    ],
    [intl, options],
  );

  useEffect(() => {
    fetchDrivers(dateFrom, dateTo, DriverSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetDrivers();
    };
  }, [fetchDrivers, resetDrivers, dateFrom, dateTo]);

  const handleRenderOption = useCallback(
    (option: DriverTo) => (
      <div>
        <div>{option.name ?? option.code}</div>
        <div className={classes.subtext}>
          {option.code === "nodriver" ? "-" : option.code}
        </div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: DriverTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? value.code}
            label={
              <>
                {value.name ?? value.code}{" "}
                <span className={classes.subtext}>
                  {value.code === "nodriver" ? "-" : value.code}
                </span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="driver-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={mergedOptions}
      testId="driver-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getDrivers(state),
  isFetching: getIsFetchingDrivers(state),
  dateFrom: selectDateRange(state).dateFrom,
  dateTo: selectDateRange(state).dateTo,
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrivers,
      resetDrivers,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DriverSelector);
