import { RSAAAction, RSAAResultAction } from "redux-api-middleware";

import * as types from "./asAppliedTaskdata.constants";

import { patchTaskdataApi } from "./asAppliedTaskdata.api";

import { TaskDataPatchTo } from "../../satellite/satellite.types";

export const resetFileImportError = () => ({
  type: types.AS_APPLIED_SET_FILE_ERROR,
  payload: undefined,
});

export const setAsAppliedShowVarioDocInactive = (val: boolean) => ({
  type: types.AS_APPLIED_SHOW_VARIODOC_INACTIVE,
  payload: val,
});

export const patchTaskdata =
  (params: TaskDataPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<RSAAResultAction>) =>
    dispatch(patchTaskdataApi(params));

export const setFocusedRow = (val?: string) => ({
  type: types.SET_FOCUSED_ROW,
  payload: val,
});
