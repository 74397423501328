import {
  getAdminFarms as getAdminFarmsApi,
  getAdminFarmsTotalCount as getAdminFarmsTotalCountApi,
  isFetchingAdminFarms as isFetchingAdminFarmsApi,
} from "../../../../shared/api/automation/notificationsAdmin/notificationsAdmin.selectors";

export const getNotificationsAdminPage = (state) =>
  state.ui.notifications_admin.page;
export const getNotificationsAdminOrder = (state) =>
  state.ui.notifications_admin.order;
export const getNotificationsAdminOrderBy = (state) =>
  state.ui.notifications_admin.orderBy;
export const getNotificationsAdminRowsPerPage = (state) =>
  state.ui.notifications_admin.rowsPerPage;
export const getNotificationsAdminTextFilter = (state) =>
  state.ui.notifications_admin.textFilter;

export const getAdminFarms = (state) => getAdminFarmsApi(state);
export const isFetchingAdminFarms = (state) => isFetchingAdminFarmsApi(state);
export const getAdminFarmsTotalCount = (state) =>
  getAdminFarmsTotalCountApi(state);
