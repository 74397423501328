import debounce from "lodash/debounce";
import { platformModifierKeyOnly, singleClick } from "ol/events/condition";
import Feature from "ol/Feature";
import { Draw, Modify, Snap, DoubleClickZoom } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";

import Geometry, { GEOM_TYPES } from "./geometry/Geometry.service";
import CommonStyles, { STYLE_TYPES } from "./styles/CommonStyles.service";

export default class Interaction {
  constructor(map, transformOptions) {
    this.map = map;
    this.transformOptions = transformOptions;
  }

  setLayer(
    iaId,
    geomType,
    styleType = STYLE_TYPES.DRAWN,
    pointsStyleType = STYLE_TYPES.DRAWN,
    zIndex = 15,
  ) {
    const styles = [];
    if (styleType) {
      styles.push(CommonStyles.getStyle(geomType, styleType));
    }
    if (pointsStyleType) {
      styles.push(CommonStyles.getStyle(GEOM_TYPES.POINT, pointsStyleType));
    }
    this[`layer_${iaId}`] = new VectorLayer({
      source: new VectorSource(),
      style: styles,
      zIndex,
    });
    this.map.addLayer(this[`layer_${iaId}`]);
  }

  removeLayer(iaId) {
    this.map.removeLayer(this[`layer_${iaId}`]);
    this[`layer_${iaId}`] = null;
    this[`geom_${iaId}`] = null;
  }

  setDrawIA(
    onDrawStart,
    onDrawEnd,
    iaId,
    geomType,
    styleType = STYLE_TYPES.DRAWING,
    pointsStyleType = STYLE_TYPES.DRAWING,
  ) {
    if (!this[`ia_draw_${iaId}`]) {
      const styles = [];
      if (styleType) {
        styles.push(CommonStyles.getStyle(geomType, styleType));
      }
      if (pointsStyleType) {
        styles.push(CommonStyles.getStyle(GEOM_TYPES.POINT, pointsStyleType));
      }

      this[`ia_draw_${iaId}`] = new Draw({
        source: this[`layer_${iaId}`].getSource(),
        type: geomType === GEOM_TYPES.LINESTRING ? "LineString" : "Polygon",
        style: styles,
        condition: (evt) => !(platformModifierKeyOnly(evt) && singleClick(evt)),
      });
      this.map.addInteraction(this[`ia_draw_${iaId}`]);

      if (onDrawStart) {
        this[`ia_draw_start_${iaId}`] = this[`ia_draw_${iaId}`].on(
          "drawstart",
          (e) => onDrawStart(e),
        );
      }

      if (onDrawEnd) {
        this[`ia_draw_end_${iaId}`] = this[`ia_draw_${iaId}`].on(
          "drawend",
          (e) =>
            onDrawEnd(
              Geometry.featureToGeometry(e.feature, this.transformOptions),
              e,
            ),
        );
      }
    }
  }

  removeDrawIA(iaId) {
    if (this[`ia_draw_${iaId}`]) {
      this.map.removeInteraction(this[`ia_draw_${iaId}`]);
      this[`ia_draw_${iaId}`] = null;
      this[`ia_draw_start_${iaId}`] = null;
      this[`ia_draw_end_${iaId}`] = null;
    }
  }

  setModifyIA(
    onModifyChange,
    onModifyStart,
    onModifyEnd,
    iaId,
    geomType,
    styleType = STYLE_TYPES.DRAWN,
  ) {
    if (this[`layer_${iaId}`] && !this[`ia_modify_${iaId}`]) {
      this[`ia_modify_${iaId}`] = new Modify({
        source: this[`layer_${iaId}`].getSource(),
        style: CommonStyles.getStyle(geomType, styleType),
        deleteCondition: (evt) =>
          platformModifierKeyOnly(evt) && singleClick(evt),
      });
      this.map.addInteraction(this[`ia_modify_${iaId}`]);

      const debouncedOnModifyChange = debounce(onModifyChange, 10);

      if (onModifyChange) {
        this[`ia_modify_change_${iaId}`] = this[`layer_${iaId}`]
          .getSource()
          .on("changefeature", (e) => {
            debouncedOnModifyChange(this.getGeometry(iaId), e);
          });
      }

      if (onModifyStart) {
        // eslint-disable-next-line
        this[`ia_modify_active_${iaId}`] = this[`ia_modify_${iaId}`].overlay_
          .getSource()
          .on("addfeature", (e) => onModifyStart(e));
      }

      if (onModifyEnd) {
        // eslint-disable-next-line
        this[`ia_modify_inactive_${iaId}`] = this[`ia_modify_${iaId}`].overlay_
          .getSource()
          .on("removefeature", (e) => onModifyEnd(e));
      }
    }
  }

  removeModifyIA(iaId) {
    if (this[`ia_modify_${iaId}`]) {
      this.map.removeInteraction(this[`ia_modify_${iaId}`]);
      this[`ia_modify_${iaId}`] = null;
      this[`ia_modify_change_${iaId}`] = null;
      this[`ia_modify_active_${iaId}`] = null;
      this[`ia_modify_inactive_${iaId}`] = null;
    }
  }

  setSnapIA(iaId, features, geometry, snapOptions = {}) {
    if (features || geometry) {
      const snapSource = new VectorSource({
        features: features || [
          new Feature({
            geometry: Geometry.readGeometry(geometry, this.transformOptions),
          }),
        ],
      });
      this[`ia_snap_${iaId}`] = new Snap({
        source: snapSource,
        ...snapOptions,
      });
      this.map.addInteraction(this[`ia_snap_${iaId}`]);
    } else if (this[`layer_${iaId}`]) {
      const source = this[`layer_${iaId}`].getSource();
      this[`ia_snap_${iaId}`] = new Snap({ source });
      // snapping to the parcel itself; to be uncommented while adding parcel snap
      // this[`layer_${snapIaId}`].addFeature(new GeoJSON().readFeatures(geometry, this.transformOptions)[0]);
      this.map.addInteraction(this[`ia_snap_${iaId}`]);
    }
  }

  removeSnapIA(iaId) {
    if (this[`ia_snap_${iaId}`]) {
      this.map.removeInteraction(this[`ia_snap_${iaId}`]);
      this[`ia_snap_${iaId}`] = null;
    }
  }

  getGeometry(iaId) {
    const layer = this[`layer_${iaId}`];
    const geom = this[`geom_${iaId}`];
    // prefer exact geometry if set manually
    if (geom) {
      return geom;
    } else if (layer) {
      // assuming only one feature can be drawn
      const features = layer.getSource().getFeatures();
      if (features?.length) {
        return Geometry.featureToGeometry(features[0], this.transformOptions);
      }
    }
    return null;
  }

  setGeometry(iaId, geom, saveExact = false) {
    const geometry = Geometry.readGeometry(geom, this.transformOptions);
    this.clearLayer(iaId);
    this[`layer_${iaId}`].getSource().addFeature(
      new Feature({
        geometry,
      }),
    );
    if (saveExact) {
      this[`geom_${iaId}`] = geom;
    }
  }

  addFeature(iaId, feature) {
    this[`layer_${iaId}`].getSource().addFeature(feature);
  }

  removeFeature(iaId, featureId) {
    const source = this[`layer_${iaId}`].getSource();
    const feature = source.getFeatureById(featureId);
    source.removeFeature(feature);
  }

  clearLayer(iaId) {
    if (this[`layer_${iaId}`]) {
      this[`layer_${iaId}`].getSource().clear();
    }
    if (this[`geom_${iaId}`]) {
      this[`geom_${iaId}`] = null;
    }
  }

  setStyle(iaId, geomType, styleType, editable = true) {
    if (this[`layer_${iaId}`]) {
      const newStyle = editable
        ? [
            CommonStyles.getStyle(geomType, styleType),
            CommonStyles.getStyle(GEOM_TYPES.POINT, styleType),
          ]
        : [CommonStyles.getStyle(geomType, styleType)];
      this[`layer_${iaId}`].setStyle(newStyle);
    }
  }

  setDrawingErrorStyle(
    iaId,
    geomType = GEOM_TYPES.POLYGON,
    styleType = STYLE_TYPES.DRAWN_ERROR,
    editable = true,
  ) {
    this.setStyle(iaId, geomType, styleType, editable);
    if (this[`ia_modify_${iaId}`]) {
      // eslint-disable-next-line
      this[`ia_modify_${iaId}`].overlay_.setStyle(
        CommonStyles.getStyle(geomType, styleType),
      );
    }
  }

  removeDrawingErrorStyle(
    iaId,
    geomType = GEOM_TYPES.POLYGON,
    styleType = STYLE_TYPES.DRAWN,
  ) {
    this.setStyle(iaId, geomType, styleType);
    if (this[`ia_modify_${iaId}`]) {
      // eslint-disable-next-line
      this[`ia_modify_${iaId}`].overlay_.setStyle(
        CommonStyles.getStyle(geomType, styleType),
      );
    }
  }

  getDoubleClickZoomIA() {
    this.doubleClickZoomIAKey = this.map
      .getInteractions()
      .getArray()
      .find((ia) => ia instanceof DoubleClickZoom);
  }

  setDoubleClickZoomIA() {
    if (this.doubleClickZoomIAKey) {
      this.map.addInteraction(this.doubleClickZoomIAKey);
    }
  }

  removeDoubleClickZoomIA() {
    this.getDoubleClickZoomIA();
    if (this.doubleClickZoomIAKey) {
      this.map.removeInteraction(this.doubleClickZoomIAKey);
    }
  }

  finishDrawingInIA(iaId) {
    if (this[`ia_draw_${iaId}`]) {
      this[`ia_draw_${iaId}`].finishDrawing();
    }
  }
}
