import React, { FC, ReactNode, useMemo } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { NSAState } from "../../../../../../shared/api/agroevidence/parcels/parcels.types";

interface Props {
  defaultNSA?: NSAState;
  label: ReactNode;
  onChange: (items: NSAState) => void;
}

export const NSA_STATES = ["yes", "no"];

const NSASelector: FC<Props> = ({ defaultNSA = {}, label, onChange }) => {
  const intl = useIntl();

  const suggestions = useMemo(
    () =>
      NSA_STATES.map((s) => ({
        state: s === "yes",
        label: intl.formatMessage({ id: `common.${s}` }),
      })),
    [intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultNSA}
      id="nsa-filter"
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId="nsa-filter"
    />
  );
};

export default NSASelector;
