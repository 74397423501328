/**
 * Created by brasko on 20.7.2015.
 */

/*@ngInject*/
export default function FertilizersController(
  $controller,
  $scope,
  FertilizersResource,
  $state,
  farm,
) {
  var vm = this;

  vm.fertToAdd = null;
  vm.isCollapsed = true;

  vm.selectorState = "EMPTY";
  vm.onSelectorStateChange = onSelectorStateChange;
  vm.canCreate = canCreate;
  vm.canAdd = canAdd;

  vm.createFertilizer = createFertilizer;
  vm.addFertilizer = addFertilizer;
  vm.showAddForm = showAddForm;

  vm.clsCtrl = $controller("ClassifiersController", { $scope: $scope });
  vm.clsCtrl.setResource(wrapResource(FertilizersResource));

  vm.config = {
    edit: false,
    delete: true,
    create: false,
  };

  vm.clsCtrl.activate(farm.id, {
    page: 1,
    count: 10,
  });

  /**********************************************************************/

  function wrapResource(r) {
    var resource = {
      getItems: r.getFertilizers,
      deleteItems: r.deleteFertilizers,
    };
    return resource;
  }

  function onSelectorStateChange(status) {
    vm.selectorState = status;
    if (status === "CREATE") {
      createFertilizer();
    }
  }

  function createFertilizer() {
    $state.go("farm.active.classifiers.private.fertilizers.fertilizer", {
      fertilizer: vm.fertToAdd,
    });
  }

  function canCreate() {
    return vm.selectorState === "TYPING" || vm.selectorState === "CREATE";
  }

  function canAdd() {
    return vm.selectorState === "SELECTED";
  }

  function showAddForm() {
    vm.isCollapsed = false;
  }

  function addFertilizer(redirect) {
    if (!_.isObject(vm.fertToAdd) || _.isEmpty(vm.fertToAdd)) {
      return false;
    }

    return FertilizersResource.addFertilizerToFarm(
      vm.fertToAdd.legislativeCode,
    ).then(function (fertilizerId) {
      vm.isCollapsed = true;
      vm.fertToAdd = null;
      if (redirect) {
        $state.go("farm.active.classifiers.private.fertilizers.fertilizer", {
          fertilizer: fertilizerId,
        });
      } else {
        vm.clsCtrl.tableParams.reload();
      }
    });
  }
}
