import React, { Component } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field } from "redux-form";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfCheckbox from "../../../../../shared/components/form/CfCheckbox/CfCheckbox";
import ValidationStatusIcon from "../../../../../shared/components/specific/ValidationStatusIcon/ValidationStatusIcon";

const styles = (theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0px",
    backgroundColor: theme.palette.grey[100],
    padding: "8px 14px",
  },
  checkbox: {
    left: -10,
    float: "left",
    height: 42,
  },
  selector: {
    marginBottom: 15,
  },
  positionStart: {
    position: "absolute",
    right: "-40px",
    top: "20px",
  },
  divPostition: {
    position: "relative",
  },
  removeButton: {
    backgroundColor: theme.palette.grey[100],
    height: 26,
    width: 26,
    padding: 0,
    margin: 0,
  },
});

class SubtractableAreasList extends Component {
  componentDidUpdate(prevProps) {
    const { fields, parcelId } = this.props;
    if (
      fields.length > prevProps.fields.length &&
      prevProps.parcelId === parcelId
    ) {
      const newlyAddedType = _.difference(
        fields.getAll(),
        prevProps.fields.getAll(),
      );
      if (newlyAddedType[0].newlyAdded === true) {
        this.validateSaType(newlyAddedType[0], prevProps.fields.getAll());
      }
    }
    if (
      this.subtractionsNotEquals(fields.getAll(), prevProps.fields.getAll())
    ) {
      this.props.refreshTotalArea(fields.getAll());
    }
  }

  subtractionsNotEquals(nextSubtractions, subtractions) {
    let hashMap = {};
    hashMap = this.addKeys(subtractions, hashMap);
    const newLength = Object.keys(hashMap).length;
    hashMap = this.addKeys(nextSubtractions, hashMap);
    return Object.keys(hashMap).length !== newLength;
  }

  key = (obj, index) => obj.type + obj.value + obj.isUsed + index;

  addKeys = (subs, hashMap) => {
    const resultMap = hashMap;
    if (subs && subs.length) {
      for (let i = 0; i < subs.length; i += 1) {
        resultMap[this.key(subs[i], i)] = subs[i];
      }
    } else {
      resultMap[undefined] = subs;
    }
    return resultMap;
  };

  handleCheckboxChange = (index) => {
    const sa = this.props.fields.get(index);
    const allFields = this.props.fields.getAll() || [];
    if (!sa.isUsed) {
      this.validateSaType(sa, allFields);
    }
  };

  validateSaType = (typeToBeChecked, fields = []) => {
    if (typeToBeChecked.type !== "Absolute") {
      const checkedOfSameType = fields.filter(
        (sa) => typeToBeChecked.type === sa.type && sa.isUsed === true,
      );
      if (checkedOfSameType[0] !== undefined) {
        checkedOfSameType[0].isUsed = false;
      }
    }
  };

  renderType = (field) => (
    <div>
      <FormattedMessage
        id={`SubtractableAreaDialog.${field.type?.toLowerCase()}`}
      />
    </div>
  );

  renderValue = (field) => (
    <div>
      {field.type === "Absolute" ? (
        ""
      ) : (
        <span>
          <CfFormattedNumber value={field.value} />
          {" m"}
        </span>
      )}
    </div>
  );

  renderArea = (field) => (
    <div>
      <CfFormattedNumber value={field.area} />
      {" ha"}
    </div>
  );

  renderSubtractableAreaItem = (fieldName, index, field) => (
    <div className={this.props.classes.divPostition} key={fieldName}>
      <Field
        className={this.props.classes.checkbox}
        component={CfCheckbox}
        disabled={!this.props.editing}
        name={`${fieldName}.isUsed`}
        onChange={() => {
          this.handleCheckboxChange(index);
        }}
      />
      <Paper className={this.props.classes.paper} elevation={1} name="paper">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {this.renderType(field)}
          </Grid>
          <Grid item xs={3}>
            {this.renderValue(field)}
          </Grid>
          <Grid item xs={3}>
            {this.renderArea(field)}
          </Grid>
        </Grid>
        {this.props.editing && !field.isMaterial && (
          <IconButton
            aria-label="Remove item"
            className={this.props.classes.removeButton}
            disabled={!this.props.editing}
            size="small"
            onClick={() => {
              this.props.onDeleteSubtractionArea(field.type, field.value);
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Paper>
      {field.isMaterial && (
        <InputAdornment
          classes={{ positionStart: this.props.classes.positionStart }}
          position="start"
        >
          <ValidationStatusIcon id={fieldName} type="info">
            <FormattedMessage id="SubtractableAreaDialog.material.info" />
          </ValidationStatusIcon>
        </InputAdornment>
      )}
    </div>
  );

  render() {
    const { fields } = this.props;
    return (
      <div id="subtractable-areas-list">
        {fields.map((fieldName, index) =>
          this.renderSubtractableAreaItem(fieldName, index, fields.get(index)),
        )}
      </div>
    );
  }
}

SubtractableAreasList.propTypes = {
  fields: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  refreshTotalArea: PropTypes.func.isRequired,
  onDeleteSubtractionArea: PropTypes.func.isRequired,
};

SubtractableAreasList.defaultProps = {
  editing: false,
};

export default withStyles(styles)(injectIntl(SubtractableAreasList));
