import { RSAA } from "redux-api-middleware";

import * as types from "./sectionStatus.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";

export const getTelematicsStatusApi = () => ({
  [RSAA]: {
    endpoint: "account/status?",
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_TELEMATIC_STATUS_REQUEST,
      types.GET_TELEMATIC_STATUS_SUCCESS,
      types.GET_TELEMATIC_STATUS_ERROR,
    ] as RsaaTypes,
  },
});
