import { AnyAction } from "redux";

import * as commonTableTypes from "../../shared/actions/table.constants";

import { DrivesImportHistoryStateUI } from "./drivesImportHistory.types";

export const NAMESPACE = "drives-import-history-ui";
export const ROWS_PER_PAGE = 10;

const initState: DrivesImportHistoryStateUI = {
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
        };
      default:
        return state;
    }
  };
