import * as types from "./radarList.constants";

export default () =>
  (
    state = {
      isFetching: false,
      error: null,
      items: [],
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_RADAR_LIST:
        return {
          ...state,
          isFetching: true,
          error: null,
        };
      case types.GET_RADAR_LIST_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_RADAR_LIST_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
