import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./drivesImportHistory.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";

export type GetImportHistoryApiParams = {
  language: string;
  page: number;
  "per-page": number;
};

export type GetImportHistoryStateApiParams = {
  language: string;
};

export const getImportHistoryApi = (params: GetImportHistoryApiParams) => ({
  [RSAA]: {
    endpoint: `import?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_IMPORT_HISTORY_REQUEST,
      types.GET_IMPORT_HISTORY_SUCCESS,
      types.GET_IMPORT_HISTORY_ERROR,
    ] as RsaaTypes,
  },
});

export const resetImportHistoryApi = () => ({
  type: types.RESET_IMPORT_HISTORY,
});

export const getImportHistoryStateApi = (
  params: GetImportHistoryStateApiParams,
) => ({
  [RSAA]: {
    endpoint: `import/state?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_IMPORT_HISTORY_STATE_REQUEST,
      types.GET_IMPORT_HISTORY_STATE_SUCCESS,
      types.GET_IMPORT_HISTORY_STATE_ERROR,
    ] as RsaaTypes,
  },
});
