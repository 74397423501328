import { createColumns } from "../../../helpers/tableHelpers";

export enum COLUMN_NAMES {
  CODE = "code",
  NAME = "name",
  PRODUCTION_OPERATION = "operation",
  VALID_FROM = "validFrom",
  VALID_TO = "validTo",
  WORKING_WIDTH = "workingWidth",
}

export const columns = createColumns([
  {
    id: COLUMN_NAMES.CODE,
    primaryLabel: "Catalogues.table.equipments.column.code",
    sortable: true,
    style: { paddingLeft: 16 },
  },
  {
    id: COLUMN_NAMES.NAME,
    primaryLabel: "Catalogues.table.equipments.column.name",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.WORKING_WIDTH,
    primaryLabel: "Catalogues.table.equipments.column.workingWidth",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.PRODUCTION_OPERATION,
    primaryLabel: "Catalogues.table.equipments.column.productionOperation",
    style: { width: "300px" },
  },
  {
    id: COLUMN_NAMES.VALID_FROM,
    primaryLabel: "Catalogues.table.equipments.column.validFrom",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.VALID_TO,
    primaryLabel: "Catalogues.table.equipments.column.validTo",
    sortable: true,
  },
]);
