export default class Countries {
  static countryToFlag = (isoCode) =>
    typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397),
          )
      : isoCode;
}
