/**
 * Created by ondrejzvara on 9.12.15.
 */

import _ from "lodash";

export function isNull() {
  return function (input, patternT, patternF) {
    patternT = patternT ? patternT : "-";
    patternF = patternF ? patternF : input;
    return _.isUndefined(input) || _.isNull(input) ? patternT : patternF;
  };
}
