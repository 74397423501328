import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import {
  indexZoneColors,
  zonesColors,
} from "../../services/monitoringZonesColors";

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  colorRectangle: {
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: 6,
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

interface BioMonitoringZonesLegendProps {
  index: string;
}

const BioMonitoringZonesLegend: FC<BioMonitoringZonesLegendProps> = ({
  index,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.legendWrapper}>
      {zonesColors[index as indexZoneColors].map((zoneColor) => (
        <span
          className={classes.legendItem}
          key={`legend_zone_${zoneColor.id}`}
        >
          <span
            className={classes.colorRectangle}
            style={{
              backgroundColor: zoneColor.color,
              border: zoneColor.borderColor ? "1px solid #E0E0E0" : "",
            }}
          />
          <span className={classes.label}>
            <FormattedMessage id="BioMonitoring.history.zone" /> {zoneColor.id}
          </span>
        </span>
      ))}
    </div>
  );
};

export default BioMonitoringZonesLegend;
