/**
 * Created by ondrejzvara on 10.12.15.
 */

/*@ngInject*/

export default function PlainEntity() {
  function Plain(obj) {
    var plain = obj.plain();

    if (plain) {
      for (var key in plain) {
        if (plain.hasOwnProperty(key)) {
          this[key] = plain[key];
        }
      }
    }
  }

  return Plain;
}
