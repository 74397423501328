import * as types from "./devices.constants";

const initialState = {
  itemsAdmin: [],
  totalCountAdmin: 0,
  isFetchingAdmin: false,
  isFetchingAdminSyncWithPC: false,
  items: [],
  totalCount: 0,
  isFetching: false,
  itemsInactive: [],
  totalCountInactive: 0,
  isFetchingInactive: false,
  points: [],
  isFetchingPoints: false,
  errorPoints: {},
  item: {},
  itemValves: [],
  isFetchingDevice: false,
  isFetchingDeviceValves: false,
  isPatchingDevice: false,
  isPatchingAdminDevice: false,
  isFetchingIntervals: false,
  intervals: [],
  types: [],
  isFetchingDeviceTypes: false,
};

export default () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.GET_ADMIN_DEVICES_REQUEST:
        return { ...state, isFetchingAdmin: true };
      case types.GET_ADMIN_DEVICES_SUCCESS:
        return {
          ...state,
          itemsAdmin: action.payload,
          totalCountAdmin: Number(action.meta.headers.get("X-Total-Count")),
          isFetchingAdmin: false,
        };
      case types.GET_ADMIN_DEVICES_ERROR:
        return { ...state, isFetchingAdmin: false };

      case types.PATCH_ADMIN_DEVICE_REQUEST:
        return {
          ...state,
          isPatchingAdminDevice: true,
        };
      case types.PATCH_ADMIN_DEVICE_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isPatchingAdminDevice: false,
        };
      case types.PATCH_ADMIN_DEVICE_ERROR:
        return {
          ...state,
          isPatchingAdminDevice: false,
        };

      case types.RESET_ADMIN_DEVICES:
        return {
          ...state,
          itemsAdmin: [],
          totalCountAdmin: 0,
          isFetchingAdmin: false,
        };

      case types.POST_ADMIN_SYNC_PC_REQUEST:
        return {
          ...state,
          isFetchingAdminSyncWithPC: true,
        };
      case types.POST_ADMIN_SYNC_PC_SUCCESS:
        return {
          ...state,
          isFetchingAdminSyncWithPC: false,
        };
      case types.POST_ADMIN_SYNC_PC_ERROR:
        return {
          ...state,
          isFetchingAdminSyncWithPC: false,
        };

      case types.GET_DEVICE_TYPES_REQUEST:
        return {
          ...state,
          isFetchingDeviceTypes: true,
        };
      case types.GET_DEVICE_TYPES_SUCCESS:
        return {
          ...state,
          isFetchingDeviceTypes: false,
          types: action.payload,
        };
      case types.GET_DEVICE_TYPES_ERROR:
        return {
          ...state,
          isFetchingDeviceTypes: false,
        };
      case types.RESET_DEVICE_TYPES:
        return {
          ...state,
          types: [],
          isFetchingDeviceTypes: false,
        };

      case types.GET_DEVICES_REQUEST:
        return { ...state, isFetching: true };
      case types.GET_DEVICES_SUCCESS:
        return {
          ...state,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
          isFetching: false,
        };
      case types.GET_DEVICES_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case types.GET_INACTIVE_DEVICES_REQUEST:
        return { ...state, isFetchingInactive: true };
      case types.GET_INACTIVE_DEVICES_SUCCESS:
        return {
          ...state,
          itemsInactive: action.payload,
          totalCountInactive: Number(action.meta.headers.get("X-Total-Count")),
          isFetchingInactive: false,
        };
      case types.GET_INACTIVE_DEVICES_ERROR:
        return {
          ...state,
          isFetchingInactive: false,
        };

      case types.GET_DEVICE_REQUEST:
        return {
          ...state,
          isFetchingDevice: true,
        };
      case types.GET_DEVICE_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isFetchingDevice: false,
        };
      case types.GET_DEVICE_ERROR:
        return {
          ...state,
          isFetchingDevice: false,
        };

      case types.GET_DEVICE_VALVES_REQUEST:
        return {
          ...state,
          isFetchingDeviceValves: true,
        };
      case types.GET_DEVICE_VALVES_SUCCESS:
        return {
          ...state,
          itemValves: action.payload,
          isFetchingDeviceValves: false,
        };
      case types.GET_DEVICE_VALVES_ERROR:
        return {
          ...state,
          isFetchingDeviceValves: false,
        };

      case types.PATCH_DEVICE_REQUEST:
        return {
          ...state,
          isPatchingDevice: true,
        };
      case types.PATCH_DEVICE_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isPatchingDevice: false,
        };
      case types.PATCH_DEVICE_ERROR:
        return {
          ...state,
          isPatchingDevice: false,
        };

      case types.GET_IRRIGATIONS_POINTS:
        return {
          ...state,
          isFetchingPoints: true,
          errorPoints: {},
        };
      case types.GET_IRRIGATIONS_POINTS_SUCCESS:
        return {
          ...state,
          isFetchingPoints: false,
          points: action.payload,
        };
      case types.GET_IRRIGATIONS_POINTS_ERROR:
        return {
          ...state,
          isFetchingPoints: false,
          errorPoints: action.payload,
        };

      case types.RESET_IRRIGATIONS_POINTS:
        return {
          ...state,
          isFetchingPoints: false,
          errorPoints: {},
          points: [],
        };

      case types.GET_DEVICE_INTERVALS_REQUEST:
        return { ...state, isFetchingIntervals: true };
      case types.GET_DEVICE_INTERVALS_SUCCESS:
        return {
          ...state,
          intervals: action.payload,
          isFetchingIntervals: false,
        };
      case types.GET_DEVICE_INTERVALS_ERROR:
        return { ...state, isFetchingIntervals: false };

      default:
        return state;
    }
  };
