import React, { Component, Fragment } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import some from "lodash/some";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchUsers } from "../../actions/notificationList.actions";

import SelectionItem from "../../../shared/components/common/SelectionItem/SelectionItem";
import ListSelectorError from "../../../shared/components/form/ListSelectorError/ListSelectorError";

const styles = (theme) => ({
  userLabel: {
    color: theme.palette.grey[500],
  },
  userName: {
    color: theme.palette.grey[500],
    marginLeft: 8,
  },
  itemContent: {
    padding: "6px 0px",
  },
});

export class NotificationUsersControl extends Component {
  componentDidMount() {
    const { farmId, isEditing } = this.props;
    if (isEditing) {
      this.props.fetchUsers(farmId);
    }
  }

  componentDidUpdate(prevProps) {
    const { farmId, isEditing, touched, users } = this.props;
    if (!prevProps.isEditing && isEditing) {
      this.props.fetchUsers(farmId);
    }
    if (!isEqual(prevProps.users, users) && !isEmpty(touched)) {
      this.props.setFieldError(
        users?.length > 0 ? null : (
          <FormattedMessage id="NotificationUsersControl.validation" />
        ),
      );
    }
  }

  addNewUser = (user) => {
    const newUser = {
      userId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    this.props.handlePush(newUser);
  };

  render() {
    const { classes, error, handleRemove, isEditing, users, usersSuggestions } =
      this.props;

    return (
      <div>
        {!isEditing ? (
          <div className={classes.userLabel}>
            <FormattedMessage id="common.users" />:
          </div>
        ) : (
          <Fragment>
            <Autocomplete
              blurOnSelect={true}
              disabled={!isEditing}
              id="user-selector"
              multiple={true}
              name="userToAdd"
              options={usersSuggestions}
              value={[]}
              getOptionLabel={(option) =>
                `${option.email} ${option.firstName} ${option.lastName}`
              }
              onChange={(event, values) => {
                if (values?.length > 0) {
                  const newValue = values[0];
                  if (!some(users, (user) => user.email === newValue.email)) {
                    this.addNewUser(newValue);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage id="NotificationUsersControl.placeholder" />
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <span>{option.email}</span>
                    <span className={classes.userName}>
                      {option.firstName} {option.lastName}
                    </span>
                  </div>
                </li>
              )}
            />
            {error && <ListSelectorError error={error} />}
          </Fragment>
        )}
        <div>
          {users.map((user, idx) => (
            <SelectionItem
              editing={isEditing}
              key={user.email}
              onRemoveItem={() => {
                handleRemove(idx);
              }}
            >
              <div className={classes.itemContent}>
                <span>{user.email}</span>
                <span className={classes.userName}>
                  {user.firstName} {user.lastName}
                </span>
              </div>
            </SelectionItem>
          ))}
        </div>
      </div>
    );
  }
}

NotificationUsersControl.propTypes = {
  users: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  handleRemove: PropTypes.func.isRequired,
  handlePush: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.object,
  fetchUsers: PropTypes.func.isRequired,
  usersSuggestions: PropTypes.array.isRequired,
  farmId: PropTypes.string.isRequired,
  setFieldError: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
};

NotificationUsersControl.defaultProps = {
  isEditing: false,
  error: undefined,
};

const mapStateToProps = (state) => ({
  usersSuggestions: state.api.users.items,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUsers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationUsersControl));
