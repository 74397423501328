module.exports = function (i) {
  const l = "dataLayer";
  const s = "script";
  const d = document;
  const w = window;

  w[l] = w[l] || [];

  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });

  function fn() {
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != "dataLayer" ? `&l=${l}` : "";

    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }

  fn();
};
