import React, { Component } from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import SensorRainfallSelector from "../../components/SensorRainfallSelector/SensorRainfallSelector";

class SensorsAdminNodeEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorType:
        this.props.node.sensors?.find((s) => s.feature === "Rainfall")?.type ||
        "default",
    };
  }

  setSensorType = (sensorType) => {
    this.setState({
      sensorType,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setSensorType("default");
    onClose();
  };

  handleAccept = () => {
    const { onAccept } = this.props;
    onAccept(this.state.sensorType);
  };

  render() {
    const { opened } = this.props;
    const { sensorType } = this.state;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.edit" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={this.handleAccept}
        onCancel={this.handleClose}
        onClose={this.handleClose}
        opened={opened}
        title={<FormattedMessage id="SensorsAdmin.editNode" />}
      >
        <SensorRainfallSelector
          onSelect={this.setSensorType}
          val={sensorType}
        />
      </CfDialog>
    );
  }
}

SensorsAdminNodeEditDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired,
};

SensorsAdminNodeEditDialog.defaultProps = {
  opened: false,
};

export default SensorsAdminNodeEditDialog;
