import Feature from "../Feature.service";

import CommonLabelStyle from "./CommonLabelStyle.service";
import CommonPolygonStyle from "./CommonPolygonStyle.service";

export default class MergeStyles {
  static getParcelStyle(
    feature,
    hoveredId,
    selectedIds,
    availableIds,
    reachableIds,
    countryCode,
  ) {
    if (!Feature.isWithoutCrop(feature)) {
      return CommonPolygonStyle.getDisabled();
    }

    if (Feature.isCzUser(countryCode) && Feature.oneParcelLpis(feature)) {
      return CommonPolygonStyle.getDisabled();
    }

    const id = feature.get("id");
    if (selectedIds.includes(id)) {
      return CommonPolygonStyle.getSelected();
    }

    if (availableIds.includes(id)) {
      if (id === hoveredId) {
        return CommonPolygonStyle.getHovered();
      }

      return CommonPolygonStyle.getAvailable();
    }

    if (reachableIds.includes(id)) {
      return CommonPolygonStyle.getReachable();
    }

    if (selectedIds.length < 1 && id === hoveredId) {
      return CommonPolygonStyle.getHovered();
    }

    return selectedIds.length > 0
      ? CommonPolygonStyle.getDisabled()
      : CommonPolygonStyle.getDefault();
  }

  static getLabelStyle(
    feature,
    resolution,
    hoveredId,
    selectedIds,
    availableIds,
    reachableIds,
    countryCode,
  ) {
    if (resolution < 50) {
      const style = MergeStyles.resolveLabelStyle(
        feature,
        selectedIds,
        availableIds,
        countryCode,
      );
      style
        .getText()
        .setText(CommonLabelStyle.getLabelContent(feature, resolution));
      return style;
    }
    return null;
  }

  static resolveLabelStyle(feature, selectedIds, availableIds, countryCode) {
    if (!Feature.isWithoutCrop(feature)) {
      return CommonLabelStyle.getDisabled();
    }

    if (Feature.isCzUser(countryCode) && Feature.oneParcelLpis(feature)) {
      return CommonLabelStyle.getDisabled();
    }

    const id = feature.get("id");
    if (selectedIds.includes(id)) {
      return CommonLabelStyle.getSelected();
    }

    if (selectedIds.length > 0 && !availableIds.includes(id)) {
      return CommonLabelStyle.getDisabled();
    }

    return CommonLabelStyle.getDefault();
  }
}
