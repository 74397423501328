import React, { useContext } from "react";

import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import invariant from "tiny-invariant";

import { getTelematicsAggregationsBulkEditMode } from "../../selectors/telematicsAggregations.selectors";
import { selectDateRange } from "../../selectors/telematicsTabs.selectors";

import { setDateRange } from "../../actions/telematicsTabs.actions";

import { TELEMATICS_URLS } from "../../telematics.constants";

import { Action } from "../../reducer/telematicsTabs.reducer";

import CfPrimaryTab from "../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import DateRangeSelector from "../../../shared/components/common/DateRangeSelector/DateRangeSelector";
import FabWithOptions from "../../../shared/components/common/FabWithOptions/FabWithOptions";
import {
  PageHeaderV2,
  desktopItemGridSize,
  mobileItemGridSize,
} from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { useLanguageChangeRefresh } from "../../../shared/hooks/useLanguageChangeRefresh";
import { useIsMobile } from "../../../shared/hooks/useWidth";
import DrivesImportHistory from "../../components/DrivesImportHistory/DrivesImportHistory";
import TelematicsExport from "../../components/TelematicsExport/TelematicsExport";
import TosSendButton from "../../components/TosSendButton/TosSendButton";
import { TelematicsContext } from "../Telematics/Telematics";

import { useTelematicsLogger } from "./useTelematicsLogger";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { EconomicSystem } from "../../../shared/api/telematics/telematics.types";

const tabs = ["machines", "drivers", "logbook"] as const;

type Tab = (typeof tabs)[number];
type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  bulkEditMode: boolean;
  langId: string;
};
type Props = ReduxProps & OwnProps;

const TelematicsTabs = ({
  bulkEditMode,
  children,
  dateFilter,
  langId,
  setDateRange,
}: React.PropsWithChildren<Props>) => {
  const { pathname } = useLocation();
  const _currentTab = pathname.split("/").pop();
  invariant(_currentTab, `currentTab should be one of ${tabs}`);
  const currentTab = _currentTab as unknown as Tab;
  const history = useHistory();
  const classes = useStyles();
  const { economicSystem, economicSystemDate } = useContext(TelematicsContext);
  const hasTosEconomicSystem =
    economicSystem === EconomicSystem.TOS &&
    moment(economicSystemDate).isBefore(moment());
  const selectedTabIndex = getSelectedTabIndex(currentTab);
  const { farmId } = useParams<Record<"farmId", string>>();
  const isMobile = useIsMobile();

  useTelematicsLogger(currentTab);
  useLanguageChangeRefresh(langId);

  const handleTabClick = (tab: Tab) => () => {
    let url = `/farm/${farmId}/`;

    switch (tab) {
      case "machines": {
        url += TELEMATICS_URLS.machines;
        break;
      }
      case "drivers": {
        url += TELEMATICS_URLS.drivers;
        break;
      }
      case "logbook": {
        url += TELEMATICS_URLS.logbook;
        break;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = tab;
        throw new Error(`Unexpected tab: ${tab}. Expected one of ${tabs}.`);
      }
    }

    history.push(url);
  };

  const handleCreateNewAggregation = () => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}/new`);
  };

  const handleCreateHandworkAggregation = () => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.handwork}/new`);
  };

  const DateRangeSelectorJsx = (
    <DateRangeSelector
      dateFrom={dateFilter.dateFrom}
      dateTo={dateFilter.dateTo}
      langId={langId}
      setDate={setDateRange}
    />
  );

  const ButtonsJsx = (
    <Box columnGap={2} display="flex">
      <Box alignItems="center" columnGap={1} display="flex">
        {currentTab === "drivers" && hasTosEconomicSystem ? (
          <TosSendButton />
        ) : null}
        <TelematicsExport selectedTelematicsTab={currentTab} />
        <DrivesImportHistory langId={langId} />
      </Box>
      <div className={classes.fabContainer}>
        <FabWithOptions
          options={[
            {
              translId: "Telematics.addHandwork",
              action: handleCreateHandworkAggregation,
            },
            {
              translId: "Telematics.create",
              action: handleCreateNewAggregation,
            },
          ]}
        />
      </div>
    </Box>
  );

  const HeadingJsx = (
    <PageHeading
      dataTest="telematics-heading"
      value={<FormattedMessage id="common.telematics" />}
    />
  );

  return (
    <>
      <div className={classes.container}>
        <PageHeaderV2 paddingBottom={2} paddingLeft={2} paddingTop={2}>
          {isMobile ? (
            <>
              <PageHeaderV2.Heading md={12} sm={12} xs={12}>
                {HeadingJsx}
              </PageHeaderV2.Heading>
              <PageHeaderV2.Item {...mobileItemGridSize}>
                {DateRangeSelectorJsx}
              </PageHeaderV2.Item>
              <PageHeaderV2.FlexItem {...mobileItemGridSize}>
                {ButtonsJsx}
              </PageHeaderV2.FlexItem>
            </>
          ) : (
            <>
              <PageHeaderV2.Item {...desktopItemGridSize}>
                {DateRangeSelectorJsx}
              </PageHeaderV2.Item>
              <PageHeaderV2.Heading md={4} sm={12} xs={12}>
                {HeadingJsx}
              </PageHeaderV2.Heading>
              <PageHeaderV2.FlexItem {...desktopItemGridSize}>
                {ButtonsJsx}
              </PageHeaderV2.FlexItem>
            </>
          )}
        </PageHeaderV2>
        <div className={classes.tabsContainer}>
          <CfPrimaryTabs centered tabValue={selectedTabIndex}>
            <CfPrimaryTab
              data-test="telematics-machines"
              disabled={bulkEditMode}
              label={<FormattedMessage id="TelematicsTabs.tabs.machines" />}
              onClick={handleTabClick("machines")}
            />
            <CfPrimaryTab
              data-test="telematics-drivers"
              label={<FormattedMessage id="TelematicsTabs.tabs.drivers" />}
              onClick={handleTabClick("drivers")}
            />
            <CfPrimaryTab
              data-test="telematics-logbook"
              disabled={bulkEditMode}
              label={<FormattedMessage id="TelematicsTabs.tabs.logbook" />}
              onClick={handleTabClick("logbook")}
            />
          </CfPrimaryTabs>
        </div>
      </div>
      {children}
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
  dateFilter: selectDateRange(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    {
      setDateRange,
    },
    dispatch,
  );

const getSelectedTabIndex = (tab: Tab): number =>
  tabs.findIndex((k) => k === tab);

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
  actionButton: {
    alignItems: "center",
    display: "flex",
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  fabContainer: {
    marginRight: theme.spacing(2),
  },
}));

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TelematicsTabs);
export type { Tab };
