import React from "react";

import Signal0Icon from "@mui/icons-material/SignalCellular0Bar";
import Signal1Icon from "@mui/icons-material/SignalCellular1Bar";
import Signal2Icon from "@mui/icons-material/SignalCellular2Bar";
import Signal3Icon from "@mui/icons-material/SignalCellular3Bar";
import Signal4Icon from "@mui/icons-material/SignalCellular4Bar";
import SignalNullIcon from "@mui/icons-material/SignalCellularNull";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  icon: {
    height: 20,
    color: theme.palette.grey[500],
  },
});

function SensorsSignalInfo(props) {
  const { classes, showTooltip, signal } = props;
  const signalIcons = {
    0: <Signal0Icon className={classes.icon} />,
    1: <Signal1Icon className={classes.icon} />,
    2: <Signal2Icon className={classes.icon} />,
    3: <Signal3Icon className={classes.icon} />,
    4: <Signal4Icon className={classes.icon} />,
  };

  const nullIcon = (
    <Tooltip placement="bottom" title={<FormattedMessage id="common.noData" />}>
      <SignalNullIcon className={classes.icon} />
    </Tooltip>
  );

  const hasData = signal >= 0 && signal <= 4 && signal !== null;

  const withTooltip = hasData ? (
    <Tooltip
      placement="bottom"
      title={
        <FormattedMessage id={`common.sensors-signal-strength-${signal}`} />
      }
    >
      {signalIcons[signal]}
    </Tooltip>
  ) : (
    nullIcon
  );

  const noTooltip = hasData ? signalIcons[signal] : nullIcon;

  return showTooltip ? withTooltip : noTooltip;
}

SensorsSignalInfo.propTypes = {
  classes: PropTypes.object,
  signal: PropTypes.number,
  showTooltip: PropTypes.bool,
};

SensorsSignalInfo.defaultProps = {
  classes: {},
  signal: 5,
  showTooltip: false,
};

export default withStyles(styles)(SensorsSignalInfo);
