import React, { FC, useMemo } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  indexZoneColors,
  zonesColors,
} from "../../services/monitoringZonesColors";

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    alignItems: "center",
  },
  legendImage: {
    margin: "0px 5px",
    width: 107,
    height: 8,
    borderRadius: 10,
    border: "1px solid #E4E4E4",
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

const getIndexGradient = (index: string) =>
  `linear-gradient(90deg,${zonesColors[index as indexZoneColors].map(
    (item, i) => `${item.color} ${i * 10 + 5}%`,
  )})`;

interface BioMonitoringLegendProps {
  index: string;
}

const BioMonitoringLegend: FC<BioMonitoringLegendProps> = ({ index }) => {
  const classes = useStyles();
  const gradient = useMemo(() => getIndexGradient(index), [index]);

  return (
    <div className={classes.legendWrapper}>
      <span className={classes.label}>Min</span>{" "}
      <span className={classes.legendImage} style={{ background: gradient }} />{" "}
      <span className={classes.label}>Max</span>
    </div>
  );
};

export default BioMonitoringLegend;
