import Switch from "@mui/material/Switch";

import { createComponent } from "../../reduxFormUtils";

export default createComponent(
  Switch,
  ({
    checked,
    input: { onChange, value, ...restInputProps },
    input,
    meta,
    ...restProps
  }) => ({
    ...restInputProps,
    ...restProps,
    onChange,
    checked: !!value,
  }),
);
