import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";

import { createComponent } from "../../reduxFormUtils";

const style = {
  root: {
    display: "inline-block",
    width: "100%",
    margin: "0",
  },
  label: {
    fontSize: "16px",
    float: "left",
    display: "flex",
    alignItems: "center",
    height: "45px",
    width: "calc(100% - 48px)",
    color: "inherit",
  },
};

export default withStyles(style)(
  createComponent(FormControlLabel, ({ label = "", ...props }) => ({
    label: <span>{label}</span>,
    ...props,
  })),
);
