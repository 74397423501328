import { AnyAction } from "redux";

import * as types from "./eagriAuth.constants";

import { EagriAuthState } from "./eagriAuth.reducer.types";

const initialState: EagriAuthState = {
  isFetching: false,
  apiError: null,
  farmName: null,
  isEagriAuthenticated: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.SET_EAGRI_AUTH:
        return {
          ...state,
          isFetching: true,
          apiError: null,
        };
      case types.SET_EAGRI_AUTH_SUCCESS:
        return {
          ...state,
          isFetching: false,
          farmName: action.payload?.farmName || "",
          isEagriAuthenticated: true,
        };
      case types.SET_EAGRI_AUTH_ERROR:
        return {
          ...state,
          isFetching: false,
          farmName: null,
          isEagriAuthenticated: false,
          apiError: action.payload,
        };

      case types.GET_EAGRI_FARM_NAME:
        return {
          ...state,
          farmName: null,
          isFetching: true,
        };
      case types.GET_EAGRI_FARM_NAME_SUCCESS:
        return {
          ...state,
          isFetching: false,
          farmName: action.payload?.farmName || "",
          isEagriAuthenticated: true,
        };
      case types.GET_EAGRI_FARM_NAME_FAILURE:
        return {
          ...state,
          isFetching: false,
          farmName: null,
          isEagriAuthenticated: false,
        };
      default:
        return state;
    }
  };
