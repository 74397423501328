import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SatelliteIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 28 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M16,12.26,12.27,16a.86.86,0,0,1-1.2,0L7.2,12.21a.85.85,0,0,1,0-1.2L11,7.25a.86.86,0,0,1,1.21,0l3.86,3.86A.85.85,0,0,1,16,12.26Z" />
      <path d="M5.68,14.74,4.19,15.81a10,10,0,0,0-1.22,1L.24,19.57a.85.85,0,0,0,0,1.18l2.32,2.31a.83.83,0,0,0,1.18,0l2.72-2.72a9.4,9.4,0,0,0,1-1.23l1.07-1.49a.83.83,0,0,0-.09-1.06L6.75,14.83A.85.85,0,0,0,5.68,14.74Z" />
      <path d="M17.63,8.56,19.12,7.5a9.4,9.4,0,0,0,1.23-1l2.72-2.72a.85.85,0,0,0,0-1.18L20.75.24a.85.85,0,0,0-1.18,0L16.85,3a10,10,0,0,0-1,1.22L14.75,5.68a.82.82,0,0,0,.09,1.07l1.72,1.73A.83.83,0,0,0,17.63,8.56Z" />
      <path d="M19.22,19.21a10.16,10.16,0,0,1-6.44,3l-.11-3a7.26,7.26,0,0,0,4.46-2.1,7.17,7.17,0,0,0,2.09-4.45l3,.12A10.2,10.2,0,0,1,19.22,19.21Z" />
      <path d="M23.56,23.54A15.1,15.1,0,0,1,13,28l-.11-3a12.19,12.19,0,0,0,8.56-3.59A12.09,12.09,0,0,0,25,12.89L28,13A15,15,0,0,1,23.56,23.54Z" />
    </svg>
  </SvgIcon>
);

export default SatelliteIcon;
