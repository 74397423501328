import React from "react";

import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";

import { getLocalizedDateString } from "../../../shared/misc/timeHelpers";
import { COLOR_ERROR } from "../../../theme";

import { FarmUpdateChange } from "../../../shared/api/agroevidence/agroevidence.types";

interface IUpdateLpisAvailableUpdatesDialogProps {
  availableUpdatesNewestDate: Date;
  availableUpdatesValidityDate: string;
  filteredChanges: FarmUpdateChange[];
  onClose: () => void;
}

export const UpdateLpisAvailableUpdatesDialog = ({
  availableUpdatesNewestDate,
  availableUpdatesValidityDate,
  filteredChanges,
  onClose,
}: IUpdateLpisAvailableUpdatesDialogProps) => {
  const classes = useStyles();

  const grouped: Record<string, string[]> = {};
  filteredChanges.forEach(({ blockNumber, type }) => {
    if (!grouped[type]) {
      grouped[type] = [];
    }
    grouped[type].push(blockNumber);
  });

  return (
    <Dialog data-test="update-lpis-available-update-dialog" open>
      <Scrollbars autoHeight autoHeightMax="80vh">
        <DialogContent className={classes.dialogContent}>
          <span>
            <FormattedMessage
              id="UpdateLPIS.availableUpdate.newestDate"
              values={{
                date: getLocalizedDateString(
                  availableUpdatesNewestDate.toISOString(),
                ),
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </span>

          {filteredChanges &&
            Object.entries(grouped).map(([type, blockNumbers]) => (
              <div key={type}>
                <FormattedMessage
                  id={`UpdateLPIS.availableUpdate.${type}`}
                  values={{
                    b: (chunks: string) => <b>{chunks}</b>,
                  }}
                />
                {` ${blockNumbers.join(", ")}`}
              </div>
            ))}

          <span className={classes.warning}>
            <FormattedMessage id="UpdateLPIS.availableUpdate.newestDate_warning" />
          </span>
          <span>
            <FormattedMessage
              id="UpdateLPIS.availableUpdate.validityDate"
              values={{
                date: getLocalizedDateString(availableUpdatesValidityDate, [
                  "L",
                  "LT",
                ]),
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </span>
        </DialogContent>
      </Scrollbars>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button color="primary" id="close" onClick={onClose} variant="text">
            <FormattedMessage id="common.close" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  warning: {
    color: COLOR_ERROR.main,
  },
}));
