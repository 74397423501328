import React from "react";

import ShowChart from "@mui/icons-material/ShowChart";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  noDataWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.grey[100],
  },
  noDataIcon: {
    width: 30,
    height: 30,
  },
});

const CfNoData = (props) => {
  const { classes, translationId } = props;
  return (
    <div className={classes.noDataWrapper}>
      <div>
        <ShowChart className={classes.noDataIcon} />
      </div>
      <div>
        <FormattedMessage id={translationId} />
      </div>
    </div>
  );
};

CfNoData.propTypes = {
  classes: PropTypes.object.isRequired,
  translationId: PropTypes.string,
};

CfNoData.defaultProps = {
  translationId: "CfNoData.noDataAvailable",
};

export default withStyles(styles)(CfNoData);
