import React, { FC, useState } from "react";

import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";

interface Props {
  onHide: () => void;
}

const HideButton: FC<Props> = ({ onHide }) => {
  const [opened, setIsOpened] = useState(false);

  const onClose = () => setIsOpened(false);

  const onSubmit = () => {
    onClose();
    onHide();
  };

  const onHideBtnClick = () => setIsOpened(true);

  return (
    <Grid item>
      <Button color="error" onClick={onHideBtnClick} variant="contained">
        <FormattedMessage id="common.delete" />
      </Button>

      <CfDialog
        acceptText={<FormattedMessage id="common.yes" />}
        cancelText={<FormattedMessage id="common.no" />}
        onAccept={onSubmit}
        onCancel={onClose}
        onClose={onClose}
        opened={opened}
        title={
          <FormattedMessage id="Catalogues.fertilizers.detail.hideModal.title" />
        }
      >
        <div>
          <FormattedMessage id="Catalogues.fertilizers.detail.hideModal.context" />
        </div>
      </CfDialog>
    </Grid>
  );
};

export default HideButton;
