import * as types from "./manager.constants";

import {
  getFarmsApi,
  getCountriesApi,
  resetFarmsApi,
  resetCountriesApi,
} from "../../../../shared/api/agroevidence/farms/farms.api";

export const getFarmsByName = (textFilter, params) => (dispatch) =>
  dispatch(getFarmsApi({ search: textFilter, ...params }));

export const getCountries = (params) => (dispatch) =>
  dispatch(getCountriesApi({ ...params }));

export const resetFarms = () => (dispatch) => {
  dispatch(resetFarmsApi());
};

export const resetCountries = () => (dispatch) => {
  dispatch(resetCountriesApi());
};

export const setDialogOpen = (dialogOpen) => ({
  type: types.SET_DIALOG_OPEN,
  dialogOpen,
});

export const setActionButtonEnabled = (actionButtonEnabled) => ({
  type: types.SET_ACTION_BUTTON_ENABLED,
  actionButtonEnabled,
});

export const setActionButtonHidden = (actionButtonHidden) => ({
  type: types.SET_ACTION_BUTTON_HIDDEN,
  actionButtonHidden,
});

export const setSensorsTabActive = (sensorsTabActive) => ({
  type: types.SET_SENSOR_TAB_ACTIVE,
  sensorsTabActive,
});

export const setIrrigationTabActive = (irrigationTabActive) => ({
  type: types.SET_IRRIGATION_TAB_ACTIVE,
  irrigationTabActive,
});
