import React, { Component } from "react";

import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { getBufferType } from "../../selectors/editor.selectors";

import * as stages from "../../constants/stages.constants";
import * as tools from "../../constants/tools.constants";

import { BUFFER_TYPES } from "../../actions/buffer/bufferTypes";
import SnackbarText from "../../components/SnackbarText/SnackbarText";

const tool = tools.BUFFER;

export class BufferHint extends Component {
  getHint = (stage, data, isPartialBuffer) => {
    switch (stage) {
      case stages.STAGE_1:
        return (
          <SnackbarText color="light">
            <FormattedMessage
              id={`hint.${tool}_${stage}_PT1`}
              values={{
                pt2: (
                  <SnackbarText color="dark">
                    <FormattedMessage id={`hint.${tool}_${stage}_PT2`} />
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );
      case stages.STAGE_2:
      case stages.STAGE_3:
        return (
          <SnackbarText color="light">
            <FormattedMessage id={`hint.${tool}_${stages.STAGE_2}`} />
            {isPartialBuffer && (
              <span>
                {" "}
                <FormattedMessage
                  id={`hint.${tool}_${stages.STAGE_2}_ADDITIONAL`}
                />
              </span>
            )}
          </SnackbarText>
        );
      case stages.STAGE_4:
        return (
          <SnackbarText color="dark">
            <FormattedMessage
              id={`hint.${tool}_${stage}`}
              values={{
                parcel: (
                  <SnackbarText color="light">
                    {data.main.localName}
                  </SnackbarText>
                ),
                parcels: (
                  <SnackbarText color="light">
                    {data.results.map((p) => p.localName).join(", ")}
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );

      default:
        return null;
    }
  };

  render() {
    const { bufferType, data, stage } = this.props;
    const isPartialBuffer = bufferType === BUFFER_TYPES.PARTIAL;
    return this.getHint(stage, data, isPartialBuffer);
  }
}

const mapStateToProps = (state) => ({
  bufferType: getBufferType(state),
});

BufferHint.propTypes = {
  stage: PropTypes.string,
  bufferType: PropTypes.string.isRequired,
  data: PropTypes.object,
};

BufferHint.defaultProps = {
  stage: null,
  data: null,
};

export default injectIntl(connect(mapStateToProps)(BufferHint));
