export const notificationFormFields = {
  sensor: "sensor",
  feature: "feature",
  conditionType: "conditionType",
  conditionValue: "conditionValue",
  description: "description",
  users: "users",
};

export const notificationFormInitialValues = {
  [notificationFormFields.sensor]: null,
  [notificationFormFields.feature]: "",
  [notificationFormFields.conditionType]: "",
  [notificationFormFields.conditionValue]: "",
  [notificationFormFields.description]: "",
  [notificationFormFields.users]: [],
};

export const mapNotificationFormValues = (rule, nodeLocation) => {
  let ruleNodeLocation = rule.sensors[0];
  if (rule.sensors[0].lastLocationId.toString() === nodeLocation.id) {
    ruleNodeLocation = nodeLocation;
  }

  return {
    [notificationFormFields.sensor]: ruleNodeLocation,
    [notificationFormFields.feature]: rule.conditions[0].feature,
    [notificationFormFields.conditionType]: rule.conditions[0].operator,
    [notificationFormFields.conditionValue]: rule.conditions[0].value,
    [notificationFormFields.description]: rule.name,
    [notificationFormFields.users]: rule.users,
  };
};
