import React from "react";

import { LANG_KEYS } from "../../../../../../shared/lang/lang.constants";

import BaseContainer from "../../../../../../shared/containers/BaseContainer/BaseContainer";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import translationsRU from "../../lang/locale-ru-UA.json";
import translationsSR from "../../lang/locale-sr-LATN-RS.json";
import ParcelDetail from "../ParcelDetail/ParcelDetail";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
};

const ParcelDetailWrapper = (props) => {
  const { farm, langId } = props;

  return (
    <BaseContainer langId={langId} translations={translations}>
      <ParcelDetail countryCode={farm.customer.countryCode} {...props} />
    </BaseContainer>
  );
};

export default ParcelDetailWrapper;
