import React from "react";

import { Tooltip } from "@mui/material";
import moment from "moment";

import * as durations from "../constants/durations.constants";

// https://github.com/openlayers/openlayers/pull/10429
// https://github.com/openlayers/openlayers/issues/10273
export const disableHTMLCanvasImageSmoothing = () => {
  HTMLCanvasElement.prototype.__oldGetContext =
    HTMLCanvasElement.prototype.getContext;

  HTMLCanvasElement.prototype.getContext = function (type, options) {
    const context = this.__oldGetContext(type, options);
    if (type === "2d") {
      context.imageSmoothingEnabled = false;
      context.webkitImageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.msImageSmoothingEnabled = false;
    }
    return context;
  };
};

export const enableHTMLCanvasImageSmoothing = () => {
  if (HTMLCanvasElement.prototype.__oldGetContext) {
    HTMLCanvasElement.prototype.getContext =
      HTMLCanvasElement.prototype.__oldGetContext;
    delete HTMLCanvasElement.prototype.__oldGetContext;
  }
};

export const checkEmailFormat = (email) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getDateTo = (duration, dateFrom) => {
  if (!dateFrom) return undefined;

  switch (duration) {
    case durations.PT24H:
      return moment(dateFrom).endOf("day").toISOString();
    case durations.P7D:
      return moment(dateFrom).endOf("week").toISOString();
    case durations.P1M:
      return moment(dateFrom).endOf("month").toISOString();
    case durations.P1Y:
      return moment(dateFrom).endOf("year").toISOString();
    default:
      return moment(dateFrom).endOf("day").toISOString();
  }
};

export const generateRandomId = (length) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let randomId = "";
  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }
  return randomId;
};

export const tooltipForDisabled = (disabled, title, component) => {
  if (disabled) {
    return (
      <Tooltip title={title}>
        <span>{component}</span>
      </Tooltip>
    );
  } else {
    return component;
  }
};

export function insertArray(array, startIndex, insertedArray) {
  const part1 = array.slice(0, startIndex);
  const part2 = array.slice(startIndex + insertedArray.length);
  return [...part1, ...insertedArray, ...part2];
}
