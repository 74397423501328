import React, { Component, Fragment } from "react";

import DialogContentText from "@mui/material/DialogContentText";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";

const styles = (theme) => ({
  menuItem: {
    color: theme.palette.error.main,
  },
});

class NodeDeactivation extends Component {
  constructor(props) {
    super(props);

    this.classes = props.classes;
    this.state = {
      isDialogOpened: false,
    };
  }

  handleDeactivateClick = () => {
    this.setState({ isDialogOpened: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpened: false });
  };

  handleDialogConfirm = () => {
    this.handleDialogClose();
    const zeroGeometry = {
      coordinates: [0.0, 0.0, 0.0],
      type: "Point",
    };
    this.props.deactivate(
      this.props.nodeLocation.node.id,
      this.props.nodeLocation.node.name,
      zeroGeometry,
      this.props.match.params.farmId,
      this.props.nodeLocation.id,
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MenuItem
          aria-label="deactivate-sensor"
          className={classes.menuItem}
          onClick={this.handleDeactivateClick}
        >
          <FormattedMessage id="common.deactivate" />
        </MenuItem>
        <CfDialog
          acceptText={<FormattedMessage id="common.yes" />}
          cancelText={<FormattedMessage id="common.no" />}
          data-test="node-popup"
          maxWidth="sm"
          onAccept={this.handleDialogConfirm}
          onCancel={this.handleDialogClose}
          onClose={this.handleDialogClose}
          opened={this.state.isDialogOpened}
          title={
            <FormattedMessage id="NodeDeactivation.deactivateSensorDialogTitle" />
          }
        >
          <DialogContentText component="span">
            <FormattedMessage
              id="NodeDeactivation.deactivateSensorDialogMessage"
              values={{
                p: (chunks) => <p>{chunks}</p>,
                div: (chunks) => <div>{chunks}</div>,
              }}
            />
          </DialogContentText>
        </CfDialog>
      </Fragment>
    );
  }
}

NodeDeactivation.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object.isRequired,
  match: PropTypes.object,
  deactivate: PropTypes.func.isRequired,
};

NodeDeactivation.defaultProps = {
  match: {},
};

export default withStyles(styles)(NodeDeactivation);
