import React, { ReactNode, useEffect, useMemo } from "react";

import { useIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCataloguesTypes,
  getIsFetchingCataloguesTypes,
} from "../../../../../shared/api/agroevidence/catalogues/cataloguesTypes.selectors";

import {
  getCataloguesTypesApi,
  resetCataloguesTypesApi,
} from "../../../../../shared/api/agroevidence/catalogues/cataloguesTypes.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";
import { getSourceId } from "../../../../helpers/selectorHelpers";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { CatalogueTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultSource: CatalogueTo;
  label: ReactNode;
  onChange: (items: CatalogueTo) => void;
};
type Props = ReduxProps & OwnProps;

const SourceSelector = ({
  defaultSource,
  getCataloguesTypesApi,
  isCataloguesTypesFetching,
  label,
  onChange,
  resetCataloguesTypesApi,
  suggestions,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    getCataloguesTypesApi();

    return () => {
      resetCataloguesTypesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extendedSuggestions = useMemo(
    () =>
      suggestions.map((s) => ({
        ...s,
        name: intl.formatMessage({ id: getSourceId(s.type) }),
      })),
    [suggestions, intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultSource}
      id="source-filter"
      isFetching={isCataloguesTypesFetching}
      label={label}
      loadOptions={getCataloguesTypesApi}
      onChange={onChange}
      suggestions={extendedSuggestions}
      testId="source-filter"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  suggestions: getCataloguesTypes(state),
  isCataloguesTypesFetching: getIsFetchingCataloguesTypes(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getCataloguesTypesApi,
      resetCataloguesTypesApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SourceSelector);
