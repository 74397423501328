export const getAccount = (state) => state.api.accounts.account;
export const getIsFetchingAccount = (state) =>
  state.api.accounts.isFetchingAccount;
export const getFetchingAccountError = (state) =>
  state.api.accounts.getAccountError;
export const getIsSavingAccount = (state) => state.api.accounts.isSavingAccount;
export const getSavingAccountError = (state) =>
  state.api.accounts.saveAccountError;
export const getIsSavingPassword = (state) =>
  state.api.accounts.isSavingPassword;
export const getSavingPasswordError = (state) =>
  state.api.accounts.savePasswordError;
