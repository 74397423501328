import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./groups.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getGroupsApi = (params) => {
  const p = {
    "sort-col": params.orderBy,
    "sort-dir": params.order,
  };

  return {
    [RSAA]: {
      endpoint: `groups?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        types.GET_GROUPS,
        types.GET_GROUPS_SUCCESS,
        types.GET_GROUPS_ERROR,
      ],
    },
  };
};

export const resetGroupsApi = () => ({
  type: types.RESET_GROUPS,
});

export const updateGroupApi = (groupId, params) => ({
  [RSAA]: {
    endpoint: `groups/${groupId}?`,
    method: methods.PATCH,
    module: modules.IOT,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_GROUP,
      types.UPDATE_GROUP_SUCCESS,
      types.UPDATE_GROUP_ERROR,
    ],
  },
});

export const deleteGroupApi = (groupId) => ({
  [RSAA]: {
    endpoint: `groups/${groupId}?`,
    method: methods.DELETE,
    module: modules.IOT,
    types: [
      types.DELETE_GROUP,
      types.DELETE_GROUP_SUCCESS,
      types.DELETE_GROUP_ERROR,
    ],
  },
});

export const createGroupApi = (params) => ({
  [RSAA]: {
    endpoint: "groups?",
    method: methods.POST,
    body: JSON.stringify(params),
    module: modules.IOT,
    types: [types.POST_GROUP, types.POST_GROUP_SUCCESS, types.POST_GROUP_ERROR],
  },
});
