import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useNoVarioDocStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    borderRadius: 4,
    background: theme.palette.secondary.light,
    padding: "16px 50px 16px 16px",
    display: "flex",
    alignItems: "center",
    gap: 32,

    maxWidth: 910,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 16,
      paddingRight: 35,
      paddingTop: 30,
    },
  },
  leftSide: {
    flex: 1,
  },
  leftSideTitle: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
  },
  leftSideDesc: {
    fontSize: 14,
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontWeight: "500",
    fontSize: 14,
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    top: 6,
    right: 6,
  },
}));

export const useAsAppliedTableRowStyles = makeStyles(() => ({
  archivedMainRow: {
    background: "#FFFADE",
  },
  archivedSubrow: {
    background: "#F7F6EF",
    color: "#757575",
  },
  archivedText: {
    color: "#757575",
  },
  selectedMainRow: {
    background: "#EFF7F5",
  },
  selectedSubrow: {
    background: "#EFF7F5",
  },
}));

export const useAsAppliedRowStyles = makeStyles((theme: Theme) => ({
  firstColumnTop: {
    fontSize: 13,
    fontWeight: 400,
  },
  firstColumnGrey: {
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.grey[400],
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
  tableRow: {
    cursor: "pointer",
    transform: "scale(1)",
    "&:hover $collapseIcon": {
      visibility: "hidden",
    },
    "&:hover $tableRowActions": {
      display: "inline-block",
      padding: "9px 6px 0px 0px",
      background: "transparent",
      backgroundColor: "transparent",
    },
  },
  tableRowActions: {
    display: "none",
  },
  columnRight: {
    textAlign: "right",
  },
  columnLeft: {
    textAlign: "left",
  },
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: "none",
  },
  collapseIcon: {
    marginLeft: 4,
  },
  exportIcon: {
    width: 20,
    height: 20,
  },
}));

export const usePrecisionFarmingStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
  actionButton: {
    display: "flex",
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  fabContainer: {
    marginRight: theme.spacing(2),
  },
}));

export const usePrecisionListStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  toolbar: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  switchFormControlLabel: {
    marginLeft: 0,
  },
  toolbarLeft: {
    display: "flex",
    gap: 12,
    flexWrap: "wrap",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      gap: 0,
      width: "100%",
    },
  },
  noPrecision: {
    padding: theme.spacing(2),
  },
  bulkDeleteButton: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 3,
    minWidth: "unset",
  },
  varioDocWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));
