/**
 * Created by ondrejzvara on 10.3.16.
 */

/* @ngInject */
export default function cfOrganismSearchSelector() {
  const directive = {
    restrict: "E",
    template: require("./cf-organism-search-selector.html"),
    require: ["ngModel", "cfClassifierSelector"],
    scope: {},
    bindToController: {
      model: "=ngModel",
      allowCreate: "=?",
      onStateChange: "=?", // EMPTY, TYPING, SELECTED, CREATE
    },
    controller: cfOrganismSearchSelectorController,
    controllerAs: "cfOrganismSearchSelectorCtrl",
  };

  return directive;
}

/* @ngInject */
function cfOrganismSearchSelectorController(OrganismsResource) {
  const vm = this;
  vm.createOptionTemplateUrl = vm.allowCreate
    ? "cf-organism-search-selector-create-option-template.html"
    : null;
  vm.getOrganismsByName = getOrganismsByName;

  function getOrganismsByName(search) {
    return OrganismsResource.searchOrganism(
      {},
      {
        page: 1,
        count: 500,
        search,
        external: true,
      },
    ).then((result) => {
      const results = result.plain();
      return vm.allowCreate ? [{ name: search }].concat(results) : results;
    });
  }
}
