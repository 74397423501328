/**
 * Created by ondrejzvara on 7.11.15.
 */

/*@ngInject*/
export default function PorPublicController(
  porId,
  ChemistriesResource,
  Plain,
  Modal,
) {
  var vm = this;

  //public
  vm.item = {};
  vm.edit = false;
  vm.loadingPromise = [];

  vm.startEditing = startEditing;
  vm.saveEdits = saveEdits;
  vm.cancelEdit = cancelEdit;
  vm.verify = verify;
  vm.reject = reject;

  vm.isProcessed = isProcessed;
  activate();

  ////////////////////////////////////////////

  function activate() {
    vm.loadingPromise = getPor();
  }

  function getPor() {
    return ChemistriesResource.getPublicChemistry(porId).then(function (data) {
      vm.item = new Plain(data);
      vm.edit = false;
      return data;
    });
  }

  function startEditing() {
    vm.edit = true;
  }

  function cancelEdit() {
    vm.edit = false;
  }

  function saveEdits() {
    vm.loadingPromise = ChemistriesResource.editPublicChemistry(vm.item).then(
      getPor,
    );
  }

  function isProcessed() {
    return (
      vm.item.usageInfo &&
      (vm.item.usageInfo.state === "VERIFIED" ||
        vm.item.usageInfo.state === "REJECTED")
    );
  }

  function verify() {
    var config = {
      data: vm.item,
      size: "md",
      templateUrl: "app/assets/templates/modals/confirmPublicClassifier.html",
    };

    return Modal.activateModal(config).then(function () {
      vm.loadingPromise = ChemistriesResource.verifyChemistry(vm.item.id).then(
        getPor,
      );
    });
  }

  function reject() {
    // vm.loadingPromise = ChemistriesResource.rejectChemistry(vm.item.id).then(getPor);
  }
}
