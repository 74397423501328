/**
 * Created by ondrejzvara on 16.6.16.
 */

/*@ngInject*/

export default function cfBoolSelector() {
  var directive = {
    restrict: "E",
    template: require("./cf-bool-selector.html"),
    require: ["ngModel"],
    scope: {},
    bindToController: {
      model: "=ngModel",
      onSelect: "=?onSelect",
      mandatory: "=?",
      disabled: "=?",
    },
    controller: cfBoolSelectorController,
    controllerAs: "cfBoolSelectorCtrl",
  };

  return directive;
}

/*@ngInject*/
function cfBoolSelectorController() {
  var vm = this;

  vm.boolSelect = [];

  if (!vm.mandatory) {
    vm.boolSelect.push({ name: "undefined", value: null });
  }

  vm.boolSelect.push({ name: "yes", value: true });
  vm.boolSelect.push({ name: "no", value: false });
}
