const PREFIX = "CATALOGUES/TELEMATICS";

export const GET_MACHINES_REQUEST = `${PREFIX}/GET_MACHINES_REQUEST`;
export const GET_MACHINES_SUCCESS = `${PREFIX}/GET_MACHINES_SUCCESS`;
export const GET_MACHINES_ERROR = `${PREFIX}/GET_MACHINES_ERROR`;

export const GET_EQUIPMENTS_REQUEST = `${PREFIX}/GET_EQUIPMENTS_REQUEST`;
export const GET_EQUIPMENTS_SUCCESS = `${PREFIX}/GET_EQUIPMENTS_SUCCESS`;
export const GET_EQUIPMENTS_ERROR = `${PREFIX}/GET_EQUIPMENTS_ERROR`;

export const GET_DRIVERS_REQUEST = `${PREFIX}/GET_DRIVERS_REQUEST`;
export const GET_DRIVERS_SUCCESS = `${PREFIX}/GET_DRIVERS_SUCCESS`;
export const GET_DRIVERS_ERROR = `${PREFIX}/GET_DRIVERS_ERROR`;

export const GET_PRODUCTION_OPERATIONS_REQUEST = `${PREFIX}/GET_PRODUCTION_OPERATIONS_REQUEST`;
export const GET_PRODUCTION_OPERATIONS_SUCCESS = `${PREFIX}/GET_PRODUCTION_OPERATIONS_SUCCESS`;
export const GET_PRODUCTION_OPERATIONS_ERROR = `${PREFIX}/GET_PRODUCTION_OPERATIONS_ERROR`;

export const GET_MACHINES_CATEGORIES_REQUEST = `${PREFIX}/GET_MACHINES_CATEGORIES_REQUEST`;
export const GET_MACHINES_CATEGORIES_SUCCESS = `${PREFIX}/GET_MACHINES_CATEGORIES_SUCCESS`;
export const GET_MACHINES_CATEGORIES_ERROR = `${PREFIX}/GET_MACHINES_CATEGORIES_ERROR`;

export const GET_MACHINES_GROUPS_REQUEST = `${PREFIX}/GET_MACHINES_GROUPS_REQUEST`;
export const GET_MACHINES_GROUPS_SUCCESS = `${PREFIX}/GET_MACHINES_GROUPS_SUCCESS`;
export const GET_MACHINES_GROUPS_ERROR = `${PREFIX}/GET_MACHINES_GROUPS_ERROR`;

export const EDIT_MACHINE_REQUEST = `${PREFIX}/EDIT_MACHINE_REQUEST`;
export const EDIT_MACHINE_SUCCESS = `${PREFIX}/EDIT_MACHINE_SUCCESS`;
export const EDIT_MACHINE_ERROR = `${PREFIX}/EDIT_MACHINE_ERROR`;

export const EDIT_EQUIPMENT_REQUEST = `${PREFIX}/EDIT_EQUIPMENT_REQUEST`;
export const EDIT_EQUIPMENT_SUCCESS = `${PREFIX}/EDIT_EQUIPMENT_SUCCESS`;
export const EDIT_EQUIPMENT_ERROR = `${PREFIX}/EDIT_EQUIPMENT_ERROR`;

export const EDIT_DRIVER_REQUEST = `${PREFIX}/EDIT_DRIVER_REQUEST`;
export const EDIT_DRIVER_SUCCESS = `${PREFIX}/EDIT_DRIVER_SUCCESS`;
export const EDIT_DRIVER_ERROR = `${PREFIX}/EDIT_DRIVER_ERROR`;
