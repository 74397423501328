import React, { Component } from "react";

import PropTypes from "prop-types";
import { Area, Dot } from "recharts";

class CfChartArea extends Component {
  isDataPointEmpty(array) {
    return (
      Array.isArray(array) && array.every((item) => typeof item === "undefined")
    );
  }

  renderDot(datapoint, data, color) {
    const { ...rest } = this.props;
    if (datapoint.payload[datapoint.dataKey]) {
      const index = data.findIndex(
        (item) => item.dateTime === datapoint.payload.dateTime,
      );
      const leftPoint = data[index - 1];
      const rightPoint = data[index + 1];
      if (
        (!leftPoint || this.isDataPointEmpty(leftPoint[datapoint.dataKey])) &&
        (!rightPoint || this.isDataPointEmpty(rightPoint[datapoint.dataKey]))
      ) {
        if (rest.dot) {
          return rest.dot;
        }
        return <Dot {...datapoint} fill={color} fillOpacity={1} r={2} />;
      }
    }

    return undefined;
  }

  render() {
    const { dotData, ...rest } = this.props;
    return (
      <Area
        {...this.props}
        type="monotone"
        dot={
          rest.dot
            ? rest.dot
            : (point) => this.renderDot(point, dotData, rest.stroke)
        }
      />
    );
  }
}

CfChartArea.defaultProps = Area.defaultProps;
CfChartArea.displayName = Area.displayName;
CfChartArea.getBaseValue = Area.getBaseValue;
CfChartArea.getComposedData = Area.getComposedData;
CfChartArea.renderDotItem = Area.renderDotItem;

CfChartArea.propTypes = {
  dotData: PropTypes.array.isRequired,
};

export default CfChartArea;
