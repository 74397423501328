import angular from "angular";

import StoresController from "./stores.controller";

const storesComponent = {
  bindings: {
    farm: "<",
    farmId: "<",
  },
  controller: StoresController,
};

export default angular
  .module("app.core.stores", [])
  .config(config)
  .component("storesComponent", storesComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider
    .state("farm.active.stores", {
      url: "/stores",
      views: {
        farm: {
          component: "storesComponent",
        },
      },
    })
    .state("farm.active.stores.seeds", {
      url: "/seeds",
      views: {
        farm: {
          component: "storesComponent",
        },
      },
    })
    .state("farm.active.stores.fertilizers", {
      url: "/fertilizers",
      views: {
        farm: {
          component: "storesComponent",
        },
      },
    })
    .state("farm.active.stores.pors", {
      url: "/pors",
      views: {
        farm: {
          component: "storesComponent",
        },
      },
    })
    .state("farm.active.store", {
      url: "/store/{stockId}",
      views: {
        farm: {
          component: "storesComponent",
        },
      },
    });
}
