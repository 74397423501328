import React from "react";

import { withStyles } from "@mui/styles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  notificationFormInitialValues as initialValues,
  mapNotificationFormValues,
} from "../../services/Notifications.service";
import { NotificationDetailForm } from "../NotificationDetailForm/NotificationDetailForm";

const validateForm = (values) => {
  const errors = {};
  if (!values.users?.length > 0) {
    errors.users = (
      <FormattedMessage id="NotificationUsersControl.validation" />
    );
  }
  return errors;
};

const styles = {
  formButtons: {},
  button: {
    margin: "10px",
  },
  condition: {
    display: "flex",
    alignItems: "flex-end",
  },
  helperText: {
    position: "absolute",
    bottom: -22,
  },
  infoCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  infoCard: {
    display: "flex",
    backgroundColor: "rgba(120, 211, 240, 0.2)",
    width: "fit-content",
    padding: "16px 24px",
    alignItems: "center",
  },
  infoIconWrapper: {
    marginRight: 8,
    width: 24,
    height: 24,
  },
  infoIcon: {
    color: "#78D3F0",
  },
};

export function NotificationDetailFormWrapper(props) {
  const {
    classes,
    farmId,
    goToNotifications,
    isEditing,
    isExisting,
    nodeLocation,
    onEditingEnd,
    rule,
  } = props;
  const initialRuleValues = isExisting
    ? mapNotificationFormValues(rule, nodeLocation)
    : initialValues;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={isExisting ? initialRuleValues : initialValues}
      validate={validateForm}
      validateOnBlur={false}
      validateOnChange={false}
      onReset={() => {
        if (isExisting) {
          onEditingEnd();
        }
      }}
      onSubmit={(values) => {
        if (isExisting) {
          onEditingEnd();
          props.updateRule(rule.id, values, farmId).then((res) => {
            if (!res.error) {
              goToNotifications();
            }
          });
        } else {
          props.createRule(values, farmId).then((res) => {
            if (!res.error) {
              goToNotifications();
            }
          });
        }
      }}
    >
      <NotificationDetailForm
        classes={classes}
        farmId={farmId}
        isEditing={isEditing}
        isExisting={isExisting}
      />
    </Formik>
  );
}

NotificationDetailFormWrapper.propTypes = {
  isEditing: PropTypes.bool,
  isExisting: PropTypes.bool,
  onEditingEnd: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  rule: PropTypes.object,
  nodeLocation: PropTypes.object.isRequired,
  goToNotifications: PropTypes.func.isRequired,
};

NotificationDetailFormWrapper.defaultProps = {
  isEditing: false,
  isExisting: true,
  rule: null,
};

export default withStyles(styles)(NotificationDetailFormWrapper);
