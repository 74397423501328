import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfReduxFormTextField from "../../../../../shared/components/form/CfReduxFormTextField/CfReduxFormTextField";
import CfSelector from "../../../../../shared/components/form/CfSelector/CfSelector";
import * as validators from "../../../../../shared/misc/validators";
import SensorTypeMenuItem from "../../components/SensorTypeMenuItem/SensorTypeMenuItem";
import FarmSelector from "../FarmSelector/FarmSelector";

const styles = (theme) => ({
  menuItem: {
    fontSize: 16,
    paddingBottom: 5,
    paddingTop: 5,
  },
  warning: {
    color: theme.palette.secondary.main,
    fontSize: 12,
  },
  errorText: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
});

class SensorAdminCreateSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: {},
    };
  }
  onSubmit = this.props.handleSubmit((values) => this.props.onAccept(values));

  initialize = () => {
    this.props.initialize({
      name: null,
      external_id: null,
      pac: null,
      type: null,
    });
    this.setState({
      network: {},
    });
  };

  updateSelectedNetwork = (network) => {
    this.setState({
      network,
    });
  };

  mapNetworkType = (network) => ({
    ...network,
    name: this.mapNetworkTypeName(network),
  });

  mapNetworkTypeName = (network) =>
    `${network.networkType} ${network.network !== null ? network.network : ""}`;

  isSaveNarrowBandOrUkraine = () =>
    this.state.network.networkType === "NARROWBAND" ||
    this.state.network.network === "UKRAINE";

  renderFieldsBasedOnNetwork = (network) =>
    network.fields.map((field) => (
      <CfFormControl key={field}>
        <Field
          component={CfReduxFormTextField}
          fullWidth
          label={field.toUpperCase()}
          name={field}
          type="text"
          validate={[validators.required, validators.noWhitespace]}
        />
      </CfFormControl>
    ));

  render() {
    const { apiError, classes, onClose, opened, submitting, types } =
      this.props;
    const { network } = this.state;
    return (
      <CfDialog
        cancelText={<FormattedMessage id="common.cancel" />}
        disabled={submitting}
        onAccept={this.onSubmit}
        onCancel={onClose}
        onClose={onClose}
        onEnter={this.initialize}
        opened={opened}
        title={<FormattedMessage id="SensorsAdmin.create-sensors-heading" />}
        acceptText={
          this.isSaveNarrowBandOrUkraine() ? (
            <FormattedMessage id="common.save" />
          ) : (
            <FormattedMessage id="SensorsAdmin.register" />
          )
        }
      >
        <form>
          <CfFormControl>
            <Field
              component={CfReduxFormTextField}
              fullWidth
              label={<FormattedMessage id="common.name" />}
              name="name"
              type="text"
              validate={validators.required}
            />
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfReduxFormTextField}
              fullWidth
              label={<FormattedMessage id="SensorsAdmin.external_id" />}
              name="external_id"
              type="text"
              validate={[validators.required, validators.noWhitespace]}
            />
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfSelector}
              idPropertyName="name"
              items={types}
              label={<FormattedMessage id="common.type" />}
              name="type"
              validate={validators.required}
              classes={{
                root: classes.menuItem,
              }}
              labelPropertyFactory={(option) => (
                <SensorTypeMenuItem option={option} />
              )}
            />
          </CfFormControl>
          <CfFormControl>
            <Field component={FarmSelector} name="farm" />
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfSelector}
              idPropertyName="name"
              items={this.props.networkTypes.map((n) => this.mapNetworkType(n))}
              label={<FormattedMessage id="SensorsAdmin.network" />}
              name="network"
              onChange={(value) => {
                this.updateSelectedNetwork(value);
              }}
            />
          </CfFormControl>
          {!isEmpty(network) && this.renderFieldsBasedOnNetwork(network)}
          <CfFormControl>
            {apiError && (
              <div className={classes.errorText}>
                <FormattedMessage id={apiError} />
              </div>
            )}
            {this.isSaveNarrowBandOrUkraine() && (
              <div className={classes.warning}>
                <FormattedMessage
                  id="SensorsAdmin.registerWarn"
                  values={{ name: this.mapNetworkTypeName(network) }}
                />
              </div>
            )}
          </CfFormControl>
        </form>
      </CfDialog>
    );
  }
}

SensorAdminCreateSensor.propTypes = {
  classes: PropTypes.object.isRequired,
  apiError: PropTypes.string,
  types: PropTypes.array.isRequired,
  networkTypes: PropTypes.array.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

SensorAdminCreateSensor.defaultProps = {
  apiError: null,
  opened: false,
};

export default connect(
  null,
  null,
)(
  reduxForm({
    form: "sensorAdminCreateSensor",
  })(withStyles(styles)(SensorAdminCreateSensor)),
);
