const PREFIX = "AGROEVIDENCE_PARCELS";

export const GET_PARCELS_CROP_REQUEST = `${PREFIX}/GET_PARCELS_CROP_REQUEST`;
export const GET_PARCELS_CROP_SUCCESS = `${PREFIX}/GET_PARCELS_CROP_SUCCESS`;
export const GET_PARCELS_CROP_ERROR = `${PREFIX}/GET_PARCELS_CROP_ERROR`;
export const RESET_PARCELS_CROP = `${PREFIX}/RESET_PARCELS_CROP`;

export const PUT_PARCELS_CROP_REQUEST = `${PREFIX}/PUT_PARCELS_CROP_REQUEST`;
export const PUT_PARCELS_CROP_SUCCESS = `${PREFIX}/PUT_PARCELS_CROP_SUCCESS`;
export const PUT_PARCELS_CROP_ERROR = `${PREFIX}/PUT_PARCELS_CROP_ERROR`;

export const EXPORT_PARCELS_CROP_REQUEST = `${PREFIX}/EXPORT_PARCELS_CROP_REQUEST`;
export const EXPORT_PARCELS_CROP_SUCCESS = `${PREFIX}/EXPORT_PARCELS_CROP_SUCCESS`;
export const EXPORT_PARCELS_CROP_ERROR = `${PREFIX}/EXPORT_PARCELS_CROP_ERROR`;
