/**
 * Created by ondrejzvara on 2.3.16.
 */

/*@ngInject*/
export default function cfEdit() {
  var directive = {
    restrict: "A",
    require: "ngModel",
    scope: {
      edit: "=cfEdit",
    },

    link: function (scope, elem, attr, ngModel) {
      var parent = elem.parent();

      scope.$watch(
        function () {
          return scope.edit;
        },
        function (newVal) {
          if (!_.isUndefined(newVal)) {
            displayDesiredElem();
          }
        },
      );

      function displayDesiredElem() {
        if (scope.edit) {
          parent.find("span").remove();
          elem.show();
        } else {
          elem.hide();
          parent.append(
            angular.element("<span>" + ngModel.$modelValue + "</span>"),
          );
        }
      }
    },
  };
  return directive;
}
