import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Form, Formik, FormikHelpers } from "formik";
import { FormattedMessage } from "react-intl";

import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import FormPasswordInput from "../../../../shared/components/form/FormPasswordInput/FormPasswordInput";
import FormTextField from "../../../../shared/components/form/FormTextField/FormTextField";
import EntryContainer from "../../../../shared/containers/EntryContainer/EntryContainer";
import { LoginLinks } from "../../components/LoginLinks/LoginLinks";

import HasLogged from "./HasLogged";

interface Props {
  isApiError: boolean;
  ngChangeLanguage: () => void;
  ngLogin: (credentials: {
    password: string;
    username: string;
  }) => Promise<unknown>;
  ngRedirectToReset: () => void;
  ngRedirectToSignup: () => void;
}

const Login: React.FC<Props> = (props) => {
  const {
    isApiError,
    ngChangeLanguage,
    ngRedirectToReset,
    ngRedirectToSignup,
  } = props;
  const classes = useStyles();

  const [loginError, setLoginError] = useState<React.ReactNode>(undefined);
  const [hasLogged, setHasLogged] = useState(false);
  const [error, setError] = useState({
    isError: isApiError,
    code: isApiError ? 500 : null,
  });

  useEffect(() => {
    if (hasLogged) {
      setHasLogged(false);
    }
  }, [hasLogged]);

  useEffect(() => {
    setError({
      isError: isApiError,
      code: isApiError ? 500 : null,
    });
  }, [isApiError]);

  const initialValues = {
    username: "",
    password: "",
  };

  const onLogin = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    const { password, username } = values;
    setLoginError(undefined);

    return props
      .ngLogin({ username, password })
      .then(() => setHasLogged(true))
      .catch((err) => {
        const status = err && err.status ? err.status : null;
        switch (status) {
          case 400:
          case 401:
            setLoginError(<FormattedMessage id="Login.incorrect-login" />);
            break;
          case 500:
          case 503:
            setError({
              isError: true,
              code: err.status,
            });
            break;
          default:
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validate = (values: typeof initialValues) => {
    const errors: { [key in keyof typeof values]?: React.ReactNode } = {};
    if (!values?.username) {
      errors.username = <FormattedMessage id="Login.field-is-required" />;
    }
    if (!values?.password) {
      errors.password = <FormattedMessage id="Login.field-is-required" />;
    }
    return errors;
  };

  return (
    <CfErrorPage error={error}>
      <HasLogged hasLogged={hasLogged} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onLogin}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <EntryContainer
            isFetching={isSubmitting}
            onLangChange={ngChangeLanguage}
          >
            <Form autoComplete="off">
              <Grid className={classes.loginContainer} container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    autoFocus={true}
                    className={classes.textField}
                    id="username"
                    label={<FormattedMessage id="Login.username" />}
                    margin="dense"
                    name="username"
                    type="text"
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormPasswordInput
                    fullWidth={true}
                    label={<FormattedMessage id="Login.password" />}
                    name="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    color="primary"
                    id="login"
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage id="Login.log-in" />
                  </Button>
                </Grid>
                {loginError && (
                  <Grid className={classes.loginErrorHolder} item xs={12}>
                    <div
                      className={classes.loginError}
                      id="incorrect-login-alert"
                    >
                      <p>{loginError}</p>
                    </div>
                  </Grid>
                )}
                <LoginLinks
                  resetRedirect={ngRedirectToReset}
                  signupRedirect={ngRedirectToSignup}
                />
              </Grid>
            </Form>
          </EntryContainer>
        )}
      </Formik>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    textAlign: "center",
    backgroundColor: "rgba(255,255,255,0.8)",
    padding: "30px",
    position: "relative",
  },
  textField: {
    textAlign: "left",
    width: "100%",
    marginTop: 0,
  },
  errorText: {
    position: "absolute",
    bottom: "-10px",
  },
  floatingLabelStyle: {
    fontWeight: "normal",
    fontSize: "14px",
  },
  inputLabel: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: "none",
  },
  button: {
    width: "100%",
  },
  loginErrorHolder: {
    marginBottom: 10,
    position: "relative",
  },
  loginError: {
    position: "absolute",
    top: 0,
    left: 0,
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "center",
    width: "100%",
    color: theme.palette.error.main,
  },
  loginContainer: {
    width: "100%",
    margin: "10px 0px",
  },
}));

export default Login;
