import React from "react";

import { useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

const SignupToLogin = (props: { redirect: () => void }) => {
  const theme = useTheme();
  return (
    <div>
      <p style={{ textAlign: "center", marginTop: "20px" }}>
        <FormattedMessage id="SignupToLogin.hasAccount" />{" "}
        <a
          href="#"
          onClick={props.redirect}
          style={{
            color: theme.palette.primary.main,
          }}
        >
          <FormattedMessage id="SignupToLogin.logIn" />
        </a>
      </p>
    </div>
  );
};

export { SignupToLogin };
