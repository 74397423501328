/**
 * Created by ondrejzvara on 17.3.16.
 */

/*@ngInject*/
export default function cfCheckbox($translate) {
  return {
    restrict: "E",
    template: require("./cf-checkbox.html"),
    scope: {
      model: "=",
      onChange: "=",
      disabled: "=",
    },
    link: function (scope, element) {
      var span = element.find("span")[0];
      scope.$watch("model", function (newValue) {
        reloadValue(span, newValue);
      });

      scope.$on("$localeChangeSuccess", function () {
        reloadValue(span, scope.model);
      });
    },
  };

  function reloadValue(span, value) {
    $(span).attr(
      "data-content",
      value
        ? $translate.instant("shared.yes")
        : $translate.instant("shared.no"),
    );
  }
}
