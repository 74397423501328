import React from "react";

import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

const DECIMAL_NUM = 3;

const CfFormattedNumber = (props) => {
  const { decimalDigits } = props;
  const fractionDigits =
    decimalDigits || decimalDigits === 0 ? decimalDigits : DECIMAL_NUM;
  return (
    <FormattedNumber
      maximumFractionDigits={fractionDigits}
      minimumFractionDigits={fractionDigits}
      {...props}
    />
  );
};

CfFormattedNumber.propTypes = {
  value: PropTypes.number.isRequired,
  decimalDigits: PropTypes.number,
};

CfFormattedNumber.defaultProps = {
  decimalDigits: null,
};

export default CfFormattedNumber;
