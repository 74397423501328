import { useState } from "react";

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverClose = () => setAnchorEl(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const isOpen = Boolean(anchorEl);

  return { isOpen, anchorEl, handlePopoverOpen, handlePopoverClose };
};

export default usePopover;
