import * as types from "./products.constants";

export default () =>
  (
    state = {
      items: [],
      isFetching: false,
      error: {},
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_PRODUCTS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_PRODUCTS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
          totalCount: 0,
        };
      default:
        return state;
    }
  };
