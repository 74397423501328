import React, { useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getFertilizer,
  getIsFetchingFertilizers,
} from "../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";

import {
  createFertilizer,
  updateFertilizer,
  fetchFertilizer,
  resetFertilizer,
  patchFertilizer,
} from "../../../actions/catalogues.actions";

import { CATALOGUES_URLS } from "../../../catalogues.constants";

import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useWidth from "../../../../shared/hooks/useWidth";
import {
  AsyncFn,
  CfFormikErrors,
  CfFormikProps,
  Thunk,
} from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import NitrogenCategorySelector from "../FertilizersAdvancedFilter/NitrogenCategorySelector/NitrogenCategorySelector";

import Buttons from "./components/Buttons";
import CheckboxField from "./components/CheckboxField";
import DatePickerField from "./components/DatePickerField";
import FertilizerDetailHeader from "./components/FertilizerDetailHeader";
import FormikTextField from "./components/FormikTextField";
import NumericField from "./components/NumericField";
import UnitTypeRadioGroup from "./components/UnitTypeRadioGroup";
import {
  initialEmptyValues,
  mapInitialValues,
  mapRequestBodyUpdateFertilizer,
} from "./FertilizerDetail.services";
import { useFertilizerDetailStyles } from "./FertilizerDetail.styles";
import FertilizerKindSelector from "./FertilizerKindSelector/FertilizerKindSelector";
import RegistrationTypeSelector from "./RegistrationTypeSelector/RegistrationTypeSelector";

import { FertilizerItemFormValues } from "./FertilizerDetail.types";
import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  EagriFertilizerKindTo,
  EagriFertilizerNitrogenCategoryTo,
  EagriFertilizerRegistrationTypeTo,
  FertilizerCreateTo,
  FertilizerDetailTo,
  FertilizerPatchTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  isNew: boolean;
};
type Props = ReduxProps & OwnProps;

const FertilizerDetail = ({
  createFertilizer,
  fertilizer,
  fetchFertilizer,
  isFetching,
  isNew,
  patchFertilizer,
  resetFertilizer,
  updateFertilizer,
}: Props) => {
  const classes = useFertilizerDetailStyles();
  const history = useHistory();
  const width = useWidth();
  const { farmId, langId } = useContext(CataloguesContext);
  const { fertilizerId } = useParams<{ fertilizerId: string }>();
  const showSnackbar = useContext(SnackbarContext);

  const [isEditing, setIsEditing] = useState(isNew);

  useEffect(() => {
    resetFertilizer();
    if (!isNew) {
      fetchFertilizer(fertilizerId);
    }
  }, [fertilizerId, fetchFertilizer, isNew, resetFertilizer]);

  const initialValues: Partial<FertilizerItemFormValues> = useMemo(() => {
    if (isNew || !fertilizer) return initialEmptyValues;

    return mapInitialValues(fertilizer);
  }, [fertilizer, isNew]);

  const handleFavoriteClick = () => {
    (patchFertilizer as unknown as AsyncFn<string, FertilizerPatchTo>)(
      fertilizerId,
      { isFavorite: !fertilizer?.isFavorite },
    ).then(() => fetchFertilizer(fertilizerId));
  };

  const handleHideClick = () => {
    (patchFertilizer as unknown as AsyncFn<string, FertilizerPatchTo>)(
      fertilizerId,
      { isHidden: true },
    ).then(() => handleGoBack());
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleResetForm = () => {
    setIsEditing(false);
  };

  const handleSubmit = (values: FertilizerItemFormValues) => {
    const data = mapRequestBodyUpdateFertilizer(values);
    if (isNew) {
      (createFertilizer as unknown as AsyncFn<FertilizerCreateTo>)(data).then(
        (res: RSAAResultAction<FertilizerDetailTo>) => {
          if (!res.error && values.isFavorite) {
            const newFertilizerId = res.payload.id;
            patchFertilizer(newFertilizerId, { isFavorite: true });
          }
          handleResponse(res, true);
        },
      );
    } else {
      (updateFertilizer as unknown as AsyncFn<string, FertilizerCreateTo>)(
        fertilizerId,
        data,
      ).then((res: RSAAResultAction<FertilizerDetailTo>) =>
        handleResponse(res),
      );
      setIsEditing(false);
    }
  };

  const handleResponse = (
    res: RSAAResultAction<FertilizerDetailTo>,
    newAction = false,
  ) => {
    if (!res.error) {
      if (newAction) handleGoBack();
      return;
    }
    showSnackbar({
      message: (
        <FormattedMessage
          id={`Catalogues.fertilizers.${
            newAction ? "createError" : "updateError"
          }`}
        />
      ),
      isError: true,
    });
  };

  const handleGoBack = () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS.fertilizersReact}`);
  };

  const validate = (values: FertilizerItemFormValues) => {
    const errors: CfFormikErrors<FertilizerItemFormValues> = {};
    if (!values.name) {
      errors.name = "validation.required";
    }

    if (!values.nitrogenCategory) {
      errors.nitrogenCategory = "validation.required";
    }

    if (!values.fertilizerKind) {
      errors.fertilizerKind = "validation.required";
    }

    if (!values.unitType) {
      errors.unitType = "validation.required";
    }

    if (!values.registrationType) {
      errors.registrationType = "validation.required";
    }

    return errors;
  };

  const isMobile = width === "xs" || width === "sm";
  const spacingValue = isMobile ? 1 : 0;
  const canUpdate = isNew || fertilizer?.catalogue.canUpdate;

  if (!fertilizer && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <Formik<Partial<FertilizerItemFormValues>>
        enableReinitialize
        initialValues={initialValues}
        onReset={handleResetForm}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          setFieldValue,
          values,
        }: CfFormikProps<FertilizerItemFormValues>) => {
          const handleChangeName = (
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            setFieldValue("name", event.target.value);
          };

          const handleChangeUnitType = (
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            setFieldValue("unitType", event.target.defaultValue);
          };

          const handleChangeNitrogenCategory = (
            nitrogenCategory: EagriFertilizerNitrogenCategoryTo,
          ) => {
            setFieldValue("nitrogenCategory", nitrogenCategory);
          };

          const handleRegistrationType = (
            registrationType: EagriFertilizerRegistrationTypeTo,
          ) => {
            setFieldValue("registrationType", registrationType);
          };

          const handleChangeFertilizerKindy = (
            fertilizerKind: EagriFertilizerKindTo,
          ) => {
            setFieldValue("fertilizerKind", fertilizerKind);
          };

          const handleChangeCheckbox = (
            event: React.ChangeEvent<HTMLInputElement>,
            name: string,
          ) => {
            setFieldValue(name, event.target.checked);
          };

          const handleChangeFavoriteOnCreate = () => {
            setFieldValue("isFavorite", !values.isFavorite);
          };

          const renderSectionHeading = (messageId: string) => (
            <Grid data-test="sectionHeading" item xs={12}>
              <Typography className={classes.sectionHeading} variant="h5">
                <FormattedMessage id={messageId} />
              </Typography>
            </Grid>
          );

          return (
            <Form>
              <div className={classes.body}>
                <Grid className={classes.headWrapper} container>
                  <FertilizerDetailHeader
                    canUpdate={canUpdate}
                    defaultIsFavorite={values.isFavorite}
                    defaultName={values.name}
                    error={!!errors.name}
                    handleGoBack={handleGoBack}
                    handleStartEdit={handleStartEdit}
                    isEditing={isEditing}
                    onChange={handleChangeName}
                    handleFavoriteClick={
                      isNew ? handleChangeFavoriteOnCreate : handleFavoriteClick
                    }
                  />
                </Grid>
                <Grid
                  container
                  direction={isMobile ? "column" : "row"}
                  spacing={2}
                  className={classNames(classes.wrapper, {
                    [classes.eAgriWrapper]: !canUpdate,
                  })}
                >
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    style={{ paddingTop: 8 }}
                    xs={12}
                  >
                    <FormattedMessage
                      id={`Catalogues.fertilizers.detail.source.${
                        canUpdate ? "custom" : "eAgri"
                      }`}
                      values={{
                        br: <br />,
                        b: (chunks: string) => <b>{chunks}</b>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {renderSectionHeading(
                      "Catalogues.fertilizers.detail.compositionSection",
                    )}
                    <Paper className={classes.paper}>
                      <Grid container direction={isMobile ? "column" : "row"}>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={2}
                          xs={3}
                        >
                          <Grid item>
                            <CheckboxField
                              defaultValues={values.isOrganic}
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.isOrganic"
                              name="isOrganic"
                              onChange={handleChangeCheckbox}
                            />
                          </Grid>
                          <Grid item style={{ paddingRight: 32 }}>
                            <FormikTextField
                              fullWidth={true}
                              isEditing={isEditing}
                              isValidation={true}
                              label="Catalogues.fertilizers.detail.producerTradeName"
                              name="producerTradeName"
                            />
                          </Grid>
                          <Grid className={classes.fieldFormik} item>
                            <UnitTypeRadioGroup
                              defaultValues={values.unitType}
                              error={!!errors.unitType}
                              isEditing={isEditing}
                              onChange={handleChangeUnitType}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={2}
                          xs={8}
                        >
                          <Grid item>
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.phFrom"
                              name="phFrom"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.phTo"
                              name="phTo"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.electricConductivity"
                              name="electricConductivity"
                              unit="S"
                            />
                            <NumericField
                              isEditing={isEditing}
                              isWider={true}
                              label="Catalogues.fertilizers.detail.chlorides"
                              name="chlorides"
                              unit="%"
                            />
                          </Grid>
                          <Grid item>
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.n"
                              name="n"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.p2o5"
                              name="p2o5"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.k2o"
                              name="k2o"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.zn"
                              name="zn"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.cu"
                              name="cu"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.fe"
                              name="fe"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.mgo"
                              name="mgo"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.cao"
                              name="cao"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.na2o"
                              name="na2o"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.b"
                              name="b"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.mn"
                              name="mn"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.mo"
                              name="mo"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.se"
                              name="se"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.s"
                              name="s"
                              unit="%"
                            />
                            <NumericField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.combustibleContent"
                              name="combustibleContent"
                              unit="%"
                            />
                            <Grid item style={{ paddingRight: 40 }} xs={5}>
                              <NumericField
                                fullWidth={true}
                                isEditing={isEditing}
                                label="Catalogues.fertilizers.detail.unitConversionCoefficient"
                                name="unitConversionCoefficient"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ paddingRight: 40 }} xs={12}>
                          <FormikTextField
                            fullWidth={true}
                            isEditing={isEditing}
                            label="common.description"
                            name="description"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    {renderSectionHeading(
                      "Catalogues.fertilizers.detail.nitrateDirectiveSection",
                    )}
                    <Paper className={classes.paper}>
                      <Grid
                        container
                        direction={isMobile ? "column" : "row"}
                        spacing={spacingValue}
                      >
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={2}
                          style={{ marginRight: 32 }}
                          xs="auto"
                        >
                          <Grid className={classes.datePickerField} item>
                            <DatePickerField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.validFrom"
                              name="validFrom"
                            />
                          </Grid>
                          <Grid className={classes.datePickerField} item>
                            <DatePickerField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.validTo"
                              name="validTo"
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={2}
                          xs={2}
                        >
                          <Grid item>
                            <CheckboxField
                              defaultValues={values.isExcrement}
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.isExcrement"
                              name="isExcrement"
                              onChange={handleChangeCheckbox}
                            />
                          </Grid>
                          <Grid item>
                            <CheckboxField
                              defaultValues={values.isEco}
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.isEco"
                              name="isEco"
                              onChange={handleChangeCheckbox}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={1}
                          xs="auto"
                        >
                          <Grid container direction="row" item>
                            <div className={classes.nitrogenCategorySelector}>
                              <NitrogenCategorySelector
                                defaultValues={values.nitrogenCategory}
                                disabled={!isEditing}
                                error={!!errors.nitrogenCategory}
                                langId={langId}
                                onChange={handleChangeNitrogenCategory}
                                label={
                                  <FormattedMessage id="Catalogues.table.fertilizers.column.nitrogenCategory" />
                                }
                              />
                            </div>
                            <div className={classes.registrationTypeSelector}>
                              <RegistrationTypeSelector
                                defaultValues={values.registrationType}
                                disabled={!isEditing}
                                error={!!errors.registrationType}
                                langId={langId}
                                onChange={handleRegistrationType}
                              />
                            </div>
                          </Grid>
                          <Grid container direction="row" item>
                            <div className={classes.nitrogenCategorySelector}>
                              <FertilizerKindSelector
                                defaultValues={values.fertilizerKind}
                                disabled={!isEditing}
                                error={!!errors.fertilizerKind}
                                onChange={handleChangeFertilizerKindy}
                              />
                            </div>
                            <NumericField
                              isEditing={isEditing}
                              isWider={true}
                              label="Catalogues.fertilizers.detail.nitrogenCoefficient"
                              name="nitrogenCoefficient"
                              unit="%"
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={1}
                          xs="auto"
                        >
                          <Grid container direction="row" item>
                            <FormikTextField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.registrationNumber"
                              name="registrationNumber"
                            />
                            <FormikTextField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.ordinance"
                              name="ordinance"
                            />
                          </Grid>
                          <Grid container direction="row" item>
                            <FormikTextField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.evidenceNumber"
                              name="evidenceNumber"
                            />
                            <FormikTextField
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.applicantTradeName"
                              name="applicantTradeName"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                {isEditing && (
                  <Buttons isNew={isNew} onHide={handleHideClick} />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  fertilizer: getFertilizer(state),
  isFetching: getIsFetchingFertilizers(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchFertilizer,
      resetFertilizer,
      createFertilizer,
      updateFertilizer,
      patchFertilizer,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FertilizerDetail);
