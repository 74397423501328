import { useState, useEffect } from "react";

import queryString from "query-string";

type ApiData = {
  cca2: string;
  name: {
    common: string;
  };
  translations: {
    ces: {
      common: string;
    };
  };
};

type ReturnData = {
  code: string;
  name: string;
};

const LANGS = {
  CZ: "cs-CZ",
  EN: "en-US",
};

const FIELDS = { fields: ["name", "cca2", "translations"] };

const parseData = (data: ApiData[], codesToMatch: string[], lang = LANGS.EN) =>
  data.reduce((acc: ReturnData[], d) => {
    const isCzech = lang === LANGS.CZ;
    const isCountryCodeMatchedWithApi = codesToMatch.includes(d.cca2);

    if (isCountryCodeMatchedWithApi) {
      acc.push({
        code: d.cca2,
        name: isCzech ? d.translations.ces.common : d.name.common,
      });
    }
    return acc;
  }, []);

const parseFallbackData = (data: string[]) =>
  data.map((d) => ({
    code: d,
    name: d,
  }));

const useCountryCodes = (
  codesToMatch: string[],
  lang: string,
): ReturnData[] => {
  // uses external API: https://restcountries.com/v3.1/

  const [countryCodes, setCountryCodes] = useState<ReturnData[]>([
    { code: "", name: "" },
  ]);
  const [rawApiData, setRawApiData] = useState<ApiData[]>();

  const fetchCountryCodes = async () => {
    try {
      const params = queryString.stringify(FIELDS);
      const res = await fetch(`https://restcountries.com/v3.1/all?${params}`);
      const data = await res.json();
      setRawApiData(data);
      setCountryCodes(parseData(data, codesToMatch, lang));
    } catch (error) {
      // fallback data in case the external API breaks down
      setCountryCodes(parseFallbackData(codesToMatch));
    }
  };

  useEffect(() => {
    fetchCountryCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rawApiData) {
      setCountryCodes(parseData(rawApiData, codesToMatch, lang));
    }
  }, [codesToMatch, lang, rawApiData]);

  return countryCodes;
};

export default useCountryCodes;
