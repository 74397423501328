import React from "react";

import MapIcon from "@mui/icons-material/Map";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  redirectControl: {
    zIndex: 9,
    position: "absolute",
    right: "15px",
  },
};

function MapRedirectControl(props) {
  const { classes, farmId, ngGoToMainMap, parcelId } = props;
  return (
    <div
      className={`ol-control ${classes.redirectControl}`}
      style={{ bottom: "145px" }}
    >
      <button
        data-test="main-map-redirect"
        onClick={() => ngGoToMainMap(farmId, parcelId)}
      >
        <MapIcon />
      </button>
    </div>
  );
}

MapRedirectControl.propTypes = {
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  parcelId: PropTypes.string,
  ngGoToMainMap: PropTypes.func.isRequired,
};

MapRedirectControl.defaultProps = {
  parcelId: "",
};

export default withStyles(styles)(MapRedirectControl);
