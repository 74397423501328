import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import SelectionItem from "../../common/SelectionItem/SelectionItem";

const styles = {
  blockNumber: {
    color: "#828282",
  },
  separator: {
    width: 10,
    display: "inline-block",
  },
};

class ParcelSelectorList extends Component {
  renderParcelItem = (fieldName, index, field) => {
    const { classes } = this.props;
    return (
      <SelectionItem
        editing={this.props.editing}
        key={fieldName}
        onRemoveItem={() => {
          this.props.onItemRemove(index);
        }}
      >
        <div>
          <span>{field.name}</span>
          <span className={classes.separator} />
          <span className={classes.blockNumber}>{field.blockNr}</span>
        </div>
      </SelectionItem>
    );
  };

  render() {
    const { fields } = this.props;
    return (
      <div id="parcel-selector-list">
        {fields.map((fieldName, index) => {
          const field = fields.get(index);
          return this.renderParcelItem(fieldName, index, field);
        })}
      </div>
    );
  }
}

ParcelSelectorList.propTypes = {
  classes: PropTypes.object,
  onItemRemove: PropTypes.func,
  fields: PropTypes.object.isRequired,
  editing: PropTypes.bool,
};

ParcelSelectorList.defaultProps = {
  onItemRemove: () => {},
  classes: {},
  editing: false,
};

export default withStyles(styles)(ParcelSelectorList);
