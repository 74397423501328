import React, { Component } from "react";

import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CfAutosuggest from "../../../../shared/components/common/CfAutosuggest/CfAutosuggest";

const styles = () => ({
  container: {
    height: "100%",
  },
  suggestionsContainerOpen: {
    margin: 0,
    "-webkit-box-shadow": "none",
    "-moz-box-shadow": "none",
    "box-shadow": "none",
    position: "static",
    height: "calc(100% - 52px)",
  },
  suggestionsContainer: {
    boxShadow: "none",
    "-webkit-box-shadow": "none",
    "-moz-box-shadow": "none",
    height: "calc(100% - 52px)",
  },
  suggestionsList: {
    maxHeight: "300px",
    maxWidth: "350px",
    marginBottom: 10,
  },
  suggestionItem: {
    fontSize: 14,
    paddingTop: 6,
    paddingBottom: 6,
    overflow: "inherit",
  },
  input: {
    fontSize: 14,
    padding: "10px 15px 5px 15px",
  },
  positionEnd: {
    right: "8px",
  },
  iconButton: {
    width: 36,
    height: 36,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export class ToolbarParcelSelector extends Component {
  getSuggestionValue = (sugg) =>
    [
      `${sugg.blockNr ? `${sugg.blockNr},` : null}${
        sugg.localName ? ` ${sugg.localName}` : null
      }`,
    ]
      .filter((item) => item)
      .join(", ");

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const placeholder = formatMessage({
      id: "ToolbarParcelSelector.placeholder",
    });
    return (
      <CfAutosuggest
        alwaysRenderSuggestions={true}
        autoFocus={true}
        clearInputOnAdornmentClick={true}
        clearInputOnSelect={true}
        clearSuggestions={this.props.onSuggestionClear}
        disableUnderline={true}
        getSuggestions={this.props.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced={true}
        multiSection={false}
        onSuggestionSelected={this.props.onSuggestionSelect}
        placeholder={placeholder}
        requiredLength={3}
        scrollable={true}
        scrollHeightMax={300}
        suggestions={this.props.suggestions.map((sugg) => ({ ...sugg }))}
        testData="parcel-selector"
        adornment={
          <IconButton
            className={classes.iconButton}
            disabled={true}
            size="large"
          >
            <SearchIcon className={classes.icon} />
          </IconButton>
        }
        classes={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsContainer: classes.suggestionsContainer,
          suggestionsList: classes.suggestionsList,
          suggestionItem: classes.suggestionItem,
          input: classes.input,
          positionEnd: classes.positionEnd,
        }}
      />
    );
  }
}

ToolbarParcelSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  getSuggestions: PropTypes.func.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
};

ToolbarParcelSelector.defaultProps = {
  suggestions: [],
};

export default injectIntl(withStyles(styles)(ToolbarParcelSelector));
