import { RESET_DRIVES, SET_FOCUSED_ROW } from "./drives.constants";

const drivesReset = () => ({
  type: RESET_DRIVES,
});

const setFocusedRow = (payload?: string) => ({
  type: SET_FOCUSED_ROW,
  payload,
});

export { drivesReset, setFocusedRow };
