import React, { Component } from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import * as stages from "../../constants/stages.constants";
import * as tools from "../../constants/tools.constants";

import SnackbarText from "../SnackbarText/SnackbarText";

const tool = tools.SPLIT;

class SplitHint extends Component {
  getHint = (stage, data) => {
    switch (stage) {
      case stages.STAGE_1:
        return (
          <SnackbarText color="light">
            <FormattedMessage
              id={`hint.${tool}_${stage}_PT1`}
              values={{
                pt2: (
                  <SnackbarText color="dark">
                    <FormattedMessage id={`hint.${tool}_${stage}_PT2`} />
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );
      case stages.STAGE_2:
        return (
          <SnackbarText color="light">
            <FormattedMessage id={`hint.${tool}_${stage}`} />
          </SnackbarText>
        );
      case stages.STAGE_3:
        return (
          <SnackbarText color="dark">
            <FormattedMessage
              id={`hint.${tool}_${stage}`}
              values={{
                parcel: (
                  <SnackbarText color="light">
                    {data.main.localName}
                  </SnackbarText>
                ),
                parcels: (
                  <SnackbarText color="light">
                    {data.results.map((p) => p.localName).join(", ")}
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );

      default:
        return null;
    }
  };

  render() {
    const { data, stage } = this.props;
    return this.getHint(stage, data);
  }
}

SplitHint.propTypes = {
  stage: PropTypes.string,
  data: PropTypes.object,
};

SplitHint.defaultProps = {
  stage: null,
  data: null,
};

export default SplitHint;
