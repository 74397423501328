import combineIcon from "../../assets/img/icons/telematics/combine.svg";
import loaderIcon from "../../assets/img/icons/telematics/loader.svg";
import otherIcon from "../../assets/img/icons/telematics/other.svg";
import passengerIcon from "../../assets/img/icons/telematics/passenger.svg";
import tractorIcon from "../../assets/img/icons/telematics/tractor.svg";
import truckIcon from "../../assets/img/icons/telematics/truck.svg";

import { CatalogueType } from "../../shared/api/agroevidence/agroevidence.types";
import { MachineGroupCode } from "../../shared/api/telematics/telematics.types";

export const getMachineIcon = (group: string) => {
  switch (group) {
    case MachineGroupCode.COMBINE:
      return combineIcon;
    case MachineGroupCode.LOADER:
      return loaderIcon;
    case MachineGroupCode.OTHER:
      return otherIcon;
    case MachineGroupCode.PASSENGER:
      return passengerIcon;
    case MachineGroupCode.TRACTOR:
      return tractorIcon;
    case MachineGroupCode.TRUCK:
      return truckIcon;
    default:
      return null;
  }
};

export const getSourceId = (type?: string) => {
  switch (type) {
    case CatalogueType.GLOBAL:
      return "Catalogues.table.shared.source.global";
    case CatalogueType.PRIVATE:
      return "Catalogues.table.shared.source.private";
    case CatalogueType.EAGRI:
      return "Catalogues.table.shared.source.eAgri";
    default:
      return "";
  }
};
