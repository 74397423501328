import { AnyAction } from "redux";

import * as types from "./subtractableAreas.constants";

const initialState = {
  subtraction: undefined,
  isFetchinSubtraction: false,
  parcelHistorySubtractions: [],
  isFetchingParcelHistorySubtractions: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.POST_SUBTRACTABLE_AREA_REQUEST:
        return {
          ...state,
          isFetchinSubtraction: true,
        };
      case types.POST_SUBTRACTABLE_AREA_SUCCESS:
        return {
          ...state,
          isFetchinSubtraction: false,
          subtraction: action.payload,
        };
      case types.POST_SUBTRACTABLE_AREA_ERROR:
        return {
          ...state,
          isFetchinSubtraction: false,
        };

      case types.GET_PARCEL_HISTORY_SUBTRACTIONS_REQUEST:
        return {
          ...state,
          isFetchingParcelHistorySubtractions: true,
        };
      case types.GET_PARCEL_HISTORY_SUBTRACTIONS_SUCCESS:
        return {
          ...state,
          isFetchingParcelHistorySubtractions: false,
          parcelHistorySubtractions: action.payload,
        };
      case types.GET_PARCEL_HISTORY_SUBTRACTIONS_ERROR:
        return {
          ...state,
          isFetchingParcelHistorySubtractions: false,
        };
      case types.RESET_PARCEL_HISTORY_SUBTRACTIONS:
        return {
          ...state,
          parcelHistorySubtractions: [],
        };

      default:
        return state;
    }
  };
