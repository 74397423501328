import { createSelector } from "reselect";

import ErrorService from "../../../services/Error.service";

export const getItems = (state) => state.api.variableApplication.items;
export const getIsFetching = (state) =>
  state.api.variableApplication.isFetching;
export const getError = (state) =>
  ErrorService.getResErrorDto(state.api.variableApplication.error);

export const getVariableExpense = (state) =>
  state.api.variableApplication.variableExpense;
export const getVariableExpenseIsFetching = (state) =>
  state.api.variableApplication.isVariableExpenseFetching;
export const getVariableExpenseIsError = (state) =>
  ErrorService.getResErrorDto(
    state.api.variableApplication.variableExpenseError,
  );

export const getVariableActionIds = (state) =>
  state.api.variableApplication.actionIds;

export const getVariableExpensesIsError = (state) =>
  ErrorService.getResErrorDto(
    state.api.variableApplication.variableExpensesError,
  );

export const getVariableExpenseSaveIsFetching = (state) =>
  state.api.variableApplication.saveVariableExpenseIsFetching;

export const getVariableExpensesIsFetching = (state) =>
  state.api.variableApplication.variableExpensesIsFetching;
export const getCreateVariableExpensesIsFetching = (state) =>
  state.api.variableApplication.isVariableExpenseFetching;

export const getVariableActionIdsError = (state) =>
  ErrorService.getResErrorDto(state.api.variableApplication.errorActions);

export const getAgiJobs = (state) => state.api.variableApplication.agiJobs;

export const getAgiMachines = (state) =>
  state.api.variableApplication.agiMachines;

export const getIsExportingVA = (state) =>
  state.api.variableApplication.isExportingVA;

export const getAgiJobsByApplicationId = createSelector(
  [getAgiJobs, (_, ownProps) => ownProps.applicationId],
  (jobs, applicationId) => jobs?.[applicationId]?.result || {},
);

export const getAgiJobsStatusByApplicationId = createSelector(
  [getAgiJobs, (_, ownProps) => ownProps.applicationId],
  (jobs, applicationId) => jobs?.[applicationId]?.isFetching || false,
);
