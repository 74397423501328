export const GREATER_THAN = "GREATER_THAN";
export const GREATER_THAN_OR_EQUALS = "GREATER_THAN_OR_EQUALS";
export const LESS_THAN = "LESS_THAN";
export const LESS_THAN_OR_EQUALS = "LESS_THAN_OR_EQUALS";
export const EQUALS = "EQUALS";
export const NOT_EQUALS = "NOT_EQUALS";

export const conditionOperators = [
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS,
  EQUALS,
  NOT_EQUALS,
];

export const conditionOperatorsCharacters = {
  GREATER_THAN: ">",
  GREATER_THAN_OR_EQUALS: "≥",
  LESS_THAN: "<",
  LESS_THAN_OR_EQUALS: "≤",
  EQUALS: "=",
  NOT_EQUALS: "≠",
};
