import React from "react";

import moment from "moment";
import ProductFruits from "react-product-fruits";

const LANGUAGES = {
  "cs-CZ": "cs",
  "en-US": "en",
  "sr-LATN-RS": "en",
  "es-ES": "es",
  "ru-UA": "en",
  "el-GR": "en",
  "ro-RO": "en",
  "hu-HU": "en",
};

const ProductFruitsProvider = ({
  account,
  children,
  farm,
  langId,
  projectId,
}) => {
  if (!account || !projectId) {
    return children;
  } else {
    const createdDate =
      account?.createdDate &&
      moment(account.createdDate * 1000).format("YYYY-MM-DD");
    const isAdmin = account?.authorities.includes("ROLE_ADMIN");
    const userInfo = {
      username: account?.login,
      email: account?.email,
      firstName: account?.firstName,
      lastName: account?.lastName,
      role: isAdmin ? "admin" : "user",
      signUpAt: createdDate,
    };

    const customProps = {
      farmId: farm?.id,
      farmName: farm?.name,
      farmCountryCode: farm?.customer?.countryCode,
      userLanguage: LANGUAGES[langId],
    };

    return (
      <>
        <ProductFruits
          language={LANGUAGES[langId]}
          projectCode={projectId}
          props={customProps}
          {...userInfo}
        />
        {children}
      </>
    );
  }
};

export default ProductFruitsProvider;
