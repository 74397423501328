import * as types from "./countries.constants";

export default () =>
  (
    state = {
      isFetchingCountries: false,
      errorCountries: {},
      items: [],
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_COUNTRIES:
        return {
          ...state,
          isFetchingCountries: true,
          errorCountries: {},
        };
      case types.GET_COUNTRIES_SUCCESS:
        return {
          ...state,
          isFetchingCountries: false,
          items: action.payload,
        };
      case types.GET_COUNTRIES_FAILURE:
        return {
          ...state,
          isFetchingCountries: false,
          errorCountries: action.payload,
        };
      case types.RESET_COUNTRIES:
        return {
          ...state,
          items: [],
        };
      default:
        return state;
    }
  };
