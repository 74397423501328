import { RSAA } from "redux-api-middleware";

import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";
import {
  getStates,
  getStatesForApiCall,
} from "../precision/precision.constants";

export const getSoilSamplesDateApi = (parcelId: string, year: string) => ({
  [RSAA]: {
    endpoint: `soil-samples/${parcelId}?year=${year}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: getStatesForApiCall("GET_SOIL_SAMPLES"),
  },
});

export const resetSoilSamplesDate = () => ({
  type: getStates("GET_SOIL_SAMPLES").RESET,
});

export const getSoilSamplesYears = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `soil-samples/${parcelId}/years?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: getStatesForApiCall("GET_SOIL_SAMPLES_YEARS"),
  },
});

export const resetSoilSamplesYearsDate = () => ({
  type: getStates("GET_SOIL_SAMPLES_YEARS").RESET,
});
