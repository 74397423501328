export const links = {
  cs: {
    precisionFarming:
      "https://www.cleverfarm.ag/cs/produkty/precizni-zemedelstvi",
    sensors: "https://www.cleverfarm.ag/cs/produkty/senzory",
    homepage: "https://www.cleverfarm.ag/cs",
    telematics: "https://www.cleverfarm.ag/cs/produkty/telematika",
    irrigation: "https://www.cleverfarm.ag/cs/produkty/cleverirrigation",
  },
  en: {
    precisionFarming: "https://www.cleverfarm.ag/products/precision-farming",
    sensors: "https://www.cleverfarm.ag/products/sensors",
    homepage: "https://www.cleverfarm.ag/",
    telematics: "https://www.cleverfarm.ag/products/telematics",
    irrigation: "https://www.cleverfarm.ag/products/cleverirrigation",
  },
};
