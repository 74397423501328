export const API_MODULES = {
  GATEWAY: "gateway",
  CORE: "core",
  AGROEVIDENCE: "agroevidence",
  IOT: "iot",
  STORES: "stores",
  WEATHER: "weather",
  SENTINEL: "sentinel",
  AUTOMATION: "automation",
  IRRIGATION: "irrigation",
  TELEMATICS: "telematics",
} as const;

export const API_METHODS = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const CZ_LPIS = "lpis:pudni_bloky_cur";
export const SK_LPIS = "lpis:pudni_bloky_sk";

export const CZ_LPIS_PROD = "farm:pudni_bloky_cur";
export const SK_LPIS_PROD = "farm:pudni_bloky_sk";
