import humanizeDuration from "humanize-duration";
import { useIntl } from "react-intl";

const useAsAppliedDuration = (duration: number) => {
  const intl = useIntl();

  const shortHumanizer = humanizeDuration.humanizer({
    language: "short",
    units: ["h", "m", "s"],
    round: true,
    languages: {
      short: {
        h: () =>
          intl.formatMessage({ id: "PrecisionFarming.asApplied.tasks.hod" }),
        m: () =>
          intl.formatMessage({ id: "PrecisionFarming.asApplied.tasks.min" }),
        s: () =>
          intl.formatMessage({ id: "PrecisionFarming.asApplied.tasks.sec" }),
      },
    },
  });

  const customShortHumanizer = (durationInSeconds: number) => {
    if (durationInSeconds < 60) {
      return shortHumanizer(durationInSeconds * 1000, { units: ["s"] });
    } else if (durationInSeconds < 3600) {
      return shortHumanizer(durationInSeconds * 1000, { units: ["m"] });
    } else {
      return shortHumanizer(durationInSeconds * 1000, { units: ["h"] });
    }
  };

  return {
    duration: customShortHumanizer(duration),
  };
};

export default useAsAppliedDuration;
