import React, { FC, useContext } from "react";

import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import { TELEMATICS_URLS } from "../../telematics.constants";

import { createHandworkApi } from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { AsyncFn, Thunk } from "../../../types";
import EditBox from "../../components/TelematicsAggregationDetailContent/EditBox";
import TelematicsHandworkForm from "../../components/TelematicsHandwork/TelematicsHandworkForm";
import { TelematicsContext } from "../Telematics/Telematics";
import { useTelematicsAggregationHandworkDetailStyles } from "../TelematicsAggregationDetail/handworkStyles";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { HandworkCreateTo } from "../../../shared/api/telematics/telematics.types";

interface Props {
  createHandworkApi: (data: HandworkCreateTo) => void;
}

const TelematicsHandworkNew: FC<Props> = ({ createHandworkApi }) => {
  const classes = useTelematicsAggregationHandworkDetailStyles();
  const history = useHistory();
  const { farmId } = useContext(TelematicsContext);
  const showSnackbar = useContext(SnackbarContext);

  const handleResponse = (res: RSAAResultAction<{ id: number }>) => {
    if (!res.error) {
      history.push(
        `/farm/${farmId}/${TELEMATICS_URLS.drivers}/${res.payload.id}`,
      );
    } else {
      showSnackbar({
        message: <FormattedMessage id="TelematicsDetail.createHandworkError" />,
        isError: true,
      });
    }
  };

  const handleSave = (data: HandworkCreateTo) =>
    (createHandworkApi as AsyncFn<HandworkCreateTo>)(data).then(
      (res: RSAAResultAction<{ id: number }>) => handleResponse(res),
    );

  const handleGoBack = () =>
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}`);

  return (
    <Grid className={classes.wrapper} container>
      <Grid className={classes.bodyWrapper} item xs={12}>
        <div className={classes.body}>
          <div className={classes.headerWithTabs}>
            <div className={classes.headerWrapper}>
              <PageHeader
                backButton={<CfBackButton onClick={handleGoBack} />}
                heading={
                  <PageHeading
                    value={<FormattedMessage id="Telematics.handwork.new" />}
                  />
                }
              />
            </div>
          </div>
          <Grid className={classes.form} container>
            <Grid item xs={6}>
              <EditBox
                disabled={false}
                headingId="TelematicsAggregations.detail.section.operation"
                isApproved={false}
                isEditing={true}
                showEditButton={false}
                visible={true}
              >
                <TelematicsHandworkForm
                  approvalValidationErrors={{}}
                  driveDetail={undefined}
                  driveKeys={undefined}
                  formPath={undefined}
                  handleReset={handleGoBack}
                  handleSave={handleSave}
                />
              </EditBox>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      createHandworkApi,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TelematicsHandworkNew);
