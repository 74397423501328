import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  getSectionListSearch,
  getSectionListPage,
  getSectionListRowsPerPage,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListAdvancedFilter,
} from "../selectors/sectionList.selectors";

import { getSelectedNamespace } from "../reducers/namespace.reducer";

import { getCropsV2Api } from "../../shared/api/agroevidence/catalogues/crops/crops.api";
import {
  getFertilizerApi,
  getFertilizersApi,
  resetFertilizerApi,
  resetFertilizersApi,
  updateFertilizerApi,
  createFertilizerApi,
  patchFertilizerApi,
} from "../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api";
import {
  getActiveSubstancesApi,
  getBioFunctionApi,
  getOrganismsApi,
  getPlantProtectionV2Api,
  patchPlanProtectionApi,
  resetPlantProtectionApi,
} from "../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import {
  createSeedApi,
  getSeedsV2Api,
  resetSeedsApi,
  updateSeedApi,
} from "../../shared/api/agroevidence/catalogues/seeds/seeds.api";
import { getShortDateString } from "../../shared/misc/timeHelpers";

import { CataloguesState } from "../../reducers/catalogues.reducer.types";
import {
  ActiveSubstanceTo,
  CropSeedCreateTo,
  CropSeedPatchTo,
  CropTo,
  EagriFertilizerNitrogenCategoryTo,
  FertilizerCreateTo,
  FertilizerPatchTo,
  OrganismTo,
  PlantProtectionPatchTo,
} from "../../shared/api/agroevidence/agroevidence.types";
import { getFertilizersParams } from "../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.types";
import { getPlantProtectionParams } from "../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.types";
import { getSeedsParams } from "../../shared/api/agroevidence/catalogues/seeds/seeds.types";

// ---------- FERTILIZERS CATALOGUES SECTION --------------//

export const fetchFertilizers =
  (validOn?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getFertilizersParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params.organic = advancedFilter.isOrganic?.state;
      params["eagri-nitrogen-category"] = advancedFilter.nitrogenCategory
        ?.map((nc: EagriFertilizerNitrogenCategoryTo) => nc.code)
        ?.join(",");
      params.catalogue = advancedFilter.source?.type;
    }

    if (validOn) {
      params["valid-on"] = validOn;
    }

    dispatch(getFertilizersApi(params));
  };

export const resetFertilizers = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetFertilizersApi());

export const fetchFertilizer =
  (fertilizerId: string) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(getFertilizerApi(fertilizerId));

export const resetFertilizer = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetFertilizerApi());

export const updateFertilizer =
  (fertilizerId: string, params: FertilizerCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updateFertilizerApi(fertilizerId, params));

export const patchFertilizer =
  (fertilizerId: string, params: FertilizerPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(patchFertilizerApi(fertilizerId, params));

export const createFertilizer =
  (params: FertilizerCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createFertilizerApi(params));

// ---------- SEEDS CATALOGUES SECTION --------------//

export const fetchSeeds =
  (validOn?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getSeedsParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params["external-ids"] = advancedFilter.crops
        ?.map((nc: CropTo) => nc.externalId)
        ?.join(",");
      params.catalogue = advancedFilter.source?.type;
    }

    if (validOn) {
      params["valid-on"] = validOn;
    }

    dispatch(getSeedsV2Api(params));
  };

export const resetSeeds = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetSeedsApi());

export const updateSeed =
  (seedId: string, params: CropSeedPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updateSeedApi(seedId, params));

export const createSeed =
  (params: CropSeedCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createSeedApi(params));

export const fetchCrops =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    const validOn = getShortDateString();
    dispatch(getCropsV2Api(search, validOn));
  };

// ---------- PLAN PROTECTION CATALOGUES SECTION --------------//

export const fetchPlantProtection =
  (usableUntil?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getPlantProtectionParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params["organism-id"] = advancedFilter.organisms
        ?.map((o: OrganismTo) => o.id)
        ?.join(",");
      params["active-substance-id"] = advancedFilter.activeSubstances
        ?.map((as: ActiveSubstanceTo) => as.id)
        ?.join(",");
    }

    if (usableUntil) {
      params["usable-until"] = usableUntil;
    }

    dispatch(getPlantProtectionV2Api(params));
  };

export const resetPlantProtection =
  () => (dispatch: (action: AnyAction) => void) =>
    dispatch(resetPlantProtectionApi());

export const patchPlanProtection =
  (planProtectionId: string, params: PlantProtectionPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(patchPlanProtectionApi(planProtectionId, params));

export const fetchActiveSubstances =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getActiveSubstancesApi(search));
  };

export const fetchOrganisms =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getOrganismsApi(search));
  };

export const fetchBioFunction =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getBioFunctionApi(search));
  };
