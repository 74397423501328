import React from "react";

import PropTypes from "prop-types";

export default (ctx, propName, propTypes) => {
  const Provider = ({ children, context, [propName]: value }) => {
    const contextValue = value;

    const Context = context || ctx;
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };

  if (process.env.NODE_ENV !== "production") {
    Provider.propTypes = {
      children: PropTypes.any.isRequired,
      // eslint-disable-next-line
      context: PropTypes.object,
      ...propTypes,
    };
  }

  return Provider;
};
