import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { SensorFilterByNodeType } from "../../../shared/components/specific/SensorFilterByNodeType/SensorFilterByNodeType";
import { SensorFilterByStatus } from "../../../shared/components/specific/SensorFilterByStatus/SensorFilterByStatus";
import CfFilter from "../../../shared/containers/CfFilter/CfFilter";
import DeviceTypesService from "../../../shared/services/DeviceTypes.service";
import NodeLocationSelectGroups from "../../components/NodeLocationSelectGroups/NodeLocationSelectGroups";

export const NodeLocationsAdvancedFilter = ({
  advancedFilter,
  isHistoryActive,
  langId,
  namespace,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [advancedFilterState, setAdvancedFilterState] =
    useState(advancedFilter);

  useEffect(() => {
    setAdvancedFilterState(advancedFilter);
  }, [advancedFilter]);

  const deviceGroups = DeviceTypesService.getDeviceGroups();
  const nodeTypes = deviceGroups.map((deviceGroup) => ({
    name: deviceGroup,
    label: intl.formatMessage({ id: `DeviceGroup.${deviceGroup}` }),
  }));

  const handleChangeStatus = (items) => {
    setAdvancedFilterState({ ...advancedFilterState, status: items });
  };
  const handleChangeNodeType = (items) => {
    setAdvancedFilterState({ ...advancedFilterState, nodeType: items });
  };
  const handleChangeGroups = (items) => {
    setAdvancedFilterState({ ...advancedFilterState, groups: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              isHistoryActive ? (
                <FormattedMessage id="SensorsAdvancedFilter.statusDisabledExplanation" />
              ) : (
                ""
              )
            }
          >
            <Grid className={classes.select} item xs={12}>
              <SensorFilterByStatus
                defaultStatus={advancedFilter.status}
                disabled={isHistoryActive}
                label={<FormattedMessage id="common.state" />}
                onChange={handleChangeStatus}
                useLabelTranslation={true}
              />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <SensorFilterByNodeType
              defaultNodeType={advancedFilter.nodeType}
              label={<FormattedMessage id="common.type" />}
              nodeTypes={nodeTypes}
              onChange={handleChangeNodeType}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <NodeLocationSelectGroups
              defaultGroups={advancedFilter.groups}
              label={<FormattedMessage id="NodeGroups.group" />}
              onChange={handleChangeGroups}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

NodeLocationsAdvancedFilter.propTypes = {
  namespace: PropTypes.string,
  advancedFilter: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  isHistoryActive: PropTypes.bool.isRequired,
};

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
  tooltip: {
    marginBottom: 4,
  },
});
