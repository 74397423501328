import { RSAAAction } from "redux-api-middleware";

import {
  getParcelListPage,
  getParcelListOrder,
  getParcelListOrderBy,
  getParcelListRowsPerPage,
  getParcelListTextFilter,
  getParcelListAdvancedFilter,
} from "../selectors/parcelList.selectors";

import { getCropsApi } from "../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import {
  exportParcelsApi,
  getParcelsApi,
} from "../../../../shared/api/agroevidence/parcels/parcels.api";
import FileService from "../../../../shared/services/File.service";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import {
  CenterTo,
  CropTo,
  LandUseResponse,
  ParcelSuggestionTo,
  ZoneTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";
import { ExportPayload } from "../../../../shared/api/api.types";

export const fetchParcels =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => ParcelsState) => {
    const state = getState();
    const advancedFilter = getParcelListAdvancedFilter(state);
    const params = {
      page: getParcelListPage(state) + 1,
      "sort-dir": getParcelListOrder(state),
      "sort-col": getParcelListOrderBy(state),
      "per-page": getParcelListRowsPerPage(state),
      search: getParcelListTextFilter(state),
      ids: advancedFilter.parcel
        ?.map((parcel: ParcelSuggestionTo) => parcel.id)
        ?.join(","),
      crops: advancedFilter.crops
        ?.map((crop: CropTo) => crop.legislativeCode)
        ?.join(","),
      "nitrate-vulnerable": advancedFilter.nsa?.state,
      zones: advancedFilter.zones?.map((zone: ZoneTo) => zone.id)?.join(","),
      centers: advancedFilter.centers
        ?.map((center: CenterTo) => center.id)
        ?.join(","),
      "land-use": advancedFilter.landUse
        ?.map((center: LandUseResponse) => center.key)
        ?.join(","),
    };
    return dispatch(getParcelsApi(params));
  };

export const fetchCrops =
  (search: string) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(getCropsApi(search, true));

export const exportParcels =
  () => (dispatch: (action: RSAAAction) => Promise<ExportPayload>) => {
    dispatch(exportParcelsApi()).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };
