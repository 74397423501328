export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR";

export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_ERROR = "CREATE_TRANSACTION_ERROR";

export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_ERROR = "UPDATE_TRANSACTION_ERROR";

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_ERROR = "DELETE_TRANSACTION_ERROR";

export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS";
