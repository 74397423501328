import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import { TELEMATICS_URLS } from "./telematics.constants";

import { NAMESPACE } from "./reducer/telematicsTabs.reducer";
import reducer from "../reducers/telematics.reducer";

import CfApp from "../shared/containers/CfApp/CfApp";
import UiCacheService from "../shared/services/UiCache.service";

import TelematicsWrapper from "./containers/TelematicsWrapper/TelematicsWrapper";

TelematicsController.$inject = [
  "$element",
  "$scope",
  "$state",
  "$rootScope",
  "$transitions",
  "Localization",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function TelematicsController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    ngResetFilters,
    ngGoToMainMap,
    ngGoToAction,
    farmId: this.farmId,
    farm: this.farm,
    initDateFrom: this.dateFrom,
    initDateTo: this.dateTo,
    initMachineFilter: this.machine,
    reducer,
    ngRedirectToMainMapWithFilters,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: "telematics",
  };

  const cachedTelematics = UiCacheService.getCachePerSection("telematics");
  const currentUrl = $state.href($state.current.name, $state.params, {
    absolute: false,
  });
  const isTelListing =
    currentUrl.endsWith(TELEMATICS_URLS.machines) ||
    currentUrl.endsWith(TELEMATICS_URLS.drivers) ||
    currentUrl.endsWith(TELEMATICS_URLS.logbook);

  if (isTelListing && cachedTelematics && NAMESPACE in cachedTelematics) {
    const tabToGo = cachedTelematics[NAMESPACE]?.lastVisitedTab;
    if (tabToGo) {
      $state.go(`farm.active.telematics.${tabToGo}`);
    }
  }

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onBefore(
    {},
    (transition) => {
      // hack because of behaviour of ui-router vs react-router
      // we need to block transition inside telematics - when we want to switch farm, it is superseded
      const sensorsState = "farm.active.telematics";
      const transitionFrom = transition.from();
      const transitionTo = transition.to();
      const transitionParams = transition.params();
      if (
        transitionFrom.name.startsWith(sensorsState) &&
        transitionTo.name.startsWith(sensorsState) &&
        transitionParams.farmId === props.farmId
      ) {
        transition.abort();
      }
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    // TODO @CFD-53 in case of problems with routing consider using deregisterOnTransitionSuccess
    // method from other section controllers
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngGoToMainMap(farmId, parcelId) {
    $state.go("farm.active.map", { farmId, parcelId });
  }

  function ngGoToAction(actionId) {
    return $state.go("farm.active.actions.action", { actionId });
  }

  function ngRedirectToMainMapWithFilters(
    dateFrom,
    dateTo,
    machine,
    driver,
    openInNewPage,
  ) {
    if (openInNewPage && window) {
      // Otevření v novém okně
      const url = $state.href(
        "farm.active.map",
        { dateFrom, dateTo, machine, driver },
        { absolute: true },
      );
      window.open(url, "_blank");
    } else {
      // Otevření v aktuálním okně
      $state.go("farm.active.map", { dateFrom, dateTo, machine, driver });
    }
  }

  function ngResetFilters() {
    props.initMachineFilter = null;
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <TelematicsWrapper {...p} />} />,
      $element[0],
    );
  }
}
