import React, { Component, Fragment } from "react";

import Divider from "@mui/material/Divider";
import { withStyles } from "@mui/styles";
import { Field } from "formik";
import { toNumber, round } from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import AbsoluteParcelSubtractableAreas from "../../components/AbsoluteParcelSubtractableAreas/AbsoluteParcelSubtractableAreas";
import BoundaryParcelSubtractableAreas from "../../components/BoundaryParcelSubtractableAreas/BoundaryParcelSubtractableAreas";
import WaterParcelSubtractableAreas from "../../components/WaterParcelSubtractableAreas/WaterParcelSubtractableAreas";

const styles = {
  divider: {
    margin: 10,
  },
};

class ActionParcelSubtractableAreas extends Component {
  componentDidUpdate(prevProps) {
    const { form, intl, parcel, parcelIndex: index } = this.props;
    const { parcel: prevParcel } = prevProps;
    const prevSubtractableAreas = prevParcel.subtractableAreas;
    const subtractableAreas = parcel.subtractableAreas;
    if (prevSubtractableAreas !== subtractableAreas) {
      const restrictedArea = [
        ...subtractableAreas.absolute
          .filter((item) => item.isUsed)
          .map((item) => item.area),
        ...subtractableAreas.boundary
          .filter(
            (item) =>
              toNumber(item.value) ===
              toNumber(subtractableAreas.boundaryChecked),
          )
          .map((item) => item.area),
        ...subtractableAreas.water
          .filter(
            (item) =>
              toNumber(item.value) === toNumber(subtractableAreas.waterChecked),
          )
          .map((item) => item.area),
      ].reduce((sum, area) => sum + area, 0);

      const parcelTotalArea = form.values.parcels[index].area;
      const newActionParcelTotalArea =
        parcelTotalArea - round(restrictedArea, 3);
      form.setFieldValue(
        `parcels.${index}.actionParcelTotalArea`,
        newActionParcelTotalArea,
      );
      if (newActionParcelTotalArea <= 0) {
        form.setFieldError(
          `parcels.${index}.actionParcelTotalArea`,
          intl.formatMessage({ id: "SubtractableArea.error" }),
        );
      } else {
        form.setFieldError(`parcels.${index}.actionParcelTotalArea`, undefined);
      }
    }
  }

  render() {
    const {
      classes,
      isEditing,
      maxValue,
      name,
      onParcelSubtractionDelete,
      parcelId,
      parcelIds,
      parcelIndex,
    } = this.props;
    return (
      <Fragment>
        <Field
          data-test="subtraction-section"
          name={`${name}.subtractableAreas`}
        >
          {({ form }) => (
            <Fragment>
              <AbsoluteParcelSubtractableAreas
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                onParcelSubtractionDelete={onParcelSubtractionDelete}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
              <Divider className={classes.divider} />
              <BoundaryParcelSubtractableAreas
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                onParcelSubtractionDelete={onParcelSubtractionDelete}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
              <Divider className={classes.divider} />
              <WaterParcelSubtractableAreas
                form={form}
                isEditing={isEditing}
                maxValue={maxValue}
                onParcelSubtractionDelete={onParcelSubtractionDelete}
                parcelId={parcelId}
                parcelIds={parcelIds}
                parcelIndex={parcelIndex}
              />
            </Fragment>
          )}
        </Field>
      </Fragment>
    );
  }
}

ActionParcelSubtractableAreas.propTypes = {
  classes: PropTypes.object,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  parcel: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  onParcelSubtractionDelete: PropTypes.func.isRequired,
};

ActionParcelSubtractableAreas.defaultProps = {
  classes: {},
  formValues: {},
  isEditing: false,
  maxArea: 0,
  parcelId: "",
  subtractableArea: {},
};

export default withStyles(styles)(injectIntl(ActionParcelSubtractableAreas));
