import moment from "moment";

import {
  getError,
  getLastCurrentSeedDate,
  getValidFrom,
  getValidFromError,
} from "../../selectors/editor.selectors";

import { bufferStart, bufferEnd, bufferSubmit } from "../buffer/buffer.actions";
import { drawStart, drawEnd, drawSubmit } from "../draw/draw.actions";
import { removeEL } from "../eventListener/eventListener.actions";
import {
  setDoubleClickZoomIA,
  removeDoubleClickZoomIA,
} from "../interaction/interaction.actions";
import { refreshLayers } from "../layers/layers.actions";
import {
  clearParcelDetail,
  setDefaultHoverEL,
  setDefaultSelectEL,
  setMapFetching,
} from "../map/map.actions";
import { measureStart, measureEnd } from "../measurement/measurement.actions";
import { mergeStart, mergeEnd, mergeSubmit } from "../merge/merge.actions";
import { removeOverlays } from "../overlay/overlay.actions";
import { splitStart, splitEnd, splitSubmit } from "../split/split.actions";
import { refreshDefaultStyles } from "../style/style.actions";

import * as types from "./editor.constants";
import * as errors from "../../constants/errors.constants";
import * as tools from "../../constants/tools.constants";

export const HINT_ACTIONS = {
  REOPEN: "REOPEN",
  REFRESH: "REFRESH",
  CLOSE: "CLOSE",
};

export const editorSetTool = (tool) => ({
  type: types.EDITOR_SET_TOOL,
  tool,
});

export const editorSetStage = (stage) => ({
  type: types.EDITOR_SET_STAGE,
  stage,
});

export const editorSetError = (error) => ({
  type: types.EDITOR_SET_ERROR,
  error,
});

export const editorHintReopen = (data) => ({
  type: types.EDITOR_HINT_SET_ACTION,
  action: HINT_ACTIONS.REOPEN,
  data,
});

export const editorHintRefresh = (data) => ({
  type: types.EDITOR_HINT_SET_ACTION,
  action: HINT_ACTIONS.REFRESH,
  data,
});

export const editorHintClose = () => ({
  type: types.EDITOR_HINT_SET_ACTION,
  action: HINT_ACTIONS.CLOSE,
  data: null,
});

export const editorSetHoveredId = (hoveredId) => ({
  type: types.EDITOR_SET_HOVERED_ID,
  hoveredId,
});

export const editorSetMapDragging = (isMapDragging) => ({
  type: types.EDITOR_SET_MAP_DRAGGING,
  isMapDragging,
});

export const editorSetSelected = (selected, rewrite = true) => ({
  type: types.EDITOR_SET_SELECTED,
  selected,
  rewrite,
});

export const setValidFrom = (validFrom) => ({
  type: types.EDITOR_SET_VALID_FROM,
  validFrom,
});

export const editorSetValidFromError = (error) => ({
  type: types.EDITOR_SET_VALID_FROM_ERROR,
  error,
});

export const editorToolStart = (toolName, countryCode) => (dispatch) => {
  dispatch(clearParcelDetail());
  dispatch(removeDoubleClickZoomIA());
  dispatch(removeEL("defaultHoverELKey"));
  dispatch(removeEL("defaultSelectELKey"));
  dispatch(editorSetTool(toolName));

  if (toolName === tools.SPLIT) {
    dispatch(splitStart());
  }

  if (toolName === tools.MERGE) {
    dispatch(mergeStart(countryCode));
  }

  if (toolName === tools.DRAW) {
    dispatch(drawStart(countryCode));
  }

  if (toolName === tools.BUFFER) {
    dispatch(bufferStart());
  }

  if (toolName === tools.MEASURE) {
    dispatch(measureStart());
  }
};

export const editorToolSubmit = (toolName) => (dispatch) => {
  dispatch(setMapFetching(true));
  dispatch(submitActiveTool(toolName))
    .then(handleResponse)
    .then(() => {
      dispatch(refreshLayers());
      dispatch(editorToolEnd(toolName));
    })
    .catch((payload) => dispatch(handleGenericError(payload, toolName)))
    .catch((reason) => dispatch(showErrorHint(reason)))
    .finally(() => dispatch(setMapFetching(false)));
};

const submitActiveTool = (toolName) => (dispatch) => {
  if (toolName === tools.SPLIT) {
    return dispatch(splitSubmit());
  }

  if (toolName === tools.MERGE) {
    return dispatch(mergeSubmit());
  }

  if (toolName === tools.DRAW) {
    return dispatch(drawSubmit());
  }

  if (toolName === tools.BUFFER) {
    return dispatch(bufferSubmit());
  }
};

export const editorToolEnd = (toolName) => (dispatch) => {
  if (toolName === tools.SPLIT) {
    dispatch(splitEnd());
  }

  if (toolName === tools.MERGE) {
    dispatch(mergeEnd());
  }

  if (toolName === tools.DRAW) {
    dispatch(drawEnd());
  }

  if (toolName === tools.BUFFER) {
    dispatch(bufferEnd());
  }

  if (toolName === tools.MEASURE) {
    dispatch(measureEnd());
  }

  dispatch(editorSetSelected([]));
  dispatch(editorSetHoveredId(null));
  dispatch(refreshDefaultStyles());

  dispatch(setDoubleClickZoomIA());
  dispatch(setDefaultSelectEL());
  dispatch(setDefaultHoverEL());
  dispatch(editorSetTool(null));
  dispatch(editorSetError(null));
  dispatch(editorSetStage(null));
  dispatch(editorSetValidFromError(null));
};

/** ********* shared ********* */

export const showErrorHint =
  (reason, reopen = true) =>
  (dispatch) => {
    dispatch(removeOverlays());
    dispatch(editorSetError(reason));
    if (reopen) {
      dispatch(editorHintReopen());
    } else {
      dispatch(editorHintRefresh());
    }
  };

export const editorSetDrawingError = (reason) => (dispatch, getState) => {
  const error = getError(getState());
  if (!error || error !== reason) {
    dispatch(showErrorHint(reason));
  }
};

export const editorClearDrawingError =
  (reopen = true) =>
  (dispatch, getState) => {
    if (getError(getState())) {
      dispatch(editorSetError(null));
      if (reopen) {
        dispatch(editorHintReopen());
      } else {
        dispatch(editorHintRefresh());
      }
    }
  };

export const handleStaleLayer = (tool) => (dispatch) => {
  setTimeout(() => {
    dispatch(editorToolEnd(tool));
    dispatch(refreshLayers());
    dispatch(editorToolStart(tool));
  }, 3000);
  return Promise.reject(errors.RETIRED_PARCEL);
};

export const getPayload = (reponse) => (reponse ? reponse.payload : null);

export const handleResponse = (res) => {
  const payload = getPayload(res);
  if (res?.error) {
    return Promise.reject(payload);
  }

  return Promise.resolve(payload);
};

export const handleGenericError = (payload, tool) => (dispatch) => {
  const { response } = payload;
  if (response && response.message) {
    return dispatch(handleStaleLayer(tool));
  }
  return Promise.reject(payload);
};

export const setValidFromErrorHint = (previousError, isError) => (dispatch) => {
  const newError = isError ? errors.DATE_VALID_FROM_ERROR : null;
  if (previousError !== newError) {
    dispatch(editorSetValidFromError(newError));
    dispatch(editorHintReopen());
  }
};

export const validateValidFrom = () => (dispatch, getState) => {
  const state = getState();
  const lastCurrentSeedDate = getLastCurrentSeedDate(state);
  const validFrom = getValidFrom(state);
  const validFromError = getValidFromError(state);
  if (validFrom) {
    const validFromMoment = moment(validFrom);
    const todayEndMoment = moment().endOf("day");
    if (
      (lastCurrentSeedDate && validFromMoment.isBefore(lastCurrentSeedDate)) ||
      validFromMoment.isAfter(todayEndMoment)
    ) {
      dispatch(setValidFromErrorHint(validFromError, true));
    } else {
      dispatch(setValidFromErrorHint(validFromError, false));
    }
  } else {
    dispatch(setValidFromErrorHint(validFromError, true));
  }
};
