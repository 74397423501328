import { storeResetSeasonSowingPlan } from "../sowingPlan/sowingPlan.actions";
import { refreshDefaultStyles } from "../style/style.actions";

import * as types from "./crops.constants";

import { getParcelsStatisticsApi } from "../../../../shared/api/agroevidence/parcels/parcels.api";

export const storeInitialCrops = (crops, cropsOrder) => ({
  type: types.STORE_INITIAL_CROPS,
  crops,
  cropsOrder,
});

export const storeResetCrops = () => ({
  type: types.STORE_RESET_CROPS,
});

export const fetchCrops = () => (dispatch) =>
  dispatch(getParcelsStatisticsApi()).then((response) => {
    if (response && response.payload && response.payload.crops) {
      const cropsStats = response.payload.crops;
      const crops = {};
      const cropsOrder = cropsStats.map((cropStats) => {
        const crop = {};
        crop.id = cropStats.cropId;
        crop.name = cropStats.name;
        crop.visible = true;
        crop.color = cropStats.color;
        crop.legislativeCode = cropStats.legislativeCode;
        crops[crop.id] = crop;
        return crop.id;
      });
      dispatch(storeInitialCrops(crops, cropsOrder));
      dispatch(storeResetSeasonSowingPlan());
      dispatch(refreshDefaultStyles());
    }
  });

export const setCropVisibility = (crop, visible) => (dispatch) => {
  dispatch({
    type: types.SET_CROP_VISIBILITY,
    cropId: crop.id,
    visible,
  });
  dispatch(refreshDefaultStyles());
};

export const setAllCropsVisibility = (visible) => (dispatch) => {
  dispatch({
    type: types.SET_ALL_CROPS_VISIBILITY,
    visible,
  });
  dispatch(refreshDefaultStyles());
};
