import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";

const DeleteDialog = (props) => {
  const { children, onAccept, onClose, opened, title } = props;
  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.delete" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      dialogHeight="75px"
      onAccept={onAccept}
      onCancel={onClose}
      onClose={onClose}
      opened={opened}
      title={title}
    >
      {children}
    </CfDialog>
  );
};

DeleteDialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {
  opened: false,
};

export default DeleteDialog;
