import moment from "moment";

import {
  getLocationsOrder,
  getLocationsOrderBy,
  getLocationsPage,
  getLocationsRowsPerPage,
  getLocationsHistoryFilter,
  getLocationsTextFilter,
  getLocationsStatusFilter,
  getLocationsNodeTypeFilter,
  getLocationsGroupsFilter,
} from "../selectors/locations.selectors";

import { fetchGroups } from "./groups.actions";
import { zoomToNodeLocation } from "./map.actions";

import * as types from "./locations.constants";

import {
  getLocations as getLocationsApi,
  getLocationsPoints as getLocationsPointsApi,
  resetLocations as resetLocationsApi,
  getLocation as getLocationApi,
  resetLocation as resetLocationApi,
  removeLocation as removeLocationApi,
  patchLocation as patchLocationApi,
  exportLocation as exportLocationApi,
  getLocationPestPredictions as getLocationPestPredictionsApi,
  assignGroups as assignGroupsApi,
} from "../../shared/api/iot/locations/locations.api";
import FileService from "../../shared/services/File.service";

export const fetchLocationsWithPoints = () => (dispatch) => {
  dispatch(fetchLocations());
  dispatch(fetchLocationsPoints());
};

export const fetchLocations = () => (dispatch, getState) => {
  const state = getState();

  const page = getLocationsPage(state);
  const order = getLocationsOrder(state);
  const orderBy = getLocationsOrderBy(state);
  const rowsPerPage = getLocationsRowsPerPage(state);
  const textFilter = getLocationsTextFilter(state);
  const historyFilter = getLocationsHistoryFilter(state);
  const historyDates = getHistoryDates(historyFilter);

  const params = {
    page: page + 1,
    "per-page": rowsPerPage,
    "sort-col": orderBy,
    "sort-dir": order,
    name: textFilter,
    historic: historyFilter.historic,
    "include-inactive": !historyFilter.historic,
    status: getLocationsStatusFilter(state).map((v) => v.name),
    "device-group": getLocationsNodeTypeFilter(state).map((v) => v.name),
    from: historyDates.from,
    to: historyDates.to,
    "group-name": getLocationsGroupsFilter(state).map((v) => v.name),
  };

  dispatch(getLocationsApi(params));
};

export const fetchLocationsPoints = () => (dispatch, getState) => {
  const state = getState();

  const textFilter = getLocationsTextFilter(state);
  const historyFilter = getLocationsHistoryFilter(state);
  const historyDates = getHistoryDates(historyFilter);

  const transformedFilter = {
    name: textFilter,
    historic: historyFilter.historic,
    status: getLocationsStatusFilter(state).map((v) => v.name),
    "device-group": getLocationsNodeTypeFilter(state).map((v) => v.name),
    from: historyDates.from,
    to: historyDates.to,
  };

  dispatch(getLocationsPointsApi(transformedFilter));
};

const getHistoryDates = (historyFilter) => {
  const historyDates = {};
  if (historyFilter.month) {
    historyDates.from = moment(
      `${historyFilter.year} ${historyFilter.month}`,
      "YYYY M",
    )
      .startOf("month")
      .toISOString();
    historyDates.to = moment(
      `${historyFilter.year} ${historyFilter.month}`,
      "YYYY M",
    )
      .endOf("month")
      .toISOString();
  } else {
    historyDates.from = moment(`${historyFilter.year}`, "YYYY")
      .startOf("year")
      .toISOString();
    historyDates.to = moment(`${historyFilter.year}`, "YYYY")
      .endOf("year")
      .toISOString();
  }
  return historyDates;
};

export const setHistoryFilter = (params) => (dispatch) => {
  dispatch({
    type: types.SET_HISTORY_FILTER,
    filterParams: processFilterParams(params),
  });
};

export const resetLocations = () => (dispatch) => {
  dispatch(resetLocationsApi());
};

export const processFilterParams = (params) => {
  const filterParams = params;
  if (params.year) {
    filterParams.month = "";
  }
  if (params.historic === false) {
    filterParams.year = moment().year();
    filterParams.month = "";
  }

  return filterParams;
};

export const fetchNodeLocation = (locationId) => (dispatch) =>
  dispatch(getLocationApi(locationId)).then((res) => {
    dispatch(zoomToNodeLocation(res.payload));
  });

export const resetNodeLocation = () => (dispatch) => {
  dispatch(resetLocationApi());
};

// TODO is this really an API action?
export const removeLocation = (id) => (dispatch) => {
  dispatch(removeLocationApi(id));
};

export const exportLocation = (locationId, from, to) => (dispatch) =>
  dispatch(exportLocationApi(locationId, from, to)).then((res) => {
    FileService.processFileResponse(res);
  });

export const getLocationPestPredictions = (locationId) => (dispatch) =>
  dispatch(getLocationPestPredictionsApi(locationId));

export const update = (id, name) => (dispatch) =>
  dispatch(patchLocationApi(id, name));

export const assignGroups = (groups, locationId) => (dispatch) =>
  dispatch(assignGroupsApi(groups, locationId)).then(() => {
    dispatch(fetchGroups());
    dispatch(fetchLocations());
  });

export const assignGroupsFromDetail = (groups, locationId) => (dispatch) =>
  dispatch(assignGroupsApi(groups, locationId)).then(() => {
    dispatch(fetchNodeLocation(locationId));
    dispatch(fetchGroups());
  });
