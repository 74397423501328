import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = {
  actionLink: {
    padding: "0 15px",
  },
};

const StoreActionLink = (props) => {
  const { classes, item, redirectToAction } = props;
  return item.type === "CONSUMPTION" ? (
    <a
      className={classes.actionLink}
      onClick={redirectToAction}
      role="button"
      tabIndex="0"
    >
      <FormattedMessage id={`Stores.${item.type}`} />
    </a>
  ) : (
    <span className={classes.actionLink}>
      <FormattedMessage id={`Stores.${item.type}`} />
    </span>
  );
};

StoreActionLink.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  redirectToAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(StoreActionLink);
