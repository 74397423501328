import React from "react";

import { Box } from "@mui/material";

import SectionWrapper from "../../../../../shared/components/specific/SectionWrapper/SectionWrapper";
import { useIsMobile } from "../../../../../shared/hooks/useWidth";
import MapService from "../../../../map/services/Map.service";

import { AttributeControl } from "./AttributeControl";
import { Chart } from "./Chart";
import { Map } from "./Map";

const MapWrapper = () => {
  const isMobile = useIsMobile();

  const handleResizeMap = (map: MapService) => {
    map.updateSize();
  };

  return (
    <Map onResize={handleResizeMap}>
      <SectionWrapper left={56} top={17}>
        <AttributeControl />
      </SectionWrapper>
      <SectionWrapper bottom={17} left={14} right={14}>
        {isMobile ? null : (
          <Box
            style={{
              background: "white",
              borderRadius: "4px",
              width: "100%",
              height: "175px",
            }}
          >
            <Chart />
          </Box>
        )}
      </SectionWrapper>
    </Map>
  );
};

export { MapWrapper };
