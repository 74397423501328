import {
  FarmStatisticsTo,
  ParcelEagriInfoTo,
  ParcelDetailTo,
  ParcelSeedApplicationTo,
  CenterTo,
  ParcelTo,
  ParcelPredecessorTo,
  ParcelSuggestionTo,
  CropTo,
  LandUseResponse,
  ParcelGeometryResponse,
  ZoneDetailTo,
} from "../agroevidence.types";

interface Error {
  message: string;
  name: string;
  stack: string;
}

export interface ParcelListAdvancedFilter {
  centers?: CenterTo[];
  crops?: CropTo[];
  landUse?: LandUseResponse[];
  nsa?: NSAState;
  parcel?: ParcelSuggestionTo[];
  zones?: ZoneDetailTo[];
}

export interface ParcelActivitiesListAdvancedFilter {
  activities: ActivitiesState[];
}

export interface ActivitiesState {
  label: string;
  name: string;
}
export interface NSAState {
  label: string;
  state: boolean;
}

export interface ParcelsTypes {
  centers: CenterTo[];
  focusedRow?: string;
  isExportingData: boolean;
  isFetchingCenters: boolean;
  isFetchingItem: boolean;
  isFetchingItemEagriRestrictions: boolean;
  isFetchingItemPredecessors: boolean;
  isFetchingItemSeedApplication: boolean;
  isFetchingItemSubtractionsGeometry: boolean;
  isFetchingItems: boolean;
  isFetchingItemsSuggestion: boolean;
  isFetchingLandUse: boolean;
  isFetchingStatistics: boolean;
  item: ParcelDetailTo;
  itemEagriRestrictions: ParcelEagriInfoTo;
  itemError?: Error;
  itemPredecessors: ParcelPredecessorTo[];
  itemSeedApplication: ParcelSeedApplicationTo[];
  itemSubtractionsGeometry: ParcelGeometryResponse;
  items: ParcelTo[];
  itemsError?: Error;
  itemsSuggestion: ParcelSuggestionTo[];
  landUse: LandUseResponse[];
  statistics: FarmStatisticsTo;
  totalCount: number;
}

export enum SEASON_STATE {
  CURRENT,
  FUTURE,
}
