import React from "react";

import { FormattedMessage } from "react-intl";

import {
  ParcelTo,
  ParcelWithSowingPlansTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  parcel: ParcelTo | ParcelWithSowingPlansTo;
}

export function ParcelZoneName({ parcel }: Readonly<Props>) {
  const hasMoreZones = (parcel: ParcelTo | ParcelWithSowingPlansTo) =>
    !!parcel.zones && parcel.zones?.length > 1;
  const hasNoZone = (parcel: ParcelTo | ParcelWithSowingPlansTo) =>
    !!parcel.zones && parcel.zones?.length === 0;

  return (
    <span>
      {hasMoreZones(parcel) && (
        <FormattedMessage id="ParcelDetailOverview.moreZonesOption" />
      )}
      {hasNoZone(parcel) && (
        <FormattedMessage id="ParcelDetailOverview.noZoneOption" />
      )}
      {!hasMoreZones(parcel) && !hasNoZone(parcel) && parcel.zones && (
        <span>{parcel.zones[0].name}</span>
      )}
    </span>
  );
}
