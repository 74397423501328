import React, { Component } from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate } from "react-intl";

const styles = (theme) => ({
  dateSetting: {
    paddingLeft: 16,
    marginTop: "-12px",
    marginBottom: 8,
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.grey[500],
    lineHeight: 1.4,
  },
});

class PredictionDateSetting extends Component {
  constructor(props) {
    super(props);

    const defaultMoment = moment().month(props.month).date(props.day);
    const daysInMonth = this.getDaysInMonth(defaultMoment);
    this.state = {
      dateMoment: defaultMoment,
      daysInMonth,
    };
    this.months = [...Array(12).keys()];
  }

  getDaysInMonth = (dateMoment) => {
    const daysInMonth = [...Array(dateMoment.daysInMonth()).keys()].map(
      (d) => d + 1,
    );
    return daysInMonth;
  };

  handleDayChange = (day) => {
    const newDateMoment = this.state.dateMoment.clone();
    newDateMoment.date(day);
    this.props.onDayMonthChange(newDateMoment.date(), newDateMoment.month());
    this.setState({
      dateMoment: newDateMoment,
    });
  };

  handleMonthChange = (month) => {
    const newDateMoment = this.state.dateMoment.clone();
    newDateMoment.month(month);
    this.props.onDayMonthChange(newDateMoment.date(), newDateMoment.month());
    this.setState({
      dateMoment: newDateMoment,
      daysInMonth: this.getDaysInMonth(newDateMoment),
    });
  };

  render() {
    const { classes, day, label, month } = this.props;
    const { dateMoment, daysInMonth } = this.state;

    return (
      <Grid className={classes.dateSetting} container spacing={3}>
        <Grid className={classes.label} item xs={4}>
          {label}
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sensors-filter-year">
              <FormattedMessage id="common.month" />
            </InputLabel>
            <Select
              data-test="flowering-month"
              onChange={(event) => this.handleMonthChange(event.target.value)}
              value={month}
            >
              {this.months.map((monthOption) => (
                <MenuItem key={monthOption} value={monthOption}>
                  <FormattedDate
                    month="long"
                    value={dateMoment.clone().month(monthOption).toDate()}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sensors-filter-year">
              <FormattedMessage id="common.day" />
            </InputLabel>
            <Select
              data-test="flowering-day"
              onChange={(event) => this.handleDayChange(event.target.value)}
              value={day}
            >
              {daysInMonth.map((dayOption) => (
                <MenuItem key={dayOption} value={dayOption}>
                  {dayOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

PredictionDateSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onDayMonthChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(PredictionDateSetting);
