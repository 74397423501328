import get from "lodash/get";
import { createSelector } from "reselect";

import { getManagementZones } from "../../../shared/api/sentinel/management/management.selectors";
import { getSatelliteProducts } from "../../../shared/api/sentinel/satelliteProducts/satelliteProducts.selectors";

import { CropQualityData } from "../containers/ManagementZones";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import {
  GrowthVariabilities,
  HistorySnap,
  Zone,
} from "../../../shared/api/sentinel/management/management.types";

const historyDataTypes = ["HIST"];

export const getCropLegislativeCode = (state: PrecisionState) =>
  state.ui.management.cropLegislativeCode;

export const getIsManagementZones = createSelector(
  getManagementZones,
  (zones) => Boolean(zones && zones.historySnaps),
);

export const getManagementZonesStatus = createSelector(
  getSatelliteProducts,
  (products) => products?.historyPotentialStatus,
);

export const getHistorySnaps = createSelector(
  getCropLegislativeCode,
  getManagementZones,
  (legislativeCode, zones) => {
    if (zones && zones.historySnaps && zones.historySnaps.length) {
      return zones.historySnaps
        .filter((i: HistorySnap) => i.crop?.legislativeCode === legislativeCode)
        .reverse()
        .map((i: HistorySnap) => ({
          date: i.date,
          involvementImgPath: i.involvementImgPath,
          managementZoneImgPath: i.managementZoneImgPath,
        }));
    }

    return [];
  },
);

export const getGrowthVariabilities = createSelector(
  getManagementZones,
  (zones) =>
    zones && zones.cropGrowthVariabilities ? zones.cropGrowthVariabilities : [],
);

export const getGrowthVariabilitiesWithZones = createSelector(
  getGrowthVariabilities,
  (variabilities) => {
    const filtered = variabilities.filter(
      (variability: GrowthVariabilities) => variability?.zones?.length > 0,
    );
    return filtered.length ? filtered : null;
  },
);

export const getGrowthVariabilitiesForVRA = createSelector(
  getGrowthVariabilitiesWithZones,
  (variabilities) =>
    variabilities?.map((v: GrowthVariabilities) => ({
      dateFrom: null,
      dateTo: null,
      crop: v.crop,
      zones: v.zones,
      averageQuality: v.averageQuality,
      type: historyDataTypes[0],
    })) || null,
);

export const getGrowthVariability = createSelector(
  getCropLegislativeCode,
  getGrowthVariabilities,
  (legislativeCode, growthVariabilities) =>
    growthVariabilities.find(
      (i: GrowthVariabilities) => i.crop.legislativeCode === legislativeCode,
    ) || {},
);

export const getCrops = createSelector(
  getGrowthVariabilities,
  (growthVariabilities) =>
    growthVariabilities.map((i: GrowthVariabilities) => i.crop),
);

export const getCropQuality = createSelector(
  getGrowthVariability,
  (growthVariability: GrowthVariabilities) =>
    growthVariability.zones
      ? {
          average: growthVariability.averageQuality,
          data: growthVariability.zones
            .map((i: Zone) => ({
              zoneNumber: i.zoneNumber,
              zoneValue: i.zoneValue,
              zoneColor: i.color,
            }))
            .sort(
              (a: CropQualityData, b: CropQualityData) =>
                a.zoneNumber - b.zoneNumber,
            ),
        }
      : {},
);

export const getCropVariability = createSelector(
  getGrowthVariability,
  getHistorySnaps,
  (growthVariability: GrowthVariabilities, historySnaps) =>
    growthVariability.data
      ? transformedVariabilityData(growthVariability, historySnaps)
      : [],
);

export const getCropZones = createSelector(
  getGrowthVariability,
  (growthVariability: GrowthVariabilities) =>
    growthVariability.zones?.length
      ? growthVariability.zones.map((zone: Zone) => ({
          ...zone,
          opacity: 0.9,
        }))
      : [],
);

const transformedVariabilityData = (
  growthVariability: GrowthVariabilities,
  historySnaps: HistorySnap[],
) =>
  growthVariability.data.map((item) => ({
    ...item,
    q1: item.q1 - item.min,
    median: item.q3 - item.q1,
    q3: item.max - item.q3,
    max: 0,
    cropName: get(growthVariability, "crop.name", null),
    involvementImgPath: historySnaps.find(
      (snap: HistorySnap) => item.date === snap.date,
    )?.involvementImgPath,
    managementZoneImgPath: historySnaps.find(
      (snap: HistorySnap) => item.date === snap.date,
    )?.managementZoneImgPath,
  }));
