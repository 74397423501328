import difference from "@turf/difference";
import { lineString } from "@turf/helpers";
import intersect from "@turf/intersect";
import { getType, getCoords } from "@turf/invariant";
import length from "@turf/length";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import polygonToLine from "@turf/polygon-to-line";
import { lineSlice } from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import { getArea, getLength } from "ol/sphere";

export const GEOM_TYPES = {
  POINT: "POINT",
  LINESTRING: "LINESTRING",
  MULTILINESTRING: "MULTILINESTRING",
  POLYGON: "POLYGON",
  MULTIPOLYGON: "MULTIPOLYGON",
};

export default class Geometry {
  static getType(geometry) {
    return getType(geometry).toUpperCase();
  }

  static getOLType(geometry) {
    return geometry.getType().toUpperCase();
  }

  static getFirstCoord(line) {
    const coords = getCoords(line);
    return coords[0];
  }

  static getLastCoord(line) {
    const coords = getCoords(line);
    return coords[coords.length - 1];
  }

  static getPolygonBorder(polygon) {
    return polygonToLine(polygon);
  }

  static getPolygonOuterBorder(polygon) {
    return lineString(polygon.coordinates[0]);
  }

  static snapToBorder(line, polygon, pt, isFirst = true) {
    const border = Geometry.getPolygonBorder(polygon);
    const nearestPtOnBorder = nearestPointOnLine(border, pt);

    const copy = Object.assign({}, line);
    const { coordinates } = nearestPtOnBorder.geometry;
    if (isFirst) {
      copy.coordinates.unshift(coordinates);
    } else {
      copy.coordinates.push(coordinates);
    }
    return copy;
  }

  static readGeometry(geometry, transformOptions) {
    return new GeoJSON().readGeometry(geometry, transformOptions);
  }

  static featureToGeometry(feature, transformOptions) {
    return new GeoJSON().writeGeometryObject(
      feature.getGeometry(),
      transformOptions,
    );
  }

  static getDifference(g1, g2) {
    return difference(g1, g2);
  }

  static getIntersection(g1, g2) {
    return intersect(g1, g2);
  }

  static getLength(geometry) {
    return getLength(geometry);
  }

  static getArea(geometry) {
    return getArea(geometry);
  }

  static getNearestPointOnLine(line, point) {
    return nearestPointOnLine(line, point);
  }

  static getPolygonLineSlice(point1, point2, border, inverted) {
    let lineslice;
    if (!inverted) {
      lineslice = lineSlice(point1, point2, border);
    } else {
      // in case we want to construct lineSlice through initial point of polygon border,
      // we have to construct it manually, because order of turf lineSlice is always in direction of line
      const [startPoint, endPoint] = this.getStartAndEndPointsAtBorder(
        border,
        point1,
        point2,
      );
      lineslice = this.constructInverseBorderLineSlice(
        border,
        startPoint,
        endPoint,
      );
    }
    return lineslice;
  }

  static getStartAndEndPointsAtBorder(border, point1, point2) {
    const lineCoordinates = border.geometry.coordinates;
    const point1LineSlice = lineSlice(lineCoordinates[0], point1, border);
    const length1 = length(point1LineSlice);
    const point2LineSlice = lineSlice(lineCoordinates[0], point2, border);
    const length2 = length(point2LineSlice);
    let startPoint = point1;
    let endPoint = point2;

    // in case these points are switched
    if (length1 > length2) {
      startPoint = point2;
      endPoint = point1;
    }
    return [startPoint, endPoint];
  }

  static constructInverseBorderLineSlice(border, startPoint, endPoint) {
    // do not forget that last point of polygon border is the same one as the first one
    const lineCoordinates = border.geometry.coordinates;

    // important to check, whether endPoint is beyond actual last line point
    // we need to construct lineslices of both cases and compare their lengths
    const endingLineSlice = lineSlice(
      lineCoordinates[0],
      lineCoordinates[lineCoordinates.length - 2],
      border,
    );
    const endingLineSliceLength = length(endingLineSlice);
    const endPointLineSlice = lineSlice(lineCoordinates[0], endPoint, border);
    const endPointLineSliceLength = length(endPointLineSlice);

    let lineSlicePart1Coordinates;
    if (endPointLineSliceLength > endingLineSliceLength) {
      // use only endPoint if it is last one
      lineSlicePart1Coordinates = [endPoint.coordinates];
    } else {
      // otherwise use coordinates between endPoint and almost last point
      const lineSlicePart1 = lineSlice(
        endPoint,
        lineCoordinates[lineCoordinates.length - 2],
        border,
      );
      lineSlicePart1Coordinates = lineSlicePart1.geometry.coordinates;
    }

    const lineSlicePart2 = lineSlice(lineCoordinates[0], startPoint, border);
    const lineSlicePart2Coordinates = lineSlicePart2.geometry.coordinates;
    return lineString([
      ...lineSlicePart1Coordinates,
      ...lineSlicePart2Coordinates,
    ]);
  }
}
