import React, { FC, Fragment } from "react";

import { makeStyles } from "@mui/styles";

import CfNoData from "../../../../shared/components/charts/CfNoData/CfNoData";
import CfStaticMap from "../../../../shared/components/specific/CfStaticMap/CfStaticMap";

import ManagementZonesLegend from "./ManagementZonesLegend";

import { Zone } from "../../../../shared/api/sentinel/management/management.types";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "0 15px",
    cursor: "pointer",
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
  mapSelected: {
    borderRadius: 0,
  },
}));

interface ManagementZonesMapProps {
  onMapClick: (geometries: Zone[]) => void;
  zones: Zone[];
}

export const ManagementZonesMap: FC<ManagementZonesMapProps> = ({
  onMapClick,
  zones,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} onClick={() => onMapClick(zones)}>
      {zones.length ? (
        <Fragment>
          <CfStaticMap
            geometries={zones}
            mapId="management-zones-map"
            classes={{
              map: classes.map,
              mapSelected: classes.mapSelected,
            }}
          />
          <ManagementZonesLegend zones={zones} />
        </Fragment>
      ) : (
        <CfNoData />
      )}
    </div>
  );
};
