import * as types from "./weather.constants";

export default () =>
  (
    state = {
      isFetching: false,
      error: null,
      item: {},
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_WEATHER:
        return {
          ...state,
          isFetching: true,
          error: null,
        };
      case types.GET_WEATHER_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isFetching: false,
        };
      case types.GET_WEATHER_ERROR:
        return {
          ...state,
          error: action.payload,
          isFetching: false,
        };
      default:
        return state;
    }
  };
