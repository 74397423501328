import React, { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateSeasonState } from "../../../shared/actions/parcelSeasonState.actions";

import withPopover from "../../../../../shared/hocs/withPopover";
import { Thunk } from "../../../../../types";
import { getParcelSeasonState } from "../../../shared/selectors/parcelSeasonState.selector";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import { SEASON_STATE } from "../../../../../shared/api/agroevidence/parcels/parcels.types";

const useStyles = makeStyles(() => ({
  button: {
    paddingLeft: 12,
    paddingRight: 8,
    borderRadius: 50,
    paddingTop: 4,
    paddingBottom: 4,
    boxShadow: "none",
  },
}));

interface IParcelListSeasonProps {
  seasonState: SEASON_STATE;
  updateSeasonState: () => void;
}

const ParcelListSeason = ({
  seasonState,
  updateSeasonState,
}: IParcelListSeasonProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = () => {
    updateSeasonState();
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        variant="contained"
      >
        <FormattedMessage
          id={
            seasonState === SEASON_STATE.CURRENT
              ? "ParcelListSeason.actual-state"
              : "ParcelListSeason.future-state"
          }
        />
        <ArrowDropDownIcon />
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
        <MenuItem onClick={() => handleSelectItem()}>
          <FormattedMessage id="ParcelListSeason.actual-state" />
        </MenuItem>
        <MenuItem onClick={() => handleSelectItem()}>
          <FormattedMessage id="ParcelListSeason.future-state" />
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  seasonState: getParcelSeasonState(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      updateSeasonState,
    },
    dispatch,
  );

export default withPopover(
  connect(mapStateToProps, mapDispatchToProps)(ParcelListSeason),
);
