import React, { Dispatch } from "react";

import { styled } from "@mui/material";
import { ConnectedProps, connect } from "react-redux";
import {
  CartesianGrid,
  LineChart,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer as ChartResponsiveContainer,
  TooltipProps,
  ReferenceLine,
} from "recharts";

import {
  PointWithAttribute,
  selectAttributeUnit,
  selectIsFetchingPoints,
  selectMinMaxValues,
  selectChartData,
  selectChartHighlight,
  selectCurrentAttribute,
} from "../../../selectors/asAppliedDetail.selectors";

import {
  Action,
  pointHighlighted,
  pointReset,
} from "../../../reducer/precisionAsAppliedDetail.reducer";

import CfChartTooltipLabelWrapper from "../../../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper";
import CfChartTooltipValue from "../../../../../shared/components/charts/CfChartTooltipValue/CfChartTooltipValue";
import { CfChartTooltipWrapper } from "../../../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";

import { PrecisionState } from "../../../../../reducers/precision.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;

const _Chart = ({
  attribute,
  attributeUnit,
  dataInterval,
  highlight = 0,
  isFetching,
  pointsWithAttribute = [],
  resetMapHighlight,
  setMapHighlight,
}: ReduxProps) => {
  const highlightedPoint =
    pointsWithAttribute && highlight
      ? pointsWithAttribute[highlight]
      : undefined;
  const boundingClientRectRef = React.useRef<DOMRect>();
  const tooltipPosition = (highlight / pointsWithAttribute.length) * 100;

  const handleMouseMove: React.ComponentProps<
    typeof LineChart
  >["onMouseMove"] = ({ activePayload }) => {
    if (!(activePayload && activePayload.length === 1)) {
      return;
    }

    const { payload } = activePayload[0];
    setMapHighlight(payload.point);
  };

  if (isFetching) {
    return <CfLoader size={20} />;
  }

  return (
    <ResponsiveContainer
      height="100%"
      width="98%"
      ref={(ref) => {
        // @ts-expect-error recharts typing is wrong here
        boundingClientRectRef.current = ref?.current.getBoundingClientRect();
      }}
    >
      <LineChart
        data={pointsWithAttribute}
        onMouseLeave={resetMapHighlight}
        onMouseMove={handleMouseMove}
      >
        <CartesianGrid
          stroke="#cacaca"
          strokeDasharray="2 4"
          strokeLinecap="round"
          strokeWidth={1}
        />
        <XAxis dataKey="time" tick={{ fontSize: 12 }} tickLine={false} />
        <YAxis
          domain={dataInterval}
          padding={{ top: 20 }}
          tick={{ fontSize: 12 }}
          tickLine={false}
          type="number"
          // expert guess of how wide one letter is
          width={dataInterval ? dataInterval[1].toString().length * 7 : 35}
        />
        <Legend
          align="left"
          iconSize={0}
          verticalAlign="top"
          wrapperStyle={{ paddingBottom: "10px" }}
          formatter={() => (
            <>
              <b>{attribute?.name}</b>
              <span>{attributeUnit}</span>
            </>
          )}
        />
        <Line
          activeDot={{ r: 5 }}
          dataKey="point.attribute.value"
          dot={false}
          isAnimationActive={false}
          stroke="#d01d02"
          strokeWidth={2}
          type="linear"
        />
        {highlightedPoint ? (
          <ReferenceLine stroke="#848484" strokeWidth={2} x={highlight} />
        ) : null}
        {highlightedPoint ? (
          <ChartTooltip
            active
            isAnimationActive={false}
            offset={50}
            content={() => (
              <TooltipContent
                active
                label={highlightedPoint.time}
                payload={[highlightedPoint.point.attribute]}
              />
            )}
            wrapperStyle={{
              left: `calc(${tooltipPosition}% + ${
                tooltipPosition > 80 ? -70 : 50
              }px)`,
              top: "20px",
              transform: "unset",
              visibility: "visible",
            }}
          />
        ) : (
          <ChartTooltip
            content={<TooltipContent />}
            isAnimationActive={false}
            offset={50}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const TooltipContent = ({
  active,
  label,
  payload,
}: TooltipProps<number, number>) => {
  if (
    !(
      active &&
      payload &&
      payload.length === 1 &&
      payload[0].value !== undefined
    )
  ) {
    return null;
  }

  return (
    <CfChartTooltipWrapper>
      <CfChartTooltipLabelWrapper>
        <small>{label}</small>
      </CfChartTooltipLabelWrapper>
      <CfChartTooltipValue sx={{ color: "#000000", padding: "5px 10px" }}>
        {payload[0].value}
      </CfChartTooltipValue>
    </CfChartTooltipWrapper>
  );
};

const ResponsiveContainer = styled(ChartResponsiveContainer)({
  "& .recharts-legend-item": {
    width: "100%",

    "& .recharts-legend-item-text": {
      color: "unset !important",
    },

    "& .recharts-surface": {
      display: "none !important",
    },
  },
  "& .recharts-legend-item-text": {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0px 5px 5px",
  },
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setMapHighlight: (point: PointWithAttribute) =>
    dispatch(pointHighlighted({ highlight: point, target: "map" })),
  resetMapHighlight: () => dispatch(pointReset()),
});

const mapStateToProps = (state: PrecisionState) => ({
  attribute: selectCurrentAttribute(state),
  attributeUnit: selectAttributeUnit(state),
  dataInterval: selectMinMaxValues(state),
  isFetching: selectIsFetchingPoints(state),
  pointsWithAttribute: selectChartData(state),
  highlight: selectChartHighlight(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const propsAreEqual = (prev: ReduxProps, next: ReduxProps) =>
  prev.attribute === next.attribute &&
  prev.pointsWithAttribute?.length === next.pointsWithAttribute?.length &&
  prev.isFetching === next.isFetching &&
  prev.highlight === next.highlight;

const Chart = connector(React.memo(_Chart, propsAreEqual));

export { Chart };
