import React, { Component } from "react";

import { red, green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import CfFileUpload from "../../../../shared/components/common/CfFileUpload/CfFileUpload";
import "../../../../assets/files/shapefile.png";
import ImportHelp from "../ImportHelp/ImportHelp";

const styles = (theme) => ({
  shapeFileError: {
    fontSize: 12,
    position: "absolute",
    textAlign: "center",
    width: "100%",
    marginTop: 5,
  },
  errorText: {
    position: "absolute",
    bottom: "-10px",
  },
  floatingLabelStyle: {
    fontWeight: "normal",
    fontSize: "14px",
  },
  shapefileUpload: {
    marginTop: 10,
    marginRight: 0,
    position: "relative",
    width: "100%",
    display: "inline-block",
  },
  shapefileUploadRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  shapeFileInfo: {
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    marginTop: 25,
    color: theme.palette.grey[500],
  },
  loadFarmButton: {
    marginTop: 75,
    marginRight: 0,
  },
});

class ShapeFileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      farmShapefile: null,
      shapefileErrorText: " ",
    };
    this.shapefileRequiredError = false;
  }

  onValidateShapefile = (event) => {
    this.props.onUploadShapeFile(event.target.files[0]);
    this.props.onValidateShapefile(event.target.files[0]);
  };

  render() {
    const { classes, country, intl } = this.props;
    const { formatMessage } = this.props.intl;
    let shapefileErrorColor = red[500];
    let shapefileErrorText = this.state.shapefileErrorText;

    switch (this.props.farmShpValidationResult) {
      case -1:
        shapefileErrorText = <FormattedMessage id="validation.fileNotValid" />;
        break;
      case 1:
        shapefileErrorText = <FormattedMessage id="validation.fileIsValid" />;
        shapefileErrorColor = green[500];
        break;
      default:
        break;
    }

    return (
      <div>
        <div className={classes.shapefileUpload}>
          <Grid className={classes.shapefileUploadRow} container>
            <Grid item xs={12}>
              <CfFileUpload
                btnTranslId="ShapefileImport.lpisShapefileHint"
                onChange={this.onValidateShapefile}
              />
            </Grid>
            {intl.locale === "cs-CZ" && country === "CZ" && (
              <Grid item xs={12}>
                <ImportHelp
                  fileUrl="assets/files/shapefile.png"
                  copy={formatMessage({
                    id: "ShapefileImport.copy.part1",
                  })}
                  linkCopy={formatMessage({
                    id: "ShapefileImport.copy.part2",
                  })}
                  title={formatMessage({
                    id: "ShapefileImport.howToGetFarmerBlocks",
                  })}
                />
              </Grid>
            )}
          </Grid>
          <div
            className={classes.shapeFileError}
            id="shape-file-error"
            style={{
              color: shapefileErrorColor,
            }}
          >
            {shapefileErrorText}
          </div>
          <div className={classes.shapeFileInfo} id="shape-file-info">
            <FormattedMessage id="ShapefileImport.info" />
          </div>
        </div>
      </div>
    );
  }
}

ShapeFileUpload.propTypes = {
  onValidateShapefile: PropTypes.func,
  farmShpValidationResult: PropTypes.number,
  intl: PropTypes.object.isRequired,
  country: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(injectIntl(ShapeFileUpload));
