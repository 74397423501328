import { AdminState } from "../../../../reducers/admin.reducer.types";

export const getAccountFarms = (state: AdminState) =>
  state.api.telematics.farms;
export const isFetchingAccountFarms = (state: AdminState) =>
  state.api.telematics.isFetching;
export const isFetchingCountryCodes = (state: AdminState) =>
  state.api.telematics.isFetchingCountryCodes;
export const getCountryCodes = (state: AdminState) =>
  state.api.telematics.countryCodes;
export const getAccountFarmsTotalCount = (state: AdminState) =>
  state.api.telematics.totalCount;
