import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Field, getFormSyncErrors } from "redux-form";

import { getTypes } from "../../../../shared/api/core/reports/reports.selectors";
import { getCounts } from "../../selectors/reports.selectors";

import CfCheckbox from "../../../../shared/components/form/CfCheckbox/CfCheckbox";
import CfCheckboxLabel from "../../../../shared/components/form/CfCheckbox/CfCheckboxLabel";
import ListSelectorError from "../../../../shared/components/form/ListSelectorError/ListSelectorError";
import Report from "../Report/Report";

const styles = (theme) => ({
  subheading: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginBottom: 5,
    marginTop: 10,
  },
  errorSubheading: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginBottom: 5,
    marginTop: 10,
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    left: 0,
  },
  reports: {
    marginBottom: 5,
  },
});

export class ReportTypes extends Component {
  groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  renderCheckBox = (categoryName, item) => (
    <CfCheckboxLabel
      key={item.id}
      label={<FormattedMessage id={`Report.${item.id}`} />}
      labelPlacement="end"
      classes={{
        root: this.props.classes.label,
      }}
      control={
        <Field
          component={CfCheckbox}
          name={`categories.${categoryName}.${item.id}`}
          classes={{
            root: this.props.classes.checkbox,
          }}
        />
      }
    />
  );

  render() {
    const {
      classes,
      counts,
      meta: { error, submitFailed },
      reportTypes,
    } = this.props;
    const groups = this.groupBy("category")(reportTypes);
    return (
      <Fragment>
        <div
          className={
            submitFailed && error ? classes.errorSubheading : classes.subheading
          }
        >
          <FormattedMessage id="Reports.type" />
        </div>
        <div className={classes.reports}>
          {Object.values(groups)?.map((category) => {
            const categoryName = category[0]?.category;
            return (
              <Report
                count={counts.find((c) => c.category === categoryName)?.count}
                header={<FormattedMessage id={`Report.${categoryName}`} />}
                id={categoryName}
                key={categoryName}
              >
                {category?.map((item) =>
                  this.renderCheckBox(categoryName, item),
                )}
              </Report>
            );
          })}
        </div>
        {submitFailed && error && (
          <ListSelectorError
            error={<FormattedMessage id="Report.typeError" />}
          />
        )}
      </Fragment>
    );
  }
}

ReportTypes.propTypes = {
  classes: PropTypes.object,
  meta: PropTypes.object.isRequired,
  reportTypes: PropTypes.array.isRequired,
  counts: PropTypes.array.isRequired,
};

ReportTypes.defaultProps = {
  classes: {},
};

const mapStateToProps = (state, props) => {
  const { formName } = props;

  return {
    formErrors: getFormSyncErrors(formName)(state),
    counts: getCounts(formName, state),
    reportTypes: getTypes(state),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ReportTypes));
