import React from "react";

import { FormattedMessage } from "react-intl";

import { getColDesc } from "../../../../shared/misc/helper";
import { COLOR_GREY } from "../../../../theme";

import { SowingPlanSeasonTo } from "../../../../shared/api/agroevidence/agroevidence.types";

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

export const parcelCropsTableColumns = (
  sowingPlanSeason: SowingPlanSeasonTo[],
) => {
  const seasonColumns = sowingPlanSeason.reduce(
    (next, season) => ({
      ...next,
      [`season${season.startYear}`]: getColDesc(
        false,
        <span>
          <p style={colStyles.head.p}>
            <FormattedMessage id="common.season" />
          </p>
          <p
            style={{
              ...colStyles.head.p,
              ...colStyles.head.secondaryLabel,
            }}
          >
            {season.startYear}/{season.endYear % 100}
          </p>
        </span>,
      ),
    }),
    {},
  );

  const baseColumns = {
    localName: getColDesc(
      true,
      <span>
        <p style={colStyles.head.p}>
          <FormattedMessage id="common.name" />
        </p>
        <p
          style={{
            ...colStyles.head.p,
            ...colStyles.head.secondaryLabel,
          }}
        >
          <FormattedMessage id="common.zone" />
        </p>
      </span>,
    ),
    blockNumber: getColDesc(true, <FormattedMessage id="common.short-code" />),
    area: getColDesc(
      true,
      <span>
        <p style={colStyles.head.p}>
          <FormattedMessage id="common.area" /> (ha)
        </p>
        <p
          style={{
            ...colStyles.head.p,
            ...colStyles.head.secondaryLabel,
          }}
        >
          <FormattedMessage id="common.area-dpb" /> (ha)
        </p>
      </span>,
      { textAlign: "right" },
    ),
    currentCrop: getColDesc(
      false,
      <FormattedMessage id="common.currentCrop" />,
    ),
  };

  return {
    ...baseColumns,
    ...seasonColumns,
  };
};
