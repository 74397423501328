import React, { PropsWithChildren } from "react";

import { TextField, TextFieldProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, useField } from "formik";

import { getError } from "../../../misc/formHelpers";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
    textAlign: "left",
  },
}));

type Props = PropsWithChildren<
  Omit<TextFieldProps, "onChange" | "onBlur" | "value">
>;

const FormTextField: React.FC<Props> = (props) => {
  const { ...settings } = props;
  const fieldProps = useField(settings.name || "");
  const field = fieldProps[0];
  const meta = fieldProps[1];

  const classes = useStyles();

  return (
    <>
      <TextField
        {...field}
        error={!!getError(meta)}
        onBlur={(e) => {
          field.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
        }}
        {...settings}
      />
      <div className={classes.error}>
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
};

export default FormTextField;
