import React, { useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isDialogOpen } from "../../../shared/selectors/manager.selectors";
import {
  getSensorsAdminDisplayInactive,
  getSensorsAdminNodeTypes,
  getSensorsAdminNetworkTypes,
  getSensorsAdminDefaultFarm,
  getSensorsAdminFarmFilter,
  getSensorsAdminCountryFilter,
  getSensorsAdminSelectedOnPage,
  getSensorsAdminApiError,
} from "../../selectors/sensors.selectors";

import {
  setDialogOpen,
  setActionButtonEnabled,
  setSensorsTabActive,
  setActionButtonHidden,
} from "../../../shared/actions/manager.actions";
import {
  createNode,
  fetchNodeTypes,
  fetchNetworkTypes,
  fetchDefaultFarm,
  resetNodesApi,
  assignNodesToFarm,
  setCountryFilter,
  setFarmFilter,
  patchSensor,
} from "../../actions/sensors.actions";

import { NAMESPACE as namespace } from "../../reducer/sensors.reducer";

import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import CountrySwitcher from "../../../shared/containers/CountrySwitcher/CountrySwitcher";
import FarmSwitcher from "../../../shared/containers/FarmSwitcher/FarmSwitcher";
import SensorAdminAssignConfirm from "../../components/SensorAdminAssignConfirm/SensorAdminAssignConfirm";
import SensorAdminBulkActions from "../../components/SensorAdminBulkActions/SensorAdminBulkActions";
import SensorAdminTextFilter from "../../components/SensorAdminTextFilter/SensorAdminTextFilter";
import SensorAdminCreateSensor from "../SensorAdminCreateSensor/SensorAdminCreateSensor";
import SensorAdminFilter from "../SensorAdminFilter/SensorAdminFilter";
import SensorsAdminNodeEditDialog from "../SensorsAdminNodeEditDialog/SensorsAdminNodeEditDialog";
import SensorsAdminTable from "../SensorsAdminTable/SensorsAdminTable";

const useStyles = makeStyles((theme) => ({
  tableHeaderContainer: {
    paddingBottom: 8,
    alignItems: "center",
  },
  switchers: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  switch: {
    margin: "0px 3px 0px 3px",
  },
}));

const SensorsAdmin = ({
  assignNodesToFarm,
  country,
  createNode,
  defaultFarm,
  dialogOpen,
  error,
  farm,
  fetchDefaultFarm,
  fetchNetworkTypes,
  fetchNodeTypes,
  langId,
  networkTypes,
  ngImpersActivate,
  nodeTypes,
  patchSensor,
  resetNodesApi,
  selectedOnPage,
  setActionButtonEnabled,
  setActionButtonHidden,
  setCountryFilter,
  setDialogOpen,
  setFarmFilter,
  setSensorsTabActive,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const [assignFarm, setAssignFarm] = useState(false);
  const [editNode, setEditNode] = useState(false);
  const [nodeIds, setNodeIds] = useState([]);
  const [farmState, setFarmState] = useState({});
  const [activeNode, setActiveNode] = useState({});

  useEffect(() => {
    fetchNodeTypes();
    fetchNetworkTypes();
    fetchDefaultFarm();
    setActionButtonHidden(false);
    setActionButtonEnabled(true);
    setSensorsTabActive(true);

    return () => {
      resetNodesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssignFarmDialogOpen = (nodeIds, farm) => {
    setAssignFarm(true);
    setNodeIds(nodeIds);
    setFarmState(farm);
  };

  const handleAssignFarmDialogClose = () => {
    setAssignFarm(false);
    setNodeIds([]);
    setFarmState({});
  };

  const handleAssignFarmDialogAccept = () => {
    assignNodesToFarm(nodeIds, farmState);
    handleAssignFarmDialogClose();
  };

  const handleCreateOneDialogClose = () => {
    setNodeIds([]);
    setFarmState({});
    setDialogOpen(false);
  };

  const handleCreateOneDialogAccept = (values) =>
    createNode(values).then((res) => {
      if (res.error) {
        showSnackbar({
          message: <FormattedMessage id="SensorsAdmin.create-sensors-error" />,
          isError: true,
        });
        return;
      }
      handleCreateOneDialogClose();
    });

  const handleEditDialogOpen = (activeNode) => {
    setEditNode(true);
    setActiveNode(activeNode);
  };

  const handleEditDialogClose = () => {
    setEditNode(false);
    setActiveNode({});
  };

  const handleEditDialogAccept = (newType) => {
    patchSensor(activeNode, newType);
    handleEditDialogClose();
  };

  const handleSetFarmFilter = (farm) => {
    if (farm.code === "all") {
      setFarmFilter({});
    } else if (farm.code === "none") {
      setFarmFilter(defaultFarm);
    } else {
      setFarmFilter(farm);
    }
  };

  return (
    <CfErrorPage error={error}>
      <Grid alignItems="center" container spacing={2}>
        <SensorAdminCreateSensor
          networkTypes={networkTypes}
          onAccept={handleCreateOneDialogAccept}
          onClose={handleCreateOneDialogClose}
          opened={dialogOpen}
          types={nodeTypes}
        />
        {activeNode !== null && (
          <SensorsAdminNodeEditDialog
            node={activeNode}
            onAccept={handleEditDialogAccept}
            onClose={handleEditDialogClose}
            opened={editNode}
          />
        )}
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid
              className={classes.tableHeaderContainer}
              container
              spacing={2}
            >
              <Grid item lg={2} md={2} sm={3} xs={12}>
                <SensorAdminBulkActions
                  key={langId}
                  onAssignNodesToFarm={handleAssignFarmDialogOpen}
                  selected={selectedOnPage}
                />
                <SensorAdminAssignConfirm
                  farm={farmState}
                  nodeIds={nodeIds}
                  onAccept={handleAssignFarmDialogAccept}
                  onClose={handleAssignFarmDialogClose}
                  opened={assignFarm}
                />
              </Grid>
              <Grid item lg={4} md={3} sm={6} xs={12}>
                <SensorAdminTextFilter key={langId} namespace={namespace} />
              </Grid>
              <Grid item lg={2} md={2} sm={3} xs={12}>
                <SensorAdminFilter
                  langId={langId}
                  namespace={namespace}
                  networkTypes={networkTypes}
                  nodeTypes={nodeTypes}
                />
              </Grid>
              <Grid
                className={classes.switchers}
                item
                lg={4}
                md={5}
                sm={12}
                xs={12}
              >
                <span className={classes.switch}>
                  <FarmSwitcher
                    allFarmOption={true}
                    farm={farm}
                    onFarmSelect={handleSetFarmFilter}
                  />
                </span>
                <span className={classes.switch}>
                  <CountrySwitcher
                    country={country}
                    onCountrySelect={setCountryFilter}
                  />
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <SensorsAdminTable
                  defaultFarm={defaultFarm}
                  langId={langId}
                  ngImpersActivate={ngImpersActivate}
                  onAssignNodesToFarm={handleAssignFarmDialogOpen}
                  openNodeEditDialog={handleEditDialogOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CfErrorPage>
  );
};

SensorsAdmin.propTypes = {
  langId: PropTypes.string.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  networkTypes: PropTypes.array.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  error: PropTypes.object.isRequired,
  defaultFarm: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
  createNode: PropTypes.func.isRequired,
  assignNodesToFarm: PropTypes.func.isRequired,
  fetchNodeTypes: PropTypes.func.isRequired,
  fetchNetworkTypes: PropTypes.func.isRequired,
  fetchDefaultFarm: PropTypes.func.isRequired,
  resetNodesApi: PropTypes.func.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  setActionButtonEnabled: PropTypes.func.isRequired,
  setSensorsTabActive: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setFarmFilter: PropTypes.func.isRequired,
  setCountryFilter: PropTypes.func.isRequired,
  ngImpersActivate: PropTypes.func.isRequired,
  patchSensor: PropTypes.func.isRequired,
  setActionButtonHidden: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  displayInactive: getSensorsAdminDisplayInactive(state),
  nodeTypes: getSensorsAdminNodeTypes(state),
  networkTypes: getSensorsAdminNetworkTypes(state),
  defaultFarm: getSensorsAdminDefaultFarm(state),
  farm: getSensorsAdminFarmFilter(state),
  country: getSensorsAdminCountryFilter(state),
  selectedOnPage: getSensorsAdminSelectedOnPage(state),
  error: getSensorsAdminApiError(state),
  dialogOpen: isDialogOpen(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFarmFilter,
      setCountryFilter,
      createNode,
      fetchNodeTypes,
      fetchNetworkTypes,
      fetchDefaultFarm,
      resetNodesApi,
      assignNodesToFarm,
      setDialogOpen,
      setActionButtonEnabled,
      setSensorsTabActive,
      patchSensor,
      setActionButtonHidden,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SensorsAdmin);
