/**
 * Created by ondrejzvara on 28.3.17.
 */

import angular from "angular";

import PlainEntity from "./Plain.entity.js";
import ZoneEntity from "./Zone.entity.js";

export default angular
  .module("app.entities", [])
  .factory("Plain", PlainEntity)
  .factory("Zone", ZoneEntity).name;
