import React, { FC, ReactNode, useEffect } from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getEquipments,
  getIsFetchingEquipments,
} from "../../../shared/api/telematics/drives/drives.selectors";

import { fetchEquipments } from "../../actions/telematicsList.actions";

import * as sortOrder from "../../../shared/constants/sortOrder.constants";

import { resetEquipments } from "../../../shared/api/telematics/drives/drives.api";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../types";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  EquipmentSortColumn,
  EquipmentTo,
} from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface TelematicsDetailEquipmentSelectorProps {
  customClasses?: Record<string, string>;
  dateFrom: string;
  dateTo: string;
  disabled: boolean;
  error: boolean;
  fetchEquipments(
    dFrom: string,
    dTo: string,
    sortCol: string,
    sortDir: string,
  ): void;
  helperText?: React.ReactElement | string;
  isFetching: boolean;
  label?: ReactNode;
  onChange(item: EquipmentTo): void;
  options: EquipmentTo[];
  placeholder?: string;
  resetEquipments(): void;
  selectedCode: string;
}

const handleGetSelected = (option: EquipmentTo, value?: EquipmentTo | null) =>
  option.code === value?.code;

const handleGetLabel = (option?: EquipmentTo | null) => {
  if (!option) return "";
  if (option.name) return `${option.name} (${option.code})`;
  return option.code;
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const TelematicsDetailEquipmentSelector: FC<
  TelematicsDetailEquipmentSelectorProps
> = ({
  customClasses,
  dateFrom,
  dateTo,
  disabled,
  error,
  fetchEquipments,
  helperText,
  isFetching,
  label,
  onChange,
  options,
  placeholder,
  resetEquipments,
  selectedCode,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    fetchEquipments(dateFrom, dateTo, EquipmentSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetEquipments();
    };
  }, [fetchEquipments, resetEquipments, dateFrom, dateTo]);

  const handleRenderOption = (option: EquipmentTo) => (
    <div>
      <div>{option.name ?? option.code}</div>
      <div className={classes.id}>{option.code}</div>
    </div>
  );

  let selectedEquipment;
  if (selectedCode) {
    const option = options.find((option) => option.code === selectedCode);
    selectedEquipment = { code: option?.code ?? "", name: option?.name ?? "" };
  } else {
    selectedEquipment = { code: "", name: "" };
  }

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={selectedEquipment}
      disabled={disabled}
      error={error}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-equipment-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="telematics-detail-equipment-selector"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getEquipments(state),
  isFetching: getIsFetchingEquipments(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchEquipments,
      resetEquipments,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsDetailEquipmentSelector);
