import React, { Component, Fragment } from "react";

import Check from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 10,
    marginBottom: 20,
  },
  paper: {
    textAlign: "center",
    width: "100%",
  },
  greyPaper: {
    backgroundColor: theme.palette.grey[100],
  },
  icon: {
    margin: 10,
    color: theme.palette.grey[400],
    width: 32,
    height: 32,
  },
  buttonLink: {
    width: "max-content",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none",
      outline: "none",
    },
  },
  button: {
    width: "max-content",
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14,
    marginTop: 5,
  },
  progress: {
    marginLeft: 10,
  },
  sent: {
    marginLeft: 5,
    color: theme.palette.primary.main,
    marginBottom: 3,
  },
  contentWrapper: {
    display: "flex",
  },
  textWrapperDense: {
    textAlign: "left",
    margin: "0px 12px",
  },
  buttonWrapper: {
    display: "flex",
    flexGrow: 1,
    padding: 10,
  },
  secondaryContent: {
    color: theme.palette.grey[500],
  },
  denseContent: {
    margin: "5px 0px",
  },
  content: {
    margin: "10px 0px",
  },
  customContent: {
    padding: "10px 0px",
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
  },
});

/**
 * Panel for displaying information about state of services or other useful information.
 * It typically consists of icon, header, content and link button.
 * It is responsive and possible to customize.
 * @version 1.0.0
 */
class CfStatusPanel extends Component {
  renderTitle = () => {
    const {
      classes,
      content,
      customContent,
      secondaryContent,
      title,
      titleWithIcon,
    } = this.props;
    return (
      <Fragment>
        {title && (
          <Typography
            className={classes.title}
            variant="h5"
            style={{
              marginBottom:
                !content && !secondaryContent && !customContent ? 10 : 0,
              alignItems: titleWithIcon ? "left" : "center",
            }}
          >
            {title}
          </Typography>
        )}
      </Fragment>
    );
  };

  renderContent = () => {
    const { classes, content, customContent, dense, secondaryContent } =
      this.props;
    return (
      <Fragment>
        {customContent && (
          <div className={classes.customContent}>{customContent}</div>
        )}
        {content && (
          <Typography
            className={dense ? classes.denseContent : classes.content}
            variant="body2"
          >
            {content}
          </Typography>
        )}
        {secondaryContent && (
          <Typography
            variant="body2"
            className={`${classes.secondaryContent} ${
              dense ? classes.denseContent : classes.content
            }`}
          >
            {secondaryContent}
          </Typography>
        )}
      </Fragment>
    );
  };

  render() {
    const {
      activated,
      buttonColor,
      classes,
      dense,
      elevation,
      errorText,
      fullWidth,
      grey,
      horizontal,
      icon,
      isFetching,
      linkHref,
      linkText,
      onLinkClick,
      testId,
      titleWithIcon,
    } = this.props;
    const Icon = icon;

    return (
      <div className={classes.wrapper} data-test={testId}>
        <Paper
          className={`${classes.paper} ${grey ? classes.greyPaper : ""}`}
          elevation={elevation}
          style={{
            maxWidth: fullWidth ? "100%" : "600px",
            padding: dense ? "10px" : "20px 30px",
          }}
        >
          <div
            className={classes.contentWrapper}
            style={{
              flexDirection: horizontal ? "row" : "column",
              margin: grey && !dense ? "20px 0px" : 0,
              alignItems: titleWithIcon ? "left" : "center",
            }}
          >
            {titleWithIcon ? (
              <Fragment>
                <div className={classes.titleWithIcon}>
                  <Icon className={classes.icon} style={{ marginLeft: 0 }} />
                  {this.renderTitle()}
                </div>
                {this.renderContent()}
              </Fragment>
            ) : (
              <Fragment>
                {icon && <Icon className={classes.icon} />}
                <span className={horizontal ? classes.textWrapperDense : ""}>
                  {this.renderTitle()}
                  {this.renderContent()}
                </span>
              </Fragment>
            )}
            {linkText && (
              <span
                className={classes.buttonWrapper}
                style={{
                  justifyContent:
                    horizontal || titleWithIcon ? "flex-end" : "initial",
                }}
              >
                {linkHref && (
                  <a
                    className={classes.buttonLink}
                    href={linkHref}
                    target="_blank"
                  >
                    <Button color={buttonColor} variant="contained">
                      {linkText}
                    </Button>
                  </a>
                )}
                {onLinkClick && (
                  <Button
                    className={classes.button}
                    color={buttonColor}
                    disabled={isFetching || activated}
                    onClick={onLinkClick}
                    variant="contained"
                  >
                    {activated ? (
                      <FormattedMessage id="button.sent" />
                    ) : (
                      linkText
                    )}
                    {isFetching && (
                      <CircularProgress
                        className={classes.progress}
                        size={21}
                      />
                    )}
                    {activated && <Check className={classes.sent} />}
                  </Button>
                )}
              </span>
            )}
          </div>
          {errorText && <div className={classes.error}>{errorText}</div>}
        </Paper>
      </div>
    );
  }
}

CfStatusPanel.propTypes = {
  /** Style classes. */
  classes: PropTypes.object.isRequired,
  /** Title of panel. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Primary text description or explanation. */
  customContent: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Secondary additional information. */
  secondaryContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** URL address to link with button. Can be used instead of `onLinkClick` */
  linkHref: PropTypes.string,
  /** Action that should perform after link click. Can be used instead of `linkHref` */
  onLinkClick: PropTypes.func,
  /** If `true`, button will contain information about successful request.
   * Use if button performs some kind of action. */
  activated: PropTypes.bool,
  /** Label displaying in the button. */
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** If `true`, button will contain spinner. */
  isFetching: PropTypes.bool,
  /** Error text information displayed at the end, if something unusual happens. */
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Icon to display at the beginning. */
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  /** Test id. */
  testId: PropTypes.string,
  /** If `true`, elements will be aligned in a row instead of a column. */
  horizontal: PropTypes.bool,
  /** If `true`, panel will take 100% width of its container. */
  fullWidth: PropTypes.bool,
  /** If `true`, elements in panel will have smaller margins. */
  dense: PropTypes.bool,
  /** If `true`, paper will have grey background. */
  grey: PropTypes.bool,
  /** Elevation of paper. If greater that 0, panel will cast shadow to its background. */
  elevation: PropTypes.number,
  buttonColor: PropTypes.string,
  titleWithIcon: PropTypes.bool,
};

CfStatusPanel.defaultProps = {
  title: null,
  customContent: null,
  content: null,
  secondaryContent: null,
  linkHref: null,
  onLinkClick: null,
  activated: false,
  linkText: "",
  isFetching: false,
  errorText: null,
  icon: null,
  testId: "status",
  horizontal: false,
  fullWidth: false,
  dense: false,
  grey: false,
  elevation: 2,
  buttonColor: "primary",
  titleWithIcon: false,
};

export default withStyles(styles)(CfStatusPanel);
