import * as types from "./groups.constants";

export default () =>
  (
    state = {
      isFetchingGroups: false,
      items: [],
      error: {},
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_GROUPS:
        return {
          ...state,
          isFetchingGroups: true,
          error: {},
        };
      case types.GET_GROUPS_SUCCESS:
        return {
          ...state,
          isFetchingGroups: false,
          items: action.payload,
        };
      case types.GET_GROUPS_ERROR:
        return {
          ...state,
          isFetchingGroups: false,
          error: action.payload,
        };
      case types.RESET_GROUPS:
        return {
          ...state,
          isFetchingGroups: false,
          error: {},
          items: [],
        };
      default:
        return state;
    }
  };
