import React, { Component, Fragment } from "react";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getChemistryApplications as getChemistryApplicationsApi } from "../../../../../shared/api/core/chemistry/chemistry.selectors";

import {
  getChemistryApplications,
  resetChemistryApplications,
} from "../../../../../shared/api/core/chemistry/chemistry.api";
import ListSelectorError from "../../../../../shared/components/form/ListSelectorError/ListSelectorError";
import PestSelector from "../../components/PestSelector/PestSelector";
import PestsList from "../../components/PestsList/PestsList";

class PestsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };
  }

  componentDidMount() {
    const { chemistryMaterialId } = this.props;
    if (chemistryMaterialId !== "") {
      this.props.getChemistryApplications(chemistryMaterialId);
    }
  }

  componentDidUpdate(prevProps) {
    const { chemistryMaterialId } = this.props;
    if (
      chemistryMaterialId !== "" &&
      chemistryMaterialId !== prevProps.chemistryMaterialId
    ) {
      this.props.getChemistryApplications(chemistryMaterialId);
    }
  }

  componentWillUnmount() {
    this.props.resetChemistryApplications();
  }

  getPestsByName(value) {
    const val = value.trim().toLowerCase();
    const pestsFilter = this.props.pests.filter(
      (item) =>
        item.organism && item.organism.name.toLowerCase().indexOf(val) >= 0,
    );
    return pestsFilter;
  }

  getSuggestions = (inputValue) => {
    let pests = this.getPestsByName(inputValue);
    if (!isEmpty(this.props.targetCrop)) {
      const { formatMessage } = this.props.intl;
      pests = this.sortPestsByCrop(
        pests,
        this.props.targetCrop.id,
        formatMessage({ id: "ChemistryDialog.pestSelectorSeparator" }),
      );
    } else {
      pests = this.formatPest(pests);
    }
    this.setState({
      suggestions: pests,
    });
  };

  formatPest = (pests) => {
    const formatPests = {
      title: "",
      items: [],
    };

    pests.forEach((item) => {
      formatPests.items.push({ ...item.organism });
    });
    return [formatPests];
  };

  sortPestsByCrop = (pests, idCrop, title) => {
    const sortPests = [];
    const pestsCropIn = {
      title: "",
      items: [],
    };
    const pestsElse = {
      title: "",
      items: [],
    };

    pests.forEach((item) => {
      if (item.crops.find((crop) => crop.id === idCrop)) {
        pestsCropIn.items.push({ ...item.organism });
      } else {
        pestsElse.items.push({ ...item.organism });
      }
    });

    if (!isEmpty(pestsCropIn.items)) {
      sortPests.push(pestsCropIn);
      pestsElse.title = title;
    }
    if (!isEmpty(pestsElse.items)) {
      sortPests.push(pestsElse);
    }
    return sortPests;
  };

  handleSuggestionSelected = (suggestion) => {
    if (!this.isPestInList(suggestion)) {
      this.props.fields.push(suggestion);
    }
  };

  isPestInList = (pestToCheck) => {
    let result = false;
    this.props.fields.forEach((fieldName, index) => {
      const pest = this.props.fields.get(index);
      if (pestToCheck.id === pest.id) {
        result = true;
      }
    });
    return result;
  };

  handleItemRemove = (index) => {
    this.props.fields.remove(index);
  };

  render() {
    const { error, submitFailed } = this.props.meta;

    return (
      <div>
        {this.props.editing && (
          <Fragment>
            <PestSelector
              autoFocus={true}
              getSuggestions={this.getSuggestions}
              onSuggestionSelected={this.handleSuggestionSelected}
              suggestions={this.state.suggestions}
            />
            {submitFailed && error && <ListSelectorError error={error} />}
          </Fragment>
        )}
        <PestsList
          editing={this.props.editing}
          fields={this.props.fields}
          onItemRemove={this.handleItemRemove}
        />
      </div>
    );
  }
}

PestsControl.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  getChemistryApplications: PropTypes.func.isRequired,
  resetChemistryApplications: PropTypes.func.isRequired,
  chemistryMaterialId: PropTypes.string,
  pests: PropTypes.array,
  editing: PropTypes.bool,
  targetCrop: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

PestsControl.defaultProps = {
  chemistryMaterialId: "",
  pests: [],
  editing: false,
  targetCrop: {},
};

const mapStateToProps = (state) => ({
  pests: getChemistryApplicationsApi(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChemistryApplications,
      resetChemistryApplications,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PestsControl));
