import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import SelectionItem from "../../../../shared/components/common/SelectionItem/SelectionItem";
import SelectionItemHeading from "../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading";

const styles = {
  parcels: {
    marginTop: 25,
    marginBottom: 35,
  },
  name: {
    display: "flex",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    margin: "0 15px 0 15px",
  },
};

class ReportsParcelsList extends Component {
  renderParcelItem = (fieldName, index, field) => {
    const { classes, farm } = this.props;
    return (
      <SelectionItem
        editing={true}
        key={fieldName}
        onRemoveItem={() => {
          this.props.onItemRemove(index);
        }}
      >
        <span className={classes.row}>
          <span className={classes.name}>
            {field.blockNumber}
            <Link to={`/farm/${farm.id}/parcels/${field.id}/overview`}>
              <SelectionItemHeading classes={{ item: classes.heading }}>
                <div>{field.localName}</div>
              </SelectionItemHeading>
            </Link>
          </span>
          <span>
            <CfFormattedNumber value={field.area} /> ha
          </span>
        </span>
      </SelectionItem>
    );
  };

  render() {
    const { classes, fields } = this.props;
    return (
      <div className={classes.parcels} id="reports-parcel-list">
        {fields.map((fieldName, index) => {
          const field = fields.get(index);
          return this.renderParcelItem(fieldName, index, field);
        })}
      </div>
    );
  }
}

ReportsParcelsList.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func,
  farm: PropTypes.object.isRequired,
};

ReportsParcelsList.defaultProps = {
  onItemRemove: () => {},
};

export default withStyles(styles)(ReportsParcelsList);
