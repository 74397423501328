import React from "react";

import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  formControlLabelClasses,
} from "@mui/material";

type Props = {
  initialChecked?: boolean;
  onChange: (value: boolean) => void;
} & Pick<FormControlLabelProps, "label" | "sx">;

const CfSwitch = ({
  initialChecked = false,
  label,
  onChange,
  sx = [],
}: Props) => {
  const [value, setValue] = React.useState(initialChecked);

  const handleChange = () => {
    setValue((v) => {
      const newValue = !v;
      onChange(newValue);
      return newValue;
    });
  };

  return (
    <FormControlLabel
      label={label}
      control={
        <Switch checked={value} color="primary" onChange={handleChange} />
      }
      sx={[
        {
          [`& .${formControlLabelClasses.label}`]: {
            fontSize: 14,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export { CfSwitch };
