import { reverse, sortBy } from "lodash";

import ErrorService from "../../../services/Error.service";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

export const selectSoilSamplesData = (state: PrecisionState) =>
  state.api.soilSamples.data;
export const selectSoilSamplesIsFetching = (state: PrecisionState) =>
  state.api.soilSamples.isFetching;
export const selectSoilSamplesError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.soilSamples.error);

export const selectSoilSamplesYearsData = (state: PrecisionState) =>
  reverse(sortBy(state.api.soilSamplesYears.data));
export const selectSoilSamplesYearsIsFetching = (state: PrecisionState) =>
  state.api.soilSamplesYears.isFetching;
export const selectSoilSamplesYearsError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.soilSamplesYears.error);
