import { getLayers } from "../../selectors/map.selectors";

export const refreshParcelLayer = () => (_, getState) => {
  const layersService = getLayers(getState());
  layersService.refreshParcelLayer();
};

export const refreshParcelLabels = () => (_, getState) => {
  const layersService = getLayers(getState());
  layersService.refreshParcelLabels();
};

export const refreshLayers = () => (dispatch) => {
  dispatch(refreshParcelLayer());
  dispatch(refreshParcelLabels());
};
