import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton, alpha } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { FILE_MAX_SIZE_MB } from "../../../core/precision/precision.constants";

import { COLOR_ERROR, COLOR_FONT } from "../../../theme";

import { FileLoadItem } from "./FileUploader";

const useStyles = makeStyles((theme: Theme) => ({
  loadedFile: {
    minHeight: 36,
    borderRadius: 4,
    color: theme.palette.common.white,
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    padding: 8,
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    color: theme.palette.common.white,
    padding: 0,
  },
  errorMessage: {
    color: alpha(COLOR_ERROR.main, 0.8),
    fontSize: 12,
    marginTop: 4,
    fontWeight: 400,
  },
  isImporting: {
    background: alpha(COLOR_FONT.main, 0.3),
  },
  error: {
    background: alpha(COLOR_ERROR.main, 0.8),
  },
  success: {
    background: alpha(theme.palette.success.main, 0.8),
  },
  validated: {
    background: alpha(COLOR_FONT.main, 0.8),
  },
  new: {
    background: alpha(COLOR_FONT.main, 0.8),
  },
}));

interface FileItemProps {
  isSubmitting: boolean;
  item: FileLoadItem;
  onRemove: (id: string | undefined) => void;
}

const FileItem: React.FC<FileItemProps> = ({
  isSubmitting,
  item,
  onRemove,
}) => {
  const classes = useStyles();

  return (
    <li key={item.uniqueId}>
      <div className={classnames(classes.loadedFile, classes[item.status])}>
        <span style={{ wordBreak: "break-all" }}>{item.file.name}</span>
        <IconButton
          className={classes.closeIcon}
          onClick={() => onRemove(item.uniqueId)}
          size="small"
        >
          {item.status === "isImporting" && (
            <CircularProgress
              color="success"
              style={{ width: "20px", height: "20px" }}
            />
          )}
          {(item.status === "validated" || item.status === "error") &&
            !isSubmitting && <CloseIcon fontSize="small" />}
        </IconButton>
      </div>
      {item.errorCode && (
        <div className={classes.errorMessage}>
          <FormattedMessage
            id={`FileUploader.modal.validation.error.${item.errorCode}`}
            values={{ maxSize: FILE_MAX_SIZE_MB }}
          />
        </div>
      )}
    </li>
  );
};

export default FileItem;
