import React, { FC } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Grid, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";

import { useTelematicsAggregationDetailContentStyles } from "./styles";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  handleStartEditing?: () => void;
  headingId: string;
  isApproved: boolean;
  isEditing: boolean;
  showEditButton?: boolean;
  visible: boolean;
}

const EditBox: FC<Props> = ({
  children,
  disabled = false,
  handleStartEditing = () => true,
  headingId,
  isApproved,
  isEditing,
  showEditButton = true,
  visible,
}) => {
  const classes = useTelematicsAggregationDetailContentStyles();

  if (!visible) return null;

  return (
    <Grid className={classes.box} container justifyContent="space-between">
      <Grid item xs={6}>
        <h3 className={classes.sectionHeading}>
          <FormattedMessage id={headingId} />
        </h3>
      </Grid>
      <Grid className={classes.editBox} item xs={3}>
        {showEditButton && (
          <Tooltip
            title={
              isApproved ? (
                <FormattedMessage id="TelematicsAggregations.detail.edit.tooltip" />
              ) : (
                ""
              )
            }
          >
            <span>
              <IconButton
                data-test="edit-ride"
                disabled={disabled || isEditing || isApproved}
                onClick={handleStartEditing}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default EditBox;
