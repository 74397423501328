import get from "lodash/get";

import * as types from "./admin.constants";

export default (reducerContext) =>
  (
    state = {
      isFetching: false,
      error: {},
      items: [],
      totalCount: 0,
    },
    action,
  ) => {
    const actionContext = get(action, "meta.context");
    if (actionContext && actionContext !== reducerContext) return state;

    switch (action.type) {
      case types.GET_ADMIN_PARCELS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_ADMIN_PARCELS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_ADMIN_PARCELS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_ADMIN_PARCELS:
        return {
          isFetching: false,
          error: {},
          items: [],
          totalCount: 0,
        };

      default:
        return state;
    }
  };
