import moment from "moment";

import * as aggregations from "../../shared/constants/aggregations.constants";
import * as granularity from "../constants/granularity.constants";

import { getEvapotranspirationData } from "../../shared/api/iot/locations/locations.api";
import { getSensorData as getSensorDataApi } from "../../shared/api/iot/sensors/sensors.api";

export const fetchSensorData =
  (sensorId, locationId, dateFrom, dateTo, gran, aggr = aggregations.AVG) =>
  (dispatch) => {
    // fix for bug on BE side, need send time in Prague timezone
    if (gran === granularity.G24H) {
      const hoursToPragueTime = moment(dateFrom).isDST() ? 2 : 1;
      dispatch(
        getSensorDataApi(
          sensorId,
          locationId,
          moment(dateFrom).add(hoursToPragueTime, "h").toISOString(),
          moment(dateTo).add(hoursToPragueTime, "h").toISOString(),
          gran,
          aggr,
        ),
      );
      return;
    }
    dispatch(
      getSensorDataApi(sensorId, locationId, dateFrom, dateTo, gran, aggr),
    );
  };

export const fetchEvapotranspirationData =
  (locationId, dateFrom, dateTo, gran, elevation) => (dispatch) => {
    // fix for bug on BE side, need send time in Prague timezone
    if (gran === granularity.G24H) {
      const hoursToPragueTime = moment(dateFrom).isDST() ? 2 : 1;
      dispatch(
        getEvapotranspirationData(
          locationId,
          moment(dateFrom).add(hoursToPragueTime, "h").toISOString(),
          moment(dateTo).add(hoursToPragueTime, "h").toISOString(),
          gran,
          elevation,
        ),
      );
      return;
    }
    dispatch(
      getEvapotranspirationData(locationId, dateFrom, dateTo, gran, elevation),
    );
  };
