import { RSAA } from "redux-api-middleware";

import * as types from "./accounts.constants";
import {
  API_METHODS as methods,
  API_MODULES as modules,
} from "../../api.constants";

export const getAccount = () => ({
  [RSAA]: {
    endpoint: "accounts?",
    method: methods.GET,
    module: modules.GATEWAY,
    types: [
      types.GET_ACCOUNT,
      types.GET_ACCOUNT_SUCCESS,
      types.GET_ACCOUNT_ERROR,
    ],
  },
});

export const saveAccount = (values) => ({
  [RSAA]: {
    endpoint: "accounts?",
    method: methods.POST,
    body: JSON.stringify(values),
    module: modules.GATEWAY,
    types: [
      types.SAVE_ACCOUNT,
      types.SAVE_ACCOUNT_SUCCESS,
      types.SAVE_ACCOUNT_ERROR,
    ],
  },
});

export const changePassword = (values) => ({
  [RSAA]: {
    endpoint: "accounts/change-password?",
    method: methods.POST,
    body: JSON.stringify(values),
    module: modules.GATEWAY,
    types: [
      types.CHANGE_PASSWORD,
      types.CHANGE_PASSWORD_SUCCESS,
      types.CHANGE_PASSWORD_ERROR,
    ],
  },
});

export const checkEmailExists = (email) => ({
  [RSAA]: {
    endpoint: `accounts?email=${email}`,
    method: methods.GET,
    module: modules.GATEWAY,
    types: [
      types.CHECK_EMAIL,
      types.CHECK_EMAIL_SUCCESS,
      types.CHECK_EMAIL_ERROR,
    ],
  },
});

export const registerAccount = (values) => ({
  [RSAA]: {
    endpoint: "accounts/register?",
    method: methods.POST,
    body: JSON.stringify(values),
    module: modules.GATEWAY,
    types: [
      types.GET_ACCOUNT,
      types.GET_ACCOUNT_SUCCESS,
      types.GET_ACCOUNT_ERROR,
    ],
  },
});
