import React, { FC, ReactNode } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { Affiliation } from "../../../../shared/api/telematics/telematics.types";

interface ParcelAffiliationSelectorProps {
  defaultValues?: Affiliation[];
  label: ReactNode;
  onChange(items: Affiliation[]): void;
}

const defaultDefaultValues: Affiliation[] = [];
const options = [Affiliation.COMPANY, Affiliation.EXTERNAL, Affiliation.NONE];

const ParcelAffiliationSelector: FC<ParcelAffiliationSelectorProps> = ({
  defaultValues = defaultDefaultValues,
  label,
  onChange,
}) => {
  const intl = useIntl();

  const handleRenderOption = (option: Affiliation) =>
    intl.formatMessage({ id: `TelematicsList.${option}` });

  const handleGetLabel = (option?: Affiliation | null) =>
    option ? intl.formatMessage({ id: `TelematicsList.${option}` }) : "";

  const handleGetSelected = (option: Affiliation, value?: Affiliation | null) =>
    option === value;

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-affiliation-selector"
      isFetching={false}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="parcel-affiliation-filter"
    />
  );
};

export default ParcelAffiliationSelector;
