import React, { FC } from "react";

import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCreateOrEditAreaCoordSelectorId,
  getCreateOrEditAreaSelectedDevices,
} from "../../../selectors/createOrEditArea.selectors";

import {
  activateCoordSelector,
  resetCoordSelector,
  setFormTouched,
} from "../../../actions/createOrEditArea.actions";
import { setMapColumns } from "../../../actions/map.actions";

import withWidth from "../../../../shared/hocs/withWidth";
import TargetIconActive from "../../../../shared/icons/TargetIconActive";
import TargetIconNotSelected from "../../../../shared/icons/TargetIconNotSelected";
import TargetIconSelected from "../../../../shared/icons/TargetIconSelected";
import { Thunk } from "../../../../types";
import useAvailableDevicesStyles from "../styles/useAvailableDevices.styles";

import { IrrigationState } from "../../../../reducers/irrigation.reducer.types";

interface Props {
  activateCoordSelector: (id: string) => void;
  coordSelectorId: string;
  deviceId: string;
  resetCoordSelector: () => void;
  selectedDevices: string[];
  setFormTouched: (bool: boolean) => void;
  setMapColumns: (cols: number) => void;
  width: string;
}

const TargetIcon: FC<Props> = ({
  activateCoordSelector,
  coordSelectorId,
  deviceId,
  resetCoordSelector,
  selectedDevices,
  setFormTouched,
  setMapColumns,
  width,
}) => {
  const classes = useAvailableDevicesStyles();
  const isSelected = selectedDevices.includes(deviceId);
  const isThisDeviceSelectingFromMap = deviceId === coordSelectorId;
  const isMobileView = width === "xs";

  const handleIconClick = () => {
    if (isThisDeviceSelectingFromMap) {
      resetCoordSelector();
      return;
    }

    activateCoordSelector(deviceId);
    setFormTouched(true);
    if (isMobileView) {
      setMapColumns(12);
    }
  };

  if (isThisDeviceSelectingFromMap) {
    return (
      <TargetIconActive
        className={classnames(classes.icon)}
        onClick={handleIconClick}
      />
    );
  } else if (isSelected) {
    return (
      <TargetIconSelected
        className={classnames(classes.icon, classes.activeIcon)}
        onClick={handleIconClick}
      />
    );
  }

  return <TargetIconNotSelected className={classes.icon} />;
};

const mapStateToProps = (state: IrrigationState) => ({
  selectedDevices: getCreateOrEditAreaSelectedDevices(state),
  coordSelectorId: getCreateOrEditAreaCoordSelectorId(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      activateCoordSelector,
      resetCoordSelector,
      setFormTouched,
      setMapColumns,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TargetIcon));
