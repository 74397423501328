import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { editorSetSelected } from "../../../core/map/actions/editor/editor.actions";
import {
  activateStart,
  activateCancel,
  activate,
  updateCoordsFromForm,
} from "../../actions/devices.actions";
import { setEnlargedVariant } from "../../actions/map.actions";

import { IRRIGATION_URLS } from "../../irrigation.constants";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import DeviceActivation from "../../../shared/components/specific/DeviceActivation/DeviceActivation";

export function IrrigationActivation(props) {
  const { device, editorSetSelected, history, match, newDevice } = props;

  useEffect(() => {
    editorSetSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DeviceActivation
      activateCancel={props.activateCancel}
      activateStart={props.activateStart}
      buttonMessageId="IrrigationActivation.place"
      device={device}
      deviceId={device.externalId}
      geometryWgs={newDevice.geometryWgs}
      pageHeadingMessageId="IrrigationActivation.placement"
      setEnlargedVariant={props.setEnlargedVariant}
      updateCoordsFromForm={props.updateCoordsFromForm}
      activate={(name) => {
        delete props.newDevice.geometryWgs;
        return props.activate(
          device.id,
          name,
          newDevice.geometry,
          match.params.farmId,
        );
      }}
      backButton={
        <CfBackButton
          onClick={() => {
            history.push(
              `/farm/${match.params.farmId}/${IRRIGATION_URLS.DEVICE}/${match.params.deviceId}`,
            );
          }}
        />
      }
    />
  );
}

IrrigationActivation.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  device: PropTypes.object.isRequired,
  newDevice: PropTypes.object.isRequired,
  activateStart: PropTypes.func.isRequired,
  activateCancel: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  updateCoordsFromForm: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
};

IrrigationActivation.defaultProps = {
  history: {},
  match: {},
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      activateStart,
      activateCancel,
      activate,
      updateCoordsFromForm,
      setEnlargedVariant,
      editorSetSelected,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(IrrigationActivation);
