import React from "react";

import PropTypes from "prop-types";

const style = {
  container: {
    width: "100%",
    textAlign: "center",
    marginTop: "5px",
  },
  msg: {
    fontSize: "14px",
    color: "#f44336",
  },
};

export default function SubmitError(props) {
  return (
    <div id="submit-error" style={style.container}>
      <p style={style.msg}>{props.msg}</p>
    </div>
  );
}

SubmitError.propTypes = {
  msg: PropTypes.any,
};

SubmitError.defaultProps = {
  msg: "",
};
