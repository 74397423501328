import React from "react";

import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
  },
  rootActive: {
    color: theme.palette.background.paper,
    background: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
});

export const ImpersonationButton = (props) => {
  const { callback, children, classes, disabled, farm, testId } = props;
  return (
    <Button
      aria-label="impersonation button"
      className={`${farm ? classes.rootActive : ""}`}
      data-test={testId}
      disabled={disabled}
      onClick={(evt) => callback(evt)}
      onTouchEnd={(evt) => callback(evt)}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </Button>
  );
};

ImpersonationButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  farm: PropTypes.object,
  testId: PropTypes.string,
};

ImpersonationButton.defaultProps = {
  disabled: false,
  farm: null,
  testId: null,
};

export default withStyles(styles)(ImpersonationButton);
