import React from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton, Stack, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedTime,
} from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  selectIsFetchingItem,
  selectItemError,
  selectReport,
} from "../../../selectors/asAppliedDetail.selectors";

import { setFocusedRow } from "../../../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions";

import {
  getPrecisionTaskDataApi,
  getPrecisionTaskDataReportApi,
} from "../../../../../shared/api/sentinel/precision/precision.api";
import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { CfLabeledText } from "../../../../../shared/components/common/CfLabeledText/CfLabeledText";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import withWidth from "../../../../../shared/hocs/withWidth";
import useFocusedTableRowSource from "../../../../../shared/hooks/useFocusedTableRowSource";
import { Thunk } from "../../../../../types";
import useAsAppliedDuration from "../../../hooks/useAsAppliedDuration";

import { PrecisionState } from "../../../../../reducers/precision.reducer.types";
import { TaskDataListTaskTo } from "../../../../../shared/api/satellite/satellite.types";

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

const _Content = ({
  error,
  getPrecisionTaskDataApi,
  getPrecisionTaskDataReportApi,
  isFetchingItem,
  setFocusedRow,
  taskData,
  taskReport,
}: Props) => {
  const { taskDataId, taskId } =
    useParams<Record<"taskId" | "taskDataId", string>>();
  const { push } = useHistory();
  const classes = useStyles();
  const { nextTaskId, previousTaskId, taskPosition } = getTaskPosition(
    taskData?.task.tasks,
    taskId,
  );
  const task = taskData?.task.tasks[taskPosition];
  const { duration: taskDuration } = useAsAppliedDuration(task?.duration ?? 0);
  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: `${taskId}-${taskDataId}`,
    setFocusedRow,
  });

  const handleGoBack = () => {
    doSetFocusedRow();
    push("..");
  };

  const handleGoToTask = (taskId: string) => () => {
    push(`${taskId}`);
  };

  React.useEffect(() => {
    getPrecisionTaskDataApi(taskDataId, taskId);
  }, [getPrecisionTaskDataApi, taskDataId, taskId]);

  React.useEffect(() => {
    getPrecisionTaskDataReportApi(taskDataId, taskId);
  }, [getPrecisionTaskDataReportApi, taskDataId, taskId]);

  return (
    <CfErrorPage
      error={error}
      error40xHeadingTranslId="AsAppliedDetail.404.heading"
      error40xMessageTranslId="AsAppliedDetail.404.message"
      handle400s
    >
      {task ? (
        <>
          <div className={classes.header}>
            <Box p={2} pb={1}>
              <PageHeader
                backButton={<CfBackButton onClick={handleGoBack} />}
                heading={
                  <Box
                    alignItems="flex-end"
                    display="flex"
                    justifyContent="center"
                  >
                    <Tooltip
                      title={
                        <FormattedMessage id="AsAppliedDetail.previousDetail" />
                      }
                    >
                      <Box
                        component={IconButton}
                        disabled={!previousTaskId}
                        mb="-3px"
                        onClick={
                          previousTaskId
                            ? handleGoToTask(previousTaskId)
                            : undefined
                        }
                      >
                        <ArrowBackIosIcon />
                      </Box>
                    </Tooltip>
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                    >
                      <span className={classes.linkArrowsText}>
                        <FormattedDate value={task.dateFrom} />{" "}
                        {taskData.task.filename} {taskPosition + 1}/
                        {taskData.task.tasks.length}
                      </span>
                      <PageHeading
                        value={
                          isFetchingItem ? (
                            <CfLoader size={29} />
                          ) : (
                            taskData.name
                          )
                        }
                      />
                    </Box>
                    <Tooltip
                      title={
                        <FormattedMessage id="AsAppliedDetail.nextDetail" />
                      }
                    >
                      <Box
                        component={IconButton}
                        disabled={!nextTaskId}
                        mb="-3px"
                        onClick={
                          nextTaskId ? handleGoToTask(nextTaskId) : undefined
                        }
                      >
                        <ArrowForwardIosIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                }
              />
            </Box>
          </div>
          {!isFetchingItem ? (
            <Grid
              className={classes.contentWrapper}
              container
              justifyContent="center"
            >
              <Grid className={classes.content} item xs={10}>
                <Stack>
                  <Grid
                    className={classes.box}
                    container
                    justifyContent="space-between"
                  >
                    <Grid item xs={12}>
                      <h3 className={classes.sectionHeading}>
                        <FormattedMessage id="AsAppliedDetail.task" />
                      </h3>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <CfLabeledText
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.date" />
                          }
                          text={
                            <>
                              <FormattedDate value={task.dateFrom} />{" "}
                              <FormattedTime value={task.dateFrom} /> -{" "}
                              <FormattedDate value={task.dateTo} />{" "}
                              <FormattedTime value={task.dateTo} />
                            </>
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CfLabeledText
                          text={taskDuration}
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.duration" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CfLabeledText
                          text={taskData.culturalPractice?.name}
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.culturalPractice" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CfLabeledText
                          text={taskData.worker?.name}
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.worker" />
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <CfLabeledText
                          text={taskData.partfield?.name}
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.field" />
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <CfLabeledText
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.area" />
                          }
                          text={
                            taskData.partfield?.area !== undefined ? (
                              <FormattedNumber
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                                value={taskData.partfield.area / 10000}
                              />
                            ) : undefined
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <CfLabeledText
                          label={
                            <FormattedMessage id="AsAppliedDetail.task.material" />
                          }
                          text={
                            taskData.product.length > 1
                              ? taskData.product
                                  .map((product) => product.name)
                                  .join(", ")
                              : undefined
                          }
                        />
                      </Grid>

                      {taskReport.map(([label, { unit, value }]) => (
                        <Grid item key={label} xs={6}>
                          <CfLabeledText
                            label={
                              <FormattedMessage
                                id={`AsAppliedDetail.report.${label}`}
                              />
                            }
                            text={
                              value && unit ? (
                                <>
                                  <FormattedNumber value={value} />
                                  &nbsp;{unit}
                                </>
                              ) : undefined
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid
                    className={classes.box}
                    container
                    justifyContent="space-between"
                  >
                    <Grid item xs={6}>
                      <h3 className={classes.sectionHeading}>
                        <FormattedMessage id="AsAppliedDetail.task.device" />
                      </h3>
                    </Grid>
                    <Grid container>
                      {taskData.device?.map((device, i, arr) => (
                        <Grid
                          container
                          key={device.id}
                          mb={2}
                          pb={2}
                          style={
                            i < arr.length - 1
                              ? {
                                  borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                }
                              : {}
                          }
                        >
                          <Grid item xs={6}>
                            <CfLabeledText
                              text={device.name}
                              label={
                                <FormattedMessage
                                  id="AsAppliedDetail.task.deviceList.item"
                                  values={{ index: i + 1 }}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CfLabeledText
                              text={device.clientName}
                              label={
                                <FormattedMessage id="AsAppliedDetail.device.clientName" />
                              }
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : null}
    </CfErrorPage>
  );
};

const getTaskPosition = (tasks: TaskDataListTaskTo[] = [], taskId: string) => {
  const taskPosition = tasks.findIndex((task) => task.id === taskId);
  let previousTaskId: string | undefined;
  let nextTaskId: string | undefined;
  const getOtherTaskIdPosition = (offset: -1 | 1) => {
    const task = tasks[taskPosition + offset];
    if (task) {
      return task.id;
    }
  };

  switch (taskPosition) {
    case 0: {
      nextTaskId = getOtherTaskIdPosition(1);
      break;
    }
    case tasks.length: {
      previousTaskId = getOtherTaskIdPosition(-1);
      break;
    }
    default: {
      previousTaskId = getOtherTaskIdPosition(-1);
      nextTaskId = getOtherTaskIdPosition(1);
      break;
    }
  }

  return {
    taskPosition,
    previousTaskId,
    nextTaskId,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #EFEFEF",
  },
  contentWrapper: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  box: {
    margin: "12px auto",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    padding: 16,
  },
  linkArrowsText: {
    color: theme.palette.grey[500],
    fontSize: 12,
    textAlign: "center",
    marginBottom: 6,
  },
  sectionHeading: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 6,
    paddingLeft: theme.spacing(1),
  },
}));

const mapStateToProps = (state: PrecisionState) => ({
  taskData: state.api.asApplied.detail.content.data,
  error: selectItemError(state),
  isFetchingItem: selectIsFetchingItem(state),
  taskReport: selectReport(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      getPrecisionTaskDataApi,
      getPrecisionTaskDataReportApi,
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
const Content = connector(withWidth()(_Content));

export { Content };
