import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

type Props = {
  setFocusedRow: (val?: string) => void;
  sourceId: string;
};

export default function useFocusedTableRowSource({
  setFocusedRow,
  sourceId,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSetFocusedRow = () => dispatch(setFocusedRow(sourceId));

  useEffect(
    () => () => {
      if (history.action === "POP") {
        doSetFocusedRow();
      }
    },
    [sourceId, history, doSetFocusedRow],
  );

  return {
    doSetFocusedRow,
  };
}
