import { TelematicsState } from "../../reducers/telematics.reducer.types";

export const getTelematicsListPage = (state: TelematicsState) =>
  state.ui.telematicsList.page;
export const getTelematicsListOrder = (state: TelematicsState) =>
  state.ui.telematicsList.order;
export const getTelematicsListOrderBy = (state: TelematicsState) =>
  state.ui.telematicsList.orderBy;
export const getTelematicsListRowsPerPage = (state: TelematicsState) =>
  state.ui.telematicsList.rowsPerPage;
export const getTelematicsListSelected = (state: TelematicsState) =>
  state.ui.telematicsList.selected;

export const getTelematicsListTextFilter = (state: TelematicsState) =>
  state.ui.telematicsList.textFilter;
export const getTelematicsListAdvancedFilter = (state: TelematicsState) =>
  state.ui.telematicsList.advancedFilter;

export const selectIsAggregated = (state: TelematicsState) =>
  state.ui.telematicsList.aggregated;
