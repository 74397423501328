import React from "react";

import WarningIcon from "@mui/icons-material/Warning";
import Card from "@mui/material/Card";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    justifyContent: "center",
    padding: "8px 24px",
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.secondary.dark,
    marginRight: 8,
  },
  content: {
    margin: 0,
    fontSize: 13,
  },
});

function CfWarningCard(props) {
  const { children, classes } = props;
  return (
    <div test-id="warning">
      <Card className={classes.card}>
        <WarningIcon className={classes.icon} />
        <p className={classes.content}>{children}</p>
      </Card>
    </div>
  );
}

CfWarningCard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired,
};

CfWarningCard.defaultProps = {
  classes: {},
};

export default withStyles(styles)(CfWarningCard);
