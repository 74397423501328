/**
 * Created by ondrejzvara on 8.4.16.
 */

import angular from "angular";
import Spinner from "./Spinner.service";

export default angular
  .module("app.spinner", [])
  .run(run)
  .factory("Spinner", Spinner).name;

/*@ngInject*/
function run(Spinner, BACKEND_OPTIONS) {
  Spinner.whitelistDomain(BACKEND_OPTIONS.apiUrl);
}
