import React, { Component } from "react";

import EditIcon from "@mui/icons-material/Edit";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getNotificationsAdminPage,
  getNotificationsAdminOrder,
  getNotificationsAdminOrderBy,
  getNotificationsAdminRowsPerPage,
  getNotificationsAdminTextFilter,
  getAdminFarms,
  getAdminFarmsTotalCount,
  isFetchingAdminFarms,
} from "../../selectors/notifications.selectors";

import { fetchAdminFarms } from "../../actions/notifications.actions";

import { NAMESPACE as namespace } from "../../reducer/notifications.reducer";

import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../../shared/misc/helper";
import Countries from "../../../../../shared/services/Countries.service";
import { COLOR_GREY } from "../../../../../theme";
import FarmNotificationsStatus from "../../components/FarmNotificationsStatus/FarmNotificationsStatus";

const columns = {
  farmName: getColDesc(
    true,
    <div style={{ paddingLeft: 15 }}>
      <div>
        <FormattedMessage id="common.farm" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.id" />
      </div>
    </div>,
  ),
  adminEmail: getColDesc(
    false,
    <FormattedMessage id="NotificationsAdminTable.adminEmail" />,
  ),
  status: getColDesc(true, <FormattedMessage id="common.state" />),
  "usage.used": getColDesc(
    true,
    <FormattedMessage id="NotificationsAdminTable.notificationsCount" />,
  ),
};
const columnsLength = Object.keys(columns).length + 1;

const styles = (theme) => ({
  farmCell: {
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
  },
  countryFlag: {
    fontSize: 16,
    marginRight: 8,
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "inline-block",
      padding: "0px 10px 0px 0px",
    },
  },
  tableRowTools: {
    display: "none",
  },
  farmName: {
    fontWeight: 500,
  },
  farmId: {
    color: theme.palette.grey[500],
  },
});

export class NotificationsAdminTable extends Component {
  componentDidMount() {
    this.getAdminFarms();
  }

  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);
    const newProps = pick(this.props, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);
    if (!isEqual(newProps, oldProps)) {
      this.getAdminFarms();
    }
  }

  getAdminFarms() {
    this.props.fetchAdminFarms();
  }

  renderFarmEmailUsage = (farmUsage) => {
    const emailUsage = farmUsage?.find((usage) => usage.type === "EMAIL");
    return emailUsage ? (
      <span>
        {emailUsage.used}/{emailUsage.limit}
      </span>
    ) : (
      "-"
    );
  };

  render() {
    const {
      classes,
      count,
      farmsSettings,
      isFetching,
      langId,
      openFarmDialog,
      order,
      orderBy,
      page,
      rowsPerPage,
    } = this.props;
    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          langId={langId}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}
        {farmsSettings.length > 0 ? (
          <TableBody>
            {farmsSettings.map((farm) => (
              <TableRow className={classes.tableRow} key={farm.farmId}>
                <CfTableCell name="farmName">
                  <div className={classes.farmCell}>
                    <span className={classes.countryFlag}>
                      {Countries.countryToFlag(farm.farmCountryCode)}
                    </span>
                    <span>
                      <div className={classes.farmName}>{farm.farmName}</div>
                      <div className={classes.farmId}>{farm.farmId}</div>
                    </span>
                  </div>
                </CfTableCell>
                <CfTableCell name="adminEmail">
                  <span>{farm.adminEmail}</span>
                </CfTableCell>
                <CfTableCell name="status">
                  <FarmNotificationsStatus status={farm.status} />
                </CfTableCell>
                <CfTableCell name="usage.used">
                  {this.renderFarmEmailUsage(farm.usage)}
                </CfTableCell>
                <CfTableRowTools toolsClass={classes.tableRowTools}>
                  <CfTableRowToolButton
                    icon={<EditIcon />}
                    onClick={() => openFarmDialog(farm)}
                    tooltipMessage={<FormattedMessage id="common.edit2" />}
                  />
                </CfTableRowTools>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={columnsLength} />
        )}
        <CfTableFooter
          count={count}
          langId={langId}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    );
  }
}

NotificationsAdminTable.propTypes = {
  langId: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  fetchAdminFarms: PropTypes.func.isRequired,
  openFarmDialog: PropTypes.func.isRequired,
  farmsSettings: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
};

NotificationsAdminTable.defaultProps = {
  farmsSettings: [],
};

const mapStateToProps = (state) => ({
  page: getNotificationsAdminPage(state),
  order: getNotificationsAdminOrder(state),
  orderBy: getNotificationsAdminOrderBy(state),
  rowsPerPage: getNotificationsAdminRowsPerPage(state),
  textFilter: getNotificationsAdminTextFilter(state),
  count: getAdminFarmsTotalCount(state),
  farmsSettings: getAdminFarms(state),
  isFetching: isFetchingAdminFarms(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAdminFarms,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationsAdminTable));
