import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfNoData from "../CfNoData/CfNoData";

const styles = () => ({
  wrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
});

export const CfChartWrapper = (props) => {
  const { children, classes, data, hasNullData, isFetching, style, testId } =
    props;
  const hasNoData = !data || data.length < 1;

  return (
    <div className={classes.wrapper} data-test={testId} style={style}>
      {(() => {
        if (isFetching) {
          return <CircularProgress />;
        } else if (hasNoData || hasNullData) {
          return <CfNoData />;
        }
        return children;
      })()}
    </div>
  );
};

CfChartWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  children: PropTypes.node,
  data: PropTypes.array,
  style: PropTypes.object,
  testId: PropTypes.string,
  hasNullData: PropTypes.bool,
};

CfChartWrapper.defaultProps = {
  children: null,
  data: [],
  style: {},
  testId: null,
  hasNullData: false,
};

export default withStyles(styles)(CfChartWrapper);
