import React, { useEffect, useState, useContext, useMemo } from "react";

import { Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";

import {
  getUnitsForSowing,
  getUnitsIsFetching,
} from "../../../../../shared/api/core/units/units.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";
import Localization from "../../../../../shared/services/Localization.service";
import ActionsService from "../../../shared/services/Actions.service";
import { FormContext } from "../../actionOther.context";

function AmountSelector({ isFetching, units }) {
  const [totalArea, setTotalArea] = useState(0);
  const [dirtyInput, setDirtyInput] = useState("");
  const {
    setFieldValue,
    values: { parcels, totalAmount, unitId },
  } = useFormikContext();
  const { isEditing } = useContext(FormContext);
  const intl = useIntl();

  const updateFormValues = (value) => {
    // this is triggered on value change.
    // Computation is skipped for all values that cannot be properly converted to numbers.
    const isValidValue = Localization.checkValidStr(value, intl.locale);

    if (!isValidValue) {
      setFieldValue(dirtyInput, value);
      return;
    }
    // convert localization-formatted value strings to numbers
    const normalizedVal = Localization.str2numNonFixed(value, intl.locale);
    const normalizedTotalArea = Localization.str2numNonFixed(
      totalArea,
      intl.locale,
    );

    if (dirtyInput === "totalAmount") {
      const hectarAmount = normalizedVal / normalizedTotalArea;
      setFieldValue(
        "totalAmount",
        Localization.num2strNonFixed(normalizedVal, intl.locale),
      );
      setFieldValue(
        "hectarAmount",
        Localization.num2str(hectarAmount, intl.locale, 3),
      );
    } else if (dirtyInput === "hectarAmount") {
      const totalAmount = normalizedVal * normalizedTotalArea;
      setFieldValue(
        "hectarAmount",
        Localization.num2strNonFixed(normalizedVal, intl.locale),
      );
      setFieldValue(
        "totalAmount",
        Localization.num2str(totalAmount, intl.locale, 3),
      );
    }
  };

  const onChangeValue = (e) => {
    const value = e.currentTarget.value;
    updateFormValues(value);
  };

  const onFocus = (e) => {
    setDirtyInput(e.currentTarget.name);
  };

  useEffect(() => {
    if (parcels && parcels.length) {
      const area = ActionsService.getTotalArea(parcels);
      setTotalArea(Localization.num2str(area, intl.locale, 2));
    }
  }, [parcels, intl.locale]);

  useEffect(() => {
    if (totalArea && totalAmount) {
      const normalizedTotalAmount = Localization.str2numNonFixed(
        totalAmount,
        intl.locale,
      );
      const normalizedTotalArea = Localization.str2numNonFixed(
        totalArea,
        intl.locale,
      );
      const hectarAmount = normalizedTotalAmount / normalizedTotalArea;
      setFieldValue(
        "hectarAmount",
        Localization.num2str(hectarAmount, intl.locale, 3),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, totalArea, intl.locale]);

  const localizedNumberValidator = useMemo(
    () => validators.localizedNumber(intl.locale),
    [intl.locale],
  );

  if (isFetching) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={1}
    >
      <Grid item xs={9}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={<FormattedMessage id="action.total-amount" />}
            name="totalAmount"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={3}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!isEditing}
            label=" "
            name="unitId"
            select
            validate={validators.required}
            validateOnBlur
          >
            {units.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.name}
              </MenuItem>
            ))}
          </Field>
        </CfFormControl>
      </Grid>
      <Grid item xs={9}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={<FormattedMessage id="action.amount-per-hectare" />}
            name="hectarAmount"
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={3}>
        <span>{unitId}/ha</span>
      </Grid>
    </Grid>
  );
}

AmountSelector.propTypes = {
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  units: getUnitsForSowing(state),
  isFetching: getUnitsIsFetching(state),
});

export default connect(mapStateToProps)(AmountSelector);
