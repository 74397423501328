import * as types from "../actions/layersUI/layersUI.constants";

export default () =>
  (
    state = {
      layers: [],
    },
    action = {},
  ) => {
    switch (action.type) {
      case types.STORE_INITIAL_LAYERS:
        return {
          ...state,
          layers: action.layers,
        };
      case types.ADD_NEW_LAYER:
        return {
          ...state,
          layers: [...state.layers, action.layer],
        };
      case types.SET_LAYER_VISIBILITY:
        return {
          ...state,
          layers: state.layers.map((layer) => {
            if (action.layerId === layer.layerId) {
              return { ...layer, visible: action.visible };
            }
            return layer;
          }),
        };
      case types.SET_ALL_LAYERS_VISIBILITY:
        return {
          ...state,
          layers: state.layers.map((layer) => ({
            ...layer,
            visible: action.visible,
          })),
        };
      default:
        return state;
    }
  };
