import React, { Component } from "react";

import EventIcon from "@mui/icons-material/Event";
import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDatePicker from "../../../../shared/components/common/CfDatePicker/CfDatePicker";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";

const styles = (theme) => ({
  wrapper: {
    paddingLeft: 14,
    paddingRight: 14,
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  input: {
    padding: "4px 0 1px",
    fontSize: 14,
    fontWeight: 500,
  },
  icon: {
    fontSize: 20,
  },
  button: {
    padding: 4,
  },
  adornment: {},
  dateLabel: {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 5,
  },
  dateLabelOk: {
    color: theme.palette.grey[500],
  },
  dateLabelError: {
    color: theme.palette.error.main,
  },
  datepickerWrapper: {
    width: 120,
  },
});

class MapDatePicker extends Component {
  constructor(props) {
    super(props);
    this.todayEndMoment = moment().endOf("day");
    this.state = {
      dateValidFrom: moment(props.validFrom),
    };
  }

  componentWillUnmount() {
    this.props.setDate(
      moment().startOf("day").add(moment().utcOffset(), "m").toISOString(),
    );
  }

  onDateChange = (value) => {
    let valueString = null;
    if (value && moment.isMoment(value) && value.isValid()) {
      valueString = value
        .startOf("day")
        .add(value.utcOffset(), "m")
        .toISOString();
    }
    this.setState({
      dateValidFrom: value,
    });
    this.props.setDate(valueString);
  };

  renderIcon = () => <EventIcon className={this.props.classes.icon} />;

  render() {
    const { classes, error, minDate } = this.props;
    const { dateValidFrom } = this.state;
    return (
      <ToolbarSection>
        <div className={classes.wrapper}>
          <span
            className={`${classes.dateLabel} ${
              error ? classes.dateLabelError : classes.dateLabelOk
            }`}
          >
            <FormattedMessage id="common.validFrom" />
          </span>
          <span className={classes.datepickerWrapper} data-test="map-date">
            <CfDatePicker
              error={error}
              keyboardIcon={this.renderIcon()}
              maxDate={this.todayEndMoment}
              maxDateMessage=""
              minDate={minDate || undefined}
              minDateMessage=""
              propagateInvalidDate={true}
              input={{
                onChange: this.onDateChange,
                value: dateValidFrom,
                name: "validFrom",
                classes: {
                  input: classes.input,
                },
              }}
              KeyboardButtonProps={{
                classes: {
                  root: classes.button,
                },
              }}
            />
          </span>
        </div>
      </ToolbarSection>
    );
  }
}

MapDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  minDate: PropTypes.object,
  setDate: PropTypes.func.isRequired,
  error: PropTypes.bool,
  validFrom: PropTypes.string,
};

MapDatePicker.defaultProps = {
  minDate: null,
  error: false,
  validFrom: null,
};

export default withStyles(styles)(MapDatePicker);
