import * as types from "./reports.constants";

export default () =>
  (
    state = {
      isFetchingTypes: false,
      isFetchingReport: false,
      isFetchingSowingPlan: false,
      noContentReport: false,
      types: [],
      error: {},
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_REPORT_TYPES:
        return {
          ...state,
          isFetchingTypes: true,
          error: {},
        };
      case types.GET_REPORT_TYPES_SUCCESS:
        return {
          ...state,
          isFetchingTypes: false,
          types: action.payload,
        };
      case types.GET_REPORT_TYPES_ERROR:
        return {
          ...state,
          isFetchingTypes: false,
          error: action.payload,
        };
      case types.GET_REPORT:
        return {
          ...state,
          isFetchingReport: true,
          error: {},
        };
      case types.GET_REPORT_SUCCESS:
        return {
          ...state,
          isFetchingReport: false,
          noContentReport: action.payload.status === 204,
          error: {},
        };
      case types.GET_REPORT_ERROR:
        return {
          ...state,
          isFetchingReport: false,
          error: action.payload,
        };
      case types.GET_SOWING_PLAN:
        return {
          ...state,
          isFetchingSowingPlan: true,
          error: {},
        };
      case types.GET_SOWING_PLAN_SUCCESS:
        return {
          ...state,
          isFetchingSowingPlan: false,
          noContentReport: action.payload.status === 204,
          error: {},
        };
      case types.GET_SOWING_PLAN_ERROR:
        return {
          ...state,
          isFetchingSowingPlan: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
