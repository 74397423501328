import { AnyAction } from "redux";

import * as types from "../actions/telematicsAggregationDetail.constants";

import { TelematicsAggregationDetail } from "../telematics.types";

export default () =>
  (
    state: TelematicsAggregationDetail = {
      relatedDrives: null,
    },
    action: AnyAction,
  ) => {
    switch (action.type) {
      case types.SAVE_RELATED_DRIVES:
        return {
          ...state,
          relatedDrives: action.payload,
        };
      default:
        return state;
    }
  };
