import React, { Component, Fragment } from "react";

import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from "redux-form";

import {
  getErrorDetailedMessage,
  getPorErrorDetailMessage,
} from "../../../../shared/api/agroevidence/reports/reports.selectors";
import {
  getIsFetchingTypes,
  getIsFetchingReport,
  getIsFetchingSowingPlan,
} from "../../../../shared/api/core/reports/reports.selectors";
import { getNoContentReports } from "../../selectors/reports.selectors";

import {
  clearNoContentReport,
  getReports,
  exportSowingPlan,
  sendFertilizerUsageReport,
  sendPorUsageReport,
} from "../../actions/reports.actions";

import { getTypes } from "../../../../shared/api/core/reports/reports.api";
import CfDatePicker from "../../../../shared/components/common/CfDatePicker/CfDatePicker";
import PageHeader from "../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfReduxFormSwitch from "../../../../shared/components/form/CfReduxFormSwitch/CfReduxFormSwitch";
import * as validators from "../../../../shared/misc/validators";
import FertilizerUsageReport from "../../components/FertilizerUsageReport/FertilizerUsageReport";
import NoContentReportMessage from "../../components/NoContentReportMessage/NoContentReportMessage";
import { PorUsageReport } from "../../components/PorUsageReport";
import { ReportsHistoryDialog } from "../../components/ReportsHistory";
import ReportTypes from "../../components/ReportTypes/ReportTypes";
import SowingPlanCsvExport from "../../components/SowingPlanCsvExport/SowingPlanCsvExport";
import ReportsParcelsControl from "../ReportsParcelsControl/ReportsParcelsControl";

const FORM_NAME = "reports";
const PDF = "pdf";
const XLS = "xls";

const styles = (theme) => ({
  content: {
    maxWidth: 600,
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
  },
  switcher: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: 0,
    marginBottom: 20,
  },
  button: {
    margin: "10px",
  },
  progress: {
    marginLeft: 10,
  },
  buttons: {
    marginTop: 45,
    marginBottom: 30,
  },
  reportButton: {
    position: "absolute",
    right: "10px",
    top: "15px",
  },
});

export class Reports extends Component {
  constructor(props) {
    super(props);
    this.getReportTypes();
    this.props.clearNoContentReport();
    this.props.initialize({
      from: moment().startOf("year"),
      to: moment(),
    });
    this.state = {
      exported: "",
      isReportDialogOpen: false,
    };
  }

  componentWillUnmount() {
    this.props.clearNoContentReport();
  }

  onSubmit = (exportType) =>
    this.props.handleSubmit((values) => {
      this.setExportedState(exportType);
      this.props.getReports(values, exportType);
    });

  getReportTypes = () => {
    this.props.getTypes();
  };

  setExportedState = (type) => {
    this.setState({ exported: type });
  };

  openHistoryReportDialogHandler = () => {
    this.setState({ isReportDialogOpen: true });
  };

  render() {
    const {
      allParcels,
      classes,
      errorDetailedMessage,
      farm,
      formErrors,
      intl: { formatMessage },
      isFetchingReports,
      isFetchingSowingPlan,
      loading,
      noContentReports,
      porErrorDetailedMessage,
    } = this.props;
    const exportLabel = formatMessage({ id: "common.export" });
    return (
      <Fragment>
        <div className={classes.wrapper}>
          <Button
            className={classes.reportButton}
            onClick={this.openHistoryReportDialogHandler}
            startIcon={<TextSnippetOutlinedIcon />}
            variant="contained"
          >
            <FormattedMessage id="button.reportHistory" />
          </Button>
          <div className={classes.content}>
            <PageHeader
              classes={{ header: classes.header }}
              heading={
                <PageHeading
                  dataTest="reports-heading"
                  translationId="common.reports"
                />
              }
            />
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                <form>
                  <Grid container justifyContent="center" spacing={5}>
                    <Grid item xs={6}>
                      <CfFormControl>
                        <Field
                          component={CfDatePicker}
                          data-test="date"
                          label={<FormattedMessage id="common.date-from" />}
                          name="from"
                          propagateInvalidDate
                          validate={[
                            validators.required,
                            validators.isValidDate,
                          ]}
                          {...formErrors.date}
                        />
                      </CfFormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <CfFormControl>
                        <Field
                          component={CfDatePicker}
                          data-test="date"
                          label={<FormattedMessage id="common.date-to" />}
                          name="to"
                          propagateInvalidDate
                          validate={[
                            validators.required,
                            validators.isValidDate,
                          ]}
                          {...formErrors.date}
                        />
                      </CfFormControl>
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    label={<FormattedMessage id="Reports.all" />}
                    labelPlacement="start"
                    classes={{
                      root: classes.switcher,
                    }}
                    control={
                      <Field
                        color="primary"
                        component={CfReduxFormSwitch}
                        id="allParcels"
                        name="allParcels"
                      />
                    }
                  />
                  {!allParcels && (
                    <FieldArray
                      component={ReportsParcelsControl}
                      formName={FORM_NAME}
                      name="parcels"
                    />
                  )}
                  <Field
                    component={ReportTypes}
                    formName={FORM_NAME}
                    name="categories"
                  />
                  <Grid
                    alignItems="center"
                    className={classes.buttons}
                    container
                    justifyContent="center"
                    spacing={0}
                  >
                    <Button
                      className={classes.button}
                      color="primary"
                      id="pdf"
                      onClick={this.onSubmit(PDF)}
                      variant="contained"
                      disabled={
                        this.state.exported === PDF && isFetchingReports
                      }
                    >
                      {`${exportLabel} ${PDF}`}
                      {isFetchingReports && this.state.exported === PDF && (
                        <CircularProgress
                          className={classes.progress}
                          size={21}
                        />
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      color="primary"
                      id="xls"
                      onClick={this.onSubmit(XLS)}
                      variant="contained"
                      disabled={
                        this.state.exported === XLS && isFetchingReports
                      }
                    >
                      {`${exportLabel} ${XLS}`}
                      {isFetchingReports && this.state.exported === XLS && (
                        <CircularProgress
                          className={classes.progress}
                          size={21}
                        />
                      )}
                    </Button>
                  </Grid>
                </form>
                {farm.customer.countryCode === "CZ" && (
                  <Fragment>
                    <Divider className={classes.divider} variant="fullWidth" />
                    <SowingPlanCsvExport
                      isFetching={isFetchingSowingPlan}
                      onCsvExport={this.props.exportSowingPlan}
                    />
                  </Fragment>
                )}
                {farm.customer.countryCode === "CZ" && (
                  <Fragment>
                    <Divider className={classes.divider} variant="fullWidth" />
                    <FertilizerUsageReport
                      errorDetailedMessage={errorDetailedMessage}
                      farmId={farm.id}
                      sendFertilizerUsageReportToEagri={
                        this.props.sendFertilizerUsageReport
                      }
                    />
                    <Divider className={classes.divider} variant="fullWidth" />
                    <PorUsageReport
                      errorDetailedMessage={porErrorDetailedMessage}
                      farmId={farm.id}
                      sendPorUsageReportToEagri={this.props.sendPorUsageReport}
                    />
                  </Fragment>
                )}
              </div>
            )}
          </div>
          {noContentReports.length > 0 && (
            <NoContentReportMessage
              clearNoContentReports={this.props.clearNoContentReport}
              noContentReports={noContentReports}
            />
          )}
        </div>
        <ReportsHistoryDialog
          isOpen={this.state.isReportDialogOpen}
          onClose={() => this.setState({ isReportDialogOpen: false })}
          porHistoryReport={this.props.porHistoryReport}
        />
      </Fragment>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object,
  farm: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  getTypes: PropTypes.func.isRequired,
  getReports: PropTypes.func.isRequired,
  allParcels: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isFetchingReports: PropTypes.bool.isRequired,
  noContentReports: PropTypes.array,
  clearNoContentReport: PropTypes.func.isRequired,
  exportSowingPlan: PropTypes.func.isRequired,
  isFetchingSowingPlan: PropTypes.bool.isRequired,
  sendFertilizerUsageReport: PropTypes.func.isRequired,
  sendPorUsageReport: PropTypes.func.isRequired,
  getPorUsageReport: PropTypes.func,
  porHistoryReport: PropTypes.object,
  errorDetailedMessage: PropTypes.object,
  porErrorDetailedMessage: PropTypes.object,
};

Reports.defaultProps = {
  classes: {},
  noContentReports: [],
  allParcels: false,
};

const selector = formValueSelector(FORM_NAME);

const validate = (values) => {
  const errors = {};

  if (!values.parcels || (values.parcels?.length === 0 && !values.allParcels)) {
    errors.parcels = { _error: true };
  }

  if (
    !values.categories ||
    values.categories?.length === 0 ||
    !Object.values(values.categories)
      .map((cat) => Object.values(cat))
      .flat(1)
      .find((val) => val === true)
  ) {
    errors.categories = { _error: true };
  }

  if (values.to?.isBefore(moment(values.from))) {
    errors.date = {
      _error: true,
      error: true,
      helperText: <FormattedMessage id="DatePicker.wrongDateRange" />,
    };
  }

  return errors;
};

const mapStateToProps = (state) => ({
  allParcels: selector(state, "allParcels"),
  loading: getIsFetchingTypes(state),
  isFetchingReports: getIsFetchingReport(state),
  isFetchingSowingPlan: getIsFetchingSowingPlan(state),
  formErrors: getFormSyncErrors(FORM_NAME)(state),
  noContentReports: getNoContentReports(state),
  errorDetailedMessage: getErrorDetailedMessage(state),
  porErrorDetailedMessage: getPorErrorDetailMessage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTypes,
      getReports,
      clearNoContentReport,
      exportSowingPlan,
      sendFertilizerUsageReport,
      sendPorUsageReport,
    },
    dispatch,
  );

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
  withStyles(styles),
)(Reports);
