import React, { FC } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import {
  getCropUsage,
  getIsFetchingCropUsage,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CropUseTypeTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface CropUsageSelectorProp {
  cropUsage: CropUseTypeTo[];
  isEditing: boolean;
  isFetchingCropUsage: boolean;
}

const CropUsageSelector: FC<CropUsageSelectorProp> = ({
  cropUsage,
  isEditing,
  isFetchingCropUsage,
}) => {
  if (isFetchingCropUsage) {
    return <LinearProgress color="secondary" />;
  }
  const isDisable = cropUsage.length === 0 || !isEditing;

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={isDisable}
        label={<FormattedMessage id="action.cropUsage" />}
        name="cropUseType"
        select
        validate={isDisable ? undefined : validators.required}
        validateOnBlur
      >
        {cropUsage.map((item) => (
          <MenuItem key={item.legislativeCode} value={item.legislativeCode}>
            {item.description}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  cropUsage: getCropUsage(state),
  isFetchingCropUsage: getIsFetchingCropUsage(state),
});

export default connect(mapStateToProps)(CropUsageSelector);
