import React, { useState, useEffect } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";

import { getError } from "../../../misc/formHelpers";
import FormInput from "../FormInput/FormInput";

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 0,
  },
  label: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: "none",
    transform: "translate(0px, 16px) scale(1)",
  },
  labelShrink: {
    transform: "translate(0px, 0px) scale(0.75)",
  },
  hintText: {
    marginLeft: 0,
  },
}));

type Props = {
  autoFocus?: boolean;
  fullWidth?: boolean;
  hasIconButton?: boolean;
  label?: React.ReactNode;
  name: string;
};

const FormPasswordInput: React.FC<Props> = (props) => {
  const {
    autoFocus = false,
    fullWidth = false,
    hasIconButton = true,
    label = "",
    name,
  } = props;
  const { ...settings } = props;
  const fieldProps = useField(settings.name || "");
  const meta = fieldProps[1];

  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  useEffect(
    () =>
      // Cleanup function when component unmounts
      () => {
        setVisible(false);
      },
    [],
  );

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <FormControl
      className={classes.textField}
      error={!!getError(meta)}
      fullWidth={fullWidth}
      margin="dense"
    >
      <InputLabel
        classes={{
          root: classes.label,
          shrink: classes.labelShrink,
        }}
      >
        {label}
      </InputLabel>
      <FormInput
        autoComplete="new-password"
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        id="key"
        margin="dense"
        name={name}
        type={visible ? "text" : "password"}
        endAdornment={
          hasIconButton && (
            <InputAdornment position="end">
              <IconButton
                onClick={toggleVisibility}
                onMouseDown={(e) => e.preventDefault()}
                size="large"
              >
                {visible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </FormControl>
  );
};

export default FormPasswordInput;
