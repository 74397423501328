import React from "react";

import AppBar from "@mui/material/AppBar";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import useWidth from "../../../../shared/hooks/useWidth";
import DesktopMenu from "../DesktopMenu/DesktopMenu";
import MobileMenu from "../MobileMenu/MobileMenu";

const Navbar = ({ ngProps }: { ngProps: { farm: { id: string } } }) => {
  const classes = useStyles();
  const width = useWidth();

  const LogoLink = (
    <Link className={classes.logoWrapper} to={`/farm/${ngProps.farm.id}/map`}>
      <img alt="logo" className={classes.logoImg} />
    </Link>
  );

  return (
    <AppBar
      className={classes.wrapper}
      color="primary"
      elevation={2}
      id="appbar"
      position="fixed"
    >
      {width === "xs" || width === "sm" ? (
        <div className={classes.sectionMobile}>
          {LogoLink}
          <MobileMenu ngProps={ngProps} />
        </div>
      ) : (
        <div className={classes.sectionDesktop}>
          {LogoLink}
          <DesktopMenu ngProps={ngProps} />
        </div>
      )}
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // hack to display navbar correctly with Product Fruits banner
  "@global": {
    ".productfruits--banners-shown #appbar": {
      top: "48px !important",
    },
  },
  wrapper: {
    height: 60,
    zIndex: 1030,
    // quick fix, but would be nice to explore the reason, because it is not the first case
    // styles are sometimes overridden, it seems to be related to lazy loading
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    marginLeft: 10,
    marginRight: 5,
    padding: 10,
  },
  logoImg: {
    width: theme.assets.navbar.width,
    content: `url(${theme.assets.navbar.src})`,
  },
  sectionDesktop: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    color: "white",
  },
  sectionMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    color: "white",
  },
}));

export { Navbar };
