import debounce from "lodash/debounce";
import { toLonLat } from "ol/proj";

import {
  getSelectedParcel,
  getBufferSize,
  getStage,
  getValidFrom,
} from "../../selectors/editor.selectors";
import { getInteraction } from "../../selectors/map.selectors";

import {
  editorClearDrawingError,
  editorHintClose,
  editorHintRefresh,
  editorHintReopen,
  editorSetDrawingError,
  editorSetSelected,
  editorSetStage,
  handleGenericError,
  handleResponse,
  showErrorHint,
} from "../editor/editor.actions";
import {
  removeEL,
  setHoverStyleEL,
  setMouseClickEL,
  setCoordinateHoverEL,
  setCoordinateClickEL,
  setMouseMoveEL,
} from "../eventListener/eventListener.actions";
import {
  removeLayer,
  setLayer,
  clearLayer,
  setGeometry,
  setModifyIA,
  setSnapIA,
  removeModifyIA,
  removeSnapIA,
} from "../interaction/interaction.actions";
import {
  mapSetCursor,
  setMapFetching,
  zoomToGeometry,
} from "../map/map.actions";
import { addAreaOverlay, removeOverlays } from "../overlay/overlay.actions";
import { onMouseMove } from "../split/split.actions";
import { refreshSplitStyles } from "../style/style.actions";

import * as types from "./buffer.constants";
import * as errors from "../../constants/errors.constants";
import * as stages from "../../constants/stages.constants";
import * as tools from "../../constants/tools.constants";

import {
  getParcelGeometryById,
  splitGeometry,
  bufferGeometry,
} from "../../../../shared/api/core/geometry/geometry.api";
import Feature from "../../services/Feature.service";
import Geometry, { GEOM_TYPES } from "../../services/geometry/Geometry.service";
import { STYLE_TYPES } from "../../services/styles/CommonStyles.service";

import { BUFFER_TYPES } from "./bufferTypes";

/*
 * STAGE_1 - tool opened, no parcel selected
 * STAGE_2 - parcel selected, but buffer line not drawn (only partial buffer)
 * STAGE_3 - overall buffer or buffer line drawn at partial buffer
 * STAGE_4 - buffer finished, resulting parcels visible
 */

let IS_TOOL_ACTIVE = false;
let IS_INVERTED = false;
const BUFFER_RESULT = "buffer_result";
const BUFFER_PARTIAL_P1 = "buffer_partial_p1";
const BUFFER_PARTIAL_P2 = "buffer_partial_p2";
const BUFFER_PARTIAL_LINE = "buffer_partial_line";
const BUFFER_PARCEL_BORDER = "buffer_parcel_border";

export const bufferStart = () => (dispatch) => {
  IS_TOOL_ACTIVE = true;
  dispatch(editorSetStage(stages.STAGE_1));
  dispatch(refreshSplitStyles());
  dispatch(
    setHoverStyleEL(
      (f) => Feature.isFeature(f) && Feature.isWithoutCrop(f),
      refreshSplitStyles,
      "bufferHoverStyleELKey",
    ),
  );
  dispatch(
    setMouseClickEL(onMouseClick, refreshSplitStyles, "bufferSelectELKey"),
  );
  dispatch(
    setMouseMoveEL(
      (feature, evt) => onMouseMove(feature, evt),
      refreshSplitStyles,
      "bufferHoverHintELKey",
    ),
  );
  dispatch(editorHintReopen());
};

export const bufferSubmit = () => (dispatch, getState) => {
  const geometry = getInteraction(getState()).getGeometry(BUFFER_RESULT);
  return dispatch(bufferParcel(geometry));
};

export const bufferEnd = () => (dispatch, getState) => {
  IS_TOOL_ACTIVE = false;
  dispatch(editorHintClose());

  dispatch(removeEL("bufferHoverStyleELKey"));
  dispatch(removeEL("bufferHoverHintELKey"));
  dispatch(removeEL("bufferSelectELKey"));

  dispatch(removeModifyIA(BUFFER_PARTIAL_P1));
  dispatch(removeModifyIA(BUFFER_PARTIAL_P2));
  dispatch(removeSnapIA(BUFFER_PARCEL_BORDER));
  dispatch(removeOverlays());
  if (getStage(getState()) !== stages.STAGE_1) {
    dispatch(removeLayer(BUFFER_RESULT));
    dispatch(endPartialBuffer());
  }
};

/** ************************************************************ */

export const onMouseClick = (feature) => (dispatch) => {
  if (!Feature.isFeature(feature) || !Feature.isWithoutCrop(feature)) {
    return;
  }

  dispatch(
    editorSetSelected({
      id: feature.get("id"),
      localName: feature.get("local_name"),
      currentSeedDate: feature.get("seed_date_start"),
    }),
  );

  dispatch(removeEL("bufferHoverStyleELKey"));
  dispatch(removeEL("bufferHoverHintELKey"));
  dispatch(removeEL("bufferSelectELKey"));
  dispatch(setMapFetching(true));

  return dispatch(getParcelGeometryById(feature.get("id")))
    .then(({ payload }) => {
      dispatch(editorSetStage(stages.STAGE_2));
      dispatch(editorHintReopen());
      IS_INVERTED = false;

      dispatch(zoomToGeometry(payload.geometry));
      dispatch(
        setLayer(BUFFER_RESULT, GEOM_TYPES.POLYGON, STYLE_TYPES.BUFFER, false),
      );
      dispatch(mapSetCursor(""));
      dispatch(startPartialBuffer(payload.geometry));
    })
    .finally(() => {
      dispatch(setMapFetching(false));
    });
};

export const startPartialBuffer = (parcelGeometry) => (dispatch) => {
  IS_INVERTED = false;
  dispatch(removeOverlays());
  dispatch(
    setLayer(
      BUFFER_PARTIAL_LINE,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.BUFFER,
      false,
    ),
  );
  dispatch(
    setLayer(
      BUFFER_PARTIAL_P1,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.DRAWING,
      false,
    ),
  );
  dispatch(
    setLayer(
      BUFFER_PARTIAL_P2,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.DRAWING,
      false,
    ),
  );
  dispatch(
    setCoordinateHoverEL((coordinate) => {
      dispatch(setPartialBufferPoint1(coordinate, parcelGeometry));
    }, "point1MoveELKey"),
  );
  dispatch(
    setCoordinateClickEL(
      (coordinate) => onPoint1End(coordinate, parcelGeometry),
      "point1ClickELKey",
    ),
  );
};

export const endPartialBuffer = () => (dispatch, getState) => {
  dispatch(removeEL("point1MoveELKey"));
  dispatch(removeEL("point1ClickELKey"));
  const bufferLine =
    getInteraction(getState()).getGeometry(BUFFER_PARTIAL_LINE);
  if (bufferLine) {
    dispatch(removeEL("point2MoveELKey"));
    dispatch(removeEL("point2ClickELKey"));
  }

  dispatch(removeLayer(BUFFER_PARTIAL_P1));
  dispatch(removeLayer(BUFFER_PARTIAL_P2));
  dispatch(removeLayer(BUFFER_PARTIAL_LINE));
};

export const invertPartialBuffer =
  (parcelGeometry, bufferSize) => (dispatch, getState) => {
    IS_INVERTED = !IS_INVERTED;
    const border = Geometry.getPolygonOuterBorder(parcelGeometry);
    const point1 = getInteraction(getState()).getGeometry(BUFFER_PARTIAL_P1);
    const point2 = getInteraction(getState()).getGeometry(BUFFER_PARTIAL_P2);

    const lineslice = Geometry.getPolygonLineSlice(
      point1,
      point2,
      border,
      IS_INVERTED,
    );

    if (lineslice?.geometry) {
      dispatch(setGeometry(BUFFER_PARTIAL_LINE, lineslice.geometry));
      if (bufferSize) {
        debouncedCreateBufferResult(
          parcelGeometry,
          lineslice,
          bufferSize,
          true,
          dispatch,
        );
      }
    }
  };

export const updateBuffer =
  (parcelGeometry, bufferType, bufferSize) => (dispatch, getState) => {
    const bufferLine = getBufferLine(parcelGeometry, bufferType, getState);

    if (bufferLine && bufferSize) {
      if (bufferSize) {
        debouncedCreateBufferResult(
          parcelGeometry,
          bufferLine,
          bufferSize,
          true,
          dispatch,
        );
      }
    } else {
      dispatch(clearLayer(BUFFER_RESULT));
      dispatch(removeOverlays());
      if (bufferSize === 0) {
        dispatch(editorSetDrawingError(errors.BUFFER_SIZE_INVALID_ERROR));
      } else {
        dispatch(editorClearDrawingError(true));
        dispatch(editorSetStage(bufferLine ? stages.STAGE_3 : stages.STAGE_2));
        dispatch(editorHintReopen());
      }
    }
  };

export const setBufferSize = (size) => ({
  type: types.BUFFER_SET_SIZE,
  size,
});

export const setBufferType = (bufferType) => ({
  type: types.BUFFER_SET_TYPE,
  bufferType,
});

/** ************************************************************ */

const setPartialBufferPoint1 = (coordinate, parcelGeometry) => (dispatch) => {
  const border = Geometry.getPolygonOuterBorder(parcelGeometry);
  const nearestPoint = Geometry.getNearestPointOnLine(
    border,
    toLonLat(coordinate),
  );
  dispatch(setGeometry(BUFFER_PARTIAL_P1, nearestPoint.geometry));
};

const setPartialBufferPoint2 =
  (coordinate, parcelGeometry, finished = false) =>
  (dispatch) => {
    const border = Geometry.getPolygonOuterBorder(parcelGeometry);
    const nearestPoint = Geometry.getNearestPointOnLine(
      border,
      toLonLat(coordinate),
    );
    dispatch(setGeometry(BUFFER_PARTIAL_P2, nearestPoint.geometry));

    dispatch(
      createPartialBuffer(
        parcelGeometry,
        nearestPoint,
        BUFFER_PARTIAL_P2,
        finished,
      ),
    );
  };

const createPartialBuffer =
  (parcelGeometry, pointGeometry, pointId, finished = true) =>
  (dispatch, getState) => {
    const border = Geometry.getPolygonOuterBorder(parcelGeometry);
    const startPoint =
      pointId === BUFFER_PARTIAL_P2 ? BUFFER_PARTIAL_P1 : BUFFER_PARTIAL_P2;
    const startPointGeometry =
      getInteraction(getState()).getGeometry(startPoint);
    const lineslice = Geometry.getPolygonLineSlice(
      startPointGeometry,
      pointGeometry,
      border,
      IS_INVERTED,
    );
    dispatch(setGeometry(BUFFER_PARTIAL_LINE, lineslice.geometry));

    if (getStage(getState()) === stages.STAGE_2) {
      dispatch(editorSetStage(stages.STAGE_3));
    }
    const bufferSize = getBufferSize(getState());
    if (bufferSize) {
      debouncedCreateBufferResult(
        parcelGeometry,
        lineslice,
        bufferSize,
        finished,
        dispatch,
      );
    }
  };

const onPoint1End = (clickCoordinate, parcelGeometry) => (dispatch) => {
  dispatch(removeEL("point1MoveELKey"));
  dispatch(removeEL("point1ClickELKey"));
  dispatch(setPartialBufferPoint1(clickCoordinate, parcelGeometry));
  dispatch(
    setCoordinateHoverEL((hoverCoordinate) => {
      dispatch(setPartialBufferPoint2(hoverCoordinate, parcelGeometry));
    }, "point2MoveELKey"),
  );
  dispatch(mapSetCursor(""));
  dispatch(
    setCoordinateClickEL(
      (coordinate) => onPoint2End(coordinate, parcelGeometry),
      "point2ClickELKey",
    ),
  );
};

const onPoint2End = (clickCoordinate, parcelGeometry) => (dispatch) => {
  dispatch(setPartialBufferPoint2(clickCoordinate, parcelGeometry, true));
  dispatch(removeEL("point2MoveELKey"));
  dispatch(removeEL("point2ClickELKey"));
  dispatch(setPartialBufferPointsEditing(parcelGeometry));
};

const setPartialBufferPointsEditing = (parcelGeometry) => (dispatch) => {
  dispatch(removeModifyIA(BUFFER_PARTIAL_P1));
  dispatch(removeModifyIA(BUFFER_PARTIAL_P2));
  dispatch(removeSnapIA(BUFFER_PARCEL_BORDER));
  dispatch(
    setModifyIA(
      (geom) => {
        dispatch(createPartialBuffer(parcelGeometry, geom, BUFFER_PARTIAL_P1));
      },
      null,
      null,
      BUFFER_PARTIAL_P1,
      GEOM_TYPES.LINESTRING,
    ),
  );
  dispatch(
    setModifyIA(
      (geom) => {
        dispatch(createPartialBuffer(parcelGeometry, geom, BUFFER_PARTIAL_P2));
      },
      null,
      null,
      BUFFER_PARTIAL_P2,
      GEOM_TYPES.LINESTRING,
    ),
  );
  const snapOptions = { vertex: false, pixelTolerance: 10000 };
  dispatch(setSnapIA(BUFFER_PARCEL_BORDER, null, parcelGeometry, snapOptions));
};

const getBufferLine = (parcelGeometry, bufferType, getState) => {
  let bufferLine;
  if (bufferType === BUFFER_TYPES.OVERALL) {
    bufferLine = Geometry.getPolygonOuterBorder(parcelGeometry);
  } else if (bufferType === BUFFER_TYPES.PARTIAL) {
    bufferLine = getInteraction(getState()).getGeometry(BUFFER_PARTIAL_LINE);
  }
  return bufferLine;
};

const createBufferResult =
  (parcelGeometry, bufferLine, bufferSize, finished) => (dispatch) => {
    dispatch(bufferGeometry(bufferLine, bufferSize))
      .then(handleResponse)
      .then((payload) => {
        const differenceResult = Geometry.getDifference(
          parcelGeometry,
          payload.geometry,
        );

        if (differenceResult?.geometry) {
          dispatch(
            setGeometry(BUFFER_RESULT, differenceResult.geometry, finished),
          );
        } else {
          dispatch(clearLayer(BUFFER_RESULT));
        }
        if (finished) {
          if (!differenceResult?.geometry) {
            dispatch(editorSetDrawingError(errors.BUFFER_SIZE_TOO_BIG_ERROR));
            debouncedGetIntermediateResult.cancel();
          } else if (
            Geometry.getType(differenceResult.geometry) !== GEOM_TYPES.POLYGON
          ) {
            dispatch(
              editorSetDrawingError(errors.BUFFER_TOO_MANY_PARCELS_ERROR),
            );
            debouncedGetIntermediateResult.cancel();
          } else {
            dispatch(editorClearDrawingError(true));
            debouncedGetIntermediateResult(differenceResult.geometry, dispatch);
          }
        }
      });
  };

const debouncedCreateBufferResult = debounce(
  (parcelGeometry, bufferLine, bufferSize, finished, dispatch) =>
    dispatch(
      createBufferResult(parcelGeometry, bufferLine, bufferSize, finished),
    ),
  100,
);

const getIntermediateResult = (geometry) => (dispatch, getState) => {
  dispatch(setMapFetching(true));
  return dispatch(bufferParcel(geometry, true))
    .then(handleResponse)
    .then((payload) => {
      if (payload.length && IS_TOOL_ACTIVE) {
        dispatch(removeOverlays());
        const elementId = "parcel-area";
        payload.forEach((parcel, index) => {
          dispatch(addAreaOverlay(parcel, elementId, `${elementId}-${index}`));
        });

        const state = getState();
        const data = { main: getSelectedParcel(state), results: payload };
        const isEnteringStage4 = getStage(state) !== stages.STAGE_4;
        if (isEnteringStage4) {
          dispatch(editorSetStage(stages.STAGE_4));
          dispatch(editorHintReopen(data));
        } else {
          dispatch(editorHintRefresh(data));
        }
      }
    })
    .catch((payload) => dispatch(handleGenericError(payload, tools.BUFFER)))
    .catch((reason) => dispatch(showErrorHint(reason)))
    .finally(() => dispatch(setMapFetching(false)));
};

const debouncedGetIntermediateResult = debounce(
  (geometry, dispatch) => dispatch(getIntermediateResult(geometry)),
  500,
);

export const bufferParcel =
  (geometry, isDryMode = false) =>
  (dispatch, getState) => {
    const state = getState();
    const feature = getSelectedParcel(state);
    const splitLine = Geometry.getPolygonOuterBorder(geometry);
    const validFrom = getValidFrom(state);

    return dispatch(
      splitGeometry(splitLine.geometry, feature.id, isDryMode, validFrom),
    );
  };
