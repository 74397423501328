import React, { Component } from "react";

import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFarmsSuggestions } from "../../../shared/selectors/manager.selectors";
import { getSensorsAdminIsFetchingFarms } from "../../selectors/sensors.selectors";

import {
  getFarmsByName,
  resetFarms,
} from "../../../shared/actions/manager.actions";

import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

export class FarmSelector extends Component {
  constructor(props) {
    super(props);
    this.fetchFarms("");
  }

  componentWillUnmount() {
    this.props.resetFarms();
  }

  onSuggestionClear = () => {
    this.props.resetFarms();
  };

  fetchFarms = (name) => {
    this.props.getFarmsByName(name);
  };

  // TODO - fix nacitani vetsiho mnozstvi farem
  render() {
    const { input, isFetchingFarms, onBlur, onChange, suggestions } =
      this.props;
    const { formatMessage } = this.props.intl;
    const label = formatMessage({ id: "common.farm" });
    return (
      <CfAutocomplete
        id="farm-selector"
        input={input}
        label={label}
        loading={isFetchingFarms}
        loadOptions={this.fetchFarms}
        onBlur={(value) => onBlur(value)}
        onChange={(value) => onChange(value)}
        suggestions={suggestions}
      />
    );
  }
}

FarmSelector.propTypes = {
  input: PropTypes.any,
  intl: PropTypes.object.isRequired,
  isFetchingFarms: PropTypes.bool.isRequired,
  suggestions: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  getFarmsByName: PropTypes.func.isRequired,
  resetFarms: PropTypes.func.isRequired,
};

FarmSelector.defaultProps = {
  input: undefined,
  suggestions: [],
  onChange: () => {},
  onBlur: () => {},
};

const mapStateToProps = (state) => ({
  isFetchingFarms: getSensorsAdminIsFetchingFarms(state),
  suggestions: getFarmsSuggestions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFarmsByName,
      resetFarms,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FarmSelector));
