/**
 * Created by brasko on 4.9.2015.
 */

/*@ngInject*/
export default function ObjTpl(Logger) {
  var objects = {
    action: {
      actionType: null,
      dateStart: new Date(),
      dateEnd: new Date(),
      note: "",
      materialList: [],
      expenseList: [],
      revenueList: [
        {
          amount: null,
          unit: {
            id: "t",
          },
        },
      ],
      pastures: [],
      parcelList: [],
      sumArea: 0,
      targetCrop: null,
      fertilizationTarget: null,
      sumAreaChanged: false,
    },
    action_expense_seed: {
      expenseType: {
        id: "M",
      },
      material: {
        material: null,
      },
      unit: null,
    },
    classifier_center: {
      name: "",
    },
    classifier_provider: {
      name: "",
      ico: "",
      address: "",
      type: {
        id: 1,
      },
    },
    classifier_machinery: {
      machineryType: {
        id: "V",
      },
      registrationNum: "",
      name: "",
      regionalBrand: "",
    },
    classifier_worker: {
      name: "abc",
      user: {
        lastName: null,
        firstName: null,
        email: null,
      },
    },
    classifier_machineryType: [
      {
        id: "V",
        name: "Stroj",
      },
      {
        id: "T",
        name: "Závěsné nářadí",
      },
    ],
    ActionExpenseMaterial: {
      "@class": "ActionExpenseMaterial",
      amount: null,
      priceUnit: null,
      expenseType: {
        id: "M",
      },
      material: {
        notes: "",
        targetOganisms: null,
        jet: null,
        material: null,
      },
    },
    stock: {
      amount: null,
      dateIn: new Date(),
      material: {
        id: "", //
        typeId: "", //SD FR CH
      },
      unitId: "t",
    },
    porApp: {
      "@class": "MaterialChemistry",
      protectionPeriod: null,
      applicationCount: null,
      slope: null,
      waterBuffer: null,
      waterBuffer50: null,
      waterBuffer75: null,
      waterBuffer90: null,
      parcelBuffer: null,
      parcelBuffer50: null,
      parcelBuffer75: null,
      parcelBuffer90: null,
      drift: null,
      nextCropRestriction: null,
      phoDistance: null,
      mandatoryDosage: null,
      undersowing: null,
      bioFunction: null,
      seedTreatment: null,
      minBbch: null,
      maxBbch: null,
      minBbchOrg: null,
      maxBbchOrg: null,
      protectionZone: null,
      materialDosageMin: null,
      materialDosageMax: null,
      materialDosageUnit: null,
      materialDosageDesc: null,
      waterDosageMin: null,
      waterDosageMax: null,
      waterDosageUnit: null,
      withdrawalPeriodDays: null,
      withdrawalPeriodText: null,
      repeatApplicationMinDays: null,
      repeatApplicationMaxDays: null,
      clCropList: [],
      materialAppliable: {},
      mixture: null,
      clMaterialRestrictionOrganisms: [],
      isCollapsed: true,
    },
  };

  var service = {
    getObjTpl: getObjTpl,
  };
  return service;

  /////////////////////////

  function getObjTpl(attr) {
    if (!attr || typeof attr !== "string" || !objects.hasOwnProperty(attr)) {
      Logger.error("Invalid attribute type or format: " + attr);
      return {};
    } else {
      return _.cloneDeep(objects[attr]);
    }
  }
}
