import { PointWithAttribute } from "../selectors/asAppliedDetail.selectors";

type State = {
  attribute?: string;
  highlight: PointWithAttribute;
  target: "map" | "chart";
};

type Action =
  | {
      payload: Pick<State, "highlight" | "target">;
      type: "SET_HIGHLIGHT_POINT";
    }
  | {
      type: "RESET_HIGHLIGHT_POINT";
    }
  | {
      payload: string;
      type: "SET_ACTIVE_ATTRIBUTE";
    };

const initialState: Partial<State> = {};

const precisionAsAppliedDetailReducer =
  () =>
  (state = initialState, action: Action): Partial<State> => {
    switch (action.type) {
      case "SET_HIGHLIGHT_POINT": {
        if (
          state.highlight === action.payload.highlight &&
          state.target === action.payload.target
        ) {
          return state;
        }

        return {
          ...state,
          ...action.payload,
        };
      }
      case "RESET_HIGHLIGHT_POINT": {
        if (!state.highlight) {
          return state;
        }
        return {
          attribute: state.attribute,
        };
      }
      case "SET_ACTIVE_ATTRIBUTE":
        return {
          ...state,
          attribute: action.payload,
        };
      default:
        return state;
    }
  };

const pointHighlighted = (
  point: Pick<State, "highlight" | "target">,
): Action => ({
  type: "SET_HIGHLIGHT_POINT",
  payload: point,
});

const pointReset = (): Action => ({
  type: "RESET_HIGHLIGHT_POINT",
});

const attributeSelected = (payload: string): Action => ({
  type: "SET_ACTIVE_ATTRIBUTE",
  payload,
});

export default precisionAsAppliedDetailReducer;
export { pointHighlighted, pointReset, attributeSelected };
export type { Action, State };
