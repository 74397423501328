import moment, { Moment } from "moment";

export const getShortDateString = (
  date: string | Moment = moment(),
  format = "YYYY-MM-DD",
) => {
  if (typeof date === "string") {
    return moment(date).format(format);
  }
  return date.format(format);
};

export const getLocalizedDateString = (
  date: string | moment.Moment = moment(),
  format: moment.LongDateFormatKey[] = ["L"],
) => {
  let momentDate: moment.Moment;

  if (typeof date === "string") {
    momentDate = moment(date);

    if (!momentDate.isValid()) {
      throw new Error("Invalid date format");
    }
  } else {
    momentDate = date;
  }

  const resFormat = format
    .map((item) => momentDate.localeData().longDateFormat(item))
    .join(" ");
  return momentDate.format(resFormat);
};

export const isToday = (date?: string): boolean => {
  const today = moment().startOf("day");
  const movementDate = moment(date).startOf("day");
  return today.isSame(movementDate);
};

export const stringDateToMoment = (
  date?: string,
): moment.Moment | undefined => {
  if (date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate;
    }
  }
  return undefined;
};
