import React from "react";

import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";

import PestPredictionDialog from "../PestPredictionDialog/PestPredictionDialog";

const styles = (theme) => ({
  pestHeader: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pestDate: {
    fontSize: 13,
  },
  pestDateLabel: {
    color: theme.palette.grey[500],
  },
  pestHeading: {
    fontWeight: 500,
  },
  iconImg: {
    color: "grey",
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 5,
  },
});

function PestPredictionGraphHeader(props) {
  const {
    classes,
    color,
    pestPredictionConfig,
    validAfterDay,
    validAfterMonth,
  } = props;
  const translationId = pestPredictionConfig.name;
  const Icon = pestPredictionConfig.icon;
  let dateMoment = null;
  if (validAfterDay && validAfterMonth) {
    dateMoment = moment()
      .month(validAfterMonth - 1)
      .date(validAfterDay);
  }

  return (
    <div className={classes.pestHeader}>
      <span className={classes.pestHeading}>
        <Icon className={classes.iconImg} style={{ color }} />
        <span data-test="header">
          <FormattedMessage id={translationId} />
        </span>
        <span>
          <PestPredictionDialog pestPredictionConfig={pestPredictionConfig} />
        </span>
      </span>
      {dateMoment && (
        <span className={classes.pestDate} data-test="date">
          <span className={classes.pestDateLabel}>
            <FormattedMessage id="NodePredictionsSettings.expectedDateOfFlowering" />
            :{" "}
          </span>
          <FormattedDate day="numeric" month="numeric" value={dateMoment} />
        </span>
      )}
    </div>
  );
}

PestPredictionGraphHeader.propTypes = {
  classes: PropTypes.object,
  pestPredictionConfig: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  validAfterDay: PropTypes.number,
  validAfterMonth: PropTypes.number,
};

PestPredictionGraphHeader.defaultProps = {
  classes: {},
  validAfterDay: null,
  validAfterMonth: null,
};

export default withStyles(styles)(PestPredictionGraphHeader);
