import isNil from "lodash/isNil";
import isString from "lodash/isString";
import toNumber from "lodash/toNumber";
import invariant from "tiny-invariant";

import { LANGUAGE_ID, LANG_KEYS } from "../lang/lang.constants";

const DECIMAL_NUM = 3;

export default class Localization {
  static getSeparator(
    locale: LANGUAGE_ID,
    separatorType: "decimal" | "group",
  ): string {
    const num = 10000.1;
    const intl = Intl.NumberFormat(locale);

    // for safari
    if (!intl.formatToParts) {
      const separators = num.toLocaleString(locale).replace(/\d/g, "");

      if (separatorType === "decimal") {
        return separators[1] ?? ","; // fallback to comma if not successful
      }

      if (separatorType === "group" && separators[0]) {
        return separators[0] ?? " "; // fallback to space if not successful
      }
    }

    const parts = intl.formatToParts(num);
    const match = parts.find((part) => part.type === separatorType);

    invariant(
      match,
      `match was not found for separatorType: ${separatorType} in ${JSON.stringify(parts)}`,
    );

    return match.value;
  }

  static getDecimalSeparator(locale: LANGUAGE_ID): string {
    return Localization.getSeparator(locale, "decimal");
  }

  static getGroupSeparator(locale: LANGUAGE_ID): string {
    return Localization.getSeparator(locale, "group");
  }

  static num2str(
    val: number | string | undefined | null,
    locale: LANGUAGE_ID,
    formatDecimal = DECIMAL_NUM,
  ): string {
    return typeof val !== "string" && !isNil(val) && !isNaN(Number(val))
      ? Intl.NumberFormat(locale, {
          minimumFractionDigits: formatDecimal,
          maximumFractionDigits: formatDecimal,
        }).format(val)
      : "";
  }

  static num2strNonFixed(
    val: number | string,
    locale: LANGUAGE_ID,
    minDecimal = 0,
    maxDecimal = 10,
  ): string {
    if (typeof val === "string" && val.length === 0) {
      return "";
    }

    if (isNil(val) || isNaN(Number(val))) {
      return "";
    }

    return Intl.NumberFormat(locale, {
      minimumFractionDigits: minDecimal,
      maximumFractionDigits: maxDecimal,
    }).format(Number(val));
  }

  static transformStr(str: string, locale: LANGUAGE_ID): string {
    let groupRegExPattern = Localization.getGroupSeparator(locale);
    groupRegExPattern =
      groupRegExPattern.replace(/\s/g, "").length === 0
        ? "\\s"
        : groupRegExPattern;
    groupRegExPattern = groupRegExPattern === "." ? "\\." : groupRegExPattern;

    return str
      .replace(new RegExp(groupRegExPattern, "g"), "")
      .replace(Localization.getDecimalSeparator(locale), ".");
  }

  static transformStr2Num(str: string, locale: LANGUAGE_ID): number {
    const newStr = this.transformStr(str, locale);
    return toNumber(newStr);
  }

  static checkValidStr(str: string | undefined, locale: LANGUAGE_ID): boolean {
    if (str === undefined) return false;
    const newStr = this.transformStr(str, locale);
    const isLastCharNonNumeric = /\D$/.test(newStr);
    const doesStringContainUnparsableZerosAfterDecimal =
      /^[+-]?\d*\.\d*0$/.test(newStr);

    if (isLastCharNonNumeric || doesStringContainUnparsableZerosAfterDecimal) {
      return false;
    } else {
      return true;
    }
  }

  static str2num(
    val: number | string | null | undefined,
    locale: LANGUAGE_ID,
    formatDecimal = DECIMAL_NUM,
  ): number | string {
    if (val === null) {
      return "";
    }

    if (val === undefined) {
      return "";
    }

    if (typeof val === "number") {
      return toNumber(val.toFixed(formatDecimal));
    }

    const str = val ? val.replace(/ /g, "") : val;

    if (!str || !isString(str)) {
      return "";
    }

    const result = this.transformStr2Num(str, locale);

    return isNaN(Number(result))
      ? val
      : toNumber(result.toFixed(formatDecimal));
  }

  static str2numNonFixed(
    val: number | string,
    locale: LANGUAGE_ID,
  ): number | string {
    if (typeof val === "number") {
      return val;
    }

    const str = val ? val.replace(/ /g, "") : val;

    if (!str || !isString(str)) {
      return "";
    }

    const result = this.transformStr2Num(str, locale);

    return isNaN(Number(result)) ? val : result;
  }

  static formatDate(
    date: Date,
    locale: LANGUAGE_ID,
    opts: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "numeric",
    },
  ): string {
    return Intl.DateTimeFormat(locale, opts).format(date);
  }

  static formatTime(date: Date, locale: LANGUAGE_ID): string {
    return Intl.DateTimeFormat(locale, {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  }

  static mergeTranslations = (
    ...sources: Record<LANGUAGE_ID, Record<string, string>>[]
  ) => {
    const res = {} as Record<LANGUAGE_ID, Record<string, string>>;
    (Object.values(LANG_KEYS) as LANGUAGE_ID[]).forEach((langId) => {
      res[langId] = {};
      sources.forEach((src) => {
        res[langId] = {
          ...res[langId],
          ...src[langId],
        };
      });
    });

    return res;
  };
}
