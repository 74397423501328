import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { COLOR_GREY } from "../../../theme";

export const useFileUploaderStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
  },
  body: {
    background: COLOR_GREY[100],
    padding: 0,
    position: "relative",
  },
  bodyIsLoading: {
    opacity: 0.5,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
  },
  loaderBody: {
    position: "sticky",
    top: "20%",
    display: "flex",
    justifyContent: "center",
  },
  bottomContainer: {
    padding: "0 16px 16px 16px",

    display: "flex",
    flexDirection: "column",
  },
  loadedFiles: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    padding: 0,
  },
  dropzone: {
    display: "grid",
    placeItems: "center",
    padding: "32px 27px 32px 27px",
    cursor: "pointer",
    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  "dropzone-title": {
    fontSize: 16,
    fontWeight: 400,
  },
  "dropzone-formats": {
    fontSize: 13,
    color: COLOR_GREY[500],
    textTransform: "uppercase",
  },
  importButton: {
    alignSelf: "flex-end",
    "&.Mui-disabled": {
      color: theme.palette.primary.main,
      backgroundColor: "inherit",
    },
  },
}));
