import React, { FC, ReactNode } from "react";

import { CropQualityData } from "../containers/ManagementZones";

type Payload = {
  coordinate: number;
  index: number;
  isShow?: boolean;
  offset: number;
  tickCoord?: number;
  value: string;
};

interface XAxisLabelProps {
  data?: CropQualityData[];
  formatter: (val?: string) => ReactNode;
  payload?: Payload;
  rotation?: number;
  textAnchor: string;
  x?: number;
  y?: number;
}

const XAxisLabel: FC<XAxisLabelProps> = ({
  data,
  formatter,
  payload,
  rotation = 0,
  textAnchor,
  x,
  y,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text
      dy={16}
      fill={data ? `#${data[payload?.index as number].zoneColor}` : "#666"}
      fontSize={12}
      textAnchor={textAnchor}
      transform={`rotate(${rotation})`}
      x={0}
      y={0}
    >
      {formatter(payload?.value)}
    </text>
  </g>
);

export default XAxisLabel;
