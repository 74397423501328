import React, { FC } from "react";

import { Field, FormikProps } from "formik";
import { round, toNumber } from "lodash";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikNumericTextField from "../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";

interface Props {
  customClasses?: Record<string, string>;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  label?: JSX.Element;
}

const BonusField: FC<Props & FormikProps<{ bonus: unknown }>> = ({
  customClasses,
  disabled,
  endAdornment,
  label,
  setFieldValue,
  values,
}) => {
  const handleBonusBlur = () => {
    if (values.bonus !== undefined) {
      if (Number(values.bonus) >= 0) {
        setFieldValue("bonus", round(toNumber(values.bonus), 2));
      } else {
        setFieldValue("bonus", undefined);
      }
    }
  };
  return (
    <CfFormControl>
      <Field
        component={CfFormikNumericTextField}
        customClasses={customClasses}
        disabled={disabled}
        label={label}
        name="bonus"
        onBlur={handleBonusBlur}
        InputProps={{
          endAdornment,
        }}
      />
    </CfFormControl>
  );
};

export default BonusField;
