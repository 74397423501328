import React, { Component } from "react";

import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  setTableSelected,
  setTableSubrowSelected,
} from "../../actions/table.actions";

import { tooltipForDisabled } from "../../misc/helpers";

export class CfTableCheckbox extends Component {
  handleCheckboxClick = (evt) => {
    // to stop it from going to detail on row click
    evt.stopPropagation();

    const { id, isSubrow, namespace, onCheck, onUncheck, selected } =
      this.props;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    const c1 = selectedIndex === -1;
    const c2 = selectedIndex === 0;
    const c3 = selectedIndex === selected.length - 1;
    const c4 = selectedIndex > 0;

    if (c1) {
      newSelected = newSelected.concat(selected, id);
    } else if (c2) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (c3) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (c4) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (isSubrow) {
      this.props.setTableSubrowSelected(newSelected, namespace);
    } else {
      this.props.setTableSelected(newSelected, namespace);
    }

    if (c1 && onCheck) {
      onCheck(id, newSelected);
    } else if ((c2 || c3 || c4) && onUncheck) {
      onUncheck(selected[selectedIndex], newSelected);
    }
  };

  isSelected = (id) => this.props.selected.indexOf(id) !== -1;

  render() {
    const {
      customClickHandler,
      disabled,
      id,
      name,
      tooltipText = "",
    } = this.props;
    return (
      <TableCell
        key={name ?? "checkbox"}
        padding="checkbox"
        rowSpan={this.props.rowSpan}
        onClick={
          disabled ? undefined : customClickHandler || this.handleCheckboxClick
        }
      >
        {tooltipForDisabled(
          disabled,
          tooltipText,
          <Checkbox
            checked={this.isSelected(id)}
            color="primary"
            data-test="table-checkbox"
            disabled={disabled}
          />,
        )}
      </TableCell>
    );
  }
}

CfTableCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selected: PropTypes.array.isRequired,
  namespace: PropTypes.string,
  name: PropTypes.string,
  tooltipText: PropTypes.string,
  setTableSelected: PropTypes.func,
  setTableSubrowSelected: PropTypes.func,
  rowSpan: PropTypes.number,
  customClickHandler: PropTypes.func,
  onCheck: PropTypes.func,
  onUncheck: PropTypes.func,
  disabled: PropTypes.bool,
  isSubrow: PropTypes.bool.isRequired,
};

CfTableCheckbox.defaultProps = {
  rowSpan: 1,
  isSubrow: false,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTableSelected,
      setTableSubrowSelected,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CfTableCheckbox);
