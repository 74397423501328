import * as types from "./nodes.constants";

import DeviceConfig from "../../../../sensors/services/DeviceConfig.service";

export default () =>
  (
    state = {
      isFetchingNodes: false,
      isFetchingStatistics: false,
      isFetchingNodeTypes: false,
      isFetchingNetworkTypes: false,
      errorNodes: {},
      errorStatistics: {},
      errorNodeTypes: {},
      errorNetworkTypes: {},
      items: [],
      totalCount: 0,
      statistics: {},
      types: [],
      networkTypes: [],
      defaultFarm: {},
      isFetchingBaraniAvailableBases: false,
      baraniAvailableBases: [],
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_NODES:
        return {
          ...state,
          isFetchingNodes: true,
          errorNodes: {},
        };
      case types.GET_NODES_SUCCESS:
        return {
          ...state,
          isFetchingNodes: false,
          errorNodes: {},
          items: action.payload.map((node) => addDeviceConfigToNode(node)),
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_NODES_ERROR:
      case types.PATCH_NODE_ERROR:
        return {
          ...state,
          isFetchingNodes: false,
          errorNodes: action.payload,
          totalCount: 0,
        };
      case types.RESET_NODES:
        return {
          ...state,
          errorNodes: {},
          items: [],
          totalCount: 0,
        };
      case types.GET_NODE_TYPES:
        return {
          ...state,
          isFetchingNodeTypes: true,
          errorNodeTypes: {},
        };
      case types.GET_NODE_TYPES_SUCCESS:
        return {
          ...state,
          isFetchingNodeTypes: false,
          errorNodeTypes: {},
          types: action.payload,
        };
      case types.GET_NODE_TYPES_ERROR:
        return {
          ...state,
          isFetchingNodeTypes: false,
          errorNodeTypes: action.payload,
        };
      case types.RESET_NODE_TYPES:
        return {
          ...state,
          errorNodeTypes: {},
          types: [],
        };
      case types.GET_NETWORK_TYPES_SUCCESS:
        return {
          ...state,
          isFetchingNetworkTypes: false,
          errorNetworkTypes: {},
          networkTypes: action.payload,
        };
      case types.GET_NETWORK_TYPES_ERROR:
        return {
          ...state,
          isFetchingNetworkTypes: false,
          errorNetworkTypes: action.payload,
        };
      case types.RESET_NETWORK_TYPES:
        return {
          ...state,
          errorNetworkTypes: {},
          networkTypes: [],
        };
      case types.GET_NODES_DEFAULT_FARM_SUCCESS:
        return {
          ...state,
          defaultFarm: { id: action.payload.toString(), name: "Default" },
        };
      case types.RESET_NODES_DEFAULT_FARM:
        return {
          ...state,
          defaultFarm: {},
        };
      case types.GET_NODES_STATISTICS:
        return {
          ...state,
          isFetchingStatistics: true,
          errorStatistics: {},
        };
      case types.GET_NODES_STATISTICS_SUCCESS:
        return {
          ...state,
          isFetchingStatistics: false,
          errorStatistics: {},
          statistics: action.payload,
        };
      case types.GET_NODES_STATISTICS_ERROR:
        return {
          ...state,
          isFetchingStatistics: false,
          errorStatistics: action.payload,
        };
      case types.RESET_NODES_STATISTICS:
        return {
          ...state,
          statistics: {},
        };
      case types.GET_BARANI_BASES_REQUEST:
        return {
          ...state,
          isFetchingBaraniAvailableBases: true,
        };
      case types.GET_BARANI_BASES_SUCCESS:
        return {
          ...state,
          isFetchingBaraniAvailableBases: false,
          baraniAvailableBases: action.payload,
        };
      case types.GET_BARANI_BASES_ERROR:
        return {
          ...state,
          isFetchingBaraniAvailableBases: false,
        };
      case types.RESET_BARANI_BASES:
        return {
          ...state,
          baraniAvailableBases: [],
          isFetchingBaraniAvailableBases: false,
        };
      default:
        return state;
    }
  };

const addDeviceConfigToNode = (node) => ({
  ...node,
  config: DeviceConfig.getDeviceConfig(node.deviceType, node.sensors),
});
