import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: "white",
    width: "fit-content",
    maxWidth: "150px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "3px",
    padding: "0.1rem 0.2rem",
    margin: 0,
    marginTop: "0.1rem",
  },
  warningText: {
    margin: 0,
  },
}));

export enum TelematicsForeignWarningType {
  AdditionalEquipment = "AdditionalEquipment",
  Driver = "Driver",
  Parcel = "Parcel",
}

interface Props {
  text?: string;
  type: TelematicsForeignWarningType;
}

const TelematicsForeignWarning: FC<Props> = ({ text, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.warning}>
      {text && <p className={classes.warningText}>{text}</p>}
      {!text && <FormattedMessage id={`TelematicsList.foreign${type}`} />}
    </div>
  );
};

export default TelematicsForeignWarning;
