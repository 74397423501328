import { RSAAAction } from "redux-api-middleware";

import {
  getParcelApi,
  updateParcelApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";

export const updateParcelNote =
  (parcelId: string, parcelNotes: string) =>
  (dispatch: (action: RSAAAction) => Promise<void>) => {
    dispatch(updateParcelApi(parcelId, { notes: parcelNotes })).then(() => {
      dispatch(getParcelApi(parcelId));
    });
  };

export const updateLpisYieldGrade =
  (parcelId: string, newYieldGrade: "I" | "II" | "III") =>
  (dispatch: (action: RSAAAction) => Promise<void>) => {
    dispatch(updateParcelApi(parcelId, { userYieldGrade: newYieldGrade })).then(
      () => {
        dispatch(getParcelApi(parcelId));
      },
    );
  };
