import React from "react";

import { useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

import { getAppVariant } from "../../shared/variants/getAppVariant";
import { Variant } from "../../shared/variants/variants";

const METADATA_TRANSLATION_VERSIONS: Record<
  Variant,
  {
    theme: string;
    author: string;
    description: string;
    title: string;
  }
> = {
  cleverfarm: {
    theme: "default",
    author: "common.metadata.title",
    description: "common.metadata.description",
    title: "common.metadata.title",
  },
  farmasense: {
    theme: "farmasense",
    author: "common.metadata.farmasense.title",
    description: "common.metadata.farmasense.description",
    title: "common.metadata.farmasense.title",
  },
  mymex: {
    theme: "farmasense",
    author: "common.metadata.mymex.title",
    description: "common.metadata.mymex.description",
    title: "common.metadata.mymex.title",
  },
};

const Metadata = () => {
  const intl = useIntl();
  const variant = getAppVariant();
  const theme = useTheme();
  return (
    <Helmet>
      <meta
        name="author"
        content={intl.formatMessage({
          id: METADATA_TRANSLATION_VERSIONS[variant].author,
        })}
      />
      <meta
        name="description"
        content={intl.formatMessage({
          id: METADATA_TRANSLATION_VERSIONS[variant].description,
        })}
      />
      <title>
        {intl.formatMessage({
          id: METADATA_TRANSLATION_VERSIONS[variant].title,
        })}
      </title>
      /
      <link href={theme.assets.favicon} rel="icon" type="image/x-icon" />
    </Helmet>
  );
};

export default Metadata;
