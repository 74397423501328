import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const IntegrationsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" x="2px" xmlns="http://www.w3.org/2000/svg" y="2px">
      <path
        d="M17.0832 9.16634H15.8332V5.83301C15.8332 4.91634 15.0832 4.16634 14.1665 4.16634H10.8332V2.91634C10.8332 1.76634 9.89984 0.833008 8.74984 0.833008C7.59984 0.833008 6.6665 1.76634 6.6665 2.91634V4.16634H3.33317C2.4165 4.16634 1.67484 4.91634 1.67484 5.83301V8.99967H2.9165C4.15817 8.99967 5.1665 10.008 5.1665 11.2497C5.1665 12.4913 4.15817 13.4997 2.9165 13.4997H1.6665V16.6663C1.6665 17.583 2.4165 18.333 3.33317 18.333H6.49984V17.083C6.49984 15.8413 7.50817 14.833 8.74984 14.833C9.9915 14.833 10.9998 15.8413 10.9998 17.083V18.333H14.1665C15.0832 18.333 15.8332 17.583 15.8332 16.6663V13.333H17.0832C18.2332 13.333 19.1665 12.3997 19.1665 11.2497C19.1665 10.0997 18.2332 9.16634 17.0832 9.16634Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { IntegrationsIcon };
