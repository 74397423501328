import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../shared/services/Localization.service";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  ha: {
    color: theme.palette.text.secondary,
  },
}));

interface SubtractableAreaProps {
  sa: SubtractionResponse | { area: number; type: string; value: number };
}

const SubtractableArea: FC<SubtractableAreaProps> = ({ sa }) => {
  const classes = useStyles();
  const intl = useTypedIntl();

  const localizedRestrictionValue = Localization.num2strNonFixed(
    sa.value,
    intl.locale,
  );
  const localizedRestrictionArea = Localization.num2str(
    sa.area,
    intl.locale,
    2,
  );
  const localizedRestrictionAreaAbsolute = Localization.num2str(
    sa.value,
    intl.locale,
    2,
  );
  return (
    <span>
      {sa.type === "Absolute"
        ? `${localizedRestrictionAreaAbsolute} ha`
        : `${localizedRestrictionValue} m`}
      {sa.type !== "Absolute" && (
        <div className={classes.ha}>{` (${localizedRestrictionArea} ha)`}</div>
      )}
    </span>
  );
};

export default SubtractableArea;
