import LayersConfigService, {
  PUBLIC_LPIS_CONFIG,
} from "../../../shared/services/LayersConfig.service";

import DefaultStyles from "./styles/DefaultStyles.service";

export default class Layers {
  constructor(map, api, farmId, farmExtent) {
    this.map = map;
    const { bing_key, geoserverUrl, geoserverWorkspaceCore, lpisLayerName } =
      api;
    this.layersConfigService = new LayersConfigService(
      geoserverWorkspaceCore,
      geoserverUrl,
      farmId,
      farmExtent,
      bing_key,
      lpisLayerName,
    );
  }

  setInitialLayers(layersConfig, storeInitialLayers) {
    const layers = [];
    const mapLayers = [];
    layersConfig.forEach((layerConfig) => {
      const mapLayer = this.setupLayer(layerConfig);

      if (layerConfig.layerId === "parcel") {
        this.parcelsLayer = mapLayer;
      } else if (layerConfig.layerId === "parcel_label") {
        this.parcelsLabelsLayer = mapLayer;
      }

      mapLayer.setVisible(layerConfig.visible);
      mapLayers.push(mapLayer);
      this.addLayer(mapLayer);

      const { layerId, title } = layerConfig;
      const newLayer = { layerId, title, mapLayer };

      if (!layerConfig.linkedTo) {
        newLayer.linkedLayers = [];
        newLayer.visible = layerConfig.visible;
        newLayer.mapLayer = mapLayer;
        layers.push(newLayer);
      } else {
        const parentLayer = layers.find(
          (layer) => layer.layerId === layerConfig.linkedTo,
        );
        parentLayer.linkedLayers.push(newLayer);
      }
    });
    if (storeInitialLayers) {
      storeInitialLayers(layers);
    }

    return mapLayers;
  }

  setupLayer(config) {
    const layerStyle =
      config.type === "vector_tile" || config.type === PUBLIC_LPIS_CONFIG.TYPE
        ? DefaultStyles.getStyle(config.layerId)
        : null;
    const layer = this.layersConfigService.getMapLayerFromConfig(
      config,
      layerStyle,
    );
    layer.setVisible(config.visible);
    return layer;
  }

  addLayer(layer, layerId) {
    this.map.addLayer(layer);
    if (layerId) {
      this[`layer_${layerId}`] = layer;
    }
  }

  removeLayer(layer) {
    this.map.removeLayer(layer);
  }

  setLayerVisibilityInMap(layer, visible) {
    layer.mapLayer.setVisible(visible);
    layer.linkedLayers.forEach((linkedLayer) => {
      linkedLayer.mapLayer.setVisible(visible);
    });
  }

  getParcelLayer() {
    return this.parcelsLayer;
  }

  getParcelLabelLayer() {
    return this.parcelsLabelsLayer;
  }

  refreshParcelLayer() {
    this.parcelsLayer.getSource().clear();
    this.parcelsLayer.getSource().refresh();
  }

  refreshParcelLabels() {
    this.parcelsLabelsLayer.getSource().clear();
    this.parcelsLabelsLayer.getSource().refresh();
  }

  updateParcelLayerExtent(extent) {
    this.parcelsLayer.setExtent(extent);
    this.parcelsLayer.getSource().refresh();
  }

  updateParcelLabelsExtent(extent) {
    this.parcelsLabelsLayer.setExtent(extent);
    this.parcelsLabelsLayer.getSource().refresh();
  }
}
