/* eslint-disable react/no-array-index-key */

import React from "react";

import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";

const styles = () => ({
  root: {},
});

export function AutosuggestItem(props) {
  const {
    classes,
    disabled,
    getSuggestionValue,
    isHighlighted,
    onItemMouseEnter,
    onItemMouseLeave,
    query,
    suggestion,
  } = props;

  const suggValue = getSuggestionValue(suggestion);
  const matches = match(suggValue, query);
  const parts = parse(suggValue, matches);

  return (
    <MenuItem
      component="div"
      data-test="suggested-item"
      disabled={disabled}
      onMouseEnter={() => onItemMouseEnter(suggestion)}
      onMouseLeave={() => onItemMouseLeave(suggestion)}
      selected={isHighlighted}
      classes={{
        root: classes.root,
      }}
    >
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={index}>
              <strong>{part.text}</strong>
            </span>
          ) : (
            <span key={index}>{part.text}</span>
          ),
        )}
      </div>
    </MenuItem>
  );
}

AutosuggestItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onItemMouseEnter: PropTypes.func.isRequired,
  onItemMouseLeave: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  suggestion: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
};

AutosuggestItem.defaultProps = {
  isHighlighted: false,
  disabled: false,
};

export default withStyles(styles)(AutosuggestItem);
