import React, { FC } from "react";

import { Grid, Stack, Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { COLOR_FONT } from "../../../../theme";

import { CropTo } from "../../../../shared/api/agroevidence/agroevidence.types";

interface SowingPlanStatisticsItemProps {
  area: number;
  crop?: CropTo;
}

const SowingPlanStatisticsItem: FC<SowingPlanStatisticsItemProps> = ({
  area,
  crop,
}) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      container
      data-test="sowing-plan-item"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid alignItems="center" container>
          <div
            className={classes.cropColor}
            style={{
              backgroundColor: `rgba(${crop?.color.red}, ${crop?.color.green}, ${crop?.color.blue}, 1)`,
            }}
          />
          <div className={classes.cropName}>{crop?.name ?? "-"}</div>
        </Grid>
      </Grid>
      <Grid className={classnames(classes.cropName, classes.cropSize)} item>
        <Stack direction="row" spacing="2px">
          <span>
            <CfFormattedNumber decimalDigits={2} value={area} />
          </span>
          <span>ha</span>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SowingPlanStatisticsItem;

const useStyles = makeStyles((theme: Theme) => ({
  cropColor: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    border: alpha(theme.palette.common.black, 0.2),
    marginRight: 5,
  },
  cropName: {
    color: COLOR_FONT.main,
    fontWeight: 400,
    fontSize: 12,
  },
  cropSize: {
    color: theme.palette.grey[400],
    marginLeft: 10,
  },
}));
