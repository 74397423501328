/**
 * Created by ondrejzvara on 4.3.16.
 */

/* @ngInject */
export default function UsersResource(Api) {
  const service = {
    getUser,
  };
  return service;

  // ///////////////////////

  function getUser(login) {
    const baseOne = Api.setGatewayBaseOne("users", login);
    return Api.getItem(baseOne);
  }
}
