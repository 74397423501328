import * as types from "./rules.constants";

export default () =>
  (
    state = {
      isFetchingRules: false,
      errorRules: null,
      items: [],
      totalCount: 0,
      isFetchingRule: false,
      item: null,
      errorRule: null,
      isDeletingRules: false,
      errorDeletingRules: null,
      isCreatingRule: false,
      errorCreatingRule: null,
      isUpdatingRule: false,
      errorUpdatingRule: null,
      isPatchingRule: false,
      errorPatchingRule: null,
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_RULES:
        return {
          ...state,
          isFetchingRules: true,
          errorRules: null,
        };
      case types.GET_RULES_SUCCESS:
        return {
          ...state,
          isFetchingRules: false,
          errorRules: null,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_RULES_ERROR:
        return {
          ...state,
          isFetchingRules: false,
          errorRules: action.payload,
          totalCount: 0,
        };
      case types.RESET_RULES:
        return {
          ...state,
          errorRules: {},
          items: [],
          totalCount: 0,
        };
      case types.GET_RULE:
        return {
          ...state,
          isFetchingRule: true,
          errorRule: null,
        };
      case types.GET_RULE_SUCCESS:
        return {
          ...state,
          isFetchingRule: false,
          errorRule: null,
          item: action.payload,
        };
      case types.GET_RULE_ERROR:
        return {
          ...state,
          isFetchingRule: false,
          item: null,
          errorRule: action.payload,
        };
      case types.RESET_RULE:
        return {
          ...state,
          errorRule: null,
          item: null,
        };
      case types.DELETE_RULES:
        return {
          ...state,
          isDeletingRules: true,
          errorDeletingRules: null,
        };
      case types.DELETE_RULES_SUCCESS:
        return {
          ...state,
          isDeletingRules: false,
          errorDeletingRules: null,
        };
      case types.DELETE_RULES_ERROR:
        return {
          ...state,
          isDeletingRules: false,
          errorDeletingRules: action.payload,
        };
      case types.CREATE_RULE:
        return {
          ...state,
          isCreatingRule: true,
          errorCreatingRule: null,
        };
      case types.CREATE_RULE_SUCCESS:
        return {
          ...state,
          isCreatingRule: false,
          errorCreatingRule: null,
        };
      case types.CREATE_RULE_ERROR:
        return {
          ...state,
          isCreatingRule: false,
          errorCreatingRule: action.payload,
        };
      case types.UPDATE_RULE:
        return {
          ...state,
          isUpdatingRule: true,
          errorUpdatingRule: null,
        };
      case types.UPDATE_RULE_SUCCESS:
        return {
          ...state,
          isUpdatingRule: false,
          errorUpdatingRule: null,
        };
      case types.UPDATE_RULE_ERROR:
        return {
          ...state,
          isUpdatingRule: false,
          errorUpdatingRule: action.payload,
        };
      case types.PATCH_RULE:
        return {
          ...state,
          isPatchingRule: true,
          errorPatchingRule: null,
        };
      case types.PATCH_RULE_SUCCESS:
        return {
          ...state,
          isPatchingRule: false,
          items: updatePatchedRuleInList(state.items, action.payload),
          errorPatchingRule: null,
        };
      case types.PATCH_RULE_ERROR:
        return {
          ...state,
          isPatchingRule: false,
          errorPatchingRule: action.payload,
        };
      default:
        return state;
    }
  };

const updatePatchedRuleInList = (rules, patchedRule) =>
  rules.map((rule) => {
    if (rule.id === patchedRule.id) {
      return patchedRule;
    }
    return rule;
  });
