import React from "react";

import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { Form, Field, FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import FeatureConfig, {
  FEATURE_TYPE_PREFIX,
} from "../../../sensors/services/FeatureConfig.service";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../shared/misc/validators";
import { conditionOperators } from "../../services/conditionOperators";
import {
  notificationFormFields as formFields,
  notificationFormInitialValues as initialValues,
} from "../../services/Notifications.service";
import NotificationsSensors from "../../services/NotificationsSensors.service";
import NotificationSensorsControl from "../NotificationSensorsControl/NotificationSensorsControl";
import NotificationUsersControl from "../NotificationUsersControl/NotificationUsersControl";

const validateSensors = (value) =>
  !value ? (
    <FormattedMessage id="NotificationSensorsControl.validation" />
  ) : null;

export const NotificationDetailForm = (props) => {
  const { classes, farmId, isEditing, isExisting } = props;
  const { setFieldValue, values } = useFormikContext();

  const nodeLocation = values[formFields.sensor];
  const defaultFeatureOptions = FeatureConfig.getNotificationsFeatureNames();
  const nodeFeatureOptions = NotificationsSensors.getNodeFeatures(
    nodeLocation?.node,
  );
  const featureOptions = nodeLocation
    ? nodeFeatureOptions
    : defaultFeatureOptions;

  const feature = values[formFields.feature];
  let featureConfig = null;
  if (feature) {
    featureConfig = FeatureConfig.getFeatureConfig(
      `${FEATURE_TYPE_PREFIX}${feature}`,
    );
  }

  return (
    <Form>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Field name={formFields.sensor} validate={validateSensors}>
            {({ field, meta }) => (
              <NotificationSensorsControl
                error={meta.error}
                isEditing={isEditing}
                isExisting={isExisting}
                nodeLocation={field.value}
                handleRemove={() => {
                  setFieldValue(
                    formFields.sensor,
                    initialValues[formFields.sensors],
                  );
                }}
                onChange={(value) => {
                  setFieldValue(formFields.sensor, value);
                  const supportedFeatures =
                    NotificationsSensors.getNodeFeatures(value?.node);
                  if (!supportedFeatures.includes(feature)) {
                    setFieldValue(
                      formFields.feature,
                      initialValues[formFields.feature],
                    );
                  }
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={5} sm={6} xl={4} xs={10}>
          <CfFormControl>
            <Field
              component={CfFormikTextField}
              disabled={!isEditing}
              disableValue={featureOptions.length === 0}
              label={<FormattedMessage id="common.feature" />}
              name={formFields.feature}
              select
              validate={validators.required}
              validateOnBlur={true}
            >
              {featureOptions.map((featureOption) => (
                <MenuItem key={featureOption} value={featureOption}>
                  <FormattedMessage id={`NodeFeature.${featureOption}_AVG`} />
                </MenuItem>
              ))}
            </Field>
          </CfFormControl>

          <Grid className={classes.condition} container spacing={2}>
            <Grid item xs={8}>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.condition" />}
                  name={formFields.conditionType}
                  select
                  validate={validators.required}
                  validateOnBlur={true}
                >
                  {conditionOperators.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                      <FormattedMessage id={`conditionOperators.${operator}`} />
                    </MenuItem>
                  ))}
                </Field>
              </CfFormControl>
            </Grid>
            <Grid item xs={4}>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.value" />}
                  name={formFields.conditionValue}
                  type="number"
                  validate={validators.requiredNumber}
                  validateOnBlur={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {featureConfig?.unit || ""}
                      </InputAdornment>
                    ),
                  }}
                />
              </CfFormControl>
            </Grid>
          </Grid>

          <CfFormControl>
            <Field
              component={CfFormikTextField}
              disabled={!isEditing}
              label={<FormattedMessage id="common.description" />}
              name={formFields.description}
            />
          </CfFormControl>
        </Grid>

        <Grid item xs={12}>
          <FieldArray name={formFields.users}>
            {({ form, push, remove }) => (
              <NotificationUsersControl
                error={form.errors.users}
                farmId={farmId}
                handlePush={push}
                handleRemove={remove}
                isEditing={isEditing}
                touched={form.touched}
                users={form.values.users}
                setFieldError={(error) =>
                  form.setFieldError(formFields.users, error)
                }
              />
            )}
          </FieldArray>
        </Grid>

        <Grid className={classes.infoCardWrapper} item xs={12}>
          <Card className={classes.infoCard}>
            <div className={classes.infoIconWrapper}>
              <InfoIcon className={classes.infoIcon} />
            </div>
            <FormattedMessage id="NotificationDetailForm.sendingExplanation" />
          </Card>
        </Grid>

        {isEditing && (
          <Grid item xs={12}>
            <Grid
              alignItems="center"
              className={classes.formButtons}
              container
              justifyContent="center"
              spacing={0}
            >
              <Button
                className={classes.button}
                disabled={!isEditing}
                id="reset"
                type="reset"
                variant="contained"
              >
                <FormattedMessage
                  id={isExisting ? "common.cancel" : "common.reset"}
                />
              </Button>
              <Button
                className={classes.button}
                color="primary"
                disabled={!isEditing}
                id="create"
                type="submit"
                variant="contained"
              >
                <FormattedMessage
                  id={isExisting ? "common.save" : "common.create"}
                />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

NotificationDetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  isExisting: PropTypes.bool,
  farmId: PropTypes.string.isRequired,
};

NotificationDetailForm.defaultProps = {
  isEditing: false,
  isExisting: true,
};
