import React, { FC, ReactNode, useMemo } from "react";

import { connect } from "react-redux";

import {
  getIsFetchingOperations,
  getOperations,
} from "../../../../shared/api/telematics/drives/drives.selectors";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import {
  OperationTo,
  TelematicsOperation,
} from "../../../../shared/api/telematics/telematics.types";

interface OperationSelectorProps {
  defaultValues?: TelematicsOperation[];
  isFetching: boolean;
  label: ReactNode;
  onChange(items: OperationTo[]): void;
  options: OperationTo[];
}

const defaultDefaultValues: TelematicsOperation[] = [];

const OperationSelector: FC<OperationSelectorProps> = ({
  defaultValues = defaultDefaultValues,
  isFetching,
  label,
  onChange,
  options,
}) => {
  const defaultValuesTranslated = useMemo(
    () => options.filter((x) => defaultValues.includes(x.code)),
    [defaultValues, options],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValuesTranslated}
      id="operation-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={options}
      testId="operation-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getOperations(state),
  isFetching: getIsFetchingOperations(state),
});

export default connect(mapStateToProps)(OperationSelector);
