import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./plantProtection.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { getPlantProtectionParams } from "./plantProtection.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";
import { PlantProtectionPatchTo } from "../../agroevidence.types";

export const getPlantProtectionApi = (params: getPlantProtectionParams) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_REQUEST,
      types.GET_PLANT_PROTECTION_SUCCESS,
      types.GET_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionV2Api = (params: getPlantProtectionParams) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protection?${queryString.stringify(
      params,
    )}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_REQUEST,
      types.GET_PLANT_PROTECTION_SUCCESS,
      types.GET_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionApi = () => ({
  type: types.RESET_PLANT_PROTECTION,
});

export const patchPlanProtectionApi = (
  plantProtectionId: string,
  params: PlantProtectionPatchTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protection/${plantProtectionId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const getActiveSubstancesApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protection/active-substances?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ACTIVE_SUBSTANCES_REQUEST,
      types.GET_ACTIVE_SUBSTANCES_SUCCESS,
      types.GET_ACTIVE_SUBSTANCES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetActiveSubstancesApi = () => ({
  type: types.RESET_ACTIVE_SUBSTANCES,
});

export const getOrganismsApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protection/organisms?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ORGANISMS_REQUEST,
      types.GET_ORGANISMS_SUCCESS,
      types.GET_ORGANISMS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetOrganismsApi = () => ({
  type: types.RESET_ORGANISMS,
});

export const getBioFunctionApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protection/bio-function?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_BIO_FUNCTION_REQUEST,
      types.GET_BIO_FUNCTION_SUCCESS,
      types.GET_BIO_FUNCTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetBioFunctionApi = () => ({
  type: types.RESET_BIO_FUNCTION,
});
