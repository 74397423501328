import React from "react";

import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";

const CfTableCell = (props) => {
  const { children, className, classes, colSpan, name, rowSpan, size } = props;
  return (
    <TableCell
      classes={classes}
      className={className}
      colSpan={colSpan}
      key={name}
      rowSpan={rowSpan}
      size={size}
    >
      {children}
    </TableCell>
  );
};

CfTableCell.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium"]),
};

CfTableCell.defaultProps = {
  classes: {},
  colSpan: 1,
  rowSpan: 1,
  size: "small",
};

export default CfTableCell;
