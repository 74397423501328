import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { IRRIGATION_PLAN_DAYS } from "../../../containers/IrrigationList/areas/components/IrrigationPlanIndicators";

const isActiveDay = (code, plan) => plan.includes(code);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "16.875rem",
    marginBottom: "0.3125rem",
  },
  day: {
    width: "1.875rem",
    height: "1.875rem",
    fontSize: "0.875rem",
    margin: "0rem 0.25rem",
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
    cursor: "pointer",
  },
  active: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
  },
}));

const IrrigationDaysSelector = ({ onChangeIrrigationDays, plan }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {IRRIGATION_PLAN_DAYS.map((day) => {
        const isActive = isActiveDay(day.code, plan);
        const handleClick = () => onChangeIrrigationDays(day.code);
        return (
          <Avatar
            data-test={`day-selector-${day.code}`}
            key={day.code}
            onClick={handleClick}
            className={classnames({
              [classes.active]: isActive,
              [classes.day]: true,
            })}
          >
            <FormattedMessage id={day.label} />
          </Avatar>
        );
      })}
    </Box>
  );
};

IrrigationDaysSelector.propTypes = {
  plan: PropTypes.array.isRequired,
  onChangeIrrigationDays: PropTypes.func.isRequired,
};

export default IrrigationDaysSelector;
