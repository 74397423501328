import React, { useEffect, useContext, useState } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import { FormControlLabel, Switch, TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getFertilizers,
  getFertilizersCount,
  getIsFetchingFertilizers,
} from "../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";
import {
  getSectionListAdvancedFilter,
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../selectors/sectionList.selectors";

import {
  fetchFertilizers,
  patchFertilizer,
  resetFertilizers,
} from "../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../reducers/namespace.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { getColDesc } from "../../../shared/misc/helper";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../types";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";

import { COLUMN_NAMES } from "./fertilizers.columns";
import FertilizersAdvancedFilter from "./FertilizersAdvancedFilter/FertilizersAdvancedFilter";
import FertilizersListRow from "./FertilizersListRow";

import {
  CataloguesState,
  CATALOGUES_NAMESPACES,
} from "../../../reducers/catalogues.reducer.types";
import { FertilizerPatchTo } from "../../../shared/api/agroevidence/agroevidence.types";

export const columns = {
  [COLUMN_NAMES.IS_FAVORITE]: getColDesc(true, <StarBorderRoundedIcon />, {
    paddingLeft: 16,
    width: 30,
  }),
  [COLUMN_NAMES.NAME]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.fertilizers.column.name" />,
  ),
  [COLUMN_NAMES.IS_ORGANIC]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.fertilizers.column.isOrganic" />,
  ),
  [COLUMN_NAMES.NITROGEN_CATEGORY]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.fertilizers.column.nitrogenCategory" />,
  ),
  [COLUMN_NAMES.SOURCE]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.fertilizers.column.source" />,
  ),
  [COLUMN_NAMES.NITROGEN]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.fertilizers.column.n" />,
  ),
  [COLUMN_NAMES.P2O5]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.fertilizers.column.p2o5" />,
  ),
  [COLUMN_NAMES.K2O]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.fertilizers.column.k2o" />,
  ),
  [COLUMN_NAMES.VALID_TO]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.fertilizers.column.validTo" />,
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;

const FertilizersList = ({
  advancedFilter,
  count,
  fertilizers,
  fetchFertilizers,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  patchFertilizer,
  resetFertilizers,
  rowsPerPage,
  search,
  setNamespace,
}: ReduxProps) => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  const [showHistorical, setShowHistorical] = useState(false);
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    resetFertilizers();
    setNamespace(CATALOGUES_NAMESPACES.FERTILIZERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validOn = !showHistorical ? getShortDateString() : undefined;
    fetchFertilizers(validOn);
    // eslint-disable-next-line max-len
  }, [
    page,
    order,
    orderBy,
    rowsPerPage,
    search,
    advancedFilter,
    fetchFertilizers,
    langId,
    showHistorical,
    shouldReloadData,
  ]);

  if (namespace !== CATALOGUES_NAMESPACES.FERTILIZERS) return null;

  const onUpdateIsFavorite = (fertilizerId: string, isFavorite: boolean) => {
    (patchFertilizer as unknown as AsyncFn<string, FertilizerPatchTo>)(
      fertilizerId,
      { isFavorite: !isFavorite },
    ).then(() => setShouldReloadData((prevState) => !prevState));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="fertilizers-list-text-filter"
              namespace={namespace}
              translId="Catalogues.table.fertilizers.search"
            />
          </div>
          <div className={classes.advancedFilter}>
            <FertilizersAdvancedFilter langId={langId} namespace={namespace} />
          </div>
        </div>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          className={classes.switch}
          label={<FormattedMessage id="Catalogues.table.shared.showHistory" />}
          control={
            <Switch
              checked={showHistorical}
              color="primary"
              onChange={() => setShowHistorical((prevState) => !prevState)}
            />
          }
        />
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {fertilizers.length && !isFetching ? (
          <TableBody>
            {fertilizers.map((fertilizer) => (
              <FertilizersListRow
                data={fertilizer}
                key={fertilizer.id}
                onUpdateIsFavorite={onUpdateIsFavorite}
              />
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  fertilizers: getFertilizers(state),
  isFetching: getIsFetchingFertilizers(state),
  count: getFertilizersCount(state),
  namespace: getSelectedNamespace(state),
  search: getSectionListSearch(state),
  advancedFilter: getSectionListAdvancedFilter(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchFertilizers,
      resetFertilizers,
      patchFertilizer,
      setNamespace: setSelectedNamespace,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FertilizersList);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  switch: {
    marginLeft: "auto",
  },
  switchLabel: {
    fontSize: 14,
  },
}));
