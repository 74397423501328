import { AnyAction } from "redux";

import * as types from "./crops.constants";

const initialState = {
  items: [],
  isFetching: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_CROPS_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_CROPS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_CROPS_ERROR:
        return {
          ...state,
          isFetching: false,
        };

      case types.RESET_CROPS:
        return {
          ...state,
          items: [],
        };

      default:
        return state;
    }
  };
