import React, { useEffect, useState } from "react";

import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getPrevPage } from "../../selectors/common.selectors";

import { IRRIGATION_PLAN, IRRIGATION_URLS } from "../../irrigation.constants";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import EditableText from "../../../shared/components/common/EditableText/EditableText";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import DeviceActivityInfo from "../../../shared/components/specific/DeviceActivityInfo/DeviceActivityInfo";
import LocationCoordinatesInfo from "../../../shared/components/specific/LocationCoordinatesInfo/LocationCoordinatesInfo";
import IrrigationPlanIndicators from "../../containers/IrrigationList/areas/components/IrrigationPlanIndicators";
import IrrigationService from "../../services/Irrigation.service";

const styles = (theme) => ({
  headerWithInfo: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  deviceId: {
    color: theme.palette.grey[500],
    fontSize: 18,
  },
  rowItem: {
    display: "flex",
    marginRight: 12,
    alignItems: "center",
  },
  rowItemDays: {
    width: "200px",
  },
  deviceInfo: {
    fontSize: 13,
    display: "flex",
    color: theme.palette.grey[500],
    justifyContent: "center",
    flexWrap: "wrap",
  },
  deviceInfoWrapper: {
    padding: "8px 16px",
  },
});

const IRRIGATION_DAYS_SHORT_TO_LONG_FORMAT = {
  Mon: IRRIGATION_PLAN.DAY_CODES.MONDAY,
  Tue: IRRIGATION_PLAN.DAY_CODES.TUESDAY,
  Wed: IRRIGATION_PLAN.DAY_CODES.WEDNESDAY,
  Thu: IRRIGATION_PLAN.DAY_CODES.THURSDAY,
  Fri: IRRIGATION_PLAN.DAY_CODES.FRIDAY,
  Sat: IRRIGATION_PLAN.DAY_CODES.SATURDAY,
  Sun: IRRIGATION_PLAN.DAY_CODES.SUNDAY,
};

export function IrrigationDetailHeader(props) {
  const { area, classes, device, farmId, history, prevPage, updateDeviceName } =
    props;
  const isActive = IrrigationService.isActive(device);
  const [irrigationDays, setIrrigationDays] = useState([]);

  useEffect(() => {
    if (!isEmpty(device)) {
      const irrigationDays = device.schedules.reduce((acc, schedule) => {
        if (schedule.programs.length > 0) {
          acc.push(IRRIGATION_DAYS_SHORT_TO_LONG_FORMAT[schedule.dayOfWeek]);
        }
        return acc;
      }, []);
      setIrrigationDays(irrigationDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const backButtonHandle = () => {
    if (prevPage) {
      history.push(prevPage);
    } else {
      history.push(`/farm/${farmId}/${IRRIGATION_URLS.DEVICES}`);
    }
  };

  return (
    <div>
      <div className={classes.headerWithInfo}>
        <div className={classes.headerWrapper}>
          <PageHeader
            classes={{ header: classes.header }}
            backButton={
              <CfBackButton
                onClick={backButtonHandle}
                translId="IrrigationDetail.goBack"
              />
            }
            heading={
              <PageHeading
                customClass={classes.heading}
                value={
                  <div>
                    <EditableText
                      initialValue={device.name}
                      labelTestId="irrigation-device-name"
                      onConfirmEditing={(newName) =>
                        updateDeviceName(device.id, newName)
                      }
                    />
                    <span className={classes.deviceId}>
                      {device.externalId}
                    </span>
                  </div>
                }
              />
            }
          />
        </div>

        <div className={classes.deviceInfoWrapper}>
          <div className={classes.deviceInfo}>
            {device.activationDate && (
              <span
                className={classes.rowItem}
                data-test="irrigation-device-activity-date"
              >
                <DeviceActivityInfo dateFrom={device.activationDate} />
              </span>
            )}
            {device.geometry && (
              <span
                className={classes.rowItem}
                data-test="irrigation-device-location"
              >
                <LocationCoordinatesInfo
                  geometry={device.geometry}
                  locationOn={isActive}
                />
              </span>
            )}
            <span className={classes.rowItem}>-</span>
            <span
              className={classes.rowItem}
              style={{ color: "#333333", fontWeight: "bold" }}
            >
              {area.name}
            </span>
            <span className={classes.rowItem} style={{ marginRight: 6 }}>
              <FormattedMessage id="Irrigation.deviceDetail.activeDays" />
            </span>
            <div
              className={classes.rowItemDays}
              data-test="irrigation-device-active-days"
            >
              <IrrigationPlanIndicators plan={irrigationDays} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  prevPage: getPrevPage(state),
});

IrrigationDetailHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  area: PropTypes.object,
  farmId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  updateDeviceName: PropTypes.func.isRequired,
  prevPage: PropTypes.string.isRequired,
};

IrrigationDetailHeader.defaultProps = {
  area: {},
};

export default connect(mapStateToProps)(
  withStyles(styles)(IrrigationDetailHeader),
);
