import { FORM_TYPES } from "../../others/actionOther.constants";

import { isParcelSown } from "../../others/helpers/others.helpers";

export default class ActionsService {
  static getTotalArea(parcels, formType, isExisting) {
    let parcelsArea = 0;
    const isHarvestForm = formType === FORM_TYPES.HARVEST;
    const isMowingForm = formType === FORM_TYPES.MOWING;
    const mustBeSown = isHarvestForm || isMowingForm;

    if (parcels && parcels.length) {
      parcels.forEach((parcel) => {
        const isNewNotSownParcel = !isParcelSown(parcel) && !isExisting;
        // if Harvest form and parcel is not sown then not add Area to Total activity area
        if (mustBeSown && isNewNotSownParcel) return;
        const areaToAdd = parcel.actionParcelTotalArea;
        parcelsArea += areaToAdd;
      });
    }
    return parcelsArea;
  }
}
