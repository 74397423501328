/**
 * Created by ondrejzvara on 10.4.16.
 */

/*@ngInject*/
export default function LoggerFncProvider($log, LogStack) {
  var service = {
    log: log,
    info: info,
    error: error,
    warn: warn,
    debug: debug,
    printa: printa,
  };
  return service;

  /////////////////////////

  function error() {
    $log.error.apply(null, arguments);
  }

  function warn() {
    $log.warn.apply(null, arguments);
  }

  function info() {
    $log.info.apply(null, arguments);
  }

  function log() {
    $log.log.apply(null, arguments);
  }

  function debug() {
    var now = getFormattedDate();
    var args = Array.prototype.slice.call(arguments);

    var callback = function (stackframes) {
      var stringifiedStack = stackframes
        .map(function (sf) {
          return sf.toString();
        })
        .join("\n");

      args.unshift(now + " -");
      args.push("Stack: " + stringifiedStack);

      $log.debug.apply(null, args);
    };

    var errback = function (err) {
      console.log(err.message);
    };

    LogStack.get().then(callback).catch(errback);
  }

  function printa() {
    var now = getFormattedDate();
    var args = Array.prototype.slice.call(arguments);
    args.unshift(now + " -");

    $log.debug.apply(null, args);
  }

  function getFormattedDate() {
    var date = new Date();

    var h = checkFormat(date.getHours(), 1);
    var min = checkFormat(date.getMinutes(), 1);
    var sec = checkFormat(date.getSeconds(), 1);
    var miliSec = checkFormat(date.getMilliseconds(), 2);

    return h + ":" + min + ":" + sec + ":" + miliSec;
  }

  function checkFormat(str, len) {
    str = str.toString();
    if (str.length === len) {
      str = "0" + str;
    }
    return str;
  }
}
