import { createSelector } from "reselect";

import { getDevices } from "../../shared/api/irrigation/devices/devices.selectors";

import { NAMESPACE as areaDevicesNamespace } from "../reducer/areaDevices.reducer";
import { NAMESPACE as devicesListNamespace } from "../reducer/devicesList.reducer";

import { IrrigationState } from "../../reducers/irrigation.reducer.types";
import { Device } from "../../shared/api/irrigation/devices/devices.types";

export type Namespace =
  | typeof areaDevicesNamespace
  | typeof devicesListNamespace;

const getStateSection = (state: IrrigationState) => state.ui;
const getListSection = (
  _state: IrrigationState,
  ownProps: { namespace: Namespace },
) => ownProps.namespace;

export const getDevicesTextFilter = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].textFilter,
);

export const getDevicesPage = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].page,
);

export const getDevicesOrder = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].order,
);

export const getDevicesOrderBy = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].orderBy,
);

export const getDevicesRowsPerPage = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].rowsPerPage,
);

export const getDevicesSelected = createSelector(
  [getStateSection, getListSection],
  (state, section) => state[section].selected,
);

export const getDevicesSelectedOnPage = createSelector(
  [getDevicesSelected, getDevices],
  (allSelectedDevices: string[], devicesOnPage: Device[]) =>
    allSelectedDevices.filter((selectedDevice) =>
      devicesOnPage.some((d) => d.id === selectedDevice),
    ),
);
