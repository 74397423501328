import React, { FC } from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

export enum COLORS {
  BLUE = "#00ABF4",
  GREY = "#AAAAAA",
  PURPLE = "#BB0092",
  TRANSPARENT = "#00000000",
  VIOLET = "#534ACE",
  VIOLET_PLUM = "#740D7F",
  WHITE = "#ffffff",
}

interface Props {
  backgroundColor: COLORS;
  children?: React.ReactNode;
  color: COLORS;
  customClasses?: {
    badge?: string;
    container?: string;
  };
  fontSize?: number;
  text: string;
  tooltipText?: string;
}

type StyleType = Pick<
  Props,
  "backgroundColor" | "color" | "children" | "fontSize"
>;

const useStyles = makeStyles({
  badge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 12,
    height: 12,
    backgroundColor: ({ backgroundColor }: StyleType) => backgroundColor,
    borderRadius: "50%",
    color: ({ color }: StyleType) => color,
    position: ({ children }: StyleType) => (children ? "absolute" : "static"),
    top: 5,
    right: -6,
    fontSize: ({ fontSize }: StyleType) => fontSize ?? 8.5,
    marginRight: ({ children }: StyleType) => (children ? 0 : 8),
  },
  text: {
    margin: 0,
  },
  container: {
    position: "relative",
  },
});

const CfTextBadge: FC<Props> = ({
  backgroundColor = COLORS.GREY,
  children,
  color,
  customClasses,
  fontSize,
  text,
  tooltipText = "",
}) => {
  const classes = useStyles({ backgroundColor, color, children, fontSize });
  if (children) {
    return (
      <div className={classnames(classes.container, customClasses?.container)}>
        <Tooltip title={tooltipText}>
          <div className={classnames(classes.badge, customClasses?.badge)}>
            <p className={classes.text}>{text}</p>
          </div>
        </Tooltip>
        {children}
      </div>
    );
  }
  return (
    <Tooltip title={tooltipText}>
      <div className={classnames(classes.badge, customClasses?.badge)}>
        <p className={classes.text}>{text}</p>
      </div>
    </Tooltip>
  );
};

export default CfTextBadge;
