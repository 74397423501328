import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import withWidth from "../../../../shared/hocs/withWidth";
import { LogoutIcon } from "../../../../shared/icons/navbar/LogoutIcon";
import NavbarService from "../../services/NavbarService";
import MainMenuDropdown from "../MainMenuDropdown/MainMenuDropdown";
import { MainMenuItem } from "../MainMenuItem/MainMenuItem";

const styles = (theme) => ({
  mainMenuDesktop: {
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    height: "100%",
  },
  mainMenuMobile: {
    fontSize: 14,
    fontWeight: 500,
  },
  menuItemLink: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    "&:focus": {
      textDecoration: "none",
      color: theme.palette.common.white,
      outline: "none",
    },
  },
  menuItemLinkMobile: {
    margin: "8px 32px",
    borderRadius: 25,
    width: "fit-content",
  },
  navlinkContentDesktop: {
    whiteSpace: "nowrap",
    padding: "0px 15px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 10px",
    },
  },
  navlinkContentMobile: {
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
  },
  navlinkIcon: {
    width: 28,
    height: 28,
    marginRight: 10,
  },
  separator: {
    borderColor: theme.palette.primary.dark,
    margin: "16px 0px",
  },
});

class MainMenu extends Component {
  constructor(props) {
    super(props);
    const dropdownMenuItems = NavbarService.getDropdownMainMenuItems();
    const selectedDropdownItem = this.getSelectedDropdownItem();
    this.state = {
      dropdownSelected: selectedDropdownItem?.id || dropdownMenuItems[0].id,
    };
  }

  componentDidUpdate(prevProps) {
    const { currState } = this.props;
    if (prevProps.currState !== currState) {
      const selectedDropdownItem = this.getSelectedDropdownItem();
      if (
        selectedDropdownItem &&
        selectedDropdownItem.id !== this.state.dropdownSelected
      ) {
        this.setState({
          dropdownSelected: selectedDropdownItem.id,
        });
      }
    }
  }

  getSelectedDropdownItem = () => {
    const dropdownMenuItems = NavbarService.getDropdownMainMenuItems();
    return dropdownMenuItems.find((menuItem) => this.isActive(menuItem));
  };

  isActive = (menuItem) => {
    const { currState } = this.props;
    const baseState = "farm.active";
    if (currState.startsWith(baseState)) {
      const statesPart = currState.split(".");
      const stateName = statesPart[2];
      return menuItem.states.some((state) => state === stateName);
    }
    return false;
  };

  render() {
    const { classes, farmId, logout, mobile, onMenuItemClick, width } =
      this.props;
    const { dropdownSelected } = this.state;
    const menuItems = NavbarService.getMainMenuItems();
    const dropdownMenuItems = NavbarService.getDropdownMainMenuItems();

    return (
      <div
        className={mobile ? classes.mainMenuMobile : classes.mainMenuDesktop}
      >
        {menuItems
          .filter(
            (menuItem) =>
              !(
                (width === "md" || width === "lg") &&
                menuItem.dropdown &&
                menuItem.id !== dropdownSelected
              ),
          )
          .map((menuItem) => (
            <MainMenuItem
              classes={classes}
              farmId={farmId}
              isActive={this.isActive(menuItem)}
              key={menuItem.id}
              menuItem={menuItem}
              mobile={mobile}
              onMenuItemClick={onMenuItemClick}
            />
          ))}
        {(width === "md" || width === "lg") && (
          <MainMenuDropdown
            farmId={farmId}
            items={dropdownMenuItems}
            lastSelected={dropdownSelected}
          />
        )}
        {mobile && (
          <Fragment>
            <hr className={classes.separator} />
            <div
              className={`${classes.menuItemLink} ${classes.menuItemLinkMobile}`}
              id="main-menu-logout"
              onClick={logout}
              role="button"
              tabIndex="0"
            >
              <span className={classes.navlinkContentMobile}>
                <LogoutIcon className={classes.navlinkIcon} />
                <FormattedMessage id="UserMenu.logout" />
              </span>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  currState: PropTypes.string.isRequired,
  logout: PropTypes.func,
  mobile: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
  width: PropTypes.string.isRequired,
};

MainMenu.defaultProps = {
  mobile: false,
  onMenuItemClick: () => {},
  logout: () => {},
};

export default compose(withWidth(), withStyles(styles))(MainMenu);
