import React from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";

import { LANGUAGE_ID } from "../../../../../shared/lang/lang.constants";

import ShowHideMap from "../../../../../shared/components/common/ShowHideMap/ShowHideMap";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { useIsMobile } from "../../../../../shared/hooks/useWidth";

import { Content } from "./Content";
import { MapWrapper } from "./MapWrapper";

type Props = {
  langId: LANGUAGE_ID;
};

const Detail = ({ langId }: Props) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const { on: isMapVisible, toggle: toggleMap } = useToggle(true);
  const isMobile = useIsMobile();
  const contentSolo = !(isMapVisible && isMobile);

  return (
    <Grid className={classes.wrapper} container>
      {contentSolo ? (
        <Grid
          className={classes.bodyWrapper}
          item
          md={isMapVisible ? 6 : 12}
          xs={12}
        >
          <Scrollbars>
            <div className={classes.body}>
              <Content langId={langId} />
            </div>
          </Scrollbars>
          <ShowHideMap handleClick={toggleMap} isMapHidden={!isMapVisible} />
        </Grid>
      ) : null}
      <Grid
        className={classes.mapWrapper}
        item
        md={6}
        style={{ display: isMapVisible ? "block" : "none" }}
        xs={12}
      >
        {!contentSolo ? (
          <ShowHideMap handleClick={toggleMap} isMapHidden={!isMapVisible} />
        ) : null}
        <MapWrapper key={pathname} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  bodyWrapper: {
    position: "relative",
  },
  mapWrapper: {
    height: "100%",
  },
}));

export { Detail };
