import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isDialogOpen } from "../../../shared/selectors/manager.selectors";

import {
  setDialogOpen,
  setSensorsTabActive,
  setActionButtonHidden,
} from "../../../shared/actions/manager.actions";
import { setFarmSettings } from "../../actions/notifications.actions";

import { NAMESPACE as namespace } from "../../reducer/notifications.reducer";

import CfTextFilter from "../../../../../shared/containers/CfTextFilter/CfTextFilter";
import NotificationsAdminEditDialog from "../../components/NotificationsAdminEditDialog/NotificationsAdminEditDialog";
import NotificationsAdminTable from "../NotificationsAdminTable/NotificationsAdminTable";

const styles = {
  sentinelAdmin: {
    fontWeight: 500,
    margin: "10px 0",
  },
  tableHeaderContainer: {
    paddingBottom: 8,
  },
};

export class NotificationsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farm: null,
    };
    props.setActionButtonHidden(true);
    props.setSensorsTabActive(false);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.dialogOpen && prevProps.dialogOpen) {
      this.setState({
        farm: null,
      });
    }
  }

  openDialogWithFarm = (farm) => {
    this.setState({
      farm,
    });
    this.props.setDialogOpen(true);
  };

  handleDialogAccept = (farm, active, adminEmail) => {
    this.props.setDialogOpen(false);
    this.props.setFarmSettings(farm, active, adminEmail);
  };

  render() {
    const { classes, dialogOpen, langId } = this.props;
    const { farm } = this.state;
    return (
      <div>
        <Grid container spacing={2}>
          <NotificationsAdminEditDialog
            farm={farm}
            onAccept={this.handleDialogAccept}
            onClose={() => this.props.setDialogOpen(false)}
            opened={dialogOpen}
          />
          <Grid
            alignItems="center"
            className={classes.sentinelAdmin}
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Grid
                className={classes.tableHeaderContainer}
                container
                spacing={2}
              >
                <Grid item md={4} sm={6} xs={12}>
                  <CfTextFilter
                    id="notifications-admin-text-filter"
                    namespace={namespace}
                    translId="common.farmName"
                  />
                </Grid>
                <Grid item md={4} sm={1} xs={3} />
                <Grid item md={4} sm={5} xs={9} />
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <NotificationsAdminTable
                    langId={langId}
                    openFarmDialog={this.openDialogWithFarm}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NotificationsAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  setActionButtonHidden: PropTypes.func.isRequired,
  setSensorsTabActive: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  setFarmSettings: PropTypes.func.isRequired,
};

NotificationsAdmin.defaultProps = {};

const mapStateToProps = (state) => ({
  dialogOpen: isDialogOpen(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDialogOpen,
      setActionButtonHidden,
      setSensorsTabActive,
      setFarmSettings,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationsAdmin));
