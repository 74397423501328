import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Switch, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";

import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";

import { IntegrationTo } from "../../../shared/api/satellite/satellite.types";

type Props = {
  buttonOnClick: () => void;
  data?: IntegrationTo;
  editOnClick: () => void;
  switchOnClick: () => void;
};

const VarioDocTableRow = ({
  buttonOnClick,
  data,
  editOnClick,
  switchOnClick,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const isEnabled = data?.enabled;

  return (
    <TableRow key={1}>
      <CfTableCell name="status">
        <Switch
          checked={isEnabled ?? false}
          color="primary"
          data-test="integration-toggle"
          disabled={!isEnabled}
          name="enabled"
          onClick={switchOnClick}
        />
      </CfTableCell>
      <CfTableCell name="service">
        <FormattedMessage id="VarioDoc.service" />
      </CfTableCell>
      <CfTableCell name="user_account">{data?.email ?? "-"}</CfTableCell>
      <CfTableCell classes={{ root: classes.lastCell }} name="description">
        <FormattedMessage id="VarioDoc.description" />
        {isEnabled ? (
          <Tooltip
            title={intl.formatMessage({
              id: "VarioDoc.activationDialog.edit.tooltip",
            })}
          >
            <IconButton onClick={editOnClick} size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className={classes.button}
            color="primary"
            onClick={buttonOnClick}
            type="submit"
            variant={isEnabled ? "text" : "contained"}
          >
            <FormattedMessage
              id={
                isEnabled
                  ? "IntegrationSettings.setConnection"
                  : "IntegrationSettings.connect"
              }
            />
          </Button>
        )}
      </CfTableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  tableHeaderContainer: {
    paddingBottom: 8,
    alignItems: "center",
  },
  lastCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
  },
  button: {
    fontWeight: "500",
    fontSize: 14,
    margin: "5px 0px",
  },
}));

export default VarioDocTableRow;
