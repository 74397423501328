/**
 * Created by ondrejzvara on 7.11.15.
 */

/*@ngInject*/
export default function PorController(
  porIdName,
  ChemistriesResource,
  Plain,
  $state,
) {
  var vm = this;

  vm.item = {
    name: porIdName,
    unitId: "t/ha",
    clMaterialRestrictionList: [],
  };
  vm.loadingPromise = [];

  vm.canPublish = canPublish;
  vm.startEditing = startEditing;
  vm.publish = publish;
  vm.saveEdits = saveEdits;
  vm.cancelEdit = cancelEdit;

  activate();

  ////////////////////////////////////////////

  function activate() {
    vm.loadingPromise = initPor();
  }

  /**
   * if porIdName is string or false then create a new private POR
   * otherwise load an existing POR
   * @returns {*}
   */
  function initPor() {
    if (!porIdName) {
      //plain new POR
      vm.edit = true;
    } else {
      var porId = _.toFinite(porIdName);
      if (porId > 0 && _.isFinite(porId)) {
        //por ID in the path param
        return getPor(porIdName);
      } else {
        //por name in the path parameter
        vm.edit = true;
      }
    }
  }

  function getPor(porId) {
    return ChemistriesResource.getChemistry(porId).then(function (data) {
      vm.item = new Plain(data);
      vm.edit = false;
      return data;
    });
  }

  function startEditing() {
    vm.edit = true;
  }

  function cancelEdit() {
    if (isNewPor()) {
      $state.go("farm.active.classifiers.private.pors");
    } else {
      vm.edit = false;
    }
  }

  function isNewPor() {
    return !vm.item.id;
  }

  function saveEdits() {
    if (isNewPor()) {
      vm.loadingPromise = ChemistriesResource.createChemistry(vm.item).then(
        getPor,
      );
    } else {
      vm.loadingPromise = ChemistriesResource.editChemistry(vm.item).then(
        function () {
          return getPor(vm.item.id);
        },
      );
    }
  }

  function canPublish() {
    return !vm.edit && vm.item.source !== "PRIVATE";
  }

  function publish() {
    if (!isNewPor()) {
      vm.loadingPromise = ChemistriesResource.publishChemistry(vm.item.id).then(
        function () {
          return getPor(vm.item.id);
        },
      );
    }
  }
}
