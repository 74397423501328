import React from "react";

import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

const styles = {
  menuItemChild: {
    width: "45px",
    textAlign: "center",
  },
};

export const options = [
  {
    value: "en-US",
    id: "lang-EN",
    primaryText: "EN",
  },
  {
    value: "cs-CZ",
    id: "lang-CZ",
    primaryText: "CZ",
  },
  {
    value: "sr-LATN-RS",
    id: "lang-RS",
    primaryText: "RS",
  },
  {
    value: "es-ES",
    id: "lang-ES",
    primaryText: "ES",
  },
  {
    value: "ru-UA",
    id: "lang-RU",
    primaryText: "RU",
  },
  {
    value: "el-GR",
    id: "lang-EL",
    primaryText: "EL",
  },
  {
    value: "ro-RO",
    id: "lang-RO",
    primaryText: "RO",
  },
  {
    value: "hu-HU",
    id: "lang-HU",
    primaryText: "HU",
  },
];

class LangSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      open: false,
      selectedIndex: options.map((o) => o.value).indexOf(props.intl.locale),
    };
  }

  componentDidUpdate() {
    const {
      intl: { locale },
    } = this.props;
    const { selectedIndex } = this.state;
    if (options[selectedIndex].value !== locale) {
      const newIndex = options.findIndex((option) => option.value === locale);
      if (newIndex !== -1) {
        this.setState({
          selectedIndex: newIndex,
        });
      }
    }
  }

  handleClickListItem = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, open: false });
    this.props.onLangChange(options[index].value);
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div className={this.props.className} id="lang-switch">
        <List>
          <ListItem
            aria-controls="lock-menu"
            aria-haspopup="true"
            button
            onClick={this.handleClickListItem}
          >
            <span id="lang-code">
              {options[this.state.selectedIndex].primaryText}
            </span>{" "}
            <Icon>keyboard_arrow_down</Icon>
          </ListItem>
        </List>
        <Menu
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
          open={this.state.open}
        >
          {options.map((option, index) => (
            <MenuItem
              id={`lang-${option.primaryText}`}
              key={option.value}
              onClick={(event) => this.handleMenuItemClick(event, index)}
              selected={index === this.state.selectedIndex}
              value={option.value}
            >
              <span className={classes.menuItemChild}>
                {option.primaryText}
              </span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

LangSwitch.propTypes = {
  intl: PropTypes.object.isRequired,
  onLangChange: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.any,
};

LangSwitch.defaultProps = {
  onLangChange: () => {},
  classes: {},
  className: "",
};

export default withStyles(styles)(injectIntl(LangSwitch));
