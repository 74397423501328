import React, { useState } from "react";

import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    maxWidth: "16.25rem",
  },
  time: {
    color: theme.palette.grey[500],
    width: 50,
    margin: 5,
    padding: 0,
  },
  timeInput: {
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
    color: theme.palette.primary.main,
    paddingBottom: "0.125rem",
    "&::placeholder": {
      color: theme.palette.grey[500],
    },
  },
}));

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, time, ...other } = props;
  const startsWithTwo = time[0] === "2";

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask="23:59"
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      definitions={{
        2: /[0-2]/,
        3: startsWithTwo ? /[0-3]/ : /[0-9]/,
        5: /[0-5]/,
        9: /[0-9]/,
      }}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const IrrigationStartTimeInput = ({ onInputStartTimes }) => {
  const classes = useStyles();
  const [time, setTime] = useState("");

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const handleBlur = () => {
    if (time.length === 5) {
      onInputStartTimes(time);
    }
    setTime("");
  };

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.time}
        id="formatted-numberformat-input"
        name="textmask"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="00:00"
        value={time}
        variant="standard"
        InputProps={{
          inputComponent: TextMaskCustom,
          inputProps: {
            time,
            className: classes.timeInput,
          },
        }}
      />
    </Box>
  );
};

IrrigationStartTimeInput.propTypes = {
  onInputStartTimes: PropTypes.func.isRequired,
};

export default IrrigationStartTimeInput;
