import React, { FC, useEffect, useMemo, useState } from "react";

import { Grid, MenuItem } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcelSowingPlan,
  getParcelSowingPlan,
} from "../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";

import { getParcelSowingPlanApi } from "../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import SelectionItemColumn from "../../../shared/components/common/SelectionItemColumn/SelectionItemColumn";
import CfFormikTextField from "../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { CropName } from "../../../shared/components/specific/CropName/CropName";
import { Thunk } from "../../../types";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  SeedApplicationType,
  SowingPlanSeasonCropTo,
} from "../../../shared/api/agroevidence/agroevidence.types";
import { DetailContentFormValues } from "../TelematicsAggregationDetailContent/DetailContentForm.types";

interface Props {
  cropLabel: string;
  currentCropName?: string;
  currentCropType: SeedApplicationType;
  disabled: boolean;
  getSowingPlans: (parcelId: string, startYear: number, size: number) => void;
  isFetching: boolean;
  parcelId?: string;
  parcelSowingPlan: SowingPlanSeasonCropTo[];
}

const TelematicsSeasonSelector: FC<Props> = ({
  cropLabel,
  currentCropName,
  currentCropType,
  disabled,
  getSowingPlans,
  isFetching,
  parcelId,
  parcelSowingPlan,
}) => {
  useEffect(() => {
    if (!parcelId) return;
    const lastYear = new Date().getFullYear() - 1;
    getSowingPlans(parcelId, lastYear, 2);
  }, [getSowingPlans, parcelId]);
  const { setFieldTouched, setFieldValue, touched } =
    useFormikContext<DetailContentFormValues>();

  const [cropName, setCropName] = useState<string | undefined>(
    currentCropName ?? "-",
  );
  const [cropType, setCropType] = useState<SeedApplicationType | undefined>(
    currentCropType,
  );

  useEffect(() => {
    if (!touched?.season) {
      // reset crop when formik is reset
      setCropName(currentCropName ?? "-");
      setCropType(currentCropType);
    }
  }, [touched, currentCropName, currentCropType]);

  const sowingPlans = useMemo(() => {
    if (!parcelSowingPlan?.length) return [];

    return parcelSowingPlan.reduce(
      (acc, sowingPlan) => {
        const {
          crop,
          season: { endYear, id, startYear },
        } = sowingPlan;

        return [
          ...acc,
          {
            id,
            name: `${startYear}-${endYear}`,
            crop: crop?.name ?? "-",
            season: startYear,
          },
        ];
      },
      [
        // no season value
        {
          id: 0,
          name: "-",
          crop: currentCropName ?? "-",
          season: 0,
        },
      ],
    );
  }, [parcelSowingPlan, currentCropName]);

  return (
    <Grid container item xs>
      <Grid item xs={6}>
        <SelectionItemColumn label={<FormattedMessage id="common.season" />}>
          <Field
            component={CfFormikTextField}
            disabled={disabled || isFetching}
            name="season"
            select
            onChange={(e: React.BaseSyntheticEvent) => {
              setFieldValue("season", e.target.value);
              setFieldTouched("season", true);
            }}
          >
            {sowingPlans.map((sowingPlan) => (
              <MenuItem
                key={sowingPlan.id}
                onClick={() => setCropName(sowingPlan?.crop)}
                value={sowingPlan.season}
              >
                {sowingPlan.name}
              </MenuItem>
            ))}
          </Field>
        </SelectionItemColumn>
      </Grid>
      <Grid item xs={6}>
        <SelectionItemColumn label={<FormattedMessage id={cropLabel} />}>
          <CropName
            cropType={cropName !== "-" ? cropType : undefined}
            sx={{ height: 20 }}
          >
            <span>{cropName}</span>
          </CropName>
        </SelectionItemColumn>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  parcelSowingPlan: getParcelSowingPlan(state),
  isFetching: getIsFetchingParcelSowingPlan(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getSowingPlans: getParcelSowingPlanApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsSeasonSelector);
