import React, { FC } from "react";

import { Autocomplete, TextField, Theme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { FieldProps, Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import * as validators from "../../../../../shared/misc/validators";

import { CropSeedTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
    marginTop: "3px",
  },
}));

interface SeedSelectorProps {
  isEditing: boolean;
  isFetchingSeeds: boolean;
  onChange: (event: React.ChangeEvent, newValue: CropSeedTo) => void;
  seeds: CropSeedTo[];
}

const SeedSelector: FC<SeedSelectorProps> = ({
  isEditing,
  isFetchingSeeds,
  onChange,
  seeds,
}) => {
  const classes = useStyles();

  if (isFetchingSeeds) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <CfFormControl>
      <Field name="seedId" validate={validators.required} validateOnBlur>
        {({ field, meta }: FieldProps<string>) => (
          <>
            <Autocomplete
              disableClearable
              disabled={!isEditing || isFetchingSeeds}
              getOptionLabel={(seed) => seed.name}
              onChange={onChange}
              options={seeds.filter((seed) => !seed.isHidden)}
              value={seeds.find((seed) => seed.id === field.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<FormattedMessage id="action.sowingSeed" />}
                />
              )}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {" "}
                  {item.name}{" "}
                </li>
              )}
            />
            {meta.error && (
              <div className={classes.error}>
                <FormattedMessage id="validation.required" />
              </div>
            )}
          </>
        )}
      </Field>
    </CfFormControl>
  );
};

export default SeedSelector;
