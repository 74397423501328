import React, { FC } from "react";

import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

import CfAutosuggest from "../../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";

import { BaraniAvailableBases } from "../../../admin.sensors.types";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  suggestionsContainerOpen: {
    margin: 0,
    "box-shadow": "none",
    position: "static",
    height: "calc(100% - 52px)",
  },
  suggestionsContainer: {
    boxShadow: "none",
    height: "calc(100% - 52px)",
  },
  suggestionsList: {
    maxHeight: "300px",
    maxWidth: "350px",
  },
  suggestionItem: {
    overflow: "inherit",
  },
  input: {
    padding: "15px 15px 5px 15px",
  },
  positionEnd: {
    right: "8px",
  },
}));

interface Props {
  getAvailableBaraniBases: (
    filter: string,
    params: string,
    moduleId: string,
  ) => void;
  isFetching: boolean;
  onSuggestionClear: () => void;
  onSuggestionSelect: (baseToAssign: BaraniAvailableBases) => void;
  suggestions: BaraniAvailableBases[];
}

const AdminBaraniBaseSelector: FC<Props> = ({
  getAvailableBaraniBases,
  isFetching = false,
  onSuggestionClear,
  onSuggestionSelect,
  suggestions = [],
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const getSuggestionValue = (suggestion: BaraniAvailableBases) =>
    suggestion.externalId;

  const disableSuggestion = (suggestion: BaraniAvailableBases) =>
    !suggestion.assignable;

  return (
    <CfAutosuggest
      alwaysRenderSuggestions={true}
      autoFocus={true}
      clearInputOnAdornmentClick={true}
      clearInputOnSelect={true}
      clearSuggestions={onSuggestionClear}
      disableSuggestion={disableSuggestion}
      disableUnderline={true}
      getSuggestions={getAvailableBaraniBases}
      getSuggestionValue={getSuggestionValue}
      isDebounced={true}
      isFetching={isFetching}
      multiSection={false}
      onSuggestionSelected={onSuggestionSelect}
      placeholder={intl.formatMessage({ id: "SensorsAdmin.baraniBaseFilter" })}
      requiredLength={3}
      scrollable={true}
      scrollHeightMax={300}
      suggestions={suggestions}
      testData="barani-bases-selector"
      adornment={
        <IconButton disabled={true} size="large">
          <SearchIcon />
        </IconButton>
      }
      classes={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsContainer: classes.suggestionsContainer,
        suggestionsList: classes.suggestionsList,
        suggestionItem: classes.suggestionItem,
        input: classes.input,
        positionEnd: classes.positionEnd,
      }}
    />
  );
};

export default AdminBaraniBaseSelector;
