import React, { Component } from "react";

import { InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { Scrollbars } from "react-custom-scrollbars-2";

import AutosuggestInput from "../AutosuggestInput/AutosuggestInput";
import AutosuggestItem from "../AutosuggestItem/AutosuggestItem";

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    borderRadius: 4,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 10,
  },
  suggestionsContainer: {},
  suggestion: {
    display: "block",
  },
  suggestionItem: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  positionEnd: {
    position: "absolute",
    right: "-42px",
  },
  positionStart: {
    margin: 0,
  },
  input: {
    color: theme.palette.text.primary,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
});

class CfAutosuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    if (props.isDebounced) {
      this.calls = 0;
      this.getDebouncedSuggestions = debounce(this.props.getSuggestions, 500);
    }
  }

  componentWillUnmount() {
    if (this.getDebouncedSuggestions) {
      this.getDebouncedSuggestions.cancel();
    }
  }

  onAdornmentClick = () => {
    this.props.onAdornmentClick();
    if (this.props.clearInputOnAdornmentClick) {
      this.setState({
        value: "",
      });
    }
  };

  onSuggestionSelected = (evt, { suggestion }) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.onSuggestionSelected(suggestion);
    if (this.props.clearInputOnSelect) {
      this.setState({
        value: "",
      });
    }
  };

  getSectionSuggestions = (section) => section.items;

  getSuggestionItemValue = () =>
    this.props.getSuggestionItemValue || this.props.getSuggestionValue;

  handleSuggestionsFetchRequested = ({ reason, value }) => {
    this.calls += 1;
    const { getSuggestions, isDebounced } = this.props;
    return isDebounced && this.calls > 1
      ? this.getDebouncedSuggestions(value, reason)
      : getSuggestions(value, reason);
  };

  handleSuggestionsClearRequested = () => {
    if (this.state.value.length >= this.props.requiredLength) {
      this.props.clearSuggestions();
    }
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.onChange(newValue);
  };

  shouldRenderSuggestions = (value) =>
    value.trim().length >= this.props.requiredLength;

  renderInput = (inputProps) => {
    const { ref, ...props } = inputProps;
    const refFncInterceptor = (refEl) => {
      this.props.inputRef(refEl);
      this.refEl = refEl;
      return ref(refEl);
    };
    return <AutosuggestInput refFnc={refFncInterceptor} {...props} />;
  };

  renderSuggestionsContainer = (options) => {
    const { children, containerProps } = options;
    const { classes, isFetching, scrollHeightMax, scrollable } = this.props;
    return (
      <Paper {...containerProps} square>
        {isFetching ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <List
            data-test="suggested-list"
            disablePadding={true}
            subheader={<div />}
          >
            {scrollable ? (
              <Scrollbars autoHeight autoHeightMax={scrollHeightMax}>
                {children}
              </Scrollbars>
            ) : (
              children
            )}
          </List>
        )}
      </Paper>
    );
  };

  renderSuggestion = (suggestion, { isHighlighted, query }) => (
    <AutosuggestItem
      disabled={this.props.disableSuggestion(suggestion)}
      getSuggestionValue={this.getSuggestionItemValue()}
      isHighlighted={isHighlighted}
      onItemMouseEnter={this.props.onItemMouseEnter}
      onItemMouseLeave={this.props.onItemMouseLeave}
      query={query}
      suggestion={suggestion}
      classes={{
        root: this.props.classes.suggestionItem,
      }}
    />
  );

  renderSectionTitle = (section) => (
    <ListSubheader>{section.title.toUpperCase()}</ListSubheader>
  );

  render() {
    const { adornment, classes, startAdornment } = this.props;

    return (
      <Autosuggest
        alwaysRenderSuggestions={this.props.alwaysRenderSuggestions}
        focusInputOnSuggestionClick={this.props.focusInputOnSuggestionClick}
        getSuggestionValue={this.props.getSuggestionValue}
        highlightFirstSuggestion={true}
        multiSection={this.props.multiSection}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        renderInputComponent={this.renderInput}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={this.props.suggestions}
        getSectionSuggestions={
          this.props.multiSection ? this.getSectionSuggestions : null
        }
        inputProps={{
          onKeyPress: this.props.onKeyPress,
          value: this.state.value,
          onChange: this.handleChange,
          placeholder: this.props.placeholder,
          id: this.props.testData,
          autoFocus: this.props.autoFocus,
          disableUnderline: this.props.disableUnderline,
          endAdornment:
            typeof adornment !== "undefined" ? (
              <InputAdornment
                classes={{ positionEnd: classes.positionEnd }}
                onClick={this.onAdornmentClick}
                position="end"
              >
                {adornment}
              </InputAdornment>
            ) : null,
          startAdornment:
            typeof startAdornment !== "undefined" ? (
              <InputAdornment
                classes={{ positionStart: classes.positionStart }}
                onClick={this.onAdornmentClick}
                position="start"
              >
                {startAdornment}
              </InputAdornment>
            ) : null,
          disabled: this.props.disabled,
          onClick: (e) => {
            // a little hack due to autoFocused input not showing suggestions
            if (!e.target.value) {
              this.refEl.blur();
              this.refEl.focus();
            }
          },
        }}
        renderSectionTitle={
          this.props.multiSection ? this.renderSectionTitle : null
        }
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsContainer: classes.suggestionsContainer,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
          input: classes.input,
        }}
      />
    );
  }
}

CfAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  testData: PropTypes.string.isRequired,
  suggestions: PropTypes.array,
  getSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onAdornmentClick: PropTypes.func,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  requiredLength: PropTypes.number,
  clearInputOnSelect: PropTypes.bool,
  clearInputOnAdornmentClick: PropTypes.bool,
  focusInputOnSuggestionClick: PropTypes.bool,
  alwaysRenderSuggestions: PropTypes.bool,
  multiSection: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  adornment: PropTypes.node,
  startAdornment: PropTypes.node,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
  isFetching: PropTypes.bool,
  scrollable: PropTypes.bool,
  scrollHeightMax: PropTypes.number,
  onItemMouseEnter: PropTypes.func,
  onItemMouseLeave: PropTypes.func,
  getSuggestionItemValue: PropTypes.func,
  disableSuggestion: PropTypes.func,
  isDebounced: PropTypes.bool,
};

CfAutosuggest.defaultProps = {
  suggestions: [],
  placeholder: "",
  requiredLength: 3,
  clearInputOnSelect: true,
  clearInputOnAdornmentClick: false,
  clearSuggestions: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  onAdornmentClick: () => {},
  focusInputOnSuggestionClick: true,
  alwaysRenderSuggestions: false,
  multiSection: true,
  autoFocus: false,
  disableUnderline: false,
  adornment: undefined,
  startAdornment: undefined,
  disabled: null,
  inputRef: () => {},
  isFetching: false,
  scrollable: false,
  scrollHeightMax: 300,
  onItemMouseEnter: () => {},
  onItemMouseLeave: () => {},
  getSuggestionItemValue: undefined,
  disableSuggestion: () => {},
  isDebounced: false,
};

export default withStyles(styles)(CfAutosuggest);
