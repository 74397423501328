import React, { Component, Fragment } from "react";

import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import WarningIcon from "@mui/icons-material/Warning";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getGroups } from "../../../shared/api/iot/groups/groups.selectors";
import { getEvapotranspirationData } from "../../../shared/api/iot/locations/locations.selectors";
import { getNewNode } from "../../selectors/node.selectors";

import {
  assignGroupsFromDetail,
  update,
} from "../../actions/locations.actions";
import { setEnlargedVariant } from "../../actions/map.actions";
import { deactivate } from "../../actions/node.actions";

import CfFormattedRelativeTime from "../../../shared/components/common/CfFormattedRelativeTime/CfFormattedRelativeTime";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import CfWarningCard from "../../../shared/components/common/CfWarningCard/CfWarningCard";
import SensorsService from "../../../shared/services/Sensors.service";
import NodeLastValues from "../../components/NodeLastValues/NodeLastValues";
import NodeLocationDetailHeader from "../../components/NodeLocationDetailHeader/NodeLocationDetailHeader";
import NodeLocationPredictions from "../../components/NodeLocationPredictions/NodeLocationPredictions";
import NodeLocationUpdateInfo from "../../components/NodeLocationUpdateInfo/NodeLocationUpdateInfo";
import NodeLocationCharts from "../NodeLocationCharts/NodeLocationCharts";

const styles = (theme) => ({
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  inactiveContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 550,
    margin: "auto",
  },
  nodeSensorsWrapper: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: "8px 16px",
    justifyContent: "center",
  },
  nodeSensorsItem: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    fontSize: 13,
    color: theme.palette.grey[500],
  },
});

export class NodeLocationDetail extends Component {
  constructor(props) {
    super(props);
    props.setEnlargedVariant(false);
  }

  setGroups = (items, locationId) => {
    this.props.assignGroupsFromDetail(items, locationId);
  };

  render() {
    const {
      classes,
      evapotranspirationData,
      groups,
      history,
      isMapDisplayed,
      match,
      ngGoToNewNotification,
      nodeLocation,
    } = this.props;
    const node = nodeLocation.node;
    const isActive = SensorsService.isActive(nodeLocation);
    const isErrorOrTransfer = SensorsService.isErrorOrTransfer(nodeLocation);
    const hasZeroCoordinates = SensorsService.hasZeroCoordinates(nodeLocation);
    const isHistoric = SensorsService.isHistoric(nodeLocation);
    const pestPredictions =
      nodeLocation && nodeLocation.computedFeatures
        ? nodeLocation.computedFeatures
        : [];
    const tooLongWithoutUpdate = node
      ? SensorsService.isTooLongWithoutUpdate(nodeLocation.lastUpdate)
      : false;

    return (
      <div>
        <NodeLocationDetailHeader
          deactivate={this.props.deactivate}
          groups={groups}
          history={history}
          match={match}
          ngGoToNewNotification={ngGoToNewNotification}
          node={node}
          nodeLocation={nodeLocation}
          setGroups={this.setGroups}
          update={this.props.update}
        />
        <Grid className={classes.nodeSensorsWrapper} container>
          <Grid className={classes.nodeSensorsItem} item xs={12}>
            {isActive && !isHistoric && (
              <NodeLocationUpdateInfo nodeLocation={nodeLocation} />
            )}
            <NodeLastValues
              hideValues={!nodeLocation.lastUpdate}
              isActive={isActive}
              isHistoric={isHistoric}
              node={node}
              pestPredictions={pestPredictions}
              totalEvapotranspiration={evapotranspirationData.total}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            lg={isMapDisplayed ? "auto" : 1}
            xl={isMapDisplayed ? "auto" : 2}
          />
          <Grid
            className={classes.content}
            item
            lg={isMapDisplayed ? 12 : 10}
            xl={isMapDisplayed ? 12 : 8}
          >
            {isActive && !hasZeroCoordinates ? (
              <Fragment>
                {!isHistoric && tooLongWithoutUpdate && (
                  <CfWarningCard>
                    <Fragment>
                      <FormattedMessage id="NodeLocationDetail.lastSensorUpdateWarning" />{" "}
                      <CfFormattedRelativeTime
                        dateTime={nodeLocation.lastUpdate}
                      />
                    </Fragment>
                  </CfWarningCard>
                )}
                <NodeLocationCharts
                  match={match}
                  node={node}
                  nodeLocation={nodeLocation}
                />
                {pestPredictions.length > 0 && (
                  <NodeLocationPredictions
                    history={history}
                    match={match}
                    nodeLocation={nodeLocation}
                    pestPredictions={pestPredictions}
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className={classes.inactiveContent}>
                  {isHistoric && (
                    <CfStatusPanel
                      icon={SignalWifiOffIcon}
                      title=""
                      content={
                        <FormattedMessage id="NodeLocationDetail.sensorWasNotActive" />
                      }
                    />
                  )}
                  {!isHistoric && hasZeroCoordinates && (
                    <CfStatusPanel
                      icon={SignalWifiOffIcon}
                      content={
                        <FormattedMessage id="NodeLocationDetail.sensorNotActivatedExplanation" />
                      }
                      linkText={
                        <FormattedMessage id="DeviceActivation.selectLocation" />
                      }
                      onLinkClick={() => {
                        history.push(
                          `/farm/${match.params.farmId}/sensors/${nodeLocation.id}/activate`,
                        );
                      }}
                      title={
                        <FormattedMessage id="NodeLocationDetail.sensorNotActivated" />
                      }
                    />
                  )}
                  {isErrorOrTransfer && !isHistoric && !hasZeroCoordinates && (
                    <CfStatusPanel
                      icon={WarningIcon}
                      title=""
                      content={
                        <FormattedMessage id="NodeLocationDetail.error" />
                      }
                    />
                  )}
                </div>
              </Fragment>
            )}
          </Grid>
          <Grid
            item
            lg={isMapDisplayed ? "auto" : 1}
            xl={isMapDisplayed ? "auto" : 2}
          />
        </Grid>
      </div>
    );
  }
}

NodeLocationDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isMapDisplayed: PropTypes.bool,
  update: PropTypes.func.isRequired,
  groups: PropTypes.array,
  assignGroupsFromDetail: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
  ngGoToNewNotification: PropTypes.func.isRequired,
  evapotranspirationData: PropTypes.object.isRequired,
};

NodeLocationDetail.defaultProps = {
  nodeLocation: {},
  groups: [],
  isMapDisplayed: true,
};

const mapStateToProps = (state) => ({
  newNode: getNewNode(state),
  groups: getGroups(state),
  evapotranspirationData: getEvapotranspirationData(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      update,
      setEnlargedVariant,
      deactivate,
      assignGroupsFromDetail,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NodeLocationDetail));
