import React, { Fragment } from "react";

import get from "lodash/get";
import PropTypes from "prop-types";
import { compose } from "react-recompose";

import {
  CHEM_DIALOD_TYPE,
  FERT_DIALOD_TYPE,
} from "../../../shared/containers/ChemFertSelector/ChemFertSelector";
import actionExpenseControl from "../../../shared/hocs/actionExpenseControl";
import ActionExpensesList from "../../components/ActionExpensesList/ActionExpensesList";
import ChemistryDialog from "../../components/ChemistryDialog/ChemistryDialog";
import EphFertilizerDialog from "../EphFertilizerDialog/EphFertilizerDialog";

export function EphExpensesControl(props) {
  const {
    expense,
    fields,
    formName,
    isAdding,
    isEditing,
    isExisting,
    isOpen,
    listRerenderSwitch,
    onAccept,
    onClose,
    onOpen,
    onRemove,
    parcelsArea,
    targetCrop,
  } = props;

  const chemDialogIsOpen =
    get(expense, "material.materialTypeId") === CHEM_DIALOD_TYPE && isOpen;
  const fertDialogIsOpen =
    get(expense, "material.materialTypeId") === FERT_DIALOD_TYPE && isOpen;

  return (
    <Fragment>
      <ChemistryDialog
        adding={isAdding}
        chemistry={expense}
        editing={isEditing}
        onAccept={onAccept}
        onClose={onClose}
        opened={chemDialogIsOpen}
        parcelsArea={parcelsArea}
        targetCrop={targetCrop}
      />
      <EphFertilizerDialog
        adding={isAdding}
        editing={isEditing}
        fertilizer={expense}
        onAccept={onAccept}
        onClose={onClose}
        opened={fertDialogIsOpen}
        parcelsArea={parcelsArea}
        rootState={formName}
      />
      <ActionExpensesList
        component={ActionExpensesList}
        editing={isEditing}
        fields={fields}
        formName={formName}
        isExisting={isExisting}
        onDialogOpen={onOpen}
        onItemRemove={onRemove}
        parcelsArea={parcelsArea}
        rerenderSwitch={listRerenderSwitch}
      />
    </Fragment>
  );
}

EphExpensesControl.propTypes = {
  parcelsArea: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isExisting: PropTypes.bool.isRequired,
  listRerenderSwitch: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  targetCrop: PropTypes.object,
  fields: PropTypes.object.isRequired,
  expense: PropTypes.object,
};

EphExpensesControl.defaultProps = {
  expense: null,
  targetCrop: null,
};

export default compose(actionExpenseControl())(EphExpensesControl);
