import { createSelector } from "reselect";

import { getSelectedNamespace } from "../reducers/namespace.reducer";

import { CataloguesState } from "../../reducers/catalogues.reducer.types";

export const getUIState = (state: CataloguesState) => state.ui;

export const getSectionListDateFrom = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return new Date().toISOString();
    return ui[namespace].dateFrom;
  },
);

export const getSectionListDateTo = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return new Date().toISOString();
    return ui[namespace].dateTo;
  },
);

export const getSectionListOrder = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return "asc";
    return ui[namespace].order;
  },
);

export const getSectionListOrderBy = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return "id";
    return ui[namespace].orderBy;
  },
);

export const getSectionListPage = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return 0;
    return ui[namespace].page;
  },
);

export const getSectionListRowsPerPage = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return 10;
    return ui[namespace].rowsPerPage;
  },
);

export const getSectionListSearch = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return "";
    return ui[namespace].search;
  },
);

export const getSectionListAdvancedFilter = createSelector(
  getUIState,
  getSelectedNamespace,
  (ui, namespace) => {
    if (!namespace) return "";
    return ui[namespace].advancedFilter;
  },
);
