import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getGroups } from "../../../shared/api/iot/groups/groups.selectors";

import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";

export const NodeLocationSelectGroups = ({
  defaultGroups = [],
  label,
  onChange,
  suggestions,
}) => (
  <CfAutocomplete
    defaultValues={defaultGroups}
    getSelected={(option, value) => option.id === value?.id}
    id="group-select"
    isMultiple={true}
    label={label}
    onChange={onChange}
    suggestions={suggestions}
    testId="group-select"
  />
);

NodeLocationSelectGroups.propTypes = {
  defaultGroups: PropTypes.array,
  suggestions: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  suggestions: getGroups(state),
});

export default connect(mapStateToProps)(NodeLocationSelectGroups);
