import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";

import HorizontalScrolling from "../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling";
import HorizontalScrollingItem from "../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem";
import Localization from "../../../../shared/services/Localization.service";
import ParcelSowingPlanTable from "../../../parcels/detail/overview/components/ParcelSowingPlanTable";

const styles = (theme) => ({
  wrapper: {
    width: "100%",
    height: 120,
    padding: "10px 0px",
    lineHeight: 1.7,
  },
  detailRowLabel: {
    color: theme.palette.grey[500],
  },
  detailRowValue: {
    fontWeight: 500,
  },
});

class MapParcelDetailBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshScrolling: false,
    };
  }

  componentDidMount() {
    // wait until dom elements are drawn
    setTimeout(() => {
      this.setState({
        refreshScrolling: !this.state.refreshScrolling,
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.parcel &&
      (!prevProps.parcel || this.props.parcel.id !== prevProps.parcel.id)
    ) {
      // wait until dom elements are drawn
      setTimeout(() => {
        this.setState({
          refreshScrolling: !this.state.refreshScrolling,
        });
      }, 100);
    }
  }

  getLastParcelActionText(parcel, isFetching) {
    if (isFetching) {
      return "...";
    }
    return parcel.lastAction ? parcel.lastAction.actionType.name : "-";
  }

  renderParcelDetailSection = (sectionRows) => (
    <div
      data-test="parcel-detail"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div style={{ marginRight: 15 }}>
        {sectionRows.map((row) => (
          <div
            className={this.props.classes.detailRowLabel}
            key={`${row.label}-label`}
          >
            {row.label ? <FormattedMessage id={row.label} /> : "\u00A0"}
          </div>
        ))}
      </div>
      <div style={{ marginRight: 30 }}>
        {sectionRows.map((row) => (
          <div
            className={this.props.classes.detailRowValue}
            key={`${row.label}-value`}
          >
            {row.value ? row.value : "\u00A0"}
          </div>
        ))}
      </div>
    </div>
  );

  render() {
    const {
      assignCropSowingPlan,
      classes,
      countryCode,
      intl,
      isCurrentStateSowingPlanSelected,
      isFetching,
      parcel,
      parcelSowingPlan,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        {isCurrentStateSowingPlanSelected && (
          <HorizontalScrolling refreshScrolling={this.state.refreshScrolling}>
            <HorizontalScrollingItem>
              {this.renderParcelDetailSection([
                {
                  label: "common.crop",
                  value: parcel.seedApplication?.seed?.crop?.name ?? (
                    <FormattedMessage id="common.noCrop" />
                  ),
                },
                {
                  label: "common.area",
                  value: `${Localization.num2str(parcel.area, intl.locale)} ha`,
                },
                {
                  label: "common.culture",
                  value: parcel.lpisBlock.landUseName,
                },
              ])}
            </HorizontalScrollingItem>
            <HorizontalScrollingItem>
              {this.renderParcelDetailSection([
                {
                  label: "ParcelDetail.lastAction",
                  value: this.getLastParcelActionText(parcel, isFetching),
                },
                {
                  label: "common.validFrom",
                  value: (
                    <FormattedDate
                      day="numeric"
                      month="numeric"
                      value={parcel.validFrom}
                      year="numeric"
                    />
                  ),
                },
                {
                  label: "common.zone",
                  value: parcel.zone ? parcel.zone.name : "-",
                },
              ])}
            </HorizontalScrollingItem>
            <HorizontalScrollingItem>
              {this.renderParcelDetailSection([
                {
                  label: "common.altitude",
                  value: `${Localization.num2str(
                    parcel.lpisBlock.altitude,
                    intl.locale,
                  )} m`,
                },
                {
                  label: "common.slope",
                  value: `${Localization.num2str(
                    parcel.lpisBlock.slope,
                    intl.locale,
                  )}\xB0`,
                },
                ...(countryCode === "CZ"
                  ? [
                      {
                        label: "term.NSA",
                        value: (
                          <FormattedMessage
                            id={`common.${
                              parcel.nitrateVulnerable ? "yes" : "no"
                            }`}
                          />
                        ),
                      },
                    ]
                  : [{}]),
              ])}
            </HorizontalScrollingItem>
          </HorizontalScrolling>
        )}
        {!isCurrentStateSowingPlanSelected && (
          <HorizontalScrolling refreshScrolling={this.state.refreshScrolling}>
            <ParcelSowingPlanTable
              onCropChange={assignCropSowingPlan}
              parcel={parcel}
              parcelSowingPlan={parcelSowingPlan}
            />
          </HorizontalScrolling>
        )}
      </div>
    );
  }
}

MapParcelDetailBody.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
  parcelSowingPlan: PropTypes.array.isRequired,
  assignCropSowingPlan: PropTypes.func.isRequired,
  isCurrentStateSowingPlanSelected: PropTypes.bool.isRequired,
};

export default injectIntl(withStyles(styles)(MapParcelDetailBody));
