export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";

export const SAVE_ACCOUNT = "SAVE_ACCOUNT";
export const SAVE_ACCOUNT_SUCCESS = "SAVE_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_ERROR = "SAVE_ACCOUNT_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_ERROR = "CHECK_EMAIL_ERROR";

export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const REGISTER_ACCOUNT_SUCCESS = "REGISTER_ACCOUNT_SUCCESS";
export const REGISTER_ACCOUNT_ERROR = "REGISTER_ACCOUNT_ERROR";
