import React, { FC, useMemo } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Formik, FormikErrors } from "formik";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { editMachine } from "../../../actions/telematicsCatalogues.actions";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import { AsyncFn, Thunk } from "../../../../types";
import { getMachineIcon } from "../../../helpers/selectorHelpers";
import TCRow from "../shared/TCRow";

import { COLUMN_NAMES } from "./machines.columns";
import MachinesListEditDialogForm from "./MachinesListEditDialogForm";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  MachineGroupCategoryTo,
  MachineGroupCode,
  MachineTo,
} from "../../../../shared/api/telematics/telematics.types";

interface Props {
  data: MachineTo;
  editMachine: (id: number, data: MachineListFormValues) => void;
  onEditSuccess: () => void;
}

export interface MachineListFormValues {
  gpsUnit: string;
  group: MachineGroupCode;
  licencePlate: string;
  machineCategory: Pick<MachineGroupCategoryTo, "code" | "name">;
  name: string;
  productionOperation?: {
    code?: string;
    name?: string;
  };
  workingWidth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  groupName: {
    marginLeft: theme.spacing(1),
  },
  machineName: {
    fontWeight: 500,
    marginBottom: 4,
  },
  machineCategory: {
    color: (isHistorical) =>
      isHistorical ? theme.palette.error.main : theme.palette.grey[500],
  },
  operation: {
    paddingRight: theme.spacing(4),
  },
}));

const handleValidate = (values: MachineListFormValues) => {
  const errors: FormikErrors<Partial<MachineListFormValues>> = {};
  const workingWidthAsNumber = Number(values.workingWidth);
  if (!values.name) {
    errors.name = "validation.required";
  }
  if (!values.licencePlate) {
    errors.licencePlate = "validation.required";
  }
  if (!values.workingWidth && typeof values.workingWidth !== "number") {
    errors.workingWidth = "validation.required";
  }
  if (workingWidthAsNumber < 0 || workingWidthAsNumber > 50) {
    errors.workingWidth = "Catalogues.validation.workingWidth";
  }

  if (!values.machineCategory) {
    errors.machineCategory = "validation.required";
  }

  if (!values.productionOperation?.code) {
    errors.productionOperation = "validation.required";
  }
  return errors;
};

const MachinesListRow: FC<Props> = ({ data, editMachine, onEditSuccess }) => {
  let isHistoricalItem = false;

  if (data.validTo) {
    const isEarlierThanToday =
      new Date().getTime() > new Date(data?.validTo).getTime();
    isHistoricalItem = isEarlierThanToday;
  }

  const classes = useStyles(isHistoricalItem);

  const initialValues = useMemo(
    () => ({
      gpsUnit: data.gpsUnit,
      machineCategory: {
        code: data.category,
        name: data.categoryName ?? "-",
      },
      group: data.group,
      name: data.name ?? "",
      workingWidth: data.workingWidth,
      licencePlate: data.licencePlate ?? "",
      productionOperation: {
        code: data.operation?.id,
        name: data.operation?.name,
      },
    }),
    [data],
  );

  const handleSubmit = async (values: MachineListFormValues) => {
    // TODO catalogues: fix swagger
    if (!data.id) return;
    const res = await (editMachine as AsyncFn<number, MachineListFormValues>)(
      data.id,
      values,
    );
    if (res.error) {
      return Promise.reject({ messageId: "Catalogues.table.shared.editError" });
    } else {
      return Promise.resolve({ handleSuccessFn: onEditSuccess });
    }
  };

  return (
    <Formik<MachineListFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      <TCRow
        dialogContent={<MachinesListEditDialogForm />}
        dialogTitle="Catalogues.table.machines.dialog.title"
        isHistorical={isHistoricalItem}
      >
        <CfTableCell name={COLUMN_NAMES.GPS_UNIT}>{data.gpsUnit}</CfTableCell>
        <CfTableCell name={COLUMN_NAMES.NAME}>
          <div className={classes.machineName}>{data.name ?? "-"}</div>
          <div className={classes.machineCategory}>
            {data.categoryName ?? "-"}
          </div>
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.LICENCE_PLATE}>
          {data.licencePlate ?? "-"}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.GROUP}>
          <div>
            <img alt="icon" src={getMachineIcon(data.group)} />
            <span className={classes.groupName}>{data.groupName ?? "-"}</span>
          </div>
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.WORKING_WIDTH}>
          {typeof data.workingWidth === "number" ? (
            <CfFormattedNumber decimalDigits={2} value={data.workingWidth} />
          ) : (
            "-"
          )}
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.operation }}
          name={COLUMN_NAMES.PRODUCTION_OPERATION}
        >
          {data.operation?.name ?? "-"}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_FROM}>
          {data.validFrom ? <FormattedDate value={data.validFrom} /> : "-"}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_TO}>
          {data.validTo ? <FormattedDate value={data.validTo} /> : "-"}
        </CfTableCell>
      </TCRow>
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      editMachine,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(MachinesListRow);
