import { Style, Icon, Fill, Stroke } from "ol/style";

export const AREAS_MAP_STYLES = {
  STROKE_COLOR: "#A9C3FB",
  HOVER_COLOR: "rgba(255, 255, 255, 0.40)",
  CIRCLE_COLOR: "#296AF6",
  TEXT_COLOR: "#000000",
  FONT: "700 12px Roboto",
  RADIUS_LARGE: 13,
  RADIUS_SMALL: 7,
  OFFSET_Y: -18,
};

export const getStrokeStyle = () =>
  new Stroke({
    color: AREAS_MAP_STYLES.STROKE_COLOR,
    width: 2,
  });

export const getFillStyle = (color: string) =>
  new Fill({
    color,
  });

export const getIconStyle = (iconSrc: string) =>
  new Style({
    image: new Icon({
      src: iconSrc,
      size: [64, 64],
    }),
  });
