import React, { Component, Fragment } from "react";

import Popover from "@mui/material/Popover";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { compose } from "react-recompose";

import ToolbarIconBtn from "../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import withPopover from "../../../../shared/hocs/withPopover";
import LayersIcon from "../../../../shared/icons/LayersIcon";
import CropSwitcher from "../../containers/CropSwitcher/CropSwitcher";
import LayerSwitcher from "../../containers/LayerSwitcher/LayerSwitcher";

const styles = (theme) => ({
  layerList: {
    marginTop: 0,
    marginLeft: -4,
  },
  separator: {
    height: 1,
    backgroundColor: theme.palette.grey[100],
  },
  wrapper: {
    marginRight: 8,
  },
});

class LayersCropsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layersExpanded: true,
      cropsExpanded: false,
    };
  }

  handleLayersExpansion = () => {
    this.setState({
      layersExpanded: !this.state.layersExpanded,
      cropsExpanded: false,
    });
    setTimeout(() => {
      this.actions.updatePosition();
    }, 0);
  };

  handleCropsExpansion = () => {
    this.setState({
      cropsExpanded: !this.state.cropsExpanded,
      layersExpanded: false,
    });
    setTimeout(() => {
      this.actions.updatePosition();
    }, 0);
  };

  render() {
    const {
      anchorEl,
      classes,
      handlePopoverClose,
      handlePopoverOpen,
      isOpen,
      withCrops,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <ToolbarSection>
          <ToolbarIconBtn
            active={isOpen}
            callback={handlePopoverOpen}
            data-test="layer-switcher"
            icon={LayersIcon}
            product-fruits="layers"
            tooltipTitle="LayerSwitcher.layers"
            withBackground={true}
          />
        </ToolbarSection>
        <Popover
          anchorEl={anchorEl}
          className={classes.layerList}
          disableScrollLock={true}
          onClose={handlePopoverClose}
          open={isOpen}
          action={(actions) => {
            this.actions = actions;
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <LayerSwitcher
            expanded={this.state.layersExpanded}
            handleExpansion={this.handleLayersExpansion}
            testId="layers"
          />
          {withCrops && (
            <Fragment>
              <div className={classes.separator} />
              <CropSwitcher
                expanded={this.state.cropsExpanded}
                handleExpansion={this.handleCropsExpansion}
                testId="crops"
              />
            </Fragment>
          )}
        </Popover>
      </div>
    );
  }
}

LayersCropsControl.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  withCrops: PropTypes.bool,
};

LayersCropsControl.defaultProps = {
  withCrops: true,
  anchorEl: null,
};

export default compose(withStyles(styles), withPopover)(LayersCropsControl);
