import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import reducer from "../../reducers/impersonation.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";

import ImpersonationWrapper from "./containers/ImpersonationWrapper/ImpersonationWrapper";

ImpersonationController.$inject = [
  "$element",
  "$scope",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Localization",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function ImpersonationController(
  $element,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    reducer,
    langId: null,
    ngImpersDisable: this.impersDisable,
    ngIsImpersActive: Auth.isImpersActive,
    ngImpersActivate: Auth.impersActivate,
    ngImpersDeactivate: Auth.impersDeactivate,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      langId: null,
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = () => {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    Localization.getCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  };

  function render() {
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <ImpersonationWrapper {...p} />} />,
      $element[0],
    );
  }
}
