import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfInfoDialog from "../../../shared/components/common/CfInfoDialog/CfInfoDialog";

const style = () => ({
  container: {
    marginBottom: "10px",
  },
  infoButton: {
    margin: "0px 6px",
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
});

const EvapotranspirationInfoDialog = (props) => {
  const { classes } = props;
  return (
    <CfInfoDialog
      classes={{ infoButton: classes.infoButton, infoIcon: classes.infoIcon }}
      heading={<FormattedMessage id="EvapotranspirationInfoDialog.head" />}
    >
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage id="EvapotranspirationInfoDialog.desc" />
          </Typography>
        </Grid>
      </Grid>
    </CfInfoDialog>
  );
};

EvapotranspirationInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(EvapotranspirationInfoDialog);
