import {
  getNotificationListOrder,
  getNotificationListOrderBy,
  getNotificationListPage,
  getNotificationListRowsPerPage,
  getNotificationListTextFilter,
} from "../selectors/notificationList.selectors";

import {
  getRules as getRulesApi,
  getRule as getRuleApi,
  deleteRules as deleteRulesApi,
  updateRule as updateRuleApi,
  createRule as createRuleApi,
  patchRule as patchRuleApi,
  resetRule as resetRuleApi,
} from "../../shared/api/automation/rules/rules.api";
import { getUsers as getUsersApi } from "../../shared/api/gateway/users/users.api";
import {
  getLocations as getLocationsApi,
  getLocation as getLocationApi,
} from "../../shared/api/iot/locations/locations.api";
import { getNodeTypes } from "../../shared/api/iot/nodes/nodes.api";

export const fetchRules = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    page: getNotificationListPage(state) + 1,
    "per-page": getNotificationListRowsPerPage(state),
    "sort-dir": getNotificationListOrder(state),
    "sort-col": getNotificationListOrderBy(state),
    search: getNotificationListTextFilter(state),
  };
  dispatch(getRulesApi(params));
};

export const fetchUnfilteredRules = () => (dispatch) => {
  dispatch(getRulesApi());
};

export const fetchUsers = (farmId) => (dispatch) => {
  dispatch(getUsersApi(farmId));
};

export const fetchLocations = () => (dispatch) => {
  dispatch(getLocationsApi({}));
};

export const fetchLocation = (nodeLocationId) => (dispatch) => {
  dispatch(getLocationApi(nodeLocationId));
};

export const fetchRule = (ruleId) => (dispatch) => {
  dispatch(getRuleApi(ruleId));
};

export const resetRule = () => (dispatch) => {
  dispatch(resetRuleApi());
};

export const createRule = (rule, farmId) => (dispatch) => {
  const ruleTo = mapNotificationState(rule, farmId);
  return dispatch(createRuleApi(ruleTo));
};

export const updateRule = (ruleId, rule, farmId) => (dispatch) => {
  const ruleTo = mapNotificationState(rule, farmId);
  ruleTo.id = ruleId;
  return dispatch(updateRuleApi(ruleId, ruleTo));
};

export const deleteRules = (rulesIds) => (dispatch) => {
  dispatch(deleteRulesApi(rulesIds)).then((res) => {
    if (!res.error) {
      dispatch(fetchRules());
    }
  });
};

export const patchRule = (ruleId, enabled) => (dispatch) => {
  const newRuleProperties = {
    enabled,
  };
  dispatch(patchRuleApi(ruleId, newRuleProperties));
};

export const fetchDeviceTypes = () => (dispatch) => {
  dispatch(getNodeTypes());
};

const mapNotificationState = (rule, farmId) => ({
  sensorIds: [rule.sensor.node.externalId],
  conditions: [
    {
      feature: rule.feature,
      operator: rule.conditionType,
      value: rule.conditionValue,
    },
  ],
  farmId,
  name: rule.description,
  userIds: rule.users.map((user) => user.userId),
});
