import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getSensorsAdminAdvancedFilter } from "../../selectors/sensors.selectors";

import { SensorFilterByNodeType } from "../../../../../shared/components/specific/SensorFilterByNodeType/SensorFilterByNodeType";
import { SensorFilterByStatus } from "../../../../../shared/components/specific/SensorFilterByStatus/SensorFilterByStatus";
import CfFilter from "../../../../../shared/containers/CfFilter/CfFilter";
import SensorAdminFilterByNetworkType from "../../components/SensorAdminFilterByNetworkType/SensorAdminFilterByNetworkType";

const styles = () => ({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});
export class SensorAdminFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedFilter: {},
    };
  }

  setFilterPart = (filterPart, items) => {
    if (filterPart === null) {
      return {};
    }
    const currentAdvancedFilter = this.state.advancedFilter;
    delete currentAdvancedFilter[filterPart];
    return {
      ...currentAdvancedFilter,
      [filterPart]: items,
    };
  };

  setFilterState = (newValues) => {
    this.setState({
      advancedFilter: newValues,
    });
  };

  handleChange = (filterPart, items) => {
    const newValues = this.setFilterPart(filterPart, items);
    this.setFilterState(newValues);
  };

  render() {
    const {
      advancedFilter,
      classes,
      langId,
      namespace,
      networkTypes,
      nodeTypes,
    } = this.props;
    return (
      <CfFilter
        filterState={this.state.advancedFilter}
        langId={langId}
        namespace={namespace}
        setAdvancedFilterState={this.setFilterState}
      >
        <div className={classes.container}>
          <Grid container>
            <Grid className={classes.select} item xs={12}>
              <SensorFilterByStatus
                defaultStatus={advancedFilter.status}
                label={<FormattedMessage id="common.state" />}
                onChange={(value) => this.handleChange("status", value)}
              />
            </Grid>
            <Grid className={classes.select} item xs={12}>
              <SensorFilterByNodeType
                defaultNodeType={advancedFilter.nodeType}
                label={<FormattedMessage id="common.type" />}
                nodeTypes={nodeTypes}
                onChange={(value) => this.handleChange("nodeType", value)}
              />
            </Grid>
            <Grid className={classes.select} item xs={12}>
              <SensorAdminFilterByNetworkType
                label={<FormattedMessage id="SensorsAdmin.network" />}
                networkTypes={networkTypes}
                selectedNetworkType={advancedFilter.networkType}
                setNetworkTypeFilter={this.handleChange}
              />
            </Grid>
          </Grid>
        </div>
      </CfFilter>
    );
  }
}

SensorAdminFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  namespace: PropTypes.string,
  advancedFilter: PropTypes.object,
  langId: PropTypes.string.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  networkTypes: PropTypes.array.isRequired,
};

SensorAdminFilter.defaultProps = {
  anchorEl: null,
  namespace: null,
  advancedFilter: {},
};

const mapStateToProps = (state) => ({
  advancedFilter: getSensorsAdminAdvancedFilter(state),
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(SensorAdminFilter));
