import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";

import { Theme, Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getMainMapTelematicsDrivers,
  getMainMapTelematicsIsFetchingDrivers,
} from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors";

import { fetchDrivers } from "../../actions/mainMapTelematics/mainMapTelematics.actions";

import { resetDrivers } from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.api";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../types";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import { DriverTo } from "../../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  driverCode: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface MainMapTelematicsDriverSelectorProps {
  defaultValues?: DriverTo[];
  fetchDrivers(): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: DriverTo[]): void;
  options: DriverTo[];
  resetDrivers(): void;
}

const defaultDefaultValues: DriverTo[] = [];

const handleGetSelected = (option: DriverTo, value?: DriverTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const MainMapTelematicsDriverSelector: FC<
  MainMapTelematicsDriverSelectorProps
> = ({
  defaultValues = defaultDefaultValues,
  fetchDrivers,
  isFetching,
  label,
  onChange,
  options,
  resetDrivers,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const mergedOptions = useMemo(
    () => [
      {
        code: "nodriver",
        name: intl.formatMessage({
          id: "MainMapTelematics.filter.withoutDriver",
        }),
      },
      ...options,
    ],
    [intl, options],
  );

  useEffect(() => {
    fetchDrivers();
    return () => {
      resetDrivers();
    };
  }, [fetchDrivers, resetDrivers]);

  const handleRenderOption = useCallback(
    (option: DriverTo) => (
      <div>
        <div>{option.name ?? option.code}</div>
        <div className={classes.driverCode}>
          {option.code === "nodriver" ? "-" : option.code}
        </div>
      </div>
    ),
    [classes.driverCode],
  );

  const handleRenderTags = useCallback(
    (values: DriverTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? value.code}
            label={
              <>
                {value.name ?? value.code}{" "}
                <span className={classes.driverCode}>
                  {value.code === "nodriver" ? "-" : value.code}
                </span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes.driverCode],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="main-map-telematics-driver-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={mergedOptions}
      testId="main-map-telematics-driver-filter"
    />
  );
};

const mapStateToProps = (state: MainMapState) => ({
  options: getMainMapTelematicsDrivers(state),
  isFetching: getMainMapTelematicsIsFetchingDrivers(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      fetchDrivers,
      resetDrivers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMapTelematicsDriverSelector);
