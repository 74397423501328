import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import SensorsPestPredictionService from "../../services/SensorsPestPrediction.service";
import PestPredictionGraphTooltip from "../PestPredictionGraphTooltip/PestPredictionGraphTooltip";

const CIRCLE_DIAMETER = 12;
const HOVER_BUFFER = 4;

const styles = () => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
  },
  popper: {
    opacity: 1,
  },
  hoverCircle: {
    height: CIRCLE_DIAMETER + HOVER_BUFFER * 2,
    position: "absolute",
    top: -HOVER_BUFFER,
    visible: false,
  },
  circle: {
    height: CIRCLE_DIAMETER,
    borderRadius: 50,
    position: "absolute",
    top: HOVER_BUFFER,
    left: HOVER_BUFFER,
  },
});

function PestPredictionGraphOccurrences(props) {
  const {
    classes,
    data,
    graphWidth,
    isAdditional,
    occurrenceValues,
    pestPredictionName,
  } = props;
  const tooltipMessage =
    SensorsPestPredictionService.getTranslationIdForTooltip(
      pestPredictionName,
      isAdditional,
    );
  const circleColor = isAdditional ? "#676767" : "#B1B1B1";
  const k = (graphWidth - CIRCLE_DIAMETER) / (data.length - 1);
  let duration = 1;
  let firstDay = 1;
  return data.map((occurrence, index) => {
    if (occurrenceValues.includes(occurrence.value)) {
      if (duration === 1) {
        firstDay = data.indexOf(occurrence) + 1;
      }
      if (
        index !== data.length - 1 &&
        occurrenceValues.includes(data[index + 1].value)
      ) {
        duration += 1;
        return false;
      }
      const width = CIRCLE_DIAMETER + (duration - 1) * k;
      const left = (firstDay - 1) * k;
      return (
        <Tooltip
          key={occurrence.date}
          classes={{
            tooltip: classes.tooltip,
            popper: classes.popper,
          }}
          title={
            <PestPredictionGraphTooltip
              day={data[firstDay - 1].date}
              dayCount={duration}
              messageId={tooltipMessage}
            />
          }
        >
          <span
            className={classes.hoverCircle}
            data-test="occurrence"
            key={occurrence.date}
            style={{
              width: width + HOVER_BUFFER * 2,
              left: left - HOVER_BUFFER,
            }}
          >
            <span
              className={classes.circle}
              style={{ width, background: circleColor }}
            >
              {}
            </span>
          </span>
        </Tooltip>
      );
    }
    duration = 1;
    return false;
  });
}

PestPredictionGraphOccurrences.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  graphWidth: PropTypes.number.isRequired,
  pestPredictionName: PropTypes.string.isRequired,
  occurrenceValues: PropTypes.array,
  isAdditional: PropTypes.bool,
};

PestPredictionGraphOccurrences.defaultProps = {
  classes: {},
  occurrenceValues: [1],
  isAdditional: false,
};

export default withStyles(styles)(PestPredictionGraphOccurrences);
