import React, { FC, ReactNode } from "react";

import { Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  hint: {
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export interface SatelliteImagesInfoDialogProps {
  children?: ReactNode;
}

const SatelliteImagesInfoDialog: FC<SatelliteImagesInfoDialogProps> = ({
  children = null,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.hint}>
        <Typography>
          <FormattedMessage id="SatelliteImagesInfoDialog.hint" />
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default SatelliteImagesInfoDialog;
