import moment from "moment";
import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./reports.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";
import * as coreTypes from "../../core/reports/reports.constants";

export type FertilizerUsageReportParams = {
  from: string;
  to: string;
  type: string;
};

export type PorUsageReportParams = {
  toDate: string;
};

type ReportGetParams = {
  format: "pdf" | "xlsx";
  from: string;
  id: "actions";
  parcelIds?: string[];
  to: string;
};

type FertilizerUsageReportGetParams = ReportGetParams;
type SeededAreasReportGetParams = ReportGetParams;

export const postFertilizerUsageReportApi = (
  params: FertilizerUsageReportParams,
) => ({
  [RSAA]: {
    endpoint: "reports/eagri/fertilizer-usage?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.POST_FERTILIZER_USAGE_REPORT_REQUEST,
      types.POST_FERTILIZER_USAGE_REPORT_SUCCESS,
      types.POST_FERTILIZER_USAGE_REPORT_ERROR,
    ],
  },
});

export const getFertilizerUsageReportApi = (
  params: FertilizerUsageReportGetParams,
) => {
  const p = {
    "date-from": moment(params.from).format("YYYY-MM-DD"),
    "date-to": moment(params.to).format("YYYY-MM-DD"),
    "parcel-ids": params.parcelIds,
    "file-type": params.format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/fertilizer-usage?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        coreTypes.GET_REPORT,
        {
          type: coreTypes.GET_REPORT_SUCCESS,
          payload: (
            _action: unknown,
            _state: unknown,
            res: {
              blob: () => unknown;
              headers: { get: (h: string) => unknown };
              status: string;
            },
          ) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id: params.id,
          }),
        },
        coreTypes.GET_REPORT_ERROR,
      ],
    },
  };
};

export const getSeededAreasReportApi = (params: SeededAreasReportGetParams) => {
  const p = {
    "date-from": moment(params.from).format("YYYY-MM-DD"),
    "date-to": moment(params.to).format("YYYY-MM-DD"),
    "parcel-ids": params.parcelIds,
    "file-type": params.format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/seeded-areas?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        coreTypes.GET_REPORT,
        {
          type: coreTypes.GET_REPORT_SUCCESS,
          payload: (
            _action: unknown,
            _state: unknown,
            res: {
              blob: () => unknown;
              headers: { get: (h: string) => unknown };
              status: string;
            },
          ) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id: params.id,
          }),
        },
        coreTypes.GET_REPORT_ERROR,
      ],
    },
  };
};

export const getPlantProtectionUsageReportApi = (params: ReportGetParams) => {
  const p = {
    "date-from": moment(params.from).format("YYYY-MM-DD"),
    "date-to": moment(params.to).format("YYYY-MM-DD"),
    "parcel-ids": params.parcelIds,
    "file-type": params.format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/plant-protection-usage?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        coreTypes.GET_REPORT,
        {
          type: coreTypes.GET_REPORT_SUCCESS,
          payload: (
            _action: unknown,
            _state: unknown,
            res: {
              blob: () => unknown;
              headers: { get: (h: string) => unknown };
              status: string;
            },
          ) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id: params.id,
          }),
        },
        coreTypes.GET_REPORT_ERROR,
      ],
    },
  };
};

export const getActionsReportApi = (params: SeededAreasReportGetParams) => {
  const p = {
    "date-from": moment(params.from).format("YYYY-MM-DD"),
    "date-to": moment(params.to).format("YYYY-MM-DD"),
    "parcel-ids": params.parcelIds,
    "file-type": params.format.toUpperCase(),
  };

  return {
    [RSAA]: {
      endpoint: `reports/actions?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        coreTypes.GET_REPORT,
        {
          type: coreTypes.GET_REPORT_SUCCESS,
          payload: (
            _action: unknown,
            _state: unknown,
            res: {
              blob: () => unknown;
              headers: { get: (h: string) => unknown };
              status: string;
            },
          ) => ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
            status: res.status,
            id: params.id,
          }),
        },
        coreTypes.GET_REPORT_ERROR,
      ],
    },
  };
};

export const postPorUsageReportApi = (params: PorUsageReportParams) => ({
  [RSAA]: {
    endpoint: `reports/eagri/por-usage?${new URLSearchParams(params)}&`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    types: [
      types.POST_POR_USAGE_REPORT_REQUEST,
      types.POST_POR_USAGE_REPORT_SUCCESS,
      types.POST_POR_USAGE_REPORT_ERROR,
    ],
  },
});

export interface GetPorUsageReportParams {
  page: number;
  rowsPerPage: number;
}

export const getPorUsageReportApi = (params: GetPorUsageReportParams) => {
  const { page, rowsPerPage } = params;

  const urlParams = {
    page: (page + 1).toString(),
    pageSize: rowsPerPage.toString(),
    type: "POR_USE",
  };

  return {
    [RSAA]: {
      endpoint: `reports/history?${new URLSearchParams(urlParams)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_POR_HISTORY_REPORT_REQUEST,
        types.GET_POR_HISTORY_REPORT_SUCCESS,
        types.GET_POR_HISTORY_REPORT_ERROR,
      ],
    },
  };
};
