export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_ERROR = "GET_GROUPS_ERROR";
export const RESET_GROUPS = "RESET_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";
export const POST_GROUP = "POST_GROUP";
export const POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS";
export const POST_GROUP_ERROR = "POST_GROUP_ERROR";
