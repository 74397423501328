import { AdminState } from "../../../../reducers/admin.reducer.types";

export const getFarmsAdminOrder = (state: AdminState) =>
  state.ui.farmsAdmin.order;
export const getFarmsAdminOrderBy = (state: AdminState) =>
  state.ui.farmsAdmin.orderBy;
export const getFarmsAdminPage = (state: AdminState) =>
  state.ui.farmsAdmin.page;
export const getFarmsAdminRowsPerPage = (state: AdminState) =>
  state.ui.farmsAdmin.rowsPerPage;
export const getFarmsAdminTextFilter = (state: AdminState) =>
  state.ui.farmsAdmin.textFilter;
