export const GET_PARCEL_GEOMETRY_BY_ID = "GET_PARCEL_GEOMETRY_BY_ID";
export const GET_PARCEL_GEOMETRY_BY_ID_SUCCESS =
  "GET_PARCEL_GEOMETRY_BY_ID_SUCCESS";
export const GET_PARCEL_GEOMETRY_BY_ID_ERROR =
  "GET_PARCEL_GEOMETRY_BY_ID_ERROR";
export const RESET_PARCEL_GEOMETRY = "RESET_PARCEL_GEOMETRY";

export const SPLIT_GEOMETRY = "SPLIT_GEOMETRY";
export const SPLIT_GEOMETRY_SUCCESS = "SPLIT_GEOMETRY_SUCCESS";
export const SPLIT_GEOMETRY_ERROR = "SPLIT_GEOMETRY_ERROR";

export const GET_MERGE_NEIGHBORS = "GET_MERGE_NEIGHBORS";
export const GET_MERGE_NEIGHBORS_SUCCESS = "GET_MERGE_NEIGHBORS_SUCCESS";
export const GET_MERGE_NEIGHBORS_ERROR = "GET_MERGE_NEIGHBORS_ERROR";

export const MERGE_GEOMETRIES = "MERGE_GEOMETRIES";
export const MERGE_GEOMETRIES_SUCCESS = "MERGE_GEOMETRIES_SUCCESS";
export const MERGE_GEOMETRIES_ERROR = "MERGE_GEOMETRIES_ERROR";

export const GET_LPIS_PARCELS_BY_PARCEL_ID = "GET_LPIS_PARCELS_BY_PARCEL_ID";
export const GET_LPIS_PARCELS_BY_PARCEL_ID_SUCCESS =
  "GET_LPIS_PARCELS_BY_PARCEL_ID_SUCCESS";
export const GET_LPIS_PARCELS_BY_PARCEL_ID_ERROR =
  "GET_LPIS_PARCELS_BY_PARCEL_ID_ERROR";

export const GET_FEATURE_AREA = "GET_FEATURE_AREA";
export const GET_FEATURE_AREA_SUCCESS = "GET_FEATURE_AREA_SUCCESS";
export const GET_FEATURE_AREA_ERROR = "GET_FEATURE_AREA_ERROR";

export const BUFFER_GEOMETRY = "BUFFER_GEOMETRY";
export const BUFFER_GEOMETRY_SUCCESS = "BUFFER_GEOMETRY_SUCCESS";
export const BUFFER_GEOMETRY_ERROR = "BUFFER_GEOMETRY_ERROR";
