import moment from "moment";

import {
  ParcelDetailTo,
  ParcelTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

export const HISTORICAL_PARCEL_STATUS = "RETIRED";

export const SHOWN_NUMBER_SOWING_SEASONS = 4;
export const START_YEAR_FIRST_SOWING_SEASONS = moment().year() - 1;

export const ParcelsService = {
  isParcelHistorical: (parcel: ParcelDetailTo | ParcelTo | null | undefined) =>
    parcel && parcel.status === HISTORICAL_PARCEL_STATUS,
};
