/**
 * Created by ondrejzvara on 10.12.15.
 */

/*@ngInject*/

export default function cfFertEditor() {
  return {
    restrict: "E",
    scope: true,
    bindToController: {
      item: "=fert",
      edit: "=isEditing",
      form: "=",
    },
    template: require("./cf-fert-editor.html"),
    controller: cfFertEditorController,
    controllerAs: "FertCtrl",
  };
}

/*@ngInject*/
function cfFertEditorController($scope, FertilizersResource) {
  var vm = this;
  vm.nitrogenCategories = [];
  vm.itemBckp = {};

  $scope.$watch(
    function () {
      return vm.item;
    },
    function (newValue) {
      if (!newValue || _.isEmpty(newValue)) {
        return;
      }

      vm.item = newValue;
      vm.itemBckp = null;
    },
  );

  $scope.$watch(
    function () {
      return vm.edit;
    },
    function (newValue) {
      if (newValue) {
        vm.itemBckp = _.cloneDeep(vm.item);
      } else {
        if (vm.itemBckp) {
          vm.item = _.cloneDeep(vm.itemBckp);
        }
      }
    },
  );

  activate();

  function activate() {
    FertilizersResource.getNitrogenCategories().then(function (result) {
      vm.nitrogenCategories = result;
    });
  }
}
