import React, { Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import NodeDeviceType from "../../../shared/components/common/NodeDeviceType/NodeDeviceType";
import LastUpdate from "../../../shared/components/specific/SensorsLastUpdate/SensorsLastUpdate";
import SensorsSignalInfo from "../../../shared/components/specific/SensorsSignalInfo/SensorsSignalInfo";
import SensorsService from "../../../shared/services/Sensors.service";

const style = (theme) => ({
  wrapper: {
    fontSize: 13,
    display: "flex",
    color: theme.palette.grey[500],
    justifyContent: "center",
  },
  tooltip: {
    marginBottom: 4,
  },
  rowItem: {
    display: "flex",
    marginRight: 15,
    alignItems: "center",
  },
  itemContent: {
    display: "flex",
    alignItems: "center",
  },
  spanMessageMargin: {
    marginLeft: "3px",
  },
});

export function NodeLocationUpdateInfo(props) {
  const { classes, nodeLocation } = props;
  const { lastUpdate, signal } = nodeLocation;
  const showLastUpdate =
    SensorsService.isCurrentlyActiveWithCoordinates(nodeLocation);
  const showSignal =
    SensorsService.isCurrentlyActiveWithValidSignal(nodeLocation);

  return (
    <Fragment>
      <span className={classes.rowItem} data-test="device-type">
        <NodeDeviceType node={nodeLocation.node} />
      </span>
      {showLastUpdate && (
        <span className={classes.rowItem} data-test="last-update">
          <LastUpdate lastUpdate={lastUpdate} />
        </span>
      )}
      {showSignal && (
        <span className={classes.rowItem} data-test="sensor-signal">
          <SensorsSignalInfo signal={signal} />
          <span className={classes.spanMessageMargin}>
            <FormattedMessage id={`common.sensors-signal-strength-${signal}`} />
          </span>
        </span>
      )}
    </Fragment>
  );
}

NodeLocationUpdateInfo.propTypes = {
  nodeLocation: PropTypes.object,
  classes: PropTypes.object,
};

NodeLocationUpdateInfo.defaultProps = {
  nodeLocation: {},
  classes: {},
};

export default withStyles(style)(NodeLocationUpdateInfo);
