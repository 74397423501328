import React, { FC, ReactNode, useEffect, useCallback } from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getMachines,
  getIsFetchingMachines,
} from "../../../shared/api/telematics/drives/drives.selectors";

import { fetchMachines } from "../../actions/telematicsList.actions";

import * as sortOrder from "../../../shared/constants/sortOrder.constants";

import { resetMachines } from "../../../shared/api/telematics/drives/drives.api";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../types";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  MachineSortColumn,
  MachineTo,
} from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface TelematicsDetailMachineSelectorProps {
  dateFrom: string;
  dateTo: string;
  disabled: boolean;
  error: boolean;
  fetchMachines(
    dateFrom: string,
    dateTo: string,
    sortCol: string,
    sortDir: string,
  ): void;
  helperText?: React.ReactElement | string;
  isFetching: boolean;
  label: ReactNode;
  onChange(item: MachineTo): void;
  options: MachineTo[];
  resetMachines(): void;
  selectedGpsUnit: string;
}

const handleGetSelected = (option: MachineTo, value?: MachineTo | null) =>
  option.gpsUnit === value?.gpsUnit;

const handleGetLabel = (option?: MachineTo | null) => {
  if (!option) return "";
  const { gpsUnit, licencePlate, name } = option;
  if (name && licencePlate) {
    return `${name} (${licencePlate})`;
  } else if (name && !licencePlate) {
    return `${name}`;
  } else {
    return `${gpsUnit}`;
  }
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ licencePlate, name }) => `${name} (${licencePlate})`,
});

const TelematicsDetailMachineSelector: FC<
  TelematicsDetailMachineSelectorProps
> = ({
  dateFrom,
  dateTo,
  disabled,
  error,
  fetchMachines,
  helperText,
  isFetching,
  label,
  onChange,
  options,
  resetMachines,
  selectedGpsUnit,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    fetchMachines(dateFrom, dateTo, MachineSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetMachines();
    };
  }, [fetchMachines, resetMachines, dateFrom, dateTo]);

  const handleRenderOption = useCallback(
    (option: MachineTo) => (
      <div>
        <div>{option.name ?? option.gpsUnit}</div>
        <div className={classes.id}>
          {option.licencePlate ?? option.gpsUnit}
        </div>
      </div>
    ),
    [classes],
  );

  const selectedMachine = (selectedGpsUnit &&
    options.find((option) => option.gpsUnit === selectedGpsUnit)) || {
    gpsUnit: selectedGpsUnit,
  };

  return (
    <CfAutocomplete
      defaultValues={selectedMachine}
      disabled={disabled}
      error={error}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-machine-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="telematics-detail-machine-selector"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getMachines(state),
  isFetching: getIsFetchingMachines(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchMachines,
      resetMachines,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsDetailMachineSelector);
