import { Fill, Style as OlStyle } from "ol/style";
import CircleStyle from "ol/style/Circle";

const MIN_COLOR = "#BC0000";
const MIDDLE_COLOR = "#E2D900";
const MAX_COLOR = "#0FB900";
const FALLBACK_COLOR = "rgba(255, 255, 255, 0.2)";

const fallbackStyle = new OlStyle({
  image: new CircleStyle({
    radius: 2,
    fill: new Fill({
      color: FALLBACK_COLOR,
    }),
  }),
});

export { MIN_COLOR, MIDDLE_COLOR, MAX_COLOR, fallbackStyle };
