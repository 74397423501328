import React, { ReactNode, SyntheticEvent } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    display: "flex",
    flexWrap: "nowrap",
    bottom: 12,
  },
  snackbarContent: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.secondary.light,
  },
  snackbarContentSuccess: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  snackbarContentError: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
  },
}));

/**
 * Our wrapper for MUI Snackbar used to display information about success/error action.
 * @version 1.0.0
 */

interface ICfSnackbarProps {
  autoHideDuration?: number;
  isCloseButtonVisible?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  message: ReactNode;
  onClose: () => void;
  open: boolean;
}

const CfSnackbar = ({
  autoHideDuration = 6000,
  isCloseButtonVisible,
  isError,
  isSuccess,
  message = "",
  onClose,
  open,
}: ICfSnackbarProps) => {
  const styles = useStyles();

  const handleClose = (_: SyntheticEvent<unknown, Event>, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      className={styles.snackbar}
      message={message}
      onClose={handleClose}
      open={open}
      action={
        isCloseButtonVisible && (
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        classes: {
          root: classnames({
            [styles.snackbarContentSuccess]: isSuccess,
            [styles.snackbarContentError]: isError,
            [styles.snackbarContent]: !isSuccess && !isError,
          }),
        },
      }}
    />
  );
};

export default CfSnackbar;
