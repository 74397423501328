import React, { ChangeEvent, FC, useEffect } from "react";

import { FormControlLabel, Switch } from "@mui/material";
import download from "js-file-download";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getShowVarioDocInactive } from "../../../shared/api/sentinel/precision/precisionAsApplied.selectors";
import {
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  getPrecisionAsAppliedSelected,
  getPrecisionAsAppliedTextFilter,
  getPrecisionAsAppliedShowArchived,
} from "../selectors/precisionAsApplied.selectors";

import { setAsAppliedShowVarioDocInactive } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions";
import {
  fetchPrecisionAsApplied,
  setPrecisionAsAppliedShowArchived,
} from "../actions/precision.actions";

import { NAMESPACE as namespace } from "../reducer/precisionAsApplied.reducer";

import { extractDispositionHeader } from "../../../shared/api/api.helpers";
import { asAppliedBulkDownloadFilesApi } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import { validateVarioDocApi } from "../../../shared/api/sentinel/varioDoc/varioDoc.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { AsyncFn, RsaaApiError, Thunk } from "../../../types";

import BulkButton from "./BulkButton";
import NoVarioDoc from "./NoVarioDoc";
import PrecisionAsAppliedTable from "./PrecisionAsAppliedTable";
import { usePrecisionListStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

export interface PrecisionAsAppliedProps {
  error?: RsaaApiError;
  farmId: string;
  ngRedirectToUserIntegrations: (farmId: string) => void;
  selected: number[];
}

export const PrecisionAsApplied: FC<PrecisionAsAppliedProps & ReduxProps> = ({
  asAppliedBulkDownloadFilesApi,
  error,
  farmId,
  fetchPrecisionAsApplied,
  ngRedirectToUserIntegrations,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  setAsAppliedShowVarioDocInactive,
  setPrecisionAsAppliedShowArchived,
  showArchived,
  showVarioDocInactive,
  textFilter,
  validateVarioDocApi,
}) => {
  const classes = usePrecisionListStyles();

  useEffect(() => {
    (validateVarioDocApi as AsyncFn)().then((res) => {
      if (res.error && res.payload.status === 403) {
        setAsAppliedShowVarioDocInactive(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateVarioDocApi]);

  useEffect(() => {
    fetchPrecisionAsApplied();
  }, [
    fetchPrecisionAsApplied,
    page,
    rowsPerPage,
    order,
    orderBy,
    textFilter,
    showArchived,
  ]);

  const handleSwitchClick = (archived?: boolean) =>
    setPrecisionAsAppliedShowArchived(!!archived);

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    (asAppliedBulkDownloadFilesApi as AsyncFn<string[]>)(
      selected.map((t) => t.toString()),
    ).then((res) => {
      if (res?.error) return;
      const { body, disposition, type } = res?.payload || {};
      body?.then((b: BodyInit | null | undefined) =>
        new Response(b).blob().then((blob) => {
          download(
            blob,
            extractDispositionHeader(disposition) ?? "taskdata.zip",
            type,
          );
        }),
      );
    });
  };

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <div className={classes.varioDocWrapper}>
          {showVarioDocInactive && (
            <NoVarioDoc
              farmId={farmId}
              ngRedirectToUserIntegrations={ngRedirectToUserIntegrations}
            />
          )}
        </div>
        <div className={classes.toolbar} style={{ paddingLeft: "5px" }}>
          <div className={classes.toolbarLeft}>
            <BulkButton onDownload={handleDownloadClick} selected={selected} />
            <CfTextFilter
              initialValue={textFilter}
              name="precision-list-text-filter"
              namespace={namespace}
              translId="PrecisionFarming.search.filter"
            />
          </div>
          <FormControlLabel
            labelPlacement="start"
            classes={{
              root: classes.switchFormControlLabel,
            }}
            control={
              <Switch
                checked={showArchived}
                color="primary"
                id="show-archived"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSwitchClick(e.target.checked)
                }
              />
            }
            label={
              <FormattedMessage id="PrecisionFarming.asApplied.toolbar.showArchived" />
            }
          />
        </div>
        <PrecisionAsAppliedTable farmId={farmId} />
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionAsAppliedTextFilter(state),
  page: getPrecisionAsAppliedPage(state),
  order: getPrecisionAsAppliedOrder(state),
  orderBy: getPrecisionAsAppliedOrderBy(state),
  rowsPerPage: getPrecisionAsAppliedRowsPerPage(state),
  selected: getPrecisionAsAppliedSelected(state),
  showArchived: getPrecisionAsAppliedShowArchived(state),
  showVarioDocInactive: getShowVarioDocInactive(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      fetchPrecisionAsApplied,
      setPrecisionAsAppliedShowArchived,
      validateVarioDocApi: validateVarioDocApi as () => void,
      setAsAppliedShowVarioDocInactive,
      asAppliedBulkDownloadFilesApi: asAppliedBulkDownloadFilesApi as (
        val: string[],
      ) => void,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(PrecisionAsApplied);
