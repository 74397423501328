import { push } from "connected-react-router";
import { transform } from "ol/proj";

import { getNewDevice } from "../selectors/device.selectors";
import {
  getDevicesOrder,
  getDevicesOrderBy,
  getDevicesPage,
  getDevicesRowsPerPage,
  getDevicesTextFilter,
} from "../selectors/devices.common.selectors";

import { setCoordsSelection, zoomToDevice } from "./map.actions";

import * as types from "./map.constants";
import { IRRIGATION_URLS } from "../irrigation.constants";

import MapService from "../../core/map/services/Map.service";
import {
  getDevicesApi,
  getDeviceApi,
  getIrrigationPoints,
  patchDevice,
  getDeviceIntervalsApi,
  getDeviceValvesApi,
  valvesManualActionApi,
  getDeviceTypes,
} from "../../shared/api/irrigation/devices/devices.api";

export const activateStart = (device) => (dispatch) => {
  const coordinates = undefined;
  dispatch(setCoordsSelection(true, coordinates));
  dispatch({
    type: types.EDIT_START,
    newDevice: createDeviceVersion(device, coordinates),
  });
};

export const activateCancel = () => (dispatch) => {
  dispatch(setCoordsSelection(false));
  dispatch({
    type: types.EDIT_CANCEL,
  });
};

export const activate = (deviceId, name, geometry, farmId) => (dispatch) => {
  const transformedGeometry = {
    ...geometry,
    coordinates: transform(geometry.coordinates, "EPSG:3857", "EPSG:4326"),
  };
  const deviceProps = {
    name,
    geometry: transformedGeometry,
    status: "ACTIVE",
  };
  return dispatch(patchDevice(deviceId, deviceProps)).then((res) => {
    // angular related hack
    setTimeout(() => {
      if (res?.payload?.id) {
        dispatch(
          push(`/farm/${farmId}/${IRRIGATION_URLS.DEVICE}/${res.payload.id}`),
        );
      }
      dispatch(getIrrigationPoints());
    }, 0);
  });
};

export const updateCoordsFromMap = (coords) => (dispatch, getState) => {
  const state = getState();
  dispatch(setCoordsSelection(true, coords));
  dispatch({
    type: types.EDIT_UPDATE,
    newDeviceVersion: createDeviceVersion(getNewDevice(state), coords),
  });
};

export const updateCoordsFromForm = (coordsWgs) => (dispatch) => {
  const coords = coordsWgs ? MapService.transformFromWgs(coordsWgs) : null;
  dispatch(setCoordsSelection(true, coords));
};

const createDeviceVersion = (device, coords) => {
  const newDeviceVersion = {
    ...device,
  };

  if (coords) {
    newDeviceVersion.geometry = {
      ...device.geometry,
      coordinates: coords.slice(0),
      type: "Point",
    };
    newDeviceVersion.geometryWgs = {
      ...device.geometry,
      coordinates: MapService.transformToWgs(coords),
      type: "Point",
    };
  }

  return newDeviceVersion;
};

export const fetchDevices = (namespace, areaId) => (dispatch, getState) => {
  const state = getState();

  const params = {
    page: getDevicesPage(state, { namespace }) + 1,
    "per-page": getDevicesRowsPerPage(state, { namespace }),
    "sort-col": getDevicesOrderBy(state, { namespace }),
    "sort-dir": getDevicesOrder(state, { namespace }),
    search: getDevicesTextFilter(state, { namespace }),
    ...(areaId && { "area-id": areaId }),
  };

  dispatch(getDevicesApi(params));
};

export const fetchUnfilteredDevices = () => (dispatch) => {
  dispatch(getDevicesApi());
};

export const fetchIrrigationPoints = (namespace) => (dispatch, getState) => {
  const state = getState();
  const textFilter = getDevicesTextFilter(state, { namespace });
  const transformedFilter = {
    search: textFilter,
  };
  dispatch(getIrrigationPoints(transformedFilter));
};

export const updateDeviceName = (deviceId, deviceName) => (dispatch) => {
  const deviceProps = {
    name: deviceName,
  };
  return dispatch(patchDevice(deviceId, deviceProps));
};

export const fetchDevice = (deviceId) => (dispatch) => {
  dispatch(getDeviceApi(deviceId)).then((res) => {
    const device = res.payload;
    if (device.activationDate) {
      dispatch(zoomToDevice(res.payload));
    }
  });
};

export const fetchDeviceValves = (deviceId) => (dispatch) => {
  dispatch(getDeviceValvesApi(deviceId));
};

export const valvesManualControl =
  (deviceId, payload, onAfterAction) => (dispatch) => {
    dispatch(valvesManualActionApi(deviceId, payload)).then(() => {
      onAfterAction();
    });
  };

export const fetchDeviceIntervals =
  (deviceId, dateFrom, dateTo) => (dispatch) => {
    const params = {
      duration: `${dateFrom}/${dateTo}`,
    };
    dispatch(getDeviceIntervalsApi(deviceId, params));
  };

export const fetchDeviceTypes = () => (dispatch) => {
  dispatch(getDeviceTypes());
};
