import React, { FC, Fragment } from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfButtonPanel from "../../../../shared/components/common/CfButtonPanel/CfButtonPanel";
import CfSwitcher from "../../../../shared/components/common/CfSwitcher/CfSwitcher";
import { CfRadioGroup } from "../../../../shared/components/form/CfReduxFormRadioGroup/CfReduxFormRadioGroup";
import { indicesList } from "../../selectors/indices";

const useStyles = makeStyles(() => ({
  indicesPanel: {
    padding: "0px 2px",
  },
  indexRadioLabel: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
  },
  indexRadioLabelText: {
    fontSize: "14px",
    fontWeight: 500,
  },
  indexRadio: {
    padding: 4,
  },
}));

interface BioMonitoringIndexSwitcherProps {
  index: string;
  setMonitoringIndex: (index: string) => void;
  width: string;
}

const BioMonitoringIndexSwitcher: FC<BioMonitoringIndexSwitcherProps> = ({
  index,
  setMonitoringIndex,
  width,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {width === "xs" || width === "sm" ? (
        <CfSwitcher
          getItemId={(item) => item.id}
          getItemValue={(item) => item.id}
          items={indicesList}
          onMenuItemClick={(item) => setMonitoringIndex(item.id)}
          testId="index-switcher"
          getItemViewValue={(item) => (
            <FormattedMessage id={`BioMonitoring.${item.id}`} />
          )}
        />
      ) : (
        <CfButtonPanel
          classes={{ root: classes.indicesPanel }}
          data-test="index-radio-switcher"
          uppercase={false}
        >
          <CfRadioGroup
            onChange={(evt) => setMonitoringIndex(evt.target.value)}
            value={index}
            classes={{
              formControlLabel: classes.indexRadioLabel,
              formControlLabelText: classes.indexRadioLabelText,
            }}
            labelPropertyFactory={(option) => (
              <FormattedMessage id={`BioMonitoring.${option.value}`} />
            )}
            options={indicesList.map((indexItem) => ({
              value: indexItem.id,
            }))}
            RadioProps={{
              classes: {
                root: classes.indexRadio,
              },
            }}
          />
        </CfButtonPanel>
      )}
    </Fragment>
  );
};

export default BioMonitoringIndexSwitcher;
