import { createSelector } from "reselect";

import ErrorService from "../../shared/services/Error.service";

import { CataloguesState } from "../../reducers/catalogues.reducer.types";

export const getMachines = (state: CataloguesState) =>
  state.api.telematics.machines;
export const getIsFetchingMachines = (state: CataloguesState) =>
  state.api.telematics.isFetchingMachines;
export const getMachinesCount = (state: CataloguesState) =>
  state.api.telematics.machinesTotalCount;
export const getMachinesError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorMachines);

export const getProductionOperations = (state: CataloguesState) =>
  state.api.telematics.productionOperations;
export const getIsFetchingProductionOperations = (state: CataloguesState) =>
  state.api.telematics.isFetchingProductionOperations;
export const getProductionOperationsError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorProductionOperations);

export const getMachineCategories = (state: CataloguesState) =>
  state.api.telematics.machineCategories;
export const getIsFetchingMachineCategories = (state: CataloguesState) =>
  state.api.telematics.isFetchingMachineCategories;
export const getMachineCategoriesError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorMachineCategories);

export const getMachineGroups = (state: CataloguesState) =>
  state.api.telematics.machineGroups;
export const getIsFetchingMachineGroups = (state: CataloguesState) =>
  state.api.telematics.isFetchingMachineGroups;
export const getMachineGroupsError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorMachineGroups);

export const getEquipments = (state: CataloguesState) =>
  state.api.telematics.equipments;
export const getIsFetchingEquipments = (state: CataloguesState) =>
  state.api.telematics.isFetchingEquipments;
export const getEquipmentsCount = (state: CataloguesState) =>
  state.api.telematics.equipmentsTotalCount;
export const getEquipmentsError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorEquipments);

export const getDrivers = (state: CataloguesState) =>
  state.api.telematics.drivers;
export const getIsFetchingDrivers = (state: CataloguesState) =>
  state.api.telematics.isFetchingDrivers;
export const getDriversCount = (state: CataloguesState) =>
  state.api.telematics.driversTotalCount;
export const getDriversError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.telematics.errorDrivers);

export const getAnyTelematicsCataloguesError = createSelector(
  getMachinesError,
  getProductionOperationsError,
  getMachineCategoriesError,
  getMachineGroupsError,
  getEquipmentsError,
  getDriversError,
  (e1, e2, e3, e4, e5, e6) => [e1, e2, e3, e4, e5, e6].find((e) => e.isError),
);
