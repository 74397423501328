import { AnyAction } from "redux";

import * as types from "../actions/management.constants";

const initialState = {
  cropLegislativeCode: null,
};

type NewState = typeof initialState;

export default () =>
  (state = initialState, action: AnyAction): NewState => {
    switch (action.type) {
      case types.SET_CROP_LEGISLATIVE_CODE:
        return {
          ...state,
          cropLegislativeCode: action.cropLegislativeCode,
        };
      case types.RESET_CROP_LEGISLATIVE_CODE:
        return {
          ...state,
          cropLegislativeCode: null,
        };
      default:
        return state;
    }
  };
