import { RSAA } from "redux-api-middleware";

import * as types from "./monitoring.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getCropIntervalsApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}/crop-intervals?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MONITORING_CROP_INTERVALS_REQUEST,
      types.GET_MONITORING_CROP_INTERVALS_SUCCESS,
      types.GET_MONITORING_CROP_INTERVALS_ERROR,
    ],
  },
});

export const resetCropIntervals = () => ({
  type: types.RESET_MONITORING_CROP_INTERVALS,
});

export const getMonitoringDataApi = (
  parcelId: string,
  index: string,
  dateFrom: string,
  dateTo: string,
  status: string,
  perPage?: number,
) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}?type=${index}&date-from=${
      dateFrom || ""
    }&date-to=${dateTo || ""}&status=${status || ""}&per-page=${
      perPage || ""
    }&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MONITORING_DATA_REQUEST,
      types.GET_MONITORING_DATA_SUCCESS,
      types.GET_MONITORING_DATA_ERROR,
    ],
  },
});

export const resetMonitoringData = () => ({
  type: types.RESET_MONITORING_DATA,
});
