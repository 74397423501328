import CommonLabelStyle from "./CommonLabelStyle.service";
import CommonPolygonStyle from "./CommonPolygonStyle.service";

export default class DrawStyles {
  static getParcelStyle() {
    return CommonPolygonStyle.getDisabled();
  }

  static getLabelStyle(feature, resolution) {
    if (resolution < 50) {
      const style = CommonLabelStyle.getDisabled();
      style
        .getText()
        .setText(CommonLabelStyle.getLabelContent(feature, resolution));
      return style;
    }
    return null;
  }
}
