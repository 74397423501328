import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  getCreateOrEditAreaOrder,
  getCreateOrEditAreaOrderBy,
  getCreateOrEditAreaUserSelectedData,
  getUserSelectedDevices,
} from "../selectors/createOrEditArea.selectors";

import * as ACTIONS from "./createOrEditArea.constants";

import { CREATE_OR_EDIT_AREA_NAMESPACE } from "../reducer/createOrEditArea.reducer";

import {
  addDevicesToAreaApi,
  createAreaApi,
  deleteDevicesFromAreaApi,
} from "../../shared/api/irrigation/areas/areas.api";
import { getInactiveDevicesApi } from "../../shared/api/irrigation/devices/devices.api";
import {
  createPayloadForEditEndpoint,
  createPayloadForCreateEndpoint,
} from "../services/CreateOrEditArea.services";
import { validateForm } from "../services/CreateOrEditArea.validations";

import { IrrigationState } from "../../reducers/irrigation.reducer.types";
import { Device } from "../../shared/api/irrigation/devices/devices.types";
import { FieldErrors } from "../reducer/createArea.reducer.types";

export const setDevicesToEdit = (payload: Device[]) => ({
  type: ACTIONS.SET_DEVICES_TO_EDIT,
  payload,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
});

export const editDevice = (
  deviceId: string,
  field: string,
  payload: string,
) => ({
  type: ACTIONS.EDIT_DEVICE,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  deviceId,
  field,
  payload,
});

export const sortDevices = (payload: string) => ({
  type: ACTIONS.SORT_DEVICES,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  payload,
});

export const setAreaName = (payload: string) => ({
  type: ACTIONS.SET_AREA_NAME,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  payload,
});

export const setFieldErrors = (payload: FieldErrors) => ({
  type: ACTIONS.SET_FIELD_ERRORS,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  payload,
});

export const setFormTouched = (status: boolean) => ({
  type: ACTIONS.SET_FORM_TOUCHED,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  status,
});

export const resetForm = () => ({
  type: ACTIONS.RESET_FORM,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
});

export const activateCoordSelector = (deviceId: string) => ({
  type: ACTIONS.ACTIVATE_COORDINATES_SELECTOR,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  deviceId,
});

export const resetCoordSelector = () => ({
  type: ACTIONS.RESET_COORDINATES_SELECTOR,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
});

export const setHoveredDevice = (deviceId: string) => ({
  type: ACTIONS.SET_HOVERED_DEVICE,
  namespace: CREATE_OR_EDIT_AREA_NAMESPACE,
  deviceId,
});

export const checkForm =
  () =>
  (
    dispatch: (action: AnyAction) => void,
    getState: () => IrrigationState,
  ): boolean => {
    const state = getState();

    const data = getCreateOrEditAreaUserSelectedData(state);

    const [isValid, fieldErrors] = validateForm(data);

    dispatch(setFieldErrors(fieldErrors));

    return isValid;
  };

export const createArea =
  (handleRedirect: (id: string) => void) =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, unknown>>,
    getState: () => IrrigationState,
  ) => {
    const state = getState();

    const data = getCreateOrEditAreaUserSelectedData(state);

    const payload = createPayloadForCreateEndpoint(data);

    dispatch(createAreaApi(payload) as unknown as RSAAAction).then((res) => {
      if (!res.error) {
        Promise.resolve(res).then(
          ({ payload }: { payload: Record<string, string> }) => {
            handleRedirect(payload.id);
            dispatch(resetForm());
          },
        );
      }
    });
  };

export const addToArea =
  (areaId: string, handleRedirect: (id: string) => void) =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, unknown>>,
    getState: () => IrrigationState,
  ) => {
    const state = getState();

    const data = getUserSelectedDevices(state);

    const payload = createPayloadForEditEndpoint(data);

    dispatch(
      addDevicesToAreaApi(areaId, payload) as unknown as RSAAAction,
    ).then((res) => {
      if (!res.error) {
        handleRedirect(areaId);
      }
    });
  };

export const deleteFromArea =
  (
    areaId: string,
    deviceIds: string[],
    handleContentRefresh: () => void,
    handleMapContentRefresh: (areaID: string) => void,
  ) =>
  (
    dispatch: (
      action: RSAAAction | AnyAction,
    ) => Promise<Record<string, unknown>>,
  ) => {
    dispatch(
      deleteDevicesFromAreaApi(areaId, deviceIds) as unknown as RSAAAction,
    ).then((res) => {
      if (!res.error) {
        handleContentRefresh();
        handleMapContentRefresh(areaId);
      }
    });
  };

export const fetchInactiveDevices =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => IrrigationState) => {
    const state = getState();

    const params = {
      "sort-col": getCreateOrEditAreaOrderBy(state),
      "sort-dir": getCreateOrEditAreaOrder(state),
    };

    dispatch(getInactiveDevicesApi(params) as unknown as RSAAAction);
  };
