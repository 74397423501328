import { createContext } from "react";

import moment from "moment";

import { ParcelTo } from "../../../shared/api/agroevidence/agroevidence.types";

interface ActionOtherContext {
  actionType: string;
  cropUseType: number | string;
  date: moment.Moment;
  description: string;
  harvestCrop: true;
  hectarAmount: number;
  hectarGain: number;
  isCatchCrop: false;
  mainHarvestProductId: number;
  parcels: ParcelTo[];
  productId: string;
  secondaryHarvestProductId: number;
  secondaryHectarGain: number;
  secondaryTotalGain: number;
  seedId: string;
  totalAmount: number;
  totalGain: number;
  unitId: string;
}

export const defaultValues: ActionOtherContext = {
  parcels: [],
  date: moment().startOf("day"),
  actionType: "",
  description: "",
  productId: "",
  totalGain: 0,
  hectarGain: 0,
  harvestCrop: true,
  isCatchCrop: false,
  seedId: "",
  cropUseType: "",
  mainHarvestProductId: 0,
  secondaryHarvestProductId: 0,
  secondaryTotalGain: 0,
  secondaryHectarGain: 0,
  totalAmount: 0,
  hectarAmount: 0,
  unitId: "t",
};

export const FormContext = createContext({ initialValues: defaultValues });
