export const ActionsPalette = {
  error: {
    light: "#FFB8B8",
    main: "#F24A4A",
  },
  warning: {
    light: "#FFEDB8",
    main: "#F2994A",
  },
  success: {
    light: "#D9F1EB",
    main: "#00A179",
  },
  neutral: {
    main: "#737373",
  },
};
