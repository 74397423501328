import React, { Fragment, useState } from "react";

import GroupWorkIcon from "@mui/icons-material/GroupWork";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { useToggle } from "../../../shared/hooks/useToggle";
import { NodeLocationEditGroupDialog } from "../NodeLocationEditGroupDialog/NodeLocationEditGroupDialog";

export const NodeLocationEditGroup = ({
  menuItem = false,
  nodeLocation,
  setGroups,
}) => {
  const classes = useStyles();

  const [selectedGroups, setSelectedGroups] = useState(
    nodeLocation.groups || [],
  );

  const {
    on: opened,
    setOff: handleDialogClose,
    setOn: handleDialogOpen,
  } = useToggle();

  const handleDialogConfirm = () => {
    setGroups(selectedGroups, nodeLocation.id);
    handleDialogClose();
  };

  return (
    <Fragment>
      {menuItem ? (
        <MenuItem aria-label="edit-group" onClick={handleDialogOpen}>
          <FormattedMessage id="NodeGroups.edit" />
        </MenuItem>
      ) : (
        <Tooltip title={<FormattedMessage id="NodeGroups.edit" />}>
          <IconButton
            aria-label="edit-group"
            className={classes.button}
            onClick={handleDialogOpen}
            size="large"
          >
            <GroupWorkIcon />
          </IconButton>
        </Tooltip>
      )}
      <NodeLocationEditGroupDialog
        nodeLocation={nodeLocation}
        onAccept={handleDialogConfirm}
        onCancel={handleDialogClose}
        opened={opened}
        selectedGroups={nodeLocation.groups || []}
        setGroups={setSelectedGroups}
      />
    </Fragment>
  );
};

NodeLocationEditGroup.propTypes = {
  nodeLocation: PropTypes.object.isRequired,
  setGroups: PropTypes.func.isRequired,
  menuItem: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  button: {
    minWidth: "unset",
  },
}));
