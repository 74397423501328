import * as types from "./variableApplication.constants";

const isWriterJob = (job) => job.type === "WRITER";
const isUploaderJob = (job) => job.type === "UPLOADER";

export default () =>
  (
    state = {
      isFetching: false,
      error: {},
      items: [],
      variableExpense: {},
      isVariableExpenseFetching: false,
      variableExpenseError: {},
      actionIds: null,
      isFetchingActions: false,
      errorActions: {},
      variableExpenses: null,
      variableExpensesIsFetching: false,
      variableExpensesError: null,
      saveVariableExpenseIsFetching: false,
      isFetchingAgiStatus: false,
      agiStatus: {},
      agiJobs: {},
      agiMachines: [],
      isExportingVA: false,
    },
    action = {},
  ) => {
    switch (action.type) {
      case types.GET_VARIABLE_FERTILIZATION_PARCELS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
          totalCount: 0,
        };
      case types.RESET_VARIABLE_FERTILIZATION_PARCELS:
        return {
          ...state,
          items: [],
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          isVariableExpenseFetching: true,
          variableExpenseError: {},
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS:
        return {
          ...state,
          isVariableExpenseFetching: false,
          variableExpense: action.payload,
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE:
        return {
          ...state,
          isVariableExpenseFetching: false,
          variableExpenseError: action.payload,
        };
      case types.RESET_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          variableExpense: {},
          isVariableExpenseFetching: false,
          variableExpenseError: {},
        };

      case types.GET_VARIABLE_FERTILIZATION_ACTIONS:
        return {
          ...state,
          isFetchingActions: true,
          errorActions: {},
        };
      case types.GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS:
        return {
          ...state,
          isFetchingActions: false,
          actionIds: action.payload,
        };
      case types.GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE:
        return {
          ...state,
          isFetchingActions: false,
          errorActions: action.payload,
        };
      case types.RESET_VARIABLE_FERTILIZATION_ACTIONS:
        return {
          ...state,
          actionIds: null,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES:
        return {
          ...state,
          variableExpenses: null,
          variableExpensesIsFetching: true,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS:
        return {
          ...state,
          variableExpenses: action.payload,
          variableExpensesError: null,
          variableExpensesIsFetching: false,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE:
        return {
          ...state,
          variableExpenses: action.payload,
          variableExpensesIsFetching: false,
        };
      case types.RESET_VARIABLE_FERTILIZATION_EXPENSES:
        return {
          ...state,
          variableExpenses: null,
        };

      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          saveVariableExpenseIsFetching: true,
        };
      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS:
        return {
          ...state,
          saveVariableExpenseIsFetching: false,
        };
      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE:
        return {
          ...state,
          saveVariableExpenseIsFetching: false,
        };
      case types.EXPORT_VARIABLE_APPLICATION:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY:
        return {
          ...state,
          isExportingVA: true,
        };

      case types.EXPORT_VARIABLE_APPLICATION_SUCCESS:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS:
      case types.EXPORT_VARIABLE_APPLICATION_ERROR:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR:
        return {
          ...state,
          isExportingVA: false,
        };

      case types.GET_AGI_JOBS:
        return {
          ...state,
          agiJobs: {
            ...state.agiJobs,
            [action.payload.applicationId]: {
              ...state.agiJobs?.[action.payload.applicationId],
              isFetching: true,
            },
          },
        };
      case types.GET_AGI_JOBS_SUCCESS:
        return {
          ...state,
          agiJobs: {
            ...state.agiJobs,
            [action.payload.applicationId]: {
              isFetching: false,
              result: {
                writer: action.payload.jobs.filter(isWriterJob),
                uploader: action.payload.jobs.filter(isUploaderJob),
              },
            },
          },
        };
      case types.GET_AGI_MACHINES_SUCCESS:
        return {
          ...state,
          agiMachines: action.payload,
        };

      default:
        return state;
    }
  };
