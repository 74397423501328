import { AnyAction } from "redux";

import * as types from "./zones.constants";

const initialState = {
  items: [],
  isFetching: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_ZONES_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_ZONES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_ZONES_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case types.RESET_ZONES:
        return {
          ...state,
          items: [],
          isFetching: false,
        };
      default:
        return state;
    }
  };
