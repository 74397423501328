import React from "react";

import { DeprecatedFarmTo } from "../../api/agroevidence/agroevidence.types";

const farmContext = React.createContext<DeprecatedFarmTo | undefined>(
  undefined,
);

const CfFarmProvider = ({
  children,
  farm,
}: React.PropsWithChildren<{ farm: DeprecatedFarmTo }>) => (
  <farmContext.Provider value={farm}>{children}</farmContext.Provider>
);

const withFarm = <T,>(WrappedComponent: React.ComponentType<T>) => {
  const EnhancedComponent = (
    props: Omit<T, keyof DeprecatedFarmTo | "farm">,
  ) => (
    <farmContext.Consumer>
      {(context) => <WrappedComponent farm={context} {...(props as T)} />}
    </farmContext.Consumer>
  );

  return EnhancedComponent;
};

export { CfFarmProvider, withFarm };
