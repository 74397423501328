import { RSAA } from "redux-api-middleware";

import * as types from "./weather.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../api.constants";

export const getWeather = (lat, lon, langId) => ({
  [RSAA]: {
    endpoint: `${lat},${lon}?units=si&lang=${langId.slice(0, 2)}`,
    proxy: true,
    module: modules.WEATHER,
    method: methods.GET,
    types: [
      types.GET_WEATHER,
      types.GET_WEATHER_SUCCESS,
      types.GET_WEATHER_ERROR,
    ],
  },
});
