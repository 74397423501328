import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./mainMapTelematics.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import {
  getMachinePositionsApiParams,
  getMachineDrivesHistoryApiParams,
  GetClassifiersApiParams,
} from "./mainMapTelematics.types";
import { RsaaMethods, RsaaTypes } from "../../api.types";

export const getMachinePositionsApi = (
  params: getMachinePositionsApiParams,
) => ({
  [RSAA]: {
    endpoint: `machines/position?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINE_POSITIONS_REQUEST,
      types.GET_MACHINE_POSITIONS_SUCCESS,
      types.GET_MACHINE_POSITIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getMachineDrivesHistoryApi = (
  params: getMachineDrivesHistoryApiParams,
) => ({
  [RSAA]: {
    endpoint: `machines/position/history?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINE_DRIVES_HISTORY_REQUEST,
      types.GET_MACHINE_DRIVES_HISTORY_SUCCESS,
      types.GET_MACHINE_DRIVES_HISTORY_ERROR,
    ] as RsaaTypes,
  },
});

export const getMachinesApi = (params: GetClassifiersApiParams) => ({
  [RSAA]: {
    endpoint: `machines?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINES_REQUEST,
      types.GET_MACHINES_SUCCESS,
      types.GET_MACHINES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetMachines = () => ({
  type: types.RESET_MACHINES,
});

export const getMachinesGroupsApi = (lang: string) => ({
  [RSAA]: {
    endpoint: `machines/groups?language=${lang}`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINES_GROUPS_REQUEST,
      types.GET_MACHINES_GROUPS_SUCCESS,
      types.GET_MACHINES_GROUPS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetMachinesGroups = () => ({
  type: types.RESET_MACHINES_GROUPS,
});

export const getDriversApi = (params: GetClassifiersApiParams) => ({
  [RSAA]: {
    endpoint: `drivers?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_DRIVERS_REQUEST,
      types.GET_DRIVERS_SUCCESS,
      types.GET_DRIVERS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetDrivers = () => ({
  type: types.RESET_DRIVERS,
});
