import React, { Component, Fragment } from "react";

import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FieldArray } from "redux-form";

import { getParcelSubtractionsGeometry } from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getParcelsArea,
  getVAIsError,
  getVAIsLoading,
  getCreateVAIsLoading,
  getDriftClass,
} from "../../../shared/selectors/actions.selectors";

import {
  exportVariableApplications,
  updateVariableExpense,
  updateParcelSubtractionsGeometry,
  resetParcelSubtractionsGeometry,
} from "../../../shared/actions/actions.actions";

import * as indices from "../../../../../core/precision/selectors/indices";
import {
  createVariableExpense,
  resetVariableExpense,
  resetVariableActionExpenses,
} from "../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import unitsMock from "../../../../../shared/mocks/units.mock.json";
import ActionDate from "../../../shared/components/ActionDate/ActionDate";
import ActionNote from "../../../shared/components/ActionNote/ActionNote";
import ActionTargetCrop from "../../../shared/components/ActionTargetCrop/ActionTargetCrop";
import VaMapSourceDialog from "../../../shared/components/VaMapSourceDialog/VaMapSourceDialog";
import ActionParcelsControl from "../../../shared/containers/ActionParcelsControl/ActionParcelControl";
import ChemFertSelector from "../../../shared/containers/ChemFertSelector/ChemFertSelector";
import actionForm from "../../../shared/hocs/actionForm";
import variableActionForm from "../../../shared/hocs/variableActionForm";
import {
  parcelsCountChanged,
  parcelsAreaChanged,
  expenseCountChanged,
} from "../../../shared/misc/action.helpers";
import {
  mapRequestBodyEphActionTo,
  createNewVrfTo,
} from "../../../shared/services/ActionToMapper.services";
import { mapRequestBodyParcelSubtractionsGeometry } from "../../../shared/services/ParcelSubtractionMapper.services";
import {
  getInitialValues,
  validate,
  warn,
  lsTransformFnc,
  STRONG_ZONES_FERTILIZATION,
} from "../../helpers/vrf.helpers";
import VrfExpenseControl from "../VrfExpenseControl/VrfExpenseControl";

const monitoringDataTypes = [indices.CAB, indices.LAI];
const historyDataTypes = ["HIST"];
const samplesDataTypes = ["SAMPLES"];

const satelliteDataTypes = [
  ...monitoringDataTypes,
  ...historyDataTypes,
  ...samplesDataTypes,
];
const FORM_NAME = "vrf";
const kg = unitsMock.units.find((u) => u.id === "kg");

/*
 * KEEP THE COMMENTS UP TO DATE!
 */
class Vrf extends Component {
  componentDidMount() {
    if (this.props.formValues.parcels[0]) {
      const requestParam = mapRequestBodyParcelSubtractionsGeometry(
        this.props.formValues.parcels[0].id,
        this.props.formValues.parcels[0].subtractableAreas,
      );
      this.props.updateParcelSubtractionsGeometry(requestParam);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      existingAction,
      expenses: oldExpenses,
      isEditing,
      parcels: oldParcels,
      parcelsArea: oldParcelsArea,
      satellite: oldSatellite,
    } = prevProps;

    const {
      expenses: newExpenses,
      fertilizationType: newFertilizationType,
      formName,
      formValues: newFormValues,
      parcelRestrictionGeometry,
      parcels: newParcels,
      parcelsArea: newParcelsArea,
      satellite: newSatellite,
    } = this.props;

    // Comparison booleans to detect the change
    const parcelsCountDiff = parcelsCountChanged(oldParcels, newParcels);
    const expenseCountDiff = expenseCountChanged(oldExpenses, newExpenses);

    /*
     * Set zonesCount & satellite to nulls, expenses to [] when:
     * 1) parcels count changed
     * 2) and there are no parcels
     */
    if (parcelsCountDiff && !newParcels.length) {
      this.props.change("satellite", null);
      this.props.change("expenses", []);
    }

    /*
     * Set parcelSubtractionsGeometry when:
     * 1) parcels count changed
     */
    if (parcelsCountDiff && newParcels.length) {
      const requestParam = mapRequestBodyParcelSubtractionsGeometry(
        this.props.formValues.parcels[0].id,
        this.props.formValues.parcels[0].subtractableAreas,
      );
      this.props.updateParcelSubtractionsGeometry(requestParam);
    }

    if (oldSatellite !== newSatellite && !newExpenses.length) {
      this.props.setCurrMapZones(newSatellite?.zones || null);
    }

    /*
     * Fetch  expense's variableExpense if its not present yet in the expense object;
     * Store it in expense. Happens for all expenses with no variableExpense; This occurs
     * when new fertilizer is added or when cached and unsaved action is loaded; variableExpense is
     * later used in VrfExpenseCard; Triggered when:
     * 1) parcel, satellite and parcel geometry are available
     * 2) there are some expenses without variableExpense property
     */
    if (
      parcelRestrictionGeometry &&
      newParcels.length &&
      newSatellite &&
      isEditing
    ) {
      const miss = newExpenses.filter((expense) => !expense.variableExpense);
      if (miss.length) {
        this.updateVariableExpenses(
          miss,
          newSatellite,
          newExpenses,
          newParcels[0],
          parcelRestrictionGeometry.geometry,
          null,
          formName,
          newFertilizationType,
        );
      }
    }

    /*
     * Update expense's variableExpense if parcel subtractable zones changed;
     * Store it in expense as a variableExpense prop. Triggered when:
     * 1) parcel, satellite and parcel geometry are available
     * 2) some parcel has changed its restrictedArea
     */
    const parcelsAreaDiff = parcelsAreaChanged(oldParcelsArea, newParcelsArea);

    if (
      parcelsAreaDiff &&
      parcelRestrictionGeometry &&
      newParcels.length &&
      newSatellite &&
      newExpenses.length
    ) {
      this.updateVariableExpenses(
        newExpenses,
        newSatellite,
        newExpenses,
        newParcels[0],
        parcelRestrictionGeometry.geometry,
        null,
        formName,
        newFertilizationType,
      );
    }

    if (expenseCountDiff) {
      if (newExpenses.length) {
        const lastExpense = newExpenses[newExpenses.length - 1];
        this.props.setCurrMapZones(
          lastExpense?.variableExpense?.applicationZones ||
            newSatellite?.zones ||
            null,
        );

        this.props.change("fertilizationType", { id: lastExpense.strategy });
      }
      if (newExpenses.length === 0) {
        this.props.setCurrMapZones(newSatellite?.zones || null);
        this.props.change("fertilizationType", { id: null });
      }
    }

    /*
     * Updates initial state of form.
     * Existing variable action should be considered to be loaded only when
     * satellite zones and all variable expenses are loaded as well.
     * Then it can be saved as real initial state.
     */
    if (existingAction && !isEditing) {
      if (
        newSatellite &&
        oldExpenses.some((exp) => !exp.variableExpense) &&
        newExpenses.every((exp) => !!exp.variableExpense)
      ) {
        this.props.initialize(newFormValues);
      }

      if (
        !oldSatellite &&
        newSatellite &&
        newExpenses.every((exp) => !!exp.variableExpense)
      ) {
        this.props.initialize(newFormValues);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetVariableActionExpenses();
    this.props.resetParcelSubtractionsGeometry();
  }

  updateVariableExpenses(
    missingVariableExpenses,
    satellite,
    expenses,
    parcel,
    geometry,
    subtractionGeometry,
    formName,
  ) {
    const promises = [];
    missingVariableExpenses.forEach((expense) => {
      const { targetCrop } = this.props;
      const {
        chemicalElement,
        crop,
        dateFrom,
        dateTo,
        type: indexType,
      } = satellite;
      const {
        doseUnit: { id: unitId },
        material: { id },
        maxDose,
        minDose,
        strategy,
        variableExpense,
      } = expense;

      const index = expenses.indexOf(expense);
      if (index === -1) {
        throw new Error("Unable to find expense index related to the VA");
      }

      const promise = this.props.updateVariableExpense(
        { isFetching: true },
        index,
        formName,
      );
      this.props
        .createVariableExpense({
          parcelId: parcel.id,
          geometry,
          ...(subtractionGeometry ? { subtractionGeometry } : {}),
          materialId: id,
          minDoseHa: minDose,
          maxDoseHa: maxDose,
          // Trick: when create vrf from SoilSamples we use targetCrop.legislativeCode
          // because we need send something on BE side.
          cropLegCode: crop?.legislativeCode ?? targetCrop.legislativeCode,
          unit: unitId,
          type: strategy,
          indexType,
          chemicalElement,
          dateFrom,
          dateTo,
          ...(variableExpense
            ? { applicationZones: variableExpense.applicationZones }
            : {}),
        })
        .then((res) => {
          let payload = res.payload;
          if (res.error) {
            payload = { isError: true };
          }
          this.props.updateVariableExpense(payload, index, formName);
          this.props.setCurrMapZones(payload?.applicationZones || null);
        });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      this.props.resetVariableExpense();
    });
  }

  render() {
    const {
      isEditing,
      initParcelIds,
      satellite,
      formName,
      parcels,
      expenses,
      targetCrop,
      driftClass,
      variableParcelIds,
      onFieldManualChange,
      isCreateVaLoading,
      langId,
      currMapZones,
      actionDate,
      parcelsArea,
      satelliteData,
      isManagementZonesLoading = { isManagementZonesLoading },
      existingAction,
    } = this.props;

    return (
      <Fragment>
        <Grid item xs={12}>
          <FieldArray
            actionDate={actionDate}
            component={ActionParcelsControl}
            driftClass={driftClass}
            expenses={expenses}
            filter={variableParcelIds}
            formName={formName}
            initParcelIds={initParcelIds}
            isDraft={!!existingAction?.isDraft}
            isEditing={isEditing && !isCreateVaLoading}
            maxParcelCount={1}
            name="parcels"
            parcelsArea={parcelsArea}
            parcelsOnly={true}
            placeholder="VariableFertilization.parcelSelector"
            subtractableAreasIds={["Boundary", "Water"]}
            targetCrop={targetCrop}
          />
        </Grid>
        <Grid item lg={5} md={6} sm={7} xl={4} xs={12}>
          <ActionDate disabled={!isEditing} />
          <ActionTargetCrop
            disabled={!isEditing}
            onChange={() => onFieldManualChange("targetCrop")}
          />
          <ActionNote disabled={!isEditing} isLast={true} />
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems="center" container justifyContent="center">
            <VaMapSourceDialog
              currMapZones={currMapZones}
              disabled={!isEditing || isCreateVaLoading || !parcels.length}
              onClick={this.props.setCurrMapZones}
              satellite={satellite}
              satelliteData={satelliteData}
              onAccept={(v) => {
                this.props.change("satellite", v);
              }}
              onRemove={() => {
                this.props.change("satellite", null);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            component={VrfExpenseControl}
            currMapZones={currMapZones}
            driftClass={driftClass}
            fertilizers={expenses}
            formName={formName}
            isEditing={isEditing}
            langId={langId}
            name="expenses"
            onMapClick={this.props.setCurrMapZones}
            parcels={parcels}
            satellite={satellite}
            targetCrop={targetCrop}
            SelectorProps={{
              component: ChemFertSelector,
              actionDate,
              fertOnly: true,
              placeholder: "VariableFertilization.chemFertSelector",
              disabled: Boolean(!satellite),
              fertAdditionalProps: {
                incorporationDay: "NO",
                minDose: null,
                maxDose: null,
                doseUnit: kg,
                strategy: STRONG_ZONES_FERTILIZATION,
                zones:
                  satellite?.zones?.map((z) => ({
                    ...z,
                    dose: null,
                  })) || [],
              },
            }}
          />
        </Grid>
      </Fragment>
    );
  }
}

Vrf.propTypes = {
  formValues: PropTypes.object.isRequired,
  satelliteData: PropTypes.object.isRequired,
  targetCrop: PropTypes.object,
  existingAction: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  driftClass: PropTypes.string,
  parcels: PropTypes.array.isRequired,
  expenses: PropTypes.array.isRequired,
  satellite: PropTypes.object,
  initParcelIds: PropTypes.array,
  actionDate: PropTypes.object.isRequired,
  variableParcelIds: PropTypes.array.isRequired,
  createVariableExpense: PropTypes.func.isRequired,
  resetVariableExpense: PropTypes.func.isRequired,
  updateVariableExpense: PropTypes.func.isRequired,
  fertilizationType: PropTypes.object.isRequired,
  onFieldManualChange: PropTypes.func.isRequired,
  resetVariableActionExpenses: PropTypes.func.isRequired,
  resetParcelSubtractionsGeometry: PropTypes.func.isRequired,
  isCreateVaLoading: PropTypes.bool.isRequired,
  langId: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  setCurrMapZones: PropTypes.func.isRequired,
  currMapZones: PropTypes.array,
  isManagementZonesLoading: PropTypes.bool.isRequired,
  parcelRestrictionGeometry: PropTypes.object,
  updateParcelSubtractionsGeometry: PropTypes.func.isRequired,
};

Vrf.defaultProps = {
  satelliteData: null,
  targetCrop: null,
  existingAction: null,
  initParcelIds: [],
  initZoneIds: [],
  satellite: null,
  driftClass: "",
  currMapZones: null,
  parcelRestrictionGeometry: null,
};

const mapStateToProps = (state) => ({
  driftClass: getDriftClass(FORM_NAME, state),
  parcelsArea: getParcelsArea(FORM_NAME, state),
  parcelRestrictionGeometry: getParcelSubtractionsGeometry(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createVariableExpense,
      resetVariableExpense,
      updateVariableExpense,
      resetVariableActionExpenses,
      resetParcelSubtractionsGeometry,
      exportVariableApplications,
      updateParcelSubtractionsGeometry,
    },
    dispatch,
  );

const onUpdateCallback = (values, dispatch, props, updateResult) =>
  props
    .saveVariableExpense({
      actionId: updateResult.payload.id,
      variableExpenseDTO: createNewVrfTo(values),
    })
    .then((result) => {
      props.updateVariableExpense(result.payload[0], 0, "vrf");
      return Promise.resolve(result);
    });

const onCreateCallback = (values, dispatch, props, createResult) =>
  props
    .saveVariableExpense({
      actionId: createResult.payload.id,
      variableExpenseDTO: createNewVrfTo(values),
    })
    .then((result) => Promise.resolve(result));

const onExport = (props, exportType) => {
  const applications = props.expenses
    ?.filter((e) => e.variableExpense)
    .map((e) => ({ id: e.variableExpense.id, material: e.material.name }));
  const parcelName = props.parcels?.[0]?.localName || "";
  const blockNumber = props.parcels?.[0]?.blockNumber || "0";
  const parcelNumber = blockNumber.replace("/", "_");
  props.exportVariableApplications(
    applications,
    exportType,
    parcelName,
    parcelNumber,
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  actionForm({
    formName: FORM_NAME,
    validate,
    warn,
    lsTransformFnc,
    onUpdateCallback,
    onCreateCallback,
    mapRequestBodyEphActionTo,
    destroyOnUnmount: true,
    initialValues: getInitialValues(),
  }),
  variableActionForm({
    onExport,
    getApiError: getVAIsError,
    getIsLoading: getVAIsLoading,
    getCreateVaIsLoading: getCreateVAIsLoading,
    satelliteDataTypes,
  }),
)(Vrf);
