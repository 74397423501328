import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./admin.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getAdminParcels = (context, params) => ({
  [RSAA]: {
    endpoint: `admin?${queryString.stringify(params)}&`,
    method: methods.GET,
    context,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_PARCELS,
      types.GET_ADMIN_PARCELS_SUCCESS,
      types.GET_ADMIN_PARCELS_ERROR,
    ],
  },
});

export const getParcelsByName = (context, search, farmId) => ({
  [RSAA]: {
    endpoint: `admin?include-disabled=true&per-page=50&farmIds=${farmId}&localNameBlockNr=${search}&`,
    method: methods.GET,
    context,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_PARCELS,
      types.GET_ADMIN_PARCELS_SUCCESS,
      types.GET_ADMIN_PARCELS_ERROR,
    ],
  },
});

export const updateParcelServices = (
  parcelServicesSettings,
  parcelId,
  farmId,
) => ({
  [RSAA]: {
    endpoint: `admin/${parcelId}?farmIds=${farmId}&`,
    method: methods.PUT,
    module: modules.SENTINEL,
    body: JSON.stringify(parcelServicesSettings),
    types: [
      types.UPDATE_PARCEL_SERVICES,
      types.UPDATE_PARCEL_SERVICES_SUCCESS,
      types.UPDATE_PARCEL_SERVICES_ERROR,
    ],
  },
});

export const addParcelsServices = (parcelsServicesSettings, farmId) => ({
  [RSAA]: {
    endpoint: `admin?farmIds=${farmId}&`,
    method: methods.POST,
    module: modules.SENTINEL,
    body: JSON.stringify(parcelsServicesSettings),
    types: [
      types.ADD_PARCELS_SERVICES,
      types.ADD_PARCELS_SERVICES_SUCCESS,
      types.ADD_PARCELS_SERVICES_ERROR,
    ],
  },
});

export const resetAdminParcels = (context) => ({
  type: types.RESET_ADMIN_PARCELS,
  meta: {
    context,
  },
});
