import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useTelematicsAggDetailTimelineStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      width: "100%",
    },
    form: {
      width: "100%",
    },
    overlapCheckbox: {
      padding: 0,
      marginLeft: 9,
      marginRight: 9,
    },
    overlapCheckboxGroup: {
      display: "flex",
      flexDirection: "column",
      gap: 7,
    },
    legend: {
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "normal",
      margin: "0 0 6px 0",
      "&.Mui-focused": {
        color: theme.palette.grey[500],
      },
    },
    intervals: {
      margin: "6px 0",
      justifyContent: "flex-start",
    },
    intervalsHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginBottom: 8,
      lineHeight: 1,
    },
    intervalsTime: {
      color: theme.palette.grey[500],
    },
    intervalWarningIcon: {
      marginTop: 8,
      textAlign: "center",
      alignSelf: "center",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      marginBottom: 16,
      "& .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 500,
      },
      "& .MuiRadio-root": {
        padding: "0 4px 0 9px",
      },
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
        marginBottom: 0,
      },
      '& input[type="radio"]': {
        margin: 0,
      },
    },
    timeSplit: {
      "& .MuiInput-root": {
        maxWidth: 50,
        width: "100%",
      },
      "& .MuiInputLabel-root": {
        overflow: "visible",
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
    timeSeparator: {
      fontSize: 16,
      fontWeight: 900,
      lineHeight: "120%",
      color: theme.palette.error.main,
    },
    splitTimeActive: {
      color: theme.palette.common.black,
    },
    error: {
      fontSize: 12,
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "140%",
      color: theme.palette.error.main,
    },
  }),
);
