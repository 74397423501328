import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  errorText: {
    fontSize: 12,
    color: theme.palette.error.main,
    position: "absolute",
    marginTop: 3,
  },
});

function ListSelectorError(props) {
  const { classes } = props;
  return <span className={classes.errorText}>{props.error}</span>;
}

ListSelectorError.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ListSelectorError.defaultProps = {
  error: "",
};

export default withStyles(styles)(ListSelectorError);
