import React, { FC } from "react";

import { TableBody } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../shared/misc/helper";

import FleetConnectorRow from "./FleetConnectorRow";
import VarioDocRow from "./VarioDocRow";

const columns = {
  status: getColDesc(false, <FormattedMessage id="common.state" />, {
    width: "80px",
    alignContent: "center",
  }),
  service: getColDesc(false, <FormattedMessage id="common.service" />),
  user_account: getColDesc(false, <FormattedMessage id="common.userAccount" />),
  description: getColDesc(false, <FormattedMessage id="common.description" />, {
    maxWidth: "200px",
  }),
};

const IntegrationsTable: FC = () => (
  <CfTableWrapper>
    <CfTableHead columns={columns} />
    <TableBody>
      <FleetConnectorRow />
      <VarioDocRow />
    </TableBody>
  </CfTableWrapper>
);

export default IntegrationsTable;
