import React from "react";

import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  avatar: {
    width: 28,
    height: 28,
    fontSize: 13,
    fontWeight: 700,
    margin: 1,
  },
  avatarEnabled: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
  avatarDisabled: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[400],
  },
  avatars: {
    display: "flex",
    flexDirection: "row",
  },
});

const getInitials = (email, firstName, lastName) => {
  if (firstName || lastName) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }
  return email.charAt(0).toUpperCase() + email.charAt(1).toUpperCase();
};

export function NotificationUsers(props) {
  const { classes, users } = props;
  return (
    <div className={classes.avatars}>
      {users.map((user) => (
        <Tooltip
          key={user.userId}
          title={`${
            (user.firstName || user.lastName) &&
            `${user.firstName} ${user.lastName} -`
          } ${user.email}`}
        >
          <Avatar
            key={user.userId}
            className={`${classes.avatar} ${
              user.email ? classes.avatarEnabled : classes.avatarDisabled
            }`}
          >
            {getInitials(user.email, user.firstName, user.lastName)}
          </Avatar>
        </Tooltip>
      ))}
    </div>
  );
}

NotificationUsers.propTypes = {
  users: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationUsers);
