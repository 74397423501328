import React, { FC, useContext, useMemo } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Formik, FormikErrors } from "formik";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { editDriver } from "../../../actions/telematicsCatalogues.actions";

import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import { AsyncFn, Thunk } from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import TCRow from "../shared/TCRow";

import { COLUMN_NAMES } from "./drivers.columns";
import DriversListEditDialogForm from "./DriversListEditDialogForm";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  Catalogue,
  DriverTo,
} from "../../../../shared/api/telematics/telematics.types";

interface Props {
  data: DriverTo;
  editDriver: (id: number, data: DriversListFormValues) => void;
  onEditSuccess: () => void;
}

export interface DriversListFormValues {
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  personalNumber: {
    color: (isHistorical) =>
      isHistorical ? theme.palette.error.main : theme.palette.grey[500],
  },
  name: {
    fontWeight: 500,
    "& ~ $personalNumber": {
      marginTop: 4,
    },
  },
}));

const handleValidate = (values: DriversListFormValues) => {
  const errors: FormikErrors<DriversListFormValues> = {};
  if (!values.name) {
    errors.name = "validation.required";
  }
  return errors;
};

const DriversListRow: FC<Props> = ({ data, editDriver, onEditSuccess }) => {
  let isHistoricalItem = false;

  if (data.validTo) {
    const isEarlierThanToday =
      new Date().getTime() > new Date(data?.validTo).getTime();
    isHistoricalItem = isEarlierThanToday;
  }
  const { catalogueType } = useContext(CataloguesContext);
  const classes = useStyles(isHistoricalItem);

  const initialValues = useMemo(
    () => ({
      code: data.code ?? "",
      name: data.name ?? "",
    }),
    [data],
  );

  const handleSubmit = async (values: DriversListFormValues) => {
    // todo fix swagger
    if (!data.id) return;
    const res = await (editDriver as AsyncFn<number, DriversListFormValues>)(
      data.id,
      values,
    );
    if (res.error) {
      return Promise.reject({ messageId: "Catalogues.table.shared.editError" });
    } else {
      return Promise.resolve({ handleSuccessFn: onEditSuccess });
    }
  };

  return (
    <Formik<DriversListFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      <TCRow
        dialogContent={<DriversListEditDialogForm />}
        dialogTitle="Catalogues.table.drivers.dialog.title"
        isHistorical={isHistoricalItem}
      >
        <CfTableCell name={COLUMN_NAMES.CODE}>{data.code}</CfTableCell>
        <CfTableCell name={COLUMN_NAMES.NAME}>
          <div className={classes.name}>{data.name ?? "-"}</div>
          {catalogueType === Catalogue.WINFAS && (
            <div className={classes.personalNumber}>
              {data.personalNumber ?? "-"}
            </div>
          )}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_FROM}>
          {data.validFrom ? <FormattedDate value={data.validFrom} /> : "-"}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_TO}>
          {data.validTo ? <FormattedDate value={data.validTo} /> : "-"}
        </CfTableCell>
      </TCRow>
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      editDriver,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DriversListRow);
