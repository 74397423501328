import * as types from "../actions/manager.constants";

export default () =>
  (
    state = {
      dialogOpen: false,
      actionButtonEnabled: false,
      actionButtonHidden: false,
      sensorsTabActive: false,
      irrigationTabActive: false,
    },
    action,
  ) => {
    switch (action.type) {
      case types.SET_DIALOG_OPEN:
        return {
          ...state,
          dialogOpen: action.dialogOpen,
        };
      case types.SET_ACTION_BUTTON_ENABLED:
        return {
          ...state,
          actionButtonEnabled: action.actionButtonEnabled,
        };
      case types.SET_ACTION_BUTTON_HIDDEN:
        return {
          ...state,
          actionButtonHidden: action.actionButtonHidden,
        };
      case types.SET_SENSOR_TAB_ACTIVE:
        return {
          ...state,
          sensorsTabActive: action.sensorsTabActive,
        };
      case types.SET_IRRIGATION_TAB_ACTIVE:
        return {
          ...state,
          irrigationTabActive: action.irrigationTabActive,
        };
      default:
        return state;
    }
  };
