import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import groupsUI from "../sensors/reducer/groups.reducer";
import locationsUI from "../sensors/reducer/locations.reducer";
import mapUI from "../sensors/reducer/map.reducer";
import nodeUI from "../sensors/reducer/node.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import groupsApi from "../shared/api/iot/groups/groups.reducer";
import locationsApi from "../shared/api/iot/locations/locations.reducer";
import nodesApi from "../shared/api/iot/nodes/nodes.reducer";
import sensorsApi from "../shared/api/iot/sensors/sensors.reducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      node: nodeUI(),
      locations: locationsUI(),
      groups: groupsUI(),
      map: mapUI(),
      cache: () => ["locations", "groups"],
    }),
    api: combineReducers({
      nodes: nodesApi(),
      locations: locationsApi(),
      sensors: sensorsApi(),
      groups: groupsApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
