import React, { Component } from "react";

import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CfAutosuggest from "../../common/CfAutosuggest/CfAutosuggest";

class ParcelSelector extends Component {
  getSuggestionValue = (suggestion) =>
    `${suggestion.blockNr ? `${suggestion.blockNr},` : ""} ${suggestion.name}`;

  render() {
    const { disableSuggestion } = this.props;
    const { formatMessage } = this.props.intl;
    const placeholder = formatMessage({ id: "ParcelSelector.placeholder" });

    return (
      <CfAutosuggest
        autoFocus={this.props.autoFocus}
        clearSuggestions={this.props.onSuggestionsClearRequested}
        disableSuggestion={disableSuggestion}
        getSuggestions={this.props.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
        inputRef={this.props.inputRef}
        multiSection={false}
        onSuggestionSelected={this.props.onSuggestionSelected}
        placeholder={placeholder}
        requiredLength={3}
        suggestions={this.props.suggestions}
        testData="parcel-selector"
      />
    );
  }
}

ParcelSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  onSuggestionSelected: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionsClearRequested: PropTypes.func,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
  disableSuggestion: PropTypes.func,
};

ParcelSelector.defaultProps = {
  suggestions: [],
  onSuggestionSelected: () => {},
  onSuggestionsFetchRequested: () => {},
  onSuggestionsClearRequested: () => {},
  autoFocus: false,
  inputRef: () => {},
  disableSuggestion: () => {},
};

export default injectIntl(ParcelSelector);
