import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const ChangePasswordIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M17.75,8.52h-1v-2a4.8,4.8,0,0,0-4.71-4.87A4.88,4.88,0,0,0,7.24,6.52v2h-1a1.9,1.9,0,0,0-1.9,1.9v10a1.9,1.9,0,0,0,1.9,1.9h11.5a1.9,1.9,0,0,0,1.9-1.9v-10A1.9,1.9,0,0,0,17.75,8.52Zm-8.69-2a3.05,3.05,0,0,1,3-3.08,3,3,0,0,1,3,3.08v2h-6ZM12,20.46a3.94,3.94,0,0,1-3.94-4v-.4H9.64v.4A2.39,2.39,0,0,0,12,18.87a2.37,2.37,0,0,0,2.31-2.29,2.4,2.4,0,0,0-1.92-2.29v2.49L9.16,13.59l3.18-3.18V12.5a4.11,4.11,0,0,1,3.56,4A4,4,0,0,1,12,20.46Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { ChangePasswordIcon };
