import React, { FC } from "react";

import { Checkbox } from "@mui/material";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";

interface Props {
  defaultValues: boolean;
  isEditing: boolean;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}

const CheckboxField: FC<Props> = ({
  defaultValues,
  isEditing,
  label,
  name,
  onChange,
}) => (
  <>
    <Field
      checked={defaultValues}
      component={Checkbox}
      disabled={!isEditing}
      name={name}
      style={{ paddingLeft: 0 }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(event, name)
      }
    />
    <span style={isEditing ? {} : { color: "rgba(0, 0, 0, 0.26)" }}>
      <FormattedMessage id={label} />
    </span>
  </>
);

export default CheckboxField;
