/**
 * Created by ondrejzvara on 9.12.15.
 */

/* @ngInject */
export function unit($filter, ActionEnums) {
  function filter(input, unitId, decimal) {
    if (!_.isUndefined(input) && !_.isNull(input)) {
      if (_.isNumber(input) && unitId) {
        return `${$filter("number")(
          input,
          decimal || decimal === 0 ? decimal : 3,
        )} ${getUnitName(unitId)}`;
      } else if (_.isNumber(input) && !unitId) {
        // when unitId is not defined
        return $filter("number")(input, decimal || decimal === 0 ? decimal : 3);
      } else if (_.isString(input)) {
        // sometimes you need just to display the unit
        return input + getUnitName(unitId);
      }
    } else {
      return "-";
    }
  }

  function getUnitName(unitId) {
    const unit = _.find(ActionEnums.Unit, _.matchesProperty("id", unitId));
    return unit ? $filter("translate")(`unit.${unit.id}`) : unitId;
  }

  filter.$stateful = true;
  return filter;
}
