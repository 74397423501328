import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";

const styles = (theme) => ({
  layerRowCheckbox: {
    width: 36,
    height: 36,
    marginLeft: 6,
    marginRight: 2,
  },
  layerRow: {
    height: 36,
    marginBottom: 0,
  },
  layerHeaderRowLabel: {
    fontWeight: 500,
    fontSize: 14,
  },
  layerRowLabel: {
    fontSize: 14,
  },
  grayed: {
    color: theme.palette.grey[500],
  },
});

function MapSwitcherCheckbox(props) {
  const {
    checked,
    classes,
    disabled,
    grayed,
    header,
    indeterminate,
    label,
    onChange,
  } = props;

  return (
    <FormControlLabel
      className={classes.layerRow}
      label={label}
      classes={{
        label: classNames({
          [classes.layerHeaderRowLabel]: header,
          [classes.layerRowLabel]: !header,
          [classes.grayed]: grayed,
        }),
      }}
      control={
        <Checkbox
          checked={checked}
          className={classes.layerRowCheckbox}
          color="primary"
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      }
    />
  );
}

MapSwitcherCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  classes: PropTypes.object,
  header: PropTypes.bool,
  disabled: PropTypes.bool,
  grayed: PropTypes.bool,
};

MapSwitcherCheckbox.defaultProps = {
  classes: {},
  indeterminate: false,
  header: false,
  disabled: false,
  grayed: false,
};

export default withStyles(styles)(MapSwitcherCheckbox);
