import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = () => ({
  hoverStopper: {
    top: "-45%",
    left: "-2%",
    padding: "10px",
    position: "absolute",
    width: "104%",
    height: "180%",
  },
});

const ToolbarHoverStopper = (props) => {
  const { classes } = props;
  return <div className={classes.hoverStopper} />;
};

ToolbarHoverStopper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ToolbarHoverStopper);
