export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_ERROR = "CREATE_AREA_ERROR";
export const CREATE_AREA_REQUEST = "CREATE_AREA_REQUEST";

export const EDIT_AREA_SUCCESS = "EDIT_AREA_SUCCESS";
export const EDIT_AREA_ERROR = "EDIT_AREA_ERROR";
export const EDIT_AREA_REQUEST = "EDIT_AREA_REQUEST";

export const DELETE_AREA_DEVICE_REQUEST = "DELETE_AREA_DEVICE_REQUEST";
export const DELETE_AREA_DEVICE_SUCCESS = "DELETE_AREA_DEVICE_SUCCESS";
export const DELETE_AREA_DEVICE_ERROR = "DELETE_AREA_DEVICE_ERROR";

export const EDIT_AREA_NAME_SUCCESS = "EDIT_AREA_NAME_SUCCESS";
export const EDIT_AREA_NAME_ERROR = "EDIT_AREA_NAME_ERROR";
export const EDIT_AREA_NAME_REQUEST = "EDIT_AREA_NAME_REQUEST";

export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_ERROR = "GET_AREAS_ERROR";
export const GET_AREAS_REQUEST = "GET_AREAS_REQUEST";

export const GET_AREA_SUCCESS = "GET_AREA_SUCCESS";
export const GET_AREA_ERROR = "GET_AREA_ERROR";
export const GET_AREA_REQUEST = "GET_AREA_REQUEST";

export const RESET_AREA = "RESET_AREA";

export const GET_AREA_PROGRAMS_SUCCESS = "GET_AREA_PROGRAMS_SUCCESS";
export const GET_AREA_PROGRAMS_ERROR = "GET_AREA_PROGRAMS_ERROR";
export const GET_AREA_PROGRAMS_REQUEST = "GET_AREA_PROGRAMS_REQUEST";

export const DELETE_AREA_REQUEST = "DELETE_AREA_REQUEST";
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const DELETE_AREA_ERROR = "DELETE_AREA_ERROR";

export const GET_AREA_PROGRAM_SUCCESS = "GET_AREA_PROGRAM_SUCCESS";
export const GET_AREA_PROGRAM_ERROR = "GET_AREA_PROGRAM_ERROR";
export const GET_AREA_PROGRAM_REQUEST = "GET_AREA_PROGRAM_REQUEST";

export const RESET_AREA_PROGRAM = "RESET_AREA_PROGRAM";

export const SET_AREA_PROGRAM = "SET_AREA_PROGRAM";

export const CREATE_AREA_PROGRAM_SUCCESS = "CREATE_AREA_PROGRAM_SUCCESS";
export const CREATE_AREA_PROGRAM_ERROR = "CREATE_AREA_PROGRAM_ERROR";
export const CREATE_AREA_PROGRAM_REQUEST = "CREATE_AREA_PROGRAM_REQUEST";

export const UPDATE_AREA_PROGRAM_SUCCESS = "UPDATE_AREA_PROGRAM_SUCCESS";
export const UPDATE_AREA_PROGRAM_ERROR = "UPDATE_AREA_PROGRAM_ERROR";
export const UPDATE_AREA_PROGRAM_REQUEST = "UPDATE_AREA_PROGRAM_REQUEST";

export const DELETE_AREA_PROGRAM_REQUEST = "DELETE_AREA_PROGRAM_REQUEST";
export const DELETE_AREA_PROGRAM_SUCCESS = "DELETE_AREA_PROGRAM_SUCCESS";
export const DELETE_AREA_PROGRAM_ERROR = "DELETE_AREA_PROGRAM_ERROR";

export const RESET_API_ERROR = "RESET_API_ERROR";
