import React, { FC, Fragment } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import withPopover from "../../../shared/hocs/withPopover";
import { useToggle } from "../../../shared/hooks/useToggle";
import useVarioDocBulkItems from "../hooks/useVarioDocBulkItems";

import AsAppliedDeleteDialog from "./AsAppliedDeleteDialog";

export interface BulkButtonProps {
  anchorEl?: null | Element;
  handlePopoverClose: () => void;
  handlePopoverOpen: (evt: React.SyntheticEvent) => void;
  isOpen: boolean;
  onDownload: (e: React.MouseEvent) => void;
  selected: number[];
}

export const BulkButton: FC<BulkButtonProps> = ({
  anchorEl = null,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  onDownload,
  selected,
}) => {
  const classes = useStyles();
  const {
    on: deleteIsOpen,
    setOff: handleDeleteClose,
    setOn: handleDeleteOpen,
  } = useToggle();

  const ListItems = useVarioDocBulkItems({
    onClick: handlePopoverClose,
    onDelete: handleDeleteOpen,
    onDownload,
    selected,
  });

  return (
    <>
      <Button
        className={`${classes.button} ${selected.length ? classes.activeButton : ""}`}
        disabled={!selected.length}
        onClick={(evt: React.SyntheticEvent) => {
          handlePopoverOpen(evt);
        }}
      >
        <span className={classes.selectedLabel}>
          <FormattedMessage id="common.selected" />
          {selected.length > 0 && `\u00A0(${selected.length})`}
        </span>
        <ArrowDropDownIcon className={classes.rightIcon} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isOpen}
        onClick={() => {
          handlePopoverClose();
        }}
        onClose={() => {
          handlePopoverClose();
        }}
      >
        {ListItems}
      </Menu>
      {deleteIsOpen && (
        <AsAppliedDeleteDialog
          handleClose={handleDeleteClose}
          open={deleteIsOpen}
        />
      )}
    </>
  );
};

export default withPopover(BulkButton);

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    paddingRight: 6,
    marginRight: 8,
  },
  rightIcon: {
    position: "relative",
    top: -1,
  },
  selectedLabel: {
    paddingTop: 2,
  },
}));
