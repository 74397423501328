/**
 * Created by ondrejzvara on 5.4.16.
 */

import TestUtils from "./TestUtils.service";
import TestGeoJSONProvider from "./TestGeoJSONProvider.service";

export default angular
  .module("app.test", [])

  .factory("TestUtils", TestUtils)
  .factory("TestGeoJSONProvider", TestGeoJSONProvider)

  .constant("FARM_ID", 1234)

  .constant("CREDENTIALS", {
    username: "user",
    password: "pass",
  })

  .constant("API_ERROR", {
    // mapping of action types to view routes (for creating actions)
    404: {
      timestamp: "1234",
      status: 404,
      error: "Not Found",
      message: "No message available",
      path: "",
    },
  })

  //the JWT token expires when jenc is already a long time dead
  .constant("API_RESPONSE", {
    token: {
      id_token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1MTI2ODA4NDksImV4cCI6Nzk4MTc5NDQ0OSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSJ9.1yhVqnTKgzmqjQz7vcsnegdg4sbg6HnRg3KChzSm5hY-bgxXcqv9DBE3Dzs1re40FeV5Kh_cxxjX0ofJQR65rg",
      principal: "test",
    },

    farms: [
      {
        id: "123",
        name: "test farm name",
        code: "test_farm_code",
        customer: {
          id: 1,
          schemaName: "cust_test_farm_schema",
          countryCode: "RS",
        },
      },
      { id: "2" },
    ],

    eph_por_action: {
      "@id": "1",
      id: 4671670449079295,
      dateStart: "2016-04-12T00:00:00Z",
      dateEnd: "2016-04-12T00:00:00Z",
      note: "",
      fertilizationTarget: null,
      jetType: null,
      parcelList: [
        {
          "@id": "2",
          id: 4671670453273346,
          area: 12.58079796527754,
          restrictedArea: 0,
          seedApplication: {
            "@id": "3",
            id: 4582484928889623,
            cropUsage: null,
            seed: {
              "@class": "MaterialSeed",
              "@id": "4",
              id: 4582484589151043,
              legislativeCode: null,
              isDeleted: false,
              name: "Řepka ozimá-Sherpa",
              description: "Automatically generated Seed.",
              priceUnit: null,
              materialType: {
                "@id": "5",
                id: "SD",
                name: null,
                description: null,
                isDeleted: false,
              },
              provider: null,
              unit: null,
              comment: null,
              note: "Automatically generated Seed.",
              crop: {
                "@id": "6",
                id: 4582484360561454,
                legislativeCode: 261,
                isDeleted: false,
                name: "Řepka ozimá",
                description: null,
                code: 0,
                nitrogenLimit: 230,
                isWinterCrop: true,
                isSpringCrop: false,
                cropGroup: {
                  "@id": "7",
                  id: 4582484360561453,
                  legislativeCode: 8,
                  isDeleted: false,
                  name: "Olejniny a přadné rostliny",
                  color: "#CCCCCC",
                },
                products: [
                  {
                    "@id": "8",
                    id: 4582484360561455,
                    legislativeCode: 4,
                    isDeleted: false,
                    name: "zrno",
                    unit: {
                      "@id": "9",
                      id: "t",
                      name: null,
                      isDeleted: false,
                      coef: 1000,
                      isSelectable: false,
                      quantity: {
                        "@id": "10",
                        id: "M",
                        name: "mass",
                        isDeleted: false,
                      },
                      baseUnit: null,
                    },
                  },
                ],
                isCatchCrop: false,
                isUndersowingCrop: false,
                isNitrogenBindingCrop: false,
              },
              variety: {
                "@id": "11",
                id: 4582484589151042,
                legislativeCode: 12335,
                isDeleted: false,
                name: "Sherpa",
                description: null,
                crop: {
                  "@ref": "6",
                },
              },
            },
          },
          parcel: {
            "@id": "12",
            id: 4582484893238151,
            lot: "Pod lagunou Rasovna",
            localName: "Pod lagunou Rasovna",
            area: 12.58079796527754,
            notes: "12335 Sherpa",
            createdOn: "2016-04-07T07:40:39.05Z",
            retiredOn: null,
            status: "O",
            region: "Háje u Chebu",
            validTo: null,
            validFrom: "2015-01-23T00:00:00Z",
            center: null,
            zone: null,
            greening: false,
            dunghill: false,
            parcelBlockList: [
              {
                id: 4582484893238152,
                area: 12.58079796527754,
                lpisBlock: {
                  "@id": "13",
                  id: 4582484347978526,
                  year: 2016,
                  countyId: 636576,
                  unitId: 889102603,
                  blockNr: 9603,
                  catUse: null,
                  areaDec: 12.58,
                  agroEnv: "false",
                  fullBlock: true,
                  inserted: "2016-04-07T07:40:37.317Z",
                  updated: null,
                  comment: null,
                  blockNr2: 2,
                  square: "880-1020",
                  slope: 3.7,
                  erosionCode: null,
                  nva: false,
                  water25: false,
                  waterDistance: 9.63,
                  climateRegion: 5,
                  landUse: {
                    "@id": "15",
                    id: 2,
                    code: "R",
                    name: "Orná půda",
                  },
                  status: "O",
                  validTo: null,
                  validFrom: "2015-01-23T00:00:00Z",
                  applicationZone: null,
                  altitude: 505.12,
                },
              },
            ],
            blockNr: "9603/2",
            efaAllowed: false,
            efa: false,
            nitrateVulnerable: false,
            aeko: false,
            aekoAllowed: false,
            currentSeedApplication: {
              "@ref": "3",
            },
          },
        },
      ],
      materialList: [
        {
          "@id": "16",
          id: 4671670451176192,
          material: {
            "@class": "MaterialChemistry",
            "@id": "17",
            id: 4584540743926610,
            legislativeCode: 24725,
            isDeleted: false,
            name: "Acanto",
            description: "V",
            priceUnit: null,
            materialType: {
              "@id": "18",
              id: "CH",
              name: null,
              description: null,
              isDeleted: false,
            },
            provider: null,
            unit: {
              "@id": "19",
              id: "l/ha",
              name: null,
              isDeleted: false,
              coef: 1,
              isSelectable: false,
              quantity: {
                "@id": "20",
                id: "V/A",
                name: "volume per area",
                isDeleted: false,
              },
              baseUnit: null,
            },
            comment: null,
            clMaterialRestrictionList: [
              {
                "@id": "21",
                id: 4584540750218077,
                protectionPeriod: null,
                applicationCount: 1,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 61,
                maxBbch: 51,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: null,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: null,
                isDeleted: false,
                clCropList: [],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [],
                comment: null,
              },
              {
                "@id": "22",
                id: 4584540750218076,
                protectionPeriod: null,
                applicationCount: 1,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 69,
                maxBbch: 61,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: null,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: null,
                isDeleted: false,
                clCropList: [],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [],
                comment: null,
              },
              {
                "@id": "23",
                id: 4584540750218078,
                protectionPeriod: null,
                applicationCount: null,
                slope: null,
                waterBuffer: null,
                waterBuffer50: null,
                waterBuffer75: null,
                waterBuffer90: null,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: null,
                maxBbch: null,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: null,
                materialDosageMin: 1,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: "1 l/ha",
                waterDosageMin: 0,
                waterDosageMax: 0,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: "1) od: 59 BBCH, do: 65 BBCH3) max. 2x, v intervalu 14 dnů minimálně",
                isDeleted: false,
                clCropList: [
                  {
                    "@id": "24",
                    id: 4584540725052228,
                    legislativeCode: 262,
                    isDeleted: false,
                    name: "Slunečnice",
                    description: null,
                    code: 0,
                    nitrogenLimit: 140,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "7",
                    },
                    products: [
                      {
                        "@id": "25",
                        id: 4584540725052229,
                        legislativeCode: 5,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "26",
                    id: 4584540750218079,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "23",
                    },
                    organism: {
                      "@id": "27",
                      id: 4584540727149382,
                      legislativeCode: 273,
                      isDeleted: false,
                      name: "hlízenka obecná",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@id": "28",
                        id: 22,
                        name: "Organismus PPP",
                        isDeleted: false,
                        legislativeKey: "PP",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "29",
                id: 4584540752315241,
                protectionPeriod: null,
                applicationCount: null,
                slope: null,
                waterBuffer: null,
                waterBuffer50: null,
                waterBuffer75: null,
                waterBuffer90: null,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: null,
                maxBbch: null,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: null,
                materialDosageMin: 1,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: "1,0 l/ha",
                waterDosageMin: 0,
                waterDosageMax: 0,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: "1) od: 51 BBCH, do: 61 BBCH",
                isDeleted: false,
                clCropList: [
                  {
                    "@id": "30",
                    id: 4584540737635150,
                    legislativeCode: 259,
                    isDeleted: false,
                    name: "Mák",
                    description: null,
                    code: 0,
                    nitrogenLimit: 85,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "7",
                    },
                    products: [
                      {
                        "@id": "31",
                        id: 4584540737635151,
                        legislativeCode: 3,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "32",
                    id: 4584540754412395,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "29",
                    },
                    organism: {
                      "@id": "33",
                      id: 4584540739732305,
                      legislativeCode: 888,
                      isDeleted: false,
                      name: "plíseň maková",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "34",
                    id: 4584540754412394,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "29",
                    },
                    organism: {
                      "@id": "35",
                      id: 4584540737635152,
                      legislativeCode: 261,
                      isDeleted: false,
                      name: "helminthosporióza máku",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "36",
                id: 4584540748120920,
                protectionPeriod: null,
                applicationCount: null,
                slope: null,
                waterBuffer: null,
                waterBuffer50: null,
                waterBuffer75: null,
                waterBuffer90: null,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: null,
                maxBbch: null,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: null,
                materialDosageMin: 1,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: "1 l/ha",
                waterDosageMin: 0,
                waterDosageMax: 0,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 35,
                withdrawalPeriodText: "35",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: "1) od: 25 BBCH, do: 69 BBCH3) max. 2x, v intervalu 14 dnů minimálně",
                isDeleted: false,
                clCropList: [
                  {
                    "@id": "37",
                    id: 4584540714566461,
                    legislativeCode: 114,
                    isDeleted: false,
                    name: "Pšenice dvouzrnka",
                    description: null,
                    code: 0,
                    nitrogenLimit: null,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@id": "38",
                      id: 4582484400407346,
                      legislativeCode: 6,
                      isDeleted: false,
                      name: "Obilniny",
                      color: "#FFFF73",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "39",
                    id: 4584540712469308,
                    legislativeCode: 107,
                    isDeleted: false,
                    name: "Pšenice tvrdá jarní",
                    description: null,
                    code: 0,
                    nitrogenLimit: 120,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "40",
                    id: 4584540716663615,
                    legislativeCode: 104,
                    isDeleted: false,
                    name: "Pšenice setá jarní",
                    description: null,
                    code: 0,
                    nitrogenLimit: 120,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [
                      {
                        "@id": "41",
                        id: 4584540716663616,
                        legislativeCode: 2,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "42",
                    id: 4584540714566462,
                    legislativeCode: 106,
                    isDeleted: false,
                    name: "Pšenice špalda",
                    description: null,
                    code: 0,
                    nitrogenLimit: null,
                    isWinterCrop: true,
                    isSpringCrop: false,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "43",
                    id: 4582484459127608,
                    legislativeCode: 105,
                    isDeleted: false,
                    name: "Pšenice setá ozimá",
                    description: null,
                    code: 0,
                    nitrogenLimit: 190,
                    isWinterCrop: true,
                    isSpringCrop: false,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [
                      {
                        "@id": "44",
                        id: 4582484459127609,
                        legislativeCode: 1,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "45",
                    id: 4584540710372155,
                    legislativeCode: 244,
                    isDeleted: false,
                    name: "Pšenice tvrdá jarní okrasná",
                    description: null,
                    code: 0,
                    nitrogenLimit: 120,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@id": "46",
                      id: 4584540710372154,
                      legislativeCode: 7,
                      isDeleted: false,
                      name: "Okrasné rostliny",
                      color: "#EBBDFC",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "47",
                    id: 4584540720857921,
                    legislativeCode: 108,
                    isDeleted: false,
                    name: "Pšenice tvrdá ozimá",
                    description: null,
                    code: 0,
                    nitrogenLimit: 190,
                    isWinterCrop: true,
                    isSpringCrop: false,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "48",
                    id: 4584540750218073,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "36",
                    },
                    organism: {
                      "@id": "49",
                      id: 4584540722955074,
                      legislativeCode: 1060,
                      isDeleted: false,
                      name: "rez pšeničná",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "50",
                    id: 4584540750218075,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "36",
                    },
                    organism: {
                      "@id": "51",
                      id: 4584540704080694,
                      legislativeCode: 819,
                      isDeleted: false,
                      name: "padlí travní",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "52",
                    id: 4584540750218074,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "36",
                    },
                    organism: {
                      "@id": "53",
                      id: 4584540722955075,
                      legislativeCode: 90,
                      isDeleted: false,
                      name: "braničnatka plevová",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "54",
                id: 4584540752315237,
                protectionPeriod: null,
                applicationCount: 2,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 65,
                maxBbch: 59,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: null,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: 14,
                repeatApplicationMaxDays: 14,
                decs: null,
                isDeleted: false,
                clCropList: [],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [],
                comment: null,
              },
              {
                "@id": "55",
                id: 4584540752315236,
                protectionPeriod: null,
                applicationCount: 2,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 61,
                maxBbch: 25,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: null,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 35,
                withdrawalPeriodText: "35",
                repeatApplicationMinDays: 14,
                repeatApplicationMaxDays: 14,
                decs: null,
                isDeleted: false,
                clCropList: [],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [],
                comment: null,
              },
              {
                "@id": "56",
                id: 4584540752315238,
                protectionPeriod: null,
                applicationCount: null,
                slope: null,
                waterBuffer: null,
                waterBuffer50: null,
                waterBuffer75: null,
                waterBuffer90: null,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: null,
                maxBbch: null,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: null,
                materialDosageMin: 1,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: "1,0 l/ha",
                waterDosageMin: 0,
                waterDosageMax: 0,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 0,
                withdrawalPeriodText: "AT",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: "1) od: 61 BBCH, od: 69 BBCH",
                isDeleted: false,
                clCropList: [
                  {
                    "@ref": "6",
                  },
                  {
                    "@id": "57",
                    id: 4584540727149383,
                    legislativeCode: 260,
                    isDeleted: false,
                    name: "Řepka jarní",
                    description: null,
                    code: 0,
                    nitrogenLimit: null,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "7",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "58",
                    id: 4584540752315240,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "56",
                    },
                    organism: {
                      "@ref": "27",
                    },
                  },
                  {
                    "@id": "59",
                    id: 4584540752315239,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "56",
                    },
                    organism: {
                      "@id": "60",
                      id: 4584540735537997,
                      legislativeCode: 159,
                      isDeleted: false,
                      name: "čerň řepková",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "61",
                id: 4584540750218080,
                protectionPeriod: null,
                applicationCount: 2,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 49,
                maxBbch: 31,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: 0.8,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 42,
                withdrawalPeriodText: "42",
                repeatApplicationMinDays: 21,
                repeatApplicationMaxDays: 21,
                decs: null,
                isDeleted: false,
                clCropList: [
                  {
                    "@id": "62",
                    id: 4584540729246537,
                    legislativeCode: 2,
                    isDeleted: false,
                    name: "Cukrovka",
                    description: null,
                    code: 0,
                    nitrogenLimit: 210,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@id": "63",
                      id: 4584540729246536,
                      legislativeCode: 1,
                      isDeleted: false,
                      name: "Brambory a jiné okopaniny",
                      color: "#894444",
                    },
                    products: [
                      {
                        "@id": "64",
                        id: 4584540729246538,
                        legislativeCode: 15,
                        isDeleted: false,
                        name: "bulvy",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@ref": "57",
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "65",
                    id: 4584540752315233,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "61",
                    },
                    organism: {
                      "@id": "66",
                      id: 4584540731343691,
                      legislativeCode: 126,
                      isDeleted: false,
                      name: "cerkosporióza řepy",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "67",
                    id: 4584540752315234,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "61",
                    },
                    organism: {
                      "@id": "68",
                      id: 4584540731343692,
                      legislativeCode: 817,
                      isDeleted: false,
                      name: "padlí řepné",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "69",
                id: 4584540748120915,
                protectionPeriod: null,
                applicationCount: null,
                slope: null,
                waterBuffer: null,
                waterBuffer50: null,
                waterBuffer75: null,
                waterBuffer90: null,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: null,
                maxBbch: null,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: null,
                materialDosageMin: 1,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: "1 l/ha",
                waterDosageMin: 0,
                waterDosageMax: 0,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 35,
                withdrawalPeriodText: "35",
                repeatApplicationMinDays: null,
                repeatApplicationMaxDays: null,
                decs: "1) od: 25 BBCH, do: 61 BBCH3) max. 2x, v intervalu 14 dnů minimálně",
                isDeleted: false,
                clCropList: [
                  {
                    "@id": "70",
                    id: 4584540697789236,
                    legislativeCode: 96,
                    isDeleted: false,
                    name: "Ječmen jarní",
                    description: null,
                    code: 0,
                    nitrogenLimit: 110,
                    isWinterCrop: false,
                    isSpringCrop: true,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [
                      {
                        "@id": "71",
                        id: 4584540697789237,
                        legislativeCode: 5,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "72",
                    id: 4582484400407347,
                    legislativeCode: 98,
                    isDeleted: false,
                    name: "Ječmen ozimý víceřadý",
                    description: null,
                    code: 0,
                    nitrogenLimit: 140,
                    isWinterCrop: true,
                    isSpringCrop: false,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                  {
                    "@id": "73",
                    id: 4584540693594930,
                    legislativeCode: 97,
                    isDeleted: false,
                    name: "Ječmen ozimý dvouřadý",
                    description: null,
                    code: 0,
                    nitrogenLimit: 140,
                    isWinterCrop: true,
                    isSpringCrop: false,
                    cropGroup: {
                      "@ref": "38",
                    },
                    products: [
                      {
                        "@id": "74",
                        id: 4584540693594931,
                        legislativeCode: 4,
                        isDeleted: false,
                        name: "zrno",
                        unit: {
                          "@ref": "9",
                        },
                      },
                    ],
                    isCatchCrop: false,
                    isUndersowingCrop: false,
                    isNitrogenBindingCrop: false,
                  },
                ],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [
                  {
                    "@id": "75",
                    id: 4584540748120917,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "69",
                    },
                    organism: {
                      "@id": "76",
                      id: 4584540706177847,
                      legislativeCode: 1066,
                      isDeleted: false,
                      name: "rynchosporiová skvrnitost",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "77",
                    id: 4584540748120916,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "69",
                    },
                    organism: {
                      "@ref": "51",
                    },
                  },
                  {
                    "@id": "78",
                    id: 4584540748120919,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "69",
                    },
                    organism: {
                      "@id": "79",
                      id: 4584540708275001,
                      legislativeCode: 288,
                      isDeleted: false,
                      name: "hnědá skvrnitost ječmene",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                  {
                    "@id": "80",
                    id: 4584540748120918,
                    nontarget: false,
                    materialRestriction: {
                      "@ref": "69",
                    },
                    organism: {
                      "@id": "81",
                      id: 4584540706177848,
                      legislativeCode: 1055,
                      isDeleted: false,
                      name: "rez ječná",
                      description: null,
                      parentOrganism: null,
                      group: {
                        "@ref": "28",
                      },
                    },
                  },
                ],
                comment: null,
              },
              {
                "@id": "82",
                id: 4584540752315235,
                protectionPeriod: null,
                applicationCount: 2,
                slope: null,
                waterBuffer: 4,
                waterBuffer50: 4,
                waterBuffer75: 4,
                waterBuffer90: 4,
                parcelBuffer: null,
                parcelBuffer50: null,
                parcelBuffer75: null,
                parcelBuffer90: null,
                drift: null,
                nextCropRestriction: null,
                phoDistance: null,
                mandatoryDosage: null,
                undersowing: null,
                seedTreatment: null,
                minBbch: 69,
                maxBbch: 25,
                minBbchOrg: null,
                maxBbchOrg: null,
                protectionZone: true,
                materialDosageMin: null,
                materialDosageMax: 1,
                materialDosageUnit: {
                  "@ref": "19",
                },
                materialDosageDesc: null,
                waterDosageMin: 200,
                waterDosageMax: 400,
                waterDosageUnit: {
                  "@ref": "19",
                },
                withdrawalPeriodDays: 35,
                withdrawalPeriodText: "35",
                repeatApplicationMinDays: 14,
                repeatApplicationMaxDays: 14,
                decs: null,
                isDeleted: false,
                clCropList: [],
                materialAppliable: {
                  "@ref": "17",
                },
                mixture: null,
                clMaterialRestrictionOrganisms: [],
                comment: null,
              },
            ],
            marketingAuth: "DuPont CZ s.r.o.",
            aktualState: "Z",
            eko: false,
            legalStart: "2012-12-04T00:00:00Z",
            legalEnd: "2015-04-11T00:00:00Z",
            marketEnd: "2015-10-11T00:00:00Z",
            useEnd: "2016-10-11T00:00:00Z",
            regNumber: "4499-1",
            bioFunction: "Fungicid",
            clMaterialChemistryDataList: [
              {
                "@id": "83",
                id: 4584540756509548,
                name: "Acanto (4499-1) DUP",
                description: "Další informace o použití",
                size: 326040,
                type: "997",
                dataLocation:
                  "http://eagri.cz/public/app/srs_pub/pp_public/rpg10a_util.download_ii?xid=3843",
                contentType: "application/pdf",
                originUrl:
                  "http://eagri.cz/public/app/srs_pub/pp_public/rpg10a_util.download_ii?xid=3843",
                clMaterialChemistry: {
                  "@ref": "17",
                },
              },
            ],
            clMaterialActiveSubstanceList: [],
            label: null,
            batches: [],
            modified: null,
            exclusionProtectionZoneII: null,
            airApplication: null,
          },
        },
      ],
      actionType: {
        "@id": "84",
        id: 1,
        name: "Aplikace postřiku",
        description: null,
        sortIndex: 1,
        isDeleted: false,
        actionClass: {
          "@id": "85",
          code: "CHEM",
          name: "Treatment",
          isDeleted: false,
          color: "000000",
          id: "CHEM",
        },
        color: "E22446",
        actionTypeMachineryList: [],
      },
      confirmed: false,
      targetCrop: {
        "@ref": "6",
      },
      actionState: "UNCONFIRMED",
    },
    products: [
      {
        id: 1,
        legislativeCode: 40,
        name: "cibule",
      },
      {
        id: 2,
        legislativeCode: 42,
        name: "cesnek",
      },
    ],
    action: {
      dateStart: new Date(),
      dateEnd: new Date(),
      note: "",
      fictitious: false,
      materialList: [
        {
          material: null,
        },
      ],
      expenseList: [
        {
          "@class": "ActionExpenseMaterial",
          amount: null, //
          priceUnit: null,
          expenseType: {
            id: "M",
          },
        },
      ],
      revenueList: [
        {
          amount: null,
        },
      ],
      parcelList: [],
      actionType: null,
      actionTypeMachineryList: null,
      sumArea: 0,
      totalAmount: null,
      amountPerUnit: null,
      overallPrice: null,
      unitPrice: null,
      gainPerHectar: null,
      overallGain: null,
      unit: null,
      seed: null,
    },
  }).name;
