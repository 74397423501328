import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getSectionListAdvancedFilter } from "../../../selectors/sectionList.selectors";

import CfFilter from "../../../../shared/containers/CfFilter/CfFilter";

import IsOrganicSelector from "./IsOrganicSelector/IsOrganicSelector";
import NitrogenCategorySelector from "./NitrogenCategorySelector/NitrogenCategorySelector";
import SourceSelector from "./SourceSelector/SourceSelector";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  CatalogueTo,
  EagriFertilizerNitrogenCategoryTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";
import type {
  CataloguesAdvancedFilterType,
  isOrganicType,
} from "../../../reducers/sectionList.types";

export interface Props {
  advancedFilter: CataloguesAdvancedFilterType;
  langId: string;
  namespace: string;
}

export const FertilziersAdvancedFilter: FC<Props> = ({
  advancedFilter,
  langId,
  namespace,
}) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] =
    useState<CataloguesAdvancedFilterType>(advancedFilter);

  const handleChangeNitrogenCategory = (
    items: EagriFertilizerNitrogenCategoryTo[],
  ) => {
    setAdvancedFilterState({ ...advancedFilterState, nitrogenCategory: items });
  };
  const handleChangeSource = (items: CatalogueTo) => {
    setAdvancedFilterState({ ...advancedFilterState, source: items });
  };
  const handleChangeIsOrganic = (items: isOrganicType) => {
    setAdvancedFilterState({ ...advancedFilterState, isOrganic: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <NitrogenCategorySelector
              defaultValues={advancedFilter?.nitrogenCategory}
              isMultiple
              langId={langId}
              onChange={handleChangeNitrogenCategory}
              label={
                <FormattedMessage id="Catalogues.table.fertilizers.column.nitrogenCategory" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <IsOrganicSelector
              defaultIsOrganic={advancedFilter?.isOrganic}
              onChange={handleChangeIsOrganic}
              label={
                <FormattedMessage id="Catalogues.table.fertilizers.column.isOrganic" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <SourceSelector
              defaultSource={advancedFilter?.source}
              onChange={handleChangeSource}
              label={
                <FormattedMessage id="Catalogues.table.fertilizers.column.source" />
              }
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  advancedFilter: getSectionListAdvancedFilter(state),
});

export default connect(mapStateToProps)(FertilziersAdvancedFilter);

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});
