import React from "react";

import { Box, styled } from "@mui/material";

export const CfChartTooltipWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <Wrapper>
    <div>{children}</div>
  </Wrapper>
);

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 3,
  "-webkit-border-radius": 3,
  "-moz-border-radius": 3,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.25)",
  "-moz-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.25)",
  padding: 0,
}));
