import React from "react";

import PropTypes from "prop-types";

import CfAutocomplete from "../../common/CfAutocomplete/CfAutocomplete";

export const SensorFilterByNodeType = ({
  defaultNodeType = [],
  label,
  nodeTypes,
  onChange,
}) => (
  <CfAutocomplete
    defaultValues={defaultNodeType}
    id="node-type-filter"
    isMultiple={true}
    label={label}
    onChange={onChange}
    suggestions={nodeTypes}
    testId="node-type-filter"
  />
);

SensorFilterByNodeType.propTypes = {
  defaultNodeType: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  nodeTypes: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
};
