import React, { FC, useContext, useEffect } from "react";

import { ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getMachineGroups } from "../../../selectors/telematicsCatalogues.selectors";

import { getMachinesGroupsApi } from "../../../../shared/api/telematics/catalogues/telematicsCatalogues.api";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { Thunk } from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import { getMachineIcon } from "../../../helpers/selectorHelpers";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  MachineGroupCode,
  MachineGroupTo,
} from "../../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles({
  select: {
    width: "100%",
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
});

interface Props {
  fetchMachineGroups: () => void;
  fieldName?: string;
  suggestions: MachineGroupTo[];
}

export interface RequiredFormValues {
  [group: string]: MachineGroupCode;
}

const TCMachineGroupSelector: FC<Props> = ({
  fetchMachineGroups,
  fieldName = "group",
  suggestions,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext<RequiredFormValues>();
  const { langId } = useContext(CataloguesContext);

  useEffect(() => {
    fetchMachineGroups();
  }, [langId, fetchMachineGroups]);

  return (
    <CfFormControl>
      <TextField
        className={classes.select}
        disabled
        select
        value={values[fieldName]}
        label={
          <FormattedMessage id="Catalogues.table.machines.dialog.machinesGroup" />
        }
      >
        {suggestions.map((suggestion) => (
          <MenuItem key={suggestion.code} value={suggestion.code}>
            <div className={classes.item}>
              <ListItemIcon>
                <img alt="icon" src={getMachineIcon(suggestion.code)} />
              </ListItemIcon>
              <ListItemText disableTypography primary={suggestion.value} />
            </div>
          </MenuItem>
        ))}
      </TextField>
    </CfFormControl>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchMachineGroups: getMachinesGroupsApi,
    },
    dispatch,
  );

const mapStateToProps = (state: CataloguesState) => ({
  suggestions: getMachineGroups(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TCMachineGroupSelector);
