import {
  getHoveredId,
  getSelected,
} from "../../core/map/selectors/editor.selectors";

import { zoomToCoords } from "../../core/map/actions/map/map.actions";

import * as types from "./map.constants";

import StyleService from "../services/IrrigationMapStyles.service";

export const setMapColumns = (mapColumns) => ({
  type: types.SET_MAP_COLUMNS,
  mapColumns,
});

export const setDefaultExtent = (extent) => ({
  type: types.SET_EXTENT,
  extent,
});

export const setEnlargedVariant = (enlarged) => ({
  type: types.SET_VARIANT_ENLARGED,
  enlarged,
});

export const refreshIrrigationStyles = (pointsLayer) => (_, getState) => {
  const state = getState();
  const hoveredId = getHoveredId(state);
  const selectedId = getSelected(state)[0]?.id || null;
  return StyleService.refreshIrrigationStyles(
    pointsLayer,
    hoveredId,
    selectedId,
  );
};

export const zoomToDevice = (device) => (dispatch) => {
  dispatch(zoomToCoords(device.geometry.coordinates, 19, 500));
};

export const setCoordsSelection = (isSelecting, selectionCoords) => ({
  type: types.SET_COORDS_SELECTION,
  isSelecting,
  selectionCoords,
});
