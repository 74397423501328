import { AnyAction } from "redux";

import * as types from "./reports.constants";

import { eagriReportState } from "./reports.types";

const initialState: eagriReportState = {
  errorFertilizerUsageReport: undefined,
  errorPorUsageReport: undefined,
  isPorUsageReportLoading: false,
  isFertilizerUsageReport: false,
  porHistoryReport: [],
  porTotalCount: 0,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.POST_FERTILIZER_USAGE_REPORT_REQUEST:
        return {
          ...state,
          isFertilizerUsageReport: true,
        };
      case types.POST_FERTILIZER_USAGE_REPORT_SUCCESS:
        return {
          ...state,
          isFertilizerUsageReport: false,
        };
      case types.POST_FERTILIZER_USAGE_REPORT_ERROR:
        return {
          ...state,
          errorFertilizerUsageReport: action.payload.response,
          isFertilizerUsageReport: false,
        };
      case types.POST_FERTILIZER_USAGE_REPORT_RESET:
        return {
          ...state,
          errorFertilizerUsageReport: undefined,
        };
      case types.POST_POR_USAGE_REPORT_REQUEST:
        return {
          ...state,
          isPorUsageReportLoading: true,
        };
      case types.POST_POR_USAGE_REPORT_SUCCESS:
        return {
          ...state,
          isPorUsageReportLoading: false,
        };
      case types.POST_POR_USAGE_REPORT_ERROR:
        return {
          ...state,
          errorPorUsageReport: action.payload.response,
          isPorUsageReportLoading: false,
        };
      case types.GET_POR_HISTORY_REPORT_SUCCESS:
        return {
          ...state,
          porHistoryReport: action.payload,
          porTotalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      default:
        return state;
    }
  };
