import React, { FC } from "react";

import { FormikProps } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import TelematicsDetailDriverSelector from "../../TelematicsDetailSelectors/TelematicsDetailDriverSelector";

import { DriverTo } from "../../../../shared/api/telematics/telematics.types";

interface Props {
  customClasses?: Record<string, string>;
  date: string;
  disabled?: boolean;
  placeholder?: string;
  placeholderId?: string;
  setSelectedDriver?: (val?: DriverTo | null) => void;
}

const DriverField: FC<Props & FormikProps<{ driverCode: string }>> = ({
  customClasses,
  date,
  disabled,
  errors,
  placeholder,
  placeholderId = "TelematicsList.driver",
  setFieldValue,
  setSelectedDriver,
  values,
}) => {
  const handleDriverChange = (driver?: DriverTo | null) => {
    if (setSelectedDriver) {
      setSelectedDriver(driver);
    }
    setFieldValue("driverCode", driver?.code ?? "");
  };
  return (
    <CfFormControl>
      <TelematicsDetailDriverSelector
        customClasses={customClasses}
        dateFrom={date}
        dateTo={date}
        disabled={disabled}
        error={!!errors.driverCode}
        onChange={handleDriverChange}
        placeholder={placeholder}
        selectedDriverCode={values.driverCode}
        helperText={
          errors.driverCode ? (errors.driverCode as string) : undefined
        }
        label={
          placeholder ? undefined : <FormattedMessage id={placeholderId} />
        }
      />
    </CfFormControl>
  );
};

export default DriverField;
