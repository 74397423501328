import React, { FC, useEffect } from "react";

import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingProductionOperations,
  getProductionOperations,
} from "../../../selectors/telematicsCatalogues.selectors";

import { fetchProductionOperations } from "../../../actions/telematicsCatalogues.actions";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { Thunk } from "../../../../types";

import TCSelectorItem from "./TCSelectorItem";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import { ProductionOperationTo } from "../../../../shared/api/telematics/telematics.types";

interface Props {
  fetchProductionOperations: (dFrom?: string, dTo?: string) => void;
  fieldName?: string;
  isFetching: boolean;
  suggestions: ProductionOperationTo[];
}

export interface RequiredFormValues {
  [productionOperation: string]: string;
}

const TCProductionOperationSelector: FC<Props> = ({
  fetchProductionOperations,
  fieldName = "productionOperation",
  isFetching,
  suggestions,
}) => {
  const { errors, setFieldValue, values } =
    useFormikContext<RequiredFormValues>();
  useEffect(() => {
    fetchProductionOperations();
  }, [fetchProductionOperations]);

  const handleFilterOptions = createFilterOptions({
    stringify: ({ code, name }) => `${name} (${code})`,
  });

  return (
    <CfFormControl>
      <CfAutocomplete
        defaultValues={values[fieldName]}
        error={!!errors[fieldName]}
        filterOptions={handleFilterOptions}
        isFetching={isFetching}
        isMultiple={false}
        onChange={(value) => setFieldValue("productionOperation", value)}
        suggestions={suggestions}
        testId="telematics-catalogue-operation-selector"
        helperText={
          !!errors[fieldName] && <FormattedMessage id={errors[fieldName]} />
        }
        label={
          <FormattedMessage id="Catalogues.table.machines.dialog.productionOperation" />
        }
        renderOption={(option: ProductionOperationTo) => (
          <TCSelectorItem
            firstLine={option?.name ?? "-"}
            secondLine={option?.code ?? "-"}
          />
        )}
      />
    </CfFormControl>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchProductionOperations,
    },
    dispatch,
  );

const mapStateToProps = (state: CataloguesState) => ({
  isFetching: getIsFetchingProductionOperations(state),
  suggestions: getProductionOperations(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TCProductionOperationSelector);
