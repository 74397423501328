import { RSAAAction } from "redux-api-middleware";

import {
  getParcelApi,
  updateParcelApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";

export const updateParcelName =
  (parcelId: string, parcelName: string) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updateParcelApi(parcelId, { name: parcelName })).then(() => {
      dispatch(getParcelApi(parcelId));
    });
