import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfInfoDialog from "../../../shared/components/common/CfInfoDialog/CfInfoDialog";

const style = (theme) => ({
  container: {
    marginBottom: "10px",
  },
  range: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  infoButton: {
    margin: "0px 6px",
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
});

const SwpInfoDialog = (props) => {
  const { classes, heading } = props;
  return (
    <CfInfoDialog
      classes={{ infoButton: classes.infoButton, infoIcon: classes.infoIcon }}
      heading={heading}
    >
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={2}>
          <Typography className={classes.range}>
            <FormattedMessage
              id="common.range"
              values={{
                from: 0,
                to: -10,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <FormattedMessage id="SoilWaterPotential.level1" />
          </Typography>
        </Grid>
      </Grid>
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={2}>
          <Typography className={classes.range}>
            <FormattedMessage
              id="common.range"
              values={{
                from: -10,
                to: -30,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <FormattedMessage id="SoilWaterPotential.level2" />
          </Typography>
        </Grid>
      </Grid>
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={2}>
          <Typography className={classes.range}>
            <FormattedMessage
              id="common.range"
              values={{
                from: -30,
                to: -60,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <FormattedMessage id="SoilWaterPotential.level3" />
          </Typography>
        </Grid>
      </Grid>
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={2}>
          <Typography className={classes.range}>
            <FormattedMessage
              id="common.range"
              values={{
                from: -60,
                to: -100,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <FormattedMessage id="SoilWaterPotential.level4" />
          </Typography>
        </Grid>
      </Grid>
      <Grid alignItems="flex-start" className={classes.container} container>
        <Grid item xs={2}>
          <Typography className={classes.range}>
            <FormattedMessage
              id="common.range"
              values={{
                from: -100,
                to: -200,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <FormattedMessage id="SoilWaterPotential.level5" />
          </Typography>
        </Grid>
      </Grid>
    </CfInfoDialog>
  );
};

SwpInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

SwpInfoDialog.defaultProps = {
  heading: null,
};

export default withStyles(style)(SwpInfoDialog);
