import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import reducer from "../../reducers/reset.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";

import ResetWrapper from "./containers/ResetWrapper/ResetWrapper";

ResetController.$inject = [
  "$element",
  "$state",
  "$stateParams",
  "$scope",
  "$rootScope",
  "Localization",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
];

export default function ResetController(
  $element,
  $state,
  $stateParams,
  $scope,
  $rootScope,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
) {
  const props = {
    langId: null,
    getResetKey,
    gwUrl: BACKEND_OPTIONS.gatewayUrl,
    ngChangeLanguage,
    ngGetCurrentLangIdAsync,
    ngRedirectToLogin,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      langId: null,
      beOpts: BACKEND_OPTIONS,
      displayErrorModal: false,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    ngGetCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function getResetKey() {
    return $stateParams.key;
  }

  function ngRedirectToLogin() {
    $state.go("login");
  }

  function ngGetCurrentLangIdAsync() {
    return Localization.getCurrentLangIdAsync();
  }

  function ngChangeLanguage(langId) {
    return Localization.setCurrLangId(langId);
  }

  function render() {
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <ResetWrapper {...p} />} />,
      $element[0],
    );
  }
}
