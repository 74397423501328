import React from "react";

const AddCircleIconFilled = () => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 25 25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.7773" cy="12.6211" fill="#848484" r="12" />
    <path
      d="M18.7215 13.8224L13.9683 13.812L13.9787 18.5653L11.602 18.56L11.5916 13.8068L6.83838 13.7964L6.83317 11.4198L11.5864 11.4302L11.576 6.67692L13.9526 6.68213L13.963 11.4354L18.7163 11.4458L18.7215 13.8224Z"
      fill="white"
    />
  </svg>
);

export default AddCircleIconFilled;
