import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import * as types from "./admin.devices.constants";

import {
  getAdminDevicesApi,
  patchAdminDeviceApi,
  resetAdminDevicesApi,
  resetDeviceTypes,
} from "../../../../shared/api/irrigation/devices/devices.api";
import {
  getIrrigationAdminCountryFilter,
  getIrrigationAdminFarmFilter,
  getIrrigationAdminDeviceTypeFilter,
  getIrrigationAdminOrder,
  getIrrigationAdminOrderBy,
  getIrrigationAdminPage,
  getIrrigationAdminRowsPerPage,
  getIrrigationAdminStatusFilter,
  getIrrigationAdminTextFilter,
} from "../selectors/admin.devices.selector";

import {
  AdminCountry,
  AdminFarm,
  IrrigationAdminState,
  PayloadPatchAdminDevice,
} from "../admin.irrigation.types";

export const fetchIrrigationAdminDevices =
  () =>
  (
    dispatch: (action: RSAAAction) => void,
    getState: () => IrrigationAdminState,
  ) => {
    const state = getState();

    const params = {
      page: getIrrigationAdminPage(state) + 1,
      "per-page": getIrrigationAdminRowsPerPage(state),
      "sort-dir": getIrrigationAdminOrder(state),
      "sort-col": getIrrigationAdminOrderBy(state),
      search: getIrrigationAdminTextFilter(state),
      status: getIrrigationAdminStatusFilter(state)?.name,
      "device-type": getIrrigationAdminDeviceTypeFilter(state).map(
        (v) => v.name,
      ),
      "country-code": getIrrigationAdminCountryFilter(state).code,
      "farm-id": getIrrigationAdminFarmFilter(state).id,
    };

    return dispatch(getAdminDevicesApi(params) as unknown as RSAAAction);
  };

export const resetIrrigationAdminDevicesApi =
  () => (dispatch: (action: AnyAction) => void) => {
    dispatch(resetAdminDevicesApi());
    dispatch(resetDeviceTypes());
  };

export const patchAdminDevice =
  (deviceIds: string[], payload: PayloadPatchAdminDevice) =>
  (
    dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>,
    getState: () => IrrigationAdminState,
  ) => {
    dispatch(
      patchAdminDeviceApi(deviceIds, payload) as unknown as RSAAAction,
    ).then(() => {
      fetchIrrigationAdminDevices()(dispatch, getState);
    });
  };

export const setIrrigationAdminFarmFilter = (farm: AdminFarm) => ({
  type: types.SET_FARM_FILTER,
  farm,
});

export const setIrrigationAdminCountryFilter = (country: AdminCountry) => ({
  type: types.SET_COUNTRY_FILTER,
  country,
});
