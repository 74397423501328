import React, { FC } from "react";

import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  cell: {
    width: 90,
    border: "1px solid",
    textAlign: "center",
  },
  firstCell: {
    width: 120,
    textAlign: "left",
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));

interface TableBodyRowPercentageProps {
  index: number;
  item: {
    high: string | null;
    low: string;
    medium: string;
    name: string;
    veryHigh: string;
    veryLow: string | null;
  };
}

const TableBodyRowPercentage: FC<TableBodyRowPercentageProps> = ({
  index,
  item,
}) => {
  const classes = useStyles();
  const { high, low, medium, name, veryHigh, veryLow } = item;

  return (
    <TableRow key={index}>
      <TableCell
        classes={{ root: classNames(classes.cell, classes.firstCell) }}
      >
        <p className={classes.text}>{name}</p>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>{veryLow}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{low}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{medium}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{high}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{veryHigh}</TableCell>
    </TableRow>
  );
};

export default TableBodyRowPercentage;
