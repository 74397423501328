import { AnyAction } from "redux";

import * as commonFilterTypes from "../../shared/actions/filter.constants";
import * as commonTableTypes from "../../shared/actions/table.constants";
import { ROWS_PER_PAGE } from "../../shared/constants/rowsPerPage.constants";
import * as sortOrder from "../../shared/constants/sortOrder.constants";
import * as types from "../actions/sectionList.constants";

import { getShortDateString } from "../../shared/misc/timeHelpers";

import { SectionListState } from "./sectionList.types";

const initialState: SectionListState = {
  dateFrom: getShortDateString(),
  dateTo: getShortDateString(),
  search: "",
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  order: sortOrder.ASC,
  orderBy: undefined,
  advancedFilter: undefined,
};

export default (reducerNamespace: string) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (!namespace) return state;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonFilterTypes.SET_TEXT_FILTER:
        return {
          ...state,
          search: action.textFilter,
          page: 0,
        };
      case types.SET_DATE_FROM:
        return {
          ...state,
          dateFrom: action.payload,
          page: 0,
        };
      case types.SET_DATE_TO:
        return {
          ...state,
          dateTo: action.payload,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
          page: 0,
        };
      case commonFilterTypes.SET_ADVANCED_FILTER:
        return {
          ...state,
          advancedFilter: action.advancedFilter,
          page: 0,
        };

      default:
        return state;
    }
  };
