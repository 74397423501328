import * as types from "../actions/map.constants";

export default () =>
  (
    state = {
      map: null,
      hoveredFeatureId: null,
      selectedFeatureId: null,
      isSelecting: false,
      selectionCoords: null,
      enlarged: false,
      mapColumns: 8,
    },
    action,
  ) => {
    switch (action.type) {
      case types.STORE_MAP:
        return {
          ...state,
          map: action.map,
        };
      case types.STORE_HOVERED_FEATURE_ID:
        return {
          ...state,
          hoveredFeatureId: action.hoveredFeatureId,
        };
      case types.STORE_SELECTED_FEATURE_ID:
        return {
          ...state,
          selectedFeatureId: action.selectedFeatureId,
        };
      case types.SET_COORDS_SELECTION:
        return {
          ...state,
          isSelecting: action.isSelecting,
          selectionCoords: action.selectionCoords,
        };
      case types.SET_VARIANT_ENLARGED:
        return {
          ...state,
          enlarged: action.enlarged,
        };
      case types.SET_MAP_COLUMNS:
        return {
          ...state,
          mapColumns: action.mapColumns,
        };
      default:
        return state;
    }
  };
