import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";

import { FarmUpdateNewParcels } from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  farmId: string;
  newParcels: FarmUpdateNewParcels[];
  onClose: () => void;
}

export function UpdateLpisNewParcelsDialog({
  farmId,
  newParcels,
  onClose,
}: Readonly<Props>) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="update-lpis-error-dialog"
      open={true}
    >
      <DialogTitle>
        <FormattedMessage id="UpdateLPIS.updateSuccess.dialog.title" />
      </DialogTitle>
      <Scrollbars autoHeight autoHeightMax="80vh">
        <DialogContent>
          <FormattedMessage id="UpdateLPIS.updateSuccess.dialog.newParcels.label" />
          {newParcels.map((parcel) => (
            <div key={parcel.parcelId}>
              <a
                color="primary"
                data-test="parcel-detail-link"
                href={`/farm/${farmId}/parcels/${parcel.parcelId}/overview`}
                target="_blank no-referrer noopener"
              >
                {parcel.block}
              </a>
            </div>
          ))}
        </DialogContent>
      </Scrollbars>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button color="primary" id="close" onClick={onClose} variant="text">
            <FormattedMessage id="common.close" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  dialogContainer: {
    minWidth: "400px",
  },
}));
