import angular from "angular";

import MapController from "./map.controller";

// eslint-disable-next-line import/prefer-default-export
const mapComponent = {
  bindings: {
    farm: "<",
    parcelId: "<",
    dateFrom: "<",
    dateTo: "<",
    machine: "<",
    driver: "<",
  },
  controller: MapController,
};

export default angular
  .module("app.core.map", [])
  .config(config)
  .component("mapComponent", mapComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider.state("farm.active.map", {
    url: "/map",
    views: {
      "farm@farm.active": {
        component: "mapComponent",
      },
    },
    params: {
      parcelId: null,
      dateFrom: null,
      dateTo: null,
      machine: null,
      driver: null,
    },
    resolve: {
      parcelId: [
        "$stateParams",
        function ($stateParams) {
          return $stateParams.parcelId;
        },
      ],
      dateFrom: [
        "$stateParams",
        function ($stateParams) {
          return $stateParams.dateFrom;
        },
      ],
      dateTo: [
        "$stateParams",
        function ($stateParams) {
          return $stateParams.dateTo;
        },
      ],
      machine: [
        "$stateParams",
        function ($stateParams) {
          return $stateParams.machine;
        },
      ],
      driver: [
        "$stateParams",
        function ($stateParams) {
          return $stateParams.driver;
        },
      ],
    },
  });
}
