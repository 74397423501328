/* eslint-disable no-console */

import isEqual from "lodash/isEqual";

import { API_MODULES } from "../api/api.constants";

import LocalStorage from "./LocalStorage.service";

const key = "UI_CACHE";
const isDevelopment = process.env.NODE_ENV === "development";

type Cache = Record<string, unknown>;
type Section = (typeof API_MODULES)[keyof typeof API_MODULES];

interface CacheStructure {
  [farmId: string]: {
    [sectionName in Section]: Cache;
  };
}

export default class UiCacheService {
  private static farmId: string;

  static initialize(farmId: string) {
    this.farmId = farmId;
  }

  static storeSettings(sectionName: Section, data: Cache): void {
    const oldData = UiCacheService.getCache() || {};
    const newData: CacheStructure = {
      ...oldData,
      [this.farmId]: {
        ...oldData[this.farmId],
        [sectionName]: {
          ...data,
        },
      },
    };

    LocalStorage.saveToLocalStorage(newData, key);
  }

  static getCache(): CacheStructure | null {
    return LocalStorage.loadFromLocalStorage(key);
  }

  static getCachePerSection(sectionName: Section): Cache | null {
    const data = UiCacheService.getCache();

    if (!data) {
      return null;
    }
    const farmData = data[this.farmId];

    if (!farmData) {
      return null;
    }

    return farmData[sectionName] ? farmData[sectionName] : null;
  }

  static isCacheExpired(sectionName: Section, newData: Cache): boolean {
    const cachedData = UiCacheService.getCachePerSection(sectionName);
    return !isEqual(newData, cachedData);
  }

  static deleteParams(): void {
    if (isDevelopment) {
      console.info("deleting cache");
    }
    LocalStorage.removeFromLocalStorage(key);
  }

  static storeParams(sectionName: Section, params: Cache): void {
    if (isDevelopment) {
      console.info("storing cache, key:", sectionName, params);
    }

    if (UiCacheService.isCacheExpired(sectionName, params)) {
      UiCacheService.storeSettings(sectionName, params);
    }
  }

  static retrieveParams(sectionName: Section): Cache | null {
    return UiCacheService.getCachePerSection(sectionName);
  }
}
