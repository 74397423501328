import React, { useEffect, useState, FC } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory, match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getApiError,
  getAreaDetail,
} from "../../../shared/api/irrigation/areas/areas.selectors";
import {
  getCreateOrEditAreaErrorMessages,
  getCreateOrEditAreaFieldErrors,
  getCreateOrEditAreaDevicesToEdit,
  getCreateOrEditAreaSelectedDevices,
} from "../../selectors/createOrEditArea.selectors";

import { fetchArea } from "../../actions/areasList.actions";
import {
  checkForm,
  addToArea,
  resetForm,
  setAreaName,
} from "../../actions/createOrEditArea.actions";
import { setEnlargedVariant } from "../../actions/map.actions";

import { resetApiError } from "../../../shared/api/irrigation/areas/areas.api";
import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { Thunk } from "../../../types";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";

import AvailableDevicesList from "./components/AvailableDevicesList";
import useCreateAreaStyles from "./styles/useCreateArea.styles";

import { IrrigationState } from "../../../reducers/irrigation.reducer.types";
import { AreaDetail } from "../../../shared/api/irrigation/areas/area.types";

interface Props {
  addToArea: (areaId: string, redirectFn: (areaId: string) => void) => void;
  apiError: boolean;
  area: AreaDetail;
  checkForm: () => unknown;
  fetchArea: (areaID: string) => void;
  match: matchType<{ areaId: string }>;
  resetApiError: () => void;
  resetForm: () => void;
  selected: string[];
  setAreaName: (name: string) => void;
  setEnlargedVariant: (bool: boolean) => void;
}

const AddToIrrigationArea: FC<Props> = ({
  addToArea,
  apiError,
  area,
  checkForm,
  fetchArea,
  match,
  resetApiError,
  resetForm,
  selected,
  setAreaName,
  setEnlargedVariant,
}) => {
  const classes = useCreateAreaStyles();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [noDeviceSelected, setNoDeviceSelected] = useState(false);

  const areaId = match.params.areaId;

  useEffect(() => {
    setEnlargedVariant(true);
    fetchArea(areaId);

    return () => {
      resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAreaName(area.name);
  }, [area, setAreaName]);

  useEffect(() => {
    if (noDeviceSelected && selected.length) {
      setNoDeviceSelected(false);
    }
  }, [noDeviceSelected, selected]);

  useEffect(() => {
    if (apiError) {
      setSubmitting(false);
    }
  }, [apiError]);

  const handleGoToAreaDetail = () => {
    history.push("./devices");
  };

  const handleSubmitData = () => {
    if (!selected.length) {
      setNoDeviceSelected(true);
      return;
    }
    const isValid = checkForm() as boolean;
    if (isValid) {
      setSubmitting(true);
      addToArea(areaId, handleGoToAreaDetail);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <PageHeader
          backButton={<CfBackButton onClick={handleGoToAreaDetail} />}
          heading={<PageHeading value={area.name} />}
        />
      </Box>
      <AvailableDevicesList
        validationError={noDeviceSelected}
        validationMessage={
          <FormattedMessage id="Irrigation.addToArea.validationError" />
        }
      />
      <Box className={classes.buttons}>
        <Button onClick={handleGoToAreaDetail} variant="contained">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmitData}
          variant="contained"
        >
          {isSubmitting && (
            <CircularProgress
              className={classes.buttonLoader}
              color="primary"
              size={20}
              thickness={8}
            />
          )}
          <FormattedMessage id="Irrigation.addToArea.addButton" />
        </Button>
      </Box>
      <ErrorSnackbar handleClose={resetApiError} open={apiError} />
    </Box>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  errorMessages: getCreateOrEditAreaErrorMessages(state),
  fieldErrors: getCreateOrEditAreaFieldErrors(state),
  selected: getCreateOrEditAreaSelectedDevices(state),
  devicesToEdit: getCreateOrEditAreaDevicesToEdit(state),
  area: getAreaDetail(state),
  apiError: getApiError(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      setEnlargedVariant,
      checkForm,
      fetchArea,
      addToArea,
      setAreaName,
      resetForm,
      resetApiError,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddToIrrigationArea);
