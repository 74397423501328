import { AnyAction } from "redux";

import * as types from "./cataloguesTypes.constants";

const initialState = {
  items: [],
  isFetchingItems: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_CATALOGUES_TYPES_REQUEST:
        return {
          ...state,
          isFetchingItems: true,
        };
      case types.GET_CATALOGUES_TYPES_SUCCESS:
        return {
          ...state,
          items: action.payload,
          isFetchingItems: false,
        };
      case types.GET_CATALOGUES_TYPES_ERROR:
        return {
          ...state,
          isFetchingItems: false,
        };
      case types.RESET_CATALOGUES_TYPES:
        return {
          ...state,
          items: [],
        };

      default:
        return state;
    }
  };
