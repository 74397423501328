import React, { Component, Fragment } from "react";

import EditIcon from "@mui/icons-material/Edit";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getSentinelAdminPage,
  getSentinelAdminOrder,
  getSentinelAdminOrderBy,
  getSentinelAdminRowsPerPage,
  getSentinelAdminTextFilter,
  getSentinelAdminTotalCount,
  getActiveSentinelParcels,
  isFetchingActiveSentinelParcels,
} from "../../selectors/sentinel.selectors";

import { fetchActiveParcels } from "../../actions/sentinel.actions";

import { NAMESPACE as namespace } from "../../reducer/sentinel.reducer";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../../shared/misc/helper";
import { COLOR_GREY } from "../../../../../theme";
import SentinelStatus from "../../components/SentinelStatus/SentinelStatus";

const columns = {
  localName: getColDesc(
    true,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="SentinelAdminTable.localName" />
    </span>,
  ),
  blockNr: getColDesc(
    true,
    <FormattedMessage id="SentinelAdminTable.blockNumber" />,
  ),
  area: getColDesc(
    true,
    <div>
      <div>
        <FormattedMessage id="SentinelAdminTable.currentArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="SentinelAdminTable.originalArea" />
      </div>
    </div>,
    { textAlign: "right", paddingRight: 40 },
    "right",
  ),
  historyPotential: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.historyPotential" />,
  ),
  biomonitoring: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.monitoring" />,
  ),
  variableApplication: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.variableApplications" />,
  ),
};
const columnsLength = Object.keys(columns).length + 1;

const styles = (theme) => ({
  nameTableCell: {
    paddingLeft: 15,
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "inline-block",
      padding: "0px 10px 0px 0px",
    },
  },
  tableRowTools: {
    display: "none",
  },
  originalArea: {
    color: theme.palette.grey[500],
  },
  paddingNumber: {
    paddingRight: 40,
  },
});

export class SentinelAdminTable extends Component {
  constructor(props) {
    super(props);
    this.productTypes = {
      HISTORY_POTENTIAL: "HISTORY_POTENTIAL",
      BIOPHYSICAL_MONITORING: "BIOPHYSICAL_MONITORING",
      VARIABLE_APPLICATION: "VARIABLE_APPLICATION",
    };
  }

  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);
    const newProps = pick(this.props, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);
    const isFarmSelected = !!this.props.farm;

    if (!isEqual(newProps, oldProps) && isFarmSelected) {
      this.getParcels();
    }
  }

  getParcels() {
    this.props.fetchActiveParcels();
  }

  getParcelProduct(parcel, productType) {
    return parcel.products
      ? parcel.products.find((product) => product.productType === productType)
      : null;
  }

  render() {
    const {
      activeParcels,
      classes,
      count,
      farm,
      isFetching,
      langId,
      openParcelDialog,
      order,
      orderBy,
      page,
      rowsPerPage,
    } = this.props;
    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          langId={langId}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {farm ? (
          <Fragment>
            {isFetching && <CfTableBodyLoader columns={columns} />}
            {activeParcels.length > 0 ? (
              <TableBody>
                {activeParcels.map((parcel) => (
                  <TableRow className={classes.tableRow} key={parcel.parcelId}>
                    <CfTableCell name="localName">
                      <span className={classes.nameTableCell}>
                        {parcel.name}
                      </span>
                    </CfTableCell>
                    <CfTableCell name="blockNr">{parcel.blockNr}</CfTableCell>
                    <CfTableCell
                      classes={{ root: classes.paddingNumber }}
                      name="area"
                    >
                      <span>
                        <CfTableCellNumber>
                          <CfFormattedNumber
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            value={parcel.area}
                          />
                          <div className={classes.originalArea}>
                            <CfFormattedNumber
                              maximumFractionDigits={2}
                              minimumFractionDigits={2}
                              value={parcel.originalArea}
                            />
                          </div>
                        </CfTableCellNumber>
                      </span>
                    </CfTableCell>
                    <CfTableCell name="historyPotential">
                      <SentinelStatus
                        active={parcel.historyPotentialEnabled}
                        testId="history-potential"
                        product={this.getParcelProduct(
                          parcel,
                          this.productTypes.HISTORY_POTENTIAL,
                        )}
                      />
                    </CfTableCell>
                    <CfTableCell name="biomonitoring">
                      <SentinelStatus
                        active={parcel.biomonitoringEnabled}
                        testId="biomonitoring"
                        product={this.getParcelProduct(
                          parcel,
                          this.productTypes.BIOPHYSICAL_MONITORING,
                        )}
                      />
                    </CfTableCell>
                    <CfTableCell name="variableApplication">
                      <SentinelStatus
                        active={parcel.variableApplicationEnabled}
                        testId="variable-application"
                        product={this.getParcelProduct(
                          parcel,
                          this.productTypes.VARIABLE_APPLICATION,
                        )}
                      />
                    </CfTableCell>
                    <CfTableRowTools toolsClass={classes.tableRowTools}>
                      <CfTableRowToolButton
                        icon={<EditIcon />}
                        onClick={() => openParcelDialog(parcel)}
                        tooltipMessage={
                          <FormattedMessage id="SentinelAdminTable.editServices" />
                        }
                      />
                    </CfTableRowTools>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <CfTableBodyEmpty
                colLength={columnsLength}
                translId="SentinelAdminTable.noActiveServices"
              />
            )}
          </Fragment>
        ) : (
          <CfTableBodyEmpty
            colLength={columnsLength}
            translId="SentinelAdminTable.noFarmSelected"
          />
        )}
        <CfTableFooter
          count={count}
          langId={langId}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    );
  }
}

SentinelAdminTable.propTypes = {
  langId: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  fetchActiveParcels: PropTypes.func.isRequired,
  openParcelDialog: PropTypes.func.isRequired,
  activeParcels: PropTypes.array,
  farm: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
};

SentinelAdminTable.defaultProps = {
  activeParcels: [],
  farm: null,
};

const mapStateToProps = (state) => ({
  page: getSentinelAdminPage(state),
  order: getSentinelAdminOrder(state),
  orderBy: getSentinelAdminOrderBy(state),
  rowsPerPage: getSentinelAdminRowsPerPage(state),
  textFilter: getSentinelAdminTextFilter(state),
  count: getSentinelAdminTotalCount(state),
  activeParcels: getActiveSentinelParcels(state),
  isFetching: isFetchingActiveSentinelParcels(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActiveParcels,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SentinelAdminTable));
