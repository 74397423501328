import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

export const NodeLocationsHistoryFilter = ({
  historyFilter,
  setHistoryFilter,
  statistics,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [historic, setHistoric] = useState(historyFilter.historic);

  const getAvailableYearsWithMonths = () => {
    const firstActivationDate = moment(statistics.firstActivation);
    const endYear = moment().year();
    const yearsWithMonths = { years: [], monthsByYear: {} };

    for (let year = firstActivationDate.year(); year <= endYear; year += 1) {
      yearsWithMonths.years.push(year);
      yearsWithMonths.monthsByYear[year] = [];

      const startMonth =
        year === firstActivationDate.year() ? firstActivationDate.month() : 0;
      const endMonth = year === endYear ? moment().month() : 11;

      for (let month = startMonth; month <= endMonth; month += 1) {
        const monthDate = moment().year(year).month(month);
        yearsWithMonths.monthsByYear[year].push({
          value: month + 1,
          label: intl.formatDate(monthDate, { month: "long" }),
        });
      }
    }
    return yearsWithMonths;
  };

  const handleChangeHistoric = (event) => {
    const checked = event.target.checked;
    setHistoric(checked);
    setHistoryFilter({ historic: checked });
  };

  const handleChange = (name) => (event) => {
    const filterParams = {};
    filterParams[name] = event.target.value;
    setHistoryFilter(filterParams);
  };

  const availableYearsWithMonths = getAvailableYearsWithMonths();

  return (
    <div className={classes.wrapper}>
      <span className={classes.labelWrapper}>
        <FormControlLabel
          className={classes.switchFormControlLabel}
          labelPlacement="start"
          control={
            <Switch
              checked={historic}
              color="primary"
              name="show-historic"
              onChange={handleChangeHistoric}
            />
          }
          label={
            <FormattedMessage id="NodeLocationsHistoryFilter.viewHistory" />
          }
        />
      </span>
      {historic && (
        <span className={classes.monthYearWrapper}>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Select
                  onChange={handleChange("year")}
                  value={historyFilter.year}
                  inputProps={{
                    name: "sensors-filter-year",
                    id: "sensors-filter-year",
                  }}
                >
                  {availableYearsWithMonths.years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl className={classes.monthSelect} fullWidth>
                <Select
                  displayEmpty
                  onChange={handleChange("month")}
                  value={historyFilter.month}
                  inputProps={{
                    name: "sensors-filter-month",
                    id: "sensors-filter-month",
                  }}
                >
                  <MenuItem value="">
                    <span className={classes.monthPlaceholder}>
                      <FormattedMessage id="NodeLocationsHistoryFilter.allMonths" />
                    </span>
                  </MenuItem>
                  {availableYearsWithMonths.monthsByYear[
                    historyFilter.year
                  ].map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </span>
      )}
    </div>
  );
};

NodeLocationsHistoryFilter.propTypes = {
  setHistoryFilter: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  historyFilter: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  switchFormControlLabel: {
    margin: 0,
    marginRight: 12,
  },
  monthSelect: {
    verticalAlign: "bottom",
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  monthYearWrapper: {
    minWidth: 250,
    marginBottom: 8,
  },
  labelWrapper: {
    width: "max-content",
    marginBottom: 4,
  },
  monthPlaceholder: {
    color: theme.palette.grey[500],
  },
}));
