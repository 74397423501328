import React, { Component } from "react";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setTablePage, setTableRowsPerPage } from "../../actions/table.actions";

export class CfTableFooter extends Component {
  handleChangeRowsPerPage = (evt) => {
    const { namespace } = this.props;
    this.props.setTableRowsPerPage(evt.target.value, namespace);
  };

  handleChangePage = (evt, page) => {
    const { namespace } = this.props;
    this.props.setTablePage(page, namespace);
  };

  render() {
    const rowsPerPageOptions = [10, 25, 50, 100];
    const { count, onChangePage, onChangeRowsPerPage, page, rowsPerPage } =
      this.props;
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count}
            data-test="table-pagination"
            labelRowsPerPage={<FormattedMessage id="common.rowsPerPage" />}
            onPageChange={onChangePage || this.handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            labelDisplayedRows={(setup) => (
              <FormattedMessage
                id="common.currentPage"
                values={{
                  from: setup.from,
                  to: setup.to,
                  count: setup.count,
                }}
              />
            )}
            onRowsPerPageChange={
              onChangeRowsPerPage || this.handleChangeRowsPerPage
            }
          />
        </TableRow>
      </TableFooter>
    );
  }
}

CfTableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  setTableRowsPerPage: PropTypes.func.isRequired,
  setTablePage: PropTypes.func.isRequired,
  namespace: PropTypes.string,
};

CfTableFooter.defaultProps = {
  namespace: null,
  onChangePage: null,
  onChangeRowsPerPage: null,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTablePage,
      setTableRowsPerPage,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CfTableFooter);
