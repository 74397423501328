import React, { FC, Fragment, useMemo } from "react";

import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCrops,
  getIsFetchingCrops,
} from "../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import { fetchCrops } from "../../actions/parcels.actions";

import { resetCropsApi } from "../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import useWidth from "../../../../../shared/hooks/useWidth";
import { Thunk } from "../../../../../types";

import CropAutosuggest from "./CropAutosuggest";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  CropTo,
  ParcelWithSowingPlansTo,
  ParcelDetailTo,
  SowingPlanSeasonCropTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

interface ParcelCropAssignProps {
  anchorEl?: null | Element;
  fetchCrops: (search?: string) => void;
  handlePopoverClose: () => void;
  handlePopoverOpen: () => void;
  isFetching: boolean;
  isOpen: boolean;
  onCropChange: (crop: CropTo, seasonId: string, parcelId: string) => void;
  parcel: ParcelDetailTo | ParcelWithSowingPlansTo;
  resetCropsApi: () => void;
  sowingPlan: SowingPlanSeasonCropTo;
  suggestions: CropTo[];
}

const ParcelCropAssign: FC<ParcelCropAssignProps> = ({
  anchorEl = null,
  fetchCrops,
  handlePopoverClose,
  handlePopoverOpen,
  isFetching,
  isOpen,
  onCropChange,
  parcel,
  resetCropsApi,
  sowingPlan,
  suggestions,
}) => {
  const classes = useStyles();
  const width = useWidth();

  const isDesktop = ["md", "lg", "xl"].includes(width);

  const extendedeSuggestions = useMemo(
    () => [
      {
        id: null,
        name: "-",
      },
      ...suggestions,
    ],
    [suggestions],
  );

  const onSuggestionSelect = (cropToAssign: CropTo) => {
    if (sowingPlan?.crop?.id !== cropToAssign.id) {
      onCropChange(cropToAssign, sowingPlan.season.id, parcel.id);
    }
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
    resetCropsApi();
  };

  return (
    <Fragment>
      <CfSwitcherButton
        classes={{ custom: classes.button }}
        onClick={handlePopoverOpen}
        transparent={true}
      >
        <span className={classes.switcherButtonLabel}>
          {sowingPlan.crop?.name ?? "–"}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={onSuggestionClear}
        open={isOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        PaperProps={{
          style: {
            maxWidth: isDesktop ? "50%" : "90%",
          },
        }}
        transformOrigin={{
          vertical: isDesktop ? -30 : -80,
          horizontal: "right",
        }}
      >
        <CropAutosuggest
          getSuggestions={fetchCrops}
          isFetching={isFetching}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={extendedeSuggestions}
        />
      </Popover>
    </Fragment>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getCrops(state),
  isFetching: getIsFetchingCrops(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchCrops,
      resetCropsApi,
    },
    dispatch,
  );

export default withPopover(
  connect(mapStateToProps, mapDispatchToProps)(ParcelCropAssign),
);

const useStyles = makeStyles(() => ({
  paper: {
    overflow: "hidden",
    height: 250,
    width: "fit-content",
  },
  switcherButtonLabel: {
    fontSize: 13,
    fontWeight: 400,
    textTransform: "lowercase",
    textAlign: "left",
  },
  button: {
    marginLeft: -9,
    display: "flex",
    justifyContent: "space-between",
    width: "-webkit-fill-available",
  },
}));
