import React, { FC } from "react";

import { Field, FormikProps } from "formik";
import { round, toNumber } from "lodash";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikNumericTextField from "../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import { validateWorkingWidth } from "../validators";

import { DetailContentFormValues } from "../DetailContentForm.types";

interface Props extends FormikProps<DetailContentFormValues> {
  handleAfterValidationEffects: (err: string) => void;
  isEditing: boolean;
}

const WorkingWidth: FC<Props> = ({
  errors,
  handleAfterValidationEffects,
  isEditing,
  setFieldValue,
  validateField,
  values,
}) => {
  const handleWorkingWidthBlur = () => {
    if (!(Number(values.workingWidth) >= 0)) {
      setFieldValue("workingWidth", undefined);
    } else {
      const newValue = round(toNumber(values.workingWidth), 5);
      setFieldValue("workingWidth", newValue);
      // @ts-ignore
      validateField("workingWidth").then(handleAfterValidationEffects);
    }
  };

  return (
    <CfFormControl>
      <Field
        component={CfFormikNumericTextField}
        disabled={!isEditing}
        error={!!errors.workingWidth}
        name="workingWidth"
        onBlur={handleWorkingWidthBlur}
        validate={validateWorkingWidth}
        helperText={
          errors.workingWidth && <FormattedMessage id={errors.workingWidth} />
        }
        label={
          <FormattedMessage
            id={
              values.equipmentCode
                ? "TelematicsList.additionalEquipmentWidth"
                : "TelematicsList.additionalMachineWidth"
            }
          />
        }
      />
    </CfFormControl>
  );
};

export default WorkingWidth;
