import moment from "moment";

import * as types from "../actions/node.constants";

export default () =>
  (
    state = {
      newNode: undefined,
      duration: undefined,
      granularity: undefined,
      dateFrom: moment().startOf("day").toISOString(),
      dateTo: moment().endOf("day").toISOString(),
    },
    action,
  ) => {
    switch (action.type) {
      case types.SET_DURATION:
        return {
          ...state,
          duration: action.duration,
          granularity: action.granularity,
        };
      case types.RESET_DURATION:
        return {
          ...state,
          duration: undefined,
          granularity: undefined,
          dateFrom: moment().startOf("day").toISOString(),
          dateTo: moment().endOf("day").toISOString(),
        };
      case types.SET_GRANULARITY:
        return {
          ...state,
          granularity: action.granularity,
        };
      case types.RESET_NODE:
        return {
          ...state,
          newNode: undefined,
        };
      case types.EDIT_START:
        return {
          ...state,
          newNode: action.newNode,
        };
      case types.EDIT_CANCEL:
        return {
          ...state,
          newNode: undefined,
        };
      case types.EDIT_UPDATE:
        return {
          ...state,
          newNode: action.newNodeVersion,
        };
      case types.SET_DATE:
        return {
          ...state,
          dateFrom: action.dateFrom,
          dateTo: action.dateTo,
          duration: action.duration,
          granularity: action.granularity,
        };
      default:
        return state;
    }
  };
