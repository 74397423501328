import React, { Fragment, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  dropdownButton: {
    height: "100%",
    padding: "0px 15px",
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.common.white,
      backgroundColor: "inherit",
    },
    minWidth: 24,
  },
  selectedNavlink: {
    padding: "0px 15px",
  },
  menuNavlink: {
    color: "rgba(255, 255, 255, 0.7)",
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
    padding: "9px 16px",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    "&:focus": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    width: "100%",
  },
  menuItem: {
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit",
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  menuPaper: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  itemSelected: {
    display: "none",
  },
});

function MainMenuDropdown(props) {
  const { classes, farmId, items, lastSelected } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        aria-controls="main-menu-dropdown"
        aria-haspopup="true"
        className={classes.dropdownButton}
        onClick={handleClick}
      >
        <FormattedMessage id="common.more" />
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="main-menu-dropdown-items"
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {items.map((menuItem) => (
          <MenuItem
            id={`main-menu-${menuItem.id}`}
            key={menuItem.id}
            className={`${classes.menuItem} ${
              menuItem.id === lastSelected ? classes.itemSelected : ""
            }`}
          >
            {
              // todo: "a" is temporary
              // change to "<NavLink to=" after remove angular and updating react-router-dom to version 6 (or higher)
            }
            <a
              className={classes.menuNavlink}
              href={`/farm/${farmId}/${menuItem.route}`}
              onClick={() => {
                handleClose();
              }}
            >
              <FormattedMessage id={menuItem.translationId} />
            </a>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

MainMenuDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  farmId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  lastSelected: PropTypes.string.isRequired,
};

export default withStyles(styles)(MainMenuDropdown);
