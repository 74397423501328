import moment from "moment";
import { AnyAction } from "redux";

import * as types from "../actions/mainMapTelematics/mainMapTelematics.constants";

import { MainMapTelematicsUIState } from "../../../reducers/map.reducer.types";

export default () =>
  (
    state: MainMapTelematicsUIState = {
      dateFrom: moment().startOf("day").toISOString(),
      dateTo: moment().endOf("day").toISOString(),
      machineFilter: [],
      driverFilter: [],

      selectedMachineGpsUnit: undefined,
    },
    action: AnyAction,
  ) => {
    switch (action.type) {
      case types.SET_DATE:
        return {
          ...state,
          dateFrom: action.dateFrom,
          dateTo: action.dateTo,
        };
      case types.SET_MACHINE_FILTER:
        return {
          ...state,
          machineFilter: action.payload,
        };
      case types.SET_DRIVER_FILTER:
        return {
          ...state,
          driverFilter: action.payload,
        };
      case types.RESET_FILTERS:
        return {
          ...state,
          driverFilter: [],
          machineFilter: [],
        };

      case types.SET_SELECTED_MACHINE_GPS_UNIT:
        return {
          ...state,
          selectedMachineGpsUnit: action.payload,
        };
      default:
        return state;
    }
  };
