import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import storesUI from "../evidence/stores/list/reducer/stores.reducer";
import storeUI from "../evidence/stores/store/reducer/store.reducer";
import fertilizersApi from "../shared/api/agroevidence/catalogues/fertilizers/fertilizers.reducer";
import plantProtectionApi from "../shared/api/agroevidence/catalogues/plantProtection/plantProtection.reducer";
import seedsApi from "../shared/api/agroevidence/catalogues/seeds/seeds.reducer";
import unitsApi from "../shared/api/core/units/units.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import storesApi from "../shared/api/stores/stores/stores.reducer";
import transactionsApi from "../shared/api/stores/transactions/transactions.reducer";

// stores module reducer
export default (history) =>
  combineReducers({
    ui: combineReducers({
      stores: storesUI(),
      store: storeUI(),
      cache: () => ["stores", "store"],
    }),
    api: combineReducers({
      agroevidence: combineReducers({
        seeds: seedsApi(),
        plantProtection: plantProtectionApi(),
        fertilizers: fertilizersApi(),
      }),
      stores: storesApi(),
      transactions: transactionsApi(),
      units: unitsApi(),
    }),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
