import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate } from "react-intl";

import CfLazyImage from "../../../../shared/components/common/CfLazyImage/CfLazyImage";

import { HistorySnap } from "../../../../shared/api/sentinel/management/management.types";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: "10px 0px 5px 10px",
  },
  image: {
    width: "150px",
    height: "auto",
    imageRendering: "pixelated",
    "&": {
      // fallback for FF
      imageRendering: "-moz-crisp-edges",
    },
    backgroundColor: theme.palette.grey[100],
  },
  dateHeading: {
    textAlign: "center",
    padding: 2,
    fontSize: 13,
  },
  images: {
    display: "flex",
    flexDirection: (isMobileView) => (isMobileView ? "row" : "column"),
  },
}));

interface ManagementHistoryImageProps {
  item: HistorySnap;
  width: string;
}

const ManagementHistoryImage: FC<ManagementHistoryImageProps> = ({
  item,
  width,
}) => {
  const isMobileView = width === "xs";
  const classes = useStyles(isMobileView);

  return (
    <div className={classes.wrapper} data-test="history-item">
      <div className={classes.images}>
        <CfLazyImage
          alt={`History Snap from ${item.date}`}
          classes={{ custom: classes.image }}
          src={item.managementZoneImgPath}
          testId="satellite"
        />
        <CfLazyImage
          alt={`History Snap from ${item.date}`}
          classes={{ custom: classes.image }}
          src={item.involvementImgPath}
          testId="involvement"
        />
      </div>
      <div className={classes.dateHeading} data-test="date">
        <FormattedDate value={new Date(item.date)} />
      </div>
    </div>
  );
};

export default ManagementHistoryImage;
