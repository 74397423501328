/**
 * Created by ondrejzvara on 3.3.16.
 */

/* @ngInject */
export default function OrganismsResource(Api, ResourceUtils) {
  const BASE_URL = "organisms";
  const baseAll = Api.setBaseAll(BASE_URL);

  const service = {
    searchOrganism,
    createOrganism,
  };
  return service;

  // ///////////////////////

  function searchOrganism(ngTparams, custParams) {
    const params = ResourceUtils.composeParams(ngTparams, custParams);

    /* jshint camelcase: false */
    if (custParams && custParams.search) {
      params.search = custParams.search;
    }

    return ResourceUtils.getItems(baseAll, params, ngTparams);
  }

  function createOrganism(item) {
    return ResourceUtils.createItem(baseAll, item, true)
      .then((response) => ResourceUtils.getIdAfterCreate(baseAll, response))
      .then(getOrganism);
  }

  function getOrganism(id) {
    const baseOne = Api.setBaseOne(BASE_URL, id);
    return ResourceUtils.getItem(baseOne);
  }
}
