import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

// https://mui.com/components/use-media-query/#migrating-from-withwidth
const useWidth = (): Breakpoint => {
  const theme = useTheme();
  if (!theme) {
    return "xs";
  }

  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const useIsMobile = () => {
  const width = useWidth();
  const mobileBreakPoints: Breakpoint[] = ["xs", "sm"];

  return mobileBreakPoints.includes(width);
};

export default useWidth;
export { useIsMobile };
