export const FORM_TYPES = {
  OTHER: "OTHER",
  HARVEST: "HARVEST",
  SOWING: "SOWING",
  MOWING: "MOWING",
};

export const FORM_TITLE_LOCALIZATION = {
  [FORM_TYPES.OTHER]: "action.formTitle.other",
  [FORM_TYPES.HARVEST]: "common.HARVEST",
  [FORM_TYPES.SOWING]: "common.SOWING",
  [FORM_TYPES.MOWING]: "common.MOWING",
};

export const OTHER_ACTION_HARVEST_CODE = "HARVEST";
export const OTHER_ACTION_SOWING_CODE = "SOWING";
export const OTHER_ACTION_MOWING_CODE = "MOWING";
export const NOT_SOWN_PARCEL_SEED_NAME = "Bez plodiny";
