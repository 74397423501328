import React, { Component } from "react";

import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  reduxForm,
  getFormValues,
  getFormSyncErrors,
  getFormSyncWarnings,
  getFormMeta,
} from "redux-form";

import { exportVariableApplications } from "../actions/actions.actions";

import {
  createSowingActionApi,
  updateSowingActionApi,
  createEPHActionApi,
  getEPHRestrictionsApi,
  resetActionRestriction,
  updateEPHActionApi,
} from "../../../../shared/api/agroevidence/actions/actions.api";
import { saveVariableExpense } from "../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import { getDisplayName } from "../../../../shared/hocs/hocHelpers";
import withEditing from "../../../../shared/hocs/withEditing";
import LocalStorage from "../../../../shared/services/LocalStorage.service";
import ActionStateMapper from "../services/ActionStateMapper.service";

let timerId = null;

const actionForm =
  ({
    destroyOnUnmount = false,
    formName,
    initialValues,
    lsTransformFnc = (arg) => arg,
    mapRequestBodyEphActionTo,
    mapRequestBodyVRSTo,
    onChange,
    onCreateCallback,
    onSubmit,
    onUpdateCallback,
    validate,
    warn,
  }) =>
  (WrappedComponent) => {
    class ActionForm extends Component {
      constructor(props) {
        super(props);

        this.state = {
          isExisting: Boolean(props.existingAction),
          // a map of a manually edited fields; we do not use touched prop of redux-form, because
          // in RF field gets touched even if validation fails without prior user edits
          fieldsManuallyChanged: {},
        };
      }

      componentDidMount() {
        const { existingAction, farmId, initParcelIds, initialize, langId } =
          this.props;
        let record = null;
        if (existingAction) {
          if (existingAction.isEph) {
            record = ActionStateMapper.mapEphActionState(existingAction);
          } else if (existingAction.isVrf) {
            record = ActionStateMapper.mapVrfActionState(existingAction);
          } else if (existingAction.isVrs) {
            record = ActionStateMapper.mapVrsActionState(
              existingAction,
              langId,
            );
          }
        } else {
          const lsRecord = LocalStorage.loadFromLocalStorage(
            `state_${formName}_${farmId}`,
          );
          record =
            !lsRecord || initParcelIds?.length
              ? initialValues
              : {
                  ...lsRecord,
                  ...(lsRecord.actionDate
                    ? { actionDate: moment(lsRecord.actionDate) }
                    : {}),
                };
        }
        initialize(record);
      }

      componentDidUpdate(prevProps) {
        const { existingAction, initialized: oldInitialized } = prevProps;
        const { initialized: newInitialized } = this.props;

        /*
         * Validate form. Do when:
         * 1) form is initialized
         */
        if (!oldInitialized && newInitialized) {
          if (!existingAction) {
            this.props.onEditingStart();
          }
        }
      }

      handleFieldManualChange = (fieldName) => {
        this.setState((state) => ({
          fieldsManuallyChanged: {
            ...state.fieldsManuallyChanged,
            [fieldName]: true,
          },
        }));
      };

      handleReset = () => {
        this.props.initialize(initialValues);
        LocalStorage.removeFromLocalStorage(
          `state_${formName}_${this.props.farmId}`,
        );
      };

      handleCancel = () => {
        this.props.onEditingEnd();
        this.props.reset();
      };

      render() {
        const { fieldsManuallyChanged, isExisting } = this.state;
        const { langId, pristine, submitting, ...restProps } = this.props;
        return (
          <WrappedComponent
            fieldsManuallyChanged={fieldsManuallyChanged}
            formName={formName}
            initVals={initialValues}
            isExisting={isExisting}
            isPristine={pristine}
            isSubmitting={submitting}
            langId={langId}
            onCancel={this.handleCancel}
            onFieldManualChange={this.handleFieldManualChange}
            onReset={this.handleReset}
            {...restProps}
          />
        );
      }
    }

    const mapStateToProps = (state) => ({
      formValues: getFormValues(formName)(state),
      formErrors: getFormSyncErrors(formName)(state),
      formWarnings: getFormSyncWarnings(formName)(state),
      formMeta: getFormMeta(formName)(state),
    });

    const mapDispatchToProps = (dispatch) =>
      bindActionCreators(
        {
          updateEPHActionApi,
          createEPHActionApi,
          getEPHRestrictionsApi,
          resetActionRestriction,
          createSowingActionApi,
          updateSowingActionApi,

          // TODO this doesnt belong here, bet no other solution
          // found for now
          saveVariableExpense,
          exportVariableApplications,
        },
        dispatch,
      );

    ActionForm.propTypes = {
      langId: PropTypes.string.isRequired,
      initialize: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      touch: PropTypes.func.isRequired,
      farmId: PropTypes.string.isRequired,
      existingAction: PropTypes.object,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      onEditingEnd: PropTypes.func.isRequired,
      updateEPHActionApi: PropTypes.func.isRequired,
      createEPHActionApi: PropTypes.func.isRequired,
      getEPHRestrictionsApi: PropTypes.func.isRequired,
      createSowingActionApi: PropTypes.func.isRequired,
      updateSowingActionApi: PropTypes.func.isRequired,
      resetActionRestriction: PropTypes.func.isRequired,
      initialized: PropTypes.bool.isRequired,
      onEditingStart: PropTypes.func.isRequired,
      formValues: PropTypes.object,
      initParcelIds: PropTypes.array,
    };

    ActionForm.defaultProps = {
      existingAction: null,
      formValues: {},
      initParcelIds: [],
    };

    ActionForm.displayName = `ActionForm(${getDisplayName(WrappedComponent)})`;

    const defaultOnSubmit = (values, dispatch, props) => {
      const { existingAction, langId } = props;
      const isVRS = props.form === "vrs";
      if (existingAction) {
        if (isVRS) {
          const dto = mapRequestBodyVRSTo(values, langId, existingAction);
          return props
            .updateSowingActionApi(dto)
            .then((result) =>
              responseProcessing(result, values, dispatch, props, true),
            );
        }
        const dto = mapRequestBodyEphActionTo(values, existingAction.id);
        return props
          .updateEPHActionApi(dto)
          .then((result) =>
            responseProcessing(result, values, dispatch, props, true),
          );
      }
      if (isVRS) {
        const dto = mapRequestBodyVRSTo(values, langId);
        return props
          .createSowingActionApi(dto)
          .then((result) =>
            responseProcessing(result, values, dispatch, props),
          );
      }
      const dto = mapRequestBodyEphActionTo(values);
      return props
        .createEPHActionApi(dto)
        .then((result) => responseProcessing(result, values, dispatch, props));
    };

    const responseProcessing = (
      result,
      values,
      dispatch,
      props,
      existingAction = false,
    ) => {
      if (!result.error) {
        if (existingAction) props.onEditingEnd();
        if (existingAction) props.initialize(props.formValues);
        if (!existingAction && onCreateCallback) {
          return onCreateCallback(values, dispatch, props, result);
        }
        if (existingAction && onUpdateCallback) {
          return onUpdateCallback(values, dispatch, props, result);
        }
        return Promise.resolve(result);
      }
      return Promise.reject(
        new Error(
          existingAction ? "Failed updating action" : "Failed creating action",
        ),
      );
    };

    const extendedOnSubmit = (values, dispatch, props) => {
      const { dirty } = props;
      // true when the current form values are different from the initialValues, false otherwise.
      if (!dirty) {
        props.onEditingEnd();
      } else {
        let promise = null;
        if (onSubmit) {
          promise = onSubmit(values, dispatch, props);
        } else {
          promise = defaultOnSubmit(values, dispatch, props);
        }

        return promise.then((result) => {
          if (!result.error) {
            if (result?.payload?.[0]?.actionId) {
              const actionId = result.payload[0].actionId;
              props.ngGoToAction(actionId, "actions.action");
            } else {
              // fallback
              props.ngGoToActions();
            }
          }
          LocalStorage.removeFromLocalStorage(
            `state_${formName}_${props.farmId}`,
          );
        });
      }
    };

    const extendedOnChange = (values, dispatch, props) => {
      const { pristine } = props;
      if (pristine) {
        props.resetActionRestriction();
        return;
      }

      if (onChange) {
        onChange(values, dispatch, props);
      } else {
        if (timerId) {
          clearTimeout(timerId);
          timerId = null;
        }

        const isValid = isEmpty(validate(values));
        if (!isValid) {
          props.resetActionRestriction();
        }
        /*
         * debounce the action restrictions requests,
         * there could be multiple form changes in the batch (like when parcels
         * change the target crop changes accordingly)
         */
        timerId = setTimeout(() => {
          const isVRS = props.form === "vrs";
          if (isValid && !isVRS) {
            const dto = mapRequestBodyEphActionTo(values);
            return props.getEPHRestrictionsApi(dto);
          }
        }, 1000);
      }

      if (!props.existingAction) {
        LocalStorage.saveToLocalStorage(
          lsTransformFnc(values),
          `state_${formName}_${props.farmId}`,
        );
      }
    };

    return compose(
      withEditing,
      connect(mapStateToProps, mapDispatchToProps),
      reduxForm({
        form: formName,
        validate,
        warn,
        onChange: extendedOnChange,
        onSubmit: extendedOnSubmit,
        destroyOnUnmount,
      }),
    )(ActionForm);
  };

export default actionForm;
