/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from "angular";

import "./loader/loader.html";
import "./loader/tbody.html";
import "./por/por.deleteBatch.html";
import "./por/por.deletePorApplication.html";
import "./por/por.edit.html";
import "./por/por.non-edit.html";

import "./modals/confirmPublicClassifier.html";
import "./modals/deleteModalClassifiers.html";

//these are already verified and OK

import "./modals/error500.html";

export default angular.module("app.templates", []).name;
