import React, { Suspense, useState } from "react";

import PropTypes from "prop-types";

import { LANG_KEYS } from "../../../shared/lang/lang.constants";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../shared/containers/BaseContainer/BaseContainer";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import translationsRU from "../../lang/locale-ru-UA.json";
import translationsSR from "../../lang/locale-sr-LATN-RS.json";
import Telematics from "../Telematics/Telematics";

import { MachineTo } from "../../../shared/api/telematics/telematics.types";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TelematicsWrapper = (props: any) => {
  const { initMachineFilter: ngInitMachineFilter, ...remainingProps } = props;
  const [initMachineFilter, setInitMachineFilter] = useState<
    MachineTo | undefined
  >(ngInitMachineFilter);

  return (
    <BaseContainer langId={props.langId} translations={translations}>
      <Suspense fallback={<CfLoader />}>
        <Telematics
          initMachineFilter={initMachineFilter}
          setInitMachineFilter={setInitMachineFilter}
          {...remainingProps}
        />
      </Suspense>
    </BaseContainer>
  );
};

TelematicsWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
};

export default TelematicsWrapper;
