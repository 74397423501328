import React, { FC, Fragment, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import withPopover from "../../../../../shared/hocs/withPopover";
import ParcelCenterAssign from "../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign";
import ParcelZoneAssign from "../../../shared/containers/ParcelZoneAssign/ParcelZoneAssign";

export interface ParcelListActionsProps {
  anchorEl?: null | Element;
  handlePopoverClose: () => void;
  handlePopoverOpen: (evt: React.SyntheticEvent) => void;
  isOpen: boolean;
  ngRedirectToEph: () => void;
  ngRedirectToHarvest: () => void;
  ngRedirectToMowing: () => void;
  ngRedirectToOtherActionsNew: () => void;
  ngRedirectToSowing: () => void;
  onAssignCenter: (
    centerId: string,
    parcelIds: string[],
    bulk: boolean,
  ) => void;
  onAssignZone: (zoneId: string, parcels: string[], bulk: boolean) => void;
  selected: string[];
}

export const ParcelListActions: FC<ParcelListActionsProps> = ({
  anchorEl = null,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToSowing,
  onAssignCenter,
  onAssignZone,
  selected,
}) => {
  const classes = useStyles();
  const [isOpenedCollapse, setIsOpenedCollapse] = useState(false);
  const [isOpenedCentersCollapse, setIsOpenedCentersCollapse] = useState(false);

  const handleCollapseClick = () => setIsOpenedCollapse(!isOpenedCollapse);

  const handleCentersCollapseClick = () =>
    setIsOpenedCentersCollapse(!isOpenedCentersCollapse);

  const setCollapsesClose = () => {
    setIsOpenedCollapse(false);
    setIsOpenedCentersCollapse(false);
  };

  return (
    <Fragment>
      <Button
        aria-label="parcel list actions button"
        disabled={!selected.length}
        className={`${classes.button} ${
          selected.length ? classes.activeButton : ""
        }`}
        onClick={(evt: React.SyntheticEvent) => {
          handlePopoverOpen(evt);
        }}
      >
        <span className={classes.selectedLabel}>
          <FormattedMessage id="common.selected" />
          {selected.length > 0 && `\u00A0(${selected.length})`}
        </span>
        <ArrowDropDownIcon className={classes.rightIcon} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="parcel list actions menu"
        open={isOpen}
        onClose={() => {
          handlePopoverClose();
          setCollapsesClose();
        }}
      >
        <ListItem button data-test="do-sowing" onClick={ngRedirectToSowing}>
          <ListItemText>
            <FormattedMessage id="common.addOtherSowingActivity" />
          </ListItemText>
        </ListItem>
        <ListItem button data-test="do-eph" onClick={ngRedirectToEph}>
          <ListItemText>
            <FormattedMessage id="common.addEph" />
          </ListItemText>
        </ListItem>
        <ListItem
          button
          data-test="do-other"
          onClick={ngRedirectToOtherActionsNew}
        >
          <ListItemText>
            <FormattedMessage id="common.addOtherActivity" />
          </ListItemText>
        </ListItem>
        <ListItem button data-test="do-mowing" onClick={ngRedirectToMowing}>
          <ListItemText>
            <FormattedMessage id="common.addOtherMowingActivity" />
          </ListItemText>
        </ListItem>
        <ListItem button data-test="do-harvest" onClick={ngRedirectToHarvest}>
          <ListItemText>
            <FormattedMessage id="common.addHarvestActivity" />
          </ListItemText>
        </ListItem>
        <ListItem
          button
          data-test="parcel-zone-assign"
          onClick={handleCollapseClick}
        >
          <ListItemText>
            <FormattedMessage id="ParcelListActions.assign-zone" />
          </ListItemText>
          {isOpenedCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isOpenedCollapse} timeout="auto" unmountOnExit>
          <ParcelZoneAssign
            onZoneChange={(zoneId: string) => {
              handlePopoverClose();
              setCollapsesClose();
              onAssignZone(zoneId, selected, true);
            }}
          />
        </Collapse>
        <ListItem
          button
          data-test="parcel-center-assign"
          onClick={handleCentersCollapseClick}
        >
          <ListItemText>
            <FormattedMessage id="ParcelListActions.assign-center" />
          </ListItemText>
          {isOpenedCentersCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isOpenedCentersCollapse} timeout="auto" unmountOnExit>
          <ParcelCenterAssign
            withPopoverEl={false}
            onCenterChange={(centerId: string) => {
              handlePopoverClose();
              setCollapsesClose();
              onAssignCenter(centerId, selected, true);
            }}
          />
        </Collapse>
      </Menu>
    </Fragment>
  );
};

export default withPopover(ParcelListActions);

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    paddingRight: 6,
    marginRight: 8,
  },
  rightIcon: {
    position: "relative",
    top: -1,
  },
  selectedLabel: {
    paddingTop: 2,
  },
}));
