import React, { Component } from "react";

import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import PropTypes from "prop-types";

// TODO: using @mui/x-date-pickers5 here because v6 has problems with error validation in DatePicker

class CfDatePickerTranslator extends Component {
  constructor(props) {
    super(props);
    const { format, value } = props;

    let dateValue = null;
    if (value && moment.isMoment(value) && value.isValid()) {
      dateValue = value;
    }

    this.state = {
      dateValue,
      format,
    };
  }

  componentDidUpdate(prevProps) {
    const { dateValue } = this.state;
    const { momentLocale: newMomentLocale, value: newValue } = this.props;
    const { momentLocale: oldMomentLocale } = prevProps;
    const format = moment.localeData().longDateFormat("L");

    if (newValue !== dateValue) {
      if (moment.isMoment(newValue) && newValue.isValid()) {
        this.setState({
          dateValue: newValue,
        });
      }
    }

    if (newMomentLocale !== oldMomentLocale) {
      if (moment.isMoment(newValue) && newValue.isValid()) {
        const newVal = newValue.locale(newMomentLocale);
        this.setState({
          dateValue: newVal,
          format,
        });
      }
    }
  }

  onChange = (dateValue) => {
    this.setState({
      dateValue,
    });
    this.props.onChange(dateValue);
  };

  render() {
    const { dateValue, format } = this.state;
    const { error, helperText, momentLocale, monthInNominativ } = this.props;

    const csMonthsNominativLocale =
      momentLocale === "cs" && monthInNominativ
        ? "cs-month-nominativ"
        : momentLocale;

    return (
      <LocalizationProvider
        adapterLocale={csMonthsNominativLocale}
        dateAdapter={AdapterMoment}
      >
        <DatePicker
          {...this.props}
          format={format}
          onChange={this.onChange}
          PaperProps={{ style: { whiteSpace: "nowrap" } }}
          value={dateValue}
          renderInput={(props) => (
            <TextField
              name="actionDate"
              {...props}
              error={error}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
}

CfDatePickerTranslator.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
};

CfDatePickerTranslator.defaultProps = {
  value: null,
};

export default CfDatePickerTranslator;
