import { AnyAction } from "redux";

import * as commonFilterTypes from "../../../../shared/actions/filter.constants";
import * as commonTableTypes from "../../../../shared/actions/table.constants";
import { ROWS_PER_PAGE } from "../../../../shared/constants/rowsPerPage.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";
import * as types from "../actions/admin.devices.constants";

export const NAMESPACE = "irrigation_admin";

const initialState = {
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  order: sortOrder.ASC,
  orderBy: "id",
  selected: [],
  textFilter: "",
  advancedFilter: {},
  farm: {},
  country: {},
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return { ...state, page: action.page };

      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return { ...state, rowsPerPage: action.rowsPerPage, page: 0 };

      case commonTableTypes.SET_TABLE_ORDER:
        return { ...state, order: action.order, page: 0 };

      case commonTableTypes.SET_TABLE_ORDER_BY:
        return { ...state, orderBy: action.orderBy, page: 0 };

      case commonTableTypes.SET_TABLE_SELECTED:
        return { ...state, selected: action.selected };

      case commonFilterTypes.SET_TEXT_FILTER:
        return { ...state, textFilter: action.textFilter, page: 0 };

      case commonFilterTypes.SET_ADVANCED_FILTER:
        return { ...state, advancedFilter: action.advancedFilter, page: 0 };

      case types.SET_FARM_FILTER:
        return { ...state, farm: action.farm, page: 0 };

      case types.SET_COUNTRY_FILTER:
        return { ...state, country: action.country, page: 0 };

      default:
        return state;
    }
  };
