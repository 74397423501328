import React, { Component } from "react";

import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getEvapotranspirationData } from "../../../shared/api/iot/locations/locations.selectors";
import { getSensorsData } from "../../../shared/api/iot/sensors/sensors.selectors";
import {
  getDateFrom,
  getDateTo,
  getDuration,
  getGranularity,
} from "../../selectors/node.selectors";

import {
  setDuration,
  resetDuration,
  setGranularity,
  setDate,
  getNumberOfDays,
} from "../../actions/node.actions";

import { resetEvapotranspirationData } from "../../../shared/api/iot/locations/locations.api";
import DateRangeSelector from "../../../shared/components/common/DateRangeSelector/DateRangeSelector";
import PeriodSwitcher from "../../../shared/components/common/PeriodSwitcher/PeriodSwitcher";
import NodeLocationChart from "../NodeLocationChart/NodeLocationChart";

const styles = (theme) => ({
  nodeCharts: {
    marginBottom: 16,
  },
  nodeChartsPaper: {
    padding: 20,
  },
  chartsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 8,
  },
  switchers: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  switchersLabel: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px 5px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "0px 10px 0px 0px",
    },
  },
});

export class NodeLocationCharts extends Component {
  constructor(props) {
    super(props);
    const { nodeLocation } = props;

    this.state = {
      config: nodeLocation.node.config,
    };
  }

  componentDidMount() {
    const { dateFrom, dateTo } = this.props;
    const numberOfSelectedDays = getNumberOfDays(dateFrom, dateTo);
    this.props.setDuration(numberOfSelectedDays);
  }

  componentDidUpdate(prevProps) {
    const { evapotranspirationData, nodeLocation, sensorsData } = this.props;
    if (nodeLocation.id !== prevProps.nodeLocation.id) {
      this.props.resetDuration();
    }
    if (
      prevProps.sensorsData !== sensorsData ||
      prevProps.evapotranspirationData !== evapotranspirationData
    ) {
      this.updateConfig(sensorsData, evapotranspirationData);
    }
  }

  componentWillUnmount() {
    this.props.resetEvapotranspirationData();
    this.props.resetDuration();
  }

  updateConfig(sensorsData = null, evapotranspirationData = null) {
    const {
      node: { config },
    } = this.props.nodeLocation;

    if (sensorsData || evapotranspirationData) {
      const configWithData = {
        ...config,
        charts: config.charts.map((chart) => ({
          ...chart,
          features: chart.features.map((feature) => {
            if (feature.name.startsWith("FEATURE_EVAPOTRANSPIRATION")) {
              if (!isEmpty(evapotranspirationData)) {
                return {
                  ...feature,
                  sensors: [
                    {
                      feature: "Evapotranspiration",
                      normalized: true,
                      aggregations: Object.keys(evapotranspirationData),
                      data: [evapotranspirationData],
                      value: evapotranspirationData.total,
                    },
                  ],
                };
              }
              return feature;
            }
            return {
              ...feature,
              sensors: feature.sensors.map((sensor) => {
                if (sensorsData[sensor.id]) {
                  return {
                    ...sensor,
                    aggregations: Object.keys(sensorsData[sensor.id]),
                    data: [sensorsData[sensor.id]],
                  };
                }
                return sensor;
              }),
            };
          }),
        })),
      };

      this.setState({
        config: configWithData,
      });
    }
  }

  render() {
    const {
      classes,
      dateFrom,
      dateTo,
      duration,
      evapotranspirationData,
      granularity,
      nodeLocation,
    } = this.props;
    const { config } = this.state;
    return (
      <div className={classes.nodeCharts}>
        <Grid className={classes.chartsHeader} container>
          <div className={classes.switchers}>
            <div className={classes.switchersLabel}>
              <FormattedMessage id="NodeCharts.chartOFAverageValues" />
            </div>
            <DateRangeSelector
              dateFrom={dateFrom}
              dateTo={dateTo}
              langId={this.props.intl?.locale}
              minDate={nodeLocation.from}
              setDate={this.props.setDate}
            />
          </div>
          <div className={classes.switchers}>
            <div className={classes.switchersLabel}>
              <FormattedMessage id="NodeCharts.chartInterval" />
            </div>
            {granularity && (
              <PeriodSwitcher
                duration={duration}
                granularity={granularity}
                setGranularity={this.props.setGranularity}
              />
            )}
          </div>
        </Grid>
        <Paper className={classes.nodeChartsPaper}>
          {config &&
            duration &&
            granularity &&
            dateFrom &&
            dateTo &&
            config.charts.map((chart) => {
              const { name } = chart;
              const isWindChart = name.startsWith("CHART_WIND");
              const isEvapotranspirationChart = name.startsWith(
                "CHART_EVAPOTRANSPIRATION",
              );
              const isBaraniDevice =
                nodeLocation.node.deviceType === "METEO_BARANI_HELIX";

              return (isWindChart || isEvapotranspirationChart) &&
                isBaraniDevice ? (
                nodeLocation.evapotranspiration && (
                  <NodeLocationChart
                    chart={chart}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    duration={duration}
                    granularity={granularity}
                    key={`${nodeLocation.id}-${chart.name}`}
                    location={nodeLocation}
                    totalEvapotranspiration={evapotranspirationData.total}
                  />
                )
              ) : (
                <NodeLocationChart
                  chart={chart}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  duration={duration}
                  granularity={granularity}
                  key={`${nodeLocation.id}-${chart.name}`}
                  location={nodeLocation}
                  totalEvapotranspiration={evapotranspirationData.total}
                />
              );
            })}
        </Paper>
      </div>
    );
  }
}

NodeLocationCharts.propTypes = {
  nodeLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  resetEvapotranspirationData: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  duration: PropTypes.number,
  granularity: PropTypes.string,
  setDuration: PropTypes.func.isRequired,
  resetDuration: PropTypes.func.isRequired,
  setGranularity: PropTypes.func.isRequired,
  sensorsData: PropTypes.object.isRequired,
  evapotranspirationData: PropTypes.object.isRequired,
};

NodeLocationCharts.defaultProps = {
  classes: {},
  historicPestPredictions: [],
};

const mapStateToProps = (state) => ({
  dateFrom: getDateFrom(state),
  dateTo: getDateTo(state),
  duration: getDuration(state),
  granularity: getGranularity(state),
  sensorsData: getSensorsData(state),
  evapotranspirationData: getEvapotranspirationData(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDate,
      resetEvapotranspirationData,
      setDuration,
      resetDuration,
      setGranularity,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(compose(withStyles(styles))(NodeLocationCharts)));
