import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const snackerTextStyles = {
  textDark: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  textLight: {
    color: "rgba(255,255,255,1)",
  },
};

const SnackbarText = (props) => {
  const { children, classes, color } = props;
  return (
    <span className={color === "light" ? classes.textLight : classes.textDark}>
      {children}
    </span>
  );
};

SnackbarText.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  color: PropTypes.oneOf(["dark", "light"]),
};

SnackbarText.defaultProps = {
  children: null,
  color: "dark",
};

export default withStyles(snackerTextStyles)(SnackbarText);
