import React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    root: {
      minWidth: (props) =>
        props.minWidth >= 0 ? props.minWidth : `${breakpoints.values.sm}px`,
      position: "relative",
      borderCollapse: "initial",
    },
    paper: {
      overflowX: "auto",
    },
  };
};

const CfTableWrapper = (props) => {
  const { children, classes, customPaperStyles, customTableStyles } = props;
  return (
    <Paper
      className={classnames(
        classes.paper,
        customPaperStyles && customPaperStyles,
      )}
    >
      <Table
        data-test={props.testId}
        className={classnames(
          classes.root,
          customTableStyles && customTableStyles,
        )}
      >
        {children}
      </Table>
    </Paper>
  );
};

CfTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  testId: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  minWidth: PropTypes.number,
  customTableStyles: PropTypes.string,
  customPaperStyles: PropTypes.string,
};

CfTableWrapper.defaultProps = {
  testId: "table",
  minWidth: -1,
};

export default withStyles(styles)(CfTableWrapper);
