/**
 * Created by ondrejzvara on 11.2.16.
 */
// jshint ignore: start

import LocalStorage from "../../../shared/services/LocalStorage.service";

/* @ngInject */
export default function Table($state) {
  const service = {
    getCheckedItems,
  };

  return service;

  // ///////////////////////

  function getCheckedItems(data, checkboxes) {
    const itemsToBeProcessed = [];
    _.each(data, (item) => {
      if (checkboxes.items[item.id]) {
        itemsToBeProcessed.push(item.id);
      }
    });
    return itemsToBeProcessed;
  }
}
