import { CSSProperties, ReactNode } from "react";

import { TableCellProps } from "@mui/material";

import { ColumnData } from "../../catalogues/helpers/tableHelpers";

export const getColDesc = (
  sortable: boolean,
  label: ReactNode,
  style?: CSSProperties,
  align: TableCellProps["align"] = "inherit",
  rightPadding = false,
): ColumnData => ({
  align,
  sortable,
  label,
  style,
  rightPadding,
});
