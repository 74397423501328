import React, { ChangeEvent, FC } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import combineIcon from "../../../../assets/img/icons/telematics/combine.svg";
import loaderIcon from "../../../../assets/img/icons/telematics/loader.svg";
import otherIcon from "../../../../assets/img/icons/telematics/other.svg";
import passengerIcon from "../../../../assets/img/icons/telematics/passenger.svg";
import tractorIcon from "../../../../assets/img/icons/telematics/tractor.svg";
import truckIcon from "../../../../assets/img/icons/telematics/truck.svg";

import {
  MachineGroupCode,
  MachineTo,
} from "../../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  groupItem: {
    display: "flex",
  },
  listItemButtonGroup: {
    paddingLeft: "5px",
  },
  listItemIcon: {
    marginRight: "5px",
  },
  listItemText: {
    fontWeight: 600,
  },
  listItemTextPrimary: {
    color: theme.palette.grey[500],
    paddingLeft: "5px",
  },
  paper: {
    maxHeight: 200,
    overflow: "auto",
  },
  listItemButtonMachine: {
    paddingLeft: "20px",
  },
}));

interface MainMapTelematicsMachineSelectorGroupItemProps {
  group: string;
  groupName?: string;
  handleOpenGroup(): void;
  machinesInGroup: MachineTo[];
  onChangeMachineFilter(items: MachineTo[], checked: boolean): void;
  openGroupState: boolean;
  selectedValues: MachineTo[];
}

const getMachineIcon = (group: string) => {
  switch (group) {
    case MachineGroupCode.COMBINE:
      return combineIcon;
    case MachineGroupCode.LOADER:
      return loaderIcon;
    case MachineGroupCode.OTHER:
      return otherIcon;
    case MachineGroupCode.PASSENGER:
      return passengerIcon;
    case MachineGroupCode.TRACTOR:
      return tractorIcon;
    case MachineGroupCode.TRUCK:
      return truckIcon;
    default:
      return null;
  }
};

const MainMapTelematicsMachineSelectorGroupItem: FC<
  MainMapTelematicsMachineSelectorGroupItemProps
> = ({
  group,
  groupName,
  handleOpenGroup,
  machinesInGroup,
  onChangeMachineFilter,
  openGroupState,
  selectedValues,
}) => {
  const classes = useStyles();

  const handleGroupChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChangeMachineFilter(machinesInGroup, evt.target.checked);
  };

  const selectedGpsUnit = selectedValues.map((item) => item.gpsUnit);
  const isGroupChecked = machinesInGroup.every((machine) =>
    selectedGpsUnit.includes(machine.gpsUnit),
  );
  const isGroupIndeterminate = machinesInGroup.some((machine) =>
    selectedGpsUnit.includes(machine.gpsUnit),
  );

  return (
    <div>
      <div className={classes.groupItem}>
        <Checkbox
          checked={isGroupChecked}
          indeterminate={isGroupIndeterminate && !isGroupChecked}
          onChange={handleGroupChange}
        />
        <ListItemButton
          className={classes.listItemButtonGroup}
          onClick={handleOpenGroup}
        >
          <ListItemIcon>
            <img
              alt="icon"
              className={classes.listItemIcon}
              src={getMachineIcon(group)}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={groupName}
            primaryTypographyProps={{ style: { fontWeight: 600 } }}
          />
          {openGroupState ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </div>
      <Collapse in={openGroupState} timeout="auto" unmountOnExit>
        <Paper className={classes.paper} elevation={0}>
          <List dense disablePadding>
            {machinesInGroup.map((machine) => {
              const { gpsUnit, licencePlate, name } = machine;
              const isMachineChecked = selectedValues.some(
                (selected) => selected.gpsUnit === gpsUnit,
              );

              const handleChange = () => {
                onChangeMachineFilter([machine], !isMachineChecked);
              };

              const getSubText = () => {
                if (licencePlate) return `(${licencePlate})`;
                if (name) return `(${gpsUnit})`;
                return "";
              };
              return (
                <ListItemButton
                  className={classes.listItemButtonMachine}
                  key={gpsUnit}
                  onClick={handleChange}
                >
                  <Checkbox
                    checked={isMachineChecked}
                    onChange={handleChange}
                  />
                  <ListItemText
                    primary={
                      <span className={classes.groupItem}>
                        {name ?? gpsUnit}
                        <span className={classes.listItemTextPrimary}>
                          {getSubText()}
                        </span>
                      </span>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Paper>
      </Collapse>
      <Divider />
    </div>
  );
};

export default MainMapTelematicsMachineSelectorGroupItem;
