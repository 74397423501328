import React, { Fragment, Component } from "react";

import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfSimpleDialog from "../../../shared/components/common/CfSimpleDialog/CfSimpleDialog";
import FusariumCalculator from "../FusariumCalculator/FusariumCalculator";

const styles = {
  button: {
    minHeight: 28,
    padding: "4px 8px",
  },
};

class FusariumCalculatorDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onDialogClose = () => {
    this.setState({
      open: false,
    });
  };

  onDialogOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <Button
          className={classes.button}
          color="primary"
          data-test="risk-calculator"
          onClick={this.onDialogOpen}
          size="small"
        >
          <FormattedMessage id="FusariumCalculatorDialog.riskCalculator" />
        </Button>
        <CfSimpleDialog
          fullWidth={true}
          maxWidth="md"
          onDialogClose={this.onDialogClose}
          open={open}
          heading={
            <FormattedMessage id="FusariumCalculatorDialog.fusariumRiskCalculator" />
          }
        >
          <div className={classes.wrapper}>
            <FusariumCalculator />
          </div>
        </CfSimpleDialog>
      </Fragment>
    );
  }
}

FusariumCalculatorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FusariumCalculatorDialog);
