import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import { EFFECTIVENESS } from "../../services/Effectiveness.services";

const styles = (theme) => ({
  radio: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class EffectivenessFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.selected,
    };
  }
  changeState = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
    this.props.handleChange(event.target.value);
  };
  render() {
    const { classes, disabled } = this.props;
    return (
      <Grid container direction="row">
        <Grid className={classes.gridItem} item xs={4}>
          <Radio
            checked={this.state.selectedValue === EFFECTIVENESS.YES}
            disabled={disabled}
            name="effectiveness-yes"
            onChange={this.changeState}
            value={EFFECTIVENESS.YES}
            classes={
              disabled ? {} : { root: classes.radio, checked: classes.checked }
            }
          />
        </Grid>
        <Grid className={classes.gridItem} item xs={4}>
          <Radio
            checked={this.state.selectedValue === EFFECTIVENESS.NO}
            disabled={disabled}
            name="effectiveness-no"
            onChange={this.changeState}
            value={EFFECTIVENESS.NO}
            classes={
              disabled ? {} : { root: classes.radio, checked: classes.checked }
            }
          />
        </Grid>
        <Grid className={classes.gridItem} item xs={4}>
          <Radio
            checked={this.state.selectedValue === EFFECTIVENESS.NOT_SPECIFIED}
            disabled={disabled}
            name="effectiveness-notSpecified"
            onChange={this.changeState}
            value={EFFECTIVENESS.NOT_SPECIFIED}
            classes={
              disabled ? {} : { root: classes.radio, checked: classes.checked }
            }
          />
        </Grid>
      </Grid>
    );
  }
}

EffectivenessFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EffectivenessFields);
