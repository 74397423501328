import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getApiError,
  getIsAreaDeleting,
} from "../../../../../shared/api/irrigation/areas/areas.selectors";

import { deleteArea } from "../../../../actions/areasList.actions";

import { resetApiError } from "../../../../../shared/api/irrigation/areas/areas.api";
import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import ErrorSnackbar from "../../../../components/ErrorSnackbar/ErrorSnackbar";

const useStyles = makeStyles((theme) => ({
  buttons: {
    textAlign: "right",
  },
  button: {
    width: 32,
    height: 32,
    padding: 0,
  },
  buttonMargin: {
    marginRight: "1rem",
  },
  dialogDescription: {
    marginBottom: "1rem",
  },
  dialogCheck: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogCheckError: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginLeft: "2rem",
  },
  dialogProgress: {
    marginTop: "1rem",
  },
}));

const handleStopPropagation = (e) => {
  e.stopPropagation();
};

const AreaButtonsCell = ({
  apiError,
  area,
  deleteArea,
  isAreaDeleting,
  isInlineDeleteConfirmation,
  resetApiError,
  setIsInlineDeleteConfirmation,
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const handleDelete = () => {
    if (area.deviceCount === 0) {
      setIsInlineDeleteConfirmation(true);
    } else {
      setIsChecked(false);
      setShowErrorMsg(false);
      setIsDialogOpen(true);
    }
  };

  const handleClose = () => {
    setIsInlineDeleteConfirmation(false);
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    if (!isChecked && isDialogOpen) {
      setShowErrorMsg(true);
      return;
    }
    deleteArea(area.id, handleClose);
  };

  const handleCheckboxChange = (e, value) => {
    setIsChecked(value);
    if (value) setShowErrorMsg(false);
  };

  return (
    <TableCell
      classes={{ root: classes.buttons }}
      colSpan={isInlineDeleteConfirmation ? 2 : undefined}
      key="buttons"
      onClick={handleStopPropagation}
    >
      {isInlineDeleteConfirmation ? (
        <>
          {isAreaDeleting ? (
            <LinearProgress color="secondary" />
          ) : (
            <>
              <Button
                className={classes.buttonMargin}
                color="primary"
                onClick={handleConfirm}
                variant="contained"
              >
                <FormattedMessage id="common.delete" />
              </Button>
              <Button onClick={handleClose} variant="contained">
                <FormattedMessage id="common.cancel" />
              </Button>
            </>
          )}
        </>
      ) : (
        <Tooltip
          title={<FormattedMessage id="IrrigationAreaList.deleteArea" />}
        >
          <IconButton
            classes={{ root: classes.button }}
            className={classes.editButton}
            onClick={handleDelete}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <CfDialog
        acceptText={<FormattedMessage id="common.delete" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={handleConfirm}
        onCancel={handleClose}
        onClose={handleClose}
        opened={isDialogOpen}
        title={
          <FormattedMessage
            id="IrrigationAreaList.deleteAreaTitle"
            values={{ areaName: area.name }}
          />
        }
      >
        <div>
          <div className={classes.dialogDescription}>
            <FormattedMessage id="IrrigationAreaList.deleteAreaDescription" />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.dialogCheck}
                  color="primary"
                  onChange={handleCheckboxChange}
                  value={isChecked}
                />
              }
              label={
                <FormattedMessage id="IrrigationAreaList.deleteAreaCheck" />
              }
            />
          </div>
          {showErrorMsg && (
            <div className={classes.dialogCheckError}>
              <FormattedMessage id="IrrigationAreaList.deleteAreaError" />
            </div>
          )}
          {isAreaDeleting && (
            <div className={classes.dialogProgress}>
              <LinearProgress color="secondary" />
            </div>
          )}
        </div>
      </CfDialog>
      <ErrorSnackbar handleClose={resetApiError} open={apiError} />
    </TableCell>
  );
};

AreaButtonsCell.propTypes = {
  area: PropTypes.object.isRequired,
  isInlineDeleteConfirmation: PropTypes.bool.isRequired,
  setIsInlineDeleteConfirmation: PropTypes.func.isRequired,
  isAreaDeleting: PropTypes.bool.isRequired,
  deleteArea: PropTypes.func.isRequired,
  apiError: PropTypes.bool.isRequired,
  resetApiError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAreaDeleting: getIsAreaDeleting(state),
  apiError: getApiError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteArea,
      resetApiError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AreaButtonsCell);
