import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./actions.constants";
import { FORM_TYPES } from "../../../../evidence/actions/others/actionOther.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { getCommonParams } from "./action.types";
import {
  ActionEPHCreateTo,
  ActionHarvestCreateTo,
  ActionMowingCreateTo,
  ActionOtherCreateTo,
  ActionSowingCreateTo,
  SplitActionRequest,
} from "../agroevidence.types";

export const getActionsApi = (p: getCommonParams) => ({
  [RSAA]: {
    endpoint: `actions?${queryString.stringify(p)}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ACTIONS_REQUEST,
      types.GET_ACTIONS_SUCCESS,
      types.GET_ACTIONS_ERROR,
    ],
  },
});

export const resetActionsApi = () => ({
  type: types.RESET_ACTIONS,
});

export const deleteActionsApi = (actionIds: string[]) => ({
  [RSAA]: {
    endpoint: `actions?actionIds=${actionIds.join(",")}&`,
    method: methods.DELETE,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_ACTIONS_REQUEST,
      types.DELETE_ACTIONS_SUCCESS,
      types.DELETE_ACTIONS_ERROR,
    ],
  },
});

export const getActionApi = (actionId: string) => ({
  [RSAA]: {
    endpoint: `actions/${actionId}?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ACTION_REQUEST,
      types.GET_ACTION_SUCCESS,
      types.GET_ACTION_ERROR,
    ],
  },
});

export const updateMowingActionApi = (p: ActionMowingCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/mowing?",
    method: methods.PUT,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_MOWING_ERROR,
      types.UPDATE_MOWING_SUCCESS,
      types.UPDATE_MOWING_ERROR,
    ],
  },
});

export const updateHarvestActionApi = (p: ActionHarvestCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/harvest?",
    method: methods.PUT,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_HARVEST_REQUEST,
      types.UPDATE_HARVEST_SUCCESS,
      types.UPDATE_HARVEST_ERROR,
    ],
  },
});

export const updateSowingActionApi = (p: ActionSowingCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/sowing?",
    method: methods.PUT,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_SOWING_REQUEST,
      types.UPDATE_SOWING_SUCCESS,
      types.UPDATE_SOWING_ERROR,
    ],
  },
});

export const updateOtherActionApi = (p: ActionOtherCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/other?",
    method: methods.PUT,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_OTHER_REQUEST,
      types.UPDATE_OTHER_SUCCESS,
      types.UPDATE_OTHER_ERROR,
    ],
  },
});

export const updateEPHActionApi = (p: ActionEPHCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/eph?",
    method: methods.PUT,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_EPH_REQUEST,
      types.UPDATE_EPH_SUCCESS,
      types.UPDATE_EPH_ERROR,
    ],
  },
});

export const createMowingActionApi = (p: ActionMowingCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/mowing?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.CREATE_MOWING_ERROR,
      types.CREATE_MOWING_SUCCESS,
      types.CREATE_MOWING_ERROR,
    ],
  },
});

export const createHarvestActionApi = (p: ActionHarvestCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/harvest?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.CREATE_HARVEST_REQUEST,
      types.CREATE_HARVEST_SUCCESS,
      types.CREATE_HARVEST_ERROR,
    ],
  },
});

export const createSowingActionApi = (p: ActionSowingCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/sowing?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.CREATE_SOWING_REQUEST,
      types.CREATE_SOWING_SUCCESS,
      types.CREATE_SOWING_ERROR,
    ],
  },
});

export const createOtherActionApi = (p: ActionOtherCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/other?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.CREATE_OTHER_REQUEST,
      types.CREATE_OTHER_SUCCESS,
      types.CREATE_OTHER_ERROR,
    ],
  },
});

export const createEPHActionApi = (p: ActionEPHCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/eph?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.CREATE_EPH_REQUEST,
      types.CREATE_EPH_SUCCESS,
      types.CREATE_EPH_ERROR,
    ],
  },
});

export const getEPHRestrictionsApi = (p: ActionEPHCreateTo) => ({
  [RSAA]: {
    endpoint: "actions/eph/restrictions?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.GET_ACTION_RESTRICTIONS_REQUEST,
      types.GET_ACTION_RESTRICTIONS_SUCCESS,
      types.GET_ACTION_RESTRICTIONS_ERROR,
    ],
  },
});

export const postActionSplit = (p: SplitActionRequest) => ({
  [RSAA]: {
    endpoint: "actions/split?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.GET_ACTION_SPLIT_REQUEST,
      types.GET_ACTION_SPLIT_SUCCESS,
      types.GET_ACTION_SPLIT_ERROR,
    ],
  },
});

export const resetActionRestriction = () => ({
  type: types.RESET_ACTION_RESTRICTIONS,
});

export const getOtherActionTypesApi = () => ({
  [RSAA]: {
    endpoint: `actions/action-types?group=${FORM_TYPES.OTHER}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_OTHER_ACTION_TYPES_REQUEST,
      types.GET_OTHER_ACTION_TYPES_SUCCESS,
      types.GET_OTHER_ACTION_TYPES_ERROR,
    ],
  },
});

export const resetOtherActionTypesApi = () => ({
  type: types.RESET_OTHER_ACTION_TYPES,
});
