import { AnyAction } from "redux";

import * as types from "./plantProtection.constants";

const initialState = {
  items: [],
  totalCount: 0,
  isFetching: false,
  isFetchingBioFunctions: false,
  isFetchingOrganisms: false,
  isFetchingActiveSubstances: false,
  activeSubstances: [],
  organisms: [],
  bioFunction: [],
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_PLANT_PROTECTION_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_PLANT_PROTECTION_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_PLANT_PROTECTION_ERROR:
        return {
          ...state,
          isFetching: false,
          totalCount: 0,
        };

      case types.RESET_PLANT_PROTECTION:
        return {
          ...state,
          items: [],
          totalCount: 0,
        };

      case types.GET_ACTIVE_SUBSTANCES_REQUEST:
        return {
          ...state,
          isFetchingActiveSubstances: true,
        };
      case types.GET_ACTIVE_SUBSTANCES_SUCCESS:
        return {
          ...state,
          isFetchingActiveSubstances: false,
          activeSubstances: action.payload,
        };
      case types.GET_ACTIVE_SUBSTANCES_ERROR:
        return {
          ...state,
          isFetchingActiveSubstances: false,
        };
      case types.RESET_ACTIVE_SUBSTANCES:
        return {
          ...state,
          activeSubstances: [],
        };

      case types.GET_ORGANISMS_REQUEST:
        return {
          ...state,
          isFetchingOrganisms: true,
        };
      case types.GET_ORGANISMS_SUCCESS:
        return {
          ...state,
          isFetchingOrganisms: false,
          organisms: action.payload,
        };
      case types.GET_ORGANISMS_ERROR:
        return {
          ...state,
          isFetchingOrganisms: false,
        };
      case types.RESET_ORGANISMS:
        return {
          ...state,
          organisms: [],
        };

      case types.GET_BIO_FUNCTION_REQUEST:
        return {
          ...state,
          isFetchingBioFunctions: true,
        };
      case types.GET_BIO_FUNCTION_SUCCESS:
        return {
          ...state,
          isFetchingBioFunctions: false,
          bioFunction: action.payload,
        };
      case types.GET_BIO_FUNCTION_ERROR:
        return {
          ...state,
          isFetchingBioFunctions: false,
        };
      case types.RESET_BIO_FUNCTION:
        return {
          ...state,
          bioFunction: [],
        };

      default:
        return state;
    }
  };
