import React, { useEffect, useRef, useState } from "react";

type Props = {
  behavior?: ScrollBehavior;
  focusedRow?: string;
  isInit?: boolean;
  rowId: string;
};

export function useFocusedTableRowTarget({
  behavior,
  focusedRow,
  isInit,
  rowId,
}: Props) {
  const isFocused = focusedRow !== undefined && focusedRow === rowId;

  const targetElementRef = useRef(null);

  const [triggerScroll, setTriggerScroll] = useState(false);

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      if (triggerScroll && targetElementRef.current !== null) {
        (targetElementRef.current as HTMLTableRowElement).scrollIntoView({
          behavior: behavior ?? "smooth",
          block: "center",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerScroll]);

  useEffect(() => {
    if (isInit && isFocused) {
      setTriggerScroll(true);
    }
  }, [isInit, focusedRow, rowId, isFocused]);

  return {
    targetElementRef: isFocused ? targetElementRef : undefined,
  };
}
