import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

const styles = (theme) => ({
  wrapper: {
    fontSize: 13,
    marginLeft: 13,
    marginRight: 13,
  },
  itemLabel: {
    color: theme.palette.grey[500],
  },
  itemValue: {
    marginLeft: 3,
    fontWeight: 500,
  },
});

function SensorChartEvapotranspiration(props) {
  const { chartProps, classes, totalEvapotranspiration } = props;
  return (
    <div className={classes.wrapper}>
      {chartProps && (
        <span>
          <span className={classes.itemLabel}>
            <FormattedMessage id="NodeFeature.EVAPOTRANSPIRATION_SUM" />{" "}
          </span>
          <span
            className={classes.itemValue}
            style={{ color: chartProps.stroke }}
          >
            <CfFormattedNumber
              decimalDigits={1}
              value={totalEvapotranspiration || 0}
            />{" "}
            {chartProps.unit}
          </span>
        </span>
      )}
    </div>
  );
}

SensorChartEvapotranspiration.propTypes = {
  classes: PropTypes.object.isRequired,
  chartProps: PropTypes.object,
  totalEvapotranspiration: PropTypes.number.isRequired,
};

SensorChartEvapotranspiration.defaultProps = {
  chartProps: {},
};

export default withStyles(styles)(SensorChartEvapotranspiration);
