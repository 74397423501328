import React, { Fragment, Component } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getParcelsToAdd } from "../../../shared/selectors/actions.selectors";

import {
  addInitParcelsToAdd,
  clearParcelsToAdd,
  fetchProducts,
  fetchUnits,
} from "../../../shared/actions/actions.actions";

import {
  FORM_TITLE_LOCALIZATION,
  FORM_TYPES,
} from "../../actionOther.constants";

import { getSeedsApi } from "../../../../../shared/api/agroevidence/catalogues/seeds/seeds.api";
import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import LocalStorage from "../../../../../shared/services/LocalStorage.service";
import { ActionDetailContext } from "../../../shared/containers/ActionDetail/ActionDetail";
import { defaultValues, FormContext } from "../../actionOther.context";
import ActionOtherFormWrapper from "../../components/ActionOtherFormWrapper/ActionOtherFormWrapper";

import { CoreActionWarningMessage } from "./CoreActionWarningMessage";

const styles = {
  wrapperStyle: {
    position: "absolute",
    top: "56px",
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    margin: 15,
    paddingBottom: 30,
  },
  header: {
    margin: 0,
    overflowX: "hidden",
    width: "100%",
  },
  editButtonItem: {
    textAlign: "right",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warningMessageContainer: {
    margin: "10px 20px",
  },
};

export class ActionOtherDetail extends Component {
  constructor(props) {
    super(props);

    const formType = this.props.formType;

    if (formType === FORM_TYPES.MOWING) {
      props.fetchProducts();
    }

    if (formType === FORM_TYPES.SOWING) {
      props.getSeedsApi({ "include-deleted": true });
      props.fetchUnits();
    }

    this.state = {
      formType,
      initialValues: defaultValues,
      isEditing: this.props.isEditing || false,
      isExisting: this.props.isExisting || false,
      lsName: "",
      hasAddedInitialParcels: false,
    };

    this.hasInitParcels = !!this.props.initParcelIds?.[0];
  }

  static hasInitParcels;

  componentDidMount() {
    const { farmId } = this.props.match.params;
    const { formType } = this.props;

    const lsName = `form_state_${formType}_${farmId}`;
    const savedValuesFromPreviousInteraction =
      LocalStorage.loadFromLocalStorage(lsName);

    const newState = {
      lsName,
      formType,
    };

    if (this.hasInitParcels) {
      this.props.fetchInitialParcelsToAdd(this.props.initParcelIds);
    }

    if (savedValuesFromPreviousInteraction && !this.hasInitParcels) {
      const cleanedValues = {
        ...savedValuesFromPreviousInteraction,
        date: moment(savedValuesFromPreviousInteraction.date),
      };
      newState.initialValues = cleanedValues;
    }

    this.setState({
      ...this.state,
      ...newState,
    });
  }

  componentDidUpdate() {
    const { initialParcelsToAdd } = this.props;

    // run only once when initial parcels data is ready
    if (!this.hasInitParcels) return;
    if (!initialParcelsToAdd.length) return;
    if (this.state.hasAddedInitialParcels) return;

    this.setState({
      ...this.state,
      hasAddedInitialParcels: true,
      initialValues: {
        ...this.state.initialValues,
        parcels: [
          ...this.state.initialValues.parcels,
          ...initialParcelsToAdd.map((p) => this.mapInitialParcelsToAdd(p)),
        ],
      },
    });
    this.props.clearInitialParcelsToAdd();
  }

  onEditingStart = () => {
    this.setState({ ...this.state, isEditing: true });
  };

  onEditingEnd = () => {
    this.setState({ ...this.state, isEditing: false });
  };

  onFormValuesReset = () => {
    this.setState({ ...this.state, initialValues: defaultValues });
  };

  onLsReset = () => {
    const { lsName } = this.state;
    LocalStorage.removeFromLocalStorage(lsName);
  };

  onSaveToLs = (values) => {
    LocalStorage.saveToLocalStorage(values, this.state.lsName);
  };

  mapInitialParcelsToAdd = (parcel) => ({
    ...parcel,
    subtractableAreas: {
      absolute: [],
      boundary: [],
      water: [],
      boundaryChecked: 0,
      waterChecked: 0,
    },
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  contextHelpers = {
    onEditingEnd: this.onEditingEnd,
    onEditingStart: this.onEditingStart,
    resetFormValues: this.onFormValuesReset,
    handleLsReset: this.onLsReset,
    handleSaveToLs: this.onSaveToLs,
  };

  render() {
    const { classes, existingAction, formType, ngGoToActions } = this.props;
    const { isEditing, isExisting } = this.state;

    return (
      <SnackbarContext.Consumer>
        {(snackbarContextValue) => (
          <ActionDetailContext.Consumer>
            {(actionDetailContextValue) => (
              <FormContext.Provider
                value={{
                  ...this.state,
                  ...this.contextHelpers,
                  showSnackbar: snackbarContextValue,
                  isDraft: !!existingAction?.isDraft,
                }}
              >
                <div className={classes.wrapperStyle}>
                  <div className={classes.container}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          alignItems="center"
                          className={classes.header}
                          container
                          spacing={2}
                        >
                          <Grid item xs={2}>
                            <CfBackButton
                              translId="action.backToActivities"
                              onClick={() => {
                                ngGoToActions();
                              }}
                            />
                          </Grid>
                          <Grid
                            className={classes.headingContainer}
                            item
                            xs={8}
                          >
                            {!!existingAction?.isDraft && (
                              <Tooltip
                                title={
                                  <FormattedMessage id="ActionDetail.isDraft" />
                                }
                              >
                                <WarningIcon color="secondary" />
                              </Tooltip>
                            )}
                            <PageHeading
                              dataTest="action-name"
                              value={
                                <FormattedMessage
                                  id={FORM_TITLE_LOCALIZATION[formType]}
                                />
                              }
                            />
                          </Grid>
                          <Grid className={classes.editButtonItem} item xs={2}>
                            {isExisting && (
                              <Fragment>
                                <Tooltip
                                  title={
                                    <FormattedMessage id="ActionSplit.button.tooltip.label" />
                                  }
                                >
                                  <span>
                                    <IconButton
                                      data-test="action-other-split"
                                      size="large"
                                      disabled={
                                        isEditing ||
                                        actionDetailContextValue?.isSplitting
                                      }
                                      onClick={
                                        actionDetailContextValue?.handleStartIsSplitting
                                      }
                                    >
                                      <ContentCopyIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <FormattedMessage id="ActionEdit.button.tooltip.label" />
                                  }
                                >
                                  <span>
                                    <IconButton
                                      data-test="action-other-edit"
                                      onClick={this.onEditingStart}
                                      size="large"
                                      disabled={
                                        isEditing ||
                                        actionDetailContextValue?.isSplitting
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Fragment>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item lg={8} md={10} xs={12}>
                            <Box className={classes.warningMessageContainer}>
                              <CoreActionWarningMessage
                                action={existingAction}
                              />
                            </Box>
                            <ActionOtherFormWrapper
                              action={existingAction}
                              countryCode={this.props.farm.customer.countryCode}
                              goToActions={ngGoToActions}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </FormContext.Provider>
            )}
          </ActionDetailContext.Consumer>
        )}
      </SnackbarContext.Consumer>
    );
  }
}

ActionOtherDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  ngGoToActions: PropTypes.func,
  existingAction: PropTypes.object,
  isEditing: PropTypes.bool,
  isExisting: PropTypes.bool,
  fetchProducts: PropTypes.func,
  getSeedsApi: PropTypes.func.isRequired,
  fetchUnits: PropTypes.func,
  formType: PropTypes.string,
  match: PropTypes.object.isRequired,
  initParcelIds: PropTypes.array,
  fetchInitialParcelsToAdd: PropTypes.func.isRequired,
  clearInitialParcelsToAdd: PropTypes.func.isRequired,
  initialParcelsToAdd: PropTypes.array.isRequired,
};

ActionOtherDetail.defaultProps = {
  isEditing: false,
  isExisting: false,
  existingAction: null,
  match: {},
  ngGoToActions: () => {},
  fetchAction: () => {},
  fetchProducts: () => {},
  fetchUnits: () => {},
  formType: "",
  initParcelIds: [],
};

const mapStateToProps = (state) => ({
  initialParcelsToAdd: getParcelsToAdd("other", state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProducts,
      getSeedsApi,
      fetchUnits,
      fetchInitialParcelsToAdd: addInitParcelsToAdd,
      clearInitialParcelsToAdd: clearParcelsToAdd,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(ActionOtherDetail);
