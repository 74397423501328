import React, { FC, ReactNode, useEffect } from "react";

import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import { resetPrecision } from "../actions/precision.actions";

import { Thunk } from "../../../types";

import PrecisionTabs, { PRECISION_LISTING_TABS } from "./PrecisionTabs";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props extends RouteComponentProps<{ farmId: string }> {
  children: ReactNode;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  resetPrecision: () => void;
  tab: PRECISION_LISTING_TABS;
}

const PrecisionFarming: FC<Props> = ({
  children,
  history,
  location,
  match,
  ngRedirectToVrf,
  ngRedirectToVrs,
  resetPrecision,
  tab,
}) => {
  useEffect(
    () => () => {
      resetPrecision();
    },
    [resetPrecision],
  );

  return (
    <PrecisionTabs
      history={history}
      location={location}
      match={match}
      ngRedirectToVrf={ngRedirectToVrf}
      ngRedirectToVrs={ngRedirectToVrs}
      tab={tab}
    >
      {children}
    </PrecisionTabs>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      resetPrecision,
    },
    dispatch,
  );

export default connect(undefined, mapDispatchToProps)(PrecisionFarming);
