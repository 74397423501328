import React from "react";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const style = {
  actionButton: {
    zIndex: 1,
    width: 28,
    height: 28,
    margin: 0,
    minHeight: 0,
    boxShadow: "none",
  },
  linkIcon: {
    transform: "rotate(90deg)",
    width: 18,
    height: 18,
  },
  actionButtonOff: {
    zIndex: 1,
    width: 28,
    height: 28,
    margin: 0,
    minHeight: 0,
    boxShadow: "none",
  },
  tooltip: {
    fontSize: 12,
    marginBottom: 4,
  },
  buttonPosition: {
    position: "absolute",
    top: "79px",
    left: "33px",
  },
};

function LockDoseButton(props) {
  const { classes, recalculateDose, turnOff, turnOn } = props;
  return (
    <div className={classes.buttonPosition}>
      {recalculateDose ? (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={<FormattedMessage id="LockDoseButton.turnOff" />}
        >
          <div>
            <Fab
              aria-label="lockDose"
              className={classes.actionButton}
              color="primary"
              disabled={props.recalcDisabled}
              onClick={turnOff}
            >
              <LinkIcon className={classes.linkIcon} />
            </Fab>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={<FormattedMessage id="LockDoseButton.turnOn" />}
        >
          <div>
            <Fab
              aria-label="lockDose"
              className={classes.actionButtonOff}
              color="default"
              disabled={props.recalcDisabled}
              onClick={turnOn}
            >
              <LinkOffIcon className={classes.linkIcon} />
            </Fab>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

LockDoseButton.propTypes = {
  classes: PropTypes.object,
  turnOn: PropTypes.func.isRequired,
  turnOff: PropTypes.func.isRequired,
  recalculateDose: PropTypes.bool,
  recalcDisabled: PropTypes.bool.isRequired,
};

LockDoseButton.defaultProps = {
  recalculateDose: false,
  classes: {},
};

export default withStyles(style)(LockDoseButton);
