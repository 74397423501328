/**
 * Check if this file still inuse
 */

/**
 * Created by brasko on 8.9.2015.
 */

import angular from "angular";

import Api from "./Api.service";
import ApiConfig from "./ApiConfig.service";
import ResourceUtils from "./ResourceUtils.service";

import RequestInterceptor from "./interceptors/RequestInterceptor.service";
import ResponseErrorInterceptor from "./interceptors/ResponseErrorInterceptor.service";
import ResponseSuccessInterceptor from "./interceptors/ResponseSuccessInterceptor.service";

import MaterialToMapper from "./mappers/MaterialToMapper.service";

import BatchesResource from "./resources/BatchesResource.service";
import ChemistriesResource from "./resources/ChemistriesResource.service";
import CropsResource from "./resources/CropsResource.service";
import FarmsResource from "./resources/FarmsResource.service";
import FertilizersResource from "./resources/FertilizersResource.service";
import OrganismsResource from "./resources/OrganismsResource.service";
import SeedsResource from "./resources/SeedsResource.service";
import UnitsResource from "./resources/UnitsResource.service";
import UsersResource from "./resources/UsersResource.service";
import VarietiesResource from "./resources/VarietiesResource.service";

export default angular
  .module("app.api", [])
  .config(config)
  .factory("Api", Api)
  .factory("ApiConfig", ApiConfig)
  .factory("ResourceUtils", ResourceUtils)
  .factory("RequestInterceptor", RequestInterceptor)
  .factory("ResponseErrorInterceptor", ResponseErrorInterceptor)
  .factory("ResponseSuccessInterceptor", ResponseSuccessInterceptor)
  .factory("MaterialToMapper", MaterialToMapper)
  .factory("BatchesResource", BatchesResource)
  .factory("ChemistriesResource", ChemistriesResource)
  .factory("CropsResource", CropsResource)
  .factory("FarmsResource", FarmsResource)
  .factory("FertilizersResource", FertilizersResource)
  .factory("OrganismsResource", OrganismsResource)
  .factory("SeedsResource", SeedsResource)
  .factory("UnitsResource", UnitsResource)
  .factory("UsersResource", UsersResource)
  .factory("VarietiesResource", VarietiesResource).name;

/* @ngInject */
function config(RestangularProvider, $httpProvider) {
  $httpProvider.defaults.withCredentials = true;
  RestangularProvider.setDefaultHttpFields({
    withCredentials: true,
  });
}
