import React, { Fragment } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { FarmerPortalHeading } from "../shared";

const CSV = "csv";

const styles = (theme) => ({
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  progress: {
    marginLeft: 10,
  },
});

function SowingPlanCsvExport(props) {
  const {
    classes,
    intl: { formatMessage },
    isFetching,
    onCsvExport,
  } = props;
  const exportLabel = formatMessage({ id: "common.export" });
  return (
    <Fragment>
      <FarmerPortalHeading
        classes={classes}
        titleTranslateString="Report.sowingPlan"
      />
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.sowingPlanInfo" />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button
          color="primary"
          disabled={isFetching}
          id="csv"
          onClick={onCsvExport}
          variant="contained"
        >
          {`${exportLabel} ${CSV}`}
          {isFetching && (
            <CircularProgress className={classes.progress} size={21} />
          )}
        </Button>
      </div>
    </Fragment>
  );
}

SowingPlanCsvExport.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onCsvExport: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

SowingPlanCsvExport.defaultProps = {
  classes: {},
};

export default injectIntl(withStyles(styles)(SowingPlanCsvExport));
