import React, { useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getVarioDoc } from "../../../shared/api/sentinel/varioDoc/varioDoc.selectors";

import { getAgIntegrationStatusVarioDocApi } from "../../../shared/api/sentinel/varioDoc/varioDoc.api";
import { useToggle } from "../../../shared/hooks/useToggle";
import { Thunk } from "../../../types";

import AgActivationDialog from "./varioDocModals/AgActivationDialog";
import AgDeactivationDialog from "./varioDocModals/AgDeactivationDialog";
import VarioDocTableRow from "./VarioDocTableRow";

import { UserState } from "../../../reducers/user.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;

const VarioDocRow = ({
  getAgIntegrationStatusVarioDocApi,
  varioDoc,
}: ReduxProps) => {
  const {
    on: deactivationDialogOpen,
    setOff: closeDeactivationDialog,
    setOn: openDeactivationDialog,
  } = useToggle();
  const {
    on: activationDialogOpen,
    setOff: closeActivationDialog,
    setOn: openActivationDialog,
  } = useToggle();

  useEffect(() => {
    getAgIntegrationStatusVarioDocApi();
  }, [getAgIntegrationStatusVarioDocApi]);

  return (
    <>
      <VarioDocTableRow
        buttonOnClick={openActivationDialog}
        data={varioDoc}
        editOnClick={openActivationDialog}
        switchOnClick={openDeactivationDialog}
      />
      {activationDialogOpen && (
        <AgActivationDialog
          data={varioDoc}
          onClose={closeActivationDialog}
          opened={activationDialogOpen}
        />
      )}
      {deactivationDialogOpen && (
        <AgDeactivationDialog
          onClose={closeDeactivationDialog}
          opened={deactivationDialogOpen}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: UserState) => ({
  varioDoc: getVarioDoc(state),
});

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      getAgIntegrationStatusVarioDocApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(VarioDocRow);
