import { AnyAction } from "redux";

import * as types from "./varioDoc.constants";

import { AgVarioDoc } from "./varioDoc.types";

const initialState = {
  varioDoc: undefined,
};

export default () =>
  (state: AgVarioDoc = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_AGINTEGRATION_VARIODOC_SUCCESS:
        return {
          varioDoc: action.payload,
        };
      case types.DELETE_AGINTEGRATION_VARIODOC_SUCCESS:
        return {
          varioDoc: undefined,
        };
      default:
        return state;
    }
  };
