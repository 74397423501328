import React, { FC, ReactNode, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCrops,
  getIsFetchingCrops,
} from "../../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import { fetchCrops } from "../../../actions/parcelList.actions";

import { resetCropsApi } from "../../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../../types";

import { ParcelsState } from "../../../../../../reducers/parcels.reducer.types";
import { CropTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

export interface CropsSelectorProps {
  defaultCrops?: CropTo[];
  fetchCrops: (search?: string) => void;
  isCropsFetching: boolean;
  label: ReactNode;
  onChange: (items: CropTo[]) => void;
  resetCropsApi: () => void;
  suggestions: CropTo[];
}

const CropsSelector: FC<CropsSelectorProps> = ({
  defaultCrops = [],
  fetchCrops,
  isCropsFetching,
  label,
  onChange,
  resetCropsApi,
  suggestions,
}) => {
  useEffect(() => {
    fetchCrops();

    return () => {
      resetCropsApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultCrops}
      id="crop-selector"
      isFetching={isCropsFetching}
      isMultiple={true}
      label={label}
      loadOptions={fetchCrops}
      onChange={onChange}
      suggestions={suggestions}
      testId="crop-filter"
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getCrops(state),
  isCropsFetching: getIsFetchingCrops(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchCrops,
      resetCropsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CropsSelector);
