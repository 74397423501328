import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import withPopover from "../../../hocs/withPopover";

const styles = {
  button: {
    color: "white",
  },
};

function FabWithOptions(props) {
  const {
    anchorEl,
    classes,
    disabled,
    handlePopoverClose,
    handlePopoverOpen,
    isOpen,
    options,
  } = props;
  return (
    <div>
      <Fab
        aria-label="parcel detail fab button"
        className={classes.button}
        color="secondary"
        disabled={disabled}
        onClick={handlePopoverOpen}
        size="medium"
      >
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem
            disabled={option.disabled}
            divider={Boolean(option.divider)}
            key={option.translId}
            onClick={() => {
              handlePopoverClose();
              option.action();
            }}
          >
            <FormattedMessage id={option.translId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

FabWithOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

FabWithOptions.defaultProps = {
  disabled: false,
  anchorEl: null,
};

export default compose(withStyles(styles), withPopover)(FabWithOptions);
