import React, { FC } from "react";

import { Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormikDatePicker from "../../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";

interface Props {
  isEditing: boolean;
  label: string;
  name: string;
}

const DatePickerField: FC<Props> = ({ isEditing, label, name }) => (
  <Field
    component={CfFormikDatePicker}
    disabled={!isEditing}
    fullWidth
    label={<FormattedMessage id={label} />}
    name={name}
  />
);

export default DatePickerField;
