import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const HumidityIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M3,17.5a3.5,3.5,0,0,0,7,0C10,15,8,14,6.5,11.66663,5,14,3,15,3,17.5Zm5,0a1.5,1.5,0,0,1-3,0c0-.86743.43359-1.39441,1.41443-2.5036L6.5,14.89954l.08563.09686C7.56641,16.10559,8,16.63257,8,17.5Z" />
    <path d="M16.5,11.66663C15,14,13,15,13,17.5a3.5,3.5,0,0,0,7,0C20,15,18,14,16.5,11.66663ZM16.5,19A1.50172,1.50172,0,0,1,15,17.5c0-.86743.43359-1.39441,1.41443-2.5036L16.5,14.89954l.08563.09686C17.56641,16.10559,18,16.63257,18,17.5A1.50172,1.50172,0,0,1,16.5,19Z" />
    <path d="M15,8.83331c0-2.5-2-3.5-3.5-5.83331C10,5.33331,8,6.33331,8,8.83331a3.5,3.5,0,1,0,7,0Zm-3.5,1.5a1.50172,1.50172,0,0,1-1.5-1.5c0-.86743.43359-1.39435,1.41437-2.5036L11.5,6.23291l.08563.0968C12.56641,7.439,13,7.96588,13,8.83331A1.50172,1.50172,0,0,1,11.5,10.33331Z" />
  </SvgIcon>
);

export default HumidityIcon;
