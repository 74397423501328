import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getErrorRules,
  getIsFetchingRules,
} from "../../../shared/api/automation/rules/rules.selectors";

import { fetchUnfilteredRules } from "../../actions/notificationList.actions";

import * as services from "../../../shared/constants/services.constants";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import ServiceNotPurchased from "../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import { links } from "../../../shared/constants/links";
import { NotificationsIcon } from "../../../shared/icons/navbar/NotificationsIcon";
import NotificationDetail from "../NotificationDetail/NotificationDetail";
import NotificationList from "../NotificationList/NotificationList";

const styles = (theme) => ({
  wrapperStyle: {
    height: "100%",
  },
  noNotifications: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
});

export class Notifications extends Component {
  constructor(props) {
    super(props);
    props.fetchUnfilteredRules();
    this.state = {
      activated: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { error, isFetching } = this.props;
    if (
      prevProps.isFetching &&
      !isFetching &&
      !(error.isError && error.code === 403)
    ) {
      this.setState({
        activated: true,
      });
    }
  }

  render() {
    const { classes, farmId, isFetching, langId } = this.props;
    const { activated } = this.state;

    return (
      <div className={classes.wrapperStyle}>
        {isFetching && !activated ? (
          <CfLoader />
        ) : (
          <Fragment>
            {activated ? (
              <Switch>
                <Route
                  exact
                  path="/farm/:farmId/notifications"
                  render={(routerProps) => (
                    <NotificationList
                      farmId={farmId}
                      langId={langId}
                      {...routerProps}
                    />
                  )}
                />
                <Route
                  exact
                  path="/farm/:farmId/notifications/new"
                  render={(routerProps) => (
                    <NotificationDetail farmId={farmId} {...routerProps} />
                  )}
                />
                <Route
                  path="/farm/:farmId/notifications/:notificationId"
                  render={(routerProps) => (
                    <NotificationDetail
                      farmId={farmId}
                      {...routerProps}
                      isExisting={true}
                    />
                  )}
                />
              </Switch>
            ) : (
              <div className={classes.noNotifications}>
                <PageHeader
                  classes={{ header: classes.header }}
                  heading={<PageHeading translationId="common.notifications" />}
                />
                <CfStatusPanel
                  icon={NotificationsIcon}
                  linkText={<FormattedMessage id="common.findOutMore" />}
                  testId="no-notifications"
                  titleWithIcon={true}
                  customContent={
                    <ServiceNotPurchased serviceId={services.NOTIFICATIONS} />
                  }
                  linkHref={
                    langId === "cs-CZ" ? links.cs.homepage : links.en.homepage
                  }
                  title={
                    <FormattedMessage id="Notifications.notPurchased.title" />
                  }
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  fetchUnfilteredRules: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingRules(state),
  error: getErrorRules(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUnfilteredRules,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Notifications));
