import { useEffect } from "react";

import { useCookies } from "react-cookie";

import { NO_VARIODOC_BANNER_COOKIE } from "../../../precision/containers/NoVarioDoc";

type Props = {
  hasLogged: boolean;
};

const HasLogged = ({ hasLogged }: Props) => {
  const [, , removeCookie] = useCookies([NO_VARIODOC_BANNER_COOKIE]);

  useEffect(() => {
    if (hasLogged) {
      removeCookie(NO_VARIODOC_BANNER_COOKIE, { path: "/" });
    }
  }, [hasLogged, removeCookie]);

  return null;
};

export default HasLogged;
