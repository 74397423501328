import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";

import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

const useStyles = makeStyles((theme: Theme) => ({
  parcelDetailButton: {
    padding: 0,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  parcelName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 4,
  },
}));

interface PrecisionDetailHeadingProps {
  farmId: string;
  parcel: PrecisionParcel;
}

const PrecisionDetailHeading: FC<PrecisionDetailHeadingProps> = ({
  farmId,
  parcel,
}) => {
  const classes = useStyles();

  const { id, localName } = parcel;

  return (
    <PageHeading
      dataTest="precisionDetail-heading"
      value={
        <div className={classes.heading}>
          <div className={classes.parcelName}>{localName}</div>
          <a
            className={classes.parcelDetailButton}
            href={`/farm/${farmId}/parcels/${id}/overview`}
          >
            <FormattedMessage id="PrecisionDetailHeader.parcelDetail" />
          </a>
        </div>
      }
    />
  );
};

export default PrecisionDetailHeading;
