import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfChartTooltipValue from "../CfChartTooltipValue/CfChartTooltipValue";

const style = () => ({
  tooltipValueWrapper: {
    padding: "10px 10px 10px 10px",
  },
});

const CfChartTooltipNoData = (props) => {
  const { children, classes, item, noDataValue } = props;
  return (
    <div className={`${classes.tooltipValueWrapper}`}>
      {isNaN(parseFloat(item.value)) ? (
        <CfChartTooltipValue>
          {noDataValue || <FormattedMessage id="common.noData" />}
        </CfChartTooltipValue>
      ) : (
        children
      )}
    </div>
  );
};

CfChartTooltipNoData.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  noDataValue: PropTypes.any,
};

CfChartTooltipNoData.defaultProps = {
  noDataValue: undefined,
};

export default withStyles(style)(CfChartTooltipNoData);
