import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import SensorsService from "../../../services/Sensors.service";
import CfAutocomplete from "../../common/CfAutocomplete/CfAutocomplete";

export const SensorFilterByStatus = ({
  defaultStatus = [],
  disabled = false,
  label,
  onChange,
  useLabelTranslation = false,
}) => {
  const intl = useIntl();

  const states = SensorsService.getNodesStates();
  const extendedeSuggestions = useMemo(
    () =>
      states.map((s) => ({
        name: s,
        label: useLabelTranslation
          ? intl.formatMessage({ id: `SensorsStatus.${s}` })
          : s,
      })),
    [intl, states, useLabelTranslation],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultStatus}
      disabled={disabled}
      id="status-filter"
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={extendedeSuggestions}
      testId="status-filter"
    />
  );
};

SensorFilterByStatus.propTypes = {
  defaultStatus: PropTypes.array,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  useLabelTranslation: PropTypes.bool,
};
