import { RSAA } from "redux-api-middleware";

import * as types from "./subtractableAreas.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import {
  RecountSubtractionRequest,
  RestrictionTo,
} from "../agroevidence.types";

/*
 * Calculate area for new subtraction
 */
export const calculateSubtractableAreaApi = (
  parcelId: string,
  p: RestrictionTo,
) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtractions?`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.POST_SUBTRACTABLE_AREA_REQUEST,
      types.POST_SUBTRACTABLE_AREA_SUCCESS,
      types.POST_SUBTRACTABLE_AREA_ERROR,
    ],
  },
});

/*
 * Gets subtractable areas on parcel defined in past
 * or previously used subtractable areas on the parcel
 */
export const getParcelHistorySubtractionsApi = (
  parcelId: string,
  parcelIds: string[],
) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtractions-history?otherParcels=${parcelIds}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_HISTORY_SUBTRACTIONS_REQUEST,
      types.GET_PARCEL_HISTORY_SUBTRACTIONS_SUCCESS,
      types.GET_PARCEL_HISTORY_SUBTRACTIONS_ERROR,
    ],
  },
});

export const resetParcelHistorySubtractionsApi = () => ({
  type: types.RESET_PARCEL_HISTORY_SUBTRACTIONS,
});

/*
 * DELETE subtractable area on parcel
 */
export const deleteSubtractionAreaApi = (
  parcelId: string,
  type: string,
  value: number,
) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/subtractions-history?type=${type}&value=${value}&`,
    method: methods.DELETE,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_PARCEL_SUBTRACTION_REQUEST,
      types.DELETE_PARCEL_SUBTRACTION_SUCCESS,
      types.DELETE_PARCEL_SUBTRACTION_ERROR,
    ],
  },
});

/*
 * Updates all parcels subtractions in context of all parcels added in the action and PPP material in EPH action.
 */
export const updateParcelsSubtractionsApi = (p: RecountSubtractionRequest) => ({
  [RSAA]: {
    endpoint: "parcels/subtractions?",
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(p),
    types: [
      types.UPDATE_PARCELS_SUBTRACTIONS_REQUEST,
      types.UPDATE_PARCELS_SUBTRACTIONS_SUCCESS,
      types.UPDATE_PARCELS_SUBTRACTIONS_ERROR,
    ],
  },
});
