import React, { FC } from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useFertilizerDetailStyles } from "../FertilizerDetail.styles";

import { UnitType } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultValues: UnitType | null;
  error: boolean;
  isEditing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UnitTypeRadioGroup: FC<Props> = ({
  defaultValues,
  error = false,
  isEditing,
  onChange,
}) => {
  const classes = useFertilizerDetailStyles();

  return (
    <FormControl>
      <FormLabel className={classes.radioGroupLabel} disabled={!isEditing}>
        <FormattedMessage id="Catalogues.fertilizers.detail.unitType" />
      </FormLabel>
      <RadioGroup name="unitType" onChange={onChange} value={defaultValues}>
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={!isEditing}
          value={UnitType.WEIGHT}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id={`Catalogues.fertilizers.detail.unitType.${UnitType.WEIGHT}`}
            />
          }
        />
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={!isEditing}
          value={UnitType.VOLUME}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id={`Catalogues.fertilizers.detail.unitType.${UnitType.VOLUME}`}
            />
          }
        />
      </RadioGroup>
      {error && (
        <div className={classes.error}>
          <FormattedMessage id="validation.required" tagName="span" />
        </div>
      )}
    </FormControl>
  );
};

export default UnitTypeRadioGroup;
