import React, { Component } from "react";

import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import CfLabel from "../../../../../../shared/components/common/CfLabel/CfLabel";
import CfSwitcherButton from "../../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../../shared/hocs/withPopover";

const styles = {
  paper: {
    overflow: "hidden",
  },
  switcherButtonLabel: {
    fontSize: 13,
  },
  l: {
    marginLeft: 5,
    height: 18,
  },
};

export class YieldGradeSelector extends Component {
  constructor(props) {
    super(props);
    this.yieldGrades = ["I", "II", "III"];
  }

  handleSelected = (chosenYieldGrade) => {
    this.props.updateParcelYieldGrade(chosenYieldGrade);
    this.props.handlePopoverClose();
  };

  render() {
    const {
      anchorEl,
      classes,
      handlePopoverClose,
      handlePopoverOpen,
      isHistorical,
      isOpen,
      userYieldGrade,
      yieldGrade,
    } = this.props;
    return (
      <div>
        <CfSwitcherButton
          disabled={isHistorical}
          onClick={handlePopoverOpen}
          transparent={true}
        >
          <span className={classes.switcherButtonLabel}>
            {userYieldGrade || yieldGrade || "-"}
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          onClose={handlePopoverClose}
          open={isOpen}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: 60,
            vertical: 0,
          }}
        >
          {this.yieldGrades.map((yieldGradeOption) => (
            <MenuItem
              key={yieldGradeOption}
              onClick={() => this.handleSelected(yieldGradeOption)}
            >
              {yieldGradeOption}
              {yieldGradeOption === yieldGrade && (
                <CfLabel
                  classes={{ label: classes.l }}
                  message={<FormattedMessage id="LayerSwitcher.lpis_block" />}
                />
              )}
            </MenuItem>
          ))}
        </Popover>
      </div>
    );
  }
}

YieldGradeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  yieldGrade: PropTypes.string,
  userYieldGrade: PropTypes.string,
  updateParcelYieldGrade: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  isHistorical: PropTypes.bool,
};

YieldGradeSelector.defaultProps = {
  yieldGrade: null,
  userYieldGrade: null,
  anchorEl: null,
  isHistorical: false,
};

export default compose(withStyles(styles), withPopover)(YieldGradeSelector);
