import React, { Component } from "react";

import Snackbar from "@mui/material/Snackbar";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import {
  getTool,
  getStage,
  getError,
  getHintAction,
  getHintData,
  getHintRefresh,
  getValidFromError,
} from "../../selectors/editor.selectors";

import { HINT_ACTIONS } from "../../actions/editor/editor.actions";

import * as errors from "../../constants/errors.constants";
import * as tools from "../../constants/tools.constants";

import DrawHint from "../../components/DrawHint/DrawHint";
import MeasureHint from "../../components/MeasureHint/MeasureHint";
import MergeHint from "../../components/MergeHint/MergeHint";
import SnackbarText from "../../components/SnackbarText/SnackbarText";
import SplitHint from "../../components/SplitHint/SplitHint";
import BufferHint from "../BufferHint/BufferHint";

const styles = (theme) => ({
  wrapper: {
    textAlign: "center",
  },
  message: {
    width: "100%",
  },
  snackbarRoot: {
    bottom: 0,
  },
  root: {
    borderRadius: "4px 4px 0 0",
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px",
    },
  },
  rootError: {
    borderRadius: "4px 4px 0 0",
    [theme.breakpoints.up("md")]: {
      maxWidth: "850px",
    },
    backgroundColor: theme.palette.error.main,
  },
  snackbar: {
    bottom: 0,
  },
});

export class SnackbarHint extends Component {
  constructor(props) {
    super(props);
    this.nextItem = null;
    this.state = {
      open: false,
      stage: null,
      tool: null,
      data: null,
      error: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { action, data, error, refresh, stage, tool, validFromError } =
      this.props;

    if (prevProps.refresh !== refresh) {
      if (action === HINT_ACTIONS.REFRESH) {
        return this.refresh(stage, tool, data, error || validFromError);
      }

      if (action === HINT_ACTIONS.REOPEN) {
        return this.reopen(stage, tool, data, error || validFromError);
      }

      if (action === HINT_ACTIONS.CLOSE) {
        return this.close();
      }
    }
  }

  close = () => {
    this.setState({ open: false });
  };

  open = () => {
    this.setState({ open: true });
  };

  refresh = (stage, tool, data, error) => {
    this.setState({ stage, tool, data, error });
  };

  reopen = (stage, tool, data, error) => {
    this.nextItem = { stage, tool, data, error };

    if (this.state.open) {
      this.close();
    } else {
      this.retrieveNextItem();
    }
  };

  retrieveNextItem = () => {
    if (this.nextItem) {
      const nextItem = this.nextItem;
      this.setState({
        open: true,
        ...nextItem,
      });
      this.nextItem = null;
    }
  };

  snackbarContent = (stage, tool, data) => {
    switch (stage) {
      case errors.RETIRED_PARCEL:
      case errors.NON_IDENTIC_LPIS_ERROR:
      case errors.SPLIT_IS_SIMPLE_ERROR:
      case errors.SPLIT_IS_DISJOINT_ERROR:
      case errors.SPLIT_PARTIAL_CROSSING_ERROR:
      case errors.MERGE_NON_CONSISTENT_ERROR:
      case errors.DRAW_PARCEL_IS_SIMPLE_ERROR:
      case errors.DRAW_OVERLAP_ERROR:
      case errors.BUFFER_SIZE_INVALID_ERROR:
      case errors.BUFFER_SIZE_TOO_BIG_ERROR:
      case errors.BUFFER_TOO_MANY_PARCELS_ERROR:
        return (
          <SnackbarText color="light">
            <FormattedMessage id={`error.${stage}`} />
          </SnackbarText>
        );
      case errors.DRAW_REDUCTION_IS_SIMPLE_ERROR:
      case errors.DRAW_IS_DISJOINT_ERROR:
      case errors.DRAW_CONTAINED_ERROR:
      case errors.DRAW_IS_MUTLIPLE_ERROR:
        return (
          <SnackbarText color="light">
            <FormattedMessage
              id={`error.${stage}`}
              values={{
                pt2: (
                  <SnackbarText color="dark">
                    <FormattedMessage id="error.DRAW_CUTOUT_ERROR_PT2" />
                  </SnackbarText>
                ),
              }}
            />
          </SnackbarText>
        );
      case errors.DATE_VALID_FROM_ERROR:
        return (
          <SnackbarText color="light">
            <FormattedMessage id="error.DATE_VALID_FROM_ERROR" />
          </SnackbarText>
        );
      default:
        break;
    }

    switch (tool) {
      case tools.SPLIT:
        return <SplitHint data={data} stage={stage} />;
      case tools.MERGE:
        return <MergeHint data={data} stage={stage} />;
      case tools.DRAW:
        return <DrawHint data={data} stage={stage} />;
      case tools.BUFFER:
        return <BufferHint data={data} stage={stage} />;
      case tools.MEASURE:
        return <MeasureHint data={data} stage={stage} />;
      default:
        return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { data, error, open, stage, tool } = this.state;
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className={classes.snackbar}
        data-test="snackbar-hint"
        key={stage}
        open={open}
        classes={{
          root: classes.snackbarRoot,
        }}
        ContentProps={{
          classes: {
            message: classes.message,
            root: error ? classes.rootError : classes.root,
          },
        }}
        message={
          <div className={classes.wrapper}>
            {this.snackbarContent(error || stage, tool, data)}
          </div>
        }
        TransitionProps={{
          onExited: this.retrieveNextItem,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tool: getTool(state),
  stage: getStage(state),
  error: getError(state),
  action: getHintAction(state),
  data: getHintData(state),
  refresh: getHintRefresh(state),
  validFromError: getValidFromError(state),
});

SnackbarHint.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.string,
  refresh: PropTypes.number,
  tool: PropTypes.string,
  stage: PropTypes.string,
  error: PropTypes.string,
  data: PropTypes.object,
  validFromError: PropTypes.string,
};

SnackbarHint.defaultProps = {
  action: null,
  refresh: 0,
  tool: null,
  stage: null,
  error: null,
  data: null,
  validFromError: null,
};

export default connect(mapStateToProps, null)(withStyles(styles)(SnackbarHint));
