/**
 * Created by ondrejzvara on 7.11.15.
 */

/*@ngInject*/
export default function FertilizerController(
  idName,
  FertilizersResource,
  Plain,
  $state,
  Validation,
) {
  var vm = this;

  vm.item = {
    name: idName,
    source: "PRIVATE",
    n: 0,
    p2o5: 0,
    k2o: 0,
    mgo: 0,
    cao: 0,
    s: 0,
    nitrogenCoef: 1,
    koefPrep: 1,
    organic: false,
    producent: null,
  };
  vm.newFertForm = {};

  vm.startEditing = startEditing;
  vm.saveEdits = saveEdits;
  vm.cancelEdit = cancelEdit;

  activate();

  ////////////////////////////////////////////

  function activate() {
    initFert();
  }

  function initFert() {
    if (!idName) {
      //plain new item
      vm.edit = true;
    } else {
      var id = _.toFinite(idName);
      if (id > 0 && _.isFinite(id)) {
        //item ID in the path param
        return getItem(idName);
      } else {
        //item name in the path parameter
        vm.edit = true;
      }
    }
  }

  function getItem(id) {
    return FertilizersResource.getFertilizer(id).then(function (data) {
      vm.item = new Plain(data);
      vm.edit = false;
      return data;
    });
  }

  function startEditing() {
    vm.edit = true;
  }

  function cancelEdit() {
    if (isNew()) {
      $state.go("farm.active.classifiers.private.fertilizers");
    } else {
      vm.edit = false;
    }
  }

  function isNew() {
    return !vm.item.id;
  }

  function saveEdits() {
    if (!Validation.checkForm(vm.newFertForm)) {
      Validation.validate(vm.newFertForm);
      return;
    }

    if (isNew()) {
      return FertilizersResource.createFertilizer(vm.item).then(getItem);
    } else {
      return FertilizersResource.editFertilizer(vm.item).then(function () {
        return getItem(vm.item.id);
      });
    }
  }
}
