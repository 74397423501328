import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  GetImportHistoryApiParams,
  GetImportHistoryStateApiParams,
  getImportHistoryApi,
  getImportHistoryStateApi,
  resetImportHistoryApi,
} from "../../shared/api/telematics/drivesImportHistory/drivesImportHistory.api";

export const fetchImportHistory =
  (params: GetImportHistoryApiParams) =>
  (dispatch: (action: RSAAAction) => void) => {
    dispatch(getImportHistoryApi(params));
  };

export const resetImportHistory =
  () => (dispatch: (action: AnyAction) => void) => {
    dispatch(resetImportHistoryApi());
  };

export const fetchImportStateHistory =
  (params: GetImportHistoryStateApiParams) =>
  (dispatch: (action: RSAAAction) => void) => {
    dispatch(getImportHistoryStateApi(params));
  };
