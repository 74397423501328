import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfTextFilter from "../../../../../shared/containers/CfTextFilter/CfTextFilter";

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    wrapper: {
      margin: 0,
    },
    [breakpoints.up("sm")]: {
      wrapper: {
        margin: theme.spacing(1),
      },
    },
  };
};

export class StoreTextFilter extends Component {
  componentDidUpdate(prevProps) {
    const { materialTypeId, textFilter } = this.props;
    if (textFilter && materialTypeId !== prevProps.materialTypeId) {
      this.props.handleTextFilterReset();
    }
  }

  resolvePlaceholder = () => {
    const { materialTypeId } = this.props;
    switch (materialTypeId) {
      case "FR":
        return "Stores.fert-name";
      case "CH":
        return "Stores.por-name";
      case "SD":
        return "Stores.seed-name";
      default:
        return "Stores.material-name";
    }
  };

  render() {
    const { classes, namespace, textFilter } = this.props;
    return (
      <div className={classes.wrapper}>
        <CfTextFilter
          initialValue={textFilter}
          name="stores-text-filter"
          namespace={namespace}
          translId={this.resolvePlaceholder()}
        />
      </div>
    );
  }
}

StoreTextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  materialTypeId: PropTypes.string,
  textFilter: PropTypes.string,
  namespace: PropTypes.string.isRequired,
  handleTextFilterReset: PropTypes.func.isRequired,
};

StoreTextFilter.defaultProps = {
  materialTypeId: null,
  textFilter: undefined,
};

export default withStyles(styles)(StoreTextFilter);
