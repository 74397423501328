import * as weatherTypes from "../constants/weatherTypes.constants";

export const getWeatherType = (state) => state.ui.weather.weatherType;
export const getWeatherCoordinates = (state) => state.ui.weather.coordinates;

export const getIsWeather = (state) => {
  const type = getWeatherType(state);
  return (
    type === weatherTypes.CURRENTLY ||
    type === weatherTypes.DAY ||
    type === weatherTypes.WEEK
  );
};
export const getIsRadar = (state) => {
  const type = getWeatherType(state);
  return type === weatherTypes.RADAR;
};
