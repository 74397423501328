import React, { FC, Fragment } from "react";

import { Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import chlorophyllImage from "../../../../assets/img/satellite/chlorophyll.png";
import chlorophyllZonesImage from "../../../../assets/img/satellite/chlorophyll_zones.png";
import leafAreaImage from "../../../../assets/img/satellite/LAI.png";
import leafAreaZonesImage from "../../../../assets/img/satellite/LAI_zones.png";
import waterImage from "../../../../assets/img/satellite/water.png";
import waterZonesImage from "../../../../assets/img/satellite/water_zones.png";
import CfLazyImage from "../../../../shared/components/common/CfLazyImage/CfLazyImage";
import SatelliteImagesInfoDialog from "../SatelliteImagesInfoDialog";
import SatelliteInfoDialogItem from "../SatelliteInfoDialogItem";

import BioMonitoringLegend from "./BioMonitoringLegend";
import BioMonitoringZonesLegend from "./BioMonitoringZonesLegend";

const images = {
  CAB: chlorophyllImage,
  CW: waterImage,
  LAI: leafAreaImage,
};
type indexImages = keyof typeof images;

const zonesImages = {
  CAB: chlorophyllZonesImage,
  CW: waterZonesImage,
  LAI: leafAreaZonesImage,
};
type indexZonesImages = keyof typeof zonesImages;

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: "150px",
    height: "auto",
    imageRendering: "pixelated",
    "&": {
      // fallback for FF
      imageRendering: "-moz-crisp-edges",
    },
    marginRight: 25,
  },
  itemLegendWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
  },
  legend: {
    margin: "0 5px",
  },
  legendInfo: {
    color: theme.palette.grey[500],
    marginTop: 10,
  },
  [theme.breakpoints.down("sm")]: {
    image: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  zonesLegend: {
    lineHeight: 1.8,
  },
}));

interface BioMonitoringHistoryInfoDialogProps {
  index: string;
}

export const BioMonitoringHistoryInfoDialog: FC<
  BioMonitoringHistoryInfoDialogProps
> = ({ index }) => {
  const classes = useStyles();

  return (
    <SatelliteImagesInfoDialog>
      <Fragment>
        <SatelliteInfoDialogItem
          label={<FormattedMessage id={`BioMonitoring.${index}`} />}
          testId="index"
        >
          <Fragment>
            <CfLazyImage
              alt="Monitoring exemplar source image"
              classes={{ custom: classes.image }}
              src={images[index as indexImages]}
              testId="info-dialog-source-image"
            />
            <div>
              <BioMonitoringLegend index={index} />
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
        <SatelliteInfoDialogItem
          label={<FormattedMessage id="BioMonitoringInfoDialog.zoning" />}
          testId="zones"
        >
          <Fragment>
            <CfLazyImage
              alt="Monitoring exemplar zoning image"
              classes={{ custom: classes.image }}
              src={zonesImages[index as indexZonesImages]}
              testId="info-dialog-zoning-image"
            />
            <div className={classes.itemLegendWrapper}>
              <div className={classes.zonesLegend}>
                <BioMonitoringZonesLegend index={index} />
              </div>
              <div className={classes.legendInfo}>
                <Typography>
                  <FormattedMessage
                    id={`BioMonitoringInfoDialog.zoningExplanation.${index}`}
                  />
                </Typography>
              </div>
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
      </Fragment>
    </SatelliteImagesInfoDialog>
  );
};
