import React, { FC, ReactNode, useEffect, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcelsSuggestion,
  getParcelsSuggestion,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.selectors";

import {
  getParcelsSuggestionsApi,
  ParcelsApiParams,
  resetParcelsSuggestionsApi,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../../types";
import useAdvancedFilterStyles from "../ParcelAdvancedFilter.styles";

import { ParcelsState } from "../../../../../../reducers/parcels.reducer.types";
import { ParcelSuggestionTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

const handleGetLabel = (option?: ParcelSuggestionTo | null) =>
  option?.localName || "";

const handleGetSelected = (
  option: ParcelSuggestionTo,
  value?: ParcelSuggestionTo | null,
) => option.blockNumber === value?.blockNumber;

const handleFilterOptions = createFilterOptions({
  stringify: ({ blockNumber, localName }) => `${localName} ${blockNumber}`,
});

interface ParcelSelectorProps {
  defaultValues?: ParcelSuggestionTo[];
  getParcelsSuggestionsApi: (params: ParcelsApiParams) => void;
  isFetching: boolean;
  label: ReactNode;
  onChange: (items: ParcelSuggestionTo[]) => void;
  options: ParcelSuggestionTo[];
  resetParcelsSuggestionsApi: () => void;
}

const ParcelSelector: FC<ParcelSelectorProps> = ({
  defaultValues = [],
  getParcelsSuggestionsApi,
  isFetching = false,
  label,
  onChange,
  options,
  resetParcelsSuggestionsApi,
}) => {
  const classes = useAdvancedFilterStyles();

  const handleRenderOption = useCallback(
    (option: ParcelSuggestionTo) => (
      <div>
        <div>{option.localName}</div>
        <div className={classes.subtext}>{option.blockNumber}</div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (
      values: ParcelSuggestionTo[],
      getTagProps: AutocompleteRenderGetTagProps,
    ) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id}
            label={
              <>
                {value.localName}{" "}
                <span className={classes.subtext}>{value.blockNumber}</span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  const fetchParcels = useCallback(
    (localNameBlockNr) => {
      getParcelsSuggestionsApi({
        search: localNameBlockNr,
        page: 1,
        "per-page": 100,
      });
    },
    [getParcelsSuggestionsApi],
  );

  useEffect(() => {
    fetchParcels("");

    return () => {
      resetParcelsSuggestionsApi();
    };
  }, [fetchParcels, resetParcelsSuggestionsApi]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      loadOptions={fetchParcels}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="parcel-filter"
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  options: getParcelsSuggestion(state),
  isFetching: getIsFetchingParcelsSuggestion(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getParcelsSuggestionsApi,
      resetParcelsSuggestionsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelSelector);
