import React, { FC, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { isExportingData } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";
import { getSelectedParcel } from "../../selectors/editor.selectors";
import {
  getSelectedSeasonIdSowingPlan,
  getSelectedSeasonYearsSowingPlan,
} from "../../selectors/map.selectors";

import { exportSowingPlan } from "../../actions/sowingPlan/sowingPlan.actions";

import {
  getParcelSowingPlanApi,
  resetSeasonSowingPlanApi,
} from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import ExportButton from "../../../../shared/components/common/ExportButton/ExportButton";
import useWidth from "../../../../shared/hooks/useWidth";
import { AsyncFn, Thunk } from "../../../../types";
import {
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../parcels/shared/services/Parcels.service";

import SowingPlanResetDialog from "./SowingPlanResetDialog";
import SowingPlanStatisticsItem from "./SowingPlanStatisticsItem";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import { SowingPlanSeasonStatisticsTo } from "../../../../shared/api/agroevidence/agroevidence.types";

interface SowingPlanStatisticsProps {
  exportSowingPlan: () => void;
  getParcelSowingPlanApi: (
    parcelId: string,
    startYear: number,
    size: number,
  ) => void;
  isExporting: boolean;
  resetSeasonSowingPlanApi: (seasonId: string) => void;
  seasonDetailSowingPlan: SowingPlanSeasonStatisticsTo;
  selectedParcelId: { id: string };
  selectedSeasonId: string;
  selectedSeasonYears: string;
}

const SowingPlanStatistics: FC<SowingPlanStatisticsProps> = ({
  exportSowingPlan,
  getParcelSowingPlanApi,
  isExporting,
  resetSeasonSowingPlanApi,
  seasonDetailSowingPlan,
  selectedParcelId,
  selectedSeasonId,
  selectedSeasonYears,
}) => {
  const classes = useStyles();
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [isSowingPlanShown, setIsSowingPlanShown] = useState(true);

  const width = useWidth();
  const isMobile = width === "xs";

  const toggleSowingPlan = () => {
    setIsSowingPlanShown(!isSowingPlanShown);
  };

  const handleResetDialogClose = () => {
    setShowResetDialog(false);
  };

  const handleResetDialogAccept = () => {
    (resetSeasonSowingPlanApi as AsyncFn<string>)(selectedSeasonId).then(() => {
      getParcelSowingPlanApi(
        selectedParcelId.id,
        START_YEAR_FIRST_SOWING_SEASONS,
        SHOWN_NUMBER_SOWING_SEASONS,
      );
    });
    handleResetDialogClose();
  };

  const seasonHasSowingPlan = seasonDetailSowingPlan.statistics.length > 1;

  return (
    <>
      {showResetDialog && (
        <SowingPlanResetDialog
          onAccept={handleResetDialogAccept}
          onClose={handleResetDialogClose}
          opened={showResetDialog}
          selectedSeasonYears={selectedSeasonYears}
        />
      )}
      <Grid container spacing={2}>
        <Paper>
          <div className={classes.titelWrap}>
            <Stack
              className={classes.titel}
              direction="row"
              justifyContent="space-between"
              onClick={isMobile ? toggleSowingPlan : undefined}
              spacing={2}
            >
              <span>
                <FormattedMessage id="SowingPlan.statistics.title" />
              </span>
              {isMobile &&
                (isSowingPlanShown ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                ))}
            </Stack>
            {!isMobile && seasonHasSowingPlan && (
              <ExportButton
                aria-label="export"
                handleClick={exportSowingPlan}
                isLoading={isExporting}
              />
            )}
          </div>

          <Collapse in={isSowingPlanShown || !isMobile}>
            <div className={classes.separator} />
            {!seasonHasSowingPlan && (
              <div className={classes.notPlannedText}>
                <FormattedMessage
                  id="SowingPlan.statistics.notPlanned"
                  values={{
                    br: <br />,
                    b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                  }}
                />
              </div>
            )}
            {seasonHasSowingPlan && (
              <Scrollbars autoHeight autoHeightMax="50vh">
                <List
                  className={classes.list}
                  data-test="sowing-plan-statistics"
                >
                  {seasonDetailSowingPlan.statistics.map((item, id) => (
                    <ListItem key={id}>
                      <SowingPlanStatisticsItem
                        area={item.area}
                        crop={item.crop}
                      />
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            )}
          </Collapse>
        </Paper>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: MainMapState) => ({
  isExporting: isExportingData(state),
  selectedSeasonId: getSelectedSeasonIdSowingPlan(state),
  selectedSeasonYears: getSelectedSeasonYearsSowingPlan(state),
  selectedParcelId: getSelectedParcel(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      resetSeasonSowingPlanApi,
      getParcelSowingPlanApi,
      exportSowingPlan,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SowingPlanStatistics);

const useStyles = makeStyles((theme: Theme) => ({
  titelWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px 16px",
  },
  titel: {
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 10px 12px 0px",
    width: "100%",
  },
  notPlannedText: {
    color: "#333333",
    fontWeight: 400,
    fontSize: 12,
    width: 235,
    margin: "12px 10px",
    textAlign: "center",
  },
  separator: {
    height: 1,
    backgroundColor: theme.palette.grey[100],
  },
  list: {
    maxHeight: "45vh",
    cursor: "default",
  },
  button: {
    margin: "2px 8px",
    color: theme.palette.error.main,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  showSowingPlanButton: {
    backgroundColor: theme.palette.background.paper,
  },
  expandIcon: {
    color: theme.palette.grey[400],
  },
}));
