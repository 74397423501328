import { IrrigationState } from "../../../../reducers/irrigation.reducer.types";

export const getAreas = (state: IrrigationState) => state.api.areas.items;
export const getIsFetchingAreas = (state: IrrigationState) =>
  state.api.areas.isFetching;
export const getAreasTotalCount = (state: IrrigationState) =>
  state.api.areas.totalCount;

export const getIsAreaDeleting = (state: IrrigationState) =>
  state.api.areas.isAreaDeleting;

export const getAreaDetail = (state: IrrigationState) => state.api.areas.item;
export const getAreaDetailPoints = (state: IrrigationState) =>
  state.api.areas.item.devices;
export const getIsFetchingAreaDetail = (state: IrrigationState) =>
  state.api.areas.isFetchingArea;

export const getAreaPrograms = (state: IrrigationState) =>
  state.api.areas.itemPrograms;
export const getIsFetchingAreaPrograms = (state: IrrigationState) =>
  state.api.areas.isFetchingAreaPrograms;

export const getAreaProgram = (state: IrrigationState) =>
  state.api.areas.itemProgram;
export const getIsFetchingAreaProgram = (state: IrrigationState) =>
  state.api.areas.isFetchingAreaProgram;

export const getApiError = (state: IrrigationState) => state.api.areas.apiError;
