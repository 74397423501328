import ErrorService from "../../../services/Error.service";

import { IrrigationAdminState } from "../../../../admin/manager/irrigation/admin.irrigation.types";
import { SignupState } from "../../../../reducers/signup.reducer.types";
import { UserState } from "../../../../reducers/user.reducer.types";

export const getIsFetching = (state: SignupState | UserState) =>
  state.api.farms.isFetching;
export const getFarms = (state: SignupState | IrrigationAdminState) =>
  state.api.farms.items;
export const getFarm = (state: SignupState | UserState) => state.api.farms.item;
export const getError = (state: SignupState | UserState) =>
  ErrorService.getResErrorDto(state.api.farms.error);

export const getCountries = (state: SignupState) => state.api.farms.countries;

export const getIsFetchingUpdateLPISResults = (state: UserState) =>
  state.api.farms.isFetchingUpdateLPISResults;
export const getUpdateLPISResults = (state: UserState) =>
  state.api.farms.updateLPISResults;

export const getFarmAvailableUpdates = (state: UserState) =>
  state.api.farms.availableUpdates;
export const getAvailableUpdatesError = (state: UserState) =>
  ErrorService.getResErrorDto(state.api.farms.availableUpdatesError);
