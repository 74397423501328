import React, { Component, Fragment } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getDevicesTotalCount,
  getIsFetchingDevices,
} from "../../../shared/api/irrigation/devices/devices.selectors";
import {
  getMapColumns,
  isEnlargedVariant,
} from "../../selectors/map.selectors";

import {
  fetchUnfilteredDevices,
  fetchIrrigationPoints,
} from "../../actions/devices.actions";
import { setEnlargedVariant, setMapColumns } from "../../actions/map.actions";

import * as services from "../../../shared/constants/services.constants";
import { IRRIGATION_URLS } from "../../irrigation.constants";

import { NAMESPACE } from "../../reducer/devicesList.reducer";

import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import ServiceNotPurchased from "../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import ShowHideMap from "../../../shared/components/common/ShowHideMap/ShowHideMap";
import { links } from "../../../shared/constants/links";
import withWidth from "../../../shared/hocs/withWidth";
import { IrrigationIcon } from "../../../shared/icons/navbar/IrrigationIcon";
import { MapColumnsService } from "../../../shared/services/MapColumns.service";
import IrrigationAreasMap from "../IrrigationAreasMap/IrrigationAreasMap";
import IrrigationList from "../IrrigationList/IrrigationList";
import IrrigationMap from "../IrrigationMap/IrrigationMap";
import IrrigationAreaRoutes from "../IrrigationRoutes/IrrigationAreaRoutes";
import IrrigationDeviceRoutes from "../IrrigationRoutes/IrrigationDeviceRoutes";

const styles = (theme) => ({
  wrapperStyle: {
    height: "100%",
  },
  style: {
    height: "100%",
  },
  bodyWrapper: {
    position: "relative",
  },
  map: {
    overflow: "hidden",
    height: "100%",
  },
  noIrrigation: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
});

export class Irrigation extends Component {
  constructor(props) {
    super(props);
    this.props.setMapColumns(
      MapColumnsService.getMapColumnsByWidth(
        props.width,
        props.isMapEnlargedVariant,
      ),
    );
    this.props.fetchUnfilteredDevices();
    this.props.fetchIrrigationPoints(NAMESPACE);
    this.state = {
      activated: false,
    };
    props.setEnlargedVariant(false);
  }

  componentDidUpdate(prevProps) {
    const { devicesCount, isMapEnlargedVariant, width } = this.props;

    if (prevProps.devicesCount === 0 && devicesCount > 0) {
      this.setState({
        activated: true,
      });
    }
    if (prevProps.isMapEnlargedVariant !== this.props.isMapEnlargedVariant) {
      this.props.setMapColumns(
        MapColumnsService.getMapColumnsByWidth(width, isMapEnlargedVariant),
      );
    }

    if (prevProps.width !== this.props.width) {
      const mapColumnsNew = MapColumnsService.getMapColumnsByWidth(
        width,
        isMapEnlargedVariant,
      );
      if (this.props.mapColumns !== mapColumnsNew) {
        this.props.setMapColumns(mapColumnsNew);
      }
    }
  }

  toggleMap = () => {
    const { isMapEnlargedVariant, mapColumns, width } = this.props;
    const columns = MapColumnsService.getMapColumns(
      width,
      isMapEnlargedVariant,
      mapColumns,
    );
    this.props.setMapColumns(columns);
  };

  renderMap = () => {
    const { classes, farmId, history, langId, match } = this.props;
    return (
      <div className={classes.map}>
        <Switch>
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.DEVICES_ROOT_WILDCARD}`}
            render={(routerProps) => (
              <IrrigationMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
                match={match}
              />
            )}
          />
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.AREAS}`}
            render={(routerProps) => (
              <IrrigationMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
                match={match}
              />
            )}
          />
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.CREATE_AREA}`}
            render={(routerProps) => (
              <IrrigationAreasMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
              />
            )}
          />
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.ADD_TO_AREA}`}
            render={(routerProps) => (
              <IrrigationAreasMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
                withAssignedDevices
              />
            )}
          />
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.AREA}/:areaId/plan`}
            render={(routerProps) => (
              <IrrigationMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
                match={match}
              />
            )}
          />
          <Route
            path={`/farm/:farmId/${IRRIGATION_URLS.AREA}/:areaId/devices`}
            render={(routerProps) => (
              <IrrigationMap
                {...routerProps}
                className={classes.style}
                farmId={farmId}
                history={history}
                langId={langId}
                match={match}
              />
            )}
          />
        </Switch>
      </div>
    );
  };

  render() {
    const {
      classes,
      farmId,
      history,
      isFetchingDevices,
      isMapEnlargedVariant,
      langId,
      mapColumns,
    } = this.props;
    const { activated } = this.state;

    return (
      <CfErrorPage>
        <div className={classes.wrapperStyle}>
          {isFetchingDevices && !activated ? (
            <CfLoader />
          ) : (
            <Fragment>
              {activated ? (
                <Grid className={classes.style} container>
                  {mapColumns !== 12 && (
                    <Grid
                      className={classes.bodyWrapper}
                      item
                      xs={12 - mapColumns}
                    >
                      <Scrollbars autoHide>
                        <Switch>
                          <Route
                            exact
                            path={`/farm/:farmId/${IRRIGATION_URLS.AREAS}`}
                            render={(routerProps) => (
                              <IrrigationList
                                {...routerProps}
                                areas
                                farmId={farmId}
                                history={history}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={`/farm/:farmId/${IRRIGATION_URLS.DEVICES}`}
                            render={(routerProps) => (
                              <IrrigationList
                                {...routerProps}
                                devices
                                farmId={farmId}
                                history={history}
                              />
                            )}
                          />
                          <Route
                            path={`/farm/:farmId/${IRRIGATION_URLS.DEVICE}/:deviceId`}
                            render={(routerProps) => (
                              <IrrigationDeviceRoutes
                                farmId={farmId}
                                isMapDisplayed={Boolean(mapColumns)}
                                {...routerProps}
                              />
                            )}
                          />
                          <Route
                            path={`/farm/:farmId/${IRRIGATION_URLS.AREA}`}
                            render={(routerProps) => (
                              <IrrigationAreaRoutes
                                {...routerProps}
                                langId={langId}
                              />
                            )}
                          />
                        </Switch>
                      </Scrollbars>
                      {(mapColumns === 0 ||
                        (!isMapEnlargedVariant && mapColumns < 12)) && (
                        <ShowHideMap
                          handleClick={() => this.toggleMap()}
                          isMapHidden={!mapColumns}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    sm={mapColumns || "auto"}
                    xs={12}
                    style={{
                      display: mapColumns !== 0 ? "block" : "none",
                      height: "100%",
                    }}
                  >
                    {mapColumns === 12 && (
                      <ShowHideMap
                        handleClick={() => this.toggleMap()}
                        isMapHidden={false}
                      />
                    )}
                    {this.renderMap()}
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.noIrrigation}>
                  <PageHeader
                    classes={{ header: classes.header }}
                    heading={<PageHeading translationId="common.irrigation" />}
                  />
                  <CfStatusPanel
                    icon={IrrigationIcon}
                    linkText={<FormattedMessage id="common.findOutMore" />}
                    testId="no-irrigation"
                    titleWithIcon={true}
                    customContent={
                      <ServiceNotPurchased serviceId={services.IRRIGATION} />
                    }
                    linkHref={
                      langId === "cs-CZ"
                        ? links.cs.irrigation
                        : links.en.irrigation
                    }
                    title={
                      <FormattedMessage id="Irrigation.notPurchased.title" />
                    }
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </CfErrorPage>
    );
  }
}

Irrigation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  mapColumns: PropTypes.number.isRequired,
  setMapColumns: PropTypes.func.isRequired,
  fetchIrrigationPoints: PropTypes.func.isRequired,
  fetchUnfilteredDevices: PropTypes.func.isRequired,
  devicesCount: PropTypes.number.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  isFetchingDevices: PropTypes.bool.isRequired,
  isMapEnlargedVariant: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  mapColumns: getMapColumns(state),
  devicesCount: getDevicesTotalCount(state),
  isFetchingDevices: getIsFetchingDevices(state),
  isMapEnlargedVariant: isEnlargedVariant(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMapColumns,
      setEnlargedVariant,
      fetchUnfilteredDevices,
      fetchIrrigationPoints,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withStyles(styles), withWidth())(Irrigation));
