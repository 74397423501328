import React, { FC } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface Props {
  firstLine: string;
  secondLine?: string;
}

const TCSelectorItem: FC<Props> = ({ firstLine, secondLine }) => {
  const classes = useStyles();
  return (
    <div>
      <div>{firstLine}</div>
      {secondLine && <div className={classes.id}>{secondLine}</div>}
    </div>
  );
};

export default TCSelectorItem;
