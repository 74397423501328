import React, { FC, useState } from "react";

import PDF from "@mikecousins/react-pdf";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useIntl } from "react-intl";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";
import "../../../../assets/files/license-cs-CZ.pdf";
import "../../../../assets/files/license-en-US.pdf";

export interface LicencePdfProps {
  handleClose(): void;
  open: boolean;
}

const LicensePdf: FC<LicencePdfProps> = ({ handleClose, open }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);

  const licenseLang =
    intl.locale === LANG_KEYS.CZ ? LANG_KEYS.CZ : LANG_KEYS.EN;
  const fileUrl = `assets/files/license-${licenseLang}.pdf`;

  const handleDocumentLoadSuccess = () => {
    setIsLoading(false);
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogContent>
        {isLoading && <CircularProgress />}
        <PDF
          file={fileUrl}
          onDocumentLoadSuccess={handleDocumentLoadSuccess}
          scale={1.3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LicensePdf;
