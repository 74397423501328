/**
 * Created by brasko on 4.9.2015.
 */

/* @ngInject */
export default function Tabs(Logger) {
  const objects = {
    classifiers: [
      {
        state: "farm.active.classifiers.private.seeds",
        heading: "components.farm.classifiers.seed.seeds",
        view: "seeds",
        index: 0,
      },
      {
        state: "farm.active.classifiers.private.pors",
        heading: "components.farm.classifiers.pors.por",
        view: "pors",
        index: 1,
      },
      {
        state: "farm.active.catalogues.seedsReact",
        heading: "components.farm.classifiers.seed.seeds",
        view: "farm",
        index: 2,
        reactCataloguesToggle: true,
      },
      {
        state: "farm.active.catalogues.fertilizersReact",
        heading: "components.farm.classifiers.fertilizers.fert",
        view: "farm",
        index: 3,
      },
      {
        state: "farm.active.catalogues.plantProtectionReact",
        heading: "components.farm.classifiers.pors.por",
        view: "farm",
        index: 4,
        reactCataloguesToggle: true,
      },
      {
        state: "farm.active.catalogues.drivers",
        heading: "components.farm.classifiers.telematics.drivers",
        view: "farm",
        index: 5,
        underToggle: true,
      },
      {
        state: "farm.active.catalogues.machines",
        heading: "components.farm.classifiers.telematics.machines",
        view: "farm",
        index: 6,
        underToggle: true,
      },
      {
        state: "farm.active.catalogues.equipment",
        heading: "components.farm.classifiers.telematics.equipment",
        view: "farm",
        index: 7,
        underToggle: true,
      },
    ],
    classifiersPublic: [
      {
        state: "farm.active.classifiers.public.pors",
        heading: "components.farm.classifiers.pors.por",
        view: "porsPublic",
        index: 0,
      },
    ],
    actions: [
      {
        state: "farm.active.actions.planned",
        heading: "components.farm.actions.planned.planned",
        view: "planned",
        index: 0,
      },
      {
        state: "farm.active.actions.past",
        heading: "components.farm.actions.confirmed.confirmed",
        view: "past",
        index: 1,
      },
    ],
    parcel_activities: [
      {
        state: "farm.active.parcel.activities.crops",
        heading: "shared.crops",
        view: "crops",
        index: 0,
      },
      {
        state: "farm.active.parcel.activities.eph",
        heading: "shared.eph",
        view: "eph",
        index: 1,
      },
      {
        state: "farm.active.parcel.activities.other",
        heading: "shared.other-actions",
        view: "other",
        index: 2,
      },
    ],
  };

  const service = {
    getTabsTpl,
  };
  return service;

  // ///////////////////////

  function getTabsTpl(attr, showToggled = false, showCatalogues = false) {
    if (!attr || typeof attr !== "string" || !objects.hasOwnProperty(attr)) {
      Logger.error(`Invalid attribute type or format: ${attr}`);
      return {};
    }
    const tabs = objects[attr].filter((tab) => {
      if (tab?.underToggle) {
        return showToggled;
      } else if (tab?.reactCataloguesToggle) {
        return showCatalogues;
      } else {
        return true;
      }
    });
    return tabs;
  }
}
