/**
 * Created by ondrejzvara on 9.4.16.
 */

import angular from "angular";
import Logger from "./Logger.service";
import LogStack from "./LogStack.service";
import LogLevelManager from "./LogLevelManager.service";
import LoggerFncProvider from "./LoggerFncProvider.service";

export default angular
  .module("app.logger", [])
  .constant("LOG_LEVELS", {
    OFF: { name: "off", value: 7 },
    ERROR: { name: "error", value: 6 },
    WARN: { name: "warn", value: 5 },
    PRINTA: { name: "printa", value: 4 },
    INFO: { name: "info", value: 3 },
    LOG: { name: "log", value: 2 },
    DEBUG: { name: "debug", value: 1 },
  })
  .run(run)
  .factory("Logger", Logger)
  .factory("LogStack", LogStack)
  .factory("LogLevelManager", LogLevelManager)
  .factory("LoggerFncProvider", LoggerFncProvider).name;

/*@ngInject*/
function run(Logger, ENVIRONMENT) {
  var logLevelName = ENVIRONMENT === "prod" ? "off" : "debug";
  Logger.setLogLevel(logLevelName);
}
