import React, { Component } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getVariableActionIds as getVariableActionIdsSelector } from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";
import {
  getSelectedOnPage,
  getTextFilter,
  getDateFilter,
  getDraftsOnly,
  getActionsAdvancedFilter,
} from "../../selectors/actionList.selectors";

import {
  deleteActions,
  setDateFilter,
  toggleDraftsView,
} from "../../actions/actionList.actions";

import { NAMESPACE as namespace } from "../../reducer/actionList.reducer";

import {
  getVariableParcelIds,
  getVariableActionIds,
  resetVariableActionIds,
} from "../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import CfDateFilter from "../../../../../shared/components/common/CfDateFilter/CfDateFilter";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import FabWithOptions from "../../../../../shared/components/common/FabWithOptions/FabWithOptions";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import TableActionButtons from "../../../../../shared/components/tables/TableActionButtons/TableActionButtons";
import CfTextFilter from "../../../../../shared/containers/CfTextFilter/CfTextFilter";
import { ActionsAdvancedFilter } from "../ActionsAdvancedFilter/ActionsAdvancedFilter";
import ActionsTable from "../ActionsTable/ActionsTable";

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  dateFilter: {
    width: 180,
    minWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 16px 8px 8px",
    },
  },
  actionButtons: {
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 14,
    },
  },
  textFilter: {
    paddingTop: "16px",
    paddingBottom: 8,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 450,
    },
  },
  filtersHeader: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  checkboxes: {
    marginLeft: "auto",
    marginRight: 0,
    display: "flex",
    alignItems: "center",
    fontSize: 14,

    "& > label": {
      margin: 0,
    },
  },
  advancedFilter: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
  },
});

class ActionsList extends Component {
  constructor(props) {
    super(props);

    const {
      ngGoToEph,
      ngGoToHarvest,
      ngGoToMowing,
      ngGoToOtherNew,
      ngGoToSowing,
      ngGoToVrf,
      ngGoToVrs,
    } = props;

    this.fabOptions = [
      {
        id: "add-other-sowing-link",
        translId: "common.addOtherSowingActivity",
        action: () => ngGoToSowing(),
      },
      {
        id: "add-eph-link",
        translId: "common.addEph",
        action: () => ngGoToEph(),
      },
      {
        id: "add-other-link",
        translId: "common.addOtherActivity",
        action: () => ngGoToOtherNew(),
      },
      {
        id: "add-other-mowing-link",
        translId: "common.addOtherMowingActivity",
        action: () => ngGoToMowing(),
      },
      {
        id: "add-harvest-link",
        translId: "common.addHarvestActivity",
        action: () => ngGoToHarvest(),
        divider: true,
      },
    ];

    this.fabOptsVrf = [
      {
        id: "add-var-vert-link",
        translId: "common.addVrfActivity",
        action: () => ngGoToVrf(),
      },
    ];

    this.fabOptsVrs = [
      {
        id: "add-var-seed-link",
        translId: "common.addVrsActivity",
        action: () => ngGoToVrs(),
      },
    ];

    this.state = {
      isVraAllowed: false,
    };
  }

  componentDidMount() {
    this.props.getVariableParcelIds().then((res) => {
      if (!res.error) {
        this.setState({
          isVraAllowed: Boolean(res.payload.length),
        });
      }
    });
    this.props.getVariableActionIds();
  }

  componentWillUnmount() {
    this.props.resetVariableActionIds();
  }

  handleDateFilterChange = (filter) => {
    let dateFilter = filter;
    if (filter) {
      dateFilter = filter.add(filter.utcOffset(), "m").format("YYYY-MM-DD");
    }
    this.props.setDateFilter(dateFilter);
  };

  handleDeleteActions = () => {
    const { selectedOnPage, variableActionIds } = this.props;
    this.props.deleteActions(selectedOnPage, variableActionIds);
  };

  render() {
    const {
      advancedFilter,
      classes,
      dateFilter,
      draftsOnly,
      history,
      langId,
      match,
      ngGoToAction,
      selectedOnPage,
      textFilter,
      toggleDraftsView,
    } = this.props;
    return (
      <CfErrorPage>
        <div className={classes.wrapper}>
          <PageHeader
            classes={{ header: classes.header }}
            actionButtons={
              <FabWithOptions
                options={[
                  ...this.fabOptions,
                  ...this.fabOptsVrf.map((opt) => ({
                    ...opt,
                    disabled: !this.state.isVraAllowed,
                  })),
                  ...this.fabOptsVrs.map((opt) => ({
                    ...opt,
                    disabled: !this.state.isVraAllowed,
                  })),
                ]}
              />
            }
            heading={
              <PageHeading
                dataTest="activities-heading"
                translationId="common.activities"
              />
            }
          />
          <div>
            <div className={classes.filtersHeader}>
              <span className={classes.actionButtons}>
                <TableActionButtons
                  onDeleteActions={this.handleDeleteActions}
                  selected={selectedOnPage}
                  content={
                    <FormattedMessage id="ActionButtons.deleteModalAction.context" />
                  }
                  title={
                    <FormattedMessage id="ActionButtons.deleteModalAction.title" />
                  }
                />
              </span>
              <span className={classes.dateFilter}>
                <CfDateFilter
                  label={<FormattedMessage id="common.date-from" />}
                  name="action-list-date-filter"
                  onFilterChange={this.handleDateFilterChange}
                  value={dateFilter}
                />
              </span>
              <div className={classes.textFilter}>
                <CfTextFilter
                  initialValue={textFilter}
                  name="action-list-text-filter"
                  namespace={namespace}
                  translId="ActionsList.text-filter-placeholder"
                />
              </div>
              <div className={classes.advancedFilter}>
                <ActionsAdvancedFilter
                  advancedFilter={advancedFilter}
                  langId={langId}
                  namespace={namespace}
                />
              </div>
              <div className={classes.checkboxes}>
                <FormControlLabel
                  label={<FormattedMessage id="ActionsList.isDraft" />}
                  control={
                    <Checkbox
                      checked={draftsOnly}
                      onChange={toggleDraftsView}
                    />
                  }
                />
              </div>
            </div>
            <ActionsTable
              history={history}
              langId={langId}
              match={match}
              ngGoToAction={ngGoToAction}
            />
          </div>
        </div>
      </CfErrorPage>
    );
  }
}

ActionsList.propTypes = {
  classes: PropTypes.object,
  langId: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  ngGoToSowing: PropTypes.func.isRequired,
  ngGoToEph: PropTypes.func.isRequired,
  ngGoToVrf: PropTypes.func.isRequired,
  ngGoToVrs: PropTypes.func.isRequired,
  ngGoToHarvest: PropTypes.func.isRequired,
  ngGoToMowing: PropTypes.func.isRequired,
  ngGoToAction: PropTypes.func.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  textFilter: PropTypes.string.isRequired,
  dateFilter: PropTypes.any,
  getVariableActionIds: PropTypes.func.isRequired,
  resetVariableActionIds: PropTypes.func.isRequired,
  getVariableParcelIds: PropTypes.func.isRequired,
  variableActionIds: PropTypes.array,
  draftsOnly: PropTypes.bool.isRequired,
  toggleDraftsView: PropTypes.func.isRequired,
  advancedFilter: PropTypes.object,
};

ActionsList.defaultProps = {
  classes: {},
  langId: "",
  match: {},
  history: {},
  variableActionIds: null,
};

const mapStateToProps = (state) => ({
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFilter: getDateFilter(state),
  variableActionIds: getVariableActionIdsSelector(state),
  draftsOnly: getDraftsOnly(state),
  advancedFilter: getActionsAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteActions,
      setDateFilter,
      getVariableActionIds,
      resetVariableActionIds,
      getVariableParcelIds,
      toggleDraftsView,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ActionsList));
