import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TemperatureIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M14,14.55475V4a2,2,0,0,0-4,0V14.55475a4,4,0,1,0,4,0ZM12,20a2,2,0,1,1,2-2A2.00229,2.00229,0,0,1,12,20Z" />
    <rect height="2" width="3" x="16" y="4" />
    <rect height="2" width="3" x="16" y="8" />
    <rect height="2" width="3" x="16" y="12" />
  </SvgIcon>
);

export default TemperatureIcon;
