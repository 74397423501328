import React, { FC } from "react";

import { Form, Field, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";

import { DriversListFormValues } from "./DriversListRow";

const DriversListEditDialogForm: FC = () => {
  const { errors } = useFormikContext<DriversListFormValues>();

  return (
    <Form>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          disabled
          label={<FormattedMessage id="Catalogues.table.drivers.dialog.code" />}
          name="code"
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          helperText={!!errors.name && <FormattedMessage id={errors.name} />}
          label={<FormattedMessage id="Catalogues.table.drivers.dialog.name" />}
          name="name"
        />
      </CfFormControl>
    </Form>
  );
};

export default DriversListEditDialogForm;
