import layersConfigCZ from "./layersCZ.config.json";
import layersConfigGlobal from "./layersGlobal.config.json";
import layersConfigSK from "./layersSK.config.json";

export default class LayersApiService {
  static getLayersConfig(countryCode) {
    const getResolve = () => {
      if (countryCode === "CZ") {
        return layersConfigCZ;
      } else if (countryCode === "SK") {
        return layersConfigSK;
      }
      return layersConfigGlobal;
    };

    return new Promise((resolve) => {
      const wait = setTimeout(() => {
        clearTimeout(wait);
        resolve(getResolve());
      }, 0);
    });
  }
}
