import * as types from "../actions/map.constants";

export default () =>
  (
    state = {
      mapColumns: 8,
      hoveredFeatureId: "-1",
      isSelecting: false,
      selectionCoords: null,
      enlarged: false,
      extent: [],
    },
    action,
  ) => {
    switch (action.type) {
      case types.SET_MAP_COLUMNS:
        return {
          ...state,
          mapColumns: action.mapColumns,
        };
      case types.STORE_HOVERED_FEATURE_ID:
        return {
          ...state,
          hoveredFeatureId: action.hoveredFeatureId,
        };
      case types.SET_EXTENT:
        return {
          ...state,
          extent: action.extent,
        };
      case types.SET_COORDS_SELECTION:
        return {
          ...state,
          isSelecting: action.isSelecting,
          selectionCoords: action.selectionCoords,
        };
      case types.SET_VARIANT_ENLARGED:
        return {
          ...state,
          enlarged: action.enlarged,
        };
      default:
        return state;
    }
  };
