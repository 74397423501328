import React, { FC, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getParcel } from "../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getDriveDetail,
  getIsFetchingDriveDetail,
} from "../../../shared/api/telematics/drives/drives.selectors";
import { getTelematicsDetailIsFormSubmitting } from "../../selectors/telematicsDetail.selectors";

import { fetchDriveDetail } from "../../actions/telematicsList.actions";

import { resetDriveDetail } from "../../../shared/api/telematics/drives/drives.api";
import { Thunk } from "../../../types";

import TelematicsRideDetail from "./TelematicsRideDetail";
import TelematicsRideHandworkDetail from "./TelematicsRideHandworkDetail";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  DrivePartDetailTo,
  Type,
} from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles(() => ({
  spinnerWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  driveDetail?: DrivePartDetailTo;
  farmId: string;
  fetchDriveDetail: (id: string) => void;
  isFetchingDriveDetail: boolean;
  langId: string;
  resetDriveDetail: () => void;
}

const TelematicsDetail: FC<Props> = ({
  driveDetail,
  farmId,
  fetchDriveDetail,
  isFetchingDriveDetail,
  langId,
  resetDriveDetail,
}) => {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);

  const { telematicsId: driveId } = useParams<{ telematicsId: string }>();

  const isNew = driveId === "new";

  useEffect(() => {
    resetDriveDetail();
    setInitialized(true);
    if (!isNew) {
      fetchDriveDetail(driveId);
    }
  }, [driveId, langId, isNew, fetchDriveDetail, resetDriveDetail]);

  const isFetching = isFetchingDriveDetail || (isNew && driveDetail);

  if (isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (!isNew && !driveDetail) return null;

  const isHandwork = () => driveDetail?.type === Type.HANDWORK;

  const renderLayout = () => {
    if (!isFetching && driveDetail) {
      if (isHandwork()) {
        return <TelematicsRideHandworkDetail farmId={farmId} />;
      } else {
        return (
          <TelematicsRideDetail farmId={farmId} initialized={initialized} />
        );
      }
    }
  };

  return <>{renderLayout()}</>;
};

const mapStateToProps = (state: TelematicsState) => ({
  parcel: getParcel(state),
  driveDetail: getDriveDetail(state),
  isFetchingDriveDetail: getIsFetchingDriveDetail(state),
  isSubmitting: getTelematicsDetailIsFormSubmitting(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDriveDetail,
      resetDriveDetail,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsDetail);
