import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const LocalizationIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M12,2.59A9.41,9.41,0,1,0,21.41,12,9.41,9.41,0,0,0,12,2.59Zm-.94,16.87A7.51,7.51,0,0,1,4.47,12a7.72,7.72,0,0,1,.2-1.68l4.51,4.5v.95a1.88,1.88,0,0,0,1.88,1.88Zm6.49-2.39a1.85,1.85,0,0,0-1.78-1.3h-.95V12.94a.94.94,0,0,0-.94-.94H8.23V10.12h1.89a1,1,0,0,0,.94-.94V7.29h1.88a1.88,1.88,0,0,0,1.88-1.88V5a7.53,7.53,0,0,1,4.71,7A7.44,7.44,0,0,1,17.55,17.07Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { LocalizationIcon };
