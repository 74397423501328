export default class GoogleAnalytics {
  static init(gaId) {
    window.gaId = gaId;
  }

  static allow() {
    if (window.gaId) {
      window[`ga-disable-${window.gaId}`] = false;
    }
  }

  static disable() {
    if (window.gaId) {
      window[`ga-disable-${window.gaId}`] = true;
    }
  }
}
