export const GET_AGINTEGRATION_VARIODOC_REQUEST =
  "GET_AGINTEGRATION_VARIODOC_REQUEST";
export const GET_AGINTEGRATION_VARIODOC_SUCCESS =
  "GET_AGINTEGRATION_VARIODOC_SUCCESS";
export const GET_AGINTEGRATION_VARIODOC_ERROR =
  "GET_AGINTEGRATION_VARIODOC_ERROR";

export const POST_AGINTEGRATION_VARIODOC_SUCCESS =
  "POST_AGINTEGRATION_VARIODOC_SUCCESS";
export const POST_AGINTEGRATION_VARIODOC_ERROR =
  "POST_AGINTEGRATION_VARIODOC_ERROR";
export const POST_AGINTEGRATION_VARIODOC_REQUEST =
  "POST_AGINTEGRATION_VARIODOC_REQUEST";

export const DELETE_AGINTEGRATION_VARIODOC_SUCCESS =
  "DELETE_AGINTEGRATION_VARIODOC_SUCCESS";
export const DELETE_AGINTEGRATION_VARIODOC_ERROR =
  "DELETE_AGINTEGRATION_VARIODOC_ERROR";
export const DELETE_AGINTEGRATION_VARIODOC_REQUEST =
  "DELETE_AGINTEGRATION_VARIODOC_REQUEST";

export const LOGIN_VARIODOC_REQUEST = "LOGIN_VARIODOC_REQUEST";
export const LOGIN_VARIODOC_SUCCESS = "LOGIN_VARIODOC_SUCCESS";
export const LOGIN_VARIODOC_ERROR = "LOGIN_VARIODOC_ERROR";

export const VALIDATE_VARIODOC_REQUEST = "VALIDATE_VARIODOC_REQUEST";
export const VALIDATE_VARIODOC_SUCCESS = "VALIDATE_VARIODOC_SUCCESS";
export const VALIDATE_VARIODOC_ERROR = "VALIDATE_VARIODOC_ERROR";
