import React, { Component } from "react";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getIsFetching as getIsFetchingStore } from "../../../../../shared/api/stores/stores/stores.selectors";
import {
  getIsFetchingTransactions,
  getTotalCount,
} from "../../../../../shared/api/stores/transactions/transactions.selectors";
import {
  getStorePage,
  getStoreRowsPerPage,
  getStoreFromFilter,
  getStoreToFilter,
  getStoreUnitId,
  getTransactions,
} from "../../selectors/store.selectors";

import {
  fetchTransactions,
  redirectToAction,
} from "../../actions/store.actions";

import { NAMESPACE as namespace } from "../../reducer/store.reducer";

import { resetTransactions } from "../../../../../shared/api/stores/transactions/transactions.api";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableIconButton from "../../../../../shared/components/tables/CfTableIconButton/CfTableIconButton";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../../shared/misc/helper";
import StoreActionLink from "../../components/StoreActionLink/StoreActionLink";

const columns = {
  type: getColDesc(
    false,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="common.type" />
    </span>,
  ),
  date: getColDesc(false, <FormattedMessage id="common.date" />),
  amount: getColDesc(false, <FormattedMessage id="common.amount" />, {
    textAlign: "right",
  }),
  before: getColDesc(
    false,
    <FormattedMessage id="Stores.state-before-move" />,
    { textAlign: "right" },
  ),
  after: getColDesc(false, <FormattedMessage id="Stores.state-after-move" />, {
    textAlign: "right",
  }),
  additional: getColDesc(
    false,
    <FormattedMessage id="Stores.additional-info" />,
  ),
  editation: getColDesc(false, <FormattedMessage id="common.edit2" />),
  delete: getColDesc(false, <FormattedMessage id="common.delete" />),
};

const styles = (theme) => ({
  editIcon: {
    color: theme.palette.action.active,
  },
  negative: {
    color: theme.palette.error.main,
  },
});

export class TransactionsTable extends Component {
  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      "storeId",
      "page",
      "rowsPerPage",
      "from",
      "to",
      "unitId",
    ]);
    const newProps = pick(this.props, [
      "storeId",
      "page",
      "rowsPerPage",
      "from",
      "to",
      "unitId",
    ]);

    if (!isEqual(newProps, oldProps) && newProps.unitId) {
      this.fetchTransactions(newProps);
    }
  }

  componentWillUnmount() {
    this.props.resetTransactions();
  }

  fetchTransactions(params) {
    const { storeId } = params;
    this.props.fetchTransactions(storeId);
  }

  render() {
    const {
      classes,
      count,
      isFetchingStore,
      isFetchingTransactions,
      items,
      langId,
      ngGoToAction,
      onDeleteOpen,
      onUpdateOpen,
      page,
      rowsPerPage,
    } = this.props;

    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          count={items.length}
          langId={langId}
          namespace={namespace}
          selectable={false}
        />
        {(isFetchingTransactions || isFetchingStore) && (
          <CfTableBodyLoader columns={columns} />
        )}

        {items.length ? (
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <CfTableCell name="type">
                  <StoreActionLink
                    item={item}
                    redirectToAction={() => {
                      this.props.redirectToAction(item.id, ngGoToAction);
                    }}
                  />
                </CfTableCell>
                <CfTableCell name="date">
                  <FormattedDate value={item.date} />
                </CfTableCell>
                <CfTableCell
                  className={item.amountView < 0 ? classes.negative : null}
                  name="amount"
                >
                  <CfTableCellNumber negative={item.amountView < 0}>
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.amountView}
                    />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="before">
                  <CfTableCellNumber negative={item.balanceBefore < 0}>
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.balanceBefore}
                    />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="after">
                  <CfTableCellNumber negative={item.balanceAfter < 0}>
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.balanceAfter}
                    />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="additional">
                  <span>{item.note}</span>
                </CfTableCell>
                <CfTableIconButton
                  disabled={!item.editable}
                  name="editation"
                  onClick={() => {
                    onUpdateOpen(item);
                  }}
                  tooltipTitle={
                    !item.editable ? (
                      <FormattedMessage id="Stores.store-edit-transaction-unable" />
                    ) : (
                      ""
                    )
                  }
                >
                  <CreateIcon className={classes.deleteIcon} />
                </CfTableIconButton>
                <CfTableIconButton
                  disabled={!item.editable}
                  name="delete"
                  onClick={() => {
                    onDeleteOpen(item);
                  }}
                  tooltipTitle={
                    !item.editable ? (
                      <FormattedMessage id="Stores.store-delete-transaction-unable" />
                    ) : (
                      ""
                    )
                  }
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </CfTableIconButton>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          langId={langId}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    );
  }
}

TransactionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isFetchingTransactions: PropTypes.bool.isRequired,
  isFetchingStore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
  resetTransactions: PropTypes.func.isRequired,
  redirectToAction: PropTypes.func.isRequired,
  ngGoToAction: PropTypes.func.isRequired,
  onDeleteOpen: PropTypes.func.isRequired,
  onUpdateOpen: PropTypes.func.isRequired,
};

TransactionsTable.defaultProps = {
  storeId: null,
  notes: [],
};

const mapStateToProps = (state) => ({
  items: getTransactions(state),
  isFetchingTransactions: getIsFetchingTransactions(state),
  isFetchingStore: getIsFetchingStore(state),
  count: getTotalCount(state),
  page: getStorePage(state),
  rowsPerPage: getStoreRowsPerPage(state),
  from: getStoreFromFilter(state),
  to: getStoreToFilter(state),
  unitId: getStoreUnitId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransactions,
      resetTransactions,
      redirectToAction,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TransactionsTable));
