import React, { Fragment } from "react";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import * as stages from "../../constants/stages.constants";

import DrawParcelForm from "../../containers/DrawParcelForm/DrawParcelForm";
import ToolbarBtnCancel from "../ToolbarBtnCancel/ToolbarBtnCancel";
import ToolbarBtnSubmit from "../ToolbarBtnSubmit/ToolbarBtnSubmit";

const DrawToolbar = (props) => {
  const {
    drawParcelCulture,
    drawParcelName,
    error,
    langId,
    onCancel,
    onSubmit,
    stage,
  } = props;
  return (
    <Fragment>
      {(stage === stages.STAGE_2 || stage === stages.STAGE_3) && (
        <DrawParcelForm langId={langId} />
      )}
      <span>
        <ToolbarBtnCancel onCancel={onCancel} />
        <ToolbarBtnSubmit
          onSubmit={onSubmit}
          translationId="tools.DRAW"
          disabled={
            !!error ||
            stage === stages.STAGE_1 ||
            stage === stages.STAGE_3 ||
            !drawParcelName ||
            isEmpty(drawParcelCulture)
          }
        />
      </span>
    </Fragment>
  );
};

DrawToolbar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  stage: PropTypes.string,
  error: PropTypes.string,
  drawParcelName: PropTypes.string,
  drawParcelCulture: PropTypes.object,
};

DrawToolbar.defaultProps = {
  stage: null,
  error: null,
  drawParcelName: "",
  drawParcelCulture: {},
};

export default DrawToolbar;
