import {
  getNotificationsAdminPage,
  getNotificationsAdminOrder,
  getNotificationsAdminOrderBy,
  getNotificationsAdminRowsPerPage,
  getNotificationsAdminTextFilter,
} from "../selectors/notifications.selectors";

import {
  getAdminFarms,
  createFarmNotifications,
  updateFarmNotifications,
} from "../../../../shared/api/automation/notificationsAdmin/notificationsAdmin.api";
import * as notificationsStatus from "../notificationsStatus";

export const fetchAdminFarms = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    page: getNotificationsAdminPage(state) + 1,
    "per-page": getNotificationsAdminRowsPerPage(state),
    "sort-dir": getNotificationsAdminOrder(state),
    "sort-col": getNotificationsAdminOrderBy(state),
    search: getNotificationsAdminTextFilter(state),
  };

  dispatch(getAdminFarms(params));
};

export const setFarmSettings = (farm, active, adminEmail) => (dispatch) => {
  if (adminEmail) {
    if (farm.status === notificationsStatus.INACTIVE) {
      dispatch(
        createFarmNotifications({
          farmId: farm.farmId,
          adminEmail,
          status: active
            ? notificationsStatus.ENABLED
            : notificationsStatus.DISABLED,
        }),
      ).then((res) => {
        if (res.error) {
          return Promise.reject(res);
        }
        dispatch(fetchAdminFarms());
        return Promise.resolve(res);
      });
    } else if (
      farm.adminEmail !== adminEmail ||
      (farm.status === notificationsStatus.ENABLED && !active) ||
      (farm.status === notificationsStatus.DISABLED && active)
    ) {
      dispatch(
        updateFarmNotifications(farm.farmId, {
          adminEmail,
          status: active
            ? notificationsStatus.ENABLED
            : notificationsStatus.DISABLED,
        }),
      ).then((res) => {
        if (res.error) {
          return Promise.reject(res);
        }
        dispatch(fetchAdminFarms());
        return Promise.resolve(res);
      });
    }
  }
};
