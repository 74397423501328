import { IrrigationState } from "../../reducers/irrigation.reducer.types";

export const getDevicesListTextFilter = (state: IrrigationState) =>
  state.ui.devicesList.textFilter;

export const getDevicesListPage = (state: IrrigationState) =>
  state.ui.devicesList.page;
export const getDevicesListOrder = (state: IrrigationState) =>
  state.ui.devicesList.order;
export const getDevicesListOrderBy = (state: IrrigationState) =>
  state.ui.devicesList.orderBy;
export const getDevicesListRowsPerPage = (state: IrrigationState) =>
  state.ui.devicesList.rowsPerPage;
export const getDevicesListSelected = (state: IrrigationState) =>
  state.ui.devicesList.selected;
