import { AnyAction } from "redux";

import * as types from "./sowingPlan.constants";

const initialState = {
  parcelSowingPlan: [],
  isFetchingParcelSowingPlan: false,
  seasonsSowingPlan: [],
  isFetchingSeasonsSowingPlan: false,
  error: undefined,
  seasonDetailSowingPlan: undefined,
  isFetchingSeasonDetailSowingPlan: false,
  isExportingData: false,
  exportError: null,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_PARCEL_PLAN_REQUEST:
        return {
          ...state,
          isFetchingParcelSowingPlan: true,
          error: undefined,
        };
      case types.GET_PARCEL_PLAN_SUCCESS:
        return {
          ...state,
          isFetchingParcelSowingPlan: false,
          parcelSowingPlan: action.payload,
        };
      case types.GET_PARCEL_PLAN_ERROR:
        return {
          ...state,
          isFetchingParcelSowingPlan: false,
          error: action.payload,
        };
      case types.RESET_PARCEL_PLAN:
        return {
          ...state,
          parcelSowingPlan: [],
          isFetchingParcelSowingPlan: false,
        };

      case types.GET_SEASONS_SUCCESS:
        return {
          ...state,
          seasonsSowingPlan: action.payload,
        };
      case types.RESET_SEASONS:
        return {
          ...state,
          seasonsSowingPlan: [],
        };
      case types.POST_SEASONS_SUCCESS:
        return {
          ...state,
          seasonsSowingPlan: action.payload,
        };

      case types.GET_SEASON_DETAIL_REQUEST:
        return {
          ...state,
          isFetchingSeasonDetailSowingPlan: true,
        };
      case types.GET_SEASON_DETAIL_SUCCESS:
        return {
          ...state,
          seasonDetailSowingPlan: action.payload,
          isFetchingSeasonDetailSowingPlan: false,
        };
      case types.GET_SEASON_DETAIL_ERROR:
        return {
          ...state,
          isFetchingSeasonDetailSowingPlan: false,
        };
      case types.RESET_SEASON_DETAIL:
        return {
          ...state,
          seasonDetailSowingPlan: undefined,
          isFetchingSeasonDetailSowingPlan: false,
        };

      case types.EXPORT_SEASONS_REQUEST:
        return {
          ...state,
          isExportingData: true,
          exportError: null,
        };
      case types.EXPORT_SEASONS_SUCCESS:
        return {
          ...state,
          isExportingData: false,
          exportError: null,
        };
      case types.EXPORT_SEASONS_ERROR:
        return {
          ...state,
          isExportingData: false,
          exportError: action.payload,
        };
      case types.RESET_EXPORT_SEASONS:
        return {
          ...state,
          exportError: null,
        };

      default:
        return state;
    }
  };
