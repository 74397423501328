import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    padding: "4px 4px 4px 12px",
    borderRadius: "50px",
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  transparent: {
    textTransform: "none",
    padding: "0px 0px 0px 9px",
    minWidth: 48,
  },
  custom: {},
  icon: {},
});

const CfSwitcherButton = (props) => {
  const { children, classes, transparent, ...rest } = props;
  return (
    <Button
      size="small"
      variant={transparent ? "text" : "contained"}
      {...rest}
      classes={{
        root: `${transparent ? classes.transparent : classes.root} ${
          classes.custom
        }`,
      }}
    >
      {children}
      <ArrowDropDownIcon classes={{ root: classes.icon }} />
    </Button>
  );
};

CfSwitcherButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  transparent: PropTypes.bool,
};

CfSwitcherButton.defaultProps = {
  id: "switcher-button",
  transparent: false,
};

export default withStyles(styles)(CfSwitcherButton);
