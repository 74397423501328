import ErrorService from "../../../services/Error.service";

export const getItems = (state) => state.api.locations.items;
export const getTotalCount = (state) => state.api.locations.totalCount;
export const getIsFetching = (state) => state.api.locations.isFetching;
export const getError = (state) =>
  ErrorService.getResErrorDto(state.api.locations.error);

export const getLocation = (state) => state.api.locations.item;
export const getIsFetchingLocation = (state) =>
  state.api.locations.isFetchingLocation;
export const getErrorLocation = (state) =>
  ErrorService.getResErrorDto(state.api.locations.errorLocation);

export const getPoints = (state) => state.api.locations.points;
export const getIsFetchingPoints = (state) =>
  state.api.locations.isFetchingPoints;

export const getPestPredictions = (state, locationId) =>
  state.api.locations.pestPredictions[locationId];
export const getPredictionData = (state, computedFeatureId, dataType) =>
  state.api.locations.pestPredictionsData[computedFeatureId] &&
  state.api.locations.pestPredictionsData[computedFeatureId][dataType]
    ? state.api.locations.pestPredictionsData[computedFeatureId][dataType].data
    : [];

export const getEvapotranspirationData = (state) =>
  state.api.locations.evapotranspiration;
export const getIsFetchingEvapotranspiration = (state) =>
  state.api.locations.isFetchingEvapotranspiration;
