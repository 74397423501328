import {
  getAreasOrder,
  getAreasOrderBy,
  getAreasPage,
  getAreasRowsPerPage,
} from "../selectors/areas.selectors";

import {
  RESET_AREA_PROGRAM,
  SET_AREA_PROGRAM,
  RESET_AREA,
} from "../../shared/api/irrigation/areas/areas.constants";

import {
  createAreaProgramApi,
  deleteAreaProgramApi,
  getAreaApi,
  deleteAreaApi,
  getAreaProgramApi,
  getAreaProgramsApi,
  getAreasListApi,
  updateAreaProgramApi,
} from "../../shared/api/irrigation/areas/areas.api";

export const fetchAreasList = () => (dispatch, getState) => {
  const state = getState();

  const params = {
    page: getAreasPage(state) + 1,
    "per-page": getAreasRowsPerPage(state),
    "sort-col": getAreasOrderBy(state),
    "sort-dir": getAreasOrder(state),
  };

  dispatch(getAreasListApi(params));
};

export const fetchArea = (areaId) => (dispatch) => {
  dispatch(getAreaApi(areaId));
};

export const resetArea = () => (dispatch) => {
  dispatch({
    type: RESET_AREA,
  });
};

export const fetchAreaPrograms = (areaId) => (dispatch) => {
  dispatch(getAreaProgramsApi(areaId));
};

export const deleteArea = (areaId, onAfterDeleted) => (dispatch, getState) => {
  dispatch(deleteAreaApi(areaId)).then(() => {
    onAfterDeleted();
    fetchAreasList()(dispatch, getState);
  });
};

export const fetchAreaProgram = (areaId, programId) => (dispatch) => {
  dispatch(getAreaProgramApi(areaId, programId));
};

export const setAreaProgram =
  (areaDevices, startTimes = null, irrigationDays = null) =>
  (dispatch) => {
    dispatch({
      type: SET_AREA_PROGRAM,
      areaDevices,
      startTimes,
      irrigationDays,
    });
  };

export const createAreaProgram =
  (areaId, payload, onAfterAction) => (dispatch) => {
    dispatch(createAreaProgramApi(areaId, payload)).then(() => {
      onAfterAction();
      fetchAreaPrograms(areaId)(dispatch);
    });
  };

export const updateAreaProgram =
  (areaId, programId, payload, onAfterAction) => (dispatch) => {
    dispatch(updateAreaProgramApi(areaId, programId, payload)).then(() => {
      onAfterAction();
      fetchAreaPrograms(areaId)(dispatch);
    });
  };

export const deleteAreaProgram =
  (areaId, programId, onAfterAction) => (dispatch) => {
    dispatch(deleteAreaProgramApi(areaId, programId)).then(() => {
      onAfterAction();
      fetchAreaPrograms(areaId)(dispatch);
    });
  };

export const resetAreaProgram = () => (dispatch) => {
  dispatch({
    type: RESET_AREA_PROGRAM,
  });
};
