import React, { useEffect, useState } from "react";

import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSoilSamplesIsFetching,
  selectSoilSamplesError,
  selectSoilSamplesYearsData,
  selectSoilSamplesYearsIsFetching,
  selectSoilSamplesYearsError,
} from "../../../shared/api/sentinel/soilSamples/soilSamples.selectors";
import {
  selectCombinedSoilSamplesData,
  selectValuesSoilSamplesData,
} from "../selectors/soilSamples.selectors";

import {
  getSoilSamplesDateApi,
  getSoilSamplesYears,
  resetSoilSamplesDate,
  resetSoilSamplesYearsDate,
} from "../../../shared/api/sentinel/soilSamples/soilSamples.api";
import CfButtonPanel from "../../../shared/components/common/CfButtonPanel/CfButtonPanel";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfSwitcher from "../../../shared/components/common/CfSwitcher/CfSwitcher";
import { CfRadioGroup } from "../../../shared/components/form/CfReduxFormRadioGroup/CfReduxFormRadioGroup";
import useWidth from "../../../shared/hooks/useWidth";
import PrecisionMapModal from "../components/PrecisionMapModal/PrecisionMapModal";
import SectionHeader from "../components/SectionHeader";
import SoilSamplesValuesTable from "../components/SoilSamples/SoilSamplesValuesTable";
import SoilSamplesZonesMap from "../components/SoilSamples/SoilSamplesZonesMap";
import ValuesInfoDialog from "../components/SoilSamples/ValuesInfoDialog/ValuesInfoDialog";

import {
  Sample,
  Zone,
} from "../../../shared/api/sentinel/soilSamples/soilSamples.types";

type Option = (typeof RADIO_BUTTONS)[number];

const RADIO_BUTTONS = ["MAPS", "VALUES"] as const;

const SoilSamples = ({ parcelId }: { parcelId: string }) => {
  const classes = useStyles();
  const width = useWidth();
  const years = {
    available: useSelector(selectSoilSamplesYearsData),
    fetching: useSelector(selectSoilSamplesYearsIsFetching),
    error: useSelector(selectSoilSamplesYearsError),
  };
  const samples = {
    values: useSelector(selectValuesSoilSamplesData),
    combined: useSelector(selectCombinedSoilSamplesData),
    fetching: useSelector(selectSoilSamplesIsFetching),
    error: useSelector(selectSoilSamplesError),
  };
  const dispatch = useDispatch();
  const [geometries, setGeometries] = useState<Zone[] | null>(null);
  const [points, setPoints] = useState<Sample[] | null>(null);
  const [activeRadioButton, setActiveRadioButton] = useState<Option>("MAPS");
  const [selectedYear, setSelectedYear] = useState<number>();
  const showModalMap = !!(geometries || points);

  const setMapForModal = (geometries: Zone[], points: Sample[]) => {
    setGeometries(geometries);
    setPoints(points);
  };

  const handleCloseModal = () => {
    setGeometries(null);
    setPoints(null);
  };

  useEffect(() => {
    if (!years.available || years.available.length === 0 || selectedYear) {
      return;
    }

    setSelectedYear(years.available[0]);
  }, [years.available, selectedYear]);

  useEffect(() => {
    if (selectedYear === undefined) {
      return;
    }
    dispatch(getSoilSamplesDateApi(parcelId, selectedYear.toString()));

    return () => {
      dispatch(resetSoilSamplesDate());
    };
  }, [dispatch, parcelId, selectedYear]);

  useEffect(() => {
    dispatch(getSoilSamplesYears(parcelId));

    return () => {
      dispatch(resetSoilSamplesYearsDate());
    };
  }, [dispatch, parcelId]);

  const Jsx: Record<Option, React.ReactNode> = {
    VALUES: (
      <Grid data-test="soil-samples-values" item xs={12}>
        <Paper className={classes.paper}>
          <SectionHeader
            dialogHeadingTranslationId="SoilSamples.valuesInfoDialog.title"
            headingTranslationId="SoilSamples.section.VALUES"
            infoDialogContent={<ValuesInfoDialog />}
            maxDialogWidth="md"
          />
          <SoilSamplesValuesTable values={samples.values} />
        </Paper>
      </Grid>
    ),
    MAPS: (
      <Grid data-test="soil-samples-maps" item xs={12}>
        <Grid container justifyContent="flex-start" spacing={0}>
          {samples.combined?.map((item, i) => (
            <SoilSamplesZonesMap
              item={item}
              key={i}
              onMapClick={setMapForModal}
              order={i}
              width={width}
            />
          ))}
        </Grid>
      </Grid>
    ),
  };

  return (
    <CfErrorPage error={samples.error ?? years.error}>
      <>
        <Grid container spacing={1}>
          <Grid className={classes.headerBar} item xs={12}>
            <Grid
              alignItems="center"
              container
              justifyContent={width !== "xs" ? "flex-start" : "center"}
            >
              <Grid data-test="soil-samples-switchers" item sm="auto" xs={12}>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item sm="auto" xs={12}>
                    <CfSwitcher
                      getItemId={(item) => item}
                      getItemValue={(item) => item}
                      items={years.available ?? []}
                      onMenuItemClick={setSelectedYear}
                      selectedItemId={selectedYear}
                      classes={{
                        wrapper: classes.cfSwitcherWrapper,
                      }}
                    />
                  </Grid>
                  <Grid item sm="auto" xs={12}>
                    <CfButtonPanel
                      classes={{ root: classes.indicesPanel }}
                      data-test="index-radio-switcher"
                      uppercase={false}
                    >
                      <CfRadioGroup
                        value={activeRadioButton}
                        classes={{
                          formControlLabel: classes.indexRadioLabel,
                          formControlLabelText: classes.indexRadioLabelText,
                        }}
                        labelPropertyFactory={(option) => (
                          <FormattedMessage
                            id={`SoilSamples.section.${option.value}`}
                          />
                        )}
                        onChange={(evt) =>
                          setActiveRadioButton(evt.target.value)
                        }
                        options={RADIO_BUTTONS.map((item) => ({
                          value: item,
                        }))}
                        RadioProps={{
                          classes: {
                            root: classes.indexRadio,
                          },
                        }}
                      />
                    </CfButtonPanel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {samples.fetching || years.fetching ? (
          <CfLoader />
        ) : (
          <Grid container spacing={1}>
            {Jsx[activeRadioButton]}
          </Grid>
        )}
      </>
      {showModalMap ? (
        <PrecisionMapModal
          geometries={geometries}
          isSoilSamples
          onClose={handleCloseModal}
          parcelId={parcelId}
          points={points}
          showModal={showModalMap}
        />
      ) : null}
    </CfErrorPage>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    margin: "10px 5px 5px 5px",
  },
  headerBar: {
    marginBottom: 5,
  },
  cfSwitcherWrapper: {
    justifyContent: "flex-start",
  },
  indicesPanel: {
    padding: "0px 2px",
  },
  indexRadioLabel: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
  },
  indexRadioLabelText: {
    fontSize: "14px",
    fontWeight: 500,
  },
  indexRadio: {
    padding: 4,
  },
}));

export { SoilSamples };
