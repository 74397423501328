import { RSAAAction } from "redux-api-middleware";

import { getTelematicsMachinesTextFilter } from "../selectors/telematicsMachines.selectors";
import { selectDateRange } from "../selectors/telematicsTabs.selectors";

import * as types from "./telematicsMachines.constants";
import * as apiTypes from "../../shared/api/telematics/machines/machines.constants";

import { exportAggregatedDrivesApi } from "../../shared/api/telematics/aggregations/aggregations.api";
import FileService from "../../shared/services/File.service";

import { TelematicsState } from "../../reducers/telematics.reducer.types";
import { ExportPayload } from "../../shared/api/api.types";
import { ExportAggregatedDrivesApiParams } from "../../shared/api/telematics/aggregations/aggregations.types";

export const setOpenedMachineRows = (payload: number[]) => ({
  type: types.SET_OPENED_MACHINE_ROWS,
  payload,
});

export const fetchAggregatedMachinesSaga = () => ({
  type: types.FETCH_AGGREGATED_MACHINES,
});

export const exportAggregatedMachinesDrives =
  () =>
  (
    dispatch: (action: RSAAAction) => Promise<ExportPayload>,
    getState: () => TelematicsState,
  ) => {
    const state = getState();

    const params: ExportAggregatedDrivesApiParams = {
      machine: getTelematicsMachinesTextFilter(state),
      ...selectDateRange(state),
    };

    dispatch(exportAggregatedDrivesApi(params)).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };

export const setFocusedRow = (payload?: string) => ({
  type: apiTypes.SET_FOCUSED_ROW,
  payload,
});
