/**
 * Created by ondrejzvara on 20.12.15.
 */

/*@ngInject*/
export default function BatchesResource(Api, ResourceUtils) {
  var service = {
    createBatch: createBatch,
    deleteBatch: deleteBatch,
    addLabelToBatch: addLabelToBatch,
    getBatchLabel: getBatchLabel,
    deleteBatchLabel: deleteBatchLabel,
    downloadBatchLabel: downloadBatchLabel,
  };
  return service;

  /////////////////////////

  function createBatch(porId, item) {
    var baseOne = Api.setBaseOne("por", porId);
    return ResourceUtils.customPost(baseOne, item, "batches");
  }

  function deleteBatch(batchId) {
    var baseOne = Api.setBaseOne("por/batches", batchId);
    return ResourceUtils.customDelete(baseOne, "");
  }

  function addLabelToBatch(file, batchId) {
    var base = Api.setBaseAll("por/batches/" + batchId + "/label");
    return ResourceUtils.uploadFile(base, { file: file });
  }

  function getBatchLabel(batchId) {
    var base = Api.setBaseAll("por/batches/" + batchId + "/label");
    return ResourceUtils.downloadFile(base, "image/jpg");
  }

  function downloadBatchLabel(batchId, fileName) {
    var base = Api.setBaseAll("por/batches/" + batchId + "/label");
    return ResourceUtils.downloadFile(base, null, fileName);
  }

  function deleteBatchLabel(batchId) {
    var base = Api.setBaseOne("por/batches/" + batchId, "label");
    return ResourceUtils.deleteItem(base);
  }
}
