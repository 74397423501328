import {
  getAccount,
  saveAccount as saveAccountApi,
} from "../../../../shared/api/gateway/accounts/accounts.api";

export const saveAccount = (account) => (dispatch) => {
  dispatch(saveAccountApi(account)).then(() => {
    dispatch(getAccount());
  });
};

export const fetchAccount = () => (dispatch) => {
  dispatch(getAccount());
};
