import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import namespaceReducer from "../catalogues/reducers/namespace.reducer";
import sectionList from "../catalogues/reducers/sectionList.reducer";
import cataloguesTypesApi from "../shared/api/agroevidence/catalogues/cataloguesTypes.reducer";
import cropsApi from "../shared/api/agroevidence/catalogues/crops/crops.reducer";
import cataloguesEagriApi from "../shared/api/agroevidence/catalogues/eagri/eagri.reducer";
import fertilizersApi from "../shared/api/agroevidence/catalogues/fertilizers/fertilizers.reducer";
import plantProtectionApi from "../shared/api/agroevidence/catalogues/plantProtection/plantProtection.reducer";
import seedsApi from "../shared/api/agroevidence/catalogues/seeds/seeds.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import telematicsCataloguesReducer from "../shared/api/telematics/catalogues/telematicsCatalogues.reducer";
import SectionStatusUIReducer from "../shared/api/telematics/sectionStatus/sectionStatus.reducer";

import { CATALOGUES_NAMESPACES } from "./catalogues.reducer.types";

export default (history: History) =>
  combineReducers({
    api: combineReducers({
      agroevidence: combineReducers({
        fertilizers: fertilizersApi(),
        seeds: seedsApi(),
        eagri: cataloguesEagriApi(),
        cataloguesTypes: cataloguesTypesApi(),
        crops: cropsApi(),
        plantProtection: plantProtectionApi(),
      }),
      telematics: telematicsCataloguesReducer,
    }),
    ui: combineReducers({
      selectedNamespace: namespaceReducer,
      drivers: sectionList(CATALOGUES_NAMESPACES.DRIVERS),
      machines: sectionList(CATALOGUES_NAMESPACES.MACHINES),
      equipment: sectionList(CATALOGUES_NAMESPACES.EQUIPMENT),
      fertilizers: sectionList(CATALOGUES_NAMESPACES.FERTILIZERS),
      seeds: sectionList(CATALOGUES_NAMESPACES.SEEDS),
      plantProtection: sectionList(CATALOGUES_NAMESPACES.PLANT_PROTECTION),
    }),
    router: connectRouter(history),
    auth: authReducer,
    telematicsStatus: SectionStatusUIReducer(),
  });
