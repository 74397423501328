import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ImageNotSupportedIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M14.79,11.56A5.57,5.57,0,0,0,4.41,10.09a4.4,4.4,0,0,0,.48,8.78h9.64a3.66,3.66,0,0,0,.26-7.31Z" />
      <path d="M21.65,7h-.18a2.85,2.85,0,1,0-2.69,3.78h2.87a1.89,1.89,0,1,0,0-3.78Z" />
    </svg>
  </SvgIcon>
);

export default ImageNotSupportedIcon;
