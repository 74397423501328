import _ from "lodash";
import { createSelector } from "reselect";

import {
  getError as getFarmsError,
  getIsFetching as getFarmsIsFetching,
} from "../../../../shared/api/agroevidence/farms/farms.selectors";
import {
  getNodes,
  getNodesError,
  getIsFetchingNodes,
  getTotalCount,
  getNodeTypes,
  getNodesDefaultFarm,
  getNetworkTypes,
} from "../../../../shared/api/iot/nodes/nodes.selectors";

export const getSensorsAdminPage = (state) => state.ui.iot_admin.page;
export const getSensorsAdminOrder = (state) => state.ui.iot_admin.order;
export const getSensorsAdminOrderBy = (state) => state.ui.iot_admin.orderBy;
export const getSensorsAdminRowsPerPage = (state) =>
  state.ui.iot_admin.rowsPerPage;
export const getSensorsAdminTextFilter = (state) =>
  state.ui.iot_admin.textFilter;
export const getSensorsAdminSelected = (state) => state.ui.iot_admin.selected;
export const getSensorsAdminDisplayInactive = (state) =>
  state.ui.iot_admin.displayInactive;

export const getSensorsAdminStatusFilter = (state) =>
  getSensorsAdminAdvancedFilter(state).status || [];
export const getSensorsAdminNodeTypeFilter = (state) =>
  getSensorsAdminAdvancedFilter(state).nodeType || [];
export const getSensorsAdminNetworkTypeFilter = (state) =>
  getSensorsAdminAdvancedFilter(state).networkType || [];
export const getSensorsAdminAdvancedFilter = (state) =>
  state.ui.iot_admin.advancedFilter;

export const getSensorsAdminCountryFilter = (state) =>
  state.ui.iot_admin.country;
export const getSensorsAdminFarmFilter = (state) => state.ui.iot_admin.farm;

export const getSensorsAdminIsFetching = (state) => getIsFetchingNodes(state);
export const getSensorsAdminTotalCount = (state) => getTotalCount(state);

export const getSensorsAdminNodes = (state) => getNodes(state);

export const getSensorsAdminNodeTypes = (state) => getNodeTypes(state);
export const getSensorsAdminNetworkTypes = (state) => getNetworkTypes(state);
export const getSensorsAdminDefaultFarm = (state) => getNodesDefaultFarm(state);
export const getSensorsAdminIsFetchingFarms = (state) =>
  getFarmsIsFetching(state);

export const getSensorsAdminApiError = createSelector(
  getNodesError,
  getFarmsError,
  (nodesError, farmsError) => {
    const errors = [nodesError, farmsError];
    return errors.find((err) => err.isError === true) || errors[0];
  },
);
export const getSensorsAdminSelectedOnPage = createSelector(
  getSensorsAdminNodes,
  getSensorsAdminSelected,
  (items, selected) =>
    _.intersection(
      items.map((item) => item.id),
      selected,
    ),
);
