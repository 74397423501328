import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getContext } from "../../selectors/map.selectors";

import { setMapContext } from "../../actions/map/map.actions";

import * as contexts from "../../constants/contexts.constants";

import { isTelematicSectionAllowed } from "../../../../shared/api/telematics/sectionStatus/sectionStatus.selector";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import { TelematicsIcon } from "../../../../shared/icons/navbar/TelematicsIcon";
import PreviewIcon from "../../../../shared/icons/PreviewIcon";
import ToolbarBtn from "../../components/ToolbarBtn/ToolbarBtn";

const styles = (theme) => ({
  custom: {
    marginTop: 2,
  },
  root: {
    color: theme.palette.common.white,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const icons = {
  [contexts.PREVIEW]: PreviewIcon,
  [contexts.TELEMATICS]: TelematicsIcon,
  // [contexts.WEATHER]: WeatherIcon,
};

export class ContextSwitcher extends Component {
  switchContext = (context) => {
    if (context !== this.props.context) {
      this.props.setMapContext(context);
    }
  };

  render() {
    const { classes, context, isTelematicsAllowed } = this.props;

    return (
      <Fragment>
        {Object.keys(contexts).map((key) =>
          key === contexts.TELEMATICS ? (
            isTelematicsAllowed && (
              <ToolbarSection
                background={false}
                key={key}
                classes={{
                  custom: classes.custom,
                }}
              >
                <ToolbarBtn
                  active={key === context}
                  callback={() => this.switchContext(key)}
                  icon={icons[key]}
                  productFruits={`${key.toLowerCase()} context`}
                  testId={`${key.toLowerCase()}-context-button`}
                  translationId={`context.${key}`}
                  classes={{
                    root: classes.root,
                    active: classes.active,
                  }}
                />
              </ToolbarSection>
            )
          ) : (
            <ToolbarSection
              background={false}
              key={key}
              classes={{
                custom: classes.custom,
              }}
            >
              <ToolbarBtn
                active={key === context}
                callback={() => this.switchContext(key)}
                icon={icons[key]}
                productFruits={`${key.toLowerCase()} context`}
                testId={`${key.toLowerCase()}-context-button`}
                translationId={`context.${key}`}
                classes={{
                  root: classes.root,
                  active: classes.active,
                }}
              />
            </ToolbarSection>
          ),
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMapContext,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  context: getContext(state),
  isTelematicsAllowed: isTelematicSectionAllowed(state),
});

ContextSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  setMapContext: PropTypes.func.isRequired,
  isTelematicsAllowed: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ContextSwitcher));
