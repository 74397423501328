import React, { FC } from "react";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "14px",
  },
}));

interface Props {
  handleSubmit: () => void;
  isChangingState?: boolean;
  isPostponed?: boolean;
}

const PostponedIconButton: FC<Props> = ({
  handleSubmit,
  isChangingState = false,
  isPostponed,
}) => {
  const classes = useStyles();

  const handleEventPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  return (
    <div onClick={handleEventPropagation}>
      {isChangingState && (
        <CfLoader
          classes={{ wrapper: classes.wrapper }}
          color="inherit"
          size={20}
        />
      )}
      {!isPostponed && !isChangingState && (
        <Tooltip
          title={
            <FormattedMessage id="TelematicsAggregations.postponed.ride.acceptTooltip" />
          }
        >
          <span>
            {!isChangingState && (
              <IconButton
                aria-label="Postponed ride"
                onClick={handleSubmit}
                size="large"
              >
                <BookmarkBorderOutlinedIcon />
              </IconButton>
            )}
          </span>
        </Tooltip>
      )}
      {isPostponed && !isChangingState && (
        <Tooltip
          title={
            <FormattedMessage id="TelematicsAggregations.postponed.ride.cancelTooltip" />
          }
        >
          <span>
            {!isChangingState && (
              <IconButton
                aria-label="Postponed ride"
                color="primary"
                onClick={handleSubmit}
                size="large"
              >
                <BookmarkIcon />
              </IconButton>
            )}
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default PostponedIconButton;
