/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** GeoJSON representation of a Geometry. */
export interface Geometry {
  /** @example "LineString" */
  type?: string;
  coordinates?: number[][];
}

export enum MachineCategoryCode {
  AJE = "AJE",
  CIS = "CIS",
  KNA = "KNA",
  KRM = "KRM",
  MAN = "MAN",
  NAK = "NAK",
  NAV = "NAV",
  NNN = "NNN",
  OMA = "OMA",
  OST = "OST",
  OSA = "OSA",
  POS = "POS",
  PED = "PED",
  SKM = "SKM",
  SKR = "SKR",
  SPC = "SPC",
  SPP = "SPP",
  SRN = "SRN",
  T0 = "T0",
  T1 = "T1",
  T2 = "T2",
  T3 = "T3",
  T4 = "T4",
  ZAS = "ZAS",
  ZLK = "ZLK",
  ZSB = "ZSB",
}

export interface MachineUpdateTo {
  name: string;
  licencePlate: string;
  /** @format double */
  workingWidth: number;
  category: MachineCategoryCode;
  operationCode: string;
}

export interface DriveUpdateKeyTo {
  /** @format int32 */
  supplierKeyId?: number;
  /** @format int32 */
  customerKeyId?: number;
  /** @format int32 */
  equipmentKeyId?: number;
}

export interface WinfasClientUpdateTo {
  idNumber: string;
  name: string;
}

export interface WinfasUpdateTo {
  keys: DriveUpdateKeyTo;
  client?: WinfasClientUpdateTo;
}

export interface DriveKeyTo {
  /** @format int32 */
  id?: number;
  key?: string;
  name?: string;
  validity?: boolean;
}

export interface DriveKeysTo {
  supplierKey?: DriveKeyTo;
  customerKey?: DriveKeyTo;
  equipmentKey?: DriveKeyTo;
  client?: string;
}

export interface WinfasClientTo {
  idNumber: string;
  name: string;
}

export interface WinfasTo {
  keys?: DriveKeysTo;
  client?: WinfasClientTo;
}

export interface EquipmentUpdateTo {
  name: string;
  /** @format double */
  workingWidth: number;
  operationCode: string;
}

export interface DriverUpdateTo {
  name: string;
}

export interface DrivePartCreateTo {
  parcelId?: string;
  /** @format date-time */
  timeFrom: string;
  /** @format date-time */
  timeTo: string;
  driver?: string;
  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example "S711A33264"
   */
  gpsUnit: string;
  /**
   * Equipment code (dallas chip).
   * @example "021467745FFF"
   */
  equipment?: string;
  operation: TelematicsOperation;
  /**
   * Production operation code.
   * @example "6020-VO"
   */
  productionOperation?: string;
  /** Working width [m] for this drive. */
  workingWidth?: number;
  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;
  /** Distance [km] which the machine travelled. */
  distance?: number;
  /** Driver bonus [%} for this operation. */
  bonus: number;
}

/**
 * Operation type.
 * @example "Transfer"
 */
export enum TelematicsOperation {
  PARCEL = "PARCEL",
  TRANSFER = "TRANSFER",
  TRANSPORT = "TRANSPORT",
  REPAIR = "REPAIR",
  HANDWORK = "HANDWORK",
}

export interface ActionDriveDetailPartTo {
  activityId?: string;
  area?: number;
  distance?: number;
}

/** Relationship between the parcel and the farm (i.e. the farm under which the drive was reported). */
export enum Affiliation {
  COMPANY = "COMPANY",
  EXTERNAL = "EXTERNAL",
  NONE = "NONE",
}

export interface DrivePartDetailFarmTo {
  /** @format int64 */
  farmId?: number;
  /** @format int32 */
  companyNumber?: number;
}

export interface DrivePartDetailOperationTo {
  code?: string;
  name?: string;
}

export interface DrivePartDetailTo {
  /** @format int32 */
  id?: number;
  /** GeoJSON representation of a Geometry. */
  geometry?: Geometry;
  /**
   * Azimuth of the trajectory start, expressed in radians.
   * @example 0.78539816
   */
  geometryStartAzimuth?: number;
  /** GeoJSON representation of a Geometry. */
  geometryStart?: Geometry;
  /** GeoJSON representation of a Geometry. */
  geometryEnd?: Geometry;
  /** GeoJSON representation of a Geometry. */
  geometryPerDay?: Geometry;
  parcelId?: string;
  centerKey?: string;
  /** @format int32 */
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  /** @format int32 */
  season?: number;
  farm: DrivePartDetailFarmTo;
  /** @format date-time */
  timeFrom: string;
  /** @format date-time */
  timeTo: string;
  driver: DriverDrivePartDetailTo;
  machineName?: string;
  machineKeyCombination?: string;
  machineCategoryCode?: string;
  gpsUnit?: string;
  equipmentKeyCombination?: string;
  equipmentCode?: string;
  equipmentName?: string;
  equipmentAffiliation?: Affiliation;
  equipmentCompanyName?: string;
  workingWidth?: number;
  operation: TelematicsOperation;
  productionOperation?: DrivePartDetailOperationTo;
  parcel?: ParcelInfoTo;
  action: ActionDriveDetailPartTo;
  source: Source;
  state: State;
  type: Type;
}

export interface DriverDrivePartDetailTo {
  name?: string;
  code?: string;
  personalNumber?: string;
  bonus?: number;
  isEmployee: boolean;
  affiliation?: Affiliation;
  companyName?: string;
}

export interface ParcelInfoTo {
  /** GeoJSON representation of a Geometry. */
  geometry?: Geometry;
  localName?: string;
  blockNumber?: string;
  square?: string;
  subjectId?: string;
  subjectName?: string;
  area?: number;
  affiliation?: Affiliation;
}

export enum Source {
  MANUAL = "MANUAL",
  EUROWAG = "EUROWAG",
  ISOXML = "ISOXML",
}

export enum State {
  DEFERRED = "DEFERRED",
  NOT_APPROVED = "NOT_APPROVED",
  APPROVED = "APPROVED",
  THIRD_PARTY_ACKNOWLEDGED = "THIRD_PARTY_ACKNOWLEDGED",
}

export enum Type {
  AUTOMATIC = "AUTOMATIC",
  UPDATED = "UPDATED",
  MANUAL = "MANUAL",
  SEPARATED = "SEPARATED",
  HANDWORK = "HANDWORK",
}

export interface DriveCreateTo {
  /** @format date-time */
  timeFrom: string;
  /** @format date-time */
  timeTo: string;
  driver?: string;
  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example "S711A33264"
   */
  gpsUnit: string;
  /**
   * Equipment code (dallas chip).
   * @example "021467745FFF"
   */
  equipment?: string;
  operation: TelematicsOperation;
  /**
   * Production operation code.
   * @example "6020-VO"
   */
  productionOperation?: string;
  /**
   * Parcel
   * @example "12"
   */
  parcelId?: string;
  /**
   * Season starting year
   * @format int32
   * @example 2022
   */
  season?: number;
  /** Working width [m] of this drive. */
  workingWidth?: number;
  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;
  /** Distance [km] which the machine travelled. */
  distance?: number;
  /** Driver bonus [%} for this operation. */
  bonus: number;
}

export interface DriveDetailActionTo {
  actionId?: string;
  actionCode?: string;
}

export interface DriveDetailDriverTo {
  name?: string;
  code?: string;
  personalNumber?: string;
  bonus?: number;
  isEmployee: boolean;
  affiliation?: Affiliation;
  companyName?: string;
}

export interface DriveDetailFarmTo {
  /** @format int64 */
  farmId?: number;
  /** @format int32 */
  companyNumber?: number;
}

export interface DriveDetailOperationTo {
  code?: string;
  name?: string;
}

export interface DriveDetailPartTo {
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTo: string;
  /** GeoJSON representation of a Geometry. */
  geometry?: Geometry;
}

export interface DriveDetailTo {
  /** @format int32 */
  id: number;
  drivePart: DriveDetailPartTo[];
  parcelId?: string;
  centerKey?: string;
  /** @format int32 */
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  /** @format int32 */
  season?: number;
  farm: DriveDetailFarmTo;
  /** @format date-time */
  dateFrom: string;
  /** @format double */
  duration: number;
  driver: DriveDetailDriverTo;
  machineName?: string;
  machineCategoryCode?: string;
  gpsUnit?: string;
  equipmentCode?: string;
  equipmentName?: string;
  equipmentAffiliation?: Affiliation;
  equipmentCompanyName?: string;
  workingWidth?: number;
  operation: TelematicsOperation;
  productionOperation?: DriveDetailOperationTo;
  parcel?: ParcelInfoTo;
  cultivated?: number;
  distance?: number;
  validation: DriveDetailValidationTo;
  actions: DriveDetailActionTo[];
  source: Source;
  state: State;
  type: Type;
  /** GeoJSON representation of a Geometry. */
  geometryPerDay?: Geometry;
}

export interface DriveDetailValidationTo {
  isComplete: boolean;
  validationErrors: DriveValidationType[];
}

export enum DriveValidationType {
  CENTER_EXISTS = "CENTER_EXISTS",
  CROP_EXISTS = "CROP_EXISTS",
  CULTIVATED_NOT_ZERO = "CULTIVATED_NOT_ZERO",
  DISTANCE_NOT_ZERO = "DISTANCE_NOT_ZERO",
  DURATION_NOT_ZERO = "DURATION_NOT_ZERO",
  DRIVER_EXISTS = "DRIVER_EXISTS",
  PARCEL_EXISTS = "PARCEL_EXISTS",
  PRODUCTION_OPERATION_EXISTS = "PRODUCTION_OPERATION_EXISTS",
  SUPPLIER_KEY_EXISTS = "SUPPLIER_KEY_EXISTS",
  SUPPLIER_KEY_VALID = "SUPPLIER_KEY_VALID",
  CUSTOMER_KEY_EXISTS = "CUSTOMER_KEY_EXISTS",
  CUSTOMER_KEY_VALID = "CUSTOMER_KEY_VALID",
  CLIENT_EXISTS = "CLIENT_EXISTS",
  TIMES_DONT_OVERLAP = "TIMES_DONT_OVERLAP",
}

export interface DriveUpdateTo {
  /** @format date-time */
  timeFrom?: string;
  /** @format date-time */
  timeTo?: string;
  driver?: string;
  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example "S711A33264"
   */
  gpsUnit: string;
  /**
   * Equipment code (dallas chip).
   * @example "021467745FFF"
   */
  equipment?: string;
  operation: TelematicsOperation;
  /**
   * Production operation code.
   * @example "6020-VO"
   */
  productionOperation?: string;
  /**
   * Parcel
   * @example "12"
   */
  parcelId?: string;
  parcelAffiliation?: Affiliation;
  /**
   * Season starting year
   * @format int32
   * @example 2022
   */
  season?: number;
  /** Working width [m] of this drive. */
  workingWidth?: number;
  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;
  /** Distance [km] which the machine travelled. */
  distance?: number;
  /** Driver bonus [%} for this operation. */
  bonus: number;
}

export interface DriveSeparationTimeTo {
  /** @format partial-time */
  time: string;
}

export interface DriveSeparationIntervalTo {
  /** @format partial-time */
  timeFrom: string;
  /** @format partial-time */
  timeTo: string;
}

export interface DriveSeparationIntervalsTo {
  intervals: DriveSeparationIntervalTo[];
}

export interface DriveRecalculateTo {
  /**
   * GPS unit code, specific to a machine which performed the drive.
   * @example "S711A33264"
   */
  gpsUnit: string;
  /**
   * Equipment code (dallas chip).
   * @example "021467745FFF"
   */
  equipment?: string;
  /** Working width [m] of this drive. */
  workingWidth?: number;
}

export interface DriveAreaTo {
  area?: number;
}

export interface DriveUpdateStateTo {
  drive: number[];
  state: State;
}

export interface DriveOverlapRequestTo {
  /** @format date */
  date: string;
  driver: string;
}

export interface DriveDetailOverlapTo {
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTo: string;
}

export interface HandworkCreateTo {
  /** @format date-time */
  timeFrom: string;
  /** @format date-time */
  timeTo: string;
  driver?: string;
  /**
   * Production operation code.
   * @example "6020-VO"
   */
  productionOperation?: string;
  /** Driver bonus [%} for this operation. */
  bonus: number;
  winfas?: WinfasUpdateTo;
}

export interface DriveBatchUpdateSubjectTo {
  drive: number[];
  subject: string;
}

export interface DriveBatchUpdateProductionOperationTo {
  drive: number[];
  /** @format int32 */
  productionOperation: number;
}

export interface DriveBatchUpdateCustomerKeyTo {
  drive: number[];
  /** @format int32 */
  customerKeyId: number;
}

export interface DriveBatchUpdateEquipmentTo {
  drive: number[];
  /** @format int32 */
  equipment: number;
}

export interface DriveBatchUpdateDriverTo {
  drive: number[];
  /** @format int32 */
  driver: number;
}

export interface DriveBatchUpdateBonusTo {
  drive: number[];
  bonus: number;
}

export interface AccountCreateTo {
  farmId: string;
  name: string;
  externalId: string;
  countryCode: string;
  enabled: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  /** @format date */
  economicSystemDate?: string;
  automaticActions: boolean;
}

export enum Catalogue {
  WINFAS = "WINFAS",
}

export enum EconomicSystem {
  TOS = "TOS",
}

export interface AccountTo {
  farmId: string;
  name: string;
  externalId?: string;
  countryCode: string;
  enabled: boolean;
  /** @format date-time */
  activated?: string;
  /** @format date-time */
  deactivated?: string;
  zoneId?: string;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  /** @format date */
  economicSystemDate?: string;
  automaticActions: boolean;
}

export interface AccountUpdateTo {
  farmId: string;
  externalId: string;
  enabled: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  /** @format date */
  economicSystemDate?: string;
  automaticActions: boolean;
}

export enum KeyType {
  SUPPLIER = "SUPPLIER",
  CUSTOMER = "CUSTOMER",
  EQUIPMENT = "EQUIPMENT",
}

export interface OperationTo {
  code: TelematicsOperation;
  name: string;
}

export interface ProductionOperationTo {
  /** @format int32 */
  id?: number;
  code?: string;
  name?: string;
  bonus?: number;
}

export enum MachineSortColumn {
  GPS_UNIT = "GPS_UNIT",
  NAME = "NAME",
  LICENCE_PLATE = "LICENCE_PLATE",
  WORKING_WIDTH = "WORKING_WIDTH",
  VALID_FROM = "VALID_FROM",
  VALID_TO = "VALID_TO",
}

export interface DeviceCompanyTo {
  id?: string;
}

export interface DeviceOperationTo {
  id?: string;
  name?: string;
  bonus?: number;
}

export enum MachineGroupCode {
  COMBINE = "COMBINE",
  LOADER = "LOADER",
  TRUCK = "TRUCK",
  PASSENGER = "PASSENGER",
  TRACTOR = "TRACTOR",
  OTHER = "OTHER",
}

export interface MachineTo {
  /** @format int32 */
  id?: number;
  machineCode?: string;
  keyCombination?: string;
  operation?: DeviceOperationTo;
  type?: string;
  licencePlate?: string;
  name?: string;
  /** @format double */
  workingWidth?: number;
  gpsUnit: string;
  unitPhoneNo?: string;
  company?: DeviceCompanyTo;
  /** @format date */
  validFrom: string;
  /** @format date */
  validTo?: string;
  profileCode?: string;
  group: MachineGroupCode;
  groupName?: string;
  category: MachineCategoryCode;
  categoryName?: string;
}

export interface PositionDetailTo {
  /** @format date-time */
  time: string;
  /** @format date-time */
  movementTime?: string;
  /** GeoJSON representation of a Geometry. */
  location: Geometry;
  machine?: PositionMachineTo;
  driver?: PositionDriverTo;
  equipment?: PositionEquipmentTo;
  speed?: number;
  azimuth?: number;
}

export interface PositionDriverTo {
  name?: string;
  code: string;
}

export interface PositionEquipmentTo {
  name?: string;
  code: string;
}

export interface PositionMachineTo {
  name?: string;
  machineCode?: string;
  type?: string;
  operation?: string;
  licencePlate?: string;
  gpsUnit: string;
  group?: MachineGroupCode;
}

export interface DailyPositionTo {
  /** @format date-time */
  date: string;
  /** GeoJSON representation of a Geometry. */
  geometry: Geometry;
  gpsUnit: string;
  machineName?: string;
  licencePlate?: string;
  driverName?: string;
  driverCode?: string;
  equipmentName?: string;
  equipmentCode?: string;
}

export interface MachineGroupTo {
  code: MachineGroupCode;
  value: string;
}

export interface MachineGroupCategoryTo {
  /** @format int32 */
  id: number;
  code: string;
  group: MachineGroupTo;
  name: string;
}

export enum LogbookSortColumn {
  FROM = "FROM",
  DRIVER_NAME = "DRIVER_NAME",
  OPERATION_NAME = "OPERATION_NAME",
  MACHINE_NAME = "MACHINE_NAME",
  CULTIVATED = "CULTIVATED",
}

export interface LogbookFilterCriteriaTo {
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState: string[];
  crop: number[];
  operation: TelematicsOperation[];
  affiliation: Affiliation[];
  productionOperation: string[];
  actionType: string[];
  parcel: number[];
  parcelSubjectId: string[];
  driver: string[];
  gpsUnit: string[];
  equipment: string[];
  state: State[];
}

export interface DriverIdentityTo {
  name?: string;
  code?: string;
  affiliation?: Affiliation;
  companyName?: string;
}

export interface EquipmentLogbookItemTo {
  code?: string;
  /**
   * Human-readable equipment name.
   * @example "ZDT, Galan, PV 15"
   */
  name?: string;
  affiliation?: Affiliation;
  width?: number;
  companyName?: string;
}

export interface LogbookItemTo {
  id: string;
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  driver?: DriverIdentityTo;
  /**
   * Operation type (localized).
   * @example "Transfer"
   */
  operation?: string;
  productionOperation?: OperationLogbookItemTo;
  parcel?: ParcelInfoTo;
  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;
  /** Total parcel area [ha]. */
  parcelArea?: number;
  /** Distance [m] which the machine travelled. */
  distance?: number;
  /** Driver bonus [%}. */
  bonus?: number;
  gpsUnit?: string;
  machineName?: string;
  machineLicencePlate?: string;
  equipment?: EquipmentLogbookItemTo;
  /** @format int32 */
  cropCode?: number;
  /** @format int32 */
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  source: Source;
  state: State;
  type: Type;
}

export interface OperationLogbookItemTo {
  code?: string;
  /**
   * Human-readable operation name.
   * @example "VO-ŽV-Převoz dobytka"
   */
  name?: string;
}

export interface TosStatsTo {
  /** @format int32 */
  count: number;
  /** @format date-time */
  dateFrom?: string;
  /** @format date-time */
  dateTo?: string;
}

export interface LogbookAggregatedFilterCriteriaTo {
  dateFrom?: string;
  dateTo?: string;
  driver?: string;
  machine?: string;
  isValid?: boolean;
  state: State[];
}

export enum LogbookAggregatedSortColumn {
  FROM = "FROM",
  DRIVER_NAME = "DRIVER_NAME",
  MACHINE_NAME = "MACHINE_NAME",
  DURATION = "DURATION",
}

export interface DriverLogbookItemTo {
  code?: string;
  /**
   * Human-readable equipment name.
   * @example "ZDT, Galan, PV 15"
   */
  name?: string;
  affiliation?: Affiliation;
  companyName?: string;
}

export interface LogbookAggregatedItemTo {
  id: string;
  /** @format date-time */
  dateFrom: string;
  duration: number;
  /**
   * Operation type (localized).
   * @example "Transfer"
   */
  operation?: string;
  productionOperation?: OperationLogbookItemTo;
  parcel?: SimpleParcelInfoTo;
  /** Parcel area [ha] which was cultivated during the drive. */
  cultivated?: number;
  /** Total parcel area [ha]. */
  parcelArea?: number;
  /** Distance [m] which the machine travelled. */
  distance?: number;
  gpsUnit?: string;
  machineName?: string;
  machineLicencePlate?: string;
  driver?: DriverLogbookItemTo;
  equipment?: EquipmentLogbookItemTo;
  validation: ValidationLogbookItemTo;
  /** @format int32 */
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  source: Source;
  state: State;
  type: Type;
}

export interface LogbookAggregatedTimeTo {
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTo: string;
  type: Type;
}

export interface LogbookAggregatedTo {
  driver?: DriverIdentityTo;
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTo: string;
  times: LogbookAggregatedTimeTo[];
  duration: number;
  cultivated: number;
  distance: number;
  drives: LogbookAggregatedItemTo[];
}

export interface SimpleParcelInfoTo {
  localName?: string;
  blockNumber?: string;
  square?: string;
  subjectName?: string;
  area?: number;
  affiliation?: Affiliation;
}

export interface ValidationLogbookItemTo {
  isComplete: boolean;
  validationErrors: DriveValidationType[];
}

export interface DriveSubjectTo {
  id: string;
  name: string;
}

export interface LogbookAggregatedMachineTo {
  machine: SimpleMachineTo;
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTo: string;
  times: LogbookAggregatedTimeTo[];
  duration: number;
  cultivated: number;
  distance: number;
  drives: LogbookAggregatedItemTo[];
}

export interface SimpleMachineTo {
  /** @format int32 */
  id?: number;
  machineCode?: string;
  operation?: DeviceOperationTo;
  type?: string;
  licencePlate?: string;
  name?: string;
  /** @format double */
  workingWidth?: number;
  gpsUnit: string;
  profileCode?: string;
}

export interface ActionDriveTo {
  /** @format int32 */
  driveId: number;
  driveAffiliation: Affiliation;
  /** @format int64 */
  farmId: number;
  farmName: string;
  companyId: string;
  gpsUnit?: string;
  machineName?: string;
  driverCode?: string;
  driverName?: string;
  equipmentCode?: string;
  equipmentName?: string;
  operationCode?: string;
  operationName?: string;
}

export interface ActionDrivesTo {
  parcels: Record<string, ActionDriveTo[]>;
}

export interface DriveImportTo {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  started?: string;
  /** @format date-time */
  finished?: string;
  state?: ImportState;
  source?: Source;
}

export enum ImportState {
  STARTED = "STARTED",
  ERROR = "ERROR",
  COMPLETED = "COMPLETED",
}

export enum EquipmentSortColumn {
  CODE = "CODE",
  NAME = "NAME",
  WORKING_WIDTH = "WORKING_WIDTH",
  VALID_FROM = "VALID_FROM",
  VALID_TO = "VALID_TO",
}

export interface EquipmentCompanyTo {
  id?: string;
}

export interface EquipmentOperationTo {
  id?: string;
  name?: string;
  bonus?: number;
}

export interface EquipmentTo {
  /** @format int32 */
  id?: number;
  name?: string;
  code: string;
  company?: EquipmentCompanyTo;
  operation?: EquipmentOperationTo;
  /** @format double */
  workingWidth?: number;
  /** @format date */
  validFrom: string;
  /** @format date */
  validTo?: string;
  profileCode?: string;
}

export enum DriverSortColumn {
  CODE = "CODE",
  NAME = "NAME",
  VALID_FROM = "VALID_FROM",
  VALID_TO = "VALID_TO",
}

export interface DriverCompanyTo {
  id?: string;
}

export interface DriverTo {
  /** @format int32 */
  id?: number;
  name?: string;
  code: string;
  personalNumber?: string;
  company?: DriverCompanyTo;
  /** @format date */
  validFrom: string;
  /** @format date */
  validTo?: string;
}

export enum CropSortColumn {
  NAME = "NAME",
}

export interface CropTo {
  /** @format int32 */
  id?: number;
  name?: string;
  /** @format int32 */
  code: number;
  /** @format int32 */
  key?: number;
}

export enum AccountSortColumn {
  FARM_ID = "FARM_ID",
  NAME = "NAME",
  EXTERNAL_ID = "EXTERNAL_ID",
  COUNTRY_CODE = "COUNTRY_CODE",
  ENABLED = "ENABLED",
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface AccountFilterCriteriaTo {
  search?: string;
  countryCode?: string[];
  enabled?: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
}

export interface AccountStatusTo {
  telematics: boolean;
  externalId?: string;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  /** @format date */
  economicSystemDate?: string;
  automaticActions: boolean;
}
