import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SoilTemperatureIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M8 13.6001H5V15.1001H8V13.6001Z" fill="#DB6B35" />
    <path d="M8 10.6001H5V12.1001H8V10.6001Z" fill="#DB6B35" />
    <path d="M8 16.5H5V18H8V16.5Z" fill="#DB6B35" />
    <path d="M19 13.6001H16V15.1001H19V13.6001Z" fill="#DB6B35" />
    <path d="M19 10.6001H16V12.1001H19V10.6001Z" fill="#DB6B35" />
    <path d="M19 16.5H16V18H19V16.5Z" fill="#DB6B35" />
    <path
      d="M16 6C16 3.8 14.2 2 12 2C9.8 2 8 3.8 8 6C8 7.5 8.8 8.8 10 9.4V20C10 21.1 10.9 22 12 22C13.1 22 14 21.1 14 20V9.5C15.2 8.8 16 7.5 16 6ZM12 4C13.1 4 14 4.9 14 6C14 7.1 13.1 8 12 8C10.9 8 10 7.1 10 6C10 4.9 10.9 4 12 4Z"
      fill="#DB6B35"
    />
  </SvgIcon>
);

export default SoilTemperatureIcon;
