import React from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import { BUFFER_TYPES } from "../../actions/buffer/bufferTypes";
import ToolbarHoverStopper from "../ToolbarHoverStopper/ToolbarHoverStopper";

const styles = {
  formControl: {
    paddingRight: 10,
  },
  formControlLabel: {
    margin: "0px 4px",
  },
  radioButton: {
    padding: 2,
  },
  input: {
    margin: 0,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
  toolbarSection: {
    display: "flex",
    alignItems: "center",
  },
};

function BufferType(props) {
  const { bufferType, classes } = props;

  return (
    <ToolbarSection classes={{ custom: classes.toolbarSection }}>
      <ToolbarHoverStopper />
      <FormControl className={classes.formControl} component="fieldset">
        <RadioGroup
          aria-label="buffer type"
          name="bufferType"
          onChange={(event) => props.setBufferType(event.target.value)}
          row
          value={bufferType}
        >
          <FormControlLabel
            className={classes.formControlLabel}
            label={<FormattedMessage id="BufferType.PARTIAL" />}
            labelPlacement="end"
            value={BUFFER_TYPES.PARTIAL}
            classes={{
              label: classes.label,
            }}
            control={
              <Radio
                className={classes.radioButton}
                color="primary"
                size="small"
              />
            }
          />
          <FormControlLabel
            className={classes.formControlLabel}
            label={<FormattedMessage id="BufferType.OVERALL" />}
            labelPlacement="end"
            value={BUFFER_TYPES.OVERALL}
            classes={{
              label: classes.label,
            }}
            control={
              <Radio
                className={classes.radioButton}
                color="primary"
                size="small"
              />
            }
          />
        </RadioGroup>
      </FormControl>
    </ToolbarSection>
  );
}

BufferType.propTypes = {
  classes: PropTypes.object.isRequired,
  setBufferType: PropTypes.func.isRequired,
  bufferType: PropTypes.string.isRequired,
};

export default withStyles(styles)(BufferType);
