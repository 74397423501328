import React from "react";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: 6,
  },
};

const CfTableIconButton = (props) => {
  const { children, classes, disabled, name, onClick, tooltipTitle } = props;
  return (
    <TableCell name={name}>
      <Tooltip placement="bottom" title={tooltipTitle || ""}>
        <div className={classes.wrapper}>
          <IconButton
            disabled={disabled}
            onClick={onClick}
            size="large"
            classes={{
              root: classes.button,
            }}
          >
            {children}
          </IconButton>
        </div>
      </Tooltip>
    </TableCell>
  );
};

CfTableIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CfTableIconButton.defaultProps = {
  disabled: false,
  tooltipTitle: "",
};

export default withStyles(styles)(CfTableIconButton);
