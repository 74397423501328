import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { COLORS } from "../../../shared/components/misc/CfTextBadge/CfTextBadge";
import { COLOR_TIMELINE } from "../../../theme";

export const useNewHandworkFormStyles = makeStyles((theme: Theme) => ({
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  form: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  intervalWarningIcon: {
    textAlign: "center",
    alignSelf: "center",
  },
  sectionHeading: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 6,
  },
  timelineNotification: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    marginBottom: 16,
  },
  timelineNotificationText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#9B9B9B",
    marginTop: 5,
    marginLeft: 4,
  },
  activeIntervalsTime: {
    color: COLORS.VIOLET_PLUM,
  },
  intervalsTime: {
    color: theme.palette.grey[500],
  },
  intervals: {
    margin: "6px 0",
  },
  intervalsHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 8,
    lineHeight: 1,
  },
  intervalsHeaderLabel: {
    fontSize: 14,
    color: theme.palette.grey[500],
  },
  intervalsHeaderLabelNew: {
    fontSize: 14,
    color: COLOR_TIMELINE.isEditing,
  },
  container: {
    marginTop: 4,
    width: "100%",
  },
  fieldWrapper: {
    display: "flex",
    alignItems: "center",
  },
  externalDriver: {
    fontSize: "13px",
    marginTop: "-22px",
    marginBottom: "25px",
  },
}));
