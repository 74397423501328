import React, { Component } from "react";

import { Button, Grid, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { checkEmailFormat } from "../../../shared/misc/helpers";

const style = {
  textField: {
    textAlign: "left",
    width: "100%",
    marginTop: 0,
  },
  header: {
    marginTop: 0,
    fontWeight: 500,
    fontSize: 24,
  },
  button: {
    width: "100%",
  },
};

class EmailEntryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailErrorText: "",
      emailFormatError: false,
      emailExists: true,
    };
  }

  onInputChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  onInputFocus = (e) => {
    const name = `${e.target.name}ErrorText`;
    this.setState({
      [name]: "",
    });
  };

  getEmailValidationMessage = () => {
    if (this.state.emailFormatError) {
      return <FormattedMessage id="reset.email.error.format" />;
    } else if (!this.state.emailExists) {
      return <FormattedMessage id="reset.email.error.not.exists" />;
    }

    return "";
  };

  setEmailValidationMessage = (emailFormatError, emailExists, callback) => {
    this.setState(
      {
        emailFormatError,
        emailExists,
      },
      () => {
        const emailErrorText = this.getEmailValidationMessage();
        const noError = emailErrorText === "";
        this.setState({
          emailErrorText,
        });
        if (noError && callback) {
          callback();
        }
      },
    );
  };

  validateEmail = (callback) => {
    const email = this.state.email;
    const emailFormatError = !checkEmailFormat(email);
    let emailExists = true;

    if (!emailFormatError) {
      this.props
        .checkEmailExists(email)
        .then(({ payload }) => {
          emailExists = payload;
          this.setEmailValidationMessage(
            emailFormatError,
            emailExists,
            callback,
          );
        })
        .catch(() => {
          this.props.errorAction();
        });

      return;
    }

    this.setEmailValidationMessage(emailFormatError, emailExists, callback);
  };

  submit = () => {
    this.validateEmail(() => {
      this.props.submitEmailReset(this.state.email);
    });
  };

  render() {
    const { classes, ngRedirectToLogin } = this.props;

    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <h3 className={classes.header}>
            <FormattedMessage id="reset.header" />
          </h3>
        </Grid>
        <Grid item xs={12}>
          <FormattedMessage id="reset.guide" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus={true}
            className={classes.textField}
            error={this.state.emailErrorText !== ""}
            label={<FormattedMessage id="reset.email.placeholder" />}
            margin="dense"
            name="email"
            onBlur={() => this.validateEmail()}
            onChange={(e) => this.onInputChange(e)}
            onFocus={(e) => this.onInputFocus(e)}
            type="text"
            value={this.state.email}
            helperText={
              this.state.emailErrorText !== "" ? this.state.emailErrorText : " "
            }
            InputLabelProps={{
              style: {
                fontWeight: 400,
                zIndex: 1,
                pointerEvents: "none",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            color="primary"
            id="reset-email"
            onClick={() => this.submit()}
            variant="contained"
          >
            <FormattedMessage id="reset.email.button" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <a href="#" onClick={ngRedirectToLogin}>
            <FormattedMessage id="reset.linkToLogin" />
          </a>
        </Grid>
      </Grid>
    );
  }
}

EmailEntryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  checkEmailExists: PropTypes.func.isRequired,
  submitEmailReset: PropTypes.func.isRequired,
  errorAction: PropTypes.func.isRequired,
  ngRedirectToLogin: PropTypes.func.isRequired,
};

export default withStyles(style)(EmailEntryForm);
