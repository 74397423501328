import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const NotificationsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M14.9994 27.4993C16.524 27.4993 17.7715 26.2519 17.7715 24.7273H12.2273C12.2273 26.2519 13.4609 27.4993 14.9994 27.4993ZM23.3156 19.1831V12.253C23.3156 7.99784 21.0425 4.43573 17.0784 3.49323V2.55073C17.0784 1.40032 16.1498 0.47168 14.9994 0.47168C13.849 0.47168 12.9203 1.40032 12.9203 2.55073V3.49323C8.94243 4.43573 6.6832 7.98398 6.6832 12.253V19.1831L3.91113 21.9552V23.3412H26.0877V21.9552L23.3156 19.1831Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { NotificationsIcon };
