import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Collapse, IconButton, TableCell, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import download from "js-file-download";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getPrecisionAsAppliedOpenedTaskRows,
  getPrecisionAsAppliedSelected,
} from "../selectors/precisionAsApplied.selectors";

import {
  fetchPrecisionAsApplied,
  setOpenedPrecisionAsAppliedRows,
} from "../actions/precision.actions";

import { NAMESPACE as namespace } from "../reducer/precisionAsApplied.reducer";

import {
  asAppliedDownloadFileApi,
  patchTaskdataApi,
} from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowTools from "../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableCheckbox from "../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { useToggle } from "../../../shared/hooks/useToggle";
import { ExportIcon } from "../../../shared/icons/ExportIcon";
import { getLocalizedDateString } from "../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../types";
import useAsAppliedDuration from "../hooks/useAsAppliedDuration";

import AsAppliedDeleteDialog from "./AsAppliedDeleteDialog";
import AsAppliedTaskList from "./AsAppliedTaskList";
import { useAsAppliedRowStyles, useAsAppliedTableRowStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import {
  TaskDataListTo,
  TaskDataPatchTo,
} from "../../../shared/api/satellite/satellite.types";

type Props = {
  columnsLength: number;
  data: TaskDataListTo;
  farmId: string;
};

type ReduxProps = ConnectedProps<typeof connector>;

const AsAppliedRow: React.FC<ReduxProps & Props> = ({
  asAppliedDownloadFile,
  columnsLength,
  data,
  farmId,
  fetchPrecisionAsApplied,
  openedRows,
  patchTaskdataApi,
  selected,
  setOpenedPrecisionAsAppliedRows,
}) => {
  const { duration: taskDuration } = useAsAppliedDuration(data?.duration ?? 0);
  const classes = useAsAppliedRowStyles();
  const rowClasses = useAsAppliedTableRowStyles();
  const {
    on: deleteIsOpen,
    setOff: handleDeleteClose,
    setOn: handleDeleteOpen,
  } = useToggle();

  const opened = openedRows?.includes(data.id) ?? false;
  const toggleOpened = () => {
    if (opened) {
      setOpenedPrecisionAsAppliedRows(
        openedRows.filter((id) => id !== data.id),
      );
    } else {
      setOpenedPrecisionAsAppliedRows([...openedRows, data.id]);
    }
  };

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    (asAppliedDownloadFile as AsyncFn<string>)(data.id.toString()).then(
      (res) => {
        if (res?.error) return;
        const { body, type } = res?.payload || {};
        body?.then((b: BodyInit | null | undefined) =>
          new Response(b).blob().then((blob) => {
            download(blob, data.filename, type);
          }),
        );
      },
    );
  };

  const onArchivingClick = (e: React.MouseEvent, archived: boolean) => {
    e.stopPropagation();
    (patchTaskdataApi as AsyncFn<TaskDataPatchTo>)({
      tasks: { [data.id]: { archived } },
    }).then((res) => {
      if (res?.error) return;
      fetchPrecisionAsApplied();
    });
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleDeleteOpen();
  };

  const getCellStyle = () =>
    classNames({
      [rowClasses.archivedText]: data.archived,
    });

  return (
    <>
      <TableRow
        hover
        key={data.id}
        onClick={toggleOpened}
        className={classNames({
          [classes.tableRow]: true,
          [rowClasses.archivedMainRow]: data.archived,
          [rowClasses.selectedMainRow]: selected.includes(data.id),
        })}
      >
        <CfTableCheckbox
          id={data.id}
          name="id"
          namespace={namespace}
          selected={selected}
        />
        <CfTableCell className={getCellStyle()} name="created">
          <div>
            <div className={classes.firstColumnTop}>
              {data.created ? getLocalizedDateString(data.created) : "-"}
            </div>
            <div className={classes.firstColumnGrey}>
              {data.created
                ? getLocalizedDateString(data.created, ["LT"])
                : "-"}
            </div>
          </div>
        </CfTableCell>
        <CfTableCell className={getCellStyle()} name="source">
          <span>{data.source}</span>
        </CfTableCell>
        <CfTableCell className={getCellStyle()} name="filename">
          <span>{data.filename}</span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.columnLeft }}
          className={getCellStyle()}
          name="tasks.length"
        >
          <span>{data.tasks.length}</span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.columnLeft }}
          className={getCellStyle()}
          name="dateFrom"
        >
          <span>
            {data.dateFrom ? getLocalizedDateString(data.dateFrom) : "-"}
          </span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.columnLeft }}
          className={getCellStyle()}
          name="dateTo"
        >
          <span>{data.dateTo ? getLocalizedDateString(data.dateTo) : "-"}</span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.columnRight }}
          className={getCellStyle()}
          name="duration"
        >
          <span>{taskDuration}</span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.actions }}
          className={getCellStyle()}
          name="actions"
        >
          <CfTableRowTools
            key="area-actions"
            toolsClass={classes.tableRowActions}
            withoutGradient
          >
            {data?.archived ? (
              <Tooltip title={<FormattedMessage id="common.makeVisible" />}>
                <IconButton
                  aria-label="unarchive"
                  onClick={(e) => onArchivingClick(e, false)}
                  size="large"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<FormattedMessage id="common.hide" />}>
                <IconButton
                  aria-label="archived"
                  onClick={(e) => onArchivingClick(e, true)}
                  size="large"
                >
                  <VisibilityOff />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={<FormattedMessage id="common.download" />}>
              <IconButton
                aria-label="Download"
                onClick={handleDownloadClick}
                size="large"
              >
                <ExportIcon className={classes.exportIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="common.delete" />}>
              <IconButton
                aria-label="delete"
                onClick={handleDeleteClick}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </CfTableRowTools>
          <IconButton
            aria-label="Toggle view"
            className={classes.collapseIcon}
            size="large"
          >
            {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={columnsLength}>
          <Collapse in={opened}>
            <>
              {data.tasks && (
                <AsAppliedTaskList
                  data={data.tasks}
                  farmId={farmId}
                  mainRow={data}
                  opened={opened}
                  selected={selected}
                />
              )}
            </>
          </Collapse>
        </TableCell>
      </TableRow>
      {deleteIsOpen && (
        <AsAppliedDeleteDialog
          handleClose={handleDeleteClose}
          open={deleteIsOpen}
          openRowId={data.id}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  openedRows: getPrecisionAsAppliedOpenedTaskRows(state),
  selected: getPrecisionAsAppliedSelected(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      setOpenedPrecisionAsAppliedRows,
      asAppliedDownloadFile: asAppliedDownloadFileApi as (id: string) => void,
      patchTaskdataApi: patchTaskdataApi as (params: TaskDataPatchTo) => void,
      fetchPrecisionAsApplied,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AsAppliedRow);
