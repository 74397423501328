import * as types from "./locations.constants";
import * as pestPredictionsTypes from "../predictions/predictions.constants";

import DeviceConfig from "../../../../sensors/services/DeviceConfig.service";
import NodesApi from "../nodes/NodesApi.service";

export default () =>
  (
    state = {
      isFetching: false,
      items: [],
      pestPredictions: {},
      pestPredictionsData: {},
      error: {},
      totalCount: 0,
      points: [],
      isFetchingPoints: false,
      errorPoints: {},
      item: {},
      isFetchingLocation: false,
      errorLocation: {},
      evapotranspiration: {},
      isFetchingEvapotranspiration: false,
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_LOCATIONS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_LOCATIONS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload
            .filter((location) =>
              location.node ? NodesApi.isSupportedDevice(location.node) : false,
            )
            .map((location) => addDeviceConfigToLocation(location)),
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_LOCATIONS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_LOCATIONS:
        return {
          ...state,
          isFetching: false,
          error: {},
          items: [],
          totalCount: 0,
        };
      case types.GET_LOCATIONS_POINTS:
        return {
          ...state,
          isFetchingPoints: true,
          errorPoints: {},
        };
      case types.GET_LOCATIONS_POINTS_SUCCESS:
        return {
          ...state,
          isFetchingPoints: false,
          points: action.payload,
        };
      case types.GET_LOCATIONS_POINTS_ERROR:
        return {
          ...state,
          isFetchingPoints: false,
          errorPoints: action.payload,
        };
      case types.RESET_LOCATIONS_POINTS:
        return {
          ...state,
          isFetchingPoints: false,
          errorPoints: {},
          points: [],
        };
      case types.GET_LOCATION:
        return {
          ...state,
          isFetchingLocation: true,
          errorLocation: {},
        };
      case types.GET_LOCATION_SUCCESS:
        return {
          ...state,
          item: addDeviceConfigToLocation(action.payload),
          isFetchingLocation: false,
        };
      case types.GET_LOCATION_ERROR:
        return {
          ...state,
          item: {},
          isFetchingLocation: false,
          errorLocation: action.payload,
        };
      case types.RESET_LOCATION:
        return {
          ...state,
          item: {},
          isFetchingLocation: false,
          errorLocation: {},
        };
      case types.PATCH_LOCATION_SUCCESS:
        return {
          ...state,
          item: addDeviceConfigToLocation(action.payload),
        };
      case types.REMOVE_LOCATION:
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.id),
        };
      case types.GET_LOCATION_PREDICTIONS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          pestPredictions: replacePestPredictions(state, action),
        };
      case types.GET_PREDICTION_DATA:
        return {
          ...state,
          pestPredictionsData: setPredictionDataFetching(
            state.pestPredictionsData,
            action,
            true,
          ),
          error: {},
        };
      case types.GET_PREDICTION_DATA_SUCCESS:
        return {
          ...state,
          pestPredictionsData: replaceInPredictionItems(
            state.pestPredictionsData,
            action,
          ),
        };
      case types.GET_PREDICTION_DATA_ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case types.RESET_PREDICTION_DATA:
        return {
          ...state,
          error: {},
          pestPredictionsData: {},
        };
      case pestPredictionsTypes.SET_PREDICTION_CONFIG_SUCCESS:
        return {
          ...state,
          item: updateNodeLocationPredictionConfig(state, action.payload),
        };
      case pestPredictionsTypes.GET_PREDICTION_CONFIG_SUCCESS:
        return {
          ...state,
          item: updateNodeLocationPredictionConfig(state, action.payload),
        };

      case types.GET_EVAPOTRANSPIRATION_DATA_REQUEST:
        return {
          ...state,
          isFetchingEvapotranspiration: true,
        };
      case types.GET_EVAPOTRANSPIRATION_DATA_SUCCESS:
        return {
          ...state,
          evapotranspiration: replaceInEvapotranspirationData(action.payload),
          isFetchingEvapotranspiration: false,
        };
      case types.GET_EVAPOTRANSPIRATION_DATA_ERROR:
        return {
          ...state,
          isFetchingEvapotranspiration: false,
        };
      case types.RESET_EVAPOTRANSPIRATION_DATA:
        return {
          ...state,
          evapotranspiration: {},
          isFetchingEvapotranspiration: false,
        };

      default:
        return state;
    }
  };

const replaceInEvapotranspirationData = (payload) => {
  const { avg, sum, total } = payload;

  return {
    total: +total || 0,
    SUM: {
      data: sum,
      isFetching: false,
    },
    INCREMENT: {
      data: avg,
      isFetching: false,
    },
  };
};

const replacePestPredictions = (state, action) => {
  if (action.payload.length > 0) {
    const { nodeLocationId } = action.payload[0];
    return {
      ...state.pestPredictions,
      [nodeLocationId]: action.payload,
    };
  }
  return {
    ...state.pestPredictions,
  };
};

const replaceInPredictionItems = (data, action) => {
  const {
    payload: { computedFeatureId, dataType, items },
  } = action;

  return {
    ...data,
    [computedFeatureId]: {
      ...data[computedFeatureId],
      [dataType]: {
        ...data[computedFeatureId][dataType],
        data: items,
        isFetching: false,
      },
    },
  };
};

const setPredictionDataFetching = (data, action, value) => {
  const {
    payload: { computedFeatureId, dataType },
  } = action;

  if (!data[dataType]) {
    return {
      ...data,
      [computedFeatureId]: {
        ...data[computedFeatureId],
        [dataType]: {
          isFetching: value,
        },
      },
    };
  }

  return {
    ...data,
    [dataType]: {
      ...data[dataType],
      isFetching: value,
    },
  };
};

const addDeviceConfigToLocation = (location) => {
  const node = NodesApi.filterSupportedSensors(location.node, location.modules);
  const newLocation = {
    ...location,
    node: {
      ...node,
      config: DeviceConfig.getDeviceConfig(
        location.node.deviceType,
        node.sensors,
      ),
    },
  };
  return newLocation;
};

const updateNodeLocationPredictionConfig = (state, computedFeatureLocation) => {
  const computedFeatureId = computedFeatureLocation.featureId;
  const { configuration } = computedFeatureLocation;
  const newConfigProps = {
    visible: configuration.visible,
    ...(configuration.validAfterDay
      ? { validAfterDay: configuration.validAfterDay }
      : {}),
    ...(configuration.validAfterMonth
      ? { validAfterMonth: configuration.validAfterMonth }
      : {}),
  };

  return {
    ...state.item,
    computedFeatures: state.item.computedFeatures.map((item) => {
      if (item.id === computedFeatureId) {
        return {
          ...item,
          ...newConfigProps,
        };
      }
      return item;
    }),
  };
};
