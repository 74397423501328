import React from "react";

import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { Field, reduxForm } from "redux-form";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormattedField from "../../../../../shared/components/form/CfFormattedField/CfFormattedField";
import CfFormattedTextField from "../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfReduxFormRadioGroup from "../../../../../shared/components/form/CfReduxFormRadioGroup/CfReduxFormRadioGroup";
import CfReduxFormSelect from "../../../../../shared/components/form/CfReduxFormSelect/CfReduxFormSelect";
import CfSelector from "../../../../../shared/components/form/CfSelector/CfSelector";
import * as validators from "../../../../../shared/misc/validators";
import unitsMock from "../../../../../shared/mocks/units.mock.json";
import incorporationDaysSource from "../../../eph/misc/incorporationDay.json";
import {
  validateMinDose,
  validateMaxDose,
} from "../../../shared/misc/action.helpers";
import {
  STRONG_ZONES_FERTILIZATION,
  WEAK_ZONES_FERTILIZATION,
} from "../../helpers/vrf.helpers";

const units = unitsMock.units.filter(
  (item) => item.id === "kg" || item.id === "l",
);
const options = [
  { value: STRONG_ZONES_FERTILIZATION },
  { value: WEAK_ZONES_FERTILIZATION },
];

const styles = (theme) => ({
  MuiFormLabel_root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  menuItem: {
    fontSize: 16,
  },
  inputLabel: {
    fontWeight: "normal",
  },
  unitGridItem: {
    marginTop: "auto",
  },
  helperText: {
    position: "absolute",
    top: 43,
  },
  unitField: {
    display: "inherit",
  },
});

const validate = (values) => {
  const minDose = validateMinDose(values);
  const maxDose = validateMaxDose(values);

  return {
    ...(minDose ? { minDose } : {}),
    ...(maxDose ? { maxDose } : {}),
  };
};

const VariableFertilizerDialog = ({
  classes,
  expense,
  handleSubmit,
  initialize,
  isAdding,
  isEditing,
  isOpen,
  onAccept,
  onClose,
}) => {
  const { incorporationDays } = incorporationDaysSource;

  let acceptText = <FormattedMessage id="common.close" />;
  if (isAdding) {
    acceptText = <FormattedMessage id="common.add" />;
  } else if (isEditing) {
    acceptText = <FormattedMessage id="common.edit" />;
  }
  return (
    <CfDialog
      acceptText={acceptText}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={isEditing || isAdding ? handleSubmit(onAccept) : onClose}
      onCancel={isEditing || isAdding ? onClose : undefined}
      onClose={onClose}
      onEnter={() => initialize(expense)}
      opened={isOpen}
      title={expense ? expense.material.name : ""}
    >
      <form>
        <CfFormControl>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <CfFormattedField
                autoFocus
                component={CfFormattedTextField}
                disabled={!isEditing}
                FormHelperTextProps={{ className: classes.helperText }}
                fullWidth
                label={<FormattedMessage id="VariableFertilization.maxDose" />}
                name="maxDose"
                type="text"
                validate={[validators.required, validators.positiveNumber]}
                InputLabelProps={{
                  style: styles.inputLabel,
                }}
              />
            </Grid>
            <Grid className={classes.unitGridItem} item xs={3}>
              <Field
                className={classes.unitField}
                component={CfSelector}
                disabled={!isEditing}
                items={units}
                name="doseUnit"
                labelPropertyFactory={(option) => (
                  <span>
                    <FormattedMessage id={`unit.${option.id}`} />
                    /ha
                  </span>
                )}
              />
            </Grid>
          </Grid>
        </CfFormControl>
        <CfFormControl>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <CfFormattedField
                component={CfFormattedTextField}
                disabled={!isEditing}
                FormHelperTextProps={{ className: classes.helperText }}
                fullWidth
                label={<FormattedMessage id="VariableFertilization.minDose" />}
                name="minDose"
                type="text"
                validate={[validators.required, validators.positiveNumber]}
                InputLabelProps={{
                  style: styles.inputLabel,
                }}
              />
            </Grid>
            <Grid className={classes.unitGridItem} item xs={3}>
              <Field
                className={classes.unitField}
                component={CfSelector}
                disabled={!isEditing}
                items={units}
                name="doseUnit"
                labelPropertyFactory={(option) => (
                  <span>
                    <FormattedMessage id={`unit.${option.id}`} />
                    /ha
                  </span>
                )}
              />
            </Grid>
          </Grid>
        </CfFormControl>

        <CfFormControl>
          <InputLabel className={classes.inputLabel}>
            <FormattedMessage id="SoilIncorporation.incorporation.date" />
          </InputLabel>
          <Field
            component={CfReduxFormSelect}
            disabled={!isEditing}
            name="incorporationDay"
          >
            {incorporationDays.map((dat) => (
              <MenuItem
                className={classes.menuItem}
                key={dat.code}
                value={dat.code}
              >
                <FormattedMessage id={dat.intId} />
              </MenuItem>
            ))}
          </Field>
        </CfFormControl>

        <CfFormControl>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel
                classes={{ root: classes.MuiFormLabel_root }}
                component="legend"
                focused={false}
              >
                <FormattedMessage id="Vrf.strategy" />
              </FormLabel>
              <Field
                component={CfReduxFormRadioGroup}
                name="strategy"
                options={options}
                labelPropertyFactory={(option) => (
                  <FormattedMessage id={`Vrf.${option.value}`} />
                )}
              />
            </Grid>
          </Grid>
        </CfFormControl>
      </form>
    </CfDialog>
  );
};

VariableFertilizerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  expense: PropTypes.object,
};

VariableFertilizerDialog.defaultProps = {
  expense: null,
};

export default compose(
  reduxForm({
    validate,
    form: "vrfExpenseDialog",
  }),
  withStyles(styles),
)(VariableFertilizerDialog);
