import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const ActionsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M28.1,18.5v-6.4c0-1.3-1-2.3-2.3-2.3h-2V4.3h-3.4v5.4h-2.6l-4.4-7.9c-0.4-0.8-1.3-1.3-2.2-1.3H9.7H4.9C3.6,0.6,2.5,1.7,2.5,3v9.1c0,0,0,0,0,0V14c-1.3,1.4-2.1,3.3-2.1,5.4c0,4.4,3.6,8,8,8c3.1,0,5.9-1.8,7.2-4.5H19c0.4,2.5,2.6,4.5,5.2,4.5c2.9,0,5.3-2.4,5.3-5.3C29.6,20.7,29,19.5,28.1,18.5z M8.5,24c-2.5,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6C13.1,21.9,11,24,8.5,24z M24.3,24c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9C26.1,23.2,25.3,24,24.3,24z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { ActionsIcon };
