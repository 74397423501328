import React from "react";

import { FormattedMessage } from "react-intl";

import * as services from "../../../shared/constants/services.constants";

import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import ServiceNotPurchased from "../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import { links } from "../../../shared/constants/links";
import { ParcelsIcon } from "../../../shared/icons/navbar/ParcelsIcon";

import { usePrecisionListStyles } from "./styles";

type Props = {
  langId: string;
};

const NoPrecision: React.FC<Props> = ({ langId }) => {
  const classes = usePrecisionListStyles();

  return (
    <div className={classes.noPrecision}>
      <CfStatusPanel
        customContent={<ServiceNotPurchased serviceId={services.PRECISION} />}
        icon={ParcelsIcon}
        linkText={<FormattedMessage id="common.findOutMore" />}
        testId="no-precision"
        title={<FormattedMessage id="PrecisionFarming.notPurchased.title" />}
        titleWithIcon={true}
        linkHref={
          langId === "cs-CZ"
            ? links.cs.precisionFarming
            : links.en.precisionFarming
        }
      />
    </div>
  );
};

export default NoPrecision;
