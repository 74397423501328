import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ValveOutlinedIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0C2.33625 4.095 0 7.632 0 10.62C0 15.102 3.325 18 7 18C10.675 18 14 15.102 14 10.62C14 7.632 11.6637 4.095 7 0ZM7 16.2C4.06875 16.2 1.75 13.887 1.75 10.62C1.75 8.514 3.45625 5.724 7 2.394C10.5437 5.724 12.25 8.505 12.25 10.62C12.25 13.887 9.93125 16.2 7 16.2Z" />
    </svg>
  </SvgIcon>
);

export default ValveOutlinedIcon;
