import React, { useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingPrecisionParcels,
  getPrecisionParcelsSortedPaginated,
  getTotalCount,
} from "../../../shared/api/sentinel/precision/precision.selectors";
import {
  getPrecisionParcelListOrder,
  getPrecisionParcelListOrderBy,
  getPrecisionParcelListPage,
  getPrecisionParcelListRowsPerPage,
} from "../selectors/precisionParcelList.selectors";

import { setFocusedRow } from "../actions/precision.actions";

import { NAMESPACE as namespace } from "../reducer/precisionParcelList.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../shared/misc/helper";
import { Thunk } from "../../../types";

import PrecisionServiceRow from "./PrecisionServiceRow";

import { ParcelsState } from "../../../reducers/parcels.reducer.types";
import { PrecisionState } from "../../../reducers/precision.reducer.types";
import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

const columns = {
  localName: getColDesc(true, <FormattedMessage id="common.name" />, {
    paddingLeft: 21,
  }),
  blockNr: getColDesc(false, <FormattedMessage id="common.short-code" />, {
    textAlign: "right",
  }),
  area: getColDesc(
    false,
    <span>
      <FormattedMessage id="common.area" /> (ha)
    </span>,
    { textAlign: "right" },
  ),
  services: getColDesc(false, undefined),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
};
type Props = ReduxProps & OwnProps;

export interface PrecisionTableProps {
  count: number;
  farmId: string;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  parcels: PrecisionParcel[];
  rowsPerPage: number;
}

export const PrecisionServicesTable = ({
  count,
  farmId,
  isFetching,
  order,
  orderBy,
  page,
  parcels,
  rowsPerPage,
  setFocusedRow,
}: Props) => {
  useEffect(
    () => () => {
      setFocusedRow(undefined);
    },
    [setFocusedRow],
  );

  return (
    <CfTableWrapper testId="precision-services-list">
      <CfTableHead
        columns={columns}
        items={parcels}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {parcels.length ? (
        <TableBody>
          {parcels.map((parcel) => (
            <PrecisionServiceRow
              farmId={farmId}
              isInit={!isFetching && count > 0}
              key={parcel.id}
              parcel={parcel}
            />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  parcels: getPrecisionParcelsSortedPaginated(state),
  isFetching: getIsFetchingPrecisionParcels(state),
  page: getPrecisionParcelListPage(state),
  order: getPrecisionParcelListOrder(state),
  orderBy: getPrecisionParcelListOrderBy(state),
  rowsPerPage: getPrecisionParcelListRowsPerPage(state),
  count: getTotalCount(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PrecisionServicesTable);
