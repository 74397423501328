import React, { Component } from "react";

import PropTypes from "prop-types";

import SelectionItem from "../../../../../shared/components/common/SelectionItem/SelectionItem";

class PestsList extends Component {
  renderPestItem = (fieldName, index, field) => (
    <SelectionItem
      editing={this.props.editing}
      key={field.id}
      onRemoveItem={() => this.props.onItemRemove(index)}
    >
      <div>{field.name}</div>
    </SelectionItem>
  );

  render() {
    const { fields } = this.props;

    return (
      <div id="pests-list">
        {fields.map((fieldName, index) => {
          const field = fields.get(index);
          return this.renderPestItem(fieldName, index, field);
        })}
      </div>
    );
  }
}

PestsList.propTypes = {
  fields: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  editing: PropTypes.bool,
};

PestsList.defaultProps = {
  editing: false,
};

export default PestsList;
