import { SimplePaletteColorOptions } from "@mui/material";
import { COLORS } from "./shared/components/misc/CfTextBadge/CfTextBadge";
import { Variant } from "./shared/variants/variants";
import loginlogo from "./assets/img/variants/cleverfarm/login-logo.png";
import navbarLogo from "./assets/img/variants/cleverfarm/navbar-logo.svg";
import loginLogoFarmaSense from "./assets/img/variants/farmasense/login-logo.svg";
import navbarLogoFarmaSense from "./assets/img/variants/farmasense/navbar-logo.svg";
import loginLogoMyMex from "./assets/img/variants/mymex/login-logo.svg";
import navbarLogoMyMex from "./assets/img/variants/mymex/navbar-logo.svg";
import faviconCleverFarm from "./assets/img/variants/cleverfarm/favicon.svg";
import faviconFarmasense from "./assets/img/variants/farmasense/favicon.svg";
import faviconMyMex from "./assets/img/variants/mymex/favicon.ico";

type Assets = {
  navbar: {
    src: string;
    width: string;
  };
  login: {
    src: string;
    style: React.CSSProperties;
  };
  favicon: string;
};

type Precision = {
  button: Record<
    "historyPotential" | "biomonitoring" | "soilSamples" | "yieldMaps",
    {
      background: string;
      backgroundHover: string;
    }
  >;
};

declare module "@mui/material/styles" {
  interface Palette {
    avatar: {
      primary: string;
      secondary: string;
    };
  }

  interface PaletteOptions {
    avatar: {
      primary: string;
      secondary: string;
    };
  }

  interface Theme {
    assets: Assets;
    precision: Precision;
  }
}

const PRECISION_SERVICES: Record<Variant, Precision> = {
  cleverfarm: {
    button: {
      historyPotential: {
        background: "#EBA63F",
        backgroundHover: "#E49218",
      },
      biomonitoring: {
        background: "#4CAC84",
        backgroundHover: "#3F8E6D",
      },
      soilSamples: {
        background: "#A16A00",
        backgroundHover: "#734C00",
      },
      yieldMaps: {
        background: "#8100A1",
        backgroundHover: "#5C0073",
      },
    },
  },
  farmasense: {
    button: {
      historyPotential: {
        background: "#EBA63F",
        backgroundHover: "#E49218",
      },
      biomonitoring: {
        background: "#57AB46",
        backgroundHover: "#4A913B",
      },
      soilSamples: {
        background: "#A16A00",
        backgroundHover: "#734C00",
      },
      yieldMaps: {
        background: "#8100A1",
        backgroundHover: "#5C0073",
      },
    },
  },
  mymex: {
    button: {
      historyPotential: {
        background: "#BB1D3D",
        backgroundHover: "#9F1934",
      },
      biomonitoring: {
        background: "#729932",
        backgroundHover: "#587823",
      },
      soilSamples: {
        background: "#A16A00",
        backgroundHover: "#734C00",
      },
      yieldMaps: {
        background: "#8100A1",
        backgroundHover: "#5C0073",
      },
    },
  },
};

// TODO once moved away from webpack, make this part of a build step
const ASSETS: Record<Variant, Assets> = {
  cleverfarm: {
    login: {
      src: loginlogo,
      style: {
        maxWidth: "74px",
        marginTop: 0,
      },
    },
    navbar: {
      src: navbarLogo,
      width: "130px",
    },
    favicon: faviconCleverFarm,
  },
  mymex: {
    login: {
      src: loginLogoMyMex,
      style: {
        marginTop: "16px",
      },
    },
    navbar: {
      src: navbarLogoMyMex,
      width: "134px",
    },
    favicon: faviconMyMex,
  },
  farmasense: {
    login: {
      src: loginLogoFarmaSense,
      style: {
        maxWidth: "230px",
        marginTop: "16px",
      },
    },
    navbar: {
      src: navbarLogoFarmaSense,
      width: "170px",
    },
    favicon: faviconFarmasense,
  },
};

export const COLOR_SECONDARY: Record<Variant, SimplePaletteColorOptions> = {
  cleverfarm: {
    light: "#FFEDB8",
    main: "#EBA607",
    dark: "#E68E27",
  },
  mymex: {
    light: "#BAE6DB",
    main: "#729932",
    dark: "#587823",
  },
  farmasense: {
    light: "#FFEDB8",
    main: "#EBA607",
    dark: "#E68E27",
  },
};

export const COLOR_PRIMARY = {
  cleverfarm: {
    light: "#BAE6DB",
    main: "#00a179",
    dark: "#008267",
  },
  farmasense: {
    light: "#BAE6DB",
    main: "#57AB46",
    dark: "#4A913B",
  },
  mymex: {
    light: "#BAE6DB",
    main: "#729932",
    dark: "#587823",
  },
} as const satisfies Record<Variant, SimplePaletteColorOptions>;

const AVATAR: Record<Variant, Record<"primary" | "secondary", string>> = {
  cleverfarm: {
    primary: COLOR_SECONDARY.cleverfarm.main,
    secondary: COLOR_PRIMARY.cleverfarm.dark,
  },
  farmasense: {
    primary: COLOR_SECONDARY.farmasense.main,
    secondary: COLOR_PRIMARY.farmasense.dark,
  },
  mymex: {
    primary: "#BB1D3D",
    secondary: "#587823",
  },
};

export const COLOR_TIMELINE = {
  interval: COLOR_PRIMARY.cleverfarm.main,
  isEditing: "#B3DBD1",
  handwork: COLORS.VIOLET_PLUM,
  overlap: COLOR_SECONDARY.cleverfarm.main,
};

export const COLOR_ERROR = {
  light: "#FFCDD2",
  main: "#DB4437",
};

export const COLOR_FONT = {
  main: "#333333",
};

//our preferred grey colors to use instead of similar ones defined in default palette
export const COLOR_GREY = {
  100: "#F2F2F2",
  200: "#E0E0E0",
  400: "#ABABAB",
  500: "#848484",
  A100: "#D5D5D5",
};

export const DEFAULT_FONT = {
  fontSize: "0.8125rem",
  fontWeight: 400,
};

export default (version: Variant) => ({
  assets: ASSETS[version],
  precision: PRECISION_SERVICES[version],
  spacing: 8,
  palette: {
    primary: COLOR_PRIMARY[version],
    secondary: COLOR_SECONDARY[version],
    error: COLOR_ERROR,
    grey: {
      ...COLOR_GREY,
    },
    avatar: AVATAR[version],
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard" as const,
      },
    },
    MuiButton: {
      defaultProps: {
        color: "inherit" as const,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard" as const,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: 224,
          "@media (min-width: 600px)": {
            minWidth: 150,
            flexShrink: 1,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          backgroundColor: "#333333",
          margin: "8px 0px !important",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          fontWeight: 400,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 42,
          paddingTop: 9,
          paddingBottom: 9,
        },
        dense: {
          minHeight: 36,
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        dense: {
          minHeight: 36,
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 10,
          color: COLOR_GREY[500],
          minWidth: "unset",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        dense: {
          padding: 0,
          fontSize: "0.875rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "42px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: COLOR_FONT.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px 6px",
          lineHeight: 1.25,
          ...DEFAULT_FONT,
        },
        sizeSmall: {
          padding: "9px 6px",
        },
        paddingCheckbox: {
          padding: "3px",
          paddingLeft: "3px",
        },
        head: {
          lineHeight: 1.25,
          fontWeight: 500,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: COLOR_GREY[500],
          p: {
            ...DEFAULT_FONT,
            margin: 0,
          },
        },
        caption: {
          ...DEFAULT_FONT,
        },
        select: {
          ...DEFAULT_FONT,
        },
        selectIcon: {
          fontSize: "24px",
        },
        actions: {
          ...DEFAULT_FONT,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "12px",
          margin: 0,
        },
      },
    },
  },
});
