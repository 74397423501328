import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getSectionListAdvancedFilter } from "../../../selectors/sectionList.selectors";

import CfFilter from "../../../../shared/containers/CfFilter/CfFilter";

import CropsSelector from "./CropsSelector/CropsSelector";
import SourceSelector from "./SourceSelector/SourceSelector";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  CatalogueTo,
  CropTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";
import { CataloguesAdvancedFilterType } from "../../../reducers/sectionList.types";

export interface Props {
  advancedFilter: CataloguesAdvancedFilterType;
  namespace: string;
}

export const SeedsAdvancedFilter: FC<Props> = ({
  advancedFilter,
  namespace,
}) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] =
    useState<CataloguesAdvancedFilterType>(advancedFilter);

  const handleChangeCrops = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };
  const handleChangeSource = (items: CatalogueTo) => {
    setAdvancedFilterState({ ...advancedFilterState, source: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultValues={advancedFilter?.crops}
              isMultiple
              label={<FormattedMessage id="common.crop" />}
              onChange={handleChangeCrops}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <SourceSelector
              defaultSource={advancedFilter?.source}
              onChange={handleChangeSource}
              label={
                <FormattedMessage id="Catalogues.table.fertilizers.column.source" />
              }
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  advancedFilter: getSectionListAdvancedFilter(state),
});

export default connect(mapStateToProps)(SeedsAdvancedFilter);

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});
