import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field } from "redux-form";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfTextField from "../../../../../shared/components/form/CfTextField/CfTextField";

const styles = {
  lastFormControl: {
    margin: "5px 0px 0px 0px",
  },
};

const ActionNote = (props) => (
  <CfFormControl
    classes={{ formControl: props.isLast ? props.classes.lastFormControl : "" }}
  >
    <Field
      component={CfTextField}
      disabled={props.disabled}
      label={<FormattedMessage id="common.note" />}
      name="actionNote"
    />
  </CfFormControl>
);

ActionNote.propTypes = {
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
};

ActionNote.defaultProps = {
  isLast: false,
};

export default withStyles(styles)(ActionNote);
