import React, { FC, useMemo } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getZones } from "../../../../../shared/api/agroevidence/zones/zones.selectors";

import { getZonesApi } from "../../../../../shared/api/agroevidence/zones/zones.api";
import withPopover from "../../../../../shared/hocs/withPopover";
import { Thunk } from "../../../../../types";

import ZoneAutosuggest from "./ZoneAutosuggest";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import { ZoneTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

export interface ParcelZoneAssignProps {
  getZonesApi: (search: string) => void;
  handlePopoverClose: () => void;
  onZoneChange: (zoneId: string, parcelId?: string) => void;
  suggestions: ZoneTo[];
}

const ParcelZoneAssign: FC<ParcelZoneAssignProps> = ({
  getZonesApi,
  handlePopoverClose,
  onZoneChange,
  suggestions,
}) => {
  const extendedeSuggestions = useMemo(() => [...suggestions], [suggestions]);

  const onSuggestionSelect = (suggestion: ZoneTo) => {
    onZoneChange(suggestion.id);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
  };

  return (
    <ZoneAutosuggest
      getSuggestions={getZonesApi}
      onSuggestionClear={onSuggestionClear}
      onSuggestionSelect={onSuggestionSelect}
      suggestions={extendedeSuggestions}
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getZones(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getZonesApi,
    },
    dispatch,
  );

export default withPopover(
  connect(mapStateToProps, mapDispatchToProps)(ParcelZoneAssign),
);
