import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { StoresState } from "../../../../../reducers/stores.reducer.types";

export const getSeeds = (state: ActionsState | StoresState | CataloguesState) =>
  state.api.agroevidence.seeds.items;
export const getIsFetchingSeeds = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.seeds.isFetching;
export const getSeedsCount = (state: CataloguesState) =>
  state.api.agroevidence.seeds.totalCount;
