import { throwNamespaceError } from "./common.actions";

import * as types from "./filter.constants";

export const setTextFilter = (textFilter, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TEXT_FILTER,
    textFilter,
    namespace,
  };
};

export const setAdvancedFilter = (advancedFilter, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_ADVANCED_FILTER,
    advancedFilter,
    namespace,
  };
};
