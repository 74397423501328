import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";

import icon from "../../../assets/img/sensor_pin.svg";

export default class Marker {
  constructor(map, transformOptions) {
    this.map = map;
    this.transformOptions = transformOptions;
  }

  mapAddMarker(coords) {
    const marker = new Feature({
      geometry: new Point(
        fromLonLat(
          [coords.lon, coords.lat],
          this.transformOptions.featureProjection,
        ),
      ),
    });

    const style = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: icon,
      }),
    });

    marker.setStyle(style);

    this.setLayer("search_coord", marker);
  }

  mapRemoveMarker() {
    this.removeLayer("search_coord");
  }

  setLayer(iaId, marker) {
    this[`layer_${iaId}`] = new VectorLayer({
      source: new VectorSource({
        features: [marker],
      }),
    });
    this.map.addLayer(this[`layer_${iaId}`]);
  }

  removeLayer(iaId) {
    this.map.removeLayer(this[`layer_${iaId}`]);
    this[`layer_${iaId}`] = null;
  }
}
