import React from "react";

import PropTypes from "prop-types";

import * as aggregations from "../../../shared/constants/aggregations.constants";

import { FEATURE_TYPES } from "../../services/FeatureConfig.service";

const CustomizedDot = (props) => {
  const { color, cx, cy, payload } = props;
  return (
    <svg height={12} viewBox="0 0 24 24" width={12} x={cx - 6} y={cy - 12}>
      <path
        d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"
        fill={color}
        transform={`rotate(${
          payload[
            `${FEATURE_TYPES.FEATURE_WIND_DIRECTION}_${aggregations.MAX_COUNT}`
          ] * 45
        } 12 12)`}
      />
    </svg>
  );
};

CustomizedDot.propTypes = {
  cx: PropTypes.any,
  cy: PropTypes.any,
  payload: PropTypes.any,
  color: PropTypes.any,
};

CustomizedDot.defaultProps = {
  cx: null,
  cy: null,
  payload: {},
  color: "",
};

export default CustomizedDot;
