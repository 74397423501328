import React from "react";

import { Box } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import "./MapStyles.css";

import { getLayersConfig } from "../../../../map/selectors/layers.selectors";
import {
  selectAttributeUnit,
  selectIsFetchingPoints,
  selectMapData,
  selectExtent,
  selectMinMaxValues,
  selectHighlightPoint,
  selectCurrentAttribute,
} from "../../../selectors/asAppliedDetail.selectors";

import { storeInitialLayers } from "../../../../map/actions/layersUI/layersUI.actions";
import {
  resetMap,
  setMapGrabEL,
  storeServiceWrapper,
} from "../../../../map/actions/map/map.actions";

import * as types from "../../../../../shared/api/sentinel/precision/precision.constants";

import { pointReset } from "../../../reducer/precisionAsAppliedDetail.reducer";

import { getLpisLayerName } from "../../../../../shared/api/api.helpers";
import {
  fetchLayersConfig,
  resetLayers,
} from "../../../../../shared/api/other/layers/layers.api";
import { getPrecisionTaskDataTimelineApi } from "../../../../../shared/api/sentinel/precision/precision.api";
import SectionWrapper from "../../../../../shared/components/specific/SectionWrapper/SectionWrapper";
import withConfig from "../../../../../shared/hocs/context/withConfig";
import { withFarm } from "../../../../../shared/hocs/context/withFarm";
import { useIsMobile } from "../../../../../shared/hooks/useWidth";
import { transformOptions } from "../../../../../shared/misc/map.helpers";
import {
  PRIVATE_LPIS_CONFIG,
  PUBLIC_LPIS_CONFIG,
} from "../../../../../shared/services/LayersConfig.service";
import { Thunk } from "../../../../../types";
import HomeControl from "../../../../map/components/HomeControl/HomeControl";
import LayersCropsControl from "../../../../map/components/LayersCropsControl/LayersCropsControl";
import EventListener from "../../../../map/services/EventListener.service";
import Layers from "../../../../map/services/Layers.service";
import MapService from "../../../../map/services/Map.service";
import Style from "../../../../map/services/Style.service";

import {
  AttributeGradient,
  AttributeInterval,
  LoadingAttribute,
  NoAttributeValue,
} from "./AttributeInterval";
import { ClickOverlay } from "./MapClickOverlay";
import { HighlightLayer } from "./MapHighlightLayer";
import { getAsAppliedLayer } from "./utils";

import { PrecisionState } from "../../../../../reducers/precision.reducer.types";
import { DeprecatedFarmTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = React.PropsWithChildren<{
  config: {
    api: {
      bing_key: string;
      geoserverUrl: string;
      geoserverWorkspaceCore: string;
      lpisLayerName: string;
    };
    environment: string;
  };
  farm: DeprecatedFarmTo;
  onResize?: (map: MapService) => void;
}>;

const MAP_ID = "as-applied-detail-map";

const _Map = ({
  attribute,
  attributeUnit,
  children,
  config,
  data: pointLayer,
  dataInterval,
  extent,
  farm,
  fetchLayersConfig,
  getPrecisionTaskDataTimelineApi,
  highlight,
  isFetching,
  layersConfig,
  onResize,
  resetHighlight,
  resetLayers,
  resetMap,
  resetTaskDataTimeline,
  setMapGrabEL,
  storeInitialLayers,
  storeServiceWrapper,
}: OwnProps & ReduxProps) => {
  const { taskDataId, taskId } =
    useParams<Record<"farmId" | "taskDataId" | "taskId", string>>();
  const [map, setMap] = React.useState<MapService | undefined>();
  const initialized = React.useRef(false);
  const mapInstance = map?.getMap();
  const isMobile = useIsMobile();

  if (map) {
    if (pointLayer && mapInstance && !getAsAppliedLayer(mapInstance)) {
      mapInstance.addLayer(pointLayer);
    }
  }

  const zoomToLocation = React.useCallback(() => {
    if (extent) {
      map?.zoomToExtent(extent);
    } else {
      map?.zoomToFarm();
    }
  }, [map, extent]);

  React.useEffect(() => {
    if (!attribute?.attribute) {
      return;
    }

    getPrecisionTaskDataTimelineApi(taskDataId, taskId, attribute.attribute);
  }, [attribute, getPrecisionTaskDataTimelineApi, taskDataId, taskId]);

  React.useEffect(
    () => () => {
      resetTaskDataTimeline();
      resetHighlight();

      if (!mapInstance) {
        return;
      }

      const asAppliedLayer = getAsAppliedLayer(mapInstance);

      if (mapInstance && asAppliedLayer) {
        mapInstance.removeLayer(asAppliedLayer);
        asAppliedLayer.dispose(); // hopefully dispose the webgl renderer context as well
      }
    },
    [attribute, resetTaskDataTimeline, mapInstance, resetHighlight],
  );

  React.useEffect(() => {
    if (!map) {
      return;
    }

    onResize?.(map);
  }, [map, onResize]);

  React.useEffect(() => {
    if (!(map && extent)) {
      return;
    }

    if (initialized.current) {
      return;
    }

    zoomToLocation();
    initialized.current = true;
  }, [map, extent, zoomToLocation]);

  // clean old stored values and create new 'map' and 'el' on mount
  React.useEffect(() => {
    if (!map) {
      storeServiceWrapper("main", undefined);
      storeServiceWrapper("el", undefined);
      storeServiceWrapper("layers", undefined);
      storeServiceWrapper("style", undefined);

      resetMap();
      resetLayers();

      setMap(
        () =>
          new MapService(MAP_ID, farm.id, farm.boundingBox, transformOptions),
      );

      return;
    }

    fetchLayersConfig(farm.customer.countryCode);
    const el = new EventListener(map.getMap());
    storeServiceWrapper("main", map);
    storeServiceWrapper("el", el);

    return () => {
      setMap(undefined);
    };
  }, [
    farm.boundingBox,
    farm.customer.countryCode,
    farm.id,
    fetchLayersConfig,
    map,
    resetLayers,
    resetMap,
    storeServiceWrapper,
  ]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    map.zoomToFarm();
  }, [map]);

  React.useEffect(() => {
    if (!layersConfig || !map) {
      return;
    }

    const updatedConfig = { ...config };
    updatedConfig.api.lpisLayerName = getLpisLayerName(
      farm.customer.countryCode,
      config.environment,
    );

    const layers = new Layers(
      map.getMap(),
      updatedConfig.api,
      farm.id,
      map.getFarmExtent(),
    );

    // @ts-expect-error err
    const extendedNewLayersConfig = layersConfig.map((c) => {
      if (c.layerId === PUBLIC_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      if (c.layerId === PRIVATE_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      return c;
    });
    layers.setInitialLayers(extendedNewLayersConfig, storeInitialLayers);

    const style = new Style(
      layers.getParcelLayer(),
      layers.getParcelLabelLayer(),
      farm.customer.countryCode,
    );

    storeServiceWrapper("layers", layers);
    storeServiceWrapper("style", style);

    setMapGrabEL();
  }, [
    config,
    farm.customer.countryCode,
    farm.id,
    layersConfig,
    map,
    setMapGrabEL,
    storeInitialLayers,
    storeServiceWrapper,
  ]);

  let AttributeControlJsx = <LoadingAttribute />;

  if (!isFetching) {
    if (dataInterval && attributeUnit) {
      AttributeControlJsx = (
        <AttributeInterval
          max={dataInterval[1]}
          min={dataInterval[0]}
          unit={attributeUnit}
        >
          <AttributeGradient />
        </AttributeInterval>
      );
    } else {
      AttributeControlJsx = <NoAttributeValue />;
    }
  }

  return (
    <Box
      className={isMobile ? "mobile" : undefined}
      height="100%"
      id={MAP_ID}
      position="relative"
      sx={{ backgroundColor: "grey" }}
    >
      <HomeControl
        sx={{ bottom: isMobile ? undefined : "275px", right: "14px" }}
        zoomToHome={zoomToLocation}
      />
      <SectionWrapper left={14} top={17}>
        <LayersCropsControl withCrops={false} />
      </SectionWrapper>
      <SectionWrapper right={14} top={17}>
        {AttributeControlJsx}
      </SectionWrapper>
      {children}
      <ClickOverlay map={mapInstance} />
      <HighlightLayer highlight={highlight} map={mapInstance} />
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      fetchLayersConfig,
      getPrecisionTaskDataTimelineApi,
      resetLayers,
      resetMap,
      setMapGrabEL,
      storeInitialLayers,
      storeServiceWrapper,
      resetTaskDataTimeline: () =>
        dispatch({
          type: types.getStates("GET_PRECISION_TASK_TIMELINE_DATA").RESET,
        }),
      resetHighlight: () => dispatch(pointReset),
    },
    dispatch,
  );

const mapStateToProps = (state: PrecisionState) => ({
  attribute: selectCurrentAttribute(state),
  layersConfig: getLayersConfig(state),
  data: selectMapData(state),
  dataInterval: selectMinMaxValues(state),
  attributeUnit: selectAttributeUnit(state),
  extent: selectExtent(state),
  isFetching: selectIsFetchingPoints(state),
  highlight: selectHighlightPoint(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const _MemoizedMap = React.memo(
  _Map,
  (prev, next) =>
    prev.data?.getSource()?.getFeatures().length ===
      next.data?.getSource()?.getFeatures().length &&
    prev.attribute === next.attribute &&
    prev.highlight === next.highlight &&
    prev.isFetching === next.isFetching,
);
const MapWithFarm = withFarm(_MemoizedMap);
const Map = connector(withConfig()(MapWithFarm));

export { Map };
