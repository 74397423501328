import React, { useEffect, ReactChild } from "react";

import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getInactiveDevices,
  getIsFetchingInactiveDevices,
} from "../../../../shared/api/irrigation/devices/devices.selectors";
import {
  getCreateOrEditAreaOrder,
  getCreateOrEditAreaOrderBy,
  getCreateOrEditAreaSelectedDevices,
  getCreateOrEditAreaDevicesToEdit,
  getCreateOrEditAreaIsFormTouched,
} from "../../../selectors/createOrEditArea.selectors";

import {
  setDevicesToEdit,
  fetchInactiveDevices,
  sortDevices,
} from "../../../actions/createOrEditArea.actions";

import { CREATE_OR_EDIT_AREA_NAMESPACE } from "../../../reducer/createOrEditArea.reducer";

import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import usePrevious from "../../../../shared/hooks/usePrevious";
import { Thunk } from "../../../../types";
import useAvailableDevicesStyles from "../styles/useAvailableDevices.styles";

import DeviceRow from "./DeviceRow";

import { IrrigationState } from "../../../../reducers/irrigation.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  validationError?: boolean;
  validationMessage?: ReactChild;
};
type Props = OwnProps & ReduxProps;

const NameColumn = () => {
  const classes = useAvailableDevicesStyles();

  return (
    <div className={classes.nameCol}>
      <p>
        <FormattedMessage id="Irrigation.createArea.form.device" />
      </p>
      <p>ID</p>
    </div>
  );
};

const columns = {
  name: {
    sortable: true,
    label: <NameColumn />,
    superRightPadding: true,
  },
  coordinates: {
    sortable: false,
    label: <FormattedMessage id="Irrigation.createArea.form.coordinates" />,
  },
};

const AvailableDevicesList = ({
  devices,
  devicesToEdit,
  fetchDevicesApi,
  isDevicesApiLoading,
  isFormTouched,
  order,
  orderBy,
  selected,
  setDevicesToEdit,
  sortDevices,
  validationError = false,
  validationMessage,
}: Props) => {
  const classes = useAvailableDevicesStyles();
  const prevIsDevicesApiLoading = usePrevious(isDevicesApiLoading);
  const hasDevicesBeenJustFetchedFromApi =
    prevIsDevicesApiLoading && !isDevicesApiLoading;

  useEffect(() => {
    if (hasDevicesBeenJustFetchedFromApi && !isFormTouched) {
      setDevicesToEdit(devices);
    }
  }, [
    devices,
    hasDevicesBeenJustFetchedFromApi,
    isFormTouched,
    setDevicesToEdit,
  ]);

  useEffect(() => {
    // avoid re-fetching when selecting from map on mobile (component is unmounted --> mounted again)
    if (isFormTouched) return;
    fetchDevicesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortDevices(order);
  }, [order, sortDevices]);

  const hasNoDevices = !devicesToEdit.length;

  return (
    <Box className={classes.container}>
      <Box className={classes.claims}>
        <Typography variant="subtitle2">
          <FormattedMessage id="Irrigation.createArea.claim" />
        </Typography>
        {!validationError && (
          <Typography variant="caption">
            <FormattedMessage id="Irrigation.createArea.subclaim" />
          </Typography>
        )}
        {validationError && (
          <Typography className={classes.validationError} variant="caption">
            {validationMessage}
          </Typography>
        )}
      </Box>
      <Box className={classes.table}>
        <CfTableWrapper minWidth={0} testId="table">
          <CfTableHead
            columns={columns}
            items={devicesToEdit}
            namespace={CREATE_OR_EDIT_AREA_NAMESPACE}
            order={order}
            orderBy={orderBy}
            selected={selected}
            selectedOnPage={selected}
          />
          {isDevicesApiLoading && <CfTableBodyLoader columns={columns} />}
          {!isDevicesApiLoading && hasNoDevices && (
            <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
          )}
          {!isDevicesApiLoading && (
            <TableBody>
              {devicesToEdit.map((device) => (
                <DeviceRow device={device} key={device.id} />
              ))}
            </TableBody>
          )}
        </CfTableWrapper>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  devices: getInactiveDevices(state),
  isDevicesApiLoading: getIsFetchingInactiveDevices(state),
  order: getCreateOrEditAreaOrder(state),
  orderBy: getCreateOrEditAreaOrderBy(state),
  selected: getCreateOrEditAreaSelectedDevices(state),
  devicesToEdit: getCreateOrEditAreaDevicesToEdit(state),
  isFormTouched: getCreateOrEditAreaIsFormTouched(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      fetchDevicesApi: fetchInactiveDevices,
      setDevicesToEdit,
      sortDevices,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AvailableDevicesList);
