import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field } from "redux-form";

import CfDatePicker from "../../../../../shared/components/common/CfDatePicker/CfDatePicker";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import * as validators from "../../../../../shared/misc/validators";

const ActionDate = (props) => (
  <CfFormControl>
    <Field
      component={CfDatePicker}
      disabled={props.disabled}
      label={<FormattedMessage id="action.applicationDate" />}
      name="actionDate"
      propagateInvalidDate
      validate={[validators.required, validators.isValidDate]}
    />
  </CfFormControl>
);

ActionDate.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default ActionDate;
