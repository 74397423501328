import React, { FC } from "react";

import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  cell: {
    width: 90,
    border: "1px solid",
    textAlign: "center",
  },
  firstCell: {
    width: 120,
    textAlign: "left",
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));

interface TableBodyRowProps {
  index: number;
  item: {
    compliant: string | null;
    good: string;
    high: string | null;
    low: string;
    name: string;
    veryHigh: string;
  };
}

const TableBodyRow: FC<TableBodyRowProps> = ({ index, item }) => {
  const classes = useStyles();
  const { compliant, good, high, low, name, veryHigh } = item;

  return (
    <TableRow key={index}>
      <TableCell
        classes={{ root: classNames(classes.cell, classes.firstCell) }}
      >
        <p className={classes.text}>{name}</p>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>{low}</TableCell>
      {compliant && (
        <TableCell classes={{ root: classes.cell }}>{compliant}</TableCell>
      )}
      <TableCell classes={{ root: classes.cell }}>{good}</TableCell>
      {high && <TableCell classes={{ root: classes.cell }}>{high}</TableCell>}
      <TableCell classes={{ root: classes.cell }}>{veryHigh}</TableCell>
    </TableRow>
  );
};

export default TableBodyRow;
