import React, { useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getLocationsAdvancedFilter,
  getLocationsHistoryFilter,
  getLocationsTextFilter,
} from "../../selectors/locations.selectors";
import { getNodesStatistics } from "../../selectors/nodes.selectors";

import { setAdvancedFilter } from "../../../shared/actions/filter.actions";
import { setHistoryFilter } from "../../actions/locations.actions";

import { NAMESPACE as namespace } from "../../reducer/locations.reducer";

import {
  createGroupApi,
  updateGroupApi,
  deleteGroupApi,
} from "../../../shared/api/iot/groups/groups.api";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { NodeLocationsHistoryFilter } from "../../components/NodeLocationsHistoryFilter/NodeLocationsHistoryFilter";
import NodeGroups from "../NodeGroups/NodeGroups";
import { NodeLocationsAdvancedFilter } from "../NodeLocationsAdvancedFilter/NodeLocationsAdvancedFilter";
import NodeLocationsTable from "../NodeLocationsTable/NodeLocationsTable";

export const NodeLocationsList = ({
  advancedFilter,
  createGroupApi,
  deleteGroupApi,
  farmId,
  historyFilter,
  isMapDisplayed = true,
  langId,
  resetHoveredAndSelectedFeatures,
  setAdvancedFilter,
  setHistoryFilter,
  statistics,
  textFilter,
  updateGroupApi,
  zoomToFarm,
}) => {
  const classes = useStyles();
  const isHistoryActive = historyFilter.historic;
  const prevIsHistoryActive = useRef(isHistoryActive);

  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    zoomToFarm();
    resetHoveredAndSelectedFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevIsHistoryActive.current !== isHistoryActive && isHistoryActive) {
      setAdvancedFilter({ ...advancedFilter, status: [] }, namespace);
    }
    prevIsHistoryActive.current = isHistoryActive;
  }, [advancedFilter, isHistoryActive, setAdvancedFilter]);

  const onCreateGroup = (newGroup) => {
    const params = {
      name: newGroup.name,
      farmId,
    };
    createGroupApi(params).then(() =>
      setShouldReloadData((prevState) => !prevState),
    );
  };

  const onUpdateGroup = (groupToUpdate) => {
    const params = {
      name: groupToUpdate.name,
    };
    updateGroupApi(groupToUpdate.id, params).then(() => {
      if (advancedFilter.groups) {
        const updatedGroups = advancedFilter.groups.map((group) => {
          if (group.id === groupToUpdate.id) {
            return { ...group, ...groupToUpdate };
          }
          return group;
        });
        setAdvancedFilter(
          { ...advancedFilter, groups: updatedGroups },
          namespace,
        );
      }
      setShouldReloadData((prevState) => !prevState);
    });
  };

  const onDeleteGroup = (groupToDelete) => {
    deleteGroupApi(groupToDelete.id).then(() => {
      if (advancedFilter.groups) {
        const updatedGroups = advancedFilter.groups.filter(
          (group) => group.id !== groupToDelete.id,
        );
        setAdvancedFilter(
          { ...advancedFilter, groups: updatedGroups },
          namespace,
        );
      }
      setShouldReloadData((prevState) => !prevState);
    });
  };

  const onFilterGroupInSensors = (groups) => {
    setAdvancedFilter({ groups }, namespace);
  };

  return (
    <div className={classes.wrapper}>
      <PageHeader
        classes={{ header: classes.header }}
        heading={
          <PageHeading
            dataTest="node-locations-list-heading"
            value={<FormattedMessage id="common.sensors" />}
          />
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className={classes.filtersHeader} item xs={12}>
            <div className={classes.textFilter}>
              <CfTextFilter
                initialValue={textFilter}
                name="node-locations-text-filter"
                namespace={namespace}
                translId="NodeLocationsList.search"
              />
            </div>
            <div className={classes.advancedFilter}>
              <NodeLocationsAdvancedFilter
                advancedFilter={advancedFilter}
                isHistoryActive={historyFilter.historic}
                langId={langId}
                namespace={namespace}
              />
            </div>
            <div className={classes.historyFilter}>
              <NodeLocationsHistoryFilter
                historyFilter={historyFilter}
                setHistoryFilter={setHistoryFilter}
                statistics={statistics}
              />
            </div>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item lg={9} md={isMapDisplayed ? 12 : 9} xs={12}>
              <NodeLocationsTable
                advancedFilter={advancedFilter}
                farmId={farmId}
                langId={langId}
                shouldReloadData={shouldReloadData}
              />
            </Grid>
            <Grid item lg={3} md={isMapDisplayed ? 12 : 3} xs={12}>
              <NodeGroups
                farmId={farmId}
                langId={langId}
                onCreateGroup={onCreateGroup}
                onDeleteGroup={onDeleteGroup}
                onFilterGroupInSensors={onFilterGroupInSensors}
                onUpdateGroup={onUpdateGroup}
                shouldReloadData={shouldReloadData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

NodeLocationsList.propTypes = {
  zoomToFarm: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  isMapDisplayed: PropTypes.bool,
  resetHoveredAndSelectedFeatures: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  historyFilter: PropTypes.object.isRequired,
  textFilter: PropTypes.string.isRequired,
  setHistoryFilter: PropTypes.func.isRequired,
  statistics: PropTypes.object.isRequired,
  advancedFilter: PropTypes.object.isRequired,
  setAdvancedFilter: PropTypes.func.isRequired,
  updateGroupApi: PropTypes.func.isRequired,
  deleteGroupApi: PropTypes.func.isRequired,
  createGroupApi: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  historyFilter: getLocationsHistoryFilter(state),
  textFilter: getLocationsTextFilter(state),
  statistics: getNodesStatistics(state),
  advancedFilter: getLocationsAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setHistoryFilter,
      setAdvancedFilter,
      createGroupApi,
      deleteGroupApi,
      updateGroupApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NodeLocationsList);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  filtersHeader: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      alignItems: "start",
    },
  },
  textFilter: {
    maxWidth: 400,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  advancedFilter: {
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  historyFilter: {
    marginLeft: 24,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));
