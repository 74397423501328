import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { COLORS } from "../../../shared/components/misc/CfTextBadge/CfTextBadge";
import { COLOR_TIMELINE } from "../../../theme";

export const useTelematicsAggregationDetailContentStyles = makeStyles(
  (theme: Theme) => ({
    badge: {
      height: 12,
      width: 12,
      backgroundColor: COLORS.GREY,
      marginTop: 13,
      marginRight: -2,
      fontSize: 8,
      minWidth: 0,
    },
    container: {
      width: "100%",
    },
    intervals: {
      margin: "6px 0",
    },
    intervalsHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: 8,
      lineHeight: 1,
    },
    intervalsHeaderLabel: {
      fontSize: 14,
      color: theme.palette.grey[500],
    },
    intervalsHeaderLabelNew: {
      fontSize: 14,
      color: COLOR_TIMELINE.isEditing,
    },
    intervalsTime: {
      color: theme.palette.grey[500],
    },
    activeIntervalsTime: {
      color: theme.palette.primary.main,
    },
    buttons: {
      marginTop: theme.spacing(4),
    },
    fullWidth: {
      width: "100%",
    },
    fieldWrapper: {
      display: "flex",
      alignItems: "center",
    },
    externalDriver: {
      fontSize: "13px",
      marginTop: "-22px",
      marginBottom: "25px",
    },
    externalEquipment: {
      marginTop: "3px",
      fontSize: "13px",
    },
    parcelFieldWrapper: {
      alignItems: "flex-end",
      marginBottom: theme.spacing(4),
    },
    parcelValidationMessage: {
      fontSize: 13,
      marginTop: `-${theme.spacing(3)}`,
      color: theme.palette.error.main,
    },
    warningIcon: {
      marginRight: 8,
    },
    intervalWarningIcon: {
      textAlign: "center",
      alignSelf: "center",
    },
    box: {
      width: "80%",
      margin: "12px auto",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      padding: 16,
    },
    sectionHeading: {
      fontSize: 18,
      fontWeight: 400,
      marginTop: 6,
    },
    postponedLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.grey[400],
    },
    editBox: {
      textAlign: "right",
    },
    heading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    headerWrapper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    checkboxLabel: {
      display: "flex",
      justifyContent: "center",
    },
    sentToTosFormControl: {
      color: theme.palette.primary.main,
      cursor: "default",
      marginLeft: theme.spacing(2),
    },
    formControl: {
      marginLeft: 0,
    },
    checkbox: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    linkArrowsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    linkArrowsTextContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    linkArrowsText: {
      color: theme.palette.grey[500],
      fontSize: 12,
      textAlign: "center",
      marginBottom: 6,
    },
    linkArrowsTextExternalDriver: {
      color: theme.palette.secondary.main,
    },
    timeToField: {
      marginRight: "auto",
      marginLeft: theme.spacing(2),
    },
    headerActionButtons: {
      display: "flex",
      justifyContent: "space-between",
    },
    timelineNotification: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: 8,
      marginBottom: 16,
    },
    timelineNotificationText: {
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#9B9B9B",
      marginTop: 5,
      marginLeft: 4,
    },
  }),
);
