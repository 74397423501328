export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";
export const RESET_LOCATIONS = "RESET_LOCATIONS";

export const GET_LOCATIONS_POINTS = "GET_LOCATIONS_POINTS";
export const GET_LOCATIONS_POINTS_SUCCESS = "GET_LOCATIONS_POINTS_SUCCESS";
export const GET_LOCATIONS_POINTS_ERROR = "GET_LOCATIONS_POINTS_ERROR";
export const RESET_LOCATIONS_POINTS = "RESET_LOCATIONS_POINTS";

export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";
export const RESET_LOCATION = "RESET_LOCATION";

export const PATCH_LOCATION = "PATCH_LOCATION";
export const PATCH_LOCATION_SUCCESS = "PATCH_LOCATION_SUCCESS";
export const PATCH_LOCATION_ERROR = "PATCH_LOCATION_ERROR";

export const REMOVE_LOCATION = "REMOVE_LOCATION";

export const EXPORT_LOCATION = "EXPORT_LOCATION";
export const EXPORT_LOCATION_SUCCESS = "EXPORT_LOCATION_SUCCESS";
export const EXPORT_LOCATION_ERROR = "EXPORT_LOCATION_ERROR";

export const GET_LOCATION_PREDICTIONS = "GET_LOCATION_PREDICTIONS";
export const GET_LOCATION_PREDICTIONS_SUCCESS =
  "GET_LOCATION_PREDICTIONS_SUCCESS";
export const GET_LOCATION_PREDICTIONS_ERROR = "GET_LOCATION_PREDICTIONS_ERROR";

export const GET_PREDICTION_DATA = "GET_PREDICTION_DATA";
export const GET_PREDICTION_DATA_SUCCESS = "GET_PREDICTION_DATA_SUCCESS";
export const GET_PREDICTION_DATA_ERROR = "GET_PREDICTION_DATA_ERROR";
export const RESET_PREDICTION_DATA = "RESET_PREDICTION_DATA";

export const PUT_LOCATION = "PUT_LOCATION";
export const PUT_LOCATION_SUCCESS = "PUT_LOCATION_SUCCESS";
export const PUT_LOCATION_ERROR = "PUT_LOCATION_ERROR";

export const GET_EVAPOTRANSPIRATION_DATA_REQUEST =
  "GET_EVAPOTRANSPIRATION_DATA_REQUEST";
export const GET_EVAPOTRANSPIRATION_DATA_SUCCESS =
  "GET_EVAPOTRANSPIRATION_DATA_SUCCESS";
export const GET_EVAPOTRANSPIRATION_DATA_ERROR =
  "GET_EVAPOTRANSPIRATION_DATA_ERROR";
export const RESET_EVAPOTRANSPIRATION_DATA = "RESET_EVAPOTRANSPIRATION_DATA";
