import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
  },
  header: {
    color: theme.palette.grey[500],
  },
  box: {
    width: "100%",
    height: 40,
    backgroundColor: theme.palette.grey[100],
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
  },
}));

const EmptyParcelBox = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p className={classes.header}>
        <FormattedMessage id="TelematicsAggregations.detail.noparcel.heading" />{" "}
      </p>
      <div className={classes.box}>
        <FormattedMessage id="TelematicsAggregations.detail.noparcel.text" />{" "}
      </div>
    </div>
  );
};

export default EmptyParcelBox;
