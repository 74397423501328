import React, { Component } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetching,
  getTotalCount,
} from "../../../../../shared/api/stores/stores/stores.selectors";
import {
  getStoreItems,
  getStoresMaterialType,
  getStoresShowNullValues,
  getStoresTextFilter,
  getStoresPage,
  getStoresOrder,
  getStoresRowsPerPage,
} from "../../selectors/stores.selectors";

import { fetchStores } from "../../actions/stores.actions";

import { NAMESPACE as namespace } from "../../reducer/stores.reducer";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableIconButton from "../../../../../shared/components/tables/CfTableIconButton/CfTableIconButton";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../../shared/misc/helper";

const columns = {
  material: getColDesc(
    true,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="Stores.material-name" />
    </span>,
    { width: "44%" },
  ),
  availableCount: getColDesc(
    false,
    <FormattedMessage id="Stores.current-amount" />,
    { textAlign: "right", width: "16%" },
    undefined,
    true,
  ),
  unit: getColDesc(false, <FormattedMessage id="common.unit" />, {
    textAlign: "right",
    width: "8%",
  }),
  planned: getColDesc(
    false,
    <FormattedMessage id="Stores.planned-to-apply" />,
    { textAlign: "right", width: "16%" },
    undefined,
    true,
  ),
  change: getColDesc(
    false,
    <div style={{ padding: "0 5px", textAlign: "center" }}>
      <FormattedMessage id="Stores.add-remove-amount" />
    </div>,
    { width: "8%" },
  ),
  delete: getColDesc(
    false,
    <div style={{ padding: "0 5px", textAlign: "center" }}>
      <FormattedMessage id="common.delete" />
    </div>,
    { width: "8%" },
  ),
};

const styles = (theme) => ({
  deleteIcon: {
    color: theme.palette.action.active,
  },
  updateIcon: {
    color: theme.palette.action.active,
  },
  name: {
    fontWeight: 500,
    paddingLeft: 15,
  },
  pointer: {
    cursor: "pointer",
  },
});

export class StoresTable extends Component {
  componentDidMount() {
    this.fetchStores();
  }

  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      "page",
      "rowsPerPage",
      "materialTypeId",
      "showNullValues",
      "order",
      "textFilter",
    ]);
    const newProps = pick(this.props, [
      "page",
      "rowsPerPage",
      "materialTypeId",
      "showNullValues",
      "order",
      "textFilter",
    ]);

    if (!isEqual(newProps, oldProps)) {
      this.fetchStores();
    }
  }

  fetchStores() {
    this.props.fetchStores();
  }

  render() {
    const {
      classes,
      count,
      farmId,
      history,
      isFetching,
      items,
      langId,
      onDeleteStoreClick,
      onUpdateStoreClick,
      order,
      page,
      rowsPerPage,
    } = this.props;
    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          count={items.length}
          langId={langId}
          namespace={namespace}
          order={order}
          orderBy="material"
          selectable={false}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {items.length ? (
          <TableBody>
            {items.map((item) => (
              <TableRow
                className={classes.pointer}
                hover
                key={item.id}
                onClick={() => history.push(`/farm/${farmId}/store/${item.id}`)}
              >
                <CfTableCell name="material">
                  <span className={classes.name}>{item.material.name}</span>
                </CfTableCell>
                <CfTableCell name="availableCount">
                  <CfTableCellNumber
                    negative={item.currentBalance < 0}
                    padding={true}
                  >
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.currentBalance}
                    />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="unit">
                  <CfTableCellNumber>
                    <FormattedMessage id={`unit.${item.unitId}`} />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="planned">
                  <CfTableCellNumber
                    negative={item.plannedConsumption < 0}
                    padding={true}
                  >
                    <CfFormattedNumber value={item.plannedConsumption} />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableIconButton
                  name="change"
                  onClick={(evt) => {
                    onUpdateStoreClick(item);
                    evt.stopPropagation();
                  }}
                >
                  <AddCircleIcon className={classes.updateIcon} />
                </CfTableIconButton>
                <CfTableIconButton
                  name="delete"
                  onClick={(evt) => {
                    onDeleteStoreClick(item);
                    evt.stopPropagation();
                  }}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </CfTableIconButton>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          langId={langId}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    );
  }
}

StoresTable.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  fetchStores: PropTypes.func.isRequired,
  onUpdateStoreClick: PropTypes.func.isRequired,
  onDeleteStoreClick: PropTypes.func.isRequired,
};

StoresTable.defaultProps = {
  materialTypeId: null,
  showNullValues: false,
  textFilter: "",
};

const mapStateToProps = (state) => ({
  items: getStoreItems(state),
  materialTypeId: getStoresMaterialType(state),
  showNullValues: getStoresShowNullValues(state),
  textFilter: getStoresTextFilter(state),
  isFetching: getIsFetching(state),
  count: getTotalCount(state),
  page: getStoresPage(state),
  order: getStoresOrder(state),
  rowsPerPage: getStoresRowsPerPage(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStores,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(StoresTable));
