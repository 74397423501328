import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import Scrollbars from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";

import CfStaticMap from "../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { COLOR_ERROR, COLOR_GREY } from "../../../theme";
import MapService from "../../map/services/Map.service";

import {
  FarmUpdateFlatResponse,
  GeoJson,
} from "../../../shared/api/agroevidence/agroevidence.types";

// errors short codes types
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LpisErrorShortCodes =
  | "parcel-deletion"
  | "invalid-eagri-geometry"
  | "lpis-block-overlap-too-large"
  | "parcel-overlap-too-large"
  | "duplicated-parcel"
  | "parcel-version-inconsistency"
  | "parcels-do-not-fill-block";

interface UpdateLpisErrorDialogProps {
  farmId: string;
  onClose: () => void;
  updateLpisResult: FarmUpdateFlatResponse;
}

const UpdateLpisErrorDialog: FC<UpdateLpisErrorDialogProps> = ({
  farmId,
  onClose,
  updateLpisResult,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="update-lpis-error-dialog"
      open
    >
      <DialogTitle>
        <FormattedMessage id="UpdateLPIS.errorDialogTitle" />
      </DialogTitle>
      <Scrollbars autoHeight autoHeightMax="80vh">
        <DialogContent>
          <Grid className={classes.itemContainer}>
            <Stack direction="column">
              <div className={classes.message}>
                <FormattedMessage
                  id={`LpisError.header.code.${updateLpisResult?.shortCode}`}
                />
              </div>
              <div>
                <FormattedMessage
                  id={`LpisError.message.code.${updateLpisResult?.shortCode}`}
                />
              </div>
            </Stack>
            <Stack direction="column">
              {updateLpisResult?.conflictingBlocks?.map((block) => (
                <span key={block}>{block}</span>
              ))}
            </Stack>

            {updateLpisResult?.farmUpdateErrorFlatResponse?.map(
              (errorFlatResponse, index) => (
                <Grid key={index}>
                  <div
                    className={classNames(classes.message, classes.blockNrc)}
                  >
                    <a
                      color="primary"
                      data-test="parcel-detail-link"
                      href={`/farm/${farmId}/parcels/${errorFlatResponse?.parcelId}/overview`}
                      target="_blank no-referrer noopener"
                    >
                      {errorFlatResponse.block}
                    </a>
                  </div>
                  <Stack direction="column" spacing={1}>
                    {errorFlatResponse.conflictingGeometry &&
                      errorFlatResponse.changeGeometry && (
                        <div>
                          <div className={classes.conflictWrapper}>
                            <CfStaticMap
                              classes={{ map: classes.map }}
                              mapId={`conflicting-geometry-map_${index}`}
                              stroke={false}
                              geometries={transformData(
                                errorFlatResponse?.conflictingGeometry,
                                COLOR_GREY[200].substring(1),
                              )}
                              overlayGeometry={transformData(
                                errorFlatResponse?.changeGeometry,
                                COLOR_ERROR.main.substring(1),
                              )}
                            />
                          </div>
                        </div>
                      )}
                  </Stack>
                </Grid>
              ),
            )}
          </Grid>
        </DialogContent>
      </Scrollbars>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button color="primary" id="close" onClick={onClose} variant="text">
            <FormattedMessage id="common.close" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLpisErrorDialog;

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    minWidth: "400px",
  },
  itemContainer: {
    padding: "5px 0px",
  },
  message: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 5,
  },
  blockNrc: {
    color: theme.palette.grey[500],
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
  conflictWrapper: {
    height: 300,
  },
}));

const transformData = (
  geometry: GeoJson,
  color = COLOR_ERROR.main.substring(1),
) => [
  {
    geometry: {
      ...geometry,
      coordinates: MapService.traverseAndTransformCoordinates(
        geometry.coordinates,
      ),
    },
    color,
  },
];
