import React, { FC, ReactNode } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { ActionState } from "../../../../shared/api/telematics/drives/drives.types";
import { State } from "../../../../shared/api/telematics/telematics.types";

interface StateSelectorProps {
  defaultValues?: State[];
  label: ReactNode;
  onChange(items: State[]): void;
}

const defaultDefaultValues: State[] = [];
const options = [
  State.NOT_APPROVED,
  State.APPROVED,
  State.THIRD_PARTY_ACKNOWLEDGED,
  State.DEFERRED,
];

const StateSelector: FC<StateSelectorProps> = ({
  defaultValues = defaultDefaultValues,
  label,
  onChange,
}) => {
  const intl = useIntl();

  const handleRenderOption = (option: ActionState) =>
    intl.formatMessage({ id: `TelematicsList.${option}` });

  const handleGetLabel = (option?: ActionState | null) =>
    option ? intl.formatMessage({ id: `TelematicsList.${option}` }) : "";

  const handleGetSelected = (option: ActionState, value?: ActionState | null) =>
    option === value;

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="action-state-selector"
      isFetching={false}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="action-state-filter"
    />
  );
};

export default StateSelector;
