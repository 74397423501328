/**
 * Created by ondrejzvara on 6.11.15.
 */

import "angular-validation";
import angular from "angular";
import Validation from "./Validation.service";

export default angular
  .module("app.validation", ["validation"])
  .config(config)
  .factory("Validation", Validation).name;

/*@ngInject*/
function config($validationProvider) {
  $validationProvider.showSuccessMessage = false;
  $validationProvider.showErrorMessage = false;

  $validationProvider.setErrorHTML(function (msg) {
    return (
      '<label class="control-label has-error">{{"' +
      msg +
      '" | translate }}</label>'
    );
  });

  var expression = {
    required: function (value) {
      if (value === 0) {
        return true;
      }

      return !!value;
    },
    url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    email:
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    number: /^\d+$/,
    minlength: function (value, scope, element, attrs, param) {
      value += "";
      return value.length >= param;
    },
    maxlength: function (value, scope, element, attrs, param) {
      value += "";
      return value.length <= param;
    },
    greaterOrEqual: function (value, scope, element, attrs, param) {
      if (_.isString(value)) {
        value = value.replace(",", ".");
      }
      var num = _.toNumber(value);
      if (typeof param !== "number") {
        param = parseFloat(param);
      }
      return num >= param;
    },
    equalPass: function (value, scope) {
      return value === scope.PasswordCtrl.newPass;
    },
    smallerOrEqual: function (value, scope, element, attrs, param) {
      if (_.isString(value)) {
        value = value.replace(",", ".");
      }
      var num = _.toNumber(value);
      if (typeof param !== "number") {
        param = parseFloat(param);
      }
      return num <= param;
    },
    numeric: function (value) {
      if (_.isString(value)) {
        value = value.replace(",", ".");
      }
      var num = _.toNumber(value);
      return !_.isBoolean(value) && _.isNumber(num) && _.isFinite(num);
    },
    greaterThen: function (value, scope, element, attrs, param) {
      if (_.isString(value)) {
        value = value.replace(",", ".");
      }
      var num = _.toNumber(value);
      if (typeof param !== "number") {
        param = parseFloat(param);
      }
      return num > param;
    },
    smallerThen: function (value, scope, element, attrs, param) {
      if (_.isString(value)) {
        value = value.replace(",", ".");
      }
      var num = _.toNumber(value);
      if (typeof param !== "number") {
        param = parseFloat(param);
      }
      return num < param;
    },
    nan: function (value) {
      return !isNaN(value);
    },
    none: function () {
      return true;
    },
  };

  var defaultMsg = {
    required: {
      error: "validation.error.required",
      success: "validation.success.success",
    },
    url: {
      error: "validation.error.url",
      success: "validation.success.url",
    },
    email: {
      error: "validation.error.email",
      success: "validation.success.email",
    },
    number: {
      error: "validation.error.number",
      success: "validation.success.number",
    },
    minlength: {
      error: "validation.error.minlength",
      success: "validation.success.minlength",
    },
    maxlength: {
      error: "validation.error.maxlength",
      success: "validation.success.maxlength",
    },
    numeric: {
      error: "validation.error.numeric",
      success: "validation.success.numeric",
    },
    greaterOrEqual: {
      error: "validation.error.greaterOrEqual",
      success: "validation.success.greaterOrEqual",
    },
    smallerOrEqual: {
      error: "validation.error.smallerOrEqual",
      success: "validation.success.smallerOrEqual",
    },
    equalPass: {
      error: "validation.error.equalPass",
      success: "validation.success.equalPass",
    },
    greaterThen: {
      error: "validation.error.greaterThen",
      success: "validation.success.greaterThen",
    },
    smallerThen: {
      error: "validation.error.smallerThen",
      success: "validation.success.smallerThen",
    },
    nan: {
      error: "validation.error.nan",
      success: "validation.success.nan",
    },
    none: {
      error: "validation.error.none",
      success: "validation.success.none",
    },
  };

  $validationProvider.setExpression(expression).setDefaultMsg(defaultMsg);
}
