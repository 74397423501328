import React, { FC } from "react";

import { InputAdornment } from "@mui/material";
import classNames from "classnames";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormikNumericTextField from "../../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import * as validators from "../../../../../shared/misc/validators";
import { useFertilizerDetailStyles } from "../FertilizerDetail.styles";

interface Props {
  fullWidth?: boolean;
  isEditing: boolean;
  isWider?: boolean;
  label: string;
  name: string;
  unit?: string;
}

const NumericField: FC<Props> = ({
  fullWidth = false,
  isEditing,
  isWider = false,
  label,
  name,
  unit,
}) => {
  const classes = useFertilizerDetailStyles();

  return (
    <Field
      component={CfFormikNumericTextField}
      disabled={!isEditing}
      fullWidth={fullWidth}
      label={<FormattedMessage id={label} />}
      name={name}
      validate={validators.formikTextFieldPositiveNumberRequired}
      customClasses={{
        root: classNames({
          [classes.fieldFormik]: fullWidth,
          [classes.numericTextFieldWider]: isWider && !fullWidth,
          [classes.numericTextField]: !isWider && !fullWidth,
        }),
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <span
              style={{ color: isEditing ? "black" : "rgba(0, 0, 0, 0.26)" }}
            >
              {unit}
            </span>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default NumericField;
