import moment from "moment/moment";

import { FORM_TYPES } from "../../others/actionOther.constants";

import Localization from "../../../../shared/services/Localization.service";

export default class ActionStateMapper {
  static mapOtherActionState(action, locale) {
    const actionDateMoment = moment(action.date).startOf("day");

    const isHarvestAction = action.actionType === FORM_TYPES.HARVEST;
    const isMowingAction = action.actionType === FORM_TYPES.MOWING;
    const isSowingAction = action.actionType === FORM_TYPES.SOWING;

    const standardFields = {
      parcels: this.mapParcelsFrom(action.parcels, true),
      date: actionDateMoment,
      description: action.note || "",
    };

    let harvestFields;
    if (action.revenues?.length === 1) {
      harvestFields = {
        mainHarvestProductId: action.revenues[0].product || 0,
        totalGain:
          Localization.num2strNonFixed(
            action.revenues[0].amount,
            locale,
            3,
            3,
          ) || "0",
        hectarGain: "0",
        secondaryHarvestProductId: 0,
        secondaryTotalGain: "0",
        secondaryHectarGain: "0",
      };
    } else if (action.revenues?.length === 2) {
      harvestFields = {
        mainHarvestProductId: action.revenues[0].isMainProduct
          ? action.revenues[0].product
          : action.revenues[1].product,
        totalGain: action.revenues[0].isMainProduct
          ? Localization.num2strNonFixed(
              action.revenues[0].amount,
              locale,
              3,
              3,
            )
          : Localization.num2strNonFixed(
              action.revenues[1].amount,
              locale,
              3,
              3,
            ),
        hectarGain: "0",
        secondaryHarvestProductId: action.revenues[0].isMainProduct
          ? action.revenues[1].product
          : action.revenues[0].product,
        secondaryTotalGain: action.revenues[0].isMainProduct
          ? Localization.num2strNonFixed(
              action.revenues[1].amount,
              locale,
              3,
              3,
            )
          : Localization.num2strNonFixed(
              action.revenues[0].amount,
              locale,
              3,
              3,
            ),
        secondaryHectarGain: "0",
      };
    } else if (!action.revenues?.length) {
      harvestFields = {
        mainHarvestProductId: 0,
        totalGain: "0",
        hectarGain: "0",
        secondaryHarvestProductId: 0,
        secondaryTotalGain: "0",
        secondaryHectarGain: "0",
      };
    }

    const mowingFields = {
      totalGain:
        Localization.num2strNonFixed(
          action?.revenues?.[0]?.amount,
          locale,
          3,
          3,
        ) || "0",
      hectarGain: "0",
      productId: action?.revenues?.[0]?.product || "",
      harvestCrop: true,
    };

    const sowingFields = {
      isCatchCrop: action.isDraft
        ? false
        : action.seedApplicationType !== "MAIN_CROP",
      seedId: action.seedExpense?.material?.id || "",
      cropUseType: action.cropUseType?.cropUseType || "",
      totalAmount:
        Localization.num2strNonFixed(
          action.seedExpense?.actionExpense?.amount,
          locale,
          3,
          3,
        ) || "0",
      hectarAmount:
        Localization.num2strNonFixed(
          action.seedExpense?.actionExpense?.dosePerHa,
          locale,
          3,
          3,
        ) || "0",
      unitId: action.seedExpense?.actionExpense?.unit || "t",
    };

    return {
      actionType: action.actionType,
      ...standardFields,
      ...(isHarvestAction && harvestFields),
      ...(isMowingAction && mowingFields),
      ...(isSowingAction && sowingFields),
    };
  }

  static mapParcelsFrom(parcels, otherAction = false) {
    return parcels
      ?.map((p) => ({
        ...p.parcel,
        subtractableAreas: otherAction
          ? this.mapSubtractableAreasFrom(p.restrictions)
          : p.restrictions,
        actionParcelTotalArea: p.actionArea,
        restrictedArea: p.restrictedArea,
      }))
      .sort((a, b) => a.id - b.id);
  }

  static mapSubtractableAreasFrom(p) {
    const areas = {
      absolute: p.filter((sa) => sa.type === "Absolute"),
      boundary: p.filter((sa) => sa.type === "Boundary"),
      water: p.filter((sa) => sa.type === "Water"),
    };
    areas.boundaryChecked =
      p.filter((sa) => sa.type === "Boundary").find((sa) => sa.isUsed === true)
        ?.value || 0;
    areas.waterChecked =
      p.filter((sa) => sa.type === "Water").find((sa) => sa.isUsed === true)
        ?.value || 0;
    return areas;
  }

  static mapVrsActionState(action, locale) {
    const { date, isDraft, note, parcels, seedApplicationType, seedExpense } =
      action;
    const actionDateMoment = moment(date).startOf("day");

    const standardFields = {
      parcels: this.mapParcelsFrom(parcels),
      actionDate: actionDateMoment,
      actionNote: note,
    };

    // if VRS action has cropUseType
    if (action.cropUseType) {
      standardFields.cropUseType = action.cropUseType;
    }

    if (isDraft) {
      return {
        ...standardFields,
      };
    } else {
      return {
        ...standardFields,
        isCatchCrop: seedApplicationType !== "MAIN_CROP",
        seedId: seedExpense?.material.id || "",
        totalAmount:
          Localization.num2strNonFixed(
            seedExpense?.actionExpense?.amount,
            locale,
            3,
            3,
          ) || "0",
        hectarAmount:
          Localization.num2strNonFixed(
            seedExpense?.actionExpense?.dosePerHa,
            locale,
            3,
            3,
          ) || "0",
        unitId: seedExpense?.actionExpense.unit || "",
        expenses: [
          {
            doseUnit: {
              id: seedExpense?.actionExpense.unit,
              name: seedExpense?.actionExpense.unit,
            },
            material: { ...seedExpense?.material },
          },
        ],
      };
    }
  }

  static mapEphActionState(action) {
    // eslint-disable-next-line max-len
    const {
      date,
      fertilizationTarget,
      fertilizers,
      isDraft,
      isStrawDecay,
      note,
      parcels,
      plantProtections,
      targetCrop,
    } = action;
    const actionDateMoment = moment(date).startOf("day");
    const standardFields = {
      parcels: this.mapParcelsFrom(parcels),
      actionDate: actionDateMoment,
      actionNote: note,
    };

    if (isDraft) {
      return {
        ...standardFields,
      };
    } else {
      return {
        ...standardFields,
        isCatchCrop: fertilizationTarget === "CATCH_CROP",
        isStrawDecay,
        expenses: this.mapExpensesFrom(
          fertilizers,
          plantProtections,
          actionDateMoment,
          parcels,
        ),
        targetCrop,
      };
    }
  }

  static mapVrfActionState(action) {
    const {
      date,
      fertilizers,
      isDraft,
      note,
      parcels,
      plantProtections,
      targetCrop,
    } = action;
    const actionDateMoment = moment(date).startOf("day");

    const standardFields = {
      parcels: this.mapParcelsFrom(parcels),
      actionDate: actionDateMoment,
      actionNote: note,
    };

    if (isDraft) {
      return {
        ...standardFields,
      };
    } else {
      return {
        ...standardFields,
        expenses: this.mapExpensesFrom(
          fertilizers,
          plantProtections,
          actionDateMoment,
          parcels,
        ),
        targetCrop,
      };
    }
  }

  static mapExpensesFrom(
    fertilizers,
    plantProtections,
    actionDateMoment,
    parcels,
  ) {
    const mapExpense = (item, materialTypeId) => ({
      dosage: item.actionExpense.amount,
      dose: item.actionExpense.dosePerHa,
      doseUnit: {
        id: item.actionExpense.unit,
        name: item.actionExpense.unit,
      },
      dosageUnit: {
        id: item.actionExpense.unit,
        name: item.actionExpense.unit,
      },
      note: item.actionExpense.notes,
      material: {
        ...item.material,
        materialTypeId,
        authorizationHolder:
          materialTypeId === "CH"
            ? item.material.authorizationHolder
            : undefined,
        registrationNumber:
          materialTypeId === "CH"
            ? item.material.registrationNumber
            : undefined,
        aktualState:
          // eslint-disable-next-line no-nested-ternary
          materialTypeId === "CH"
            ? item.material.allowed
              ? "P"
              : "Z"
            : undefined,
      },
      driftClass: item.actionMaterial.driftClass,
      incorporationDay:
        materialTypeId === "FR"
          ? this.mapDateSoilIncorporation(
              item.actionMaterial.dateSoilIncorporation,
              actionDateMoment,
            )
          : undefined,
      n:
        materialTypeId === "FR"
          ? item.fertilization.accountableNitrogen
          : undefined,
      p2o5: materialTypeId === "FR" ? item.fertilization.p2o5 : undefined,
      k2o: materialTypeId === "FR" ? item.fertilization.k2o : undefined,
      pests: materialTypeId === "CH" ? item.targetOrganisms : undefined,
      effectiveness:
        materialTypeId === "CH"
          ? item.parcelPlantProtectionEffectiveness.map(
              (effectivenessItem) => ({
                parcelName: parcels.find(
                  (item) => item.parcel.id === effectivenessItem.parcelId,
                )?.parcel.localName,
                parcelId: effectivenessItem.parcelId,
                value: effectivenessItem.effectiveness,
              }),
            )
          : undefined,
    });

    const fertilizerExpenses = fertilizers.map((fertilizer) =>
      mapExpense(fertilizer, "FR"),
    );
    const plantProtectionExpenses = plantProtections.map((plantProtection) =>
      mapExpense(plantProtection, "CH"),
    );

    return [...fertilizerExpenses, ...plantProtectionExpenses];
  }

  static mapDateSoilIncorporation(dateSoilIncorporation, actionDateMoment) {
    const incorporationDateMoment = moment(dateSoilIncorporation);
    if (incorporationDateMoment.isValid()) {
      if (incorporationDateMoment.isSame(actionDateMoment, "days")) {
        return "SAME_DAY";
      } else if (
        incorporationDateMoment
          .clone()
          .subtract(1, "d")
          .isSame(actionDateMoment, "days")
      ) {
        return "NEXT_DAY";
      }
    }
    return "NO";
  }
}
