import React, { Component, Fragment } from "react";

import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import head from "lodash/head";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import * as aggregations from "../../../constants/aggregations.constants";

import ChartPreviewInfo from "../../../../sensors/components/ChartPreviewInfo/ChartPreviewInfo";
import {
  CHART_TYPES,
  CHART_TYPE_PREFIX,
} from "../../../../sensors/services/DeviceConfig.service";
import FeatureData from "../../../../sensors/services/FeatureData.service";
import { COLOR_GREY } from "../../../../theme";
import SensorsService from "../../../services/Sensors.service";

const styles = () => ({
  chartPreviewInfo: {
    fontSize: "13px",
    verticalAlign: "middle",
    marginRight: 15,
  },
  tooltip: {
    marginBottom: 4,
  },
  sensorInfo: {
    float: "left",
    display: "inline-block",
    marginTop: 4,
    marginBottom: 4,
  },
  icon: {
    verticalAlign: "middle",
    marginRight: 3,
  },
  sensorContainer: {
    display: "flex",
    alignItems: "center",
  },
  valves: {
    display: "flex",
  },
});

class SensorGroup extends Component {
  renderValves = (chart, sensors, displayValues, isActive) => (
    <span className={this.props.classes.valves}>
      {sensors.map((sensor) => {
        chart = {
          ...chart,
          value: sensor.value,
          stroke: sensor.value === 0 ? COLOR_GREY[400] : "#296AF6",
        };
        return (
          <span key={`sensor-${sensor.id}`}>
            {this.renderChartPreviewInfo(
              chart,
              false,
              isActive,
              SensorsService.getValveNumber(sensor.level),
            )}
          </span>
        );
      })}
    </span>
  );

  renderChartInfo = (
    chart,
    displayValues,
    isActive,
    totalEvapotranspiration,
  ) => {
    const { features, icon, lastUpdateUnit, name, stroke, unit } = chart;
    let sensors = [{}];

    if (features.length === 1) {
      sensors = head(features).sensors;
    }

    if (name === `${CHART_TYPES.CHART_RAINFALL}_${aggregations.INCREMENT}`) {
      sensors = features.find(
        (f) => f.aggregation === aggregations.INCREMENT,
      ).sensors;
    }

    if (sensors.length === 0) {
      return "-";
    }

    if (name === `${CHART_TYPES.CHART_VALVE}_${aggregations.AVG}`) {
      return this.renderValves(
        { name, icon, unit, lastUpdateUnit },
        sensors,
        displayValues,
        isActive,
      );
    }

    let value =
      sensors.length > 1
        ? FeatureData.getMinMaxValues(sensors, "value")
        : head(sensors).value;

    if (name === CHART_TYPES.CHART_WIND) {
      value = {};
      features.forEach((feature) => {
        if (feature.sensors.length === 0) {
          return "-";
        }
        value[feature.name] = head(feature.sensors).value;
      });
    }

    if (
      name ===
      `${CHART_TYPES.CHART_EVAPOTRANSPIRATION}_${aggregations.INCREMENT}`
    ) {
      value = totalEvapotranspiration;
    }

    return this.renderChartPreviewInfo(
      { name, stroke, icon, value, unit, lastUpdateUnit },
      displayValues,
      isActive,
    );
  };

  renderChartPreviewInfo = (chart, displayValues, isActive, valveNumber) => {
    const { classes, isDetail } = this.props;
    const { icon: FeatureIcon, name, stroke } = chart;
    const color = !isDetail && !isActive ? COLOR_GREY[400] : stroke;
    const chartPreviewProps = valveNumber ? { decimalDigits: 0 } : {};

    return (
      <Tooltip
        placement="bottom"
        classes={{
          tooltip: classes.tooltip,
        }}
        title={
          <FormattedMessage
            id={`NodeFeature.${name.replace(CHART_TYPE_PREFIX, "")}`}
            values={{ number: valveNumber }}
          />
        }
      >
        <span className={classes.sensorContainer} data-test={name} key={name}>
          <FeatureIcon
            className={classes.icon}
            htmlColor={color}
            style={{ fontSize: isDetail ? 20 : 18 }}
          />
          {!valveNumber && (
            <span
              className={classes.chartPreviewInfo}
              data-test={`last-${name}`}
            >
              {isActive && displayValues && (
                <ChartPreviewInfo chart={chart} {...chartPreviewProps} />
              )}
              {isDetail && (!isActive || !displayValues) && (
                <div style={{ color }}>
                  <FormattedMessage
                    id={`NodeFeature.${name.replace(CHART_TYPE_PREFIX, "")}`}
                  />
                </div>
              )}
            </span>
          )}
        </span>
      </Tooltip>
    );
  };

  render() {
    const { classes, displayValues, isActive, node, totalEvapotranspiration } =
      this.props;
    const charts = node && node.config ? node.config.charts : [];
    return (
      <Fragment>
        {charts.map((chart) => (
          <span className={classes.sensorInfo} key={chart.name}>
            {this.renderChartInfo(
              chart,
              displayValues,
              isActive,
              totalEvapotranspiration,
            )}
          </span>
        ))}
      </Fragment>
    );
  }
}

SensorGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  isDetail: PropTypes.bool,
  isActive: PropTypes.bool,
  displayValues: PropTypes.bool,
  totalEvapotranspiration: PropTypes.number,
};

SensorGroup.defaultProps = {
  node: {},
  isDetail: true,
  isActive: true,
  displayValues: true,
  totalEvapotranspiration: 0,
};

export default withStyles(styles)(SensorGroup);
