import React from "react";

import PropTypes from "prop-types";

import ToolbarBtn from "../ToolbarBtn/ToolbarBtn";

const ToolbarBtnCancel = (props) => (
  <ToolbarBtn
    active={false}
    callback={props.onCancel}
    testId="cancel"
    translationId="common.cancel"
  />
);

ToolbarBtnCancel.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

ToolbarBtnCancel.defaultProps = {
  stage: null,
};

export default ToolbarBtnCancel;
