/* @ngInject */
export default function PorsPublicController(
  $controller,
  $scope,
  ChemistriesResource,
  farm,
) {
  const vm = this;

  vm.clsCtrl = $controller("ClassifiersPublicController", { $scope });
  vm.clsCtrl.setResource({
    getItems: getPublicChemistries,
  });

  vm.config = {
    ctrl: vm,
    edit: false,
    delete: false,
    create: false,
  };

  vm.clsCtrl.activate(farm.id, {
    page: 1,
    count: 10,
  });

  /** ******************************************************************* */

  function getPublicChemistries(ngParams, custParams) {
    return ChemistriesResource.getPublicChemistries(ngParams, custParams).then(
      (result) => {
        // the following lodash is because of using rowspan in the template for same legislative codes
        let grouped = _.groupBy(result, "legislativeCode");

        grouped = _.orderBy(
          grouped,
          [
            function (value) {
              return value[0].usageInfo.dateIn;
            },
          ],
          ["desc"],
        );

        _.forEach(grouped, (value) => {
          value[0].$rowspan = value.length;
        });

        const flattened = _.flatten(_.values(grouped));

        return flattened;
      },
    );
  }
}
