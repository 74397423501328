import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import EditableText from "../../../shared/components/common/EditableText/EditableText";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import SensorsService from "../../../shared/services/Sensors.service";
import NodeLocationActionsMenu from "../NodeLocationActionsMenu/NodeLocationActionsMenu";
import NodeLocationInfo from "../NodeLocationInfo/NodeLocationInfo";

const styles = (theme) => ({
  headerWithInfo: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
  },
  nodeId: {
    color: theme.palette.grey[500],
    fontSize: 18,
  },
  nodeLocationInfo: {
    padding: "8px 16px",
  },
  locationName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 4,
  },
});

class NodeLocationDetailHeader extends Component {
  onEditName(newName) {
    return this.props.update(this.props.nodeLocation.id, newName);
  }

  render() {
    const {
      classes,
      deactivate,
      groups,
      history,
      match,
      ngGoToNewNotification,
      node,
      nodeLocation,
      setGroups,
    } = this.props;
    const isActive = SensorsService.isActive(nodeLocation);
    const isErrorOrTransfer = SensorsService.isErrorOrTransfer(nodeLocation);
    const hasZeroCoordinates = SensorsService.hasZeroCoordinates(nodeLocation);

    return (
      <div className={classes.headerWithInfo}>
        <div className={classes.headerWrapper}>
          <PageHeader
            classes={{ header: classes.header }}
            actionButtons={
              <Fragment>
                {isActive && (
                  <NodeLocationActionsMenu
                    deactivate={deactivate}
                    groups={groups}
                    match={match}
                    ngGoToNewNotification={ngGoToNewNotification}
                    nodeLocation={nodeLocation}
                    setGroups={setGroups}
                  />
                )}
              </Fragment>
            }
            backButton={
              <CfBackButton
                translId="NodeLocationDetailHeader.backToSensors"
                onClick={() => {
                  history.push(`/farm/${match.params.farmId}/sensors`);
                }}
              />
            }
            heading={
              <PageHeading
                dataTest="nodeDetail-heading"
                value={
                  <div className={classes.heading}>
                    {nodeLocation &&
                      typeof nodeLocation.name === "string" &&
                      (isActive ||
                        (isErrorOrTransfer && !hasZeroCoordinates)) && (
                        <div className={classes.locationName}>
                          <EditableText
                            initialValue={nodeLocation.name}
                            labelTestId="location-name"
                            onConfirmEditing={(newValue) =>
                              this.onEditName(newValue)
                            }
                          />
                        </div>
                      )}
                    <div className={classes.nodeId} data-test="node-id">
                      {node?.externalId || ""}
                    </div>
                  </div>
                }
              />
            }
          />
        </div>
        <div className={classes.nodeLocationInfo}>
          <NodeLocationInfo nodeLocation={nodeLocation} />
        </div>
      </div>
    );
  }
}

NodeLocationDetailHeader.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object,
  node: PropTypes.object,
  update: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  setGroups: PropTypes.func.isRequired,
  ngGoToNewNotification: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
};

NodeLocationDetailHeader.defaultProps = {
  node: {},
  nodeLocation: {},
};

export default withStyles(styles)(NodeLocationDetailHeader);
