import React, { FC, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfFilter from "../../../../../shared/containers/CfFilter/CfFilter";

import CenterSelector from "./CenterSelector/CenterSelector";
import CropsSelector from "./CropsSelector/CropsSelector";
import LandUseSelector from "./LandUseSelector/LandUseSelector";
import NSASelector from "./NSASelector/NSASelector";
import ParcelSelector from "./ParcelSelector/ParcelSelector";
import ZoneSelector from "./ZoneSelector/ZoneSelector";

import {
  CenterTo,
  CropTo,
  LandUseResponse,
  ParcelSuggestionTo,
  ZoneDetailTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  NSAState,
  ParcelListAdvancedFilter,
} from "../../../../../shared/api/agroevidence/parcels/parcels.types";

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

export interface ParcelAdvancedFilterProps {
  advancedFilter: ParcelListAdvancedFilter;
  langId: string;
  namespace: string;
}

export const ParcelAdvancedFilter: FC<ParcelAdvancedFilterProps> = ({
  advancedFilter,
  langId,
  namespace,
}) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] =
    useState<ParcelListAdvancedFilter>(advancedFilter);

  useEffect(() => {
    setAdvancedFilterState(advancedFilter);
  }, [advancedFilter]);

  const handleChangeParcel = (items: ParcelSuggestionTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, parcel: items });
  };
  const handleChangeLandUse = (items: LandUseResponse[]) => {
    setAdvancedFilterState({ ...advancedFilterState, landUse: items });
  };
  const handleChangeCrop = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };
  const handleChangeNSA = (items: NSAState) => {
    setAdvancedFilterState({ ...advancedFilterState, nsa: items });
  };
  const handleChangeZone = (items: ZoneDetailTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, zones: items });
  };
  const handleChangeCenter = (items: CenterTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, centers: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ParcelSelector
              defaultValues={advancedFilter.parcel}
              label={<FormattedMessage id="common.parcel" />}
              onChange={handleChangeParcel}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <LandUseSelector
              defaultLandUse={advancedFilter.landUse}
              label={<FormattedMessage id="common.culture" />}
              onChange={handleChangeLandUse}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultCrops={advancedFilter.crops}
              label={<FormattedMessage id="common.crop" />}
              onChange={handleChangeCrop}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <NSASelector
              defaultNSA={advancedFilter.nsa}
              label={<FormattedMessage id="term.NSA" />}
              onChange={handleChangeNSA}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ZoneSelector
              defaultZones={advancedFilter.zones}
              label={<FormattedMessage id="common.zone" />}
              onChange={handleChangeZone}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CenterSelector
              defaultCenters={advancedFilter.centers}
              label={<FormattedMessage id="common.center" />}
              onChange={handleChangeCenter}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

export default ParcelAdvancedFilter;
