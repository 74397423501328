import * as React from "react";

import hoistNonReactStatics from "hoist-non-react-statics";
import PropTypes from "prop-types";

import { getDisplayName } from "../../hocHelpers";

export default (Consumer, defaultPropName, componentPrefix, hocName) =>
  (options = {}) =>
  (WrappedComponent) => {
    const { forwardRef = false, propName = defaultPropName } = options;

    if (process.env.NODE_ENV !== "production") {
      if (WrappedComponent === undefined) {
        throw new Error(
          [
            `You are calling ${hocName}(options)(Component) with an undefined component.`,
            "You may have forgotten to import it.",
          ].join("\n"),
        );
      }

      if (defaultPropName === undefined) {
        throw new Error(
          "You are trying to create the consumer withou specifying defaultPropName option.",
        );
      }
    }

    if (!propName) {
      throw new Error("Prop name not specified.");
    }

    const WithProp = (props) => (
      <Consumer>
        {(prop) => (
          <WrappedComponent
            {...props}
            {...{
              [propName]: prop,
            }}
            ref={forwardRef ? props.forwardedRef : null}
          />
        )}
      </Consumer>
    );

    WithProp.displayName = `${componentPrefix}(${getDisplayName(
      WrappedComponent,
    )})`;
    WithProp.WrappedComponent = WrappedComponent;

    WithProp.propTypes = {
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
      ]),
    };

    WithProp.defaultProps = {
      forwardedRef: PropTypes.any,
    };

    if (forwardRef) {
      return hoistNonReactStatics(
        React.forwardRef((props, ref) => (
          <WithProp {...props} forwardedRef={ref} />
        )),
        WrappedComponent,
      );
    }

    return hoistNonReactStatics(WithProp, WrappedComponent);
  };
