import React, { Component, Fragment } from "react";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, Fab, Grid, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getAreaDetail,
  getIsFetchingAreaDetail,
} from "../../../shared/api/irrigation/areas/areas.selectors";
import {
  getDevice,
  getIsFetchingDevice,
  getIsFetchingDeviceValves,
  setDeviceValvesForManualAction,
} from "../../../shared/api/irrigation/devices/devices.selectors";

import { editorSetSelected } from "../../../core/map/actions/editor/editor.actions";
import { fetchArea, resetArea } from "../../actions/areasList.actions";
import {
  updateDeviceName,
  fetchDeviceValves,
  valvesManualControl,
  fetchDevice,
} from "../../actions/devices.actions";
import { setEnlargedVariant } from "../../actions/map.actions";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import IrrigationOffIcon from "../../../shared/icons/IrrigationOffIcon";
import { setActivePage, setPrevPage } from "../../actions/commonActions";
import IrrigationDetailHeader from "../../components/IrrigationDetailHeader/IrrigationDetailHeader";
import { payloadPrepareStopManualAction } from "../../components/IrrigationDevicesTable/components/IrrigationManualAction.helper";
import IrrigationManualActionModal from "../../components/IrrigationDevicesTable/components/IrrigationManualActionModal";
import ValvesStatus from "../../components/ValvesStatus/ValvesStatus";
import IrrigationService from "../../services/Irrigation.service";
import ValvesHistory from "../ValvesHistory/ValvesHistory";
import ValvesPlan from "../ValvesPlan/ValvesPlan";

const styles = (theme) => ({
  valveStatusWrapper: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: "8px 12px",
    justifyContent: "center",
  },
  valveStatusContent: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    fontSize: 13,
    color: theme.palette.grey[500],
  },
  content: {
    padding: "10px 15px",
  },
  fabContainer: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  fabActive: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabNotActive: {
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[500],
    },
  },
});

export class IrrigationDetail extends Component {
  constructor(props) {
    super(props);
    this.path = window.location.pathname;
    this.state = { showDialog: false, hasClickedToStopValves: false };
    props.setEnlargedVariant(false);
  }

  componentDidMount() {
    this.props.setActivePage(this.path);
  }

  componentDidUpdate(prevProps) {
    const { device, deviceValves } = this.props;

    const { device: prevDevice, deviceValves: prevDeviceValves } = prevProps;

    this.props.editorSetSelected({ id: this.props.device.id });

    if (device !== prevDevice && device.areaId) {
      this.props.fetchArea(this.props.device.areaId);
    }

    if (deviceValves !== prevDeviceValves) {
      if (this.state.hasClickedToStopValves === true) {
        const stopManualAction = payloadPrepareStopManualAction(deviceValves);
        this.props.valvesManualControl(
          device.id,
          stopManualAction,
          this.handleContentRefresh,
        );
        this.setState({ hasClickedToStopValves: false });
      }
    }
  }

  componentWillUnmount() {
    this.props.setPrevPage(this.path);
    this.props.resetArea();
  }

  handleStopManualAction = (evt) => {
    evt.stopPropagation();
    this.props.fetchDeviceValves(this.props.device.id);
    this.setState({ hasClickedToStopValves: true });
  };

  handleOpenModal = (evt) => {
    evt.stopPropagation();
    this.props.fetchDeviceValves(this.props.device.id);
    this.setState({ showDialog: true });
  };

  handleCloseModal = () => {
    this.setState({ showDialog: false });
  };

  handleSubmitModal = (startManualAction) => {
    this.props.valvesManualControl(
      this.props.device.id,
      startManualAction,
      this.handleContentRefresh,
    );
    this.setState({ showDialog: false });
  };

  handleContentRefresh = () => {
    this.props.fetchDevice(this.props.device.id);
  };

  render() {
    const {
      area,
      classes,
      device,
      deviceValves,
      farmId,
      history,
      isFetchingArea,
      isFetchingDevice,
      isFetchingDeviceValves,
      isMapDisplayed,
      match,
    } = this.props;
    const { showDialog } = this.state;
    const isActive = IrrigationService.isActive(device);
    const isDataLoading = isFetchingDevice || isFetchingArea;
    const isIrrigating = device.isIrrigating;

    return isDataLoading ? (
      <CfLoader />
    ) : (
      <div>
        <IrrigationDetailHeader
          area={area}
          device={device}
          farmId={farmId}
          history={history}
          updateDeviceName={this.props.updateDeviceName}
        />
        {isActive && (
          <Box className={classes.fabContainer}>
            <Tooltip
              title={
                <span>
                  <FormattedMessage
                    id={
                      isIrrigating
                        ? "Irrigation.valveManualAction.off"
                        : "Irrigation.valveManualAction.on"
                    }
                  />
                </span>
              }
            >
              <Fab
                aria-label="action"
                size="small"
                className={
                  isIrrigating ? classes.fabActive : classes.fabNotActive
                }
                onClick={
                  isIrrigating
                    ? this.handleStopManualAction
                    : this.handleOpenModal
                }
              >
                <PowerSettingsNewIcon />
              </Fab>
            </Tooltip>
          </Box>
        )}
        <Grid className={classes.valveStatusWrapper} container>
          <Grid className={classes.valveStatusContent} item xs={12}>
            <ValvesStatus installed={isActive} valvesStatus={device.valves} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            lg={isMapDisplayed ? "auto" : 1}
            xl={isMapDisplayed ? "auto" : 2}
          />
          <Grid
            className={classes.content}
            item
            lg={isMapDisplayed ? 12 : 10}
            xl={isMapDisplayed ? 12 : 8}
            xs={12}
          >
            {isActive ? (
              <Fragment>
                <ValvesPlan />
                <ValvesHistory
                  activationDate={device.activationDate}
                  deviceId={match.params.deviceId}
                />
              </Fragment>
            ) : (
              <CfStatusPanel
                icon={IrrigationOffIcon}
                content={
                  <FormattedMessage id="IrrigationDetail.notActivatedExplanation" />
                }
                title={
                  <FormattedMessage id="IrrigationDetail.inactiveHeading" />
                }
              />
            )}
          </Grid>
          <Grid
            item
            lg={isMapDisplayed ? "auto" : 1}
            xl={isMapDisplayed ? "auto" : 2}
          />
        </Grid>
        {showDialog && !isFetchingDeviceValves && (
          <IrrigationManualActionModal
            deviceName={device.name}
            deviceValves={deviceValves}
            onClose={this.handleCloseModal}
            onSubmit={this.handleSubmitModal}
            showDialog={showDialog}
          />
        )}
      </div>
    );
  }
}

IrrigationDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  isMapDisplayed: PropTypes.bool.isRequired,
  updateDeviceName: PropTypes.func.isRequired,
  isFetchingDevice: PropTypes.bool.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setPrevPage: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  area: PropTypes.object.isRequired,
  fetchArea: PropTypes.func.isRequired,
  resetArea: PropTypes.func.isRequired,
  isFetchingArea: PropTypes.bool.isRequired,
  deviceValves: PropTypes.array.isRequired,
  isFetchingDeviceValves: PropTypes.bool.isRequired,
  fetchDeviceValves: PropTypes.func.isRequired,
  valvesManualControl: PropTypes.func.isRequired,
  fetchDevice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  device: getDevice(state),
  isFetchingDevice: getIsFetchingDevice(state),
  area: getAreaDetail(state),
  isFetchingArea: getIsFetchingAreaDetail(state),
  deviceValves: setDeviceValvesForManualAction(state),
  isFetchingDeviceValves: getIsFetchingDeviceValves(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDeviceName,
      setEnlargedVariant,
      setActivePage,
      setPrevPage,
      editorSetSelected,
      fetchArea,
      resetArea,
      fetchDeviceValves,
      valvesManualControl,
      fetchDevice,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(IrrigationDetail));
