import React, { FC } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import {
  getHarvestProducts,
  getIsFetchingHarvestProducts,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { HarvestProductTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface HarvestProductSelectorProp {
  harvestProducts: HarvestProductTo[];
  isEditing: boolean;
  isFetchingHarvestProduct: boolean;
  isSecondaryHarvestProduct?: boolean;
}

const HarvestProductSelector: FC<HarvestProductSelectorProp> = ({
  harvestProducts,
  isEditing,
  isFetchingHarvestProduct,
  isSecondaryHarvestProduct = false,
}) => {
  if (isFetchingHarvestProduct) {
    return <LinearProgress color="secondary" />;
  }
  const isDisable = harvestProducts.length === 0 || !isEditing;
  const labelTranslationId = isSecondaryHarvestProduct
    ? "action.harvestProductSecondary"
    : "action.harvestProductMain";
  const name = isSecondaryHarvestProduct
    ? "secondaryHarvestProductId"
    : "mainHarvestProductId";

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={isDisable}
        label={<FormattedMessage id={labelTranslationId} />}
        name={name}
        select
        validate={isSecondaryHarvestProduct ? undefined : validators.required}
        validateOnBlur
      >
        {isSecondaryHarvestProduct && <MenuItem value={0}>-</MenuItem>}
        {harvestProducts.map((item) => (
          <MenuItem key={item.legislativeCode} value={item.legislativeCode}>
            {item.description}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  harvestProducts: getHarvestProducts(state),
  isFetchingHarvestProduct: getIsFetchingHarvestProducts(state),
});

export default connect(mapStateToProps)(HarvestProductSelector);
