import { Fill, Stroke, Style, Circle, Text } from "ol/style";

export default class IrrigationMapStylesService {
  static FILL_COLOR_HOVER = "rgba(255, 255, 255, 0.40)";
  static SELECT_COLOR = "rgba(255, 255, 255, 0.65)";
  static TEXT_FILL_COLOR = "#000000";
  static ACTIVE_DEVICE_FILL_COLOR = "#296AF6";
  static ACTIVE_DEVICE_STROKE_COLOR = "#A9C3FB";
  static INACTIVE_DEVICE_FILL_COLOR = "#848484";
  static INACTIVE_DEVICE_STROKE_COLOR = "#E0E0E0";
  static CIRCLE_RADIUS = 7;
  static HOVER_RADIUS = 13;
  static STROKE_WIDTH = 2;

  static setHoverIrrigationStyle(featureName, isDeviceActive) {
    const hoverFill = new Fill({
      color: this.FILL_COLOR_HOVER,
    });
    return new Style({
      image: new Circle({
        fill: hoverFill,
        radius: this.HOVER_RADIUS,
      }),
      text: new Text({
        text: featureName,
        font: "700 12px Roboto",
        fill: new Fill({
          color: this.TEXT_FILL_COLOR,
        }),
        offsetY: -18,
        stroke: new Stroke({
          color: isDeviceActive
            ? this.ACTIVE_DEVICE_STROKE_COLOR
            : this.INACTIVE_DEVICE_STROKE_COLOR,
          width: this.STROKE_WIDTH,
        }),
      }),
    });
  }

  static setSelectedIrrigationStyle() {
    const selectFill = new Fill({
      color: this.SELECT_COLOR,
    });
    return new Style({
      image: new Circle({
        fill: selectFill,
        radius: this.HOVER_RADIUS,
      }),
    });
  }

  static setDefaultIrrigationStyle(isDeviceActive) {
    const fill = new Fill({
      color: isDeviceActive
        ? this.ACTIVE_DEVICE_FILL_COLOR
        : this.INACTIVE_DEVICE_FILL_COLOR,
    });

    const strokeColor = isDeviceActive
      ? this.ACTIVE_DEVICE_STROKE_COLOR
      : this.INACTIVE_DEVICE_STROKE_COLOR;
    const stroke = new Stroke({
      color: strokeColor,
      width: this.STROKE_WIDTH,
    });

    return new Style({
      image: new Circle({
        fill,
        stroke,
        radius: this.CIRCLE_RADIUS,
      }),
    });
  }

  static setIrrigationStyle(feature, hoveredId, selectedId) {
    const featureId = feature.get("id");
    const featureName = feature.get("name");
    const featureStatus = feature.get("status") || "ACTIVE";

    const isDeviceActive = featureStatus === "ACTIVE";

    let style = this.setDefaultIrrigationStyle(isDeviceActive);
    if (Array.isArray(hoveredId)) {
      if (hoveredId?.find((item) => item === featureId)) {
        style = [
          this.setHoverIrrigationStyle(featureName, isDeviceActive),
          style,
        ];
      }
    } else if (hoveredId === featureId) {
      style = [
        this.setHoverIrrigationStyle(featureName, isDeviceActive),
        style,
      ];
    } else if (selectedId === featureId) {
      style = [this.setSelectedIrrigationStyle(), style];
    }
    return style;
  }

  static refreshIrrigationStyles(pointsLayer, hoveredId, selectedId) {
    return pointsLayer.setStyle((feature) =>
      this.setIrrigationStyle(feature, hoveredId, selectedId),
    );
  }
}
