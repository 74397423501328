import * as types from "./chemistry.constants";

export default () =>
  (
    state = {
      isFetching: false,
      error: {},
      items: [],
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_CHEMISTRY_APPLICATIONS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_CHEMISTRY_APPLICATIONS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_CHEMISTRY_APPLICATIONS_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_CHEMISTRY_APPLICATIONS:
        return {
          ...state,
          items: [],
        };

      default:
        return state;
    }
  };
