import {
  Valve,
  ValveManualAction,
} from "../../../../shared/api/irrigation/devices/devices.types";

export const payloadPrepareStopManualAction = (valves: Valve[]) =>
  valves.reduce((acc: ValveManualAction[], valve) => {
    if (valve.isOpen) {
      acc.push({
        valveId: valve.valveId,
        command: "CLOSE",
      });
    }
    return acc;
  }, []);

export const payloadPrepareStartManualAction = (valves: Valve[]) =>
  valves.reduce((acc: ValveManualAction[], valve) => {
    if (valve.durationSeconds) {
      acc.push({
        valveId: valve.valveId,
        command: "OPEN",
        durationSeconds: valve.durationSeconds,
      });
    }
    return acc;
  }, []);
