import React, { FC } from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getTelematicsAdminSelected } from "../selectors/telematicsAdmin.selectors";

import withPopover from "../../../../shared/hocs/withPopover";

import { AdminState } from "../../../../reducers/admin.reducer.types";

interface Props {
  anchorEl: HTMLElement;
  className?: string;
  handleActivation: () => void;
  handleDeactivation: () => void;
  handlePopoverClose: () => void;
  handlePopoverOpen: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isOpen: boolean;
  selected: string[];
}

const ActivationSelector: FC<Props> = ({
  anchorEl,
  className,
  handleActivation,
  handleDeactivation,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  selected,
}) => {
  const handleApprove = () => {
    handleActivation();
    handlePopoverClose();
  };
  const handleUnapprove = () => {
    handleDeactivation();
    handlePopoverClose();
  };
  return (
    <div className={className}>
      <Button
        aria-label="telematics admin activate button"
        className={undefined}
        color="primary"
        disabled={!selected.length}
        onClick={handlePopoverOpen}
      >
        <span>
          <FormattedMessage
            id="TelematicsAdmin.activation.button"
            values={{ count: selected.length }}
          />
        </span>
        <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="telematics-admin-activate-menu"
        onClose={handlePopoverClose}
        open={isOpen}
      >
        <ListItem button data-test="approve" onClick={handleApprove}>
          <ListItemText>
            <FormattedMessage id="TelematicsAdmin.activation.turnOn" />
          </ListItemText>
        </ListItem>
        <ListItem button data-test="unapprove" onClick={handleUnapprove}>
          <ListItemText>
            <FormattedMessage id="TelematicsAdmin.activation.turnOff" />
          </ListItemText>
        </ListItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selected: getTelematicsAdminSelected(state),
});

export default connect(mapStateToProps)(withPopover(ActivationSelector));
