import React, { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ListItemText, MenuItem, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import withPopover from "../../../../shared/hocs/withPopover";
import ParcelCropAssignBulk from "../../shared/containers/ParcelCropAssign/ParcelCropAssignBulk";

import {
  CropTo,
  SowingPlanSeasonTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    paddingRight: 6,
    marginRight: 8,
  },
  rightIcon: {
    position: "relative",
    top: -1,
  },
  selectedLabel: {
    paddingTop: 2,
  },
}));

export interface IParcelCropsActionsProps {
  onAssignCrop: (crop: CropTo, seasonId: string, parcelId: string[]) => void;
  parcelSowingPlan: SowingPlanSeasonTo[];
  selected: string[];
}

export const ParcelCropsActions = ({
  onAssignCrop,
  parcelSowingPlan,
  selected,
}: IParcelCropsActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignCrop = (crop: CropTo, seasonId: string) => {
    onAssignCrop(crop, seasonId, selected);
  };

  return (
    <div>
      <Button
        disabled={!selected.length}
        onClick={handleClick}
        className={classNames(classes.button, {
          [classes.activeButton]: selected.length > 0,
        })}
      >
        <span className={classes.selectedLabel}>
          <FormattedMessage id="common.selectedCrop" />
          {selected.length > 0 && `\u00A0(${selected.length})`}
        </span>
        <ArrowDropDownIcon className={classes.rightIcon} />
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
        {parcelSowingPlan.map((sowingPlan) => (
          <SubMenu
            handleClose={handleClose}
            key={sowingPlan.id}
            onAssignCrop={handleAssignCrop}
            parcelSowingPlan={sowingPlan}
          />
        ))}
      </Menu>
    </div>
  );
};

interface ISubMenuProps {
  handleClose: () => void;
  onAssignCrop: (crop: CropTo, seasonId: string) => void;
  parcelSowingPlan: SowingPlanSeasonTo;
}

const SubMenu = ({
  handleClose,
  onAssignCrop,
  parcelSowingPlan,
}: ISubMenuProps) => {
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<Element | null>(null);

  const handleSubMenuClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    handleClose();
  };

  return (
    <>
      <MenuItem onClick={handleSubMenuClick}>
        <ListItemText>
          <FormattedMessage id="common.season" />{" "}
          {`${parcelSowingPlan.startYear}/${parcelSowingPlan.endYear}`}
        </ListItemText>
        <KeyboardArrowRightIcon />
      </MenuItem>
      <Menu
        anchorEl={subMenuAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleSubMenuClose}
        open={Boolean(subMenuAnchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <ParcelCropAssignBulk
          sowingSeason={parcelSowingPlan}
          onCropChangeBulk={(crop: CropTo, seasonId: string) => {
            try {
              onAssignCrop(crop, seasonId);
            } finally {
              handleSubMenuClose();
            }
          }}
        />
      </Menu>
    </>
  );
};

export default withPopover(ParcelCropsActions);
