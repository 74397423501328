import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfReduxFormCheckbox from "../CfReduxFormCheckbox/CfReduxFormCheckbox";

const style = {
  root: {
    left: "10px",
  },
};

const Component = ({ classes, ...props }) => (
  <CfReduxFormCheckbox
    classes={{ root: classes.root }}
    color="primary"
    {...props}
  />
);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Component);
