import { feature as toFeature } from "@turf/helpers";
import { RSAA } from "redux-api-middleware";

import * as types from "./geometry.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

// TODO: CORE geometry is deprecated, remove when all moved to Agroevidence (dependences in Map - split, buffer, merge)

export const getParcelGeometryById = (parcelId) => ({
  [RSAA]: {
    endpoint: `geometry/${parcelId}?`,
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_PARCEL_GEOMETRY_BY_ID,
      types.GET_PARCEL_GEOMETRY_BY_ID_SUCCESS,
      types.GET_PARCEL_GEOMETRY_BY_ID_ERROR,
    ],
  },
});

export const resetParcelGeometry = () => ({
  type: types.RESET_PARCEL_GEOMETRY,
});

export const splitGeometry = (linestring, parcelId, isDryMode, validFrom) => ({
  [RSAA]: {
    endpoint: `geometry/${parcelId}/split?dry-mode=${isDryMode}&date=${validFrom}&`,
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(toFeature(linestring)),
    types: [
      types.SPLIT_GEOMETRY,
      types.SPLIT_GEOMETRY_SUCCESS,
      types.SPLIT_GEOMETRY_ERROR,
    ],
  },
});

export const getMergeNeighbors = (parcelId, lpisId) => ({
  [RSAA]: {
    endpoint: `geometry/${parcelId}/neighbors?${
      lpisId ? `lpis-block-id=${lpisId}&` : ""
    }`,
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_MERGE_NEIGHBORS,
      types.GET_MERGE_NEIGHBORS_SUCCESS,
      types.GET_MERGE_NEIGHBORS_ERROR,
    ],
  },
});

export const mergeGeometries = (parcelIds, isDryMode, validFrom) => ({
  [RSAA]: {
    endpoint: `geometry/merge?parcel-ids=${parcelIds.join(
      ",",
    )}&dry-mode=${isDryMode}&date=${validFrom}&`,
    method: methods.POST,
    module: modules.CORE,
    types: [
      types.MERGE_GEOMETRIES,
      types.MERGE_GEOMETRIES_SUCCESS,
      types.MERGE_GEOMETRIES_ERROR,
    ],
  },
});

export const getLpisParcelsByParcelId = (parcelId) => ({
  [RSAA]: {
    endpoint: `geometry/${parcelId}/parcels?`,
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_LPIS_PARCELS_BY_PARCEL_ID,
      types.GET_LPIS_PARCELS_BY_PARCEL_ID_SUCCESS,
      types.GET_LPIS_PARCELS_BY_PARCEL_ID_ERROR,
    ],
  },
});

export const getGeometryArea = (feature) => ({
  [RSAA]: {
    endpoint: "geometry/area?",
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(toFeature(feature)),
    types: [
      types.GET_FEATURE_AREA,
      types.GET_FEATURE_AREA_SUCCESS,
      types.GET_FEATURE_AREA_ERROR,
    ],
  },
});

export const bufferGeometry = (geometry, length) => ({
  [RSAA]: {
    endpoint: `geometry/buffer?length=${length}&endcap=SQUARE&`,
    method: methods.POST,
    module: modules.CORE,
    body: JSON.stringify(geometry),
    types: [
      types.BUFFER_GEOMETRY,
      types.BUFFER_GEOMETRY_SUCCESS,
      types.BUFFER_GEOMETRY_ERROR,
    ],
  },
});
