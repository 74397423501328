import * as types from "./accounts.constants";

export default () =>
  (
    state = {
      isFetchingAccount: false,
      getAccountError: null,
      account: null,
      isSavingAccount: false,
      saveAccountError: null,
      isSavingPassword: false,
      savePasswordError: null,
    },
    action,
  ) => {
    switch (action.type) {
      case types.GET_ACCOUNT:
        return {
          ...state,
          isFetchingAccount: true,
          getAccountError: null,
        };
      case types.GET_ACCOUNT_SUCCESS:
        return {
          ...state,
          isFetchingAccount: false,
          account: action.payload,
        };
      case types.GET_ACCOUNT_ERROR:
        return {
          ...state,
          isFetchingAccount: false,
          getAccountError: action.payload,
        };
      case types.SAVE_ACCOUNT:
        return {
          ...state,
          isSavingAccount: true,
          saveAccountError: null,
        };
      case types.SAVE_ACCOUNT_SUCCESS:
        return {
          ...state,
          isSavingAccount: false,
        };
      case types.SAVE_ACCOUNT_ERROR:
        return {
          ...state,
          isSavingAccount: false,
          saveAccountError: action.payload,
        };
      case types.CHANGE_PASSWORD:
        return {
          ...state,
          isSavingPassword: true,
          savePasswordError: null,
        };
      case types.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          isSavingPassword: false,
        };
      case types.CHANGE_PASSWORD_ERROR:
        return {
          ...state,
          isSavingPassword: false,
          savePasswordError: action.payload,
        };
      default:
        return state;
    }
  };
