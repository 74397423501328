import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const IrrigationIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M22.9256 8.36649L15.0217 0.476562L7.11778 8.36649C4.93933 10.5449 3.8501 13.4496 3.8501 16.2424C3.8501 19.0353 4.93933 21.9818 7.11778 24.1603C9.29624 26.3388 12.159 27.4419 15.0217 27.4419C17.8844 27.4419 20.7471 26.3388 22.9256 24.1603C25.104 21.9818 26.1932 19.0353 26.1932 16.2424C26.1932 13.4496 25.104 10.5449 22.9256 8.36649ZM6.64299 16.7452C6.65696 13.9523 7.50879 12.1788 9.10074 10.6008L15.0217 4.55419L20.9426 10.6706C22.5346 12.2346 23.3864 13.9523 23.4004 16.7452H6.64299Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { IrrigationIcon };
