/**
 * Created by ondrejzvara on 30.3.17.
 */

import cfBoolSelector from "./cf-bool-selector/cf-bool-selector.directive.js";
import cfClassifierSelector from "./cf-classifier-selector/cf-classifier-selector.directive.js";
import cfCropSelector from "./cf-crop-selector/cf-crop-selector.directive.js";
import cfFertSelector from "./cf-fert-selector/cf-fert-selector.directive.js";
import cfOrganismSearchSelector from "./cf-organism-search-selector/cf-organism-search-selector.directive.js";
import cfPorSelector from "./cf-por-selector/cf-por-selector.directive.js";
import cfVarietySelector from "./cf-variety-selector/cf-variety-selector.directive.js";

export default angular
  .module("app.directives.selectors", [])
  .directive("cfBoolSelector", cfBoolSelector)
  .directive("cfClassifierSelector", cfClassifierSelector)
  .directive("cfCropSelector", cfCropSelector)
  .directive("cfFertSelector", cfFertSelector)
  .directive("cfOrganismSearchSelector", cfOrganismSearchSelector)
  .directive("cfPorSelector", cfPorSelector)
  .directive("cfVarietySelector", cfVarietySelector).name;
