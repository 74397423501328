/**
 * Created by ondrejzvara on 27.4.16.
 */

/*@ngInject*/
export default function MaterialToMapper() {
  return {
    modelToDto: modelToDto,
  };

  function modelToDto(material, dtoClass) {
    var materialTo = _.cloneDeep(material);

    materialTo["@class"] = dtoClass;
    materialTo.fertilizerNitrogenCategoryId = _.get(
      materialTo,
      "fertilizerNitrogenCategory.id",
    );
    materialTo.restrictions = mapRestrictions(materialTo);
    materialTo.activeSubstances = mapActiveSubstances(materialTo);

    return materialTo;
  }

  function mapRestrictions(materialTo) {
    var restrictions = materialTo.clMaterialRestrictionList;
    _.each(restrictions, function (rest) {
      rest.materialDosageUnitId = rest.materialDosageUnit
        ? rest.materialDosageUnit.id
        : null;
      rest.waterDosageUnitId = rest.waterDosageUnit
        ? rest.waterDosageUnit.id
        : null;
      rest.cropLegCodeList = _.map(rest.clCropList, "legislativeCode");
      rest.restrictionOrganisms = _.map(
        rest.clMaterialRestrictionOrganisms,
        function (restOrg) {
          return {
            organismLegCode: restOrg.organism.legislativeCode,
            nontarget: restOrg.nontarget,
          };
        },
      );

      delete rest.materialAppliable;
      delete rest.clMaterialRestrictionOrganisms;
      delete rest.materialDosageUnit;
      delete rest.waterDosageUnit;
      delete rest.clCropList;
    });

    delete materialTo.clMaterialRestrictionList;

    return restrictions;
  }

  function mapActiveSubstances(materialTo) {
    var activeSubstances = [];
    _.each(materialTo.clMaterialActiveSubstanceList, function (activeSubst) {
      activeSubst.substance = activeSubst.clMaterialSubstance;
      delete activeSubst.clMaterialSubstance;
      activeSubstances.push(activeSubst);
    });

    delete materialTo.clMaterialActiveSubstanceList;

    return activeSubstances;
  }
}
