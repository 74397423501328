import { PrecisionState } from "../../../reducers/precision.reducer.types";

export const getPrecisionParcelListPage = (state: PrecisionState) =>
  state.ui.precisionParcelsList.page;
export const getPrecisionParcelListOrder = (state: PrecisionState) =>
  state.ui.precisionParcelsList.order;
export const getPrecisionParcelListOrderBy = (state: PrecisionState) =>
  state.ui.precisionParcelsList.orderBy;
export const getPrecisionParcelListRowsPerPage = (state: PrecisionState) =>
  state.ui.precisionParcelsList.rowsPerPage;
export const getPrecisionParcelListTextFilter = (state: PrecisionState) =>
  state.ui.precisionParcelsList.textFilter;
