import { Action } from "../actions/commonActions";
import ACTIONS from "../actions/commonContants";

const initialState = {
  activePage: "",
  prevPage: "",
};

type NewState = typeof initialState;

export default () =>
  (state = initialState, action: Action): NewState => {
    switch (action.type) {
      case ACTIONS.SET_ACTIVE_PAGE:
        return { ...state, activePage: action.path };

      case ACTIONS.SET_PREV_PAGE:
        return { ...state, prevPage: action.path };

      default:
        return state;
    }
  };
