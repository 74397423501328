import { AnyAction } from "redux";

import * as types from "../actions/signup.constants";

export const NAMESPACE = "signup";

export default (reducerNamespace = NAMESPACE) =>
  (
    state = {
      langId: "en-US",
      isFetching: false,
      isEagriAuth: false,
      farmName: "",
      farmShpValidationResult: 0,
      country: null,
    },
    action: AnyAction,
  ) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case types.LANG_CHANGE:
        return {
          ...state,
          langId: action.langId,
        };
      case types.SET_FETCH:
        return {
          ...state,
          isFetching: action.isFetching,
        };
      case types.SET_IS_EAGRI_AUTH:
        return {
          ...state,
          isEagriAuth: action.isEagriAuth,
        };
      case types.SET_FARM_NAME:
        return {
          ...state,
          farmName: action.farmName,
        };
      case types.SET_SHP_VALIDATION:
        return {
          ...state,
          farmShpValidationResult: action.farmShpValidationResult,
        };
      case types.SET_COUNTRY:
        return {
          ...state,
          country: action.country,
        };
      default:
        return state;
    }
  };
