import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  heading: {
    fontSize: "13px",
    display: "inline",
    fontWeight: 500,
    margin: 0,
  },
  custom: {},
};

const CfSectionHeading = (props) => {
  const { children, classes } = props;
  return <h3 className={`${classes.heading} ${classes.custom}`}>{children}</h3>;
};

CfSectionHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(CfSectionHeading);
