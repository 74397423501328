import React, { Fragment } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";

import { getActions } from "../../../../shared/api/agroevidence/actions/actions.selectors";
import { getVariableActionIds } from "../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import { FormattedAmount } from "../../../../shared/components/common/FormattedAmount/FormattedAmount";
import { CropName } from "../../../../shared/components/specific/CropName/CropName";
import { COLOR_GREY } from "../../../../theme";
import ActionLabel from "../components/ActionLabel/ActionLabel";

export const getPage = (state) => state.ui.actionList.page;
export const getRowsPerPage = (state) => state.ui.actionList.rowsPerPage;
export const getOrder = (state) => state.ui.actionList.order;
export const getOrderBy = (state) => state.ui.actionList.orderBy;
export const getSelected = (state) => state.ui.actionList.selected;
export const getTextFilter = (state) => state.ui.actionList.textFilter;
export const getDateFilter = (state) => state.ui.actionList.dateFilter;
export const getDraftsOnly = (state) => state.ui.actionList.draftsOnly;
export const getActionsAdvancedFilter = (state) =>
  state.ui.actionList.advancedFilter;

const getFarmId = (state, props) => props.match.params.farmId;
export const getProcessedActions = createSelector(
  getActions,
  getFarmId,
  getVariableActionIds,
  (actions, farmId, variableActionIds) =>
    processValues(actions, farmId, variableActionIds || []),
);

export const getSelectedOnPage = createSelector(
  getActions,
  getSelected,
  (items, selected) =>
    _.intersection(
      items.map((item) => item.id),
      selected,
    ),
);

const styles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryValue: {
      color: COLOR_GREY[500],
    },
  },
};

const ItemWrapper = (props) => (
  <span>
    <p style={styles.head.p}>{props.children}</p>
  </span>
);

const getActionMaterial = (expense) => expense?.materialName ?? "-";

const getActionDose = (expense) =>
  expense?.amount ? <FormattedAmount expense={expense} param="amount" /> : "-";
const getActionDosage = (expense) =>
  expense?.dosePerHa ? (
    <FormattedAmount expense={expense} param="dosePerHa" perHa={true} />
  ) : (
    "-"
  );

ItemWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

const processValues = (actions, farmId, variableActionIds) =>
  actions.map((a) => ({
    id: a.id,
    route: "actions.action",
    single: {
      "dateStart,id": {
        val: <FormattedDate value={a.date} />,
        rowspan: a.expenses.length,
      },
      "actionType.localizedNames.value": {
        val: (
          <Fragment>
            <span style={{ fontWeight: 500 }}>
              <FormattedMessage id={`common.${a.actionType.code}`} />
            </span>
            {a.isDraft && (
              <Tooltip title={<FormattedMessage id="ActionsList.isDraft" />}>
                <WarningIcon
                  color="secondary"
                  style={{ marginLeft: 12, verticalAlign: "middle" }}
                />
              </Tooltip>
            )}
            {variableActionIds.includes?.(a.id) && <ActionLabel />}
          </Fragment>
        ),
        rowspan: a.expenses.length,
      },
      source: {
        val: <FormattedMessage id={`common.action.source.${a.source}`} />,
        rowspan: a.expenses.length,
      },
      landUse: {
        val: (
          <div style={{ ...styles.head.p, ...styles.head.secondaryValue }}>
            <CropName cropType={a.seedApplicationType}>{a.crop?.name}</CropName>
          </div>
        ),
        rowspan: a.expenses.length,
      },
      localName: {
        val: (
          <span>
            {a.parcels.map((parcel) => (
              <p key={parcel.id} style={styles.head.p}>
                <Link
                  key={parcel.id}
                  to={`/farm/${farmId}/parcels/${parcel.id}/overview`}
                >
                  {`${parcel.blockNumber ? `${parcel.blockNumber} ` : ""}${
                    parcel.name
                  }`}
                </Link>
              </p>
            ))}
          </span>
        ),
        rowspan: a.expenses.length,
      },
      material: {
        val: <ItemWrapper>{getActionMaterial(a.expenses?.[0])}</ItemWrapper>,
      },
      dose: {
        val: <ItemWrapper>{getActionDose(a.expenses?.[0])}</ItemWrapper>,
      },
      dosePerHectare: {
        val: <ItemWrapper>{getActionDosage(a.expenses?.[0])}</ItemWrapper>,
      },
    },
    multi: a.expenses.slice(1).map((expense) => ({
      material: {
        val: (
          <ItemWrapper key={expense.amount}>
            {getActionMaterial(expense)}
          </ItemWrapper>
        ),
      },
      dose: {
        val: (
          <ItemWrapper key={expense.amount}>
            {getActionDose(expense)}
          </ItemWrapper>
        ),
      },
      dosePerHectare: {
        val: (
          <ItemWrapper key={expense.amount}>
            {getActionDosage(expense)}
          </ItemWrapper>
        ),
      },
    })),
  }));
