import React from "react";

import CloseIcon from "@mui/icons-material/Clear";
import ExtensionIcon from "@mui/icons-material/Extension";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";

import { useNoVarioDocStyles } from "./styles";

export const NO_VARIODOC_BANNER_COOKIE = "noVariodocBannerHidden";

type Props = {
  farmId: string;
  ngRedirectToUserIntegrations: (farmId: string) => void;
};

const NoVarioDoc = ({ farmId, ngRedirectToUserIntegrations }: Props) => {
  const classes = useNoVarioDocStyles();
  const [cookies, setCookie] = useCookies([NO_VARIODOC_BANNER_COOKIE]);

  const buttonOnClick = () => {
    ngRedirectToUserIntegrations(farmId);
  };

  const onCloseClick = () => {
    const cookieExists = cookies[NO_VARIODOC_BANNER_COOKIE] !== undefined;
    if (!cookieExists) {
      // expires at midnight:
      setCookie(NO_VARIODOC_BANNER_COOKIE, true, {
        expires: moment().endOf("day").toDate(),
      });
    }
  };

  return !cookies[NO_VARIODOC_BANNER_COOKIE] ? (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <div className={classes.leftSideTitle}>
          <FormattedMessage id="VarioDoc.noVarioDoc.title" />
        </div>
        <div className={classes.leftSideDesc}>
          <FormattedMessage id="VarioDoc.noVarioDoc.desc" />
        </div>
      </div>
      <div className={classes.rightSide}>
        <Button
          className={classes.button}
          color="primary"
          onClick={buttonOnClick}
          startIcon={<ExtensionIcon />}
          variant="contained"
        >
          <FormattedMessage id="VarioDoc.noVarioDoc.button.redirection" />
        </Button>
      </div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onCloseClick}
        style={{ padding: 12, width: 20, height: 20 }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : null;
};

export default NoVarioDoc;
