import React, { Suspense } from "react";

import { Switch, Route } from "react-router-dom";

import { CfLoaderPage } from "../../../../../shared/components/common/CfLoaderPage/CfLoaderPage";
import ParcelDetailWrapper from "../../../detail/shared/containers/ParcelDetailWrapper/ParcelDetailWrapper";
import ParcelListWrapper from "../../../list/containers/ParcelListWrapper/ParcelListWrapper";

const ParcelsWrapper = (props) => (
  <Suspense fallback={<CfLoaderPage />}>
    <Switch>
      <Route
        exact
        path="/farm/:farmId/parcels"
        render={(routerProps) => (
          <ParcelListWrapper {...props} {...routerProps} />
        )}
      />
      <Route
        path="/farm/:farmId/parcels/:parcelId"
        render={(routerProps) => (
          <ParcelDetailWrapper {...props} {...routerProps} />
        )}
      />
    </Switch>
  </Suspense>
);

export default ParcelsWrapper;
