import PropTypes from "prop-types";

import consumerFactory from "./helpers/consumerFactory";
import contextFactory from "./helpers/contextFactory";
import providerFactory from "./helpers/providerFactory";

// USAGE:

// PropName which will be supplied to the provider
const propName = "config";

// The name used as a hoc function name
const hocName = "withConfig";

// component prefix for DevTools
const componentPrefix = "CfConfig";

// Shape definition of the provided object. Define ONLY the provided object.
// PropTypes are extended later with:
// children: func
// context: object
const propTypes = {
  [propName]: PropTypes.shape({
    api: PropTypes.shape({
      apiUrl: PropTypes.string.isRequired,
      gatewayUrl: PropTypes.string.isRequired,
      iotUrl: PropTypes.string.isRequired,
      storesUrl: PropTypes.string.isRequired,
      apiDocUrl: PropTypes.string.isRequired,
      weatherUrl: PropTypes.string.isRequired,
      sentinelUrl: PropTypes.string.isRequired,
      geoserverUrl: PropTypes.string.isRequired,
      geoserverWorkspaceCore: PropTypes.string.isRequired,
      geoserverWorkspaceIot: PropTypes.string.isRequired,
      hotjarId: PropTypes.string.isRequired,
      gtmId: PropTypes.string.isRequired,
      sentryDsn: PropTypes.string.isRequired,
      bing_key: PropTypes.string.isRequired,
      intercom_app_id: PropTypes.string.isRequired,
    }),
    environment: PropTypes.string.isRequired,
  }).isRequired,
};

const ctx = contextFactory(componentPrefix);

const Provider = providerFactory(ctx, propName, propTypes);

/*
 * The Consumer HOC accepts two options:
 * propName: String - if you want to inject the value under different name;
 * forwardRef: Boolean - false
 */
const Consumer = consumerFactory(
  ctx.Consumer,
  propName,
  componentPrefix,
  hocName,
);

export const CfConfigProvider = Provider;
export default Consumer;
