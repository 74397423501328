import { getLayers } from "../../selectors/map.selectors";

import * as types from "./layersUI.constants";

export const storeInitialLayers = (layers) => ({
  type: types.STORE_INITIAL_LAYERS,
  layers,
});

export const setLayerVisibility = (layer, visible) => (dispatch, getState) => {
  const layersService = getLayers(getState());
  layersService.setLayerVisibilityInMap(layer, visible);
  dispatch({
    type: types.SET_LAYER_VISIBILITY,
    layerId: layer.layerId,
    visible,
  });
};

export const setAllLayersVisibility =
  (layers, visible) => (dispatch, getState) => {
    const layersService = getLayers(getState());
    layers.forEach((layer) => {
      layersService.setLayerVisibilityInMap(layer, visible);
    });
    dispatch({
      type: types.SET_ALL_LAYERS_VISIBILITY,
      visible,
    });
  };
