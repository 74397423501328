import { createSelector } from "reselect";

import { getIsAreaDetail, getIsAreasList } from "./common.selectors";
import { getAreas } from "../../shared/api/irrigation/areas/areas.selectors";
import {
  getPoints,
  getAssignedPoints,
  getDevicesWithCoordinates,
} from "../../shared/api/irrigation/devices/devices.selectors";

export const getMapColumns = (state) => state.ui.irrMap.mapColumns;
export const getMapExtent = (state) => state.ui.irrMap.extent;
export const getIsSelecting = (state) => state.ui.irrMap.isSelecting;
export const getSelectionCoords = (state) => state.ui.irrMap.selectionCoords;
export const isEnlargedVariant = (state) => state.ui.irrMap.enlarged;

export const getPointsBySectionType = createSelector(
  [
    getPoints,
    getAssignedPoints,
    getDevicesWithCoordinates,
    getIsAreaDetail,
    getIsAreasList,
  ],
  (
    points,
    assignedPoints,
    areaDevicesWithCoordinate,
    isAreaDetail,
    isAreasList,
  ) => {
    if (isAreaDetail) {
      return areaDevicesWithCoordinate;
    }
    if (isAreasList) {
      return assignedPoints;
    }
    return points;
  },
);

export const getDevicesIdsForEachIrrigationArea = createSelector(
  getAreas,
  (areas) =>
    areas.map((area) => {
      const devicesIdArray = area.devices.map((device) => device.id);
      return {
        id: area.id,
        devicesId: devicesIdArray,
      };
    }),
);
