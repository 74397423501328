import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

import { FILE_MAX_SIZE_MB } from "../../../core/precision/precision.constants";

import { FileImportStatus } from "../../../shared/components/fileImport/useFileImport";

import FileItem from "./FileItem";
import { useFileUploaderStyles } from "./style";

type FileLoadItemStatus =
  | "new"
  | "validated"
  | "isImporting"
  | "success"
  | "error";

export type FileLoadItem = {
  errorCode?: string;
  file: File;
  status: FileLoadItemStatus;
  uniqueId: string;
};

type Props = {
  allFiles: FileLoadItem[];
  handleFilesValidationApi: (newFiles: FileLoadItem[]) => void;
  setAllFiles: React.Dispatch<React.SetStateAction<FileLoadItem[]>>;
  status: FileImportStatus;
};

const FileUploader: React.FC<Props> = ({
  allFiles,
  handleFilesValidationApi,
  setAllFiles,
  status,
}) => {
  const classes = useFileUploaderStyles();
  const isLoading = () => status === "validating" || status === "submitting";

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "application/zip": [".zip"],
      // 'application/xml': ['.xml'],
      // 'text/csv': ['.csv'],
    },
    onDrop: (accepted) => {
      const newFiles = [...accepted] as File[];
      const newFilesToSave: FileLoadItem[] = newFiles.map(
        (file) =>
          ({
            file,
            uniqueId: _.uniqueId(),
            status: "new",
          }) as FileLoadItem,
      );
      setAllFiles((prevState) => [...prevState, ...newFilesToSave]);
      handleFilesValidationApi(newFilesToSave);
    },
    disabled: isLoading(),
  });

  const handleRemoveFile = (idToRemove?: string) => {
    setAllFiles((prevFiles) =>
      prevFiles.filter((file) => file.uniqueId !== idToRemove),
    );
  };

  return (
    <section className={classes.container}>
      <div
        className={classNames(classes.body, {
          [classes.bodyIsLoading]: status === "validating",
        })}
      >
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <div className={classes["dropzone-title"]}>
            <FormattedMessage id="FileUploader.title" />
          </div>
          {!allFiles.length && (
            <div className={classes["dropzone-formats"]}>
              <FormattedMessage
                id="FileUploader.formats"
                values={{ maxSize: FILE_MAX_SIZE_MB }}
              />
            </div>
          )}
        </div>
        {allFiles.length > 0 && (
          <aside className={classes.bottomContainer}>
            <ul className={classes.loadedFiles}>
              {allFiles.map((item) => (
                <FileItem
                  isSubmitting={status === "submitting"}
                  item={item}
                  key={item.uniqueId}
                  onRemove={handleRemoveFile}
                />
              ))}
            </ul>
          </aside>
        )}
      </div>
      {isLoading() && (
        <div className={classes.loader}>
          {status === "validating" && (
            <div className={classes.loaderBody}>
              <CircularProgress />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default FileUploader;
