import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { getShortDateString } from "../../../../shared/misc/timeHelpers";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    maxWidth: "400px",
  },
  itemContainer: {
    padding: "5px 0px",
  },
  button: {
    marginLeft: 8,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 30,
  },
  dateSelectorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  statisticsDateRange: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 5,
  },
  grid: {
    marginRight: 30,
  },
}));

interface PorUsageReportDialogProps {
  onAccept: (dateTo: string) => void;
  onClose: () => void;
  showDialog?: boolean;
}

export const PorUsageReportDialog: FC<PorUsageReportDialogProps> = ({
  onAccept,
  onClose,
  showDialog = false,
}) => {
  const lastDate = getShortDateString(
    moment().subtract(1, "months").endOf("month"),
  );

  const classes = useStyles();

  const handleSendReport = () => {
    onAccept(getShortDateString(moment(lastDate)));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="report-por-dialog"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle>
        <FormattedMessage id="Reports.porUsageReport.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        <div>
          <FormattedMessage
            id="Reports.porUsageReport.sendReport.date"
            values={{
              date: moment()
                .subtract(1, "months")
                .endOf("month")
                .format("D.M.yyyy"),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="send"
            onClick={handleSendReport}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.send" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
