import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingFertilizerKind,
  getFertilizerKind,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import {
  getFertilizerKindsApi,
  resetFertilizerKindApi,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { EagriFertilizerKindTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues: EagriFertilizerKindTo;
  disabled?: boolean;
  error?: boolean;
  onChange: (items: EagriFertilizerKindTo) => void;
};
type Props = ReduxProps & OwnProps;

const FertilizerKindSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  fertilizerKind,
  getFertilizerKindsApi,
  isFertilizerKindFetching,
  onChange,
  resetFertilizerKindApi,
}: Props) => {
  useEffect(() => {
    getFertilizerKindsApi();

    return () => {
      resetFertilizerKindApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="fertilizer-kind-selector"
      isFetching={isFertilizerKindFetching}
      isMultiple={false}
      loadOptions={getFertilizerKindsApi}
      onChange={onChange}
      suggestions={fertilizerKind}
      testId="fertilizer-kind-selector"
      label={
        <FormattedMessage id="Catalogues.fertilizers.detail.fertilizerKind" />
      }
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  fertilizerKind: getFertilizerKind(state),
  isFertilizerKindFetching: getIsFetchingFertilizerKind(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerKindsApi,
      resetFertilizerKindApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FertilizerKindSelector);
