import React, { FC, useState, useEffect, ReactNode, useRef } from "react";

import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { FieldInputProps, FormikProps } from "formik";

import { useTypedIntl } from "../../../hooks/useTypedIntl";
import Localization from "../../../services/Localization.service";

const useStyles = makeStyles({
  helperText: {
    position: "absolute",
    bottom: -22,
  },
});

interface Props {
  children: ReactNode;
  customClasses: Record<string, string>;
  field: FieldInputProps<string>;
  form: FormikProps<Record<string, unknown>>;
  onBlur?: (e?: React.BaseSyntheticEvent) => void;
  shrinkLabel: boolean;
  validateOnBlur: boolean;
}

// Component displays number formatted by respective locale.
// Should return number after onBlur event. On change event it returns string.
const CfFormikNumericTextField: FC<Props> = (props) => {
  const {
    children,
    customClasses,
    field,
    form,
    onBlur,
    shrinkLabel,
    validateOnBlur,
    ...restProps
  } = props;

  const intl = useTypedIntl();
  const classes = useStyles();
  const blurEventRef = useRef<React.BaseSyntheticEvent>();

  const [localValue, setLocalValue] = useState<string>(
    Localization.num2strNonFixed(field.value, intl.locale),
  );

  const [useLocalValue, setUseLocalValue] = useState(false);

  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    if (isBlurred) {
      if (onBlur) {
        onBlur(blurEventRef.current);
      }
      if (validateOnBlur) {
        form.validateField(field.name);
      }
      setIsBlurred(false);
      blurEventRef.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlurred]);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    setUseLocalValue(true);
    setIsBlurred(false);
    setLocalValue(e.currentTarget.value);
    field.onChange(e);
  };

  const handleBlur = (e: React.BaseSyntheticEvent) => {
    setUseLocalValue(false);
    form.setFieldValue(
      field.name,
      Localization.str2numNonFixed(localValue, intl.locale),
    );
    // because formik cannot do async field change, we need to for one more update cycle before running blur event
    setIsBlurred(true);
    blurEventRef.current = e;
  };

  const shrinkLabelProps = {
    InputLabelProps: { shrink: true },
  };
  const error = form.errors[field.name];

  return (
    <TextField
      classes={customClasses}
      error={Boolean(error)}
      helperText={error}
      name={field.name}
      onBlur={handleBlur}
      onChange={handleChange}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
      value={
        useLocalValue
          ? localValue
          : Localization.num2strNonFixed(field.value, intl.locale)
      }
      {...(shrinkLabel && shrinkLabelProps)}
      {...restProps}
    >
      {children}
    </TextField>
  );
};

export default CfFormikNumericTextField;
