import React, { FC, Fragment } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import EditableText from "../../../../shared/components/common/EditableText/EditableText";
import MapBottomPanel from "../../../../shared/components/specific/MapBottomPanel/MapBottomPanel";
import MapBottomPanelHeader from "../../../../shared/components/specific/MapBottomPanelHeader/MapBottomPanelHeader";
import useWidth from "../../../../shared/hooks/useWidth";
import MapParcelDetailBody from "../MapParcelDetailBody/MapParcelDetailBody";

import {
  ParcelDetailTo,
  SowingPlanSeasonCropTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  parcelNameWrapper: {
    display: "flex",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  detailGroup: {
    margin: 5,
  },
  parcelDetailLink: {
    textTransform: "uppercase",
    fontWeight: 500,
    margin: "2px 0px",
  },
  parcelBlockNumber: {
    color: theme.palette.grey[500],
    marginRight: 10,
  },
  paper: {
    padding: "0px 15px",
    overflow: "auto",
    width: "75%",
    boxShadow: "none",
  },
}));

interface MapParcelDetailProps {
  assignCropSowingPlan: (
    cropId: string,
    seasonId: string,
    parcelId: string,
  ) => void;
  clearParcelDetail: () => void;
  countryCode: string;
  farmId: string;
  isCurrentStateSowingPlanSelected: boolean;
  isFetching: boolean;
  parcel: ParcelDetailTo;
  parcelSowingPlan: SowingPlanSeasonCropTo[];
  updateParcelName: (parcelId: string, parcelName: string) => void;
}

const MapParcelDetail: FC<MapParcelDetailProps> = ({
  assignCropSowingPlan,
  clearParcelDetail,
  countryCode,
  farmId,
  isCurrentStateSowingPlanSelected,
  isFetching = false,
  parcel,
  parcelSowingPlan,
  updateParcelName,
}) => {
  const classes = useStyles();
  const width = useWidth();

  const confirmEditing = (newName: string) =>
    updateParcelName(parcel.id, newName);

  // TODO - replace link to parcel detail with NavLink tag
  return (
    <MapBottomPanel onPanelClose={clearParcelDetail}>
      <Fragment>
        <MapBottomPanelHeader testId="parcel-detail-header">
          <div>
            <div className={classes.parcelNameWrapper}>
              <div
                className={classes.parcelBlockNumber}
                data-test="block-number"
              >
                {parcel.blockNumber}
              </div>
              <EditableText
                initialValue={parcel.localName}
                labelTestId="parcel-name"
                maxWidth={width === "xs" || width === "sm" ? "200px" : "140px"}
                truncate={true}
                onConfirmEditing={(newValue: string) =>
                  confirmEditing(newValue)
                }
              />
            </div>
            <div className={classes.parcelDetailLink}>
              <a
                color="primary"
                data-test="parcel-detail-link"
                href={`/farm/${farmId}/parcels/${parcel.id}/overview`}
              >
                <FormattedMessage id="common.detail" />
              </a>
            </div>
          </div>
        </MapBottomPanelHeader>
        <MapParcelDetailBody
          assignCropSowingPlan={assignCropSowingPlan}
          countryCode={countryCode}
          isCurrentStateSowingPlanSelected={isCurrentStateSowingPlanSelected}
          isFetching={isFetching}
          parcel={parcel}
          parcelSowingPlan={parcelSowingPlan}
        />
      </Fragment>
    </MapBottomPanel>
  );
};

export default MapParcelDetail;
