import React, { Component } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import * as notificationsStatus from "../../notificationsStatus";

const style = {
  tooltip: {
    margin: 4,
  },
};

export class FarmNotificationsStatus extends Component {
  constructor(props) {
    super(props);
    this.USER_STATES = {
      ACTIVE: "active",
      INACTIVE: "inactive",
    };
  }

  getStatusToDisplay = (status) => {
    if (status === notificationsStatus.ENABLED) {
      return this.USER_STATES.ACTIVE;
    } else if (
      status === notificationsStatus.DISABLED ||
      status === notificationsStatus.INACTIVE
    ) {
      return this.USER_STATES.INACTIVE;
    }
    return this.USER_STATES.INACTIVE;
  };

  getIcon = (status) => {
    const { testId } = this.props;
    switch (status) {
      case this.USER_STATES.ACTIVE:
        return <CheckCircleIcon color="primary" data-test={testId} />;
      case this.USER_STATES.INACTIVE:
        return <CancelIcon color="disabled" data-test={testId} />;
      default:
        return <CancelIcon color="disabled" data-test={testId} />;
    }
  };

  render() {
    const { classes, status } = this.props;
    const statusToDisplay = this.getStatusToDisplay(status);
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<FormattedMessage id={`common.${statusToDisplay}`} />}
      >
        {this.getIcon(statusToDisplay)}
      </Tooltip>
    );
  }
}

FarmNotificationsStatus.propTypes = {
  classes: PropTypes.object,
  testId: PropTypes.string,
  status: PropTypes.string,
};

FarmNotificationsStatus.defaultProps = {
  classes: {},
  testId: "status-icon",
  status: "",
};

export default injectIntl(withStyles(style)(FarmNotificationsStatus));
