import angular from "angular";

import reportsController from "./reports.controller";

const reportsComponent = {
  bindings: {
    farm: "<",
  },
  controller: reportsController,
};

export default angular
  .module("app.reports", [])
  .config(config)
  .component("reportsComponent", reportsComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider.state("farm.active.reports", {
    url: "/reports",
    views: {
      "farm@farm.active": {
        component: "reportsComponent",
      },
    },
  });
}
