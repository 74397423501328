const PREFIX = "AGROEVIDENCE_CATALOGUES";

export const GET_PLANT_PROTECTION_REQUEST = `${PREFIX}/GET_PLANT_PROTECTION_REQUEST`;
export const GET_PLANT_PROTECTION_SUCCESS = `${PREFIX}/GET_PLANT_PROTECTION_SUCCESS`;
export const GET_PLANT_PROTECTION_ERROR = `${PREFIX}/GET_PLANT_PROTECTION_ERROR`;
export const RESET_PLANT_PROTECTION = `${PREFIX}/RESET_PLANT_PROTECTION`;

export const UPDATE_PLANT_PROTECTION_REQUEST = `${PREFIX}/UPDATE_PLANT_PROTECTION_REQUEST`;
export const UPDATE_PLANT_PROTECTION_SUCCESS = `${PREFIX}/UPDATE_PLANT_PROTECTION_SUCCESS`;
export const UPDATE_PLANT_PROTECTION_ERROR = `${PREFIX}/UPDATE_PLANT_PROTECTION_ERROR`;

export const GET_ACTIVE_SUBSTANCES_REQUEST = `${PREFIX}/GET_ACTIVE_SUBSTANCES_REQUEST`;
export const GET_ACTIVE_SUBSTANCES_SUCCESS = `${PREFIX}/GET_ACTIVE_SUBSTANCES_SUCCESS`;
export const GET_ACTIVE_SUBSTANCES_ERROR = `${PREFIX}/GET_ACTIVE_SUBSTANCES_ERROR`;
export const RESET_ACTIVE_SUBSTANCES = `${PREFIX}/RESET_ACTIVE_SUBSTANCES`;

export const GET_ORGANISMS_REQUEST = `${PREFIX}/GET_ORGANISMS_REQUEST`;
export const GET_ORGANISMS_SUCCESS = `${PREFIX}/GET_ORGANISMS_SUCCESS`;
export const GET_ORGANISMS_ERROR = `${PREFIX}/GET_ORGANISMS_ERROR`;
export const RESET_ORGANISMS = `${PREFIX}/RESET_ORGANISMS`;

export const GET_BIO_FUNCTION_REQUEST = `${PREFIX}/GET_BIO_FUNCTION_REQUEST`;
export const GET_BIO_FUNCTION_SUCCESS = `${PREFIX}/GET_BIO_FUNCTION_SUCCESS`;
export const GET_BIO_FUNCTION_ERROR = `${PREFIX}/GET_BIO_FUNCTION_ERROR`;
export const RESET_BIO_FUNCTION = `${PREFIX}/RESET_BIO_FUNCTION`;
