import React, { Fragment } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

const styles = (theme) => ({
  wrapper: {
    fontSize: 13,
    marginLeft: 13,
    marginRight: 13,
  },
  item: {
    marginLeft: 12,
  },
  itemLabel: {
    color: theme.palette.grey[500],
  },
  itemValue: {
    marginLeft: 3,
    fontWeight: 500,
  },
});

function SensorChartStatistics(props) {
  const { classes, statistics } = props;
  return (
    <div className={classes.wrapper}>
      {statistics.map((item) => (
        <span
          className={classes.item}
          key={`${item.featureName}_${item.type.id}`}
        >
          <span className={classes.itemLabel}>
            <FormattedMessage id={`SensorChartStatistics.${item.type.id}`} />
          </span>
          <span
            className={classes.itemValue}
            style={{ color: item.strokeColor }}
            data-test={`${item.featureName}_${item.type.id}`
              .replace("FEATURE_", "")
              .replace("_AVG", "")}
          >
            {isNaN(parseFloat(item.value)) ? (
              "-"
            ) : (
              <Fragment>
                <CfFormattedNumber
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                  value={item.value || 0}
                />{" "}
                {item.unit}
              </Fragment>
            )}
          </span>
        </span>
      ))}
    </div>
  );
}

SensorChartStatistics.propTypes = {
  classes: PropTypes.object.isRequired,
  statistics: PropTypes.array.isRequired,
};

SensorChartStatistics.defaultProps = {
  statistics: [],
};

export default withStyles(styles)(SensorChartStatistics);
