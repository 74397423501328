import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: "34px 50px 20px",
  },
  dialogContent: {
    padding: "0px 50px 24px 50px",
  },
  dialogContainer: {
    position: "relative",
    paddingRight: "10px",
  },
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 10,
  },
};

function CfDialog(props) {
  const { children, classes, dialogHeight, maxWidth, opened, title } = props;
  const {
    acceptText,
    cancelText,
    dataTest,
    disabled,
    onAccept,
    onCancel,
    onClose,
    onEnter,
  } = props;
  const {
    acceptButtonVariant,
    externalStyle = {},
    onCloseIcon,
    processing,
  } = props;

  return (
    <Dialog
      data-test={dataTest}
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={onClose}
      open={opened}
      classes={{
        paper: classnames(
          classes.dialogContainer,
          externalStyle.dialogContainer,
        ),
      }}
      TransitionProps={{
        onEnter,
      }}
    >
      {!isEmpty(title) && (
        <DialogTitle
          className={classnames(classes.title, externalStyle.title)}
          data-test="dialog-title"
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        data-test="dialog-content"
        style={{ height: dialogHeight }}
        classes={{
          root: classnames(classes.dialogContent, externalStyle.dialogContent),
        }}
      >
        {React.Children.only(children)}
      </DialogContent>
      <DialogActions
        data-test="dialog-actions"
        className={classnames(
          classes.dialogActions,
          externalStyle.dialogActions,
        )}
      >
        {onCancel && (
          <Button
            className={classnames(externalStyle.cancelButton)}
            data-test="cancel-button"
            id="dismiss"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        {onAccept && (
          <Button
            color="primary"
            data-test="accept-button"
            disabled={disabled}
            id="accept"
            onClick={onAccept}
            variant={acceptButtonVariant}
          >
            {acceptText}
          </Button>
        )}
        {onCloseIcon && (
          <IconButton
            className={classnames(classes.closeIcon, externalStyle.closeIcon)}
            disabled={processing}
            onClick={onCloseIcon}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

CfDialog.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  onCancel: PropTypes.func,
  onCloseIcon: PropTypes.func,
  onAccept: PropTypes.func,
  cancelText: PropTypes.any,
  acceptText: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  opened: PropTypes.bool,
  classes: PropTypes.object,
  onEnter: PropTypes.func,
  onClose: PropTypes.func,
  dialogHeight: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  acceptButtonVariant: PropTypes.string,
  externalStyle: PropTypes.object,
};

CfDialog.defaultProps = {
  title: "",
  maxWidth: "sm",
  opened: false,
  cancelText: "Cancel",
  acceptText: "OK",
  onCancel: undefined,
  onAccept: undefined,
  onEnter: () => {},
  onClose: () => {},
  classes: {},
  dialogHeight: "auto",
  dataTest: "dialog",
  disabled: false,
  processing: false,
};

export default withStyles(styles)(CfDialog);
