import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getParcels,
  getIsFetchingParcels,
} from "../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getTool, getSelectedParcel } from "../../selectors/editor.selectors";
import { getContext } from "../../selectors/map.selectors";

import {
  editorSetHoveredId,
  editorSetSelected,
  editorToolEnd,
} from "../../actions/editor/editor.actions";
import {
  fetchMapSearchSuggestions,
  clearMapSearchSuggestions,
  fetchParcelDetail,
  zoomToGeometry,
  zoomToCoords,
  clearParcelDetail,
  setMapContext,
} from "../../actions/map/map.actions";
import {
  mapAddMarker,
  mapRemoveMarker,
} from "../../actions/marker/marker.actions";
import { refreshDefaultStyles } from "../../actions/style/style.actions";

// import * as contexts from '../../constants/contexts.constants';

import { getParcelApi } from "../../../../shared/api/agroevidence/parcels/parcels.api";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import ParcelCoordSelector from "../../components/ParcelCoordSelector/ParcelCoordSelector";

const styles = () => ({
  wrapper: {
    height: "32px",
    width: "250px",
    marginRight: 0,
  },
  bgr: {
    borderRadius: "4px",
  },
});

export class MapSearch extends Component {
  constructor(props) {
    super(props);
    this.lastSelected = null;
  }

  onSuggestionSelect = (suggestion) => {
    this.onSuggestionReset();

    if (suggestion.id) {
      if (this.props.tool) {
        this.props.editorToolEnd(this.props.tool);
      }

      // if (this.props.context === contexts.WEATHER) {
      //   this.props.setMapContext(contexts.PREVIEW);
      // }

      this.props.getParcelApi(suggestion.id).then(({ payload }) => {
        this.props.zoomToGeometry(payload.geometry);
      });

      this.props.editorSetSelected([{ id: suggestion.id }]);
      this.props.fetchParcelDetail(suggestion.id);
      this.props.refreshDefaultStyles();
    } else if (suggestion.lat) {
      this.props.mapAddMarker(suggestion);
      this.onSuggestionReset();
      this.props.zoomToCoords([suggestion.lon, suggestion.lat]);
    }

    this.lastSelected = suggestion;
  };

  onSuggestionReset = () => {
    const { selectedParcel } = this.props;
    if (
      this.lastSelected &&
      selectedParcel &&
      this.lastSelected.id === selectedParcel.id
    ) {
      this.props.editorSetSelected([]);
      this.props.clearParcelDetail();
      this.props.refreshDefaultStyles();
    } else if (this.lastSelected && this.lastSelected.lat) {
      this.props.mapRemoveMarker();
    }
    this.lastSelected = null;
  };

  onItemHover = (itemId) => {
    if (!this.props.tool) {
      this.props.editorSetHoveredId(itemId);
      this.props.refreshDefaultStyles();
    }
  };

  getMapSearchSuggestions = (textFilter, reason) => {
    if (!textFilter && reason === "input-focused") {
      return Promise.resolve();
    }
    return this.props.fetchMapSearchSuggestions(textFilter.replace(/,/g, ""));
  };

  render() {
    const { classes, isFetching, suggestions } = this.props;
    return (
      <ToolbarSection
        classes={{
          wrapper: classes.wrapper,
          bgr: classes.bgr,
        }}
      >
        <ParcelCoordSelector
          getSuggestions={this.getMapSearchSuggestions}
          isFetching={isFetching}
          onItemHover={this.onItemHover}
          onSuggestionClear={this.props.clearMapSearchSuggestions}
          onSuggestionReset={this.onSuggestionReset}
          onSuggestionSelect={this.onSuggestionSelect}
          suggestions={suggestions.map((sugg) => ({ ...sugg }))}
        />
      </ToolbarSection>
    );
  }
}

MapSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedParcel: PropTypes.object,
  tool: PropTypes.string,
  fetchMapSearchSuggestions: PropTypes.func.isRequired,
  clearMapSearchSuggestions: PropTypes.func.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
  refreshDefaultStyles: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  editorToolEnd: PropTypes.func.isRequired,
  fetchParcelDetail: PropTypes.func.isRequired,
  getParcelApi: PropTypes.func.isRequired,
  zoomToGeometry: PropTypes.func.isRequired,
  clearParcelDetail: PropTypes.func.isRequired,
  zoomToCoords: PropTypes.func.isRequired,
  mapAddMarker: PropTypes.func.isRequired,
  mapRemoveMarker: PropTypes.func.isRequired,
  // setMapContext: PropTypes.func.isRequired,
  // context: PropTypes.string.isRequired,
  suggestions: PropTypes.array,
};

MapSearch.defaultProps = {
  selectedParcel: {},
  tool: null,
  suggestions: [],
};

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingParcels()(state),
  selectedParcel: getSelectedParcel(state),
  tool: getTool(state),
  suggestions: getParcels()(state),
  context: getContext(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMapSearchSuggestions,
      clearMapSearchSuggestions,
      editorSetHoveredId,
      refreshDefaultStyles,
      editorSetSelected,
      editorToolEnd,
      fetchParcelDetail,
      getParcelApi,
      zoomToGeometry,
      clearParcelDetail,
      zoomToCoords,
      mapAddMarker,
      mapRemoveMarker,
      setMapContext,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MapSearch));
