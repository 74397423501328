import { AnyAction } from "redux";

import * as types from "./areas.constants";

import {
  getAreaProgramUpdateDurations,
  getAreaPrograms,
  setAreaProgramUpdateDurations,
} from "./areas.helpers";

const initialState = {
  isFetching: false,
  items: [],
  totalCount: 0,
  item: {},
  isFetchingArea: false,
  itemPrograms: [],
  isFetchingAreaPrograms: false,
  itemProgram: {
    programName: "",
    irrigationDays: [],
    startTimes: [],
    durations: [],
  },
  isFetchingAreaProgram: false,
  isAreaDeleting: false,
  isAreaProgramDeleting: false,
  apiError: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_AREAS_REQUEST:
        return { ...state, isFetching: true };
      case types.GET_AREAS_SUCCESS:
        return {
          ...state,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
          isFetching: false,
        };
      case types.GET_AREAS_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case types.GET_AREA_REQUEST:
        return {
          ...state,
          isFetchingArea: true,
        };
      case types.GET_AREA_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isFetchingArea: false,
        };
      case types.GET_AREA_ERROR:
        return {
          ...state,
          isFetchingArea: false,
        };
      case types.RESET_AREA:
        return {
          ...state,
          item: { ...initialState.item },
        };
      case types.DELETE_AREA_REQUEST:
        return {
          ...state,
          isAreaDeleting: true,
        };
      case types.DELETE_AREA_SUCCESS:
        return {
          ...state,
          isAreaDeleting: false,
        };
      case types.DELETE_AREA_ERROR:
        return {
          ...state,
          isAreaDeleting: false,
          apiError: true,
        };
      case types.GET_AREA_PROGRAMS_REQUEST:
        return {
          ...state,
          isFetchingAreaPrograms: true,
        };
      case types.GET_AREA_PROGRAMS_SUCCESS:
        return {
          ...state,
          itemPrograms: getAreaPrograms(action.payload),
          isFetchingAreaPrograms: false,
        };
      case types.GET_AREA_PROGRAMS_ERROR:
        return {
          ...state,
          isFetchingAreaPrograms: false,
        };
      case types.GET_AREA_PROGRAM_REQUEST:
        return {
          ...state,
          isFetchingAreaProgram: true,
        };
      case types.GET_AREA_PROGRAM_SUCCESS:
        return {
          ...state,
          itemProgram: {
            ...state.itemProgram,
            programId: action.payload.programId,
            programName: action.payload.programName,
            irrigationDays: action.payload.irrigationDays,
            startTimes: action.payload.startTimes,
            durations: getAreaProgramUpdateDurations(
              action.payload.durations,
              state.itemProgram.durations,
            ),
          },
          isFetchingAreaProgram: false,
        };
      case types.GET_AREA_PROGRAM_ERROR:
        return {
          ...state,
          isFetchingAreaProgram: false,
        };
      case types.DELETE_AREA_PROGRAM_REQUEST:
        return {
          ...state,
          isAreaProgramDeleting: false,
        };
      case types.DELETE_AREA_PROGRAM_SUCCESS:
        return {
          ...state,
          isAreaProgramDeleting: false,
        };
      case types.DELETE_AREA_PROGRAM_ERROR:
        return {
          ...state,
          isAreaProgramDeleting: false,
          apiError: true,
        };
      case types.SET_AREA_PROGRAM:
        return {
          ...state,
          itemProgram: {
            ...state.itemProgram,
            irrigationDays: action.irrigationDays
              ? state.itemProgram.irrigationDays.concat(action.irrigationDays)
              : state.itemProgram.irrigationDays,
            startTimes: action.startTimes
              ? state.itemProgram.startTimes.concat(action.startTimes)
              : state.itemProgram.startTimes,
            durations: setAreaProgramUpdateDurations(action.areaDevices),
          },
        };
      case types.RESET_AREA_PROGRAM:
        return {
          ...state,
          itemProgram: { ...initialState.itemProgram },
        };

      case types.CREATE_AREA_ERROR:
      case types.EDIT_AREA_ERROR:
      case types.EDIT_AREA_NAME_ERROR:
      case types.CREATE_AREA_PROGRAM_ERROR:
      case types.UPDATE_AREA_PROGRAM_ERROR:
        return {
          ...state,
          apiError: true,
        };

      case types.RESET_API_ERROR:
        return {
          ...state,
          apiError: false,
        };

      default:
        return state;
    }
  };
