import moment from "moment";

import {
  validateParcels,
  validateActionDate,
  validateExpenses,
} from "../../shared/misc/action.helpers";

import { ActionType } from "../../../../shared/api/agroevidence/agroevidence.types";

export const STRONG_ZONES_SEEDING = "STRONG_ZONES_SEEDING";
export const WEAK_ZONES_SEEDING = "WEAK_ZONES_SEEDING";
export const VRS_CODE = "SEED";

export const getInitialValues = () => ({
  actionDate: moment().startOf("day"),
  parcels: [],
  expenses: [],
  fertilizationType: {
    id: WEAK_ZONES_SEEDING,
  },
  zonesCount: null,
  variability: null,
  minMappingUnit: null,
  targetCrop: null,
  actionNote: "",
  cropUseType: null,
});

export const validate = (values) => {
  const parcels = validateParcels(values);
  const expenses = validateExpenses(values, "SeedSelector.choose-seed-error");
  const actionDate = validateActionDate(values);

  return {
    ...(parcels ? { parcels } : {}),
    ...(expenses ? { expenses } : {}),
    ...(actionDate ? { actionDate } : {}),
  };
};

export const warn = () => ({});

// Transforms what goes to LocalStorage
export const lsTransformFnc = (values) => ({
  ...values,
  expenses: values.expenses.map((expense) => {
    const { variableExpense, ...rest } = expense;
    return rest;
  }),
});

export const isVrs = (action, variableActionIds = []) => {
  if (!action || !action.id) return false;

  return (
    action.actionType === ActionType.SOWING &&
    variableActionIds?.includes(action.id)
  );
};
