import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getLocation,
  getIsFetchingLocation,
  getErrorLocation,
} from "../../selectors/locations.selectors";
import { getNewNode } from "../../selectors/node.selectors";

import {
  fetchNodeLocation,
  resetNodeLocation,
  update,
} from "../../actions/locations.actions";
import {
  setEnlargedVariant,
  storeSelectedFeatureId,
  storeHoveredFeatureId,
} from "../../actions/map.actions";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import SensorsService from "../../../shared/services/Sensors.service";
import NodeActivation from "../NodeActivation/NodeActivation";
import NodeLocationDetail from "../NodeLocationDetail/NodeLocationDetail";
import PestPredictionsSettings from "../PestPredictionsSettings/PestPredictionsSettings";

const styles = {
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
};

export class NodeLocation extends Component {
  constructor(props) {
    super(props);
    props.setEnlargedVariant(false);
  }

  componentDidMount() {
    this.props.fetchNodeLocation(this.props.match.params.sensorId);
    this.props.storeSelectedFeatureId(this.props.match.params.sensorId);
    this.props.storeHoveredFeatureId(null);
  }

  componentDidUpdate(prevProps) {
    const { errorNodeLocation, history, match } = this.props;
    if (this.props.match.params.sensorId !== prevProps.match.params.sensorId) {
      this.props.fetchNodeLocation(this.props.match.params.sensorId);
      this.props.storeSelectedFeatureId(this.props.match.params.sensorId);
      this.props.storeHoveredFeatureId(null);
    }
    if (errorNodeLocation.isError) {
      history.push(`/farm/${match.params.farmId}/sensors`);
    }
  }

  componentWillUnmount() {
    this.props.resetNodeLocation();
  }

  render() {
    const {
      classes,
      isFetching,
      isMapDisplayed,
      newNode,
      ngGoToNewNotification,
      nodeLocation,
    } = this.props;
    const isHistoric = SensorsService.isHistoric(nodeLocation);
    const computedFeatures = nodeLocation.computedFeatures
      ? nodeLocation.computedFeatures
      : [];

    return isFetching ? (
      <CfLoader />
    ) : (
      <div className={classes.body}>
        <Switch>
          <Route
            exact
            path="/farm/:farmId/sensors/:sensorId"
            render={(routerProps) => (
              <NodeLocationDetail
                isMapDisplayed={isMapDisplayed}
                ngGoToNewNotification={ngGoToNewNotification}
                nodeLocation={nodeLocation}
                {...routerProps}
              />
            )}
          />
          <Route
            exact
            path="/farm/:farmId/sensors/:sensorId/activate"
            render={(routerProps) => (
              <NodeActivation
                newNode={newNode}
                nodeLocation={nodeLocation}
                oldNode={nodeLocation.node}
                {...routerProps}
              />
            )}
          />
          {!isHistoric && (
            <Route
              exact
              path="/farm/:farmId/sensors/:sensorId/predictions-settings"
              render={(routerProps) => (
                <PestPredictionsSettings
                  nodeLocationId={nodeLocation.id}
                  predictions={computedFeatures}
                  {...routerProps}
                />
              )}
            />
          )}
        </Switch>
      </div>
    );
  }
}

NodeLocation.propTypes = {
  newNode: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  fetchNodeLocation: PropTypes.func.isRequired,
  resetNodeLocation: PropTypes.func.isRequired,
  nodeLocation: PropTypes.object,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isMapDisplayed: PropTypes.bool,
  setEnlargedVariant: PropTypes.func.isRequired,
  storeSelectedFeatureId: PropTypes.func.isRequired,
  storeHoveredFeatureId: PropTypes.func.isRequired,
  ngGoToNewNotification: PropTypes.func.isRequired,
  errorNodeLocation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

NodeLocation.defaultProps = {
  newNode: {},
  nodeLocation: {},
  isMapDisplayed: true,
};

const mapStateToProps = (state, props) => ({
  nodeLocation: getLocation(state, props),
  newNode: getNewNode(state),
  isFetching: getIsFetchingLocation(state),
  errorNodeLocation: getErrorLocation(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      update,
      fetchNodeLocation,
      resetNodeLocation,
      setEnlargedVariant,
      storeSelectedFeatureId,
      storeHoveredFeatureId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NodeLocation));
