import React, { FC } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { FieldInputProps, FormikProps } from "formik";

import SubtractableArea from "../SubtractableArea/SubtractableArea";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface SubtractableAreaWithCheckboxProps {
  field: FieldInputProps<SubtractionResponse>;
  form: FormikProps<Record<string, unknown>>;
  handleSubtractionDelete: (type: string, area: number) => void;
  isEditing: boolean;
}

const SubtractableAreaWithCheckbox: FC<SubtractableAreaWithCheckboxProps> = ({
  field,
  form,
  handleSubtractionDelete,
  isEditing = false,
}) => {
  const classes = useStyles();

  const isChecked = field.value?.isUsed === true;

  return (
    <div className={classes.saRow}>
      <div>
        <Checkbox
          checked={isChecked}
          color="primary"
          disabled={!isEditing}
          onChange={(event, value) => {
            form.setFieldValue(field.name, { ...field.value, isUsed: value });
          }}
        />
        <SubtractableArea sa={field.value} />
      </div>
      {isEditing && (
        <IconButton
          aria-label="Remove item"
          className={classes.removeButton}
          disabled={!isEditing}
          size="small"
          onClick={() => {
            handleSubtractionDelete(field.value.type, field.value.area);
          }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

export default SubtractableAreaWithCheckbox;

const useStyles = makeStyles((theme: Theme) => ({
  saRow: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      "& $removeButton": {
        opacity: 1,
      },
    },
  },
  removeButton: {
    height: 26,
    width: 26,
    padding: 0,
    marginLeft: 6,
    opacity: 0,
  },
}));
