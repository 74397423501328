import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./units.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getUnits = () => ({
  [RSAA]: {
    endpoint: "units?",
    method: methods.GET,
    module: modules.CORE,
    types: [types.GET_UNITS, types.GET_UNITS_SUCCESS, types.GET_UNITS_ERROR],
  },
});

export const convertAmount = (amount, from, to) => {
  const p = {
    srcUnit: from,
    dstUnit: to,
    amount,
  };

  return {
    [RSAA]: {
      endpoint: `units/convert?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_CONVERT_UNIT,
        types.GET_CONVERT_UNIT_SUCCESS,
        types.GET_CONVERT_UNIT_ERROR,
      ],
    },
  };
};

export const resetConvertedAmount = () => ({
  type: types.RESET_CONVERT_UNIT,
});
