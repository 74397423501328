import React, { FC } from "react";

import { makeStyles } from "@mui/styles";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import ManagementZoneIcon from "../../../../shared/icons/ManagementZoneIcon";

import { Zone } from "../../../../shared/api/sentinel/management/management.types";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "15%",
  },
  zone: {
    margin: "0 12px 0 5px",
    fontSize: 12,
  },
  icon: {
    width: "10px",
    height: "10px",
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
}));

interface ManagementZonesLegendProps {
  zones: Zone[];
}

const ManagementZonesLegend: FC<ManagementZonesLegendProps> = ({ zones }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {zones.map((zone) => (
        <span
          className={classes.item}
          data-test="management-zones-legend"
          key={zone.zoneNumber}
        >
          <span className={classes.icon}>
            <ManagementZoneIcon color={zone.color} display="block" />
          </span>
          <span className={classes.zone} style={{ color: `#${zone.color}` }}>
            <CfFormattedNumber decimalDigits={1} value={zone.areaHa} /> ha
          </span>
        </span>
      ))}
    </div>
  );
};

export default ManagementZonesLegend;
