import React, { Fragment } from "react";

import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SensorsPestPredictionService from "../../services/SensorsPestPrediction.service";
import PestPredictionValue from "../PestPredictionValue/PestPredictionValue";

const styles = (theme) => ({
  iconImg: {
    color: "grey",
    width: 20,
    height: 16,
  },
  pestIcon: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    marginBottom: 4,
  },
  tooltipDesc: {
    color: theme.palette.grey[300],
  },
  pestValue: {
    verticalAlign: "middle",
  },
  pestValues: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
});

function PestPrediction(props) {
  const { classes, displayValues, pestPrediction } = props;
  const { name: pestPredictionName, values } = pestPrediction;
  const pestPredictionValue = SensorsPestPredictionService.getLastValue(values);
  const additionalValue =
    SensorsPestPredictionService.getSpreadingValue(values);
  const pestPredictionConfig =
    SensorsPestPredictionService.getPredictionTypeConfig(pestPredictionName);
  const { containsAdditionalValue, icon: Icon } = pestPredictionConfig;
  const pestPredictionValueLimit =
    SensorsPestPredictionService.getPredictionTypeDescriptionByValue(
      pestPredictionName,
      pestPredictionValue,
    );
  const additionalValueLimit =
    SensorsPestPredictionService.getPredictionTypeDescriptionByValue(
      pestPredictionName,
      additionalValue,
      true,
    );
  const maxValue =
    SensorsPestPredictionService.getPredictionMaxLimit(pestPredictionName);
  const maxPestPredictionValue =
    pestPredictionValue > maxValue ? maxValue : pestPredictionValue;
  let iconColor = pestPredictionValueLimit?.color;
  if (additionalValue && additionalValue < pestPredictionValue) {
    iconColor = additionalValueLimit?.color;
  }

  return (
    <div data-test="last-pest-prediction">
      {pestPredictionValueLimit ? (
        <Tooltip
          placement="bottom"
          classes={{
            tooltip: classes.tooltip,
          }}
          title={
            <Fragment>
              <div>
                <FormattedMessage id={pestPredictionConfig.name} />
              </div>
              {displayValues && (
                <Fragment>
                  <div className={classes.tooltipDesc}>
                    <FormattedMessage
                      id={pestPredictionValueLimit?.translationId}
                      values={{ count: pestPredictionValue }}
                    />
                  </div>
                  {containsAdditionalValue && !!additionalValue && (
                    <div className={classes.tooltipDesc}>
                      <FormattedMessage
                        id={additionalValueLimit?.translationId}
                        values={{ count: additionalValue }}
                      />
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          }
        >
          <div
            className={classes.pestValues}
            data-test={pestPredictionConfig.name}
          >
            <span className={classes.pestIcon}>
              <Icon className={classes.iconImg} style={{ color: iconColor }} />
            </span>
            {displayValues && (
              <span className={classes.pestValue}>
                <PestPredictionValue
                  pestPredictionColor={pestPredictionValueLimit?.color}
                  pestPredictionValue={maxPestPredictionValue}
                  style={{ verticalAlign: "middle" }}
                />
                {containsAdditionalValue && (
                  <Fragment>
                    <span style={{ color: pestPredictionValueLimit?.color }}>
                      {" "}
                      |{" "}
                    </span>
                    <PestPredictionValue
                      pestPredictionColor={additionalValueLimit?.color}
                      pestPredictionValue={additionalValue}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Fragment>
                )}
              </span>
            )}
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
}

PestPrediction.propTypes = {
  classes: PropTypes.object,
  pestPrediction: PropTypes.object.isRequired,
  displayValues: PropTypes.bool,
};

PestPrediction.defaultProps = {
  classes: {},
  displayValues: true,
};

export default withStyles(styles)(PestPrediction);
