import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  limit: {
    width: 2,
    height: 12,
    position: "absolute",
    zIndex: 1,
    backgroundColor: theme.palette.grey[500],
  },
});

function PestPredictionGraphLimits(props) {
  const { classes, graphWidth, limits, maxValue } = props;
  return limits.map((limitValue) => {
    const k = graphWidth / maxValue;
    const left = limitValue * k;
    return (
      left < graphWidth - 1 && (
        <span
          className={classes.limit}
          data-test="limit"
          key={`limit_${limitValue}`}
          style={{ left }}
        >
          {}
        </span>
      )
    );
  });
}

PestPredictionGraphLimits.propTypes = {
  classes: PropTypes.object,
  limits: PropTypes.array.isRequired,
  maxValue: PropTypes.number.isRequired,
  graphWidth: PropTypes.number.isRequired,
};

PestPredictionGraphLimits.defaultProps = {
  classes: {},
};

export default withStyles(styles)(PestPredictionGraphLimits);
