import React from "react";

import BaseContainer from "../../../containers/BaseContainer/BaseContainer";
import CfLoader from "../CfLoader/CfLoader";

export const CfLoaderPage = () => (
  <BaseContainer langId="en-US" translations={{}}>
    <CfLoader />
  </BaseContainer>
);
