const PREFIX = "TELEMATICS_MACHINES_AGGREGATIONS";

export const GET_MACHINE_AGGREGATIONS_REQUEST = `${PREFIX}/GET_MACHINE_AGGREGATIONS_REQUEST`;
export const GET_MACHINE_AGGREGATIONS_SUCCESS = `${PREFIX}/GET_MACHINE_AGGREGATIONS_SUCCESS`;
export const GET_MACHINE_AGGREGATIONS_ERROR = `${PREFIX}/GET_MACHINE_AGGREGATIONS_ERROR`;

export const GET_MACHINE_AGGREGATIONS_REQUEST_NOREDUCER = `${PREFIX}/GET_MACHINE_AGGREGATIONS_REQUEST_NOREDUCER`;
export const GET_MACHINE_AGGREGATIONS_SUCCESS_NOREDUCER = `${PREFIX}/GET_MACHINE_AGGREGATIONS_SUCCESS_NOREDUCER`;
export const GET_MACHINE_AGGREGATIONS_ERROR_NOREDUCER = `${PREFIX}/GET_MACHINE_AGGREGATIONS_ERROR_NOREDUCER`;

export const RESET_API_ERROR = `${PREFIX}/RESET_API_ERROR`;
export const RESET_EXPORT_ERROR = `${PREFIX}/RESET_EXPORT_ERROR`;

export const EXPORT_AGGREGATED_MACHINES_REQUEST = `${PREFIX}/EXPORT_AGGREGATED_MACHINES_REQUEST`;
export const EXPORT_AGGREGATED_MACHINES_SUCCESS = `${PREFIX}/EXPORT_AGGREGATED_MACHINES_SUCCESS`;
export const EXPORT_AGGREGATED_MACHINES_ERROR = `${PREFIX}/EXPORT_AGGREGATED_MACHINES_ERROR`;

export const SET_FOCUSED_ROW = `${PREFIX}/SET_FOCUSED_ROW`;
