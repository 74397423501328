import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  button: {
    color: "white",
    float: "right",
  },
};

const StoreFabButton = (props) => {
  const { callback, classes } = props;
  return (
    <div>
      <Fab
        aria-label="add to store"
        className={classes.button}
        color="secondary"
        onClick={callback}
        size="medium"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

StoreFabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default withStyles(styles)(StoreFabButton);
