import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  List,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import IrrigationCounter from "./components/IrrigationCounter";
import IrrigationDaysSelector from "./components/IrrigationDaysSelector";
import IrrigationDevice from "./components/IrrigationDevice";
import IrrigationStartTimeInput from "./components/IrrigationStartTimeInput";
import IrrigationStartTimeList from "./components/IrrigationStartTimeList";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "19.6875rem",
    minHeight: "29.0625rem",
  },
  itemContainer: {
    padding: "0.3125rem 0rem",
  },
  formLabel: {
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1.0625rem",
    color: theme.palette.grey[500],
    marginBottom: "0.3125rem",
  },
  programName: {
    display: "flex",
    justifyContent: "left",
    marginBottom: "1.25rem",
    color: theme.palette.grey[500],
    fontWeight: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.3125rem",
  },
  programNameInput: {
    fontWeight: "500",
    color: theme.palette.primary.main,
    paddingBottom: "0.125rem",
    "&::placeholder": {
      color: theme.palette.grey[500],
    },
  },
  paper: {
    maxHeight: 150,
    overflow: "auto",
  },
  list: {
    padding: 0,
  },
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  button: {
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    margin: "0.3125rem 0rem",
  },
  buttonDelete: {
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    margin: "0.3125rem 0rem",
    color: theme.palette.error.main,
  },
  dialogCheckError: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginLeft: "0.5rem",
  },
}));

const IrrigationPlanCalendarModal = ({
  areaProgram,
  isExistingEvent,
  onClose,
  onCreate,
  onDelete,
  onEdit,
  showDialog,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [isTouched, setIsTouched] = useState(isExistingEvent);
  const [generalCount, setGeneralCount] = useState(0);
  const [currentEvent, setCurrentEvent] = useState(areaProgram);
  const [isGeneralCountActive, setIsGeneralCountActive] = useState(false);
  const [showIrrigationDaysError, setShowIrrigationDaysError] = useState(false);
  const [showIrrigationStartTimeError, setShowIrrigationStartTimeError] =
    useState(false);
  const [showIrrigationDevicesError, setShowIrrigationDevicesError] =
    useState(false);

  const onChangeProgramName = (evt) => {
    evt.preventDefault();
    setCurrentEvent({ ...currentEvent, programName: evt.target.value });
    setIsTouched(false);
  };

  const onChangeIrrigationDays = (day) => {
    const isActive = currentEvent.irrigationDays.indexOf(day);
    if (isActive === -1) {
      setCurrentEvent((prevState) => ({
        ...prevState,
        irrigationDays: prevState.irrigationDays.concat(day),
      }));
    } else {
      setCurrentEvent((prevState) => ({
        ...prevState,
        irrigationDays: prevState.irrigationDays.filter((item) => item !== day),
      }));
    }
    setShowIrrigationDaysError(false);
    setIsTouched(false);
  };

  const onInputStartTimes = (time) => {
    const isExistTime = currentEvent.startTimes.indexOf(time);
    if (isExistTime === -1) {
      setCurrentEvent((prevState) => ({
        ...prevState,
        startTimes: prevState.startTimes.concat(time).sort(),
      }));
      setShowIrrigationStartTimeError(false);
      setIsTouched(false);
    }
  };

  const onDeleteStartTimes = (time) => {
    setCurrentEvent((prevState) => ({
      ...prevState,
      startTimes: prevState.startTimes.filter((item) => item !== time),
    }));
    setIsTouched(false);
  };

  const generalCountIncrement = () => {
    const newGeneralCount = generalCount + 60;
    setCurrentEvent((prevState) => ({
      ...prevState,
      durations: updateDurationsValvesByGeneralCount(
        prevState.durations,
        newGeneralCount,
      ),
    }));
    setGeneralCount(newGeneralCount);
    setIsGeneralCountActive(true);
    setIsTouched(false);
  };

  const generalCountDecrement = () => {
    const newGeneralCount = Math.max(generalCount - 60, 0);
    setCurrentEvent((prevState) => ({
      ...prevState,
      durations: updateDurationsValvesByGeneralCount(
        prevState.durations,
        newGeneralCount,
      ),
    }));
    setGeneralCount(newGeneralCount);
    setIsGeneralCountActive(true);
    setIsTouched(false);
  };

  const onChangeDurations = (idDev, idVal, durationSeconds) => {
    const tempEventDurations = currentEvent.durations;
    tempEventDurations[idDev].valves[idVal].durationSeconds = durationSeconds;
    setCurrentEvent((prevState) => ({
      ...prevState,
      durations: tempEventDurations,
    }));
    setIsGeneralCountActive(false);
    setShowIrrigationDevicesError(false);
    setIsTouched(false);
  };

  const onCheckedDurations = (idDev, checked) => {
    const tempEventDurations = currentEvent.durations;
    tempEventDurations[idDev].checked = checked;
    tempEventDurations[idDev].valves = tempEventDurations[idDev].valves.map(
      (valve) => ({
        ...valve,
        durationSeconds: generalCount,
      }),
    );
    setCurrentEvent((prevState) => ({
      ...prevState,
      durations: tempEventDurations,
    }));
    setShowIrrigationDevicesError(false);
    setIsTouched(false);
  };

  const handleSubmitCreate = () => {
    const newEvent = payloadPrepareCurrentEvent(currentEvent);
    validationHandleSubmit(newEvent, onCreate);
  };

  const handleSubmitUpdate = () => {
    const updateEvent = payloadPrepareCurrentEvent(currentEvent);
    validationHandleSubmit(updateEvent, onEdit);
  };

  const validationHandleSubmit = (event, onSubmit) => {
    const selectedIrrigationDays = event.irrigationDays.length;
    const selectedStartTimes = event.startTimes.length;
    const selectedDevices = event.durations.some(
      (item) => item.valves.length > 0,
    );
    if (selectedIrrigationDays === 0) {
      setShowIrrigationDaysError(true);
    }
    if (selectedStartTimes === 0) {
      setShowIrrigationStartTimeError(true);
    }
    if (!selectedDevices) {
      setShowIrrigationDevicesError(true);
    }
    if (
      selectedIrrigationDays !== 0 &&
      selectedStartTimes !== 0 &&
      selectedDevices
    ) {
      onSubmit(event);
    }
  };

  const handleSubmitDelete = () => onDelete(currentEvent.programId);

  const updateDurationsValvesByGeneralCount = (programDevices, generalCount) =>
    programDevices.map((device) =>
      device.checked === false
        ? device
        : {
            ...device,
            valves: device.valves.map((valve) => ({
              ...valve,
              durationSeconds: generalCount,
            })),
          },
    );

  const payloadPrepareCurrentEvent = (tempEvent) => ({
    ...tempEvent,
    durations: tempEvent.durations.map((device) => ({
      id: device.id,
      valves: device.valves.filter((valve) => valve.durationSeconds > 0),
    })),
  });

  return (
    <div className={classes.container}>
      <Dialog data-test="dialog" open={showDialog}>
        <DialogContent>
          <Grid
            alignItems="baseline"
            className={classes.itemContainer}
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <TextField
                className={classes.programName}
                inputProps={{ className: classes.programNameInput }}
                name="programName"
                onChange={onChangeProgramName}
                value={currentEvent.programName}
                variant="standard"
                placeholder={intl.formatMessage({
                  id: "Irrigation.planCalendarModal.namePlaceholder",
                })}
              />
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                className={classes.closeIcon}
                onClick={onClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            alignItems="baseline"
            container
            direction="column"
            justifyContent="flex-start"
            spacing={0}
          >
            <Grid item>
              <FormLabel className={classes.formLabel} component="legend">
                <FormattedMessage id="Irrigation.planCalendarModal.startDay" />
              </FormLabel>
            </Grid>
            <Grid item>
              <IrrigationDaysSelector
                onChangeIrrigationDays={onChangeIrrigationDays}
                plan={currentEvent.irrigationDays}
              />
            </Grid>
            {showIrrigationDaysError && (
              <div className={classes.dialogCheckError}>
                <FormattedMessage id="Irrigation.planCalendarModal.errorMsg" />
              </div>
            )}
          </Grid>
          <Grid
            alignItems="baseline"
            container
            direction="column"
            justifyContent="flex-start"
            spacing={0}
          >
            <Grid
              alignItems="baseline"
              className={classes.itemContainer}
              container
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item>
                <FormLabel className={classes.formLabel} component="legend">
                  <FormattedMessage id="Irrigation.planCalendarModal.startTime" />
                </FormLabel>
              </Grid>
              <Grid item>
                <IrrigationStartTimeInput
                  onInputStartTimes={onInputStartTimes}
                />
              </Grid>
            </Grid>
            <IrrigationStartTimeList
              onDeleteStartTimes={onDeleteStartTimes}
              plan={currentEvent.startTimes}
            />
            {showIrrigationStartTimeError && (
              <div className={classes.dialogCheckError}>
                <FormattedMessage id="Irrigation.planCalendarModal.errorMsg" />
              </div>
            )}
          </Grid>
          <Grid
            alignItems="baseline"
            className={classes.itemContainer}
            container
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item>
              <FormLabel className={classes.formLabel} component="legend">
                <FormattedMessage id="Irrigation.planCalendarModal.lengthEachValve" />
              </FormLabel>
            </Grid>
            <Grid item>
              <IrrigationCounter
                active={isGeneralCountActive}
                checked={true}
                count={generalCount}
                handleDecrement={generalCountDecrement}
                handleIncrement={generalCountIncrement}
              />
            </Grid>
          </Grid>
          <Grid
            alignItems="stretch"
            className={classes.itemContainer}
            container
            direction="column"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item>
              <FormLabel className={classes.formLabel} component="legend">
                <FormattedMessage id="Irrigation.planCalendarModal.irrigationDevices" />
              </FormLabel>
              {showIrrigationDevicesError && (
                <div className={classes.dialogCheckError}>
                  <FormattedMessage id="Irrigation.planCalendarModal.errorMsg" />
                </div>
              )}
            </Grid>
            <Grid item>
              <Paper className={classes.paper} variant="outlined">
                <List className={classes.list}>
                  {currentEvent.durations.map((device, id) => (
                    <IrrigationDevice
                      device={device}
                      idDevice={id}
                      key={device.id}
                      onChangeDurations={onChangeDurations}
                      onCheckedDurations={onCheckedDurations}
                    />
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isTouched && isExistingEvent ? (
            <Grid
              alignItems="center"
              container
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item>
                <Button
                  className={classes.buttonDelete}
                  onClick={handleSubmitDelete}
                  variant="text"
                >
                  <FormattedMessage id="Irrigation.planCalendarModal.deleteButton" />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              alignItems="center"
              container
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <Button
                  className={classes.button}
                  id="reset"
                  onClick={onClose}
                  type="reset"
                  variant="contained"
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  color="primary"
                  id="create"
                  type="submit"
                  variant="contained"
                  onClick={
                    isExistingEvent ? handleSubmitUpdate : handleSubmitCreate
                  }
                >
                  <FormattedMessage
                    id={
                      isExistingEvent
                        ? "Irrigation.planCalendarModal.saveButton"
                        : "common.create"
                    }
                  />
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

IrrigationPlanCalendarModal.propTypes = {
  areaProgram: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  isExistingEvent: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default IrrigationPlanCalendarModal;
