import { RSAA } from "redux-api-middleware";

import * as types from "./radarList.constants";
import { API_METHODS as methods } from "../../api.constants";

export const getRadar = () => ({
  [RSAA]: {
    endpoint: "https://s3.eu-central-1.amazonaws.com/cf-weather",
    method: methods.GET,
    types: [
      types.GET_RADAR_LIST,
      {
        type: types.GET_RADAR_LIST_SUCCESS,
        payload: (action, state, res) =>
          res.text().then((str) => {
            const responseDoc = new DOMParser().parseFromString(
              str,
              "application/xml",
            );
            const imagesNames = Array.from(
              responseDoc.getElementsByTagName("Key"),
            ).map((name) => name.textContent);
            return imagesNames.slice(imagesNames.length - 13);
          }),
      },
      types.GET_RADAR_LIST_ERROR,
    ],
  },
});
