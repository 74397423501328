import { RSAAAction } from "redux-api-middleware";

import {
  getMainMapTelematicsDateFrom,
  getMainMapTelematicsDateTo,
  getMainMapTelematicsDriverFilter,
  getMainMapTelematicsMachineFilter,
} from "../../selectors/mainMapTelematics.selectors";
import { getMainMapTelematics } from "../../selectors/map.selectors";

import { setAllCropsVisibility } from "../crops/crops.actions";
import {
  setDoubleClickZoomIA,
  removeDoubleClickZoomIA,
} from "../interaction/interaction.actions";

import * as types from "./mainMapTelematics.constants";
import {
  RESET_MACHINE_DRIVES_HISTORY,
  SET_MACHINE_SELECTOR_FILTER,
} from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.constants";

import {
  getMachinePositionsApi,
  getMachinesApi,
  getDriversApi,
  getMachineDrivesHistoryApi,
  getMachinesGroupsApi,
} from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.api";
import {
  getShortDateString,
  isToday,
} from "../../../../shared/misc/timeHelpers";
import { Thunk } from "../../../../types";
import MainMapTelematics from "../../services/MainMapTelematics.service";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import {
  getMachinePositionsApiParams,
  getMachineDrivesHistoryApiParams,
} from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.types";
import {
  DriverTo,
  MachineTo,
  DailyPositionTo,
  PositionDetailTo,
} from "../../../../shared/api/telematics/telematics.types";

export const setDate = (dateFrom: string, dateTo: string) => ({
  type: types.SET_DATE,
  dateFrom,
  dateTo,
});
export const setMachineFilter = (machineFilter: MachineTo[]) => ({
  type: types.SET_MACHINE_FILTER,
  payload: machineFilter,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});

export const setDriverFilter = (driver: DriverTo[]) => ({
  type: types.SET_DRIVER_FILTER,
  payload: driver,
});

const setSelectedMachineGpsUnitStore = (gpsUnit?: string) => ({
  type: types.SET_SELECTED_MACHINE_GPS_UNIT,
  payload: gpsUnit,
});

const setMainMapTelematicsLayer =
  () => (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    mainMapTelematics.setMachinePositionsLayer();
    mainMapTelematics.setMachineDrivesHistoryLayer();
  };

const unsetMainMapTelematicsLayer =
  () => (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    if (!mainMapTelematics) return;
    mainMapTelematics.unsetMachinePositionsLayer();
    mainMapTelematics.unsetMachineDrivesHistoryLayer();
    mainMapTelematics.unSetEvents();
  };

export const fetchMachinePositions =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();

    const params: getMachinePositionsApiParams = {
      drivers: getMainMapTelematicsDriverFilter(state)
        .map((driverFilter) => driverFilter.code)
        .join(", "),
      machines: getMainMapTelematicsMachineFilter(state)
        .map((machineFilter) => machineFilter.gpsUnit)
        .join(", "),
    };

    return dispatch(getMachinePositionsApi(params));
  };

export const fetchMachineDrivesHistory =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();

    const params: getMachineDrivesHistoryApiParams = {
      "date-from": getMainMapTelematicsDateFrom(state),
      "date-to": getMainMapTelematicsDateTo(state),
      drivers: getMainMapTelematicsDriverFilter(state)
        .map((driverFilter) => driverFilter.code)
        .join(", "),
      machines: getMainMapTelematicsMachineFilter(state)
        .map((machineFilter) => machineFilter.gpsUnit)
        .join(", "),
    };

    return dispatch(getMachineDrivesHistoryApi(params));
  };

export const resetMachineDrivesHistory = () => ({
  type: RESET_MACHINE_DRIVES_HISTORY,
});

export const fetchMachines =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();
    const dateFrom = getShortDateString(getMainMapTelematicsDateFrom(state));
    const dateTo = getShortDateString(getMainMapTelematicsDateTo(state));

    return dispatch(getMachinesApi({ dateFrom, dateTo }));
  };

export const fetchMachinesGroups =
  (lang: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getMachinesGroupsApi(lang));
  };

export const setMachineSelectorFilter = (machineSelectorFilter: string) => ({
  type: SET_MACHINE_SELECTOR_FILTER,
  machineSelectorFilter,
});

export const fetchDrivers =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();
    const dateFrom = getShortDateString(getMainMapTelematicsDateFrom(state));
    const dateTo = getShortDateString(getMainMapTelematicsDateTo(state));
    dispatch(getDriversApi({ dateFrom, dateTo }));
  };

export const setMachinePositions =
  (machinePositions: PositionDetailTo[]) =>
  (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    mainMapTelematics.setMachinePositions(machinePositions);
  };

export const setMachineDrivesHistory =
  (machineDrivesHistory: DailyPositionTo[]) =>
  (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    const dateTo = getMainMapTelematicsDateTo(getState());
    mainMapTelematics.setMachineDrivesHistory(
      machineDrivesHistory,
      !dateTo || isToday(dateTo),
    );
  };

export const setSelectedMachineGpsUnit =
  (gpsUnit?: string) =>
  (dispatch: Thunk<MainMapState>, getState: () => MainMapState) => {
    dispatch(setSelectedMachineGpsUnitStore(gpsUnit));
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    if (!mainMapTelematics) return;
    mainMapTelematics.setSelectedMachineGpsUnit(gpsUnit);
  };

export const enableTelematics = () => (dispatch: Thunk<MainMapState>) => {
  dispatch(setAllCropsVisibility(false));
  dispatch(setMainMapTelematicsLayer());
  dispatch(setDoubleClickZoomIA());
};

export const disableTelematics = () => (dispatch: Thunk<MainMapState>) => {
  dispatch(unsetMainMapTelematicsLayer());
  dispatch(setSelectedMachineGpsUnit(undefined));
  dispatch(removeDoubleClickZoomIA());
  dispatch(setAllCropsVisibility(false));
};
