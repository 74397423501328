import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  layersHeader: {
    padding: "4px 6px 4px 16px",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
  },
  expanded: {
    backgroundColor: theme.palette.grey[100],
  },
  notExpanded: {
    backgroundColor: theme.palette.common.white,
  },
  expandButton: {
    width: 32,
    height: 32,
    padding: 0,
    alignSelf: "center",
    transform: "rotate(0deg)",
    color: theme.palette.grey[500],
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.instant,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
});

function MapSwitcherHeader(props) {
  const { children, classes, expanded, handleExpansion, testId } = props;

  return (
    <div
      data-test={`${testId}-header`}
      className={`${classes.layersHeader} ${
        expanded ? classes.expanded : classes.notExpanded
      }`}
    >
      {React.Children.only(children)}
      <IconButton
        onClick={handleExpansion}
        size="large"
        className={`${classes.expandButton} ${
          expanded ? classes.expandOpen : ""
        }`}
      >
        <ExpandMoreIcon />
      </IconButton>
    </div>
  );
}

MapSwitcherHeader.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  handleExpansion: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

MapSwitcherHeader.defaultProps = {
  classes: {},
};

export default withStyles(styles)(MapSwitcherHeader);
