import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { IRRIGATION_PLAN } from "../../../../irrigation.constants";

export const IRRIGATION_PLAN_DAYS = [
  {
    code: IRRIGATION_PLAN.DAY_CODES.MONDAY,
    label: "common.daysOfWeek.short.mon",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.TUESDAY,
    label: "common.daysOfWeek.short.tue",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.WEDNESDAY,
    label: "common.daysOfWeek.short.wed",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.THURSDAY,
    label: "common.daysOfWeek.short.thu",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.FRIDAY,
    label: "common.daysOfWeek.short.fri",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.SATURDAY,
    label: "common.daysOfWeek.short.sat",
  },
  {
    code: IRRIGATION_PLAN.DAY_CODES.SUNDAY,
    label: "common.daysOfWeek.short.sun",
  },
];

const isActiveDay = (code, plan) => plan.includes(code);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "200px",
  },
  day: {
    width: "25px",
    height: "25px",
    fontSize: "9px",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const IrrigationPlanIndicators = ({ plan }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {IRRIGATION_PLAN_DAYS.map((day) => {
        const isActive = isActiveDay(day.code, plan);
        return (
          <Avatar
            key={day.code}
            className={classnames({
              [classes.active]: isActive,
              [classes.day]: true,
            })}
          >
            <FormattedMessage id={day.label} />
          </Avatar>
        );
      })}
    </Box>
  );
};

IrrigationPlanIndicators.propTypes = {
  plan: PropTypes.array.isRequired,
};

export default IrrigationPlanIndicators;
