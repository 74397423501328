import React, { FC } from "react";

import { Field, FormikProps } from "formik";
import { round } from "lodash";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikNumericTextField from "../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";

import { DetailContentFormValues } from "../DetailContentForm.types";

interface Props extends FormikProps<DetailContentFormValues> {
  isEditing: boolean;
}

const CultivatedField: FC<Props> = ({ isEditing, setFieldValue, values }) => {
  const handleBlur = () => {
    if (!(Number(values.cultivated) >= 0)) {
      setFieldValue("cultivated", undefined);
    } else {
      setFieldValue("cultivated", round(values.cultivated, 4));
    }
  };

  return (
    <CfFormControl>
      <Field
        component={CfFormikNumericTextField}
        disabled={!isEditing}
        label={<FormattedMessage id="TelematicsList.operationArea" />}
        name="cultivated"
        onBlur={handleBlur}
      />
    </CfFormControl>
  );
};

export default CultivatedField;
