import * as commonTypes from "../../../../../shared/actions/table.constants";
import { ROWS_PER_PAGE } from "../../../../../shared/constants/rowsPerPage.constants";

export const NAMESPACE = "parcelDetail";

export default (reducerNamespace = NAMESPACE) =>
  (
    state = {
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
    },
    action = {},
  ) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
        };
      default:
        return state;
    }
  };
