import React, { Component } from "react";

import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../CfDialog/CfDialog";

class NameEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.item.name || "",
    };
  }

  setName = (name) => {
    this.setState({
      name,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleAccept = () => {
    const { item, onAccept } = this.props;
    const { name } = this.state;
    onAccept({ id: item.id, name });
  };

  render() {
    const { opened, title } = this.props;
    const { name } = this.state;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.edit" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        maxWidth="xs"
        onAccept={this.handleAccept}
        onCancel={this.handleClose}
        onClose={this.handleClose}
        opened={opened}
        title={title}
      >
        <TextField
          data-test="edit-name"
          error={name === ""}
          fullWidth={true}
          label={<FormattedMessage id="common.name" />}
          name="edit-name"
          onChange={(e) => this.setName(e.target.value)}
          value={name}
          helperText={
            name === "" ? <FormattedMessage id="validation.required" /> : ""
          }
        />
      </CfDialog>
    );
  }
}

NameEditDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

NameEditDialog.defaultProps = {
  opened: false,
};

export default NameEditDialog;
