import React, { FC, Fragment, useCallback, useEffect } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";

import { getTool, getStage, getError } from "../../selectors/editor.selectors";
import { getContext } from "../../selectors/map.selectors";

import {
  editorToolStart,
  editorToolSubmit,
  editorToolEnd,
} from "../../actions/editor/editor.actions";

import * as tools from "../../constants/tools.constants";

import ToolbarIconBtn from "../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import BufferIcon from "../../../../shared/icons/mapTools/BufferIcon";
import DrawIcon from "../../../../shared/icons/mapTools/DrawIcon";
import MergeIcon from "../../../../shared/icons/mapTools/MergeIcon";
import SplitIcon from "../../../../shared/icons/mapTools/SplitIcon";
import { Thunk } from "../../../../types";
import DrawToolbar from "../../components/DrawToolbar/DrawToolbar";
import BufferToolbar from "../BufferToolbar/BufferToolbar";
import MergeToolbar from "../MergeToolbar/MergeToolbar";
import SplitToolbar from "../SplitToolbar/SplitToolbar";

import { MainMapState } from "../../../../reducers/map.reducer.types";

const icons: { [key: string]: () => JSX.Element } = {
  [tools.DRAW]: DrawIcon,
  [tools.MERGE]: MergeIcon,
  [tools.SPLIT]: SplitIcon,
  [tools.BUFFER]: BufferIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: 3,
    },
  },
}));

export interface EditorToolbarProps {
  countryCode: string;
  // TODO add correct type when drawParcel part will rewrite to TS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drawParcelCulture?: any;
  drawParcelName?: string;
  editorToolEnd: (tool: string) => void;
  editorToolStart: (tool: string, countryCode: string) => void;
  editorToolSubmit: (tool: string) => void;
  error?: string;
  langId: string;
  stage?: string;
  tool: string;
}

const EditorToolbar: FC<EditorToolbarProps> = ({
  countryCode,
  drawParcelCulture,
  drawParcelName = "",
  editorToolEnd,
  editorToolStart,
  editorToolSubmit,
  error,
  langId,
  stage,
  tool,
}) => {
  const classes = useStyles();

  useEffect(
    () => () => {
      endTool();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const switchTool = (newTool: string) => {
    if (newTool !== tool) {
      endTool();
      editorToolStart(newTool, countryCode);
    }
  };

  const submitTool = () => {
    if (tool) {
      editorToolSubmit(tool);
    }
  };

  const endTool = useCallback(() => {
    if (tool) {
      editorToolEnd(tool);
    }
  }, [editorToolEnd, tool]);

  const getToolIcon = (tool: string, active: boolean) => (
    <ToolbarIconBtn
      active={active}
      aria-label={tool}
      callback={() => switchTool(tool)}
      data-test={tool}
      icon={icons[tool]}
      key={tool}
      product-fruits={`${tool.toString().toLowerCase()} parcel`}
      tooltipTitle={`ToolbarIconBtn.${tool}-tooltip`}
    />
  );

  const editTools = Object.keys(tools).filter(
    (tool) =>
      (countryCode === "CZ" ? tool !== tools.DRAW : true) &&
      tool !== tools.MEASURE,
  );

  return (
    <Fragment>
      {editTools.includes(tool) ? (
        <ToolbarSection background={false}>
          {getToolIcon(tool, true)}
        </ToolbarSection>
      ) : (
        <ToolbarSection background={true}>
          {editTools.map((key) => getToolIcon(key, false))}
        </ToolbarSection>
      )}

      <span className={classes.toolbar}>
        {tool === tools.DRAW && (
          <DrawToolbar
            drawParcelCulture={drawParcelCulture}
            drawParcelName={drawParcelName}
            error={error}
            langId={langId}
            onCancel={endTool}
            onSubmit={submitTool}
            stage={stage}
          />
        )}
        {tool === tools.MERGE && (
          <MergeToolbar
            countryCode={countryCode}
            error={error}
            langId={langId}
            onCancel={endTool}
            onSubmit={submitTool}
            stage={stage}
          />
        )}
        {tool === tools.SPLIT && (
          <SplitToolbar
            error={error}
            langId={langId}
            onCancel={endTool}
            onSubmit={submitTool}
            stage={stage}
          />
        )}
        {tool === tools.BUFFER && (
          <BufferToolbar
            error={error}
            langId={langId}
            onCancel={endTool}
            onSubmit={submitTool}
            stage={stage}
          />
        )}
      </span>
    </Fragment>
  );
};

const selector = formValueSelector("new_parcel");

const mapStateToProps = (state: MainMapState) => ({
  tool: getTool(state),
  stage: getStage(state),
  error: getError(state),
  drawParcelName: selector(state, "name"),
  drawParcelCulture: selector(state, "culture"),
  context: getContext(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      editorToolStart,
      editorToolSubmit,
      editorToolEnd,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditorToolbar);
