import { useIntl } from "react-intl";

import { LANGUAGE_ID } from "../lang/lang.constants";

/**
 * TODO possibly use zod to validate the locale
 */
const useTypedIntl = () => {
  const intl = useIntl();

  return intl as Omit<typeof intl, "locale"> & { locale: LANGUAGE_ID };
};

export { useTypedIntl };
