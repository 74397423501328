/**
 * Created by ondrejzvara on 9.3.16.
 */

/* @ngInject */

export default function cfVarietySelector() {
  const directive = {
    restrict: "E",
    template: require("./cf-variety-selector.html"),
    require: ["ngModel", "cfClassifierSelector"],
    bindToController: {
      model: "=ngModel",
      cropLegCode: "=?",
      external: "=?",
      onStateChange: "=?", // EMPTY, TYPING, SELECTED, CREATE
      disabled: "=?",
      focus: "=?",
    },
    controller: cfVarietySelectorController,
    controllerAs: "cfVarietySelectorCtrl",
  };

  return directive;
}

/* @ngInject */
function cfVarietySelectorController(VarietiesResource) {
  const vm = this;

  vm.createOptionTemplateUrl =
    "cf-varieties-selector-create-option-template.html";
  vm.getVarietiesByName = getVarietiesByName;

  function getVarietiesByName(search) {
    return VarietiesResource.searchVarieties(
      search,
      vm.cropLegCode,
      vm.external,
    ).then((result) =>
      [{ name: search, cropLegislativeCode: vm.cropLegCode }].concat(
        result.plain(),
      ),
    );
  }
}
