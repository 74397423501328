import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const PreviewIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      height="24"
      viewBox="0 0 18 18"
      width="24"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="1px"
    >
      <path
        d="M6.64414 14.2729V9.3788H9.84414V14.2729H13.8441V7.74743H16.2441L8.24414 0.40625L0.244141 7.74743H2.64414V14.2729H6.64414Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export default PreviewIcon;
