import React from "react";

import { Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { connect, ConnectedProps } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getIsFetchingPrecisionParcel,
  getIsFetchingPrecisionParcels,
} from "../../../shared/api/sentinel/precision/precision.selectors";
import {
  getFocusedRow,
  getPrecisionAsAppliedIsFetching,
  getPrecisionTaskdataCount,
} from "../../../shared/api/sentinel/precision/precisionAsApplied.selectors";
import { selectIsFetchingItem } from "../selectors/asAppliedDetail.selectors";
import { getPrecisionAsAppliedSelected } from "../selectors/precisionAsApplied.selectors";

import { PRECISION_URLS } from "../precision.constants";

import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import { useFocusedTableRowTarget } from "../../../shared/hooks/useFocusedTableRowTarget";
import { getLocalizedDateString } from "../../../shared/misc/timeHelpers";
import { Thunk } from "../../../types";
import useAsAppliedDuration from "../hooks/useAsAppliedDuration";

import { useAsAppliedTableRowStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import {
  TaskDataListTaskTo,
  TaskDataListTo,
} from "../../../shared/api/satellite/satellite.types";

interface Props {
  data: TaskDataListTaskTo;
  farmId: string;
  mainRow: TaskDataListTo;
}

type ReduxProps = ConnectedProps<typeof connector>;

const AsAppliedTaskRow = ({
  data,
  farmId,
  focusedRow,
  isFetching,
  isFetchingDetail,
  isFetchingParcel,
  isFetchingParcels,
  mainRow,
  selected,
  tableRowsCount,
}: ReduxProps & Props) => {
  const taskDataId = mainRow.id;
  const classes = useStyles();
  const rowClasses = useAsAppliedTableRowStyles();
  const history = useHistory();
  const { duration: taskDuration } = useAsAppliedDuration(data?.duration ?? 0);
  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit:
      !isFetching &&
      !isFetchingDetail &&
      !isFetchingParcels &&
      !isFetchingParcel &&
      tableRowsCount > 0,
    rowId: `${data.id}-${taskDataId}`,
    behavior: "auto",
  });

  const rowClickRedirectionUrl = generatePath(
    `/farm/${farmId}/${PRECISION_URLS.asAppliedDetail()}`,
    {
      farmId,
      taskDataId,
      taskId: data.id,
    },
  );

  const handleClick = () => history.push(rowClickRedirectionUrl);

  const getCellStyle = () =>
    classNames({
      [rowClasses.archivedSubrow]: mainRow.archived,
      [rowClasses.selectedSubrow]: selected.includes(mainRow.id),
    });

  return (
    <TableRow
      onClick={handleClick}
      ref={targetElementRef}
      className={classNames({
        [classes.row]: true,
        [rowClasses.archivedSubrow]: mainRow.archived,
        [rowClasses.selectedSubrow]: selected.includes(mainRow.id),
      })}
    >
      <CfTableCell className={getCellStyle()} name="name">
        <Tooltip className={classes.firstCell} title="">
          <div>{data.name ?? "-"}</div>
        </Tooltip>
      </CfTableCell>
      <CfTableCell className={getCellStyle()} name="dateFrom">
        <span>
          {data.dateFrom
            ? getLocalizedDateString(data.dateFrom, ["L", "LT"])
            : "-"}
        </span>
      </CfTableCell>
      <CfTableCell className={getCellStyle()} name="dateTo">
        <span>
          {data.dateTo ? getLocalizedDateString(data.dateTo, ["L", "LT"]) : "-"}
        </span>
      </CfTableCell>
      <CfTableCell
        classes={{ root: classes.columnLeft }}
        className={getCellStyle()}
        name="duration"
      >
        <span>{taskDuration}</span>
      </CfTableCell>
      <CfTableCell className={getCellStyle()} name="actions">
        <></>
      </CfTableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  operation: {
    color: theme.palette.primary.dark,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  firstCell: {
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
  },
  columnRight: {
    textAlign: "right",
  },
  columnLeft: {
    textAlign: "left",
  },
  row: {
    cursor: "pointer",
  },
}));

const mapStateToProps = (state: PrecisionState) => ({
  selected: getPrecisionAsAppliedSelected(state),
  focusedRow: getFocusedRow(state),
  isFetching: getPrecisionAsAppliedIsFetching(state),
  tableRowsCount: getPrecisionTaskdataCount(state),
  isFetchingDetail: selectIsFetchingItem(state),
  isFetchingParcels: getIsFetchingPrecisionParcels(state),
  isFetchingParcel: getIsFetchingPrecisionParcel(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AsAppliedTaskRow);
