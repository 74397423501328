export const getAreaPrograms = (action) =>
  action.map((program) => {
    program.title = program.programName;
    const startTime = new Date(program.start);
    program.start = new Date(
      startTime.valueOf() + startTime.getTimezoneOffset() * 60000,
    );
    const endTime = new Date(program.end);
    program.end = new Date(
      endTime.valueOf() + endTime.getTimezoneOffset() * 60000,
    );
    return program;
  });

export const getAreaProgramUpdateDurations = (
  fetchProgramDurations,
  setProgramDurations,
) =>
  fetchProgramDurations.map((fetchProgramDevice) => {
    const setProgramDevice = setProgramDurations.find(
      (d) => d.id === fetchProgramDevice.id,
    );
    return {
      id: setProgramDevice.id,
      name: setProgramDevice.name,
      checked: fetchProgramDevice.valves.some(
        (item) => item.durationSeconds > 0,
      ),
      valves: getAreaDeviceValves(fetchProgramDevice, setProgramDevice),
    };
  });

export const getAreaDeviceValves = (fetchProgramDevice, setProgramDevice) =>
  setProgramDevice.valves.map((valve) => {
    const valveWithProgram = fetchProgramDevice.valves.find(
      (item) => item.valveId === valve.valveId,
    );
    if (valveWithProgram) {
      return valveWithProgram;
    }
    return valve;
  });

export const setAreaProgramUpdateDurations = (areaDevices) =>
  areaDevices.map((device) => ({
    id: device.id,
    name: device.name,
    checked: false,
    valves: device.valves.map((valve) => ({
      ...valve,
      durationSeconds: 0,
    })),
  }));
