import React, { Fragment, useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import { debounce } from "lodash";
import differenceBy from "lodash/differenceBy";
import some from "lodash/some";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getParcelsAndZonesSuggestionsAutocomplete } from "../../selectors/actions.selectors";

import {
  clearParcelsAndZonesSuggestions,
  fetchParcelsAndZonesSuggestions,
} from "../../actions/actions.actions";

import { FORM_TYPES } from "../../../others/actionOther.constants";

const styles = (theme) => ({
  groupHeading: {
    paddingLeft: 16,
    textTransform: "uppercase",
    color: theme.palette.grey[500],
    fontWeight: 500,
    display: "flex",
    paddingBottom: 10,
    paddingTop: 10,
  },
});

function ParcelsZoneAutocompleteSelector(props) {
  const [values, setValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { formType, suggestions } = props;
  const {
    fetchParcelsAndZonesSuggestions: fetchParcelsAndZonesSuggestionsFunc,
    parcelsOnly,
    zonesOnly,
  } = props;

  const getSuggestionValue = (suggestion) =>
    suggestion.localName
      ? `${suggestion.blockNumber ? `${suggestion.blockNumber},` : ""} ${
          suggestion.localName
        }`
      : suggestion.name;

  const handleOnChange = (_, selectedValues) => {
    // using diff for selectedValues and parcels/zones to get newlyAdded one and call onChange
    if (selectedValues?.length > 0) {
      // as removing items is done outside Autocomplete via Formik, only the last added value should be
      // used to prevent buggy behaviour when some items are removed in Formik (but still exist in Autocomplete)
      const lastlyAddedValue = selectedValues[selectedValues.length - 1];
      const filteredSelectedValues = [...values, lastlyAddedValue];
      // we need to filter out parcels and zones and work with them differently
      const zones = filteredSelectedValues.filter((s) => "parcelCount" in s);
      const parcels = filteredSelectedValues.filter(
        (s) => !("parcelCount" in s),
      );
      if (zones.length > 0) {
        const zonesAlreadyInSelect = values.filter((s) => "parcelCount" in s);
        const newlyAddedZone = differenceBy(zones, zonesAlreadyInSelect, "id");
        if (newlyAddedZone.length) {
          // there should always be one zone added
          const newValue = newlyAddedZone[0];
          props.onChange(newValue);
        }
      }
      const newlyAddedParcels = differenceBy(parcels, props.parcels, "id");
      if (newlyAddedParcels.length) {
        // there should always be one parcel added
        const newValue = newlyAddedParcels[0];
        if (!some(props.parcels, (id) => id === newValue.id)) {
          props.onChange(newValue);
        }
      }
      setValues(filteredSelectedValues);
    }
  };

  const handleRenderGroup = (option) => (
    <Fragment key={option.key}>
      <span className={props.classes.groupHeading}>
        <FormattedMessage id={option.group} />
      </span>
      {option.children}
    </Fragment>
  );

  const handleRenderOption = (props, option) => (
    <li {...props} key={option.id}>
      {getSuggestionValue(option)}
    </li>
  );

  useEffect(() => {
    // Harvest form -> GET only sown parcels
    const isSown =
      formType === FORM_TYPES.HARVEST || formType === FORM_TYPES.MOWING;
    if (isSown) {
      return fetchParcelsAndZonesSuggestionsFunc(
        inputValue,
        parcelsOnly,
        zonesOnly,
        isSown,
      );
    }
    fetchParcelsAndZonesSuggestionsFunc(
      inputValue,
      parcelsOnly,
      zonesOnly,
      null,
    );
  }, [
    inputValue,
    fetchParcelsAndZonesSuggestionsFunc,
    parcelsOnly,
    zonesOnly,
    formType,
  ]);

  useEffect(() => {
    setValues(props.parcels);
  }, [props.parcels]);

  return (
    <Autocomplete
      autoComplete
      blurOnSelect
      filterOptions={() => suggestions}
      getOptionLabel={(option) => getSuggestionValue(option)} // hack to not trigger warning with empty values
      groupBy={(option) => option.title}
      id="parcel-zone-selector"
      multiple
      onChange={handleOnChange}
      options={[...values, ...suggestions]}
      renderGroup={handleRenderGroup}
      renderOption={handleRenderOption}
      onInputChange={debounce(
        (event, newInputValue) => setInputValue(newInputValue),
        500,
      )}
      renderInput={(params) => (
        <TextField {...params} fullWidth label={props.label} />
      )}
      // This will prevent autocomplete from duplicity values in suggestion list
    />
  );
}

ParcelsZoneAutocompleteSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  parcels: PropTypes.array.isRequired,
  parcelsOnly: PropTypes.bool,
  zonesOnly: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  formType: PropTypes.string.isRequired,
  fetchParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  clearParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

ParcelsZoneAutocompleteSelector.defaultProps = {
  parcelsOnly: false,
  zonesOnly: false,
  isFetching: false,
};

const mapStateToProps = (state) => ({
  suggestions: getParcelsAndZonesSuggestionsAutocomplete("other")(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearParcelsAndZonesSuggestions,
      fetchParcelsAndZonesSuggestions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ParcelsZoneAutocompleteSelector));
