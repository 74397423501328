/**
 * Created by ondrejzvara on 9.12.15.
 */

cfDate.$inject = ["$filter", "$locale"];

export function cfDate($filter, $locale) {
  return function (dateStr, format) {
    if (dateStr) {
      var localeFormat = $locale.DATETIME_FORMATS.mediumDate;

      var check = parseInt(new Date(dateStr).getFullYear());
      if (check > 1970) {
        return $filter("date")(
          new Date(dateStr),
          format ? format : localeFormat,
        );
      }
    }
    return "-";
  };
}
