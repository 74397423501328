import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const NoCropIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M21.47,11.89V10.41h-.88a9.2,9.2,0,0,0-4.37,1.1A6.11,6.11,0,0,0,14.61,5L12,2.52,9.39,5a6.46,6.46,0,0,0-.73.84L19.93,17.11A9.63,9.63,0,0,0,21.47,11.89Z" />
      <path d="M21.91,21.91,2.13,2.11.72,3.53,7.5,10.31a5,5,0,0,0,.28,1.2,9.26,9.26,0,0,0-4.37-1.1H2.53v1.48a9.57,9.57,0,0,0,8.2,9.5,9.21,9.21,0,0,0,6.38-1.45l3.38,3.38Z" />
    </svg>
  </SvgIcon>
);

export default NoCropIcon;
