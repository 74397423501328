import React, { FC } from "react";

import { makeStyles } from "@mui/styles";

import CfChartTooltipLabel from "../../../../shared/components/charts/CfChartTooltipLabel/CfChartTooltipLabel";
import CfChartTooltipLabelWrapper from "../../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper";
import { CfChartTooltipWrapper } from "../../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { CropQualityData } from "../../containers/ManagementZones";

const useStyles = makeStyles(() => ({
  tooltipLabelWrapper: {
    borderRadius: 3,
  },
}));

interface CropQualityTooltipPayload {
  payload: CropQualityData;
  value: number;
}

interface CropQualityTooltipProps {
  active?: boolean;
  payload?: CropQualityTooltipPayload[];
}

const CropQualityTooltip: FC<CropQualityTooltipProps> = ({
  active,
  payload,
}) => {
  const classes = useStyles();
  if (active && payload && payload[0]) {
    return (
      <CfChartTooltipWrapper>
        <CfChartTooltipLabelWrapper
          classes={{ tooltipLabelWrapper: classes.tooltipLabelWrapper }}
        >
          <CfChartTooltipLabel>
            <span style={{ color: `#${payload[0].payload.zoneColor}` }}>
              <CfFormattedNumber decimalDigits={1} value={payload[0].value} /> %
            </span>
          </CfChartTooltipLabel>
        </CfChartTooltipLabelWrapper>
      </CfChartTooltipWrapper>
    );
  }
  return null;
};

export default CropQualityTooltip;
