import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import {
  zonesColors,
  phZonesColors,
  percentageZonesColors,
  kmgZonesColors,
  SoilSamplesZonesColor,
} from "../../services/soilSamlesZonesColors";

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  colorRectangle: {
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: 6,
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

interface SoilSamplesZonesLegendProps {
  isKMGElement?: boolean;
  isPHElement?: boolean;
  isPercentageContentElement?: boolean;
  lessValueLevels?: boolean;
}

const SoilSamplesZonesLegend: FC<SoilSamplesZonesLegendProps> = ({
  isKMGElement = false,
  isPHElement = false,
  isPercentageContentElement = false,
  lessValueLevels = false,
}) => {
  const classes = useStyles();

  let colorArray: SoilSamplesZonesColor[];

  switch (true) {
    case isPHElement:
      colorArray = phZonesColors;
      break;
    case isKMGElement:
      colorArray = kmgZonesColors;
      break;
    case isPercentageContentElement:
      colorArray = percentageZonesColors;
      break;
    default:
      colorArray = zonesColors;
      break;
  }

  return (
    <div className={classes.legendWrapper}>
      {colorArray.map((zoneColor) => {
        if (
          lessValueLevels &&
          (zoneColor.zoneStrength === 1 || zoneColor.zoneStrength === 3)
        )
          return null;
        return (
          <span
            className={classes.legendItem}
            key={`legend_zone_${zoneColor.id}`}
          >
            <span
              className={classes.colorRectangle}
              style={{ backgroundColor: `#${zoneColor.color}` }}
            />
            <span className={classes.label}>
              <FormattedMessage
                id={
                  isPHElement
                    ? `SoilSamples.level.ph.${zoneColor.zoneLevel}`
                    : `SoilSamples.level.${zoneColor.zoneLevel}`
                }
              />
            </span>
          </span>
        );
      })}
    </div>
  );
};

export default SoilSamplesZonesLegend;
