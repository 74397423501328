import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import farmsApi from "../shared/api/agroevidence/farms/farms.reducer";
import accountsApi from "../shared/api/gateway/accounts/accounts.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import AgIntegrationStatusApi from "../shared/api/sentinel/agiStatus/agiStatus.reducer";
import AgVarioDocApi from "../shared/api/sentinel/varioDoc/varioDoc.reducer";

export default (history) =>
  combineReducers({
    api: combineReducers({
      accounts: accountsApi(),
      farms: farmsApi(),
    }),
    AGIStatus: AgIntegrationStatusApi(),
    VarioDoc: AgVarioDocApi(),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
