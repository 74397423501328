import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const ImpersonationIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M12,14c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
        fill="currentColor"
      />
      <path
        d="M12,12a4,4,0,0,0,4-4,4.1,4.1,0,0,0-3.6-4V1.89L9.2,5.07l3.2,3.18V5.81a2.3,2.3,0,0,1-.4,4.56A2.34,2.34,0,0,1,9.68,8V7.6H8V8A4,4,0,0,0,12,12Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { ImpersonationIcon };
