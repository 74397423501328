import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const SensorsIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 30 28" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path
        d="M19.14,16.46H10.86a1.33,1.33,0,0,0-1.38,1.26v8.51a1.33,1.33,0,0,0,1.38,1.27h8.28a1.33,1.33,0,0,0,1.38-1.27V17.72A1.33,1.33,0,0,0,19.14,16.46Z"
        fill="currentColor"
      />
      <path
        d="M15,7.6a11.75,11.75,0,0,0-7.68,2.83L9.64,13a8.41,8.41,0,0,1,10.72,0l2.32-2.52A11.75,11.75,0,0,0,15,7.6Z"
        fill="currentColor"
      />
      <path
        d="M15,.5a17.49,17.49,0,0,0-12.27,5L5.07,8A14.14,14.14,0,0,1,24.93,8l2.33-2.52A17.36,17.36,0,0,0,15,.5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { SensorsIcon };
