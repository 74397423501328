import CommonStyles, { STROKE_COLOR } from "./CommonStyles.service";

export default class CommonLineStyle {
  static getDrawing() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.DEFAULT,
        width: 4,
        lineDash: [5, 10],
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
    });
  }

  static getDrawn() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.DEFAULT,
        width: 4,
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.SELECTED },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
    });
  }

  static getDrawnError() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.ERROR,
        width: 4,
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.ERROR },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
    });
  }

  static getBuffer() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.BUFFER,
        width: 4,
        lineDash: [5, 10],
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.BUFFER },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
    });
  }

  static getMeasurement() {
    return CommonStyles.getGeometryStyle({
      stroke: {
        color: STROKE_COLOR.MEASUREMENT_LINE,
        width: 2,
        lineDash: [4, 4],
      },
      image: {
        radius: 6,
        fill: { color: STROKE_COLOR.MEASUREMENT },
        stroke: { color: STROKE_COLOR.DEFAULT, width: 2 },
      },
    });
  }
}
