import React, { FC, Fragment, useMemo } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Radio, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import { FieldInputProps } from "formik";
import { FormattedMessage } from "react-intl";

import SubtractableArea from "../SubtractableArea/SubtractableArea";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface SubtractableAreaWithRadioButtonProps {
  field: FieldInputProps<SubtractionResponse>;
  handleSubtractionDelete: (type: string, area: number) => void;
  isEditing: boolean;
  options: SubtractionResponse[];
}

const SubtractableAreaWithRadioButton: FC<
  SubtractableAreaWithRadioButtonProps
> = ({ field, handleSubtractionDelete, isEditing = false, options }) => {
  const classes = useStyles();

  const optionsWithNone = useMemo(
    () => [
      {
        type: "none",
        value: 0,
        area: 0,
        isMaterial: false,
        isUsed: false,
      },
      ...options,
    ],
    [options],
  );

  return (
    <Fragment>
      <RadioGroup
        data-test="area-subtraction-list"
        {...field}
        name={field.name}
      >
        <Grid container>
          {optionsWithNone.map((option) => {
            const isChecked =
              field.value?.toString() === option.value?.toString();
            return (
              <Grid
                item
                key={`radio-${option.type}-${option.value}`}
                md={3}
                sm={4}
                xs={12}
              >
                <span
                  className={classes.saRow}
                  data-test={`${option.type.toLowerCase()}-radio-subtraction-item`}
                >
                  <Radio
                    checked={isChecked}
                    color="primary"
                    disabled={!isEditing}
                    value={option.value}
                  />
                  {option.value === 0 ? (
                    <FormattedMessage id="SubtractableArea.none" />
                  ) : (
                    <SubtractableArea sa={option} />
                  )}
                  {option.value !== 0 && isEditing && (
                    <IconButton
                      aria-label="Remove item"
                      className={classes.removeButton}
                      disabled={!isEditing}
                      size="small"
                      onClick={() => {
                        handleSubtractionDelete(option.type, option.value);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </span>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Fragment>
  );
};

export default SubtractableAreaWithRadioButton;

const useStyles = makeStyles((theme: Theme) => ({
  saRow: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      "& $removeButton": {
        opacity: 1,
      },
    },
  },
  removeButton: {
    height: 26,
    width: 26,
    padding: 0,
    marginLeft: 6,
    opacity: 0,
  },
}));
