import React, { Component } from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Field, withFormik } from "formik";
import toNumber from "lodash/toNumber";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { calculateSubtractableAreaApi } from "../../../../../shared/api/agroevidence/subtractableAreas/subtractableAreas.api";
import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";
import Localization from "../../../../../shared/services/Localization.service";

export class NewSubtractableAreaDialog extends Component {
  getTitle() {
    const { subtractableAreaType } = this.props;
    switch (subtractableAreaType) {
      case "Absolute":
        return (
          <FormattedMessage id="NewSubtractableAreaDialog.absoluteHeading" />
        );
      case "Boundary":
        return (
          <FormattedMessage id="NewSubtractableAreaDialog.boundaryHeading" />
        );
      case "Water":
        return <FormattedMessage id="NewSubtractableAreaDialog.waterHeading" />;
      default:
        return "";
    }
  }

  getSubtractedAreaBe = (restrictionValue) => {
    const { parcelId, subtractableAreaType } = this.props;
    const p = {
      type: subtractableAreaType,
      value: restrictionValue,
    };
    return this.props
      .calculateSubtractableAreaApi(parcelId, p)
      .then((res) => res.payload.area);
  };

  render() {
    const { handleClose, handleSubmit, intl, opened, subtractableAreaType } =
      this.props;

    return (
      <CfDialog
        acceptText={<FormattedMessage id="NewSubtractableAreaDialog.add" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        maxWidth="xs"
        onAccept={handleSubmit}
        onCancel={handleClose}
        onClose={handleClose}
        opened={opened}
        title={this.getTitle()}
      >
        <form>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <Field
                  component={CfFormikTextField}
                  disabled={subtractableAreaType === "Absolute"}
                  label={<FormattedMessage id="SubtractableArea.distance" />}
                  name="valueM"
                  value={this.props.values.valueM}
                  onChange={(e) => {
                    const valueM = e.target.value;
                    this.props.setFieldValue("valueM", valueM);
                    const normalizedValueM = Localization.str2numNonFixed(
                      valueM,
                      intl.locale,
                    );
                    if (normalizedValueM > 0) {
                      this.getSubtractedAreaBe(normalizedValueM).then(
                        (area) => {
                          const localizedValueHa = Localization.num2strNonFixed(
                            area,
                            intl.locale,
                            3,
                            3,
                          );
                          this.props.setFieldValue("valueHa", localizedValueHa);
                          this.props.setFieldTouched("valueHa", true, true);
                        },
                      );
                    } else {
                      this.props.setFieldValue("valueHa", "");
                      this.props.setFieldTouched("valueHa", false, false);
                    }
                  }}
                  validate={
                    subtractableAreaType === "Absolute"
                      ? undefined
                      : validators.localizedNumberRequired(intl.locale)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <Field
                  component={CfFormikTextField}
                  label={<FormattedMessage id="SubtractableArea.area" />}
                  name="valueHa"
                  validate={validators.localizedNumberRequired(intl.locale)}
                  validateOnBlur={true}
                  value={this.props.values.valueHa}
                  disabled={
                    subtractableAreaType === "Boundary" ||
                    subtractableAreaType === "Water"
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </CfDialog>
    );
  }
}

NewSubtractableAreaDialog.propTypes = {
  opened: PropTypes.bool,
  parcelId: PropTypes.string.isRequired,
  subtractableAreaType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  calculateSubtractableAreaApi: PropTypes.func.isRequired,
};

NewSubtractableAreaDialog.defaultProps = {
  classes: {},
  opened: false,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      calculateSubtractableAreaApi,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(
  compose(
    injectIntl,
    withFormik({
      // maxValue - parcel total area, validating valueHa field -> display error message
      validate: (values, props) => {
        const errors = {};

        const normalizedValueHa = Localization.str2numNonFixed(
          values.valueHa,
          props.intl.locale,
        );
        const maxValueHa = toNumber(props.maxValue.toFixed(2));

        if (normalizedValueHa >= maxValueHa) {
          errors.valueHa = props.intl.formatMessage({
            id: "validation.tooBig",
          });
        }

        if (
          props.subtractableAreas.find(
            (sa) => sa.value.toString() === values.valueM?.toString(),
          )
        ) {
          errors.valueM = props.intl.formatMessage({
            id: "SubtractableAreaDialog.areaAlreadyInResult",
          });
        }

        return errors;
      },
      handleSubmit: (values, { props }) => {
        const normalizedValueHa = Localization.str2numNonFixed(
          values.valueHa,
          props.intl.locale,
        );
        const normalizedValueM = Localization.str2numNonFixed(
          values.valueM,
          props.intl.locale,
        );
        props.onAccept({
          type: props.subtractableAreaType,
          value:
            props.subtractableAreaType === "Absolute"
              ? normalizedValueHa
              : normalizedValueM,
          area: normalizedValueHa,
        });
      },
    }),
  )(NewSubtractableAreaDialog),
);
