import * as types from "../aggregations/aggregations.constants";

import ErrorService from "../../../services/Error.service";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

export const getMachineAggregations = (state: TelematicsState) =>
  state.api.machines.machineAggregations;
export const isFetchingMachineAggregations = (state: TelematicsState) =>
  state.api.machines.isFetchingMachineAggregations;

export const getMachineAggregationsCount = (state: TelematicsState) =>
  state.api.machines.machineAggregationsCount;

export const getAggregatedMachine = (state: TelematicsState) =>
  state.api.machines.driveDetail;

export const isFetchingAggregatedMachine = (state: TelematicsState) =>
  state.api.machines.isFetchingDriveDetail;

export const getApiError = (state: TelematicsState) =>
  ErrorService.getResErrorDto(state.api.machines.apiError);

export const isAggregatedDetailError = (state: TelematicsState) =>
  state.api.machines.apiErrorTriggeredBy === types.GET_AGGREGATED_DRIVE_ERROR;

export const getAggregatedMachinesExportError = (state: TelematicsState) =>
  ErrorService.getResErrorDto(state.api.machines.exportError);

export const isExportingAggregatedMachinesData = (state: TelematicsState) =>
  state.api.machines.isExportingData;

export const getTelematicsMachinesFocusedRow = (state: TelematicsState) =>
  state.api.machines.focusedRow;
