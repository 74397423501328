import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getFertilizerRegistrationType,
  getIsFetchingFertilizerRegistrationType,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import {
  getFertilizerRegistrationTypeApi,
  resetFertilizerRegistrationTypeApi,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { EagriFertilizerRegistrationTypeTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues?: EagriFertilizerRegistrationTypeTo | null;
  disabled?: boolean;
  error?: boolean;
  langId: string;
  onChange: (items: EagriFertilizerRegistrationTypeTo) => void;
};
type Props = ReduxProps & OwnProps;

const RegistrationTypeSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  getFertilizerRegistrationTypeApi,
  isFetchingRegistrationType,
  langId,
  onChange,
  registrationType,
  resetFertilizerRegistrationTypeApi,
}: Props) => {
  useEffect(() => {
    getFertilizerRegistrationTypeApi(langId);

    return () => {
      resetFertilizerRegistrationTypeApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="registration-type-selector"
      isFetching={isFetchingRegistrationType}
      isMultiple={false}
      loadOptions={getFertilizerRegistrationTypeApi}
      onChange={onChange}
      suggestions={registrationType}
      testId="registration-type-selector"
      label={
        <FormattedMessage id="Catalogues.fertilizers.detail.registrationType" />
      }
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  registrationType: getFertilizerRegistrationType(state),
  isFetchingRegistrationType: getIsFetchingFertilizerRegistrationType(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerRegistrationTypeApi,
      resetFertilizerRegistrationTypeApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RegistrationTypeSelector);
