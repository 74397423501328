import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const style = (theme) => ({
  number: {
    textAlign: "right",
  },
  paddingNumber: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
    },
  },
});

const CfTableCellNumber = (props) => {
  const { children, classes, negative, padding } = props;
  return (
    <div
      style={negative ? { color: "red" } : {}}
      className={
        padding ? `${classes.number} ${classes.paddingNumber}` : classes.number
      }
    >
      {children}
    </div>
  );
};

CfTableCellNumber.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  negative: PropTypes.bool,
  padding: PropTypes.bool,
};

CfTableCellNumber.defaultProps = {
  classes: {},
  negative: false,
  padding: false,
};

export default withStyles(style)(CfTableCellNumber);
