import React, { Component } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setTextFilter } from "../../actions/filter.actions";

const styles = {
  wrapper: {
    position: "relative",
    fontWeight: 400,
  },
  fullWidth: {
    width: "100%",
  },
};

export class CfTextFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
    };
    const fnc = this.props.onFilterChange
      ? this.props.onFilterChange
      : this.props.setTextFilter;
    this.debouncedFnc = debounce(fnc, 500);
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;
    if (
      initialValue !== prevProps.initialValue &&
      initialValue !== this.state.value
    ) {
      this.setState({
        value: initialValue,
      });
    }
  }

  componentWillUnmount() {
    if (this.debouncedFnc) {
      this.debouncedFnc.cancel();
    }
  }

  onInputChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    const { namespace } = this.props;
    if (this.props.onFilterChange) {
      this.debouncedFnc(e.target.value);
    } else {
      this.debouncedFnc(e.target.value, namespace);
    }
  };

  onResetBtnClick = () => {
    if (this.state.value.length) {
      this.setState({
        value: "",
      });

      const { namespace } = this.props;
      if (this.props.onFilterReset) {
        return this.props.onFilterReset();
      }
      return this.props.setTextFilter("", namespace);
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { autoFocus, customStyles, disableUnderline, name, translId } =
      this.props;
    const { value } = this.state;
    const icon = this.state.value.length ? <ClearIcon /> : <SearchIcon />;
    const translationId = translId
      ? formatMessage({
          id: translId,
        })
      : null;

    return (
      <div style={styles.wrapper}>
        <Input
          autoFocus={autoFocus}
          disableUnderline={disableUnderline}
          name={name}
          onChange={this.onInputChange}
          placeholder={translationId}
          style={customStyles || styles.fullWidth}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Text filter"
                color="default"
                onClick={this.onResetBtnClick}
                size="large"
              >
                {icon}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    );
  }
}

CfTextFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  translId: PropTypes.string,
  initialValue: PropTypes.string,
  name: PropTypes.string,
  onFilterReset: PropTypes.func,
  onFilterChange: PropTypes.func,
  setTextFilter: PropTypes.func,
  namespace: PropTypes.string,
  disableUnderline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  customStyles: PropTypes.object,
};

CfTextFilter.defaultProps = {
  translId: null,
  initialValue: "",
  name: "text-filter",
  onFilterReset: null,
  onFilterChange: null,
  setTextFilter: null,
  namespace: null,
  disableUnderline: false,
  autoFocus: false,
  customStyles: null,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTextFilter,
    },
    dispatch,
  );

export default compose(
  connect(null, mapDispatchToProps),
  injectIntl,
)(CfTextFilter);
