import React, { Component } from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field } from "redux-form";

import { getCrops } from "../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import {
  getCropsApi,
  resetCropsApi,
} from "../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfSelector from "../../../../../shared/components/form/CfSelector/CfSelector";
import * as validators from "../../../../../shared/misc/validators";

class ActionTargetCrop extends Component {
  componentDidMount() {
    this.props.getCropsApi();
  }

  componentWillUnmount() {
    this.props.resetCropsApi();
  }

  render() {
    const { crops, disabled, onChange } = this.props;
    return (
      <CfFormControl>
        <Field
          component={CfSelector}
          disabled={disabled}
          idPropertyName="legislativeCode"
          items={crops}
          label={<FormattedMessage id="action.targetCrop" />}
          name="targetCrop"
          onChange={onChange}
          validate={[validators.required]}
        />
      </CfFormControl>
    );
  }
}
const mapStateToProps = (state) => ({
  crops: getCrops(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCropsApi,
      resetCropsApi,
    },
    dispatch,
  );

ActionTargetCrop.propTypes = {
  disabled: PropTypes.bool.isRequired,
  crops: PropTypes.array.isRequired,
  getCropsApi: PropTypes.func.isRequired,
  resetCropsApi: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

ActionTargetCrop.defaultProps = {
  onChange: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionTargetCrop);
