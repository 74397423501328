import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";

const SensorAdminAssignConfirm = (props) => {
  const { onAccept, onClose, opened } = props;
  return (
    <CfDialog
      acceptText={<FormattedMessage id="SensorsAdmin.assign" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      dialogHeight="75px"
      onAccept={onAccept}
      onCancel={onClose}
      onClose={onClose}
      opened={opened}
      title={<FormattedMessage id="SensorsAdmin.assign-farm" />}
    >
      <FormattedMessage
        id="SensorsAdmin.assign-to-farm-confirm"
        values={{
          farm: props.farm.name || "",
          length: props.nodeIds.length,
        }}
      />
    </CfDialog>
  );
};

SensorAdminAssignConfirm.propTypes = {
  opened: PropTypes.bool.isRequired,
  farm: PropTypes.object.isRequired,
  nodeIds: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

SensorAdminAssignConfirm.defaultProps = {
  opened: false,
};

export default SensorAdminAssignConfirm;
