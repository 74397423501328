import { jwtDecode } from "jwt-decode";
import * as localStorageService from "local-storage";

type TokenContainer = {
  id_token: string;
  principal: string;
};

type Token = {
  auth: ["ROLE_USER"] | ["ROLE_ADMIN"] | ("ROLE_USER" | "ROLE_ADMIN")[];
  exp: number;
  user_farms: number[];
  user_id: number;
};

type ImpersonatedToken = Token & {
  original_user_id: number;
};

class Jwt {
  getToken(): TokenContainer {
    return localStorageService.get("token");
  }

  storeToken(token: TokenContainer): void {
    localStorageService.set("token", token);
  }

  removeToken(): void {
    localStorageService.remove("token");
  }

  hasValidToken(): boolean {
    const token = this.getToken();

    if (!(token && token.id_token)) {
      return false;
    }

    return !this.isTokenExpired(token.id_token);
  }

  isTokenExpired(idToken: string): boolean {
    const jwt = jwtDecode<Token>(idToken);
    const time = new Date().getTime() / 1000;
    return time > jwt.exp;
  }

  readToken(): Token | ImpersonatedToken {
    const token = this.getToken();
    return token && jwtDecode(token.id_token);
  }

  getTokenValue(): string {
    const token = this.getToken();
    return token && token.id_token;
  }

  isImpersonatedToken(): boolean {
    const token = this.readToken();
    return (
      token && Object.prototype.hasOwnProperty.call(token, "original_user_id")
    );
  }
}

const JwtService = new Jwt();
export { JwtService };
