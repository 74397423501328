import React from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { ExportIcon } from "../../../icons/ExportIcon";
import CfLoader from "../CfLoader/CfLoader";

const useStyles = makeStyles({
  exportButton: {
    minWidth: "unset",
  },
  exportIcon: {
    width: 20,
    height: 20,
  },
});

/**
 * Styled button used for exporting excel files.
 * @version 1.0.0
 */
const ExportButton = ({
  customIcon,
  handleClick,
  isDisabled,
  isLoading,
  tooltipTitle,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle || <FormattedMessage id="common.export" />}>
      <>
        {isLoading && <CfLoader color="inherit" size={20} />}
        {!isLoading && (
          <IconButton
            aria-label="Export"
            className={classes.exportButton}
            disabled={isDisabled}
            onClick={handleClick}
            size="large"
          >
            {customIcon || <ExportIcon className={classes.exportIcon} />}
          </IconButton>
        )}
      </>
    </Tooltip>
  );
};

ExportButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  tooltipTitle: PropTypes.node,
  customIcon: PropTypes.node,
  isLoading: PropTypes.bool,
};

ExportButton.defaultProps = {
  isDisabled: false,
  tooltipTitle: undefined,
  isLoading: false,
};

export default ExportButton;
