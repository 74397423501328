import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";

import { getLayers as getLayersService } from "./map.selectors";

import {
  PUBLIC_LPIS_CONFIG,
  PRIVATE_LPIS_CONFIG,
} from "../../../shared/services/LayersConfig.service";

export const getLayersConfig = (state) => state.api.layers.items;
export const getLayers = (state) => state.ui.layers.layers;

export const getParcelLayerVisibility = (state) => {
  const layers = getLayers(state);
  const layersService = getLayersService(state);
  if (!layersService || isEmpty(layersService)) return false;
  const parcelMapLayer = layersService.getParcelLayer();
  const parcelLayer = layers.find((layer) => layer.mapLayer === parcelMapLayer);
  return parcelLayer.visible;
};

export const getLpisLayers = createSelector(getLayers, (layers) =>
  layers.filter(
    ({ layerId }) =>
      layerId === PUBLIC_LPIS_CONFIG.LAYER_ID ||
      layerId === PRIVATE_LPIS_CONFIG.LAYER_ID,
  ),
);
