import React, { Component } from "react";

import Switch from "@mui/material/Switch";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getItems,
  getIsFetchingRules,
  getTotalCount,
} from "../../../shared/api/automation/rules/rules.selectors";
import {
  getNotificationListOrder,
  getNotificationListOrderBy,
  getNotificationListPage,
  getNotificationListRowsPerPage,
  getNotificationListSelected,
  getNotificationListSelectedOnPage,
  getNotificationListTextFilter,
} from "../../selectors/notificationList.selectors";

import { fetchRules, patchRule } from "../../actions/notificationList.actions";

import { NAMESPACE as namespace } from "../../reducer/notificationList.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableCheckbox from "../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../shared/misc/helper";
import { COLOR_GREY } from "../../../theme";
import NotificationCondition from "../../components/NotificationCondition/NotificationCondition";
import NotificationUsers from "../../components/NotificationUsers/NotificationUsers";

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  lastLocationName: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.sensor" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.id" />
      </p>
    </span>,
  ),
  condition: getColDesc(false, <FormattedMessage id="common.condition" />),
  description: getColDesc(false, <FormattedMessage id="common.description" />),
  users: getColDesc(false, <FormattedMessage id="common.users" />),
  enabled: getColDesc(true, <FormattedMessage id="common.state" />),
};

const styles = {
  cellRow: {
    margin: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  sensorName: {
    fontWeight: 500,
  },
};

export class NotificationsTable extends Component {
  componentDidUpdate(prevProps) {
    const oldProps = pick(prevProps, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);
    const newProps = pick(this.props, [
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "textFilter",
    ]);

    if (!isEqual(newProps, oldProps)) {
      this.props.fetchRules();
    }
  }

  render() {
    const {
      classes,
      count,
      farmId,
      goToNotificationDetail,
      isFetching,
      langId,
      order,
      orderBy,
      page,
      rowsPerPage,
      rules,
      selected,
      selectedOnPage,
    } = this.props;
    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          items={rules}
          langId={langId}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
          selected={selected}
          selectedOnPage={selectedOnPage}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}
        {rules.length ? (
          <TableBody>
            {rules.map((item) => (
              <TableRow
                className={classes.pointer}
                hover
                key={item.id}
                onClick={() => goToNotificationDetail(item.id)}
              >
                <CfTableCheckbox
                  id={item.id}
                  namespace={namespace}
                  selected={selected}
                />
                <CfTableCell name="lastLocationName">
                  <span>
                    <p className={`${classes.cellRow} ${classes.sensorName}`}>
                      <Link
                        to={`/farm/${farmId}/sensors/${item.sensors[0].lastLocationId}`}
                      >
                        {item.sensors[0].lastLocationName}
                      </Link>
                    </p>
                    <p className={`${classes.cellRow} ${classes.greyText}`}>
                      {item.sensors[0].externalId}
                    </p>
                  </span>
                </CfTableCell>
                <CfTableCell name="condition">
                  <NotificationCondition condition={item.conditions[0]} />
                </CfTableCell>
                <CfTableCell name="description">{item.name}</CfTableCell>
                <CfTableCell name="users">
                  <NotificationUsers users={item.users} />
                </CfTableCell>
                <CfTableCell name="enabled">
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={`NotificationsTable.${
                          item.enabled
                            ? "activeNotification"
                            : "inactiveNotification"
                        }`}
                      />
                    }
                  >
                    <Switch
                      checked={item.enabled}
                      color="primary"
                      name="enabled"
                      onClick={(evt) => evt.stopPropagation()}
                      onChange={(evt) =>
                        this.props.patchRule(item.id, evt.target.checked)
                      }
                    />
                  </Tooltip>
                </CfTableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          langId={langId}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    );
  }
}

NotificationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  langId: PropTypes.string.isRequired,
  goToNotificationDetail: PropTypes.func.isRequired,
  fetchRules: PropTypes.func.isRequired,
  patchRule: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  rules: PropTypes.array.isRequired,
  farmId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  page: getNotificationListPage(state),
  order: getNotificationListOrder(state),
  orderBy: getNotificationListOrderBy(state),
  rowsPerPage: getNotificationListRowsPerPage(state),
  selected: getNotificationListSelected(state),
  selectedOnPage: getNotificationListSelectedOnPage(state),
  textFilter: getNotificationListTextFilter(state),
  isFetching: getIsFetchingRules(state),
  rules: getItems(state),
  count: getTotalCount(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRules,
      patchRule,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationsTable));
