/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from "angular";
import tpl from "./fert.html";
import FertilizerController from "./fert.controller";
import cfFertEditor from "./cf-fert-editor/cf-fert-editor.directive.js";

export default angular
  .module("app.components.farm.classifiers.private.fertilizers.fertilizer", [])
  .controller("FertilizerController", FertilizerController)
  .directive("cfFertEditor", cfFertEditor)
  .config(config).name;

/*@ngInject*/
function config($stateProvider) {
  $stateProvider.state(
    "farm.active.classifiers.private.fertilizers.fertilizer",
    {
      url: "/{fertilizer}",
      views: {
        "farm@farm.active": {
          controller: "FertilizerController as FertCtrl",
          template: tpl,
        },
      },
      resolve: {
        idName: function ($stateParams) {
          return $stateParams.fertilizer;
        },
      },
    },
  );
}
