import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Field, getFormValues } from "redux-form";

import CfFormattedField from "../../../../../shared/components/form/CfFormattedField/CfFormattedField";
import CfFormattedTextField from "../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfSelector from "../../../../../shared/components/form/CfSelector/CfSelector";
import * as validators from "../../../../../shared/misc/validators";
import unitsMock from "../../../../../shared/mocks/units.mock.json";
import LockDoseButton from "../LockDoseButton/LockDoseButton";

const styles = (theme) => ({
  solidLineHorizontal: {
    width: "35px",
    position: "absolute",
    top: 55,
    left: 13,
  },
  solidLineVertical: {
    height: "13px",
    width: "40px",
    position: "absolute",
    top: 55,
  },
  solidLineVerticalBottom: {
    height: "13px",
    width: "40px",
    position: "absolute",
    top: 43,
  },
  unitGridItem: {
    marginTop: "auto",
  },
  helperText: {
    position: "absolute",
    bottom: -16,
  },
  unitField: {
    display: "inherit",
  },
  mainColor: {
    color: theme.palette.primary.main,
  },
  disabledColor: {
    color: theme.palette.grey[300],
  },
});

const units = unitsMock.units.filter((item) => item.id !== "VJ");

class DoseDosageFields extends Component {
  constructor(props) {
    super(props);
    this.initializeForm();
    const totalDose = Math.round((this.props.material.dosage * 100) / 100);
    const doseByArea = Math.round(
      (this.props.material.dose * this.props.parcelsArea * 100) / 100,
    );
    this.state = {
      recalculateDose:
        this.props.parcelsArea > 0 &&
        this.props.material &&
        (!this.props.material.dosage ||
          !this.props.material.dose ||
          totalDose === doseByArea),
      recalcDisabled: this.props.parcelsArea === 0 || !this.props.editing,
    };
  }

  onDoseChange = (event, newValue) => {
    this.updateFertilizerStats(this.props.formValues.doseUnit, newValue);
    if (
      this.state.recalculateDose &&
      newValue > 0 &&
      this.props.parcelsArea > 0
    ) {
      this.changeDosage(newValue);
    }
  };

  onDosageChange = (event, newValue) => {
    if (
      this.state.recalculateDose &&
      newValue > 0 &&
      this.props.parcelsArea > 0
    ) {
      this.changeDose(newValue);
    }
  };

  getColor = () => {
    const { classes } = this.props;
    return this.state.recalcDisabled || !this.state.recalculateDose
      ? classes.disabledColor
      : classes.mainColor;
  };

  getBorder = () =>
    this.state.recalculateDose || this.state.recalcDisabled
      ? "1px solid"
      : "1px dashed";

  changeDose = (dosage) => {
    const newDose = (dosage / this.props.parcelsArea).toFixed(4);
    this.props.change("dose", newDose);
    this.updateFertilizerStats(this.props.formValues.doseUnit, newDose);
  };

  changeDosage = (dose) => {
    const newDosage = (dose * this.props.parcelsArea).toFixed(4);
    this.props.change("dosage", newDosage);
  };

  turnOnRecalc = () => {
    this.setState({ recalculateDose: true });
    this.props.handleShowWarning(false);
    if (this.props.formValues.dosage) {
      this.changeDose(this.props.formValues.dosage);
    }
    if (!this.props.formValues.dosage && this.props.formValues.dose) {
      this.changeDosage(this.props.formValues.dose);
    }
  };

  turnOffRecalc = () => {
    this.setState({ recalculateDose: false });
    this.props.handleShowWarning(true);
  };

  initializeForm = () => {
    this.props.initialize(this.props.material);
  };

  updateFertilizerStats = (unit, dose) => {
    if (this.props.material.material.materialTypeId === "FR") {
      this.props.getFertilizerDoseApi(
        this.props.material.material.id,
        unit.id,
        dose,
      );
    }
  };

  renderHorizontalLine = () => {
    const { classes } = this.props;
    return (
      <div
        className={`${classes.solidLineHorizontal} ${this.getColor()}`}
        style={{ borderTop: this.getBorder() }}
      />
    );
  };

  renderUpperVerticalLine = () => {
    const { classes } = this.props;
    return (
      <div
        className={`${classes.solidLineVertical} ${this.getColor()}`}
        style={{ borderRight: this.getBorder() }}
      />
    );
  };

  renderBottomVerticalLine = () => {
    const { classes } = this.props;
    return (
      <div
        className={`${classes.solidLineVerticalBottom} ${this.getColor()}`}
        style={{ borderRight: this.getBorder() }}
      />
    );
  };

  render() {
    const { classes, editing, formValues } = this.props;
    return (
      <div>
        <CfFormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CfFormattedField
                component={CfFormattedTextField}
                disabled={!editing}
                formatDecimal={4}
                FormHelperTextProps={{ className: classes.helperText }}
                fullWidth
                label={<FormattedMessage id="common.dosePerHectare" />}
                name="dose"
                onChange={this.onDoseChange}
                type="text"
                validate={[validators.required, validators.positiveNumber]}
                InputLabelProps={{
                  style: styles.inputLabel,
                }}
              />
            </Grid>
            <Grid item style={{ position: "relative" }} xs={3}>
              {this.renderHorizontalLine()}
              {this.renderUpperVerticalLine()}
              <LockDoseButton
                recalcDisabled={this.state.recalcDisabled}
                recalculateDose={this.state.recalculateDose}
                turnOff={this.turnOffRecalc}
                turnOn={this.turnOnRecalc}
              />
            </Grid>
            <Grid className={classes.unitGridItem} item xs={3}>
              <Field
                className={classes.unitField}
                component={CfSelector}
                disabled={!editing}
                items={units}
                name="doseUnit"
                labelPropertyFactory={(option) => (
                  <span>
                    <FormattedMessage id={`unit.${option.id}`} />
                    /ha
                  </span>
                )}
                onChange={(event, value) => {
                  this.props.change("dosageUnit", value);
                  this.updateFertilizerStats(value, formValues.dose);
                }}
              />
            </Grid>
          </Grid>
        </CfFormControl>
        <CfFormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CfFormattedField
                component={CfFormattedTextField}
                disabled={!editing}
                formatDecimal={4}
                FormHelperTextProps={{ className: classes.helperText }}
                fullWidth
                label={<FormattedMessage id="common.totalDose" />}
                name="dosage"
                onChange={this.onDosageChange}
                type="text"
                validate={[validators.required, validators.positiveNumber]}
                InputLabelProps={{
                  style: styles.inputLabel,
                }}
              />
            </Grid>
            <Grid item style={{ position: "relative" }} xs={3}>
              {this.renderBottomVerticalLine()}
              {this.renderHorizontalLine()}
            </Grid>
            <Grid className={classes.unitGridItem} item xs={3}>
              <Field
                className={classes.unitField}
                component={CfSelector}
                disabled={!editing}
                items={units}
                name="dosageUnit"
                labelPropertyFactory={(option) => (
                  <FormattedMessage id={`unit.${option.id}`} />
                )}
                onChange={(event, value) => {
                  this.props.change("doseUnit", value);
                  this.updateFertilizerStats(value, formValues.dose);
                }}
              />
            </Grid>
          </Grid>
        </CfFormControl>
      </div>
    );
  }
}

DoseDosageFields.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  parcelsArea: PropTypes.number,
  initialize: PropTypes.func,
  material: PropTypes.object,
  editing: PropTypes.bool,
  getFertilizerDoseApi: PropTypes.func,
  handleShowWarning: PropTypes.func,
};

DoseDosageFields.defaultProps = {
  initialize: () => {},
  material: {},
  formValues: {},
  parcelsArea: 0,
  editing: false,
  handleShowWarning: () => {},
  getFertilizerDoseApi: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  formValues: getFormValues(ownProps.name)(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withStyles(styles)(injectIntl(DoseDosageFields)),
);
