import React, { Component } from "react";

import Popover from "@mui/material/Popover";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getCountriesSuggestions } from "../../selectors/manager.selectors";

import { getCountries, resetCountries } from "../../actions/manager.actions";

import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import AdminCountrySelector from "../../../../shared/components/AdminCountrySelector/AdminCountrySelector";

const styles = {
  popoverPaper: {
    width: 350,
    overflow: "hidden",
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
};

export class CountrySwitcher extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;
    const label = formatMessage({
      id: "CountrySwitcher.allCountries",
    }).toUpperCase();
    this.noCountryOption = [{ code: null, name: label }];
  }

  onSuggestionSelect = (country) => {
    this.props.onCountrySelect(country);
    this.handlePopoverClose();
  };

  onSuggestionClear = () => {
    this.props.resetCountries();
  };

  handlePopoverClose = () => {
    this.props.handlePopoverClose();
    this.props.resetCountries();
  };

  render() {
    const {
      anchorEl,
      classes,
      country,
      handlePopoverOpen,
      intl: { formatMessage },
      isOpen,
      suggestions,
    } = this.props;
    const placeholder = formatMessage({ id: "CountrySwitcher.chooseCountry" });
    return (
      <div>
        <CfSwitcherButton
          data-test="country-switcher"
          onClick={handlePopoverOpen}
        >
          <span className={classes.switcherButtonLabel}>
            {!isEmpty(country) && country.code !== null && country.name}
            {(country.code === null || isEmpty(country)) && (
              <FormattedMessage id="CountrySwitcher.allCountries" />
            )}
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.popoverPaper }}
          onClose={this.handlePopoverClose}
          open={isOpen}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <AdminCountrySelector
            getCountries={this.props.getCountries}
            onSuggestionClear={this.onSuggestionClear}
            onSuggestionSelect={this.onSuggestionSelect}
            placeholder={placeholder}
            suggestions={
              suggestions
                ? this.noCountryOption.concat(suggestions)
                : suggestions
            }
          />
        </Popover>
      </div>
    );
  }
}

CountrySwitcher.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  resetCountries: PropTypes.func.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  suggestions: PropTypes.array,
  country: PropTypes.object,
};

CountrySwitcher.defaultProps = {
  suggestions: [],
  onFarmSelect: () => {},
  country: null,
  anchorEl: null,
};

const mapStateToProps = (state) => ({
  suggestions: getCountriesSuggestions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCountries,
      resetCountries,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
  withPopover,
)(CountrySwitcher);
