import React, { FC, ReactNode, useEffect } from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getDrivers,
  getIsFetchingDrivers,
} from "../../../shared/api/telematics/drives/drives.selectors";

import { fetchDrivers } from "../../actions/telematicsList.actions";

import * as sortOrder from "../../../shared/constants/sortOrder.constants";

import { resetDrivers } from "../../../shared/api/telematics/drives/drives.api";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../types";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  DriverSortColumn,
  DriverTo,
} from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: "-5px",
  },
}));

interface TelematicsDetailDriverSelectorProps {
  customClasses?: Record<string, string>;
  dateFrom: string;
  dateTo: string;
  disabled?: boolean;
  error: boolean;
  fetchDrivers(
    dateFrom: string,
    dateTo: string,
    sortCol: string,
    sortDir: string,
  ): void;
  helperText?: React.ReactElement | string;
  isFetching: boolean;
  label: ReactNode;
  onChange(item?: DriverTo | null): void;
  options: DriverTo[];
  placeholder?: string;
  resetDrivers(): void;
  selectedDriverCode: string;
}

const handleGetSelected = (option: DriverTo, value?: DriverTo | null) =>
  option.code === value?.code;

const handleGetLabel = (option?: DriverTo | null) => {
  if (!option) return "";
  if (option.name) return `${option.name} (${option.code})`;
  return option.code;
};

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const TelematicsDetailDriverSelector: FC<
  TelematicsDetailDriverSelectorProps
> = ({
  customClasses,
  dateFrom,
  dateTo,
  disabled,
  error,
  fetchDrivers,
  helperText,
  isFetching,
  label,
  onChange,
  options,
  placeholder,
  resetDrivers,
  selectedDriverCode,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    fetchDrivers(dateFrom, dateTo, DriverSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetDrivers();
    };
  }, [fetchDrivers, resetDrivers, dateFrom, dateTo]);

  const handleRenderOption = (option: DriverTo) => (
    <div>
      <div>{option.name ?? option.code}</div>
      <div className={classes.id}>{option.code}</div>
    </div>
  );

  let selectedDrive;
  if (selectedDriverCode) {
    const option = options.find((option) => option.code === selectedDriverCode);
    selectedDrive = { code: option?.code ?? "", name: option?.name ?? "" };
  } else {
    selectedDrive = { code: "", name: "" };
  }

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={selectedDrive}
      disabled={disabled}
      error={error}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      helperText={helperText}
      id="telematics-detail-driver-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      renderOption={handleRenderOption}
      suggestions={options}
      testId="telematics-detail-driver-selector"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getDrivers(state),
  isFetching: getIsFetchingDrivers(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrivers,
      resetDrivers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsDetailDriverSelector);
