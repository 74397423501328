import React from "react";

import { Field, FieldArray } from "formik";
import PropTypes from "prop-types";

import ActionParcelSubtractableAreasSection from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import SubtractableAreaWithRadioButton from "../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton";

function BoundaryParcelSubtractableAreas(props) {
  const {
    isEditing,
    maxValue,
    onParcelSubtractionDelete,
    parcelId,
    parcelIds,
    parcelIndex,
  } = props;
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.boundary`}>
      {({ form, push, remove }) => {
        const handleSubtractionDelete = (type, value) => {
          const index = form.values.parcels[
            parcelIndex
          ].subtractableAreas.boundary.findIndex(
            (area) => area.value === value,
          );
          remove(index);
          onParcelSubtractionDelete(type, value);
        };

        return (
          <ActionParcelSubtractableAreasSection
            isEditing={isEditing}
            maxValue={maxValue}
            parcelId={parcelId}
            parcelIds={parcelIds}
            subtractableAreaType="Boundary"
            handleAdd={(sa) => {
              push(sa);
              form.setFieldValue(
                `parcels.${props.parcelIndex}.subtractableAreas.boundaryChecked`,
                sa.value,
              );
            }}
            subtractableAreas={
              form.values.parcels[parcelIndex].subtractableAreas.boundary
            }
          >
            <Field
              component={SubtractableAreaWithRadioButton}
              handleSubtractionDelete={handleSubtractionDelete}
              isEditing={isEditing}
              name={`parcels.${parcelIndex}.subtractableAreas.boundaryChecked`}
              options={
                form.values.parcels[parcelIndex].subtractableAreas.boundary
              }
              value={
                form.values.parcels[parcelIndex].subtractableAreas
                  .boundaryChecked
              }
            />
          </ActionParcelSubtractableAreasSection>
        );
      }}
    </FieldArray>
  );
}

BoundaryParcelSubtractableAreas.propTypes = {
  isEditing: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
  onParcelSubtractionDelete: PropTypes.func.isRequired,
};

BoundaryParcelSubtractableAreas.defaultProps = {
  isEditing: false,
};

export default BoundaryParcelSubtractableAreas;
