/**
 * Created by ondrejzvara on 29.3.16.
 */

/*@ngInject*/
export default function Storage($q) {
  return {
    getDefer: getDefer,
    getUnresolvedDefer: getUnresolvedDefer,
    setResolvedDefer: setResolvedDefer,
  };

  /////////////////////////

  function getDefer(obj) {
    var defer;
    if (_.isEmpty(obj) || obj.resolvedDefer === false) {
      defer = getUnresolvedDefer(obj);
    } else {
      defer = obj.defer;
    }
    return defer;
  }

  function getUnresolvedDefer(obj) {
    var defer;
    if (_.isEmpty(obj) || obj.resolvedDefer === true) {
      defer = $q.defer();
      obj.defer = defer;
      obj.resolvedDefer = false;
    } else {
      defer = obj.defer;
    }
    return defer;
  }

  function setResolvedDefer(obj) {
    obj.resolvedDefer = true;
  }
}
