import { RSAA, RSAAAction } from "redux-api-middleware";

import * as types from "./sowingPlan.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import { ExportPayload, RsaaMethods, RsaaTypes } from "../../api.types";
import { SowingPlanParcelCropBody } from "../agroevidence.types";

export const getParcelSowingPlanApi = (
  parcelId: string,
  startYear: number,
  size: number,
) => ({
  [RSAA]: {
    endpoint: `sowing-plan/parcels/${parcelId}?startYear=${startYear}&size=${size}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_PLAN_REQUEST,
      types.GET_PARCEL_PLAN_SUCCESS,
      types.GET_PARCEL_PLAN_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelSowingPlanApi = () => ({
  type: types.RESET_PARCEL_PLAN,
});

export const updateParcelSowingPlanApi = (
  parcelId: string,
  body: SowingPlanParcelCropBody,
) => ({
  [RSAA]: {
    endpoint: `sowing-plan/parcels/${parcelId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(body),
    types: [
      types.PUT_PARCEL_PLAN_REQUEST,
      types.PUT_PARCEL_PLAN_SUCCESS,
      types.PUT_PARCEL_PLAN_ERROR,
    ] as RsaaTypes,
  },
});

// Check if this need endpoint, updateSeasonsSowingPlanApi do same thing with extra update
export const getSeasonsSowingPlanApi = (startYear: number, size: number) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons?startYear=${startYear}&size=${size}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SEASONS_REQUEST,
      types.GET_SEASONS_SUCCESS,
      types.GET_SEASONS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetSeasonsSowingPlanApi = () => ({
  type: types.RESET_SEASONS,
});

export const updateSeasonsSowingPlanApi = (
  startYear: number,
  size: number,
) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons?startYear=${startYear}&size=${size}&`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.POST_SEASONS_REQUEST,
      types.POST_SEASONS_SUCCESS,
      types.POST_SEASONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getSeasonDetailSowingPlanApi = (seasonId: string) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons/${seasonId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SEASON_DETAIL_REQUEST,
      types.GET_SEASON_DETAIL_SUCCESS,
      types.GET_SEASON_DETAIL_ERROR,
    ] as RsaaTypes,
  },
});

export const exportSowingPlanApi = (startYear: number, size: number) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons/excel?startYear=${startYear}&size=${size}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.EXPORT_SEASONS_REQUEST,
      {
        type: types.EXPORT_SEASONS_SUCCESS,
        payload: (_action: RSAAAction, _state: MainMapState, res: Response) =>
          ({
            body: res.blob(),
            type: res.headers.get("Content-Type"),
            disposition: res.headers.get("Content-Disposition"),
          }) as ExportPayload,
      },
      types.EXPORT_SEASONS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetSeasonSowingPlanApi = (seasonId: string) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons/${seasonId}/reset?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.RESET_SEASON_PLAN_REQUEST,
      types.RESET_SEASON_PLAN_SUCCESS,
      types.RESET_SEASON_PLAN_ERROR,
    ] as RsaaTypes,
  },
});
