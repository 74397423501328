import LocalStorage from "../services/LocalStorage.service";

import type { Variant } from "./variants";

const FARMASENSE_DOMAIN = /farmasense.gr/;
const MYMEX_DOMAIN = /mymex.cz/;

export const isFarmaSenseDomain =
  !!window.location?.hostname.match(FARMASENSE_DOMAIN);

export const isMyMexDomain = !!window.location?.hostname.match(MYMEX_DOMAIN);

const savedTheme = LocalStorage.loadFromLocalStorage("theme") as Variant | null;

const resolveVariant = (): Variant => {
  if (isFarmaSenseDomain) {
    return "farmasense";
  }

  if (isMyMexDomain) {
    return "mymex";
  }

  return "cleverfarm";
};

export const getAppVariant = (): Variant => {
  if (savedTheme == null) {
    const variant = resolveVariant();
    LocalStorage.saveToLocalStorage(variant, "theme");
    return variant;
  }

  // in the past, numbers were used to identify different themes
  // TODO drop this after few weeks
  if (isNaN(parseInt(savedTheme))) {
    return savedTheme;
  }

  return resolveVariant();
};
