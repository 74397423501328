import ErrorService from "../../../services/Error.service";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";

export const getParcelsCropDetails = () => (state: ParcelsState) =>
  state.api.parcelsCropDetails.items;
export const getIsExportingDataCropDetails = (state: ParcelsState) =>
  state.api.parcelsCropDetails.isExportingData;
export const getIsFetchingParcelsCropDetails = () => (state: ParcelsState) =>
  state.api.parcelsCropDetails.isFetchingItems;
export const getTotalCountParcelsCropDetails = (state: ParcelsState) =>
  state.api.parcelsCropDetails.totalCount;
export const getParcelsCropDetailsError = (state: ParcelsState) =>
  ErrorService.getResErrorDto(state.api.parcelsCropDetails.itemsError);
