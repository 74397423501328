import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import FeatureConfigService, {
  FEATURE_TYPE_PREFIX,
} from "../../../sensors/services/FeatureConfig.service";
import { conditionOperatorsCharacters } from "../../services/conditionOperators";

const styles = {
  condition: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  featureIcon: {
    display: "flex",
    marginRight: 4,
  },
  tooltip: {
    maxWidth: "none",
  },
};

function NotificationCondition(props) {
  const { classes, condition } = props;
  const featureConfig = FeatureConfigService.getFeatureConfig(
    `${FEATURE_TYPE_PREFIX}${condition.feature}`,
  );
  const featureName = FeatureConfigService.getFeatureNameWithoutPrefix(
    featureConfig.name,
  );
  const { icon: FeatureIcon, stroke } = featureConfig;

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={
        <div>
          <FormattedMessage id={`NodeFeature.${featureName}`} />
          {": "}
          <FormattedMessage id="common.value" />{" "}
          <FormattedMessage id={`conditionOperators.${condition.operator}`} />{" "}
          {condition.value} {featureConfig.unit}
        </div>
      }
    >
      <div className={classes.condition}>
        <span className={classes.featureIcon}>
          <FeatureIcon htmlColor={stroke} style={{ fontSize: 18 }} />
        </span>
        {conditionOperatorsCharacters[condition.operator]} {condition.value}{" "}
        {featureConfig.unit}
      </div>
    </Tooltip>
  );
}

NotificationCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationCondition);
