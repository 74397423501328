import { RSAA } from "redux-api-middleware";

import * as types from "./chemistry.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getChemistryApplications = (chemistryId) => ({
  [RSAA]: {
    endpoint: `por/${chemistryId}/applications?`,
    method: methods.GET,
    module: modules.CORE,
    types: [
      types.GET_CHEMISTRY_APPLICATIONS,
      types.GET_CHEMISTRY_APPLICATIONS_SUCCESS,
      types.GET_CHEMISTRY_APPLICATIONS_FAILURE,
    ],
  },
});

export const resetChemistryApplications = () => ({
  type: types.RESET_CHEMISTRY_APPLICATIONS,
});
