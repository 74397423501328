import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./devices.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getAdminDevicesApi = (params) => ({
  [RSAA]: {
    endpoint: `admin?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_ADMIN_DEVICES_REQUEST,
      types.GET_ADMIN_DEVICES_SUCCESS,
      types.GET_ADMIN_DEVICES_ERROR,
    ],
  },
});

export const resetAdminDevicesApi = () => ({
  type: types.RESET_ADMIN_DEVICES,
});

export const patchAdminDeviceApi = (deviceIds, payload) => ({
  [RSAA]: {
    endpoint: `admin/${deviceIds.join(",")}?`,
    method: methods.PATCH,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [
      types.PATCH_ADMIN_DEVICE_REQUEST,
      types.PATCH_ADMIN_DEVICE_SUCCESS,
      types.PATCH_ADMIN_DEVICE_ERROR,
    ],
  },
});

export const postAdminSyncWithPCApi = () => ({
  [RSAA]: {
    endpoint: "admin/sync/plant-control?",
    method: methods.POST,
    module: modules.IRRIGATION,
    types: [
      types.POST_ADMIN_SYNC_PC_REQUEST,
      types.POST_ADMIN_SYNC_PC_SUCCESS,
      types.POST_ADMIN_SYNC_PC_ERROR,
    ],
  },
});

export const getDeviceTypes = () => ({
  [RSAA]: {
    endpoint: "devices/types?",
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_DEVICE_TYPES_REQUEST,
      types.GET_DEVICE_TYPES_SUCCESS,
      types.GET_DEVICE_TYPES_ERROR,
    ],
  },
});

export const resetDeviceTypes = () => ({
  type: types.RESET_DEVICE_TYPES,
});

export const getDevicesApi = (params) => ({
  [RSAA]: {
    endpoint: `devices?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_DEVICES_REQUEST,
      types.GET_DEVICES_SUCCESS,
      types.GET_DEVICES_ERROR,
    ],
  },
});

export const getInactiveDevicesApi = (params) => ({
  [RSAA]: {
    endpoint: `devices?${queryString.stringify({
      ...params,
      status: "INACTIVE",
    })}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_INACTIVE_DEVICES_REQUEST,
      types.GET_INACTIVE_DEVICES_SUCCESS,
      types.GET_INACTIVE_DEVICES_ERROR,
    ],
  },
});

export const getDeviceApi = (deviceId) => ({
  [RSAA]: {
    endpoint: `devices/${deviceId}?`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_DEVICE_REQUEST,
      types.GET_DEVICE_SUCCESS,
      types.GET_DEVICE_ERROR,
    ],
  },
});

export const patchDevice = (deviceId, deviceProps) => ({
  [RSAA]: {
    endpoint: `devices/${deviceId}?`,
    method: methods.PATCH,
    module: modules.IRRIGATION,
    body: JSON.stringify(deviceProps),
    types: [
      types.PATCH_DEVICE_REQUEST,
      types.PATCH_DEVICE_SUCCESS,
      types.PATCH_DEVICE_ERROR,
    ],
  },
});

export const getDeviceValvesApi = (deviceId) => ({
  [RSAA]: {
    endpoint: `devices/${deviceId}/valves?`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_DEVICE_VALVES_REQUEST,
      types.GET_DEVICE_VALVES_SUCCESS,
      types.GET_DEVICE_VALVES_ERROR,
    ],
  },
});

export const valvesManualActionApi = (deviceId, payload) => ({
  [RSAA]: {
    endpoint: `devices/${deviceId}/control/valves?`,
    method: methods.POST,
    module: modules.IRRIGATION,
    body: JSON.stringify(payload),
    types: [
      types.POST_VALVES_ACTION_REQUEST,
      types.POST_VALVES_ACTION_SUCCESS,
      types.POST_VALVES_ACTION_ERROR,
    ],
  },
});

export const getIrrigationPoints = (transformedFilter) => ({
  [RSAA]: {
    endpoint: `points?${queryString.stringify(transformedFilter)}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_IRRIGATIONS_POINTS,
      types.GET_IRRIGATIONS_POINTS_SUCCESS,
      types.GET_IRRIGATIONS_POINTS_ERROR,
    ],
  },
});

export const getDeviceIntervalsApi = (deviceId, params) => ({
  [RSAA]: {
    endpoint: `devices/${deviceId}/intervals?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.IRRIGATION,
    types: [
      types.GET_DEVICE_INTERVALS_REQUEST,
      types.GET_DEVICE_INTERVALS_SUCCESS,
      types.GET_DEVICE_INTERVALS_ERROR,
    ],
  },
});
