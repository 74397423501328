import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const style = (theme) => ({
  label: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "16px",
  },
});

export const CfChartTooltipLabel = (props) => {
  const { children, classes } = props;
  return <p className={classes.label}>{children}</p>;
};

CfChartTooltipLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(style)(CfChartTooltipLabel);
