import React, { FC, ReactNode, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSensorsAdminIsFetchingFarms } from "../../../sensors/selectors/sensors.selectors";
import { getFarmsSuggestions } from "../../../shared/selectors/manager.selectors";

import {
  getFarmsByName,
  resetFarms,
} from "../../../shared/actions/manager.actions";

import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import { Thunk } from "../../../../../types";
import AdminFarmSelector from "../../../../shared/components/AdminFarmSelector/AdminFarmSelector";

import { FarmTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  AdminFarm,
  IrrigationAdminDevice,
  IrrigationAdminState,
} from "../../admin.irrigation.types";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 350,
    height: 350,
    overflow: "hidden",
  },
  switcherLabel: {
    lineHeight: 1.2,
  },
  switcherLabelBigFont: {
    fontSize: 16,
    textTransform: "uppercase",
  },
  switcherLabelModal: {
    fontSize: 16,
    fontWeight: 400,
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
  button: (isSingleAssigned) =>
    isSingleAssigned
      ? { fontSize: 13, marginLeft: -9 }
      : { padding: "6px 12px" },
}));

interface Props {
  anchorEl?: null | Element;
  disabled?: boolean;
  fontSize?: number;
  getFarmsByName: (filter: string, params: string) => void;
  handlePopoverClose: () => void;
  handlePopoverOpen: () => void;
  inModal?: boolean;
  isFetchingFarms: boolean;
  isOpen: boolean;
  isSingleAssigned?: boolean;
  item?: null | IrrigationAdminDevice;
  noFarmLabel?: ReactNode;
  onAssignDevicesToFarm: (farm: AdminFarm) => void;
  resetFarms: () => void;
  selectedLength?: number;
  suggestions?: FarmTo[];
}

const IrrigationAdminAssignFarm: FC<Props> = ({
  anchorEl = null,
  disabled = false,
  fontSize = 13,
  getFarmsByName,
  handlePopoverClose,
  handlePopoverOpen,
  inModal = false,
  isFetchingFarms,
  isOpen,
  isSingleAssigned = false,
  item = null,
  noFarmLabel = <FormattedMessage id="common.no-farm" />,
  onAssignDevicesToFarm,
  resetFarms,
  selectedLength = 0,
  suggestions = [],
}) => {
  const intl = useIntl();
  const classes = useStyles({ isSingleAssigned });

  const [mergedSuggestions, setMergedSuggestions] = useState<Partial<FarmTo>[]>(
    [],
  );

  useEffect(() => {
    setMergedSuggestions([
      {
        id: "0",
        name: intl.formatMessage({ id: "common.no-farm" }),
      },
      ...suggestions,
    ]);
  }, [suggestions, intl]);

  const onSuggestionSelect = (farmToAssign: AdminFarm) => {
    onAssignDevicesToFarm(farmToAssign);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
    resetFarms();
    setMergedSuggestions([]);
  };

  const hasFarmAssigned = (item: IrrigationAdminDevice | null) =>
    item && item.farm.name && item.farm.code && item.farm.id;

  const isAssigned = hasFarmAssigned(item);

  return (
    <div>
      <CfSwitcherButton
        disabled={disabled}
        onClick={handlePopoverOpen}
        transparent={true}
        classes={{
          custom: classnames(
            classes.button,
            !(disabled || isAssigned) && classes.activeButton,
          ),
        }}
      >
        <span
          className={classnames(
            classes.switcherLabel,
            fontSize === 16 && classes.switcherLabelBigFont,
            inModal && classes.switcherLabelModal,
          )}
        >
          {isAssigned ? item?.farm.name : noFarmLabel}
          {selectedLength > 0 && `\u00A0(${selectedLength})`}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={onSuggestionClear}
        open={isOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: 350,
          vertical: 0,
        }}
      >
        <AdminFarmSelector
          getFarmsByName={getFarmsByName}
          isFetching={isFetchingFarms}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={mergedSuggestions}
        />
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: IrrigationAdminState) => ({
  isFetchingFarms: getSensorsAdminIsFetchingFarms(state),
  suggestions: getFarmsSuggestions(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationAdminState>) =>
  bindActionCreators(
    {
      getFarmsByName,
      resetFarms,
    },
    dispatch,
  );

export default withPopover(
  connect(mapStateToProps, mapDispatchToProps)(IrrigationAdminAssignFarm),
);
