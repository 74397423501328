import React, { Component } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    "& .MuiAutocomplete-tag": {
      fontSize: 14,
      margin: "0px 2px 2px 0px",
      "& .MuiChip-deleteIcon": {
        color: theme.palette.grey[500],
        "&:hover": {
          color: theme.palette.grey[700],
        },
      },
    },
    "& .MuiInput-root": {
      paddingTop: 0,
    },
  },
  errorInput: {
    "& .MuiAutocomplete-input": {
      "&::placeholder": {
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
  },
});

class CfAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValues,
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultValues } = this.props;
    const { value } = this.state;

    if (
      !isEqual(defaultValues, prevProps.defaultValues) &&
      !isEqual(defaultValues, value)
    ) {
      this.setState({
        value: defaultValues,
      });
    }
  }

  onSelect = (v) => {
    this.setState({
      value: v,
    });
    this.props.onChange(v);
  };

  renderOption = (props, option) => {
    const uniqueKey = option?.id || Math.random().toString();
    return (
      <li {...props} key={uniqueKey}>
        {this.props.renderOption(option)}
      </li>
    );
  };

  render() {
    const {
      classes,
      disabled,
      error,
      filterOptions,
      fullWidth,
      getLabel,
      getSelected,
      groupByOptions,
      helperText,
      id,
      input,
      isFetching,
      isMultiple,
      label,
      loadOptions,
      multilineInput,
      onChange,
      placeholder,
      renderGroup,
      renderTags,
      suggestions,
      testId,
    } = this.props;

    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Autocomplete
          {...input}
          data-test={testId}
          disabled={disabled}
          filterOptions={filterOptions}
          fullWidth={fullWidth}
          getOptionLabel={(option) => getLabel(option)}
          groupBy={(option) => groupByOptions(option)}
          id={id}
          isOptionEqualToValue={(option, v) => getSelected(option, v)}
          loading={isFetching}
          multiple={isMultiple}
          noOptionsText={<FormattedMessage id="AutosuggestSelect.noOptions" />}
          options={suggestions}
          renderGroup={(option) => renderGroup(option)}
          renderOption={this.renderOption}
          renderTags={renderTags}
          value={value}
          onChange={
            isEmpty(input.value)
              ? (event, v) => this.onSelect(v)
              : (evt) => onChange(evt.target.value)
          }
          onInputChange={(event, v, reason) => {
            if (loadOptions) {
              if (reason === "input") {
                loadOptions(v);
              }
              if (reason === "clear" || isEmpty(v)) {
                loadOptions();
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={error ? classes.errorInput : undefined}
              error={error}
              fullWidth
              helperText={helperText}
              label={label}
              multiline={multilineInput}
              placeholder={placeholder}
              variant="standard"
            />
          )}
        />
      </div>
    );
  }
}

CfAutocomplete.propTypes = {
  input: PropTypes.any,
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  loadOptions: PropTypes.func,
  defaultValues: PropTypes.any,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func,
  isMultiple: PropTypes.bool,
  label: PropTypes.any,
  testId: PropTypes.string,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  filterOptions: PropTypes.func,
  groupByOptions: PropTypes.func,
  renderGroup: PropTypes.func,
  getSelected: PropTypes.func,
  getLabel: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multilineInput: PropTypes.bool,
};

CfAutocomplete.defaultProps = {
  input: {},
  id: "cf-autocomplete",
  defaultValues: null,
  isFetching: false,
  onChange: () => {},
  loadOptions: null,
  isMultiple: false,
  label: undefined,
  testId: "cf-autocomplete",
  renderOption: (option) => option.label || option.name,
  renderTags: undefined,
  filterOptions: undefined,
  groupByOptions: () => {},
  renderGroup: (option) => option.children,
  getSelected: (option, value) => option.name === value?.name,
  getLabel: (option) =>
    typeof option === "string" ? option : option?.label || option?.name || "",
  fullWidth: false,
  disabled: false,
  multilineInput: false,
};

export default withStyles(styles)(CfAutocomplete);
