/**
 * Created by jenc.
 */

cfDateTime.$inject = ["$filter"];

export function cfDateTime($filter) {
  return function (dateStr) {
    return $filter("cfDate")(dateStr, "dd.MM.yyyy H:mm");
  };
}
