/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from "angular";
import tpl from "./seeds.html";
import SeedsController from "./seeds.controller";

export default angular
  .module("app.components.farm.classifiers.private.seeds", [])
  .controller("SeedsController", SeedsController)
  .config(config).name;

/*@ngInject*/
function config($stateProvider) {
  $stateProvider.state("farm.active.classifiers.private.seeds", {
    url: "/seeds",
    views: {
      seeds: {
        controller: "SeedsController as SeedsCtrl",
        template: tpl,
      },
    },
  });
}
