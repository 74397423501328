/**
 * Created by ondrejzvara on 14.11.16.
 */

import $ from "jquery";

/*@ngInject*/
export default function cfHttpSpinner(
  $rootScope,
  $parse,
  $timeout,
  $compile,
  $templateCache,
) {
  /**
   * Usage example:
   *
   * Multiple method loader
   * <div ng-http-loader methods="['PUT', 'POST']"template="example/loader.tpl.html"></div>
   *
   * Single method loader
   * <div cf-http-spiner methods="'GET'" template="example/loader.tpl.html"></div>
   *
   * template
   * <div cf-http-spiner template="example/loader.tpl.html"></div>
   *
   */

  var directive = {
    restrict: "A",

    link: function (scope, element, attrs) {
      var template = $parse(attrs.template)() || attrs.template;
      template = !_.isUndefined(template)
        ? _.toString(template)
        : "app/assets/templates/loader/loader.html";

      var urlMatch = $parse(attrs.urlMatch)() || attrs.urlMatch;
      urlMatch = _.isUndefined(urlMatch) ? null : _.toString(urlMatch);

      var paramsMatch = $parse(attrs.paramsMatch)() || attrs.paramsMatch;
      paramsMatch = _.isUndefined(paramsMatch) ? null : _.toString(paramsMatch);

      var loaderClass = $parse(attrs.loaderClass)() || attrs.loaderClass;
      loaderClass = _.isUndefined(loaderClass) ? null : _.toString(loaderClass);

      var methods = $parse(attrs.methods)() || attrs.methods;
      methods = _.isUndefined(methods) ? [] : methods;
      methods = _.isArray(methods) ? methods : [methods];
      methods = _.map(methods, function (method) {
        return _.toUpper(method);
      });

      var ttl = $parse(attrs.ttl)() || attrs.ttl;
      ttl = _.isUndefined(ttl) ? 0 : _.toNumber(ttl) * 1000;
      ttl = _.isNumber(ttl) && !_.isNaN(ttl) ? ttl : 0;

      var show = false;
      var timeoutId,
        showLoader = show,
        loader = getLoaderElement();

      function toggleDisplay(event, config) {
        if (
          urlMatch &&
          !_.includes(_.toUpper(config.url), _.toUpper(urlMatch))
        ) {
          return;
        }

        if (paramsMatch && !config.params.hasOwnProperty(paramsMatch)) {
          return;
        }

        if (
          !_.isEmpty(methods) &&
          !_.includes(methods, _.toUpper(config.methods))
        ) {
          return;
        }

        if (config.methods) {
          showLoader = event.name === "loaderShow";
        }

        if (ttl <= 0 || (!timeoutId && !showLoader)) {
          show = showLoader;
          toggleElement(show, element, urlMatch);
          return;
        }

        if (timeoutId) {
          return;
        }

        show = showLoader;
        toggleElement(show, element, urlMatch);
        timeoutId = $timeout(function () {
          if (!showLoader) {
            show = showLoader;
            toggleElement(show, element, urlMatch);
          }
          timeoutId = undefined;
        }, ttl);
      }

      function toggleElement(show, element) {
        element.ready(function () {
          if (show) {
            $(element).hide();
            $(element).after(loader);
          } else {
            $(element).show();
            $(loader).remove();
          }
        });
      }

      function getLoaderElement() {
        var loader = $compile($templateCache.get(template))({});
        if (loaderClass) {
          loader.addClass(loaderClass);
        }
        return loader;
      }

      $rootScope.$on("loaderShow", toggleDisplay);
      $rootScope.$on("loaderHide", toggleDisplay);
    },
  };
  return directive;
}
