export default class Logger {
  static log(...params) {
    if (process.env.NODE_ENV !== "production")
      if (window.console)
        // eslint-disable-next-line
        console.log(...params);
  }

  static warn(...params) {
    if (process.env.NODE_ENV !== "production")
      if (window.console)
        // eslint-disable-next-line
        console.warn(...params);
  }
}
