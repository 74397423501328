import React, { FC, useContext, useMemo } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getAgIntegrationStatusVarioDocApi,
  loginVarioDocApi,
} from "../../../../shared/api/sentinel/varioDoc/varioDoc.api";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { AsyncFn, Thunk } from "../../../../types";

import { UserState } from "../../../../reducers/user.reducer.types";
import {
  IntegrationTo,
  VariodocCreateTo,
} from "../../../../shared/api/satellite/satellite.types";

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    minWidth: 300,
    padding: "5px 0px",
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  textField: {
    display: "flex",
    justifyContent: "left",
    marginBottom: 20,
    color: theme.palette.grey[500],
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: 21,
  },
}));

interface Props {
  data?: IntegrationTo;
  onClose: () => void;
  opened?: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const AgActivationDialog: FC<Props & ReduxProps> = ({
  data,
  getAgIntegrationStatusVarioDocApi,
  loginVarioDocApi,
  onClose,
  opened = false,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(
    () => ({
      username: data?.email ?? "",
      password: "",
    }),
    [data],
  );

  const handleSubmit = (values: VariodocCreateTo) => {
    const param: VariodocCreateTo = {
      username: values.username,
      password: values.password,
    };

    (loginVarioDocApi as AsyncFn<VariodocCreateTo>)(param).then((res) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage
              id={
                res.payload.status === 409
                  ? "VarioDoc.activationDialog.error409"
                  : "VarioDoc.activationDialog.error"
              }
            />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: <FormattedMessage id="VarioDoc.activationDialog.success" />,
        isSuccess: true,
      });
      getAgIntegrationStatusVarioDocApi();
      onClose();
    });
  };

  const validate = (values: VariodocCreateTo) => {
    const errors: Record<string, string | React.ReactNode> = {};
    if (!values.username || values.username === "") {
      errors.username = <FormattedMessage id="validation.required" />;
    }
    if (!values.password || values.password === "") {
      errors.password = <FormattedMessage id="validation.required" />;
    }
    return errors;
  };

  const isNew = !data?.email;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ resetForm, submitForm }) => {
        const closeForm = () => {
          resetForm();
          onClose();
        };

        return (
          <CfDialog
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onCancel={closeForm}
            onClose={closeForm}
            opened={opened}
            acceptText={
              <FormattedMessage id="VarioDoc.activationDialog.acceptBtn" />
            }
            title={
              isNew ? (
                <FormattedMessage id="VarioDoc.activationDialog.title" />
              ) : (
                <FormattedMessage id="VarioDoc.activationDialog.edit.title" />
              )
            }
          >
            <Form className={classes.itemContainer}>
              <Field
                component={CfFormikTextField}
                fullWidth
                id="username"
                label={<FormattedMessage id="VarioDoc.activationDialog.name" />}
                name="username"
              />
              <Field
                component={CfFormikTextField}
                fullWidth
                id="password"
                name="password"
                type="password"
                label={
                  <FormattedMessage id="VarioDoc.activationDialog.password" />
                }
              />
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      getAgIntegrationStatusVarioDocApi,
      loginVarioDocApi: loginVarioDocApi as (param: VariodocCreateTo) => void,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps);
export default connector(AgActivationDialog);
