import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useTelematicsAggregationDetailStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      height: "100%",
    },
    headerWithTabs: {
      backgroundColor: theme.palette.common.white,
      borderBottom: "1px solid #EFEFEF",
    },
    headerWrapper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    bodyWrapper: {
      position: "relative",
    },
    body: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentWrapper: {
      height: "100%",
    },
    content: {
      padding: theme.spacing(2),
      width: "100%",
    },
    mapWrapper: {
      height: "100%",
    },
    spinnerWrapper: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);
