import React from "react";

import countBy from "lodash/countBy";
import every from "lodash/every";
import get from "lodash/get";
import size from "lodash/size";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import {
  isEph,
  isInvalidCatchCropApplication,
} from "../../eph/misc/eph.helpers";
import { isOtherAction } from "../../others/helpers/others.helpers";
import { isVrf } from "../../vrf/helpers/vrf.helpers";
import { isVrs } from "../../vrs/helpers/vrs.helpers";

export const targetCropChanged = (oldTargetCrop, newTargetCrop) =>
  newTargetCrop?.legislativeCode !== oldTargetCrop?.legislativeCode;

export const parcelsCountChanged = (oldParcels, newParcels) =>
  newParcels.length !== oldParcels.length;

export const expenseCountChanged = (oldExpenses, newExpenses) =>
  newExpenses.length !== oldExpenses.length;

export const parcelsAreaChanged = (oldParcelsArea, newParcelsArea) =>
  oldParcelsArea !== newParcelsArea;

export const parcelsRestrictedAreaChanged = (oldParcels, newParcels) =>
  newParcels.some((newParcel) => {
    const currParcel = oldParcels.find((p) => p.id === newParcel.id);
    if (!currParcel) {
      return false;
    }

    return currParcel.restrictedArea !== newParcel.restrictedArea;
  });

export const resolveTargetCrop = (parcels) => {
  if (parcels && parcels[0]?.seedApplication) {
    return get(parcels[0], "seedApplication.seed.crop");
  }
  return undefined;
};

export const isAllParcelsSameCrop = (parcelList, cropPredicate) => {
  const numParcelsByCropCodes = countBy(
    parcelList,
    "seedApplication.seed.crop.legislativeCode",
  );
  return (
    size(numParcelsByCropCodes) === 1 &&
    (cropPredicate ? every(parcelList, cropPredicate) : true)
  );
};

export const warnTargetCrop = (values) => {
  let result = null;
  const { parcels, targetCrop } = values;

  if (targetCrop) {
    //  TODO make for all action types
    const allParcelsSameCrop = isAllParcelsSameCrop(parcels);
    const defaultTargetCrop = resolveTargetCrop(parcels);
    if (
      defaultTargetCrop &&
      (defaultTargetCrop.legislativeCode !== targetCrop.legislativeCode ||
        !allParcelsSameCrop)
    ) {
      result = {
        // advanced error structure supported by some Cf-components (like CfSelector)
        icon: "info",
        message: (
          <FormattedMessage
            id={
              allParcelsSameCrop
                ? "action.targetCropWarning"
                : "action.targetCropWarningDiffCrops"
            }
            values={{
              crop: targetCrop.name,
            }}
          />
        ),
      };
    }
  }

  return result;
};

export const validateParcels = (values) => {
  const { parcels } = values;
  return !parcels || !parcels.length
    ? { _error: <FormattedMessage id="ParcelControl.chooseParcel" /> }
    : null;
};

export const isValidDate = (date) => moment.isMoment(date) && date.isValid();

export const validateActionDate = (values) => {
  const { actionDate } = values;
  if (!actionDate) {
    return { _error: <FormattedMessage id="action.selectDate" /> };
  } else if (!isValidDate(actionDate)) {
    return { _error: <FormattedMessage id="action.selectValidDate" /> };
  } else {
    return null;
  }
};

export const validateExpenses = (
  values,
  msg = "ActionExpensesControl.chooseMaterial",
) => {
  const { expenses } = values;
  return !expenses || !expenses.length
    ? { _error: <FormattedMessage id={msg} /> }
    : null;
};

export const validateMinDose = (values) => {
  const { maxDose, minDose } = values;
  return maxDose && minDose >= maxDose
    ? {
        _error: (
          <FormattedMessage id="VariableFertilization.minDoseValidation" />
        ),
      }
    : null;
};

export const validateMaxDose = (values) => {
  const { maxDose, minDose } = values;
  return minDose && maxDose <= minDose
    ? {
        _error: (
          <FormattedMessage id="VariableFertilization.maxDoseValidation" />
        ),
      }
    : null;
};

export const validateTargetCrop = (values) => {
  const { parcels, targetCrop } = values;

  if (!targetCrop) {
    return { _error: <FormattedMessage id="validation.required" /> };
  }

  return isInvalidCatchCropApplication(parcels, targetCrop)
    ? {
        message: <FormattedMessage id="Eph.catchCropParcelsError" />,
      }
    : null;
};

export const zoneTotalDoseChanged = (oldZones, newZones) =>
  newZones.some((newZone) => {
    const currZone = oldZones.find((z) => z.zoneId === newZone.zoneId);
    if (!currZone) {
      return false;
    }

    return currZone.totalDose !== newZone.totalDose;
  });

export const getRouting = (action, variableActionIds) =>
  isEph(action, variableActionIds) ||
  isVrf(action, variableActionIds) ||
  isVrs(action, variableActionIds) ||
  isOtherAction(action)
    ? "actions.action"
    : "action";
