import { NO_CROP_LEGISLATIVE_CODE } from "../constants/crops.constants";

export default class FeatureService {
  static isFeature = (f) => Boolean(f);
  static isWithoutCrop = (f) =>
    f.get("crop_legislative_code") === NO_CROP_LEGISLATIVE_CODE;
  static oneParcelLpis = (f) => f.get("lpis_parcel_count") < 2;
  static isCzUser = (code) => code === "CZ";
  static getLpisBlock = (f) => f.get("lpis_block_id");
}
