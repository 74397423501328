import moment from "moment";
import { createSelector } from "reselect";

import {
  getLanduse,
  getIsFetching,
} from "../../../shared/api/core/landuse/landuse.selectors";
import { getParcelsMapActionable } from "../../../shared/api/core/parcels/parcels.selectors";

export const getTool = (state) => state.ui.editor.tool;
export const getStage = (state) => state.ui.editor.stage;
export const getError = (state) => state.ui.editor.error;
export const getHoveredId = (state) => state.ui.editor.hoveredId;
export const getSelected = (state) => state.ui.editor.selected;
export const getMapDragging = (state) => state.ui.editor.isMapDragging;
export const getIsEditing = (state) => Boolean(getTool(state));

export const getSelectedParcel = (state) => state.ui.editor.selected[0] || null;
export const getSelectedParcelLpisBlock = createSelector(
  getSelectedParcel,
  (selected) => (selected ? selected.lpisBlockId : null),
);
export const getSelectedParcels = (state) => state.ui.editor.selected;
export const getSelectedParcelIds = createSelector(
  getSelectedParcels,
  (selected) => selected.map((item) => item.id),
);

// hint selectors
export const getHintAction = (state) => state.ui.editor.hint.action;
export const getHintRefresh = (state) => state.ui.editor.hint.refresh;
export const getHintData = (state) => state.ui.editor.hint.data;

// merge selectors
const isDistinct = (value, index, self) => self.indexOf(value) === index;
const isNotSelected = (value, selected) => selected.indexOf(value) < 0;

export const getAvailableIds = createSelector(
  getSelectedParcels,
  getSelectedParcelIds,
  (selected, selectedIds) => {
    const neighbors = selected.map((s) => s.neighbors);
    const res = []
      .concat(...neighbors)
      .filter(
        (value, index, self) =>
          isNotSelected(value, selectedIds) && isDistinct(value, index, self),
      );
    return res;
  },
);

export const getAllReachableIds = (state) => state.ui.editor.merge.reachableIds;
export const getFilteredReachableIds = createSelector(
  getAllReachableIds,
  getAvailableIds,
  getSelectedParcelIds,
  (reachableIds, availableIds, selectedIds) =>
    reachableIds.filter(
      (id) => !availableIds.includes(id) && !selectedIds.includes(id),
    ),
);

export const getValidFrom = (state) => state.ui.editor.validFrom;
export const getValidFromError = (state) => state.ui.editor.validFromError;

// draw selectors
export const getLanduseFilter = (state) => state.ui.editor.draw.landUseFilter;
export const getLanduseSuggestions = (state) => getLanduse(state);
export const getLanduseIsFetching = (state) => getIsFetching(state);
export const getFilteredLandUseSuggestions = createSelector(
  getLanduseFilter,
  getLanduseSuggestions,
  (filter, items) => {
    if (filter === null || (typeof filter === "string" && !filter.length)) {
      return items;
    }

    const capitalFilter = filter.toUpperCase();
    return items.filter((i) => i.name.toUpperCase().includes(capitalFilter));
  },
);

// parcel selector

export const getParcelsSuggestions = createSelector(
  getParcelsMapActionable(),
  (items) => items,
);

export const getMergeParcelsSuggestions = createSelector(
  getParcelsMapActionable(),
  (items) => items,
);

export const getLastCurrentSeedDate = createSelector(
  getSelectedParcels,
  (selected) => {
    let lastDate;
    selected.forEach((parcel) => {
      if (parcel.currentSeedDate) {
        const parcelMomentDate = moment(parcel.currentSeedDate);
        if (lastDate) {
          if (parcelMomentDate.isAfter(lastDate)) {
            lastDate = parcelMomentDate;
          }
        } else {
          lastDate = parcelMomentDate;
        }
      }
    });
    return lastDate;
  },
);

// buffer selectors
export const getBufferSize = (state) => state.ui.editor.buffer.size;
export const getBufferType = (state) => state.ui.editor.buffer.type;

// measurement selectors
export const getMeasurements = (state) => state.ui.editor.measurement.items;
