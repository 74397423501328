import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./notificationsAdmin.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getAdminFarms = (params) => ({
  [RSAA]: {
    endpoint: `admin?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.AUTOMATION,
    types: [
      types.GET_ADMIN_FARMS,
      types.GET_ADMIN_FARMS_SUCCESS,
      types.GET_ADMIN_FARMS_ERROR,
    ],
  },
});

export const updateFarmNotifications = (farmId, farmNotificationsSettings) => ({
  [RSAA]: {
    endpoint: `admin/${farmId}?`,
    method: methods.PUT,
    module: modules.AUTOMATION,
    body: JSON.stringify(farmNotificationsSettings),
    types: [
      types.UPDATE_FARM_NOTIFICATIONS,
      types.UPDATE_FARM_NOTIFICATIONS_SUCCESS,
      types.UPDATE_FARM_NOTIFICATIONS_ERROR,
    ],
  },
});

export const createFarmNotifications = (farmNotificationsSettings) => ({
  [RSAA]: {
    endpoint: "admin?",
    method: methods.POST,
    module: modules.AUTOMATION,
    body: JSON.stringify(farmNotificationsSettings),
    types: [
      types.CREATE_FARM_NOTIFICATIONS,
      types.CREATE_FARM_NOTIFICATIONS_SUCCESS,
      types.CREATE_FARM_NOTIFICATIONS_ERROR,
    ],
  },
});

export const resetAdminFarms = () => ({
  type: types.RESET_ADMIN_FARMS,
});
