const MENU_ITEMS = [
  {
    route: "map",
    states: ["map"],
    id: "map",
    translationId: "navbar.map",
  },
  {
    route: "parcels",
    states: ["parcels"],
    id: "parcels",
    translationId: "navbar.parcels",
  },
  {
    route: "precision-farming",
    states: ["precision"],
    id: "precision",
    translationId: "navbar.precision",
  },
  {
    route: "telematics/machines",
    states: ["telematics"],
    id: "telematics",
    translationId: "navbar.telematics",
  },
  {
    route: "sensors",
    states: ["sensors"],
    id: "sensors",
    translationId: "navbar.sensors",
    dropdown: true,
  },
  {
    route: "irrigation/areas",
    states: ["irrigation"],
    id: "irrigation",
    translationId: "navbar.irrigation",
    dropdown: true,
  },
  {
    route: "notifications",
    states: ["notifications"],
    id: "notifications",
    translationId: "navbar.notifications",
    dropdown: true,
  },
  {
    route: "actions",
    states: ["actions", "action"],
    id: "actions",
    translationId: "navbar.activities",
    dropdown: true,
  },
  {
    route: "stores/fertilizers",
    states: ["stores", "store"],
    id: "stores",
    translationId: "navbar.stores",
    dropdown: true,
  },
  {
    route: "reports",
    states: ["reports"],
    id: "reports",
    translationId: "navbar.reports",
    dropdown: true,
  },
  {
    route: "classifiers/private/seeds",
    states: ["classifiers"],
    id: "classifiers",
    translationId: "navbar.catalogues",
    dropdown: true,
  },
];

export default MENU_ITEMS;
