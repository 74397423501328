import React, { useEffect, useContext, useState } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import { TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingSeeds,
  getSeeds,
  getSeedsCount,
} from "../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors";
import {
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
  getSectionListAdvancedFilter,
} from "../../selectors/sectionList.selectors";

import {
  fetchSeeds,
  resetSeeds,
  updateSeed,
} from "../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../reducers/namespace.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { getColDesc } from "../../../shared/misc/helper";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../types";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";

import { COLUMN_NAMES } from "./seeds.columns";
import SeedsAdvancedFilter from "./SeedsAdvancedFilter/SeedsAdvancedFilter";
import SeedsListRow from "./SeedsListRow";

import {
  CataloguesState,
  CATALOGUES_NAMESPACES,
} from "../../../reducers/catalogues.reducer.types";
import { CropSeedPatchTo } from "../../../shared/api/agroevidence/agroevidence.types";

export const columns = {
  [COLUMN_NAMES.IS_FAVORITE]: getColDesc(true, <StarBorderRoundedIcon />, {
    paddingLeft: 16,
    width: 30,
  }),
  [COLUMN_NAMES.CROP]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.seeds.column.crop" />,
    { width: 500 },
  ),
  [COLUMN_NAMES.VARIETY]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.seeds.column.variety" />,
  ),
  [COLUMN_NAMES.SOURCE]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.seeds.column.source" />,
  ),
};
type ReduxProps = ConnectedProps<typeof connector>;

const SeedsList = ({
  advancedFilter,
  count,
  fetchSeeds,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  resetSeeds,
  rowsPerPage,
  search,
  seeds,
  setNamespace,
  updateSeed,
}: ReduxProps) => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    resetSeeds();
    setNamespace(CATALOGUES_NAMESPACES.SEEDS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validOn = getShortDateString();
    fetchSeeds(validOn);
  }, [
    page,
    order,
    orderBy,
    rowsPerPage,
    search,
    advancedFilter,
    fetchSeeds,
    langId,
    shouldReloadData,
  ]);

  if (namespace !== CATALOGUES_NAMESPACES.SEEDS) return null;

  const onUpdateIsFavorite = (seedId: string, isFavorite: boolean) => {
    (updateSeed as unknown as AsyncFn<string, CropSeedPatchTo>)(seedId, {
      isFavorite: !isFavorite,
    }).then(() => setShouldReloadData((prevState) => !prevState));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="seeds-list-text-filter"
              namespace={namespace}
              translId="Catalogues.table.seeds.search"
            />
          </div>
          <div className={classes.advancedFilter}>
            <SeedsAdvancedFilter namespace={namespace} />
          </div>
        </div>
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {seeds.length && !isFetching ? (
          <TableBody>
            {seeds.map((seed) => (
              <SeedsListRow
                data={seed}
                key={seed.id}
                onUpdateIsFavorite={onUpdateIsFavorite}
              />
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  seeds: getSeeds(state),
  isFetching: getIsFetchingSeeds(state),
  count: getSeedsCount(state),
  namespace: getSelectedNamespace(state),
  search: getSectionListSearch(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
  advancedFilter: getSectionListAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchSeeds,
      resetSeeds,
      updateSeed,
      setNamespace: setSelectedNamespace,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SeedsList);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
