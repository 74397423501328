import React, { Component } from "react";

import Popover from "@mui/material/Popover";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFarmsSuggestions } from "../../../shared/selectors/manager.selectors";
import {
  getSensorsAdminDefaultFarm,
  getSensorsAdminIsFetchingFarms,
} from "../../selectors/sensors.selectors";

import {
  getFarmsByName,
  resetFarms,
} from "../../../shared/actions/manager.actions";

import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import AdminFarmSelector from "../../../../shared/components/AdminFarmSelector/AdminFarmSelector";

const styles = (theme) => ({
  paper: {
    width: 350,
    height: 350,
    overflow: "hidden",
  },
  switcherLabel: {
    lineHeight: "1.2",
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
  icon: {},
  button: {},
});

export class SensorAdminAssignFarm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mergedSuggestions: [],
    };
  }

  onSuggestionSelect = (farmToAssign) => {
    this.props.onAssignNodesToFarm(farmToAssign);
    this.props.handlePopoverClose();
  };

  onSuggestionClear = () => {
    this.props.handlePopoverClose();
    this.props.resetFarms();
    this.setState({ mergedSuggestions: [] });
  };

  getFarmsAndUpdatePopover = (filter, params) => {
    this.props.getFarmsByName(filter, params).then(() => {
      const { defaultFarm } = this.props;
      const { formatMessage } = this.props.intl;

      this.setState({
        mergedSuggestions: [
          {
            ...defaultFarm,
            countryCode: "CZ",
            name: formatMessage({ id: "common.no-farm" }),
          },
          ...this.props.suggestions,
        ],
      });
    });
  };

  hasFarmAssigned = (item, defaultFarm) =>
    item &&
    !isEmpty(defaultFarm) &&
    item.farm.name &&
    item.farm.code &&
    item.farm.id.toString() !== defaultFarm.id.toString();

  render() {
    const { mergedSuggestions } = this.state;
    const {
      anchorEl,
      classes,
      defaultFarm,
      disabled,
      fontSize,
      handlePopoverOpen,
      isFetchingFarms,
      isOpen,
      item,
      noFarmLabel,
      selectedLength,
    } = this.props;
    const isAssigned = this.hasFarmAssigned(item, defaultFarm);
    const iconClasses = isAssigned ? null : classes.icon;
    return (
      <div>
        <CfSwitcherButton
          data-test="sensor-assign-farm"
          disabled={disabled}
          onClick={handlePopoverOpen}
          transparent={true}
          classes={{
            icon: iconClasses,
            custom: `${classes.button} ${
              !(disabled || isAssigned) && classes.activeButton
            }`,
          }}
        >
          <span
            className={classes.switcherLabel}
            style={{
              fontSize,
              textTransform: fontSize === 16 ? "uppercase" : "",
            }}
          >
            {isAssigned ? item.farm.name : noFarmLabel}
            {selectedLength > 0 && `\u00A0(${selectedLength})`}
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          onClose={this.onSuggestionClear}
          open={isOpen}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: 350,
            vertical: 0,
          }}
        >
          <AdminFarmSelector
            getFarmsByName={this.getFarmsAndUpdatePopover}
            isFetching={isFetchingFarms}
            onSuggestionClear={this.onSuggestionClear}
            onSuggestionSelect={this.onSuggestionSelect}
            suggestions={mergedSuggestions}
          />
        </Popover>
      </div>
    );
  }
}

SensorAdminAssignFarm.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  disabled: PropTypes.bool,
  isFetchingFarms: PropTypes.bool.isRequired,
  defaultFarm: PropTypes.object,
  suggestions: PropTypes.array,
  onAssignNodesToFarm: PropTypes.func.isRequired,
  getFarmsByName: PropTypes.func.isRequired,
  resetFarms: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  selectedLength: PropTypes.number,
  noFarmLabel: PropTypes.node,
  fontSize: PropTypes.number,
};

SensorAdminAssignFarm.defaultProps = {
  item: null,
  disabled: false,
  defaultFarm: {},
  suggestions: [],
  anchorEl: null,
  selectedLength: 0,
  noFarmLabel: <FormattedMessage id="common.no-farm" />,
  fontSize: 13,
};

const mapStateToProps = (state) => ({
  isFetchingFarms: getSensorsAdminIsFetchingFarms(state),
  defaultFarm: getSensorsAdminDefaultFarm(state),
  suggestions: getFarmsSuggestions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFarmsByName,
      resetFarms,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
  withPopover,
)(SensorAdminAssignFarm);
