/**
 * Created by ondrejzvara on 27.3.17.
 */

import angular from "angular";

import localization from "./localization/localization.module";
import validation from "./validation/validation.module";
import logger from "./logger/logger.module";
import auth from "./auth/auth.module";
import storage from "./storage/storage.module";
import api from "./rest/api.module";
import spinner from "./spinner/spinner.module";
import modal from "./modal/modal.module";
import misc from "./misc/misc.module";
import classifiers from "./classifiers/classifiers.module";
import entities from "./entities/entities.module";
import tabs from "./tabs/tabs.module";
import table from "./table/table.module";
import test from "./test/test.module";

import Unit from "./Unit.service";

export default angular
  .module("app.common.services", [
    localization,
    validation,
    logger,
    storage,
    auth,
    api,
    spinner,
    modal,
    misc,
    classifiers,
    entities,
    tabs,
    table,
    test,
  ])
  .factory("Unit", Unit).name;
