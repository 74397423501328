import React, { Fragment, Component } from "react";

import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getRule,
  getIsFetchingRule,
  getErrorRule,
} from "../../../shared/api/automation/rules/rules.selectors";
import {
  getLocation,
  getIsFetchingLocation,
  getErrorLocation,
} from "../../../shared/api/iot/locations/locations.selectors";

import {
  fetchRule,
  resetRule,
  createRule,
  updateRule,
  fetchDeviceTypes,
  fetchLocation,
} from "../../actions/notificationList.actions";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import withEditing from "../../../shared/hocs/withEditing";
import NotificationDetailFormWrapper from "../../components/NotificationDetailFormWrapper/NotificationDetailFormWrapper";

const styles = {
  container: {
    margin: 15,
    paddingBottom: 30,
  },
  header: {
    margin: 0,
    overflowX: "hidden",
    width: "100%",
  },
  editButtonItem: {
    textAlign: "right",
  },
};

export class NotificationDetail extends Component {
  componentDidMount() {
    const { isExisting, match } = this.props;
    if (isExisting) {
      this.props.fetchRule(match.params.notificationId);
    } else {
      this.props.onEditingStart();
    }
    this.props.fetchDeviceTypes();
  }

  componentDidUpdate(prevProps) {
    const { rule } = this.props;
    if (rule && !prevProps.rule) {
      if (rule.sensors.length > 0) {
        this.props.fetchLocation(rule.sensors[0].lastLocationId);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetRule();
  }

  goToNotifications = () => {
    const { farmId, history } = this.props;
    history.push(`/farm/${farmId}/notifications`);
  };

  render() {
    const {
      classes,
      errorRule,
      farmId,
      isEditing,
      isExisting,
      isFetchingNodeLocation,
      isFetchingRule,
      nodeLocation,
      onEditingEnd,
      onEditingStart,
      rule,
    } = this.props;
    return (
      <CfErrorPage error={errorRule}>
        {isFetchingRule ||
        isFetchingNodeLocation ||
        (isExisting && (!rule || !nodeLocation.id)) ? (
          <CfLoader />
        ) : (
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  alignItems="center"
                  className={classes.header}
                  container
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <CfBackButton
                      onClick={this.goToNotifications}
                      translId="NotificationDetail.backToNotifications"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <PageHeading
                      value={<FormattedMessage id="common.notification" />}
                    />
                  </Grid>
                  <Grid className={classes.editButtonItem} item xs={2}>
                    {isExisting && (
                      <Fragment>
                        <IconButton
                          data-test="notification-edit"
                          disabled={isEditing}
                          onClick={onEditingStart}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </Fragment>
                    )}
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item lg={8} md={10} xs={12}>
                    <NotificationDetailFormWrapper
                      createRule={this.props.createRule}
                      farmId={farmId}
                      goToNotifications={this.goToNotifications}
                      isEditing={isEditing}
                      isExisting={isExisting}
                      nodeLocation={nodeLocation}
                      onEditingEnd={onEditingEnd}
                      rule={rule}
                      updateRule={this.props.updateRule}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </CfErrorPage>
    );
  }
}

NotificationDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isExisting: PropTypes.bool,
  onEditingStart: PropTypes.func.isRequired,
  onEditingEnd: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetchRule: PropTypes.func.isRequired,
  resetRule: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  fetchDeviceTypes: PropTypes.func.isRequired,
  rule: PropTypes.object,
  isFetchingRule: PropTypes.bool.isRequired,
  errorRule: PropTypes.object,
  fetchLocation: PropTypes.func.isRequired,
  nodeLocation: PropTypes.object.isRequired,
  isFetchingNodeLocation: PropTypes.bool.isRequired,
};

NotificationDetail.defaultProps = {
  isExisting: false,
  rule: null,
  errorRule: null,
};

const mapStateToProps = (state) => ({
  rule: getRule(state),
  isFetchingRule: getIsFetchingRule(state),
  errorRule: getErrorRule(state),
  nodeLocation: getLocation(state),
  isFetchingNodeLocation: getIsFetchingLocation(state),
  errorNodeLocation: getErrorLocation(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRule,
      resetRule,
      createRule,
      updateRule,
      fetchDeviceTypes,
      fetchLocation,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEditing,
  withStyles(styles),
)(NotificationDetail);
