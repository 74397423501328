import { getMapDragging } from "../../selectors/editor.selectors";
import { getMainMap, getLayers } from "../../selectors/map.selectors";

import { setAllCropsVisibility } from "../crops/crops.actions";
import {
  editorSetSelected,
  handleResponse,
  editorSetMapDragging,
} from "../editor/editor.actions";
import {
  setHoverStyleEL,
  setMouseClickEL,
  setMouseMoveEL,
  removeEL,
} from "../eventListener/eventListener.actions";
import {
  setDoubleClickZoomIA,
  removeDoubleClickZoomIA,
} from "../interaction/interaction.actions";
import {
  refreshDefaultStyles,
  refreshSowingPlanStyles,
} from "../style/style.actions";

import * as types from "./map.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";

import { getActionsApi } from "../../../../shared/api/agroevidence/actions/actions.api";
import { getFarmApi } from "../../../../shared/api/agroevidence/farms/farms.api";
import {
  getParcelApi,
  resetParcelApi,
  getParcelsApi,
  resetParcelsApi,
  updateParcelApi,
} from "../../../../shared/api/agroevidence/parcels/parcels.api";
import { getParcelSowingPlanApi } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import {
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../parcels/shared/services/Parcels.service";
import Feature from "../../services/Feature.service";

export const initMapSaga = (
  farmId,
  langId,
  countryCode,
  api,
  environment,
  parcelId,
) => ({
  type: types.INIT_MAP_SAGA,
  farmId,
  langId,
  countryCode,
  api,
  environment,
  parcelId,
});

export const setMapInitSuccess = {
  type: types.MAP_INIT_SUCCESS,
};

export const storeServiceWrapper = (key, value) => ({
  type: types.STORE_SERVICE_WRAPPER,
  key,
  value,
});

export const setMapContext = (context) => (dispatch) => {
  dispatch({
    type: types.SET_MAP_CONTEXT,
    context,
  });
};

export const resetMap = () => (dispatch) => {
  dispatch({
    type: types.RESET_MAP,
  });
};

export const zoomToFarm = () => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToFarm();
};

export const zoomToGeometry = (geometry, scaleFactor) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToGeometry(geometry, scaleFactor);
};

export const zoomToExtent = (extent, scaleFactor) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToExtent(extent, scaleFactor);
};

export const zoomToCoords = (coords, zoom) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.zoomToCoords(coords, zoom);
};

export const setMapFetching = (isFetching) => (dispatch) => {
  dispatch({
    type: types.SET_MAP_FETCHING,
    isFetching,
  });
};

export const setDefaultSelectEL = () => (dispatch) => {
  dispatch(
    setMouseClickEL(onMouseClick, refreshDefaultStyles, "defaultSelectELKey"),
  );
};

export const setDefaultHoverEL = () => (dispatch) => {
  dispatch(
    setHoverStyleEL(
      (f) => Feature.isFeature(f),
      refreshDefaultStyles,
      "defaultHoverELKey",
    ),
  );
};

export const setSowingPlanSelectEL = () => (dispatch) => {
  dispatch(
    setMouseClickEL(
      onMouseClick,
      refreshSowingPlanStyles,
      "sowingPlanSelectELKey",
    ),
  );
};

export const setSowingPlanHoverEL = () => (dispatch) => {
  dispatch(
    setHoverStyleEL(
      (f) => Feature.isFeature(f),
      refreshSowingPlanStyles,
      "sowingPlanHoverELKey",
    ),
  );
};

export const setMapGrabEL = () => (dispatch) => {
  dispatch(
    setMouseMoveEL((f, evt) => onMapDrag(evt), undefined, "mapDragELKey"),
  );
};

const onMouseClick = (feature) => (dispatch) => {
  if (!feature) {
    dispatch(editorSetSelected([]));
    dispatch(clearParcelDetail());
  } else {
    const featureId = feature.get("id");
    dispatch(editorSetSelected({ id: featureId }));
    dispatch(fetchParcelDetail(featureId));
  }
};

const onMapDrag = (evt) => (dispatch, getState) => {
  const isMapDragging = getMapDragging(getState());
  if (!evt.dragging && isMapDragging) {
    dispatch(mapSetCursor(""));
    dispatch(editorSetMapDragging(false));
  } else if (evt.dragging && !isMapDragging) {
    dispatch(editorSetMapDragging(true));
    dispatch(mapSetCursor("grabbing"));
  }
};

export const activateParcelDetail = (parcelId) => (dispatch) => {
  dispatch(editorSetSelected({ id: parcelId }));
  dispatch(fetchParcelDetail(parcelId));
  dispatch(getParcelApi(parcelId)).then(({ payload }) => {
    dispatch(zoomToGeometry(payload.geometry, 0.5));
  });
};

export const fetchParcelDetail = (featureId) => (dispatch) =>
  Promise.all([
    dispatch(getParcelApi(featureId)),
    dispatch(fetchParcelLastAction(featureId)),
    dispatch(
      getParcelSowingPlanApi(
        featureId,
        START_YEAR_FIRST_SOWING_SEASONS,
        SHOWN_NUMBER_SOWING_SEASONS,
      ),
    ),
  ]);

export const fetchParcelLastAction = (parcelId) => (dispatch) =>
  dispatch(
    getActionsApi({
      page: 1,
      "per-page": 1,
      "sort-dir": sortOrder.DESC,
      "sort-col": "date",
      parcels: [parcelId],
    }),
  );

export const clearParcelDetail = () => (dispatch) => {
  dispatch(resetParcelApi());
  // necessary when closing parcel detail by icon
  dispatch(editorSetSelected([]));
  dispatch(refreshDefaultStyles());
};

export const updateParcelName =
  (parcelId, parcelName) => (dispatch, getState) =>
    dispatch(updateParcelApi(parcelId, { name: parcelName })).then(() => {
      dispatch(getParcelApi(parcelId));
      const layersService = getLayers(getState());
      layersService.refreshParcelLabels();
    });

export const fetchBoundingBox = (farmId) => (dispatch) =>
  dispatch(getFarmApi(farmId))
    .then(handleResponse)
    .then((farm) => farm.boundingBox);

export const mapSetCursor = (value) => (_, getState) => {
  const mainMap = getMainMap(getState());
  mainMap.setCursor(value);
};

export const fetchMapSearchSuggestions = (searchInput) => (dispatch) => {
  dispatch(getParcelsApi({ search: searchInput }));
};

export const clearMapSearchSuggestions = () => (dispatch) => {
  dispatch(resetParcelsApi());
};

export const enablePreview = () => (dispatch) => {
  dispatch(setAllCropsVisibility(true));
  dispatch(setDoubleClickZoomIA());
  dispatch(setDefaultSelectEL());
  dispatch(setDefaultHoverEL());
};

export const enableSeasonSowingPlan = () => (dispatch) => {
  dispatch(setDoubleClickZoomIA());
  dispatch(setSowingPlanSelectEL());
  dispatch(setSowingPlanHoverEL());
};

export const disablePreview = () => (dispatch) => {
  dispatch(clearParcelDetail());
  dispatch(removeDoubleClickZoomIA());
  dispatch(removeEL("defaultHoverELKey"));
  dispatch(removeEL("defaultSelectELKey"));
  dispatch(removeEL("sowingPlanHoverELKey"));
  dispatch(removeEL("sowingPlanSelectELKey"));
  dispatch(setAllCropsVisibility(false));
};
