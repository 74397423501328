import React from "react";

import PropTypes from "prop-types";

import FabWithOptions from "../../../../../../shared/components/common/FabWithOptions/FabWithOptions";

const ParcelDetailFabButton = (props) => {
  const {
    isHistorical,
    ngRedirectToEph,
    ngRedirectToHarvest,
    ngRedirectToMowing,
    ngRedirectToOtherActionsNew,
    ngRedirectToSowing,
    ngRedirectToVrf,
    ngRedirectToVrs,
    parcelId,
  } = props;

  const fabOptions = [
    {
      id: "sowing",
      translId: "common.addOtherSowingActivity",
      action: () => ngRedirectToSowing([parcelId]),
    },
    {
      id: "eph",
      translId: "common.addEph",
      action: () => ngRedirectToEph([parcelId]),
    },
    {
      id: "other",
      translId: "common.addOtherActivity",
      action: () => ngRedirectToOtherActionsNew([parcelId]),
    },
  ];

  const fabOptsMowing = [
    {
      id: "mowing",
      translId: "common.addOtherMowingActivity",
      action: () => ngRedirectToMowing([parcelId]),
    },
  ];

  const fabOptsHarvest = [
    {
      id: "harvest",
      translId: "common.addHarvestActivity",
      action: () => ngRedirectToHarvest([parcelId]),
      divider: true,
    },
  ];

  const fabOptsVrf = [
    {
      id: "add-var-vert-link",
      translId: "common.addVrfActivity",
      action: () => ngRedirectToVrf([parcelId]),
    },
  ];

  const fabOptsVrs = [
    {
      id: "add-var-seed-link",
      translId: "common.addVrsActivity",
      action: () => ngRedirectToVrs([parcelId]),
    },
  ];

  return (
    <FabWithOptions
      disabled={isHistorical}
      options={[
        ...fabOptions,
        ...fabOptsMowing.map((opt) => ({
          ...opt,
        })),
        ...fabOptsHarvest.map((opt) => ({
          ...opt,
        })),
        ...fabOptsVrf.map((opt) => ({
          ...opt,
          disabled: !props.isVraAllowed,
        })),
        ...fabOptsVrs.map((opt) => ({
          ...opt,
          disabled: !props.isVraAllowed,
        })),
      ]}
    />
  );
};

ParcelDetailFabButton.propTypes = {
  parcelId: PropTypes.string.isRequired,
  ngRedirectToEph: PropTypes.func.isRequired,
  ngRedirectToSowing: PropTypes.func.isRequired,
  ngRedirectToOtherActionsNew: PropTypes.func.isRequired,
  ngRedirectToHarvest: PropTypes.func.isRequired,
  ngRedirectToVrf: PropTypes.func.isRequired,
  ngRedirectToVrs: PropTypes.func.isRequired,
  ngRedirectToMowing: PropTypes.func.isRequired,
  isVraAllowed: PropTypes.bool.isRequired,
  isHistorical: PropTypes.bool,
};

ParcelDetailFabButton.defaultProps = {
  isHistorical: false,
};

export default ParcelDetailFabButton;
