import React from "react";

import {
  SelectChangeEvent,
  MenuItem,
  Select as MUISelect,
  styled,
} from "@mui/material";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "redux";

import {
  selectAvailableAttributes,
  selectCurrentAttribute,
} from "../../../selectors/asAppliedDetail.selectors";

import {
  Action,
  attributeSelected,
  pointReset,
} from "../../../reducer/precisionAsAppliedDetail.reducer";

import { PrecisionState } from "../../../../../reducers/precision.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;

const _AttributeControl = ({
  attributes,
  currentAttribute,
  resetHighlight,
  selectAttribute,
}: ReduxProps) => {
  const handleChange = ({ target: { value } }: SelectChangeEvent<string>) => {
    resetHighlight();
    selectAttribute(value);
  };

  return currentAttribute ? (
    <Wrapper>
      <Select
        disableUnderline
        onChange={handleChange}
        value={currentAttribute.attribute}
      >
        {attributes?.map((attribute) => (
          <MenuItem key={attribute.attribute} value={attribute.attribute}>
            {attribute.name}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  ) : null;
};

const mapStateToProps = (state: PrecisionState) => ({
  attributes: selectAvailableAttributes(state),
  currentAttribute: selectCurrentAttribute(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetHighlight: () => dispatch(pointReset()),
  selectAttribute: (attribute: string) =>
    dispatch(attributeSelected(attribute)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const AttributeControl = connector(_AttributeControl);

const Wrapper = styled("div")(() => ({
  background: "white",
  borderRadius: "28px",
  padding: "0px 4px 0px 12px",
}));

const Select = styled(MUISelect)(() => ({
  fontSize: "14px",
}));

export { AttributeControl };
