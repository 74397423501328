import React, { FC, ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getIsFetchingPrecisionParcels,
  getIsInitPrecisionPage,
  getTotalCount,
} from "../../../shared/api/sentinel/precision/precision.selectors";

import { setAsAppliedShowVarioDocInactive } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions";
import {
  fetchPrecisionAsApplied,
  setPrecisionPageIsInit,
} from "../actions/precision.actions";

import { PRECISION_URLS } from "../precision.constants";

import {
  ImportFilesApiType,
  ValidateFileApiType,
  importFilesApi,
  validateFileApi,
} from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import CfPrimaryTab from "../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import FabWithOptions from "../../../shared/components/common/FabWithOptions/FabWithOptions";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { useToggle } from "../../../shared/hooks/useToggle";
import { Thunk } from "../../../types";
import { useIsPrecisionFarmingActive } from "../hooks/useIsPrecisionFarmingActive";

import ImportMachinesDialog from "./ImportMachinesDialog";
import { usePrecisionFarmingStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

export enum PRECISION_LISTING_TABS {
  AS_APPLIED = "as_applied",
  SERVICES = "services",
}

const getSelectedTabIndex = (tab: PRECISION_LISTING_TABS): number => {
  if (tab === PRECISION_LISTING_TABS.SERVICES) {
    return 0;
  } else if (tab === PRECISION_LISTING_TABS.AS_APPLIED) {
    return 1;
  }
  return 0;
};
interface Props extends RouteComponentProps<{ farmId: string }> {
  children: ReactNode;
  count: number;
  fetchPrecisionAsApplied: () => void;
  importFilesApi: (params: ImportFilesApiType) => void;
  isFetching: boolean;
  isInit: boolean;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  setAsAppliedShowVarioDocInactive: (val: boolean) => void;
  setPrecisionPageIsInit: (payload: boolean) => void;
  tab: PRECISION_LISTING_TABS;
  validateFileApi: (params: ValidateFileApiType) => void;
}

const PrecisionTabs: FC<Props> = ({
  children,
  count,
  fetchPrecisionAsApplied,
  history,
  importFilesApi,
  isFetching,
  isInit,
  match,
  ngRedirectToVrf,
  ngRedirectToVrs,
  setAsAppliedShowVarioDocInactive,
  setPrecisionPageIsInit,
  tab,
  validateFileApi,
}) => {
  const classes = usePrecisionFarmingStyles();
  const selectedTabIndex = getSelectedTabIndex(tab);
  const hasPrecision =
    useIsPrecisionFarmingActive({ isPageInitCall: true, selectedTabIndex }) ??
    false;

  const { farmId } = match.params;

  const {
    on: machinesImportIsOpen,
    setOff: handleMachinesImportClose,
    setOn: handleMachinesImportOpen,
  } = useToggle();

  useEffect(() => {
    if (!isInit && !isFetching && count > 0) {
      setAsAppliedShowVarioDocInactive(false);
      setPrecisionPageIsInit(true);
    }
  }, [
    isFetching,
    count,
    setPrecisionPageIsInit,
    isInit,
    setAsAppliedShowVarioDocInactive,
  ]);

  const handleServicesTabClick = () =>
    history.push(`/farm/${farmId}/${PRECISION_URLS.services()}`);
  const handleAsAppliedTabClick = () =>
    history.push(`/farm/${farmId}/${PRECISION_URLS.asApplied()}`);

  return (
    <div>
      <div className={classes.container}>
        <PageHeader
          actionButtons={
            <div className={classes.fabContainer}>
              <FabWithOptions
                options={[
                  {
                    translId: "common.addVrfActivity",
                    action: () => ngRedirectToVrf([]),
                    disabled: !hasPrecision,
                  },
                  {
                    translId: "common.addVrsActivity",
                    action: () => ngRedirectToVrs([]),
                    divider: true,
                    disabled: !hasPrecision,
                  },
                  {
                    translId: "PrecisionFarming.add.importRides",
                    action: handleMachinesImportOpen,
                  },
                ]}
              />
            </div>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="precision-heading"
              value={<FormattedMessage id="common.precision" />}
            />
          }
        />
        <div className={classes.tabsContainer}>
          <CfPrimaryTabs centered tabValue={selectedTabIndex}>
            <CfPrimaryTab
              data-test="precision-services"
              onClick={handleServicesTabClick}
              label={
                <div>
                  <FormattedMessage id="PrecisionFarming.tabs.services" />
                </div>
              }
            />
            <CfPrimaryTab
              data-test="precision-as-applied"
              onClick={handleAsAppliedTabClick}
              label={
                <div>
                  <FormattedMessage id="PrecisionFarming.tabs.machineData" />
                </div>
              }
            />
          </CfPrimaryTabs>
        </div>
      </div>
      {machinesImportIsOpen && (
        <ImportMachinesDialog
          handleClose={handleMachinesImportClose}
          importFilesApi={importFilesApi}
          open={machinesImportIsOpen}
          validateFileApi={validateFileApi}
          onSuccess={() => {
            if (tab === PRECISION_LISTING_TABS.AS_APPLIED) {
              fetchPrecisionAsApplied();
            } else {
              handleAsAppliedTabClick();
            }
          }}
        />
      )}
      {children}
    </div>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  isFetching: getIsFetchingPrecisionParcels(state),
  count: getTotalCount(state),
  isInit: getIsInitPrecisionPage(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      importFilesApi,
      validateFileApi,
      fetchPrecisionAsApplied,
      setPrecisionPageIsInit,
      setAsAppliedShowVarioDocInactive,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionTabs);
