/**
 * Created by ondrejzvara on 6.4.16. pluralization files at https://github.com/SlexAxton/messageformat.js/tree/0.2.2/locale
 */

import "../../../assets/locale/locale-cs-CZ.json";
import "../../../assets/locale/locale-en-US.json";
import "../../../assets/locale/locale-sr-LATN-RS.json";
import "../../../assets/locale/locale-es-ES.json";
import "../../../assets/locale/locale-ru-UA.json";
import "../../../assets/locale/locale-el-GR.json";
import "../../../assets/locale/locale-ro-RO.json";
import "../../../assets/locale/locale-hu-HU.json";
import "../../../assets/locale/angular/angular-locale_cs-CZ.js";
import "../../../assets/locale/angular/angular-locale_en-US.js";
import "../../../assets/locale/angular/angular-locale_sr-LATN-RS.js";
import "../../../assets/locale/angular/angular-locale_es-ES.js";
import "../../../assets/locale/angular/angular-locale_ru-UA";
import "../../../assets/locale/angular/angular-locale_el-GR";
import "../../../assets/locale/angular/angular-locale_ro-RO.js";
import "../../../assets/locale/angular/angular-locale_hu-HU.js";

import angular from "angular";
import Localization from "./Localization.service";
import { isFarmaSenseDomain } from "../../../shared/variants/getAppVariant";

export default angular
  .module("app.localization", ["app.common"])
  .constant("LOCALES", {
    langs: {
      "cs-CZ": {
        NAME: "Česky",
        ISO_CODE: "cs",
      },
      "en-US": {
        NAME: "English",
        ISO_CODE: "en",
      },
      "sr-LATN-RS": {
        NAME: "Srpski",
        ISO_CODE: "sr",
      },
      "es-ES": {
        NAME: "Español",
        ISO_CODE: "es",
      },
      "ru-UA": {
        NAME: "Pусский",
        ISO_CODE: "ru",
      },
      "el-GR": {
        NAME: "Ελληνικά",
        ISO_CODE: "gr",
      },
      "ro-RO": {
        NAME: "Romanesc",
        ISO_CODE: "ro",
      },
      "hu-HU": {
        NAME: "Magyar",
        ISO_CODE: "hu",
      },
    },
    fallbackLocale: "en-US",
  })
  .config(config)
  .run(run)
  .factory("Localization", Localization).name;

/* @ngInject */
function config($translateProvider, LOCALES, tmhDynamicLocaleProvider) {
  let preferred = matchLocaleFromBrowser(LOCALES);

  //Farmasense app customization
  if (isFarmaSenseDomain) {
    preferred = "el-GR";
  }

  tmhDynamicLocaleProvider.useCookieStorage();
  tmhDynamicLocaleProvider.localeLocationPattern(
    "assets/locale/angular-locale_{{locale}}.js".toLowerCase(),
  );
  tmhDynamicLocaleProvider.defaultLocale(preferred);

  $translateProvider
    .useStaticFilesLoader({
      files: [
        {
          prefix: "assets/locale/locale-",
          suffix: ".json",
        },
      ],
    })
    .registerAvailableLanguageKeys(Object.keys(LOCALES.langs), {
      "cs*": Object.keys(LOCALES.langs)[0],
      "en*": Object.keys(LOCALES.langs)[1],
      "sr*": Object.keys(LOCALES.langs)[2],
      "es*": Object.keys(LOCALES.langs)[3],
      "ru*": Object.keys(LOCALES.langs)[4],
      "el*": Object.keys(LOCALES.langs)[5],
      "ro*": Object.keys(LOCALES.langs)[6],
      "hu*": Object.keys(LOCALES.langs)[7],
    })
    .addInterpolation("$translateMessageFormatInterpolation")
    .preferredLanguage(preferred)
    .useSanitizeValueStrategy("escape")
    .useMissingTranslationHandlerLog()
    .useCookieStorage();
}

function matchLocaleFromBrowser(LOCALES) {
  let resultLocale;
  const locales = _.keys(LOCALES.langs);
  let browserLocale = getBrowserLocale();

  if (!_.isEmpty(browserLocale)) {
    resultLocale = _.find(locales, (locale) => {
      // if retrieved locale is identical with storage;
      if (locale === browserLocale) {
        return true;
      }

      browserLocale = _.replace(browserLocale, "_", "-");
      if (locale === browserLocale) {
        return true;
      }

      // try to match the language part
      const lang = _.split(locale, "-")[0];
      const browserLang = _.split(browserLocale, "-")[0];
      return lang === browserLang;
    });
  }

  if (!resultLocale) {
    resultLocale = LOCALES.fallbackLocale;
    if (browserLocale === "sk") {
      resultLocale = locales[0];
    }
  }

  return resultLocale;
}

function getBrowserLocale() {
  let browserLocale, androidLocale;
  // works for earlier version of Android (2.3.x)
  if (
    navigator &&
    navigator.userAgent &&
    (androidLocale = navigator.userAgent.match(/android.*\W(\w\w)-(\w\w)\W/i))
  ) {
    browserLocale = androidLocale[1];
  } else {
    // works for iOS and Android 4.x
    browserLocale = navigator.userLanguage || navigator.language;
  }

  return browserLocale;
}

/* @ngInject */
function run($rootScope, Localization, LOCALES) {
  $rootScope.$on("$translateChangeError", () => {
    Localization.setCurrLangId(Object.keys(LOCALES.langs)[0]);
  });

  $rootScope.$on("$translateChangeSuccess", () => {
    const lang = LOCALES.langs[Localization.getCurrentLangId()];
    if (lang.ISO_CODE) {
      $("html").attr("lang", lang.ISO_CODE);
    }
  });
}
