import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Draw, Select } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import crosshair from "../../assets/img/crosshair.png";
import sensorPin from "../../assets/img/sensor_pin.svg";

export default class SelectMapPositionService {
  constructor(map, drawCallback, hoverMapCallback) {
    this.map = map;
    this.selectionLayer = this.setSelectionLayer();
    this.setDrawInteraction(drawCallback);
    this.setHoverMapCallback(hoverMapCallback);
    this.isSelectionMode = false;
  }

  getSelectionLayer() {
    return this.selectionLayer;
  }

  selectionModeInactive() {
    this.showInfoControl(false);
    this.displaySelectionCursor(false);
  }

  setSelectionMode(isSelection) {
    if (this.isSelectionMode === isSelection) {
      return;
    }

    this.map.getTargetElement().style.cursor = isSelection ? "none" : "auto";
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Select) {
        interaction.setActive(!isSelection);
      }
    });

    if (!isSelection) {
      const drawSource = this.selectionLayer.getSource();
      drawSource.clear(true);
    }

    this.showInfoControl(isSelection);
    this.isSelectionMode = isSelection;
  }

  setSelection(coords) {
    const selectionGeometry = new Point(coords);
    const selectionFeature = new Feature({
      geometry: selectionGeometry,
    });
    selectionFeature.setId(-1);

    const drawSource = this.selectionLayer.getSource();
    drawSource.clear(true);
    drawSource.addFeature(selectionFeature);
  }

  clearSelection() {
    this.map.getTargetElement().style.cursor = "auto";
    const drawSource = this.selectionLayer.getSource();
    drawSource.clear(true);
  }

  displaySelectionCursor(shouldDisplay) {
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Draw) {
        interaction.setActive(shouldDisplay);
      }
    });
  }

  showInfoControl(show) {
    const element = document.getElementById("infoBox");
    element.style.visibility = show ? "visible" : "hidden";
  }

  setDrawInteraction(drawCallback) {
    const drawSource = this.selectionLayer.getSource();
    const draw = new Draw({
      source: drawSource,
      type: "Point",
      style: this.setSelectionCursorStyle(),
    });

    draw.on("drawstart", () => {
      drawSource.clear();
    });

    draw.on("drawend", (e) => {
      drawCallback(e.feature);
    });

    // drawing mode is disabled by default
    draw.setActive(false);

    this.map.addInteraction(draw);
  }

  setHoverMapCallback(hoverMapCallback) {
    this.map.on("pointermove", hoverMapCallback);
  }

  setClickMapCallback(clickMapCallback) {
    this.map.on("click", clickMapCallback);
  }

  setSelectionLayer() {
    const source = new VectorSource();
    return new VectorLayer({
      source,
      style: this.setSelectedStyle(),
    });
  }

  setSelectedStyle() {
    return new Style({
      image: new Icon({
        src: sensorPin,
        anchor: [0.5, 1],
        size: [25, 32],
      }),
    });
  }

  setSelectionCursorStyle() {
    return new Style({
      image: new Icon({
        src: crosshair,
        size: [32, 32],
      }),
    });
  }
}
