import { createColumns } from "../../../helpers/tableHelpers";

export enum COLUMN_NAMES {
  GPS_UNIT = "gpsUnit",
  GROUP = "group",
  LICENCE_PLATE = "licencePlate",
  NAME = "name",
  PRODUCTION_OPERATION = "operation",
  VALID_FROM = "validFrom",
  VALID_TO = "validTo",
  WORKING_WIDTH = "workingWidth",
}

export const columns = createColumns([
  {
    id: COLUMN_NAMES.GPS_UNIT,
    primaryLabel: "Catalogues.table.machines.column.gpsUnit",
    sortable: true,
    style: { paddingLeft: 16 },
  },
  {
    id: COLUMN_NAMES.NAME,
    primaryLabel: "Catalogues.table.machines.column.name",
    secondaryLabel: "Catalogues.table.machines.column.category",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.LICENCE_PLATE,
    primaryLabel: "Catalogues.table.machines.column.licencePlate",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.GROUP,
    primaryLabel: "Catalogues.table.machines.column.group",
  },
  {
    id: COLUMN_NAMES.WORKING_WIDTH,
    primaryLabel: "Catalogues.table.machines.column.workingWidth",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.PRODUCTION_OPERATION,
    primaryLabel: "Catalogues.table.machines.column.productionOperation",
    style: { width: "300px" },
  },
  {
    id: COLUMN_NAMES.VALID_FROM,
    primaryLabel: "Catalogues.table.machines.column.validFrom",
    sortable: true,
  },
  {
    id: COLUMN_NAMES.VALID_TO,
    primaryLabel: "Catalogues.table.machines.column.validTo",
    sortable: true,
  },
]);
