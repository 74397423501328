import React, { FC, useContext, useMemo } from "react";

import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getPrecisionAsAppliedSelected } from "../selectors/precisionAsApplied.selectors";

import {
  fetchPrecisionAsApplied,
  resetSelectedAsApplied,
} from "../actions/precision.actions";

import { deleteTaskdataApi } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import FormCheckbox from "../../../shared/components/form/FormCheckbox/FormCheckbox";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { COLOR_FONT } from "../../../theme";
import { AsyncFn, Thunk } from "../../../types";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  handleClose: () => void;
  open: boolean;
  openRowId?: number;
}

const AsAppliedDeleteDialog: FC<Props & ReduxProps> = ({
  deleteTaskdataApi,
  fetchPrecisionAsApplied,
  handleClose,
  open,
  openRowId,
  resetSelectedAsApplied,
  selected,
}) => {
  const showSnackbar = useContext(SnackbarContext);
  const classes = useStyles();

  const initialValues = useMemo(
    () => ({
      confirmation: false,
    }),
    [],
  );

  const validate = (values: typeof initialValues) => {
    const errors: Record<string, string | React.ReactNode> = {};
    if (!values?.confirmation) {
      errors.confirmation = <FormattedMessage id="validation.required" />;
    }
    return errors;
  };

  const handleOnAccept = () =>
    (deleteTaskdataApi as AsyncFn<number[]>)(
      openRowId ? [openRowId] : selected,
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: <FormattedMessage id="common.error" />,
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: (
          <FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.success" />
        ),
        isSuccess: true,
      });
      resetSelectedAsApplied();
      fetchPrecisionAsApplied();
      handleClose();
    });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleOnAccept}
      validate={validate}
    >
      {({ submitForm }) => (
        <CfDialog
          acceptButtonVariant="text"
          acceptText={<FormattedMessage id="common.delete" />}
          externalStyle={classes}
          maxWidth="xs"
          onAccept={submitForm}
          onCancel={handleClose}
          opened={open}
          processing={false}
          title={
            <FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.title" />
          }
        >
          <div className={classes.body}>
            <FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.desc" />
            <Form>
              <FormCheckbox
                name="confirmation"
                label={
                  <FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.confirmation" />
                }
              />
            </Form>
          </div>
        </CfDialog>
      )}
    </Formik>
  );
};

const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: "16px 24px 24px 24px",
  },
  dialogContent: {
    padding: 0,
    overflowY: "unset",
  },
  dialogActions: {
    padding: 0,
    paddingTop: 48,
  },
  title: {
    padding: 0,
    marginBottom: 8,
  },
  closeIcon: {
    top: 8,
    right: 8,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    color: COLOR_FONT.main,
    fontSize: 14,
  },
  cancelButton: {
    color: COLOR_FONT.main,
  },
}));

const mapStateToProps = (state: PrecisionState) => ({
  selected: getPrecisionAsAppliedSelected(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      deleteTaskdataApi: deleteTaskdataApi as (val: number[]) => void,
      fetchPrecisionAsApplied,
      resetSelectedAsApplied,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AsAppliedDeleteDialog);
