import React from "react";

import TableBody from "@mui/material/TableBody";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";

import {
  getPrecisionAsAppliedIsFetching,
  getPrecisionAsAppliedSelectedOnPage,
  getPrecisionTaskdata,
  getPrecisionTaskdataCount,
} from "../../../shared/api/sentinel/precision/precisionAsApplied.selectors";
import {
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  getPrecisionAsAppliedSelected,
} from "../selectors/precisionAsApplied.selectors";

import { NAMESPACE as namespace } from "../reducer/precisionAsApplied.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../shared/misc/helper";
import DoubleLinedHeader from "../../../telematics/components/DoubleLinedHeader/DoubleLinedHeader";

import AsAppliedRow from "./AsAppliedRow";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

const columns = {
  created: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="PrecisionFarming.asApplied.column.importDate"
      secondaryId="common.time"
    />,
    { width: "110px" },
  ),
  source: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.column.source" />,
    { width: "200px" },
  ),
  filename: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.column.fileName" />,
    { width: "240px" },
  ),
  tasks: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskCount" />
    </span>,
    { width: "90px" },
  ),
  dateFrom: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDateFrom" />
    </span>,
    { width: "120px" },
  ),
  dateTo: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDateTo" />
    </span>,
    { width: "120px" },
  ),
  duration: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDuration" />
    </span>,
    { width: "100px", textAlign: "right" },
  ),
  actions: getColDesc(false, <span />, { width: "140px" }),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
};
type Props = ReduxProps & OwnProps;

export const PrecisionAsAppliedTable = ({
  count,
  farmId,
  isFetching,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  taskdata,
}: Props) => (
  <CfTableWrapper testId="precision-as-applied-list">
    <CfTableHead
      columns={columns}
      items={taskdata}
      namespace={namespace}
      order={order}
      orderBy={orderBy}
      selected={selected}
      selectedOnPage={selectedOnPage}
    />
    {isFetching && <CfTableBodyLoader columns={columns} />}

    {taskdata.length ? (
      <TableBody>
        {taskdata.map((row) => (
          <AsAppliedRow
            columnsLength={Object.keys(columns).length + 1}
            data={row}
            farmId={farmId}
            key={row.id}
          />
        ))}
      </TableBody>
    ) : (
      <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
    )}
    <CfTableFooter
      count={count}
      namespace={namespace}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  </CfTableWrapper>
);
const mapStateToProps = (state: PrecisionState) => ({
  taskdata: getPrecisionTaskdata(state),
  isFetching: getPrecisionAsAppliedIsFetching(state),
  page: getPrecisionAsAppliedPage(state),
  order: getPrecisionAsAppliedOrder(state),
  orderBy: getPrecisionAsAppliedOrderBy(state),
  rowsPerPage: getPrecisionAsAppliedRowsPerPage(state),
  count: getPrecisionTaskdataCount(state),
  selected: getPrecisionAsAppliedSelected(state),
  selectedOnPage: getPrecisionAsAppliedSelectedOnPage(state),
});

const connector = connect(mapStateToProps);

export default connector(PrecisionAsAppliedTable);
