import React, { FC } from "react";

import { Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";
import { useFertilizerDetailStyles } from "../FertilizerDetail.styles";

interface Props {
  fullWidth?: boolean;
  isEditing: boolean;
  isValidation?: boolean;
  label: string;
  name: string;
}

const FormikTextField: FC<Props> = ({
  fullWidth = false,
  isEditing,
  isValidation = false,
  label,
  name,
}) => {
  const classes = useFertilizerDetailStyles();

  return (
    <Field
      component={CfFormikTextField}
      customClasses={{ root: classes.fieldFormik }}
      disabled={!isEditing}
      fullWidth={fullWidth}
      label={<FormattedMessage id={label} />}
      name={name}
      validate={isValidation ? validators.required : undefined}
    />
  );
};

export default FormikTextField;
