import React from "react";

import TableCell from "@mui/material/TableCell";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  editButtonCell: {
    position: "absolute",
    right: 0,
    top: 1,
    height: "calc(100% - 3px)",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FFFFFF",
    paddingLeft: 5,
    paddingRight: 5,
    borderBottom: "none",
  },
  gradient: {
    width: 20,
    background:
      "linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    position: "absolute",
    left: -20,
    height: "100%",
  },
};

function CfTableRowTools(props) {
  const { children, classes, toolsClass, withoutGradient } = props;
  return (
    <TableCell
      className={`${classes.editButtonCell} ${toolsClass}`}
      onClick={(evt) => evt.stopPropagation()}
    >
      {!withoutGradient && <div className={classes.gradient} />}
      {children}
    </TableCell>
  );
}

CfTableRowTools.propTypes = {
  classes: PropTypes.object.isRequired,
  toolsClass: PropTypes.string.isRequired,
  withoutGradient: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CfTableRowTools.defaultProps = {
  withoutGradient: false,
};

export default withStyles(styles)(CfTableRowTools);
