import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import reducer from "../../reducers/admin.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";

import AdminWrapper from "./shared/containers/AdminWrapper/AdminWrapper";

AdminController.$inject = [
  "$element",
  "$scope",
  "$rootScope",
  "$transitions",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Localization",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function AdminController(
  $element,
  $scope,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    reducer,
    langId: null,
    ngImpersActivate: Auth.impersActivate,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onBefore(
    {},
    (transition) => {
      // hack because of behaviour of ui-router vs react-router
      // we need to block transition inside admin - when we want to switch farm, it is superseded
      const adminState = "farm.active.admin";
      const transitionFrom = transition.from();
      const transitionTo = transition.to();
      const transitionParams = transition.params();
      if (
        transitionFrom.name.startsWith(adminState) &&
        transitionTo.name.startsWith(adminState) &&
        transitionParams.farmId === this.farm.id
      ) {
        transition.abort();
      }
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function render() {
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <AdminWrapper {...p} />} />,
      $element[0],
    );
  }
}
