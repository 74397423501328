import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SpotsIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 20 20" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M4,17c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4C0.1,15.2,1.9,17,4,17z" />
      <path d="M13.5,19.9c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5S11,16.1,11,17.5C11,18.8,12.1,19.9,13.5,19.9z" />
      <path d="M14,12c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6C8,9.3,10.7,12,14,12z" />
    </svg>
  </SvgIcon>
);

export default SpotsIcon;
