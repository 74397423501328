/**
 * Created by ondrejzvara on 5.11.15.
 */

/* @ngInject */
export default function ResourceUtils(Api, FarmStorage, $q, FileSaver, Blob) {
  const service = {
    getItem,
    getItems,
    createItem,
    createItems,
    getIdAfterCreate,
    editItem,
    editItems,
    deleteItem,
    deleteItems,
    deleteItemsInBatch,
    addToFarm,
    customGet,
    customPut,
    customPatch,
    customPost,
    customDelete,
    composeParams,
    uploadFile,
    downloadFile,
  };
  return service;

  // ///////////////////////

  function getItem(base, params) {
    return injectFarmId(params).then((params) => Api.getItem(base, params));
  }

  function getItems(base, params, ngTparams) {
    return injectFarmId(params).then((params) =>
      Api.getItems(base, params).then((result) => {
        if (ngTparams && ngTparams.total) {
          ngTparams.total(result.headerCount);
        }

        return params.fullResponse ? result : result.data;
      }),
    );
  }

  function addToFarm(base, itemId, raw, params) {
    return injectFarmId(params).then((params) => {
      itemId += "/addToFarm";
      return Api.addToFarm(base, itemId, raw, params);
    });
  }

  function createItem(base, item, raw, params) {
    return injectFarmId(params).then((params) =>
      Api.createItem(base, item, raw, params),
    );
  }

  function createItems(base, items, params) {
    return injectFarmId(params).then((params) =>
      Api.createItems(base, items, params),
    );
  }

  function getIdAfterCreate(base, response) {
    const loc = response.location || null;
    if (loc) {
      const id = loc
        .replace(`/api/${base.route}/`, "")
        .replace(/\?farmIds=[0-9]*/i, "");
      return id ? $q.resolve(id) : $q.reject(id);
    }
    return $q.reject();
  }

  function editItem(item, params) {
    return injectFarmId(params).then((params) => Api.editItem(item, params));
  }

  function editItems(items, params) {
    return injectFarmId(params).then((params) => Api.editItems(items, params));
  }

  function deleteItem(item, params) {
    return injectFarmId(params).then((params) => Api.deleteItem(item, params));
  }

  function deleteItemsInBatch(base, items, params) {
    return injectFarmId(params).then((params) =>
      Api.deleteItemsInBatch(base, items, params),
    );
  }

  function deleteItems(items, params) {
    return injectFarmId(params).then((params) =>
      Api.deleteItems(items, params),
    );
  }

  function uploadFile(base, files, data, params) {
    return injectFarmId(params).then((params) =>
      Api.uploadFile(base, files, data, params),
    );
  }

  function downloadFile(base, mimeType, saveFileName, params) {
    return injectFarmId(params)
      .then((params) => Api.getFile(base, params))
      .then((resp) => {
        // only 200s are accepted
        if (resp.status !== 200) {
          return $q.reject(resp);
        }

        const blob = new Blob([resp.data], { type: mimeType });
        if (saveFileName) {
          FileSaver.saveAs(blob, saveFileName);
        }
        return {
          blob,
          blobUrl: (window.URL || window.webkitURL).createObjectURL(blob),
        };
      });
  }

  function customGet(base, pathStr, params, raw, seriouslyRaw) {
    return injectFarmId(params).then((params) =>
      Api.customGet(base, pathStr, params, raw, seriouslyRaw).then(
        (response) => {
          if (response.headerCount && params && params.total) {
            params.total(response.headerCount);
          }

          return response;
        },
      ),
    );
  }

  function customPut(base, elem, pathStr, params, raw) {
    return injectFarmId(params).then((params) =>
      Api.customPut(base, elem, pathStr, params, raw),
    );
  }

  function customPatch(base, elem, pathStr, params, raw) {
    return injectFarmId(params).then((params) =>
      Api.customPatch(base, elem, pathStr, params, raw),
    );
  }

  function customPost(base, elem, pathStr, params, raw) {
    return injectFarmId(params).then((params) =>
      Api.customPost(base, elem, pathStr, params, raw),
    );
  }

  function customDelete(base, pathStr, params) {
    return injectFarmId(params).then((params) =>
      Api.customDelete(base, pathStr, params),
    );
  }

  function composeParams(ngTparams, custParams) {
    const params = {};
    const src = _.merge(_.cloneDeep(ngTparams), _.cloneDeep(custParams));

    /* jshint camelcase: false */
    const page =
      typeof src.page === "function" ? src.page() : src.page ? src.page : null;
    const perPage =
      typeof src.count === "function"
        ? src.count()
        : src.count
          ? src.count
          : null;
    const sortCol =
      typeof src.sorting === "function"
        ? Object.keys(src.sorting())[0]
        : src.sorting
          ? Object.keys(src.sorting)[0]
          : null;
    const sortDir =
      typeof src.sorting === "function"
        ? src.sorting()[Object.keys(src.sorting())]
        : src.sorting
          ? src.sorting[Object.keys(src.sorting)]
          : null;
    const search =
      typeof src.filter === "function"
        ? src.filter().search
        : src.search
          ? src.search
          : null;
    const searchStr =
      typeof src.filter === "function"
        ? src.filter().search_str
        : src.filter
          ? src.filter
          : null;
    const external =
      src.hasOwnProperty("external") && src.external === true
        ? src.external
        : null;

    /* jshint camelcase: false */
    if (page) {
      params.page = page;
    }
    if (perPage) {
      params["per-page"] = perPage;
    }
    if (sortCol) {
      params["sort-col"] = sortCol;
    }
    if (sortDir) {
      params["sort-dir"] = sortDir;
    }
    if (search) {
      // newer API with "search" parameter (classifiers, ...)
      params.search = search;
    } else if (searchStr) {
      // older API with "search_str" parameter (parcels, actions, ...)
      params["search_str"] = searchStr;
    }
    if (external) {
      params.external = external;
    }

    if (ngTparams.fullResponse) {
      params.fullResponse = ngTparams.fullResponse;
    }

    return params;
  }

  function injectFarmId(params) {
    if (!params || !_.isObject(params)) {
      params = {};
    }

    const deferred = $q.defer();

    // !!!
    if (params.farmIds === false) {
      delete params.farmIds;
      deferred.resolve(params);
    } else if (typeof params.farmIds === "string" || params.farmIds) {
      deferred.resolve(params);
    } else {
      FarmStorage.getFarmId().then((farmId) => {
        params.farmIds = farmId;
        deferred.resolve(params);
      });
    }

    return deferred.promise;
  }
}
