import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";

export const getCrops = (
  state: ActionsState | ParcelsState | CataloguesState,
) => state.api.agroevidence.crops.items;
export const getIsFetchingCrops = (
  state: ActionsState | ParcelsState | CataloguesState,
) => state.api.agroevidence.crops.isFetching;
