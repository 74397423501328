import React, { Component } from "react";

import { Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import trim from "lodash/trim";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  checkResetKey,
  changeLanguage,
  setMailSent,
  saveNewPassword,
  resetError,
} from "../../actions/reset.actions";

import { checkEmailExists } from "../../../../shared/api/gateway/accounts/accounts.api";
import EntryContainer from "../../../../shared/containers/EntryContainer/EntryContainer";
import EmailEntryForm from "../../components/EmailEntryForm";
import NewPasswordForm from "../../components/NewPasswordForm";

const style = {
  textField: {
    textAlign: "left",
    width: "100%",
    marginTop: 0,
  },
  header: {
    marginTop: 0,
  },
  button: {
    width: "100%",
  },
  simpleMessage: {
    marginTop: 16,
    marginBottom: 16,
  },
};

class Reset extends Component {
  constructor(props) {
    super(props);
    const resetKey = props.getResetKey();
    if (resetKey !== undefined) {
      props.checkResetKey(props.gwUrl, resetKey);
    }

    if (props.resetSuccess) {
      props.ngRedirectToLogin();
    }
  }

  componentDidMount = () => {
    this.props.ngGetCurrentLangIdAsync().then((langId) => {
      this.props.changeLanguage(langId);
    });
  };

  componentDidUpdate(prevProps) {
    const { ngRedirectToLogin, resetSuccess } = this.props;
    if (resetSuccess && !prevProps.resetSuccess) {
      ngRedirectToLogin();
    }
  }

  onLangChange = (langId) => {
    this.props.changeLanguage(langId);
    this.props.ngChangeLanguage(langId);
  };

  submitEmailReset = (email) => {
    const url = this.props.gwUrl;
    this.props.setMailSent(url, trim(email));
  };

  submitNewPassword = (pass) => {
    const url = this.props.gwUrl;
    const key = this.props.getResetKey();
    this.props.saveNewPassword(url, key, trim(pass));
  };

  contentRender() {
    const { error, getResetKey } = this.props;

    if (error === null) {
      return getResetKey() === undefined
        ? this.renderWithoutKey()
        : this.renderWithKey();
    }
    return (
      <div className={this.props.classes.simpleMessage}>
        <FormattedMessage id="reset.error" />
        <a href="mailto:help@cleverfarm.cz">help@cleverfarm.cz</a>
      </div>
    );
  }

  renderWithKey() {
    const { resetKeyValid } = this.props;

    if (resetKeyValid) {
      return (
        <NewPasswordForm
          onNewPassword={(pass) => this.submitNewPassword(pass)}
        />
      );
    }

    return (
      <div className={this.props.classes.simpleMessage} id="invalid-key">
        <FormattedMessage id="reset.key.not.valid" />
      </div>
    );
  }

  renderWithoutKey() {
    const { classes, mailSent, ngRedirectToLogin } = this.props;

    if (mailSent) {
      return (
        <>
          <div className={classes.simpleMessage} id="email-sent-message">
            <FormattedMessage id="reset.mail.sent.message" />
          </div>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Button
                className={classes.button}
                color="primary"
                onClick={ngRedirectToLogin}
                variant="contained"
              >
                <FormattedMessage id="reset.linkToLogin" />
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <EmailEntryForm
        checkEmailExists={this.props.checkEmailExists}
        errorAction={this.props.resetError}
        ngRedirectToLogin={ngRedirectToLogin}
        submitEmailReset={this.submitEmailReset}
      />
    );
  }

  render() {
    const { isFetching } = this.props;

    return (
      <EntryContainer isFetching={isFetching} onLangChange={this.onLangChange}>
        {this.contentRender()}
      </EntryContainer>
    );
  }
}

Reset.propTypes = {
  isFetching: PropTypes.any,
  mailSent: PropTypes.bool.isRequired,
  ngChangeLanguage: PropTypes.func.isRequired,
  ngGetCurrentLangIdAsync: PropTypes.func.isRequired,
  ngRedirectToLogin: PropTypes.func.isRequired,
  getResetKey: PropTypes.func.isRequired,
  resetKeyValid: PropTypes.bool.isRequired,
  gwUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  resetSuccess: PropTypes.bool.isRequired,
  error: PropTypes.object,
  checkResetKey: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  setMailSent: PropTypes.func.isRequired,
  saveNewPassword: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  checkEmailExists: PropTypes.func.isRequired,
};

Reset.defaultProps = {
  isFetching: false,
  error: null,
};
const mapStateToProps = (state) => ({
  isFetching: state.ui.reset.isFetching,
  mailSent: state.ui.reset.mailSent,
  resetKeyValid: state.ui.reset.resetKeyValid,
  resetSuccess: state.ui.reset.resetSuccess,
  error: state.ui.reset.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkResetKey,
      changeLanguage,
      setMailSent,
      saveNewPassword,
      resetError,
      checkEmailExists,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(Reset));
