/**
 * Created by brasko on 5.8.2015.
 */

/* @ngInject */
export default function Api(ApiConfig, $q, Logger, Localization) {
  /**
   * Main API service
   * @namespace Api
   */

  const service = {
    // general
    setBaseAll,
    setBaseOne,
    setGatewayBaseAll,
    setGatewayBaseOne,
    restangularizeCollection,
    restangularizeOne,
    unrestangularize,
    getItem,
    getItems,
    createItem,
    createItems,
    editItem,
    editItems,
    deleteItem,
    deleteItems,
    deleteItemsInBatch,
    uploadFile,
    getFile,
    addToFarm,
    customGet,
    customPut,
    customPatch,
    customPost,
    customDelete,
    setSentinelBaseOne,
    setTelematicsBaseOne,
    setAgroevidenceBaseAll,
    setAgroevidenceBaseOne,
  };
  return service;

  // ///////////////////////

  function setBaseAll(route) {
    return ApiConfig.getObject().all(route);
  }

  function setBaseOne(route, id) {
    return ApiConfig.getObject().one(route, id);
  }

  function setAgroevidenceBaseAll(route) {
    return ApiConfig.getAgroevidenceObject().all(route);
  }

  function setAgroevidenceBaseOne(route, id) {
    return ApiConfig.getAgroevidenceObject().one(route, id);
  }

  function setTelematicsBaseOne(route, id) {
    return ApiConfig.getTelematicsObject().one(route, id);
  }

  function setGatewayBaseAll(route) {
    return ApiConfig.getGatewayObject().all(route);
  }

  function setGatewayBaseOne(route, id) {
    return ApiConfig.getGatewayObject().one(route, id);
  }

  function setSentinelBaseOne(route, id) {
    return ApiConfig.getSentinelObject().one(route, id);
  }

  function restangularizeCollection(parent, elements, route, queryParams) {
    _.each(elements, (element) => {
      restangularizeOne(parent, element, route, queryParams);
    });
    return elements;
  }

  function restangularizeOne(parent, element, route, queryParams) {
    const restangular = ApiConfig.getObject();
    return restangular.restangularizeElement(
      parent,
      element,
      route,
      queryParams,
    );
  }

  function unrestangularize(element) {
    return ApiConfig.getObject().stripRestangular(element);
  }

  function getItem(base, params) {
    return base.get(params).then(handleSuccess).catch(handleError);
  }

  /**
   * Get items.
   * @public
   * @memberof Api
   * @param {Object} base Restangular object defining base path to the resource.
   * @param {Object} [params=null] Set query params in (key, value) form.
   * @returns {Array} AN array of items.
   */
  function getItems(base, params) {
    return base.getList(params).then(handleGetListSuccess).catch(handleError);
  }

  function createItem(base, item, raw, params) {
    return base
      .post(item, params)
      .then((response) => handleSuccess(response, raw))
      .catch(handleError);
  }

  function createItems(base, items, params) {
    if (!_.isObject(items) && items.length < 1) {
      return;
    }

    const promises = [];
    angular.forEach(items, (item) => {
      const promise = createItem(base, item, false, params);
      promises.push(promise);
    });
    return $q.all(promises);
  }

  /**
   * Edits provided item.
   * @public
   * @memberof Api
   * @param {Object} item An item to be edited.
   * @param {Object} [params=null] Set query params in (key, value) form.
   * @returns {Object} Returns promise.
   */
  function editItem(item, params) {
    if (!_.isObject(item) || _.isEmpty(item)) {
      return;
    }

    return item.put(params).then(editItemSuccess).catch(editItemError);

    function editItemSuccess(data) {
      return data;
    }

    function editItemError(error) {
      Logger.log(error);
    }
  }

  /**
   * Edits an array of provided items.
   * @public
   * @memberof Api
   * @param {Array} items An array of items to be edited. Min length = 1.
   * @param {Object} [params=null] Set query params in (key, value) form.
   * @returns {Object} Returns promise.
   */
  function editItems(items, params) {
    if (!_.isObject(items) && items.length < 1) {
      return;
    }

    const promises = [];
    angular.forEach(items, (item) => {
      const itemCpy = angular.copy(item);
      const promise = editItem(itemCpy, params);
      promises.push(promise);
    });
    return $q.all(promises);
  }

  /**
   * Deletes provided item.
   * @public
   * @memberof Api
   * @param {Object} item An item to be deleted.
   * @param {Object} [params=null] Set query params in (key, value) form.
   * @returns {Object} Returns promise.
   */
  function deleteItem(item, params) {
    if (!_.isObject(item) || _.isEmpty(item)) {
      return;
    }

    return item.remove(params).then(deleteItemSuccess).catch(deleteItemError);

    function deleteItemSuccess(data) {
      Logger.log(`Deleted item: ${data}`);
    }

    function deleteItemError(error) {
      Logger.error(error);
      return false;
    }
  }

  /**
   * Deletes an array of provided items.
   * @public
   * @memberof Api
   * @param {Array} items An array of items to be deleted. Min length = 1.
   * @param {Object} [params=null] Set query params in (key, value) form.
   * @returns {Object} Returns promise.
   */
  function deleteItems(items, params) {
    if (!_.isObject(items) && items.length < 1) {
      return;
    }

    const promises = [];
    angular.forEach(items, (item) => {
      const promise = deleteItem(item, params);
      promises.push(promise);
    });
    return $q.all(promises);
  }

  function deleteItemsInBatch(base, items, params) {
    if (!_.isObject(items) && items.length < 1) {
      return;
    }

    const itemIds = _.reduce(
      items,
      (result, item) => (!result ? item.id : `${result},${item.id}`),
      null,
    );

    return customDelete(base, itemIds, params);
  }

  function uploadFile(base, files, data, params) {
    const fd = new FormData();
    _.each(files, (val, key) => {
      fd.append(key, files[key]);
    });

    _.each(data, (val, key) => {
      fd.append(key, data[key]);
    });

    base.withHttpConfig({
      transformRequest: angular.identity,
      withCredentials: true,
    });

    return base
      .customPOST(fd, "", params, { "Content-Type": undefined })
      .then((res) => {
        Logger.log(res);
        return res.data;
      });
  }

  function getFile(base, params) {
    base.withHttpConfig({ responseType: "blob" });
    return base.customGET(null, params);
  }

  function addToFarm(base, itemId, raw, params) {
    return base
      .customPOST(null, itemId, params)
      .then((response) => handleSuccess(response, raw))
      .catch(handleError);
  }

  /** ****************** CUSTOM FUNCTIONS ************************************* */

  function customGet(base, pathStr, params, raw, seriouslyRaw) {
    return base
      .customGET(pathStr, params)
      .then((response) => handleSuccess(response, raw, seriouslyRaw))
      .catch((error) => handleError(error, raw));
  }

  function customPut(base, elem, pathStr, params, raw) {
    return base
      .customPUT(elem, pathStr, params)
      .then((response) => handleSuccess(response, raw))
      .catch((error) => handleError(error, raw));
  }

  function customPatch(base, elem, pathStr, params, raw) {
    return base
      .customPATCH(elem, pathStr, params)
      .then((response) => handleSuccess(response, raw))
      .catch((error) => handleError(error, raw));
  }

  function customPost(base, elem, pathStr, params, raw) {
    return base
      .customPOST(elem, pathStr, params)
      .then((response) => handleSuccess(response, raw))
      .catch((error) => handleError(error, raw));
  }

  function customDelete(base, pathStr, params) {
    return base
      .customDELETE(pathStr, params)
      .then(handleSuccess)
      .catch(handleError);
  }

  /** ****************** HANDLERS ************************************* */

  function handleGetListSuccess(response) {
    const result = {};
    result.data = response.data || null;
    result.headerCount = response.headers("X-Total-Count") || null;

    const location = response.headers("location") || null;
    if (location) {
      result.location = location;
    }

    return result;
  }

  function handleSuccess(response, raw, seriouslyRaw) {
    if (seriouslyRaw) {
      return response;
    } else if (raw) {
      return handleGetListSuccess(response);
    } else if (response && response.data !== undefined) {
      return response.data;
    }
  }

  /**
   * By default exceptions thrown from the resource endpoint contain the following properties.
   *
   * timestamp - The time that the errors were extracted
   * status - The status code
   * error - The error reason - short name of the response status
   * exception - The class name of the root server-side exception
   * message - The exception message - description of the error. THIS SHOULD NOT BE USED IN VIEWS
   * errors - Optional: More detailed information on error
   * trace - Optional: The server-side exception stack trace - it is disabled by default
   * path - The URL path when the exception was raised
   *
   * @param error Original error object
   * @param  Whether the rejected response should contain the original error object or just plain messages
   * @returns {*}
   */
  function handleError(error, raw) {
    if (_.startsWith(error.status, "4")) {
      return handleClientErrors(error, raw);
    }
    return handleDefault(error, raw);
  }

  function handleClientErrors(error, raw) {
    // client errors are enriched with localized messages if any provided, else default is used

    const errorCodes = _.map(
      error.data.errors,
      (err) => `errors.${err.objectName}.${err.code}`,
    );

    return Localization.getMessages(errorCodes)
      .then((msgs) => _.values(msgs))
      .then((localizedMessages) => {
        error.localizedMessages = localizedMessages;
        const response = createErrorResponse(error, raw);
        return $q.reject(response);
      });
  }

  function handleDefault(error, raw) {
    const response = createErrorResponse(error, raw);
    return $q.reject(response);
  }

  function createErrorResponse(error, raw) {
    let result;
    if (raw) {
      result = error;
    } else if (error.localizedMessages && error.localizedMessages.length > 0) {
      result = error.localizedMessages;
    } else {
      const eMsg = error.data
        ? error.data.error
          ? error.data.error
          : "error not available"
        : "error not available";
      const eStatus = error.data
        ? error.data.status
          ? error.data.status
          : "undefined"
        : "undefined";
      result = `Status ${eStatus}: ${eMsg}`;
    }

    return result;
  }
}
