import { getMarker } from "../../selectors/map.selectors";

export const mapAddMarker = (coords) => (_, getState) => {
  const markerService = getMarker(getState());
  markerService.mapAddMarker(coords);
};

export const mapRemoveMarker = () => (_, getState) => {
  const markerService = getMarker(getState());
  markerService.mapRemoveMarker();
};
