import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getIsFetchingPrecisionParcels,
  getTotalCount,
  getIsInitPrecisionPage,
} from "../../../shared/api/sentinel/precision/precision.selectors";
import { getPrecisionParcelListTextFilter } from "../selectors/precisionParcelList.selectors";

import { getPrecisionParcelsApi } from "../../../shared/api/sentinel/precision/precision.api";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

type Props = {
  isPageInitCall: boolean;
  selectedTabIndex?: number;
};

const useIsPrecisionFarmingActive = (p: Props): boolean | undefined => {
  const activeServicesTabIsSelected = p.selectedTabIndex !== 1;
  const dispatch = useDispatch();
  const parcelCount = useSelector(getTotalCount);
  const textFilter = useSelector(getPrecisionParcelListTextFilter);
  const settled = useSelector(
    (state: PrecisionState) => !getIsFetchingPrecisionParcels(state),
  );
  const viewIsInit = useSelector(getIsInitPrecisionPage);

  React.useEffect(() => {
    if (
      activeServicesTabIsSelected &&
      ((p.isPageInitCall && !viewIsInit) || (!p.isPageInitCall && viewIsInit))
    ) {
      dispatch(getPrecisionParcelsApi());
    }
  }, [
    activeServicesTabIsSelected,
    dispatch,
    p.isPageInitCall,
    p.selectedTabIndex,
    viewIsInit,
  ]);

  if (settled) {
    return parcelCount > 0 || (parcelCount === 0 && textFilter.length > 0);
  }
};

export { useIsPrecisionFarmingActive };
