import React from "react";

import { Theme } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { patchTaskdata } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions";
import {
  fetchPrecisionAsApplied,
  resetSelectedAsApplied,
} from "../actions/precision.actions";

type Props = {
  onClick: () => void;
  onDelete: () => void;
  onDownload: (e: React.MouseEvent) => void;
  selected: number[];
};

const useVarioDocBulkItems = ({
  onClick,
  onDelete,
  onDownload,
  selected,
}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const refreshView = () => {
    dispatch(fetchPrecisionAsApplied());
    dispatch(resetSelectedAsApplied());
  };

  const onArchivingClick = (archived: boolean) => {
    const result = {};
    selected.forEach((id) => {
      Object.assign(result, { [id]: { archived } });
    });

    return patchTaskdata({
      tasks: result,
    })(dispatch).then(() => refreshView());
  };

  const onDownloadClick = (e: React.MouseEvent) => {
    onDownload(e);
    onClick();
  };

  const handleOnDelete = () => {
    onDelete();
    onClick();
  };

  return (
    <>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => {
          onArchivingClick(false);
          onClick();
        }}
      >
        <ListItemText>
          <FormattedMessage id="common.makeVisible" />
        </ListItemText>
      </ListItem>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => {
          onArchivingClick(true);
          onClick();
        }}
      >
        <ListItemText>
          <FormattedMessage id="common.hide" />
        </ListItemText>
      </ListItem>
      <ListItem button className={classes.listItem} onClick={onDownloadClick}>
        <ListItemText>
          <FormattedMessage id="common.download" />
        </ListItemText>
      </ListItem>
      <ListItem button className={classes.listItem} onClick={handleOnDelete}>
        <ListItemText>
          <FormattedMessage id="common.delete" />
        </ListItemText>
      </ListItem>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

export default useVarioDocBulkItems;
