import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { PRECISION_URLS } from "../../../../../precision/precision.constants";

import CfPrimaryTab from "../../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";

import { PrecisionParcel } from "../../../../../../shared/api/sentinel/precision/precision.types";

type Tab = {
  code?: string;
  id: string;
  path: string;
  section: string;
};

export type Tabs = {
  [t: string]: Tab;
};

export interface ParcelDetailTabsProps {
  activateTab: (value: string) => void;
  activeTab: string;
  farmId: string;
  parcelId: string;
  parcelsSection?: boolean;
  precisionParcel?: PrecisionParcel;
  precisionSection?: boolean;
  tabs: Tabs;
  width: string;
}

const ParcelDetailTabs: FC<ParcelDetailTabsProps> = ({
  activateTab,
  activeTab,
  farmId,
  parcelId,
  parcelsSection = false,
  precisionParcel,
  precisionSection = false,
  tabs,
  width,
}) => {
  const history = useHistory();

  let tabsArray;

  if (precisionSection && precisionParcel) {
    tabsArray = Object.values(tabs).filter(
      (tab) => precisionParcel[tab.code as keyof PrecisionParcel],
    );
  }
  if (parcelsSection) {
    tabsArray = Object.values(tabs);
  }

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string,
  ) => {
    if (parcelsSection) {
      history.push(`/farm/${farmId}/parcels/${parcelId}/${tabs[value].path}`);
    }
    if (precisionSection) {
      history.push(
        `/farm/${farmId}/${PRECISION_URLS.services()}/${parcelId}/${
          tabs[value].path
        }`,
      );
    }
    activateTab(value);
  };

  const variant = width === "xs" ? "scrollable" : "standard";

  return (
    <CfPrimaryTabs
      allowScrollButtonsMobile={true}
      centered={width !== "xs"}
      onChange={handleChange}
      scrollButtons={true}
      tabValue={activeTab}
      variant={variant}
    >
      {tabsArray?.map((tab: Tab) => (
        <CfPrimaryTab
          data-test={tab.id}
          key={tab.id}
          label={<FormattedMessage id={`${tab.section}.${tab.id}`} />}
          style={{ flexGrow: 1 }}
          value={tab.id}
        />
      ))}
    </CfPrimaryTabs>
  );
};

export default ParcelDetailTabs;
