import { AnyAction } from "redux";

import * as commonFilterTypes from "../../../../shared/actions/filter.constants";
import * as commonTableTypes from "../../../../shared/actions/table.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";

import { FarmsUIState } from "../../../../reducers/admin.reducer.types";

export const NAMESPACE = "farms-admin-ui";

const initialState: FarmsUIState = {
  page: 0,
  rowsPerPage: 10,
  order: sortOrder.ASC,
  orderBy: "name",
  textFilter: "",
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_SELECTED:
        return {
          ...state,
          selected: action.selected,
        };
      case commonFilterTypes.SET_TEXT_FILTER:
        return {
          ...state,
          textFilter: action.textFilter,
          page: 0,
        };
      default:
        return state;
    }
  };
