import React from "react";

import { TableCell, TableRow, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableCheckbox from "../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { ParcelZoneName } from "../../list/components/ParcelZoneName";
import ParcelCropAssign from "../../shared/containers/ParcelCropAssign/ParcelCropAssign";

import {
  CropTo,
  ParcelWithSowingPlansTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

interface IParcelCropsTableProps {
  farmId: string;
  isSelected: boolean;
  namespace: string;
  onAssignCrop: (crop: CropTo, seasonId: string, parcelId: string) => void;
  parcel: ParcelWithSowingPlansTo;
  selected: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  greyText: {
    color: theme.palette.grey[500],
  },
  cellRow: {
    margin: 0,
  },
  name: {
    fontWeight: 500,
  },
  pointer: {
    cursor: "pointer",
  },
}));

const ParcelCropsTableRow = ({
  farmId,
  isSelected,
  namespace,
  onAssignCrop,
  parcel,
  selected,
}: IParcelCropsTableProps) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableRow
      className={classes.pointer}
      hover
      selected={isSelected}
      onClick={() =>
        history.push(`/farm/${farmId}/parcels/${parcel.id}/overview`)
      }
    >
      <CfTableCheckbox
        id={parcel.id}
        namespace={namespace}
        selected={selected}
      />
      <CfTableCell name="localName">
        <span>
          <p className={`${classes.name} ${classes.cellRow}`}>
            {parcel.localName}
          </p>
          <p className={`${classes.cellRow} ${classes.greyText}`}>
            <ParcelZoneName parcel={parcel} />
          </p>
        </span>
      </CfTableCell>
      <CfTableCell name="blockNumber">{parcel.blockNumber}</CfTableCell>
      <CfTableCell name="area">
        <span>
          <CfTableCellNumber padding={true}>
            <p className={`${classes.cellRow}`}>
              <CfFormattedNumber value={parcel.area || 0} />
            </p>
            <p className={`${classes.cellRow} ${classes.greyText}`}>
              <CfFormattedNumber value={parcel.lpisBlockArea || 0} />
            </p>
          </CfTableCellNumber>
        </span>
      </CfTableCell>
      <CfTableCell name="currentCrop">
        {parcel.seedApplication?.seed.crop.name || (
          <FormattedMessage id="common.noCrop" />
        )}
      </CfTableCell>
      {parcel.seasons?.map((parcelSowingPlan, i) => (
        <TableCell key={i} onClick={(evt) => evt.stopPropagation()}>
          <ParcelCropAssign
            onCropChange={onAssignCrop}
            parcel={parcel}
            sowingPlan={parcelSowingPlan}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ParcelCropsTableRow;
