import { ActionsIcon } from "../../../shared/icons/navbar/ActionsIcon";
import { ClassifiersIcon } from "../../../shared/icons/navbar/ClassifiersIcon";
import { IrrigationIcon } from "../../../shared/icons/navbar/IrrigationIcon";
import { MapIcon } from "../../../shared/icons/navbar/MapIcon";
import { NotificationsIcon } from "../../../shared/icons/navbar/NotificationsIcon";
import { ParcelsIcon } from "../../../shared/icons/navbar/ParcelsIcon";
import { ReportsIcon } from "../../../shared/icons/navbar/ReportsIcon";
import { SensorsIcon } from "../../../shared/icons/navbar/SensorsIcon";
import { StoresIcon } from "../../../shared/icons/navbar/StoresIcon";
import { TelematicsIcon } from "../../../shared/icons/navbar/TelematicsIcon";

import MENU_ITEMS from "./menuItems";

const icons = {
  map: MapIcon,
  parcels: ParcelsIcon,
  sensors: SensorsIcon,
  notifications: NotificationsIcon,
  stores: StoresIcon,
  actions: ActionsIcon,
  reports: ReportsIcon,
  classifiers: ClassifiersIcon,
  irrigation: IrrigationIcon,
  telematics: TelematicsIcon,
  precision: ParcelsIcon,
};

export default class NavbarService {
  static getMainMenuItems() {
    return MENU_ITEMS;
  }

  static getDropdownMainMenuItems() {
    return MENU_ITEMS.filter((menuItem) => menuItem.dropdown);
  }

  static getMenuItemIcon(itemId: keyof typeof icons) {
    return icons[itemId];
  }

  static getUsername(user: {
    firstName?: string;
    lastName?: string;
    email: string;
  }) {
    return user.firstName || user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.email;
  }
}
