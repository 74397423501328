import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  header: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "18px 36px",
      borderBottom: "1px solid #EFEFEF",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 0 0 20px",
      minWidth: "max-content",
      height: 120,
      borderRight: "1px solid #EFEFEF",
    },
  },
});

function MapBottomPanelHeader(props) {
  const { children, classes, testId } = props;
  return (
    <div className={classes.header} data-test={testId}>
      {children}
    </div>
  );
}

MapBottomPanelHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  testId: PropTypes.string,
};

MapBottomPanelHeader.defaultProps = {
  testId: "map-bottom-panel-header",
};

export default withStyles(styles)(MapBottomPanelHeader);
