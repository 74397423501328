import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getVariableActionIds as getVariableActionIdsSelector } from "../../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";
import {
  getSelectedOnPage,
  getParcelActivitiesDateFilter,
} from "../../selectors/parcelActivities.selectors";

import {
  setDateFilter,
  deleteActivities,
} from "../../actions/parcelActivities.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelActivities.reducer";

import {
  getVariableActionIds,
  resetVariableActionIds,
} from "../../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import CfDateFilter from "../../../../../../shared/components/common/CfDateFilter/CfDateFilter";
import TableActionButtons from "../../../../../../shared/components/tables/TableActionButtons/TableActionButtons";
import ParcelActivitiesAdvancedFilter from "../ParcelActivitiesAdvancedFilter/ParcelActivitiesAdvancedFilter";
import ParcelActivitiesTable from "../ParcelActivitiesTable/ParcelActivitiesTable";

const styles = (theme) => ({
  dateFilter: {
    width: 180,
    minWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: 2,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 16px 8px 8px",
    },
  },
  actionButtons: {
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 14,
    },
  },
  advancedFilter: {
    order: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      order: 1,
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
});

class ParcelActivities extends Component {
  componentDidMount() {
    this.props.getVariableActionIds();
  }

  componentWillUnmount() {
    this.props.resetVariableActionIds();
  }

  handleDateFilterChange = (filter) => {
    let dateFilter = filter;
    if (filter) {
      // utc offset is temporarily added, until BE error is fixed
      dateFilter = filter.add(filter.utcOffset(), "m").toISOString();
    }
    this.props.setDateFilter(dateFilter);
  };

  handleDeleteActivities = () => {
    const { parcelId, selectedOnPage, variableActionIds } = this.props;
    this.props.deleteActivities(parcelId, selectedOnPage, variableActionIds);
  };

  render() {
    const {
      classes,
      dateFilter,
      farmId,
      history,
      langId,
      match,
      parcelId,
      selectedOnPage,
    } = this.props;
    return (
      <div>
        <div className={classes.filtersHeader}>
          <div className={classes.actionButtons}>
            <TableActionButtons
              onDeleteActions={this.handleDeleteActivities}
              selected={selectedOnPage}
              content={
                <FormattedMessage id="ActionButtons.deleteModalAction.context" />
              }
              title={
                <FormattedMessage id="ActionButtons.deleteModalAction.title" />
              }
            />
          </div>
          <div className={classes.dateFilter}>
            <CfDateFilter
              label={<FormattedMessage id="common.date-from" />}
              name="parcel-activities-date-filter"
              onFilterChange={this.handleDateFilterChange}
              value={dateFilter}
            />
          </div>
          <div className={classes.advancedFilter}>
            <ParcelActivitiesAdvancedFilter
              langId={langId}
              namespace={namespace}
            />
          </div>
        </div>
        <ParcelActivitiesTable
          farmId={farmId}
          history={history}
          langId={langId}
          match={match}
          parcelId={parcelId}
        />
      </div>
    );
  }
}

ParcelActivities.propTypes = {
  classes: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  deleteActivities: PropTypes.func.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  dateFilter: PropTypes.any,
  setDateFilter: PropTypes.func.isRequired,
  getVariableActionIds: PropTypes.func.isRequired,
  resetVariableActionIds: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  variableActionIds: PropTypes.array,
};

ParcelActivities.defaultProps = {
  dateFilter: null,
  variableActionIds: null,
};

const mapStateToProps = (state) => ({
  selectedOnPage: getSelectedOnPage(state),
  dateFilter: getParcelActivitiesDateFilter(state),
  variableActionIds: getVariableActionIdsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDateFilter,
      deleteActivities,
      getVariableActionIds,
      resetVariableActionIds,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ParcelActivities));
