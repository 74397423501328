import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfChip from "../../../../shared/components/common/CfChip/CfChip";

const styles = (theme) => ({
  accordion: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  root: {
    backgroundColor: theme.palette.grey[100],
    minHeight: 48,
    fontSize: 16,
    "&$expanded": {
      minHeight: 48,
    },
  },
  expandIcon: {
    padding: 6,
  },
  expanded: {},
  content: {
    margin: "8px 0px",
    justifyContent: "left",
    "&$expanded": {
      margin: "8px 0px",
    },
  },
  details: {
    display: "block",
    padding: "8px 8px 8px",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
});

function Report(props) {
  const { children, classes, count, header, id } = props;
  const message = <FormattedMessage id="Report.selected" values={{ count }} />;
  return (
    <Accordion
      classes={{ root: classes.accordion, expanded: classes.expanded }}
      data-test={id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={id}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          expandIcon: classes.expandIcon,
        }}
      >
        <span className={classes.header}>
          <span className={classes.label}>{header}</span>
          {count > 0 && <CfChip message={message} />}
        </span>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

Report.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  count: PropTypes.number,
};

Report.defaultProps = {
  classes: {},
  count: 0,
};

export default withStyles(styles)(Report);
