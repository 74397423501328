import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import CfFilter from "../../../../../shared/containers/CfFilter/CfFilter";
import IrrigationFilterByDeviceType from "../../components/IrrigationFilterByDeviceType/IrrigationFilterByDeviceType";
import IrrigationFilterByStatus from "../../components/IrrigationFilterByStatus/IrrigationFilterByStatus";
import { getIrrigationAdminAdvancedFilter } from "../../selectors/admin.devices.selector";

import {
  AdminDeviceState,
  AdminDeviceTypeTo,
  IrrigationAdminAdvancedFilter,
  IrrigationAdminState,
} from "../../admin.irrigation.types";

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
}));

interface Props {
  advancedFilter?: IrrigationAdminAdvancedFilter;
  deviceTypes: AdminDeviceTypeTo[];
  langId: string;
  namespace?: string;
}

const IrrigationAdminFilter: FC<Props> = ({
  advancedFilter = {},
  deviceTypes,
  langId,
  namespace = "",
}) => {
  const classes = useStyles();
  const [advFilter, setAdvFilter] = useState({});

  const setFilterPart = (
    filterPart: string,
    items: AdminDeviceTypeTo[] | AdminDeviceState,
  ) => {
    if (!filterPart) {
      return advFilter;
    }
    return {
      ...advFilter,
      [filterPart]: items,
    };
  };

  const handleChange = (
    filterPart: string,
    items: AdminDeviceTypeTo[] | AdminDeviceState,
  ) => {
    const newValues = setFilterPart(filterPart, items);
    setAdvFilter(newValues);
  };

  return (
    <CfFilter
      filterState={advFilter}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvFilter}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <IrrigationFilterByStatus
              label={<FormattedMessage id="common.state" />}
              selectedState={advancedFilter.status}
              setDeviceStatusFilter={handleChange}
            />
          </Grid>
          <Grid className={classes.select} item xs={12}>
            <IrrigationFilterByDeviceType
              deviceTypes={deviceTypes}
              label={<FormattedMessage id="common.type" />}
              selectedDeviceTypes={advancedFilter.deviceType}
              setDeviceTypeFilter={handleChange}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: IrrigationAdminState) => ({
  advancedFilter: getIrrigationAdminAdvancedFilter(state),
});

export default connect(mapStateToProps, null)(IrrigationAdminFilter);
