import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import reducer from "../../reducers/stores.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";

import StoresWrapper from "./shared/containers/StoresWrapper/StoresWrapper";

StoresController.$inject = [
  "$element",
  "$scope",
  "$state",
  "$rootScope",
  "$transitions",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Localization",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function StoresController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    farmId: this.farmId,
    farm: this.farm,
    langId: null,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    ngGoToAction,
    reducer,
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: "stores",
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onBefore(
    {},
    (transition) => {
      // hack because of behaviour of ui-router vs react-router
      // we need to block transition inside stores - when we want to switch farm, it is superseded
      const storesState = "farm.active.store";
      const transitionFrom = transition.from();
      const transitionTo = transition.to();
      const transitionParams = transition.params();
      if (
        transitionFrom.name.startsWith(storesState) &&
        transitionTo.name.startsWith(storesState) &&
        transitionParams.farmId === props.farmId
      ) {
        transition.abort();
      }
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngGoToAction(actionId) {
    return $state.go("farm.active.actions.action", { actionId });
  }

  const render = () => {
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <StoresWrapper {...p} />} />,
      $element[0],
    );
  };
}
