import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SoilMoisture = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.81,10.55H2v1.5H5.38A8.84,8.84,0,0,1,5.81,10.55Z" />
      <path d="M18.19,10.55a8.84,8.84,0,0,1,.43,1.5H22v-1.5Z" />
      <path d="M12.17,4.87,12,4.7l-.17.16C8.46,8.08,6.75,10.92,6.75,13.3c0,3.9,2.71,6,5.25,6s5.25-2.1,5.25-6C17.25,10.93,15.54,8.09,12.17,4.87ZM12,17.39c-2,0-3.5-1.76-3.5-4.09,0-1.53,1.21-3.61,3.5-6v0c2.29,2.39,3.5,4.47,3.5,6C15.5,15.63,14,17.39,12,17.39Z" />
      <path d="M5.85,16.55H2v1.5H6.7A7.16,7.16,0,0,1,5.85,16.55Z" />
      <path d="M18.15,16.55a7.16,7.16,0,0,1-.85,1.5H22v-1.5Z" />
      <path d="M5.26,13.55H2v1.5H5.41A9.41,9.41,0,0,1,5.26,13.55Z" />
      <path d="M18.74,13.55a9.41,9.41,0,0,1-.15,1.5H22v-1.5Z" />
    </svg>
  </SvgIcon>
);

export default SoilMoisture;
