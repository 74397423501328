import React, { useState } from "react";

import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";

import { IRRIGATION_URLS } from "../../../../irrigation.constants";

import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";

import AreaButtonsCell from "./AreaButtonsCell";
import IrrigationPlanIndicators from "./IrrigationPlanIndicators";

const AreaListRow = ({
  area,
  areaDevicesIds,
  editorSetHoveredId,
  farmId,
  history,
}) => {
  const [isInlineDeleteConfirmation, setIsInlineDeleteConfirmation] =
    useState(false);

  return (
    <TableRow
      hover
      onMouseEnter={() => editorSetHoveredId(areaDevicesIds.devicesId)}
      onMouseLeave={() => editorSetHoveredId(null)}
      style={{ cursor: "pointer" }}
      onClick={() =>
        history.push(`/farm/${farmId}/${IRRIGATION_URLS.AREA}/${area.id}/plan`)
      }
    >
      <CfTableCell name="name">{area.name}</CfTableCell>
      <CfTableCell name="deviceCount">{area.deviceCount}</CfTableCell>
      {!isInlineDeleteConfirmation && (
        <CfTableCell name="IrrigationPlan">
          <IrrigationPlanIndicators plan={area.irrigationDaysOfWeek} />
        </CfTableCell>
      )}
      <AreaButtonsCell
        area={area}
        isInlineDeleteConfirmation={isInlineDeleteConfirmation}
        setIsInlineDeleteConfirmation={setIsInlineDeleteConfirmation}
      />
    </TableRow>
  );
};

AreaListRow.propTypes = {
  farmId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editorSetHoveredId: PropTypes.func.isRequired,
  areaDevicesIds: PropTypes.object.isRequired,
};

export default AreaListRow;
