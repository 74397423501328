import React, { FC, Fragment } from "react";

import { Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import legend from "../../../../assets/img/satellite/hist-potential-legend.png";
import CfLazyImage from "../../../../shared/components/common/CfLazyImage/CfLazyImage";
import SatelliteImagesInfoDialog from "../SatelliteImagesInfoDialog";
import SatelliteInfoDialogItem from "../SatelliteInfoDialogItem";

import { HistorySnap } from "../../../../shared/api/sentinel/management/management.types";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    minWidth: "150px",
    maxWidth: "150px",
    height: "auto",
    imageRendering: "pixelated",
    "&": {
      // fallback for FF
      imageRendering: "-moz-crisp-edges",
    },
    marginRight: 25,
  },
  itemLegendWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
  },
  legendWrapper: {
    display: "flex",
    alignItems: "center",
  },
  legend: {
    margin: "0 5px",
  },
  legendInfo: {
    color: theme.palette.grey[500],
    marginTop: 10,
  },
  legendLowLabel: {
    color: "#8F3D2A",
  },
  legendHighLabel: {
    color: "#438B00",
  },
  [theme.breakpoints.down("sm")]: {
    image: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
}));

interface ManagementHistoryDialogProps {
  item: HistorySnap;
}

const ManagementHistoryDialog: FC<ManagementHistoryDialogProps> = ({
  item,
}) => {
  const classes = useStyles();

  return (
    <SatelliteImagesInfoDialog>
      <Fragment>
        <SatelliteInfoDialogItem
          label={<FormattedMessage id="ManagementZones.History.satellite" />}
        >
          <CfLazyImage
            alt={`History Snap from ${item.date}`}
            classes={{ custom: classes.image }}
            src={item.managementZoneImgPath}
            testId="satellite"
          />
        </SatelliteInfoDialogItem>
        <SatelliteInfoDialogItem
          label={
            <FormattedMessage id="ManagementZones.History.rel-involvement" />
          }
        >
          <Fragment>
            <CfLazyImage
              alt={`History Snap from ${item.date}`}
              classes={{ custom: classes.image }}
              src={item.involvementImgPath}
              testId="involvement"
            />
            <div className={classes.itemLegendWrapper}>
              <div className={classes.legendWrapper}>
                <span className={classes.legendLowLabel}>
                  <FormattedMessage id="common.low" />
                </span>
                <img alt="legend" className={classes.legend} src={legend} />{" "}
                <span className={classes.legendHighLabel}>
                  <FormattedMessage id="common.high" />
                </span>
              </div>
              <div className={classes.legendInfo}>
                <Typography>
                  <FormattedMessage id="ManagementZones.History.dialogLegend" />
                </Typography>
              </div>
            </div>
          </Fragment>
        </SatelliteInfoDialogItem>
      </Fragment>
    </SatelliteImagesInfoDialog>
  );
};

export default ManagementHistoryDialog;
