import { DrivesImportHistoryReducer } from "../../../../telematics/reducer/drivesImportHistory.types";

export const getStateIsFetching = (state: DrivesImportHistoryReducer) =>
  state.api.drivesImportHistory.stateIsFetching;
export const getLastImportState = (state: DrivesImportHistoryReducer) =>
  state.api.drivesImportHistory.lastImportState;
export const getItems = (state: DrivesImportHistoryReducer) =>
  state.api.drivesImportHistory.items;
export const getIsFetching = (state: DrivesImportHistoryReducer) =>
  state.api.drivesImportHistory.isFetching;
export const getTotalCount = (state: DrivesImportHistoryReducer) =>
  state.api.drivesImportHistory.totalCount;
