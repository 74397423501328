import { createSelector } from "reselect";

import { getCurrentLocation } from "./locations.selectors";
import { getIsFetching as getIsFetchingLocationsApi } from "../../shared/api/iot/locations/locations.selectors";
import {
  getNodes,
  getIsFetchingNodes as getIsFetchingNodesApi,
  getIsFetchingStatistics as getIsFetchingStatisticsApi,
} from "../../shared/api/iot/nodes/nodes.selectors";

export const getCurrentNodeId = (state, props) => props.match.params.sensorId;
export const getNewNode = (state) => state.ui.node.newNode;
export const getGranularity = (state) => state.ui.node.granularity;
export const getDuration = (state) => state.ui.node.duration;
export const getDateFrom = (state) => state.ui.node.dateFrom;
export const getDateTo = (state) => state.ui.node.dateTo;

export const getNodeByCurrentLocation = createSelector(
  getNodes,
  getCurrentLocation,
  (nodes, location) =>
    location ? nodes.find((node) => node.id === location.nodeId) : null,
);

export const getAllFetched = createSelector(getNodeByCurrentLocation, (node) =>
  node.sensors.every((item) => !item.isFetching),
);

export const getIsFetchingNode = (state) =>
  getIsFetchingNodesApi(state) ||
  getIsFetchingLocationsApi(state) ||
  getIsFetchingStatisticsApi(state);
