import React, { FC } from "react";

import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  cell: {
    width: 90,
    border: "1px solid",
    textAlign: "center",
  },
  firstCell: {
    width: 120,
    textAlign: "left",
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));

interface TableBodyRowPHProps {
  index: number;
  item: {
    alkaline: string;
    extremly_acid: string;
    medium_acid: string;
    name: string;
    neutral: string;
    slightly_acid: string;
    strongly_acid: string;
    strongly_alkaline: string;
  };
}

const TableBodyRowPH: FC<TableBodyRowPHProps> = ({ index, item }) => {
  const classes = useStyles();

  return (
    <TableRow key={index}>
      <TableCell
        classes={{ root: classNames(classes.cell, classes.firstCell) }}
      >
        <p className={classes.text}>{item.name}</p>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>
        {item.extremly_acid}
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>
        {item.strongly_acid}
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>{item.medium_acid}</TableCell>
      <TableCell classes={{ root: classes.cell }}>
        {item.slightly_acid}
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>{item.neutral}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{item.alkaline}</TableCell>
      <TableCell classes={{ root: classes.cell }}>
        {item.strongly_alkaline}
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRowPH;
