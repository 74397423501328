import React, { Component } from "react";

import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import Localization from "../../../../shared/services/Localization.service";
import ToolbarHoverStopper from "../ToolbarHoverStopper/ToolbarHoverStopper";

const styles = (theme) => ({
  inputWrapper: {
    paddingLeft: 14,
    paddingRight: 14,
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  inputRoot: {
    fontSize: "14px",
    marginLeft: 5,
    marginRight: 5,
    maxWidth: 50,
    fontWeight: 500,
  },
  input: {
    padding: "4px 0 2px",
  },
  toolbarSection: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  labelError: {
    color: theme.palette.error.main,
  },
});

export class BufferSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bufferSize: "",
      error: false,
    };
  }

  handleChange = (event) => {
    const {
      intl: { locale },
    } = this.props;
    const eventValue = event.target.value;
    this.setState({ bufferSize: eventValue });
    const numberValue = Localization.str2num(event.target.value, locale);
    if (eventValue === "") {
      this.props.setBufferSize(null);
      this.setState({
        error: false,
      });
    } else if (!isNaN(numberValue) && numberValue > 0) {
      this.props.setBufferSize(numberValue);
      this.setState({
        error: false,
      });
    } else {
      this.props.setBufferSize(0);
      this.setState({
        error: true,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { bufferSize, error } = this.state;

    return (
      <ToolbarSection classes={{ custom: classes.toolbarSection }}>
        <ToolbarHoverStopper />
        <div className={classes.inputWrapper}>
          <span
            className={`${classes.label} ${error ? classes.labelError : ""}`}
          >
            <FormattedMessage id="BufferSize.bufferSize" />
          </span>
          <TextField
            autoComplete="off"
            onChange={this.handleChange}
            value={bufferSize || ""}
            InputProps={{
              spellCheck: false,
              error,
              name: "buffer-size",
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
            }}
          />
          <span className={classes.label}>m</span>
        </div>
      </ToolbarSection>
    );
  }
}

BufferSize.propTypes = {
  classes: PropTypes.object.isRequired,
  setBufferSize: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(BufferSize));
