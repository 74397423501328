import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./telematicsCatalogues.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { GetApiParams } from "./telematicsCatalogues.types";
import { RsaaMethods, RsaaTypes } from "../../api.types";
import {
  DriverUpdateTo,
  EquipmentUpdateTo,
  MachineUpdateTo,
} from "../telematics.types";

export const getMachinesApi = (params: GetApiParams) => ({
  [RSAA]: {
    endpoint: `machines?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINES_REQUEST,
      types.GET_MACHINES_SUCCESS,
      types.GET_MACHINES_ERROR,
    ] as RsaaTypes,
  },
});

export const getEquipmentsApi = (params: GetApiParams) => ({
  [RSAA]: {
    endpoint: `equipments?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_EQUIPMENTS_REQUEST,
      types.GET_EQUIPMENTS_SUCCESS,
      types.GET_EQUIPMENTS_ERROR,
    ] as RsaaTypes,
  },
});

export const getDriversApi = (params: GetApiParams) => ({
  [RSAA]: {
    endpoint: `drivers?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_DRIVERS_REQUEST,
      types.GET_DRIVERS_SUCCESS,
      types.GET_DRIVERS_ERROR,
    ] as RsaaTypes,
  },
});

export const getProductionOperationsApi = (params: GetApiParams) => ({
  [RSAA]: {
    endpoint: `operations/production?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_PRODUCTION_OPERATIONS_REQUEST,
      types.GET_PRODUCTION_OPERATIONS_SUCCESS,
      types.GET_PRODUCTION_OPERATIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getMachineCategoriesApi = () => ({
  [RSAA]: {
    endpoint: "machines/categories?",
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINES_CATEGORIES_REQUEST,
      types.GET_MACHINES_CATEGORIES_SUCCESS,
      types.GET_MACHINES_CATEGORIES_ERROR,
    ] as RsaaTypes,
  },
});

export const getMachinesGroupsApi = () => ({
  [RSAA]: {
    endpoint: "machines/groups?",
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_MACHINES_GROUPS_REQUEST,
      types.GET_MACHINES_GROUPS_SUCCESS,
      types.GET_MACHINES_GROUPS_ERROR,
    ] as RsaaTypes,
  },
});

export const editMachineApi = (
  machineId: number,
  payload: MachineUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `machines/${machineId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.EDIT_MACHINE_REQUEST,
      types.EDIT_MACHINE_SUCCESS,
      types.EDIT_MACHINE_ERROR,
    ] as RsaaTypes,
  },
});

export const editEquipmentApi = (
  equipmentId: number,
  payload: EquipmentUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `equipments/${equipmentId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.EDIT_EQUIPMENT_REQUEST,
      types.EDIT_EQUIPMENT_SUCCESS,
      types.EDIT_EQUIPMENT_ERROR,
    ] as RsaaTypes,
  },
});

export const editDriverApi = (driverId: number, payload: DriverUpdateTo) => ({
  [RSAA]: {
    endpoint: `drivers/${driverId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.EDIT_DRIVER_REQUEST,
      types.EDIT_DRIVER_SUCCESS,
      types.EDIT_DRIVER_ERROR,
    ] as RsaaTypes,
  },
});
