import React, { FC } from "react";

import { Field, FormikProps } from "formik";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import * as validators from "../../../../shared/misc/validators";

import { DetailContentFormValues } from "../DetailContentForm.types";

interface Props extends FormikProps<DetailContentFormValues> {
  disabled?: boolean;
}

const DateField: FC<Props> = ({ disabled, errors }) => (
  <CfFormControl>
    <Field
      component={CfFormikDatePicker}
      disabled={disabled}
      disableFuture
      error={errors.date}
      label={<FormattedMessage id="TelematicsList.date" />}
      maxDate={moment().subtract(1, "days").startOf("day")}
      name="date"
      validate={validators.formikDateValidRequired}
    />
  </CfFormControl>
);

export default DateField;
