import React, { useContext, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getAgiStatus } from "../../../shared/api/sentinel/agiStatus/agiStatus.selectors";

import {
  createAgIntegrationApi,
  deleteAgIntegrationApi,
  getAgIntegrationSettingsApi,
  getAgIntegrationStatusApi,
} from "../../../shared/api/sentinel/agiStatus/agiStatus.api";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { AsyncFn, Thunk } from "../../../types";

import AgActivationDialog from "./fleetConnectorModals/AgActivationDialog";
import AgDeactivationDialog from "./fleetConnectorModals/AgDeactivationDialog";
import FleetConnectorTableRow from "./FleetConnectorTableRow";

import { UserState } from "../../../reducers/user.reducer.types";
import { getCreateAgIntegrationStatusApiParams } from "../../../shared/api/sentinel/agiStatus/agiStatus.types";

type ReduxProps = ConnectedProps<typeof connector>;

const FleetConnectorRow = ({
  agiStatus,
  createAgIntegrationApi,
  deleteAgIntegrationApi,
  getAgIntegrationSettingsApi,
  getAgIntegrationStatusApi,
}: ReduxProps) => {
  const showSnackbar = useContext(SnackbarContext);

  const {
    on: deactivationDialogOpen,
    setOff: closeDeactivationDialog,
    setOn: openDeactivationDialog,
  } = useToggle();
  const {
    on: activationDialogOpen,
    setOff: closeActivationDialog,
    setOn: openActivationDialog,
  } = useToggle();

  useEffect(() => {
    getAgIntegrationStatusApi();
  }, [getAgIntegrationStatusApi]);

  const handleAgDeactivationDialogAccept = () => {
    deleteAgIntegrationApi();
    closeDeactivationDialog();
  };

  const handleAgActivationDialogAccept = (name: string, email: string) => {
    const param: getCreateAgIntegrationStatusApiParams = {
      email,
      name,
    };
    (createAgIntegrationApi as AsyncFn<getCreateAgIntegrationStatusApiParams>)(
      param,
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage
              id="Agi.activationDialog.error"
              values={{ email: param.email }}
            />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: <FormattedMessage id="Agi.activationDialog.success" />,
        isSuccess: true,
      });
    });

    closeActivationDialog();
  };

  const handleAgIntegrationSetting = () => {
    (getAgIntegrationSettingsApi as AsyncFn)().then((res) =>
      window.open(res.payload.url, "_blank"),
    );
  };

  return (
    <>
      <FleetConnectorTableRow
        data={agiStatus}
        buttonOnClick={
          agiStatus.enabled ? handleAgIntegrationSetting : openActivationDialog
        }
        switchOnClick={
          agiStatus.enabled ? openDeactivationDialog : openActivationDialog
        }
      />
      <AgActivationDialog
        onAccept={handleAgActivationDialogAccept}
        onClose={closeActivationDialog}
        opened={activationDialogOpen}
      />
      <AgDeactivationDialog
        onAccept={handleAgDeactivationDialogAccept}
        onClose={closeDeactivationDialog}
        opened={deactivationDialogOpen}
      />
    </>
  );
};

const mapStateToProps = (state: UserState) => ({
  agiStatus: getAgiStatus(state),
});

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      createAgIntegrationApi: createAgIntegrationApi as (
        param: getCreateAgIntegrationStatusApiParams,
      ) => void,
      deleteAgIntegrationApi,
      getAgIntegrationStatusApi,
      getAgIntegrationSettingsApi: getAgIntegrationSettingsApi as () => void,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FleetConnectorRow);
