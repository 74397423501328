import { createSelector } from "reselect";

import {
  getActions,
  getIsFetching as isParcelActionsFetching,
} from "../../../shared/api/agroevidence/actions/actions.selectors";
import { getFarm } from "../../../shared/api/agroevidence/farms/farms.selectors";
import {
  getParcel,
  getIsFetchingParcel,
} from "../../../shared/api/agroevidence/parcels/parcels.selectors";

export const getService = (state, key) => state.ui.map[key];
export const getMainMap = (state) => getService(state, "main");
export const getInteraction = (state) => getService(state, "ia");
export const getEventListener = (state) => getService(state, "el");
export const getStyle = (state) => getService(state, "style");
export const getOverlay = (state) => getService(state, "overlay");
export const getLayers = (state) => getService(state, "layers");
export const getMarker = (state) => getService(state, "marker");
export const getWeather = (state) => getService(state, "weather");
export const getMainMapTelematics = (state) =>
  getService(state, "mainMapTelematics");
export const getContext = (state) => state.ui.map.context;
export const getSelectedSeasonIdSowingPlan = (state) =>
  state.ui.sowingPlan.selectedSeasonId;
export const getSelectedSeasonYearsSowingPlan = (state) =>
  state.ui.sowingPlan.selectedSeasonYears;

export const getParcelDetail = createSelector(
  getParcel,
  getActions,
  (parcel, actions) => {
    if (parcel) {
      return {
        ...parcel,
        lastAction: actions ? actions[0] : undefined,
      };
    }
    return undefined;
  },
);

export const isParcelDetailFetching = createSelector(
  getIsFetchingParcel,
  isParcelActionsFetching,
  (parcelFetching, parcelActionsFetching) =>
    parcelFetching && parcelActionsFetching,
);

export const getMapExtent = (state) => getFarm(state).boundingBox;
export const getIsFetching = (state) => state.ui.map.isFetching;
export const getIsInitialized = (state) => state.ui.map.isInitialized;
