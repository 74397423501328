import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./stores.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getStores = (params) => {
  const p = {
    page: params.page + 1,
    "per-page": params.rowsPerPage,
    "sort-dir": params.order,
    type: params.materialTypeId,
    nulls: params.showNullValues,
  };

  if (params.textFilter) {
    p.name = params.textFilter;
  }

  return {
    [RSAA]: {
      endpoint: `items?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.STORES,
      types: [
        types.GET_STORE_ITEMS,
        types.GET_STORE_ITEMS_SUCCESS,
        types.GET_STORE_ITEMS_ERROR,
      ],
    },
  };
};

export const getStore = (storeId) => ({
  [RSAA]: {
    endpoint: `items/${storeId}?`,
    method: methods.GET,
    module: modules.STORES,
    types: [
      types.GET_STORE_ITEM,
      types.GET_STORE_ITEM_SUCCESS,
      types.GET_STORE_ITEM_ERROR,
    ],
  },
});

export const deleteStoreItem = (itemId) => ({
  [RSAA]: {
    endpoint: `items/${itemId}?`,
    method: methods.DELETE,
    module: modules.STORES,
    types: [
      types.DELETE_STORE_ITEM,
      types.DELETE_STORE_ITEM_SUCCESS,
      types.DELETE_STORE_ITEM_ERROR,
    ],
  },
});

export const exportStores = (params) => ({
  [RSAA]: {
    endpoint: `items/export?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.STORES,
    types: [
      types.EXPORT_STORE_ITEMS,
      {
        type: types.EXPORT_STORE_ITEMS_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.EXPORT_STORE_ITEMS_ERROR,
    ],
  },
});

export const exportStoreItem = (storeId, params) => ({
  [RSAA]: {
    endpoint: `items/${storeId}/export?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.STORES,
    types: [
      types.EXPORT_STORE_ITEM,
      {
        type: types.EXPORT_STORE_ITEM_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.EXPORT_STORE_ITEM_ERROR,
    ],
  },
});

export const resetStores = () => ({
  type: types.RESET_STORES,
});
