import React, { Component } from "react";

import Popover from "@mui/material/Popover";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getIsFetching } from "../../../../../shared/api/agroevidence/farms/farms.selectors";
import { getFarmsSuggestions } from "../../selectors/manager.selectors";

import { getFarmsByName, resetFarms } from "../../actions/manager.actions";

import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import AdminFarmSelector from "../../../../shared/components/AdminFarmSelector/AdminFarmSelector";

const styles = {
  popoverPaper: {
    width: 350,
    height: 350,
    overflow: "hidden",
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
};

export class FarmSwitcher extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
    } = props;
    const label = formatMessage({ id: "FarmSwitcher.allFarms" });
    const noFarmLabel = formatMessage({ id: "common.no-farm" });
    this.farmOptions = [
      { id: null, code: "all", name: label?.toUpperCase() },
      { id: null, code: "none", name: noFarmLabel?.toUpperCase() },
    ];
  }

  onSuggestionSelect = (farm) => {
    this.props.onFarmSelect(farm);
    this.handlePopoverClose();
  };

  onSuggestionClear = () => {
    this.props.resetFarms();
  };

  handlePopoverClose = () => {
    this.props.handlePopoverClose();
    this.props.resetFarms();
  };

  renderSwitcherLabel = () => {
    const { allFarmOption, farm } = this.props;
    if (farm?.name === "Default") {
      return <FormattedMessage id="common.no-farm" />;
    }
    if (allFarmOption) {
      return !isEmpty(farm) && farm?.name !== "Default" ? (
        farm.name
      ) : (
        <FormattedMessage id="FarmSwitcher.allFarms" />
      );
    }
    return !isEmpty(farm) ? (
      farm.name
    ) : (
      <FormattedMessage id="FarmSwitcher.chooseFarm" />
    );
  };

  render() {
    const {
      allFarmOption,
      anchorEl,
      classes,
      handlePopoverOpen,
      isOpen,
      suggestions,
    } = this.props;
    const extendedSuggestions = suggestions.length
      ? this.farmOptions.concat(suggestions)
      : suggestions;
    return (
      <div>
        <CfSwitcherButton data-test="farm-switcher" onClick={handlePopoverOpen}>
          <span className={classes.switcherButtonLabel}>
            {this.renderSwitcherLabel()}
          </span>
        </CfSwitcherButton>
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.popoverPaper }}
          onClose={this.handlePopoverClose}
          open={isOpen}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <AdminFarmSelector
            getFarmsByName={this.props.getFarmsByName}
            isFetching={this.props.isFetchingFarms}
            onSuggestionClear={this.onSuggestionClear}
            onSuggestionSelect={this.onSuggestionSelect}
            suggestions={allFarmOption ? extendedSuggestions : suggestions}
          />
        </Popover>
      </div>
    );
  }
}

FarmSwitcher.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getFarmsByName: PropTypes.func.isRequired,
  resetFarms: PropTypes.func.isRequired,
  onFarmSelect: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  suggestions: PropTypes.array,
  farm: PropTypes.object,
  allFarmOption: PropTypes.bool,
  isFetchingFarms: PropTypes.bool.isRequired,
};

FarmSwitcher.defaultProps = {
  suggestions: [],
  onFarmSelect: () => {},
  farm: null,
  anchorEl: null,
  allFarmOption: false,
};

const mapStateToProps = (state) => ({
  suggestions: getFarmsSuggestions(state),
  isFetchingFarms: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFarmsByName,
      resetFarms,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withStyles(styles),
  withPopover,
)(FarmSwitcher);
