import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCrops,
  getIsFetchingCrops,
} from "../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import { fetchCrops } from "../../../../actions/catalogues.actions";

import { resetCropsApi } from "../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { CropTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues?: CropTo[] | CropTo;
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  onChange: (items: CropTo | CropTo[]) => void;
};
type Props = ReduxProps & OwnProps;

const CropsSelector = ({
  crops,
  defaultValues = [],
  disabled = false,
  error = false,
  fetchCrops,
  isCropsFetching,
  isMultiple = false,
  label,
  onChange,
  resetCropsApi,
}: Props) => {
  useEffect(() => {
    fetchCrops();

    return () => {
      resetCropsApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="crops-selector"
      isFetching={isCropsFetching}
      isMultiple={isMultiple}
      label={label}
      loadOptions={fetchCrops}
      onChange={onChange}
      suggestions={crops}
      testId="crops-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  crops: getCrops(state),
  isCropsFetching: getIsFetchingCrops(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchCrops,
      resetCropsApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CropsSelector);
