import { AnyAction } from "redux";

import * as types from "./drives.constants";
import * as telematicsListTypes from "../../../../telematics/actions/telematicsList.constants";

import { DrivesState } from "./drives.types";

const initialState: DrivesState = {
  items: [],
  isFetching: false,
  totalCount: 0,
  error: undefined,

  driveDetail: undefined,
  isFetchingDriveDetail: false,
  errorDriveDetail: undefined,

  operations: [],
  isFetchingOperations: false,
  errorOperations: undefined,

  productionOperations: [],
  isFetchingProductionOperations: false,
  errorProductionOperations: undefined,

  drivers: [],
  isFetchingDrivers: false,
  errorDrivers: undefined,

  machines: [],
  isFetchingMachines: false,
  errorMachines: undefined,

  equipments: [],
  isFetchingEquipments: false,
  errorEquipments: undefined,

  crops: [],
  isFetchingCrops: false,
  errorCrops: undefined,

  parcelSubject: [],
  isFetchingParcelSubject: false,
  errorParcelSubject: undefined,

  isExportingData: false,
  exportError: undefined,
  focusedRow: undefined,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case telematicsListTypes.SAGA_FETCH_DRIVES:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_DRIVES_REQUEST:
        return { ...state, isFetching: true };
      case types.GET_DRIVES_SUCCESS:
        return {
          ...state,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
          isFetching: false,
        };
      case types.GET_DRIVES_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_DRIVES: {
        return {
          ...state,
          items: initialState.items,
        };
      }

      case types.GET_DRIVE_DETAIL_REQUEST:
        return { ...state, isFetchingDriveDetail: true };
      case types.GET_DRIVE_DETAIL_SUCCESS:
        return {
          ...state,
          driveDetail: action.payload,
          isFetchingDriveDetail: false,
        };
      case types.GET_DRIVE_DETAIL_ERROR:
        return {
          ...state,
          isFetchingDriveDetail: false,
          errorDriveDetail: action.payload,
        };
      case types.RESET_DRIVE_DETAIL:
        return {
          ...state,
          driveDetail: undefined,
        };

      case types.GET_ACTION_TYPES_REQUEST:
        return { ...state, isFetchingActionTypes: true };
      case types.GET_ACTION_TYPES_SUCCESS:
        return {
          ...state,
          actionTypes: action.payload,
          isFetchingActionTypes: false,
        };
      case types.GET_ACTION_TYPES_ERROR:
        return {
          ...state,
          isFetchingActionTypes: false,
          errorActionTypes: action.payload,
        };
      case types.RESET_ACTION_TYPES:
        return {
          ...state,
          actionTypes: [],
        };

      case types.GET_OPERATIONS_REQUEST:
        return { ...state, isFetchingOperations: true };
      case types.GET_OPERATIONS_SUCCESS:
        return {
          ...state,
          operations: action.payload,
          isFetchingOperations: false,
        };
      case types.GET_OPERATIONS_ERROR:
        return {
          ...state,
          isFetchingOperations: false,
          errorOperations: action.payload,
        };
      case types.RESET_OPERATIONS:
        return {
          ...state,
          operations: [],
        };

      case types.GET_PRODUCTION_OPERATIONS_REQUEST:
        return { ...state, isFetchingProductionOperations: true };
      case types.GET_PRODUCTION_OPERATIONS_SUCCESS:
        return {
          ...state,
          productionOperations: action.payload,
          isFetchingProductionOperations: false,
        };
      case types.GET_PRODUCTION_OPERATIONS_ERROR:
        return {
          ...state,
          isFetchingOperations: false,
          errorOperations: action.payload,
        };
      case types.RESET_PRODUCTION_OPERATIONS:
        return {
          ...state,
          productionOperations: [],
        };

      case types.GET_DRIVERS_REQUEST:
        return { ...state, isFetchingDrivers: true };
      case types.GET_DRIVERS_SUCCESS:
        return {
          ...state,
          drivers: action.payload,
          isFetchingDrivers: false,
        };
      case types.GET_DRIVERS_ERROR:
        return {
          ...state,
          isFetchingDrivers: false,
          errorDrivers: action.payload,
        };
      case types.RESET_DRIVERS:
        return {
          ...state,
          drivers: [],
        };

      case types.GET_MACHINES_REQUEST:
        return { ...state, isFetchingMachines: true };
      case types.GET_MACHINES_SUCCESS:
        return {
          ...state,
          machines: action.payload,
          isFetchingMachines: false,
        };
      case types.GET_MACHINES_ERROR:
        return {
          ...state,
          isFetchingMachines: false,
          errorMachines: action.payload,
        };
      case types.RESET_MACHINES:
        return {
          ...state,
          machines: [],
        };

      case types.GET_EQUIPMENTS_REQUEST:
        return { ...state, isFetchingEquipments: true };
      case types.GET_EQUIPMENTS_SUCCESS:
        return {
          ...state,
          equipments: action.payload,
          isFetchingEquipments: false,
        };
      case types.GET_EQUIPMENTS_ERROR:
        return {
          ...state,
          isFetchingEquipments: false,
          errorEquipments: action.payload,
        };
      case types.RESET_EQUIPMENTS:
        return {
          ...state,
          equipments: [],
        };

      case types.GET_CROPS_REQUEST:
        return { ...state, isFetchingCrops: true };
      case types.GET_CROPS_SUCCESS:
        return {
          ...state,
          crops: action.payload,
          isFetchingCrops: false,
        };
      case types.GET_CROPS_ERROR:
        return {
          ...state,
          isFetchingCrops: false,
          errorCrops: action.payload,
        };
      case types.RESET_CROPS:
        return {
          ...state,
          crops: [],
        };

      case types.GET_DRIVE_SUBJECT_REQUEST:
        return { ...state, isFetchingDriveSubject: true };
      case types.GET_DRIVE_SUBJECT_SUCCESS:
        return {
          ...state,
          parcelSubject: action.payload,
          isFetchingpParcelSubject: false,
        };
      case types.GET_DRIVE_SUBJECT_ERROR:
        return {
          ...state,
          isFetchingpParcelSubject: false,
          errorParcelSubject: action.payload,
        };
      case types.RESET_DRIVE_SUBJECT:
        return {
          ...state,
          parcelSubject: [],
        };

      case types.EXPORT_DRIVES_REQUEST:
        return {
          ...state,
          isExportingData: true,
          exportError: undefined,
        };

      case types.EXPORT_DRIVES_SUCCESS:
        return {
          ...state,
          isExportingData: false,
        };

      case types.EXPORT_DRIVES_ERROR:
        return {
          ...state,
          isExportingData: false,
          exportError: action.payload,
        };

      case types.RESET_EXPORT_ERROR:
        return {
          ...state,
          exportError: undefined,
        };
      case types.SET_FOCUSED_ROW: {
        return {
          ...state,
          focusedRow: action.payload,
        };
      }
      default:
        return state;
    }
  };
