import * as types from "./notificationsAdmin.constants";

export default () =>
  (
    state = {
      isFetching: false,
      error: {},
      items: [],
      totalCount: 0,
    },
    action = {},
  ) => {
    switch (action.type) {
      case types.GET_ADMIN_FARMS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_ADMIN_FARMS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_ADMIN_FARMS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_ADMIN_FARMS:
        return {
          isFetching: false,
          error: {},
          items: [],
          totalCount: 0,
        };

      default:
        return state;
    }
  };
