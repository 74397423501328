import React, { useContext } from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setEnlargedVariant } from "../../actions/map.actions";
import {
  activateStart,
  activateCancel,
  activate,
  updateCoordsFromForm,
} from "../../actions/node.actions";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import DeviceActivation from "../../../shared/components/specific/DeviceActivation/DeviceActivation";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import withConfig from "../../../shared/hocs/context/withConfig";
import { useGetElevation } from "../../hooks/useGetElevation";

export function NodeActivation(props) {
  const { config, history, match, newNode, nodeLocation, oldNode } = props;

  const showSnackbar = useContext(SnackbarContext);

  const { getElevation } = useGetElevation({ key: config.api.bing_key });

  const activateSensor = async (name) => {
    let elevation = 0;
    if (newNode.deviceType === "METEO_BARANI_HELIX") {
      try {
        elevation = await getElevation(props.newNode.geometryWgs);
      } catch (e) {
        showSnackbar({
          message: (
            <FormattedMessage id="Sensors.activation.fetchElevation.error" />
          ),
          isError: true,
        });
      }
    }

    newNode.geometry.coordinates.push(elevation);
    delete props.newNode.geometryWgs;
    return props.activate(
      oldNode.id,
      name,
      newNode.geometry,
      match.params.farmId,
      nodeLocation.id,
    );
  };

  return (
    <DeviceActivation
      activate={activateSensor}
      activateCancel={props.activateCancel}
      activateStart={props.activateStart}
      buttonMessageId="NodeActivation.placeSensor"
      device={oldNode}
      deviceId={oldNode?.externalId}
      geometryWgs={newNode.geometryWgs}
      pageHeadingMessageId="NodeActivation.sensorPlacement"
      setEnlargedVariant={props.setEnlargedVariant}
      updateCoordsFromForm={props.updateCoordsFromForm}
      backButton={
        <CfBackButton
          onClick={() => {
            history.push(
              `/farm/${match.params.farmId}/sensors/${match.params.sensorId}`,
            );
          }}
        />
      }
    />
  );
}

NodeActivation.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  nodeLocation: PropTypes.object,
  oldNode: PropTypes.object,
  newNode: PropTypes.object,
  activateStart: PropTypes.func.isRequired,
  activateCancel: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  updateCoordsFromForm: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  config: PropTypes.object,
};

NodeActivation.defaultProps = {
  history: {},
  match: {},
  nodeLocation: {},
  oldNode: {},
  newNode: {},
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      activateStart,
      activateCancel,
      activate,
      updateCoordsFromForm,
      setEnlargedVariant,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withConfig()(NodeActivation));
