import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchEvapotranspirationData,
  fetchSensorData,
} from "../../actions/sensors.actions";

import CfChartView from "../../../shared/components/charts/CfChartView/CfChartView";
import withWidth from "../../../shared/hocs/withWidth";
import SensorChartCumulativeRainfall from "../../components/SensorChartCumulativeRainfall/SensorChartCumulativeRainfall";
import SensorChartEvapotranspiration from "../../components/SensorChartEvapotranspiration/SensorChartEvapotranspiration";
import SensorChartHeading from "../../components/SensorChartHeading/SensorChartHeading";
import SensorChartStatistics from "../../components/SensorChartStatistics/SensorChartStatistics";
import DeviceConfig from "../../services/DeviceConfig.service";
import FeatureData from "../../services/FeatureData.service";

const styles = {
  header: {
    minHeight: 36,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    alignItems: "center",
  },
};

export class NodeLocationChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      features: null,
      chartProps: {},
      isFetching: FeatureData.isDataFetched(props.chart.features),
      hasNullData: true,
    };
  }

  componentDidMount() {
    this.getSensorsData();
  }

  componentDidUpdate(prevProps) {
    const {
      chart,
      chart: { features, statistics, ...chartProps },
      dateFrom,
      dateTo,
      granularity,
    } = this.props;
    const { isFetching } = this.state;
    const {
      chart: prevChart,
      chart: { features: prevFeatures },
      dateFrom: prevDateFrom,
      dateTo: prevDateTo,
      granularity: prevGranularity,
    } = prevProps;

    if (
      granularity !== prevGranularity ||
      dateFrom !== prevDateFrom ||
      dateTo !== prevDateTo
    ) {
      this.getSensorsData();
    }

    const prevFinishedFetching = FeatureData.isDataFetched(prevFeatures);
    const nowFinishedFetching = FeatureData.isDataFetched(features);
    const hasSensors = FeatureData.featuresHasSensors(features);

    if (
      (prevFinishedFetching !== nowFinishedFetching ||
        isFetching !== !nowFinishedFetching) &&
      hasSensors
    ) {
      this.setState({
        isFetching: !nowFinishedFetching,
      });
    }

    if (prevChart !== chart && nowFinishedFetching) {
      const { tooltipProps, xAxisProps } = chartProps;
      const Tooltip = tooltipProps ? tooltipProps.content : null;
      const mergedData = FeatureData.mergeFeatureData(features);
      const updatedFeatures = features.map((feature) => {
        const { aggregation, sensors, ...restFeatureProps } = feature;
        return {
          ...restFeatureProps,
          aggregation,
          sensors: sensors.map((sensor) => {
            const { aggregations, data, ...restSensorProps } = sensor;
            return {
              ...restSensorProps,
            };
          }),
        };
      });

      this.setState({
        features: updatedFeatures,
        chartProps: {
          ...chartProps,
          xAxisProps: {
            ...(xAxisProps ? { ...xAxisProps } : {}),
            tickFormatter: (time) => this.timeTickFormatter(time),
          },
          ...(Tooltip
            ? {
                tooltipProps: {
                  ...tooltipProps,
                  content: (
                    <Tooltip features={features} granularity={granularity} />
                  ),
                },
              }
            : {}),
          data: mergedData,
          statistics: FeatureData.processFeatureStatistics(
            features,
            statistics,
          ),
        },
        hasNullData: this.hasNullData(mergedData, updatedFeatures),
      });
    }
  }

  getSensorsData() {
    const { chart, dateFrom, dateTo, granularity, location } = this.props;

    if (
      chart.name.startsWith("CHART_EVAPOTRANSPIRATION") &&
      location.evapotranspiration
    ) {
      this.props.fetchEvapotranspirationData(
        location.id,
        dateFrom,
        dateTo,
        granularity,
      );
      return;
    }

    chart.features.forEach((feature) => {
      const { aggregation, sensors } = feature;
      sensors.forEach((sensor) => {
        const { id, lastLocationId } = sensor;
        this.props.fetchSensorData(
          id,
          lastLocationId,
          dateFrom,
          dateTo,
          granularity,
          aggregation,
        );
      });
    });
  }

  timeTickFormatter(time) {
    const {
      duration,
      intl: { formatDate, formatTime },
    } = this.props;
    const dateTime = moment.unix(time).toISOString();
    return FeatureData.timeTickFormatter(
      dateTime,
      formatDate,
      formatTime,
      duration,
    );
  }

  hasNullData(data, features) {
    let hasNullData = false;
    if (data && data.length) {
      // if data does not contain some feature with at least one not null value
      hasNullData = !data.some((dataPoint) =>
        features.some((feature) => {
          const dataValue = dataPoint[feature.name];
          if (Array.isArray(dataValue)) {
            return dataValue.some(
              (itemValue) => itemValue !== null && itemValue !== undefined,
            );
          }
          return dataValue !== null && dataValue !== undefined;
        }),
      );
    }
    return hasNullData;
  }

  render() {
    const { chartProps, features, hasNullData, isFetching } = this.state;
    const {
      chart: { InfoDialog, icon: FeatureIcon, name, stroke, unit },
      classes,
      dateFrom,
      dateTo,
      totalEvapotranspiration,
      width,
    } = this.props;

    const chartHeaderStyle =
      width === "xs"
        ? { flexDirection: "column", alignItems: "start" }
        : { flexDirection: "row", alignItems: "center" };
    const isRainfall = DeviceConfig.isChartRainfall(chartProps?.name);
    const isEvapotranspiration = DeviceConfig.isChartEvapotranspiration(
      chartProps?.name,
    );
    return (
      <Fragment>
        <div className={classes.header} style={chartHeaderStyle}>
          <SensorChartHeading
            testId={`heading-${name}`}
            chartProps={
              !isEmpty(chartProps)
                ? chartProps
                : { name, stroke, icon: FeatureIcon, unit, InfoDialog }
            }
          />
          {isRainfall && (
            <SensorChartCumulativeRainfall
              chartProps={chartProps}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          )}
          {isEvapotranspiration && (
            <SensorChartEvapotranspiration
              chartProps={chartProps}
              totalEvapotranspiration={totalEvapotranspiration}
            />
          )}
          {chartProps?.statistics?.length > 0 && !isRainfall && (
            <SensorChartStatistics
              statistics={chartProps.statistics}
              testId={`chart-statistics-${name}`}
            />
          )}
        </div>
        <CfChartView
          chartProps={chartProps}
          features={features}
          hasNullData={hasNullData}
          isFetching={isFetching}
          testId={`chart-${name}`}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSensorData,
      fetchEvapotranspirationData,
    },
    dispatch,
  );

NodeLocationChart.propTypes = {
  intl: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  granularity: PropTypes.string.isRequired,
  chart: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchSensorData: PropTypes.func.isRequired,
  fetchEvapotranspirationData: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  totalEvapotranspiration: PropTypes.number,
};

NodeLocationChart.defaultProps = {
  totalEvapotranspiration: 0,
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(compose(withStyles(styles), withWidth())(NodeLocationChart)),
);
