import React, { FC } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {
  className: string;
  handleChange: (bool: boolean) => void;
}

type E = React.ChangeEvent<HTMLInputElement>;

const MapLayerControl: FC<Props> = ({ className, handleChange }) => (
  <FormControlLabel
    className={className}
    label={<FormattedMessage id="Irrigation.addToArea.map.layerControl" />}
    control={
      <Checkbox onChange={(e: E) => handleChange(e.currentTarget.checked)} />
    }
  />
);

export default MapLayerControl;
