export default class Hotjar {
  static boot() {
    if (window.hjSiteSettings) {
      window.hjSiteSettings.record = true;
    }
  }

  static shutdown() {
    if (window.hjSiteSettings) {
      window.hjSiteSettings.record = false;
    }
  }
}
