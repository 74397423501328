const PREFIX = "AGROEVIDENCE_CATALOGUES";

export const GET_SEEDS_REQUEST = `${PREFIX}/GET_SEEDS_REQUEST`;
export const GET_SEEDS_SUCCESS = `${PREFIX}/GET_SEEDS_SUCCESS`;
export const GET_SEEDS_ERROR = `${PREFIX}/GET_SEEDS_ERROR`;
export const RESET_SEEDS = `${PREFIX}/RESET_SEEDS`;

export const CREATE_SEED_REQUEST = `${PREFIX}/CREATE_SEED_REQUEST`;
export const CREATE_SEED_SUCCESS = `${PREFIX}/CREATE_SEED_SUCCESS`;
export const CREATE_SEED_ERROR = `${PREFIX}/CREATE_SEED_ERROR`;

export const UPDATE_SEED_REQUEST = `${PREFIX}/UPDATE_SEED_REQUEST`;
export const UPDATE_SEED_SUCCESS = `${PREFIX}/UPDATE_SEED_SUCCESS`;
export const UPDATE_SEED_ERROR = `${PREFIX}/UPDATE_SEED_ERROR`;
