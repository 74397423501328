import React, { useEffect } from "react";

import { TableBody } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getAccountFarms,
  getAccountFarmsTotalCount,
  isFetchingAccountFarms,
} from "../../../../shared/api/agroevidence/farms/admin/farmsAdmin.selectors";

import { fetchAccountFarms } from "../actions/farms.actions";

import { NAMESPACE } from "../reducer/farms.reducer";

import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../shared/misc/helper";
import DoubleLinedHeader from "../../../../telematics/components/DoubleLinedHeader/DoubleLinedHeader";
import { Thunk } from "../../../../types";
import {
  getFarmsAdminOrder,
  getFarmsAdminOrderBy,
  getFarmsAdminPage,
  getFarmsAdminRowsPerPage,
  getFarmsAdminTextFilter,
} from "../selectors/farms.selector";

import Row from "./Row";

import { AdminState } from "../../../../reducers/admin.reducer.types";

const COLUMNS = {
  name: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsAdmin.columns.farm"
      secondaryId="TelematicsAdmin.columns.id"
    />,
    { width: "300px" },
  ),
  countryCode: getColDesc(
    true,
    <FormattedMessage id="TelematicsAdmin.columns.country" />,
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;

const FarmsAdminTable = ({
  accountFarms,
  fetchAccountFarms,
  isFetchingFarms,
  order,
  orderBy,
  page,
  rowsPerPage,
  textFilter,
  totalCount,
}: ReduxProps) => {
  useEffect(() => {
    fetchAccountFarms();
  }, [order, orderBy, page, rowsPerPage, textFilter, fetchAccountFarms]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={COLUMNS}
        items={accountFarms}
        namespace={NAMESPACE}
        order={order}
        orderBy={orderBy}
      />
      {isFetchingFarms && <CfTableBodyLoader columns={COLUMNS} />}

      {!isFetchingFarms && accountFarms.length ? (
        <TableBody>
          {accountFarms.map((item) => (
            <Row item={item} key={item.id} />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} />
      )}

      <CfTableFooter
        count={totalCount}
        namespace={NAMESPACE}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: AdminState) => ({
  accountFarms: getAccountFarms(state),
  isFetchingFarms: isFetchingAccountFarms(state),
  order: getFarmsAdminOrder(state),
  orderBy: getFarmsAdminOrderBy(state),
  page: getFarmsAdminPage(state),
  rowsPerPage: getFarmsAdminRowsPerPage(state),
  totalCount: getAccountFarmsTotalCount(state),
  textFilter: getFarmsAdminTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) =>
  bindActionCreators(
    {
      fetchAccountFarms,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FarmsAdminTable);
