import React, { FC } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import IntegrationsTable from "./IntegrationsTable";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    margin: "10px 0px 15px 0px",
  },
  headerDescription: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 20,
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
}));

const IntegrationsList: FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.headerWrapper}>
        <Grid className={classes.header} container>
          <Grid className={classes.headerTitle} item>
            <FormattedMessage id="IntegrationSettings.title" />
          </Grid>
          <Grid className={classes.headerDescription} item>
            <FormattedMessage id="IntegrationSettings.description" />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12}>
            <IntegrationsTable />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default IntegrationsList;
