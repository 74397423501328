import React, { Component, Fragment } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import NewSubtractableAreaDialog from "../../containers/NewSubractableAreaDialog/NewSubtractableAreaDialog";

const styles = (theme) => ({
  button: {
    color: theme.palette.grey[400],
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
});

class ActionParcelSubtractableAreasSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAreaDialogOpen: false,
    };
  }

  setNewAreaDialogOpen = (state) => {
    this.setState({
      newAreaDialogOpen: state,
    });
  };

  render() {
    const {
      children,
      classes,
      handleAdd,
      isEditing,
      maxValue,
      parcelId,
      parcelIds,
      subtractableAreaType,
      subtractableAreas,
    } = this.props;
    return (
      <Fragment>
        <div className={classes.row}>
          <div className={classes.heading}>
            <FormattedMessage
              id={`SubtractableAreaDialog.${subtractableAreaType.toLowerCase()}`}
            />
          </div>
          <IconButton
            aria-label={`add ${subtractableAreaType.toLowerCase()} subtraction`}
            className={classes.button}
            disabled={!isEditing}
            onClick={() => this.setNewAreaDialogOpen(true)}
            size="large"
          >
            <AddCircleIcon />
          </IconButton>
        </div>
        {children}
        {this.state.newAreaDialogOpen && (
          <NewSubtractableAreaDialog
            handleClose={() => this.setNewAreaDialogOpen(false)}
            maxValue={maxValue}
            opened={this.state.newAreaDialogOpen}
            parcelId={parcelId}
            parcelIds={parcelIds}
            subtractableAreas={subtractableAreas}
            subtractableAreaType={subtractableAreaType}
            valueHa=""
            valueM=""
            onAccept={(value) => {
              handleAdd(value);
              this.setNewAreaDialogOpen(false);
            }}
          />
        )}
      </Fragment>
    );
  }
}

ActionParcelSubtractableAreasSection.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  maxValue: PropTypes.number.isRequired,
  isEditing: PropTypes.bool,
  subtractableAreas: PropTypes.array.isRequired,
  subtractableAreaType: PropTypes.string.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

ActionParcelSubtractableAreasSection.defaultProps = {
  classes: {},
  isEditing: false,
  parcelId: "",
};

export default withStyles(styles)(ActionParcelSubtractableAreasSection);
