import React, { FC } from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, Theme } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getCatalogueType } from "../../../shared/api/telematics/sectionStatus/sectionStatus.selector";
import withPopover from "../../../shared/hocs/withPopover";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { Catalogue } from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginRight: 12,
  },
  button: {
    borderRadius: "28px",
    padding: "0 4px 0 10px",
    display: "flex",
    lineHeight: "normal",
    letterSpacing: "0.108px",
    alignSelf: "flex-start",
  },
  buttonText: {
    paddingTop: 6,
    paddingBottom: 6,
    textWrap: "nowrap",
  },
  paper: {
    marginTop: 7,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  label: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginLeft: 10,
  },
}));

interface Props {
  anchorEl: HTMLElement;
  catalogueType: Catalogue;
  handlePopoverClose: () => void;
  handlePopoverOpen: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isOpen: boolean;
  selected: number;
  selectedRecords: string[];
  setMainSelected: (val: number) => void;
}

const BulkMainSelector: FC<Props> = ({
  anchorEl,
  catalogueType,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  selected,
  selectedRecords,
  setMainSelected,
}) => {
  const classes = useStyles();

  const onClickGeneral = (index: number) => {
    setMainSelected(index);
    handlePopoverClose();
  };

  const items = [
    {
      dataTest: "driver",
      messageId: "TelematicsAggregations.list.bulk.droplist.driver",
      visible: true,
    },
    {
      dataTest: "bonus",
      messageId: "TelematicsAggregations.list.bulk.droplist.bonus",
      visible: true,
    },
    {
      dataTest: "operation",
      messageId: "TelematicsAggregations.list.bulk.droplist.operation",
      visible: true,
    },
    {
      dataTest: "equipment",
      messageId: "TelematicsAggregations.list.bulk.droplist.equipment",
      visible: true,
    },
    {
      dataTest: "customerKey",
      messageId: "TelematicsAggregations.list.bulk.droplist.customerKey",
      visible: catalogueType === Catalogue.WINFAS,
    },
    {
      dataTest: "client",
      messageId: "TelematicsAggregations.list.bulk.droplist.client",
      visible: catalogueType === Catalogue.WINFAS,
    },
  ];

  return (
    <div className={classes.item}>
      <span className={classes.buttonWrapper}>
        <span className={classes.label}>
          <FormattedMessage id="TelematicsAggregations.list.bulk.label" />
        </span>
        <Button
          aria-label="telematics bulk button"
          className={classes.button}
          color="primary"
          disabled={selectedRecords.length === 0}
          onClick={handlePopoverOpen}
          variant="contained"
        >
          <span className={classes.buttonText}>
            <FormattedMessage
              id={items[selected].messageId}
              values={{ count: selectedRecords.length }}
            />
          </span>
          <ArrowDropDown />
        </Button>
      </span>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="telematics-drivers-bulk-menu"
        onClose={handlePopoverClose}
        open={isOpen}
        PaperProps={{
          className: classes.paper,
        }}
      >
        {items
          .filter((item) => item.visible)
          .map((item, i) => (
            <ListItem
              button
              data-test={item.dataTest}
              key={i}
              onClick={() => onClickGeneral(i)}
            >
              <ListItemText>
                <FormattedMessage id={item.messageId} values={{ count: 0 }} />
              </ListItemText>
            </ListItem>
          ))}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  catalogueType: getCatalogueType(state),
});

export default connect(mapStateToProps)(withPopover(BulkMainSelector));
