/* eslint-disable no-console */
import React from "react";

import { isBoolean } from "lodash";

import LocalStorage from "../services/LocalStorage.service";

import TOGGLES from "./toggles.const";

const isExistingToggle = (toggleId) => !!TOGGLES[toggleId];
const isPermanentToggle = (toggleId) => TOGGLES[toggleId].permanent;

const validateInput = (toggleId, value) => {
  if (!isExistingToggle(toggleId)) {
    console.error("Toggle does not exist, try again");
    return false;
  }
  if (isPermanentToggle(toggleId)) {
    console.error("Toggle cannot be modified");
    return false;
  }
  if (!isBoolean(value)) {
    console.error("Toggle value is not valid or missing, use boolean");
    return false;
  }
  return true;
};

const useToggles = () => {
  const permanentToggles = {};
  const changeableToggles = {};

  Object.keys(TOGGLES).forEach((toggleId) => {
    if (isPermanentToggle(toggleId)) {
      permanentToggles[toggleId] = TOGGLES[toggleId].active;
    } else {
      changeableToggles[toggleId] = TOGGLES[toggleId].active;
    }
  });

  const userSavedToggles = LocalStorage.loadFromLocalStorage("toggles");

  const [activeToggles, setActiveToggles] = React.useState({
    ...changeableToggles,
    ...userSavedToggles,
    ...permanentToggles,
  });

  const setToggle = (toggleId, value) => {
    if (!validateInput(toggleId, value)) return;

    const updatedToggles = { ...activeToggles, [toggleId]: value };
    setActiveToggles(updatedToggles);
    LocalStorage.saveToLocalStorage(updatedToggles, "toggles");

    setTimeout(() => location.reload(), 2000);
    console.info("Toggle set sucessfully!");
    return TOGGLES[toggleId].description;
  };

  const isToggleActive = (toggleId) => activeToggles[toggleId];

  return [activeToggles, setToggle, isToggleActive];
};

export const TogglesContext = React.createContext();

export default useToggles;
