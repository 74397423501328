/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from "angular";
import tpl from "./pors.html";
import PorsController from "./pors.controller";

import por from "./por/por.module";

export default angular
  .module("app.components.farm.classifiers.private.pors", [por])
  .controller("PorsController", PorsController)
  .config(config).name;

/*@ngInject*/
function config($stateProvider) {
  $stateProvider.state("farm.active.classifiers.private.pors", {
    url: "/pors",
    views: {
      pors: {
        controller: "PorsController as PorsCtrl",
        template: tpl,
      },
    },
  });
}
