import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcelActivities,
  getParcelActivitiesOrder,
  getParcelActivitiesOrderBy,
  getParcelActivitiesPage,
  getParcelActivitiesTotalCount,
  getParcelActivitiesRowsPerPage,
  getParcelActivitiesSelected,
  getSelectedOnPage,
  getParcelActivitiesDateFilter,
  getParcelActivitiesAdvancedFilter,
  getProcessedActivities,
} from "../../selectors/parcelActivities.selectors";

import {
  fetchParcelActivities,
  resetParcelActivities,
} from "../../actions/parcelActivities.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelActivities.reducer";

import CfTableBody from "../../../../../../shared/components/tables/CfTableBody/CfTableBody";
import CfTableBodyEmpty from "../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../../../shared/misc/helper";
import { COLOR_GREY } from "../../../../../../theme";
import { Thunk } from "../../../../../../types";

import { ActionsState } from "../../../../../../reducers/actions.reducer.types";
import { ParcelActivitiesListAdvancedFilter } from "../../../../../../shared/api/agroevidence/parcels/parcels.types";
import { Order } from "../../../../../../shared/api/api.types";

const ELEMENT_UNIT = "(kg/ha)   ";

const columns = {
  "dateStart,id": getColDesc(true, <FormattedMessage id="common.date" />),
  "actionType.localizedNames.value": getColDesc(
    false,
    <FormattedMessage id="common.action-name" />,
  ),
  source: getColDesc(false, <FormattedMessage id="common.source" />),
  activityCrop: getColDesc(false, <FormattedMessage id="common.crop" />),
  area: getColDesc(
    false,
    <span>
      <div>
        <FormattedMessage id="common.activityArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.parcelArea" />
      </div>
    </span>,
    undefined,
    "right",
  ),
  material: getColDesc(
    false,
    <FormattedMessage id="common.material" />,
    undefined,
    "right",
  ),
  dose: getColDesc(
    false,
    <FormattedMessage id="common.totalDose" />,
    undefined,
    "right",
  ),
  dosePerHectare: getColDesc(
    false,
    <FormattedMessage id="common.dosePerHectare" />,
    undefined,
    "right",
  ),
  n: getColDesc(
    false,
    <span>
      <FormattedMessage id="common.accountableN" />
      {` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  p2o5: getColDesc(
    false,
    <span>
      P<sub>2</sub>O<sub>5</sub>
      {` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  k2o: getColDesc(
    false,
    <span>
      K<sub>2</sub>O{` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  mgo: getColDesc(false, `MgO ${ELEMENT_UNIT}`, undefined, "right"),
  cao: getColDesc(false, `CaO ${ELEMENT_UNIT}`, undefined, "right"),
  s: getColDesc(false, `S ${ELEMENT_UNIT}`, undefined, "right"),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  advancedFilter: ParcelActivitiesListAdvancedFilter;
  farmId: string;
  parcelId: string;
};
type Props = ReduxProps & OwnProps;

export const ParcelActivitiesTable = ({
  activities,
  advancedFilter = {},
  count,
  dateFilter,
  farmId,
  fetchParcelActivities,
  isFetching,
  order,
  orderBy,
  page,
  parcelId,
  resetParcelActivities,
  rowsPerPage,
  selected,
  selectedOnPage,
}: Props) => {
  const history = useHistory();

  useEffect(
    () => () => {
      resetParcelActivities();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchParcelActivities(parcelId, false);
    // eslint-disable-next-line max-len
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    dateFilter,
    parcelId,
    fetchParcelActivities,
    advancedFilter,
  ]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={activities}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {activities.length && !isFetching ? (
        // @ts-expect-error row is of type any
        activities.map((row) => (
          <CfTableBody
            columns={columns}
            hover
            key={row.id}
            namespace={namespace}
            row={row}
            selected={selected}
            onClick={() => {
              history.push(`/farm/${farmId}/actions/${row.id}`);
              history.go(0);
            }}
          />
        ))
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  activities: getProcessedActivities(state),
  isFetching: getIsFetchingParcelActivities(state),
  count: getParcelActivitiesTotalCount(state),
  page: getParcelActivitiesPage(state),
  order: getParcelActivitiesOrder(state) as Order,
  orderBy: getParcelActivitiesOrderBy(state),
  rowsPerPage: getParcelActivitiesRowsPerPage(state),
  selected: getParcelActivitiesSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  dateFilter: getParcelActivitiesDateFilter(state),
  advancedFilter: getParcelActivitiesAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      fetchParcelActivities,
      resetParcelActivities,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ParcelActivitiesTable);
