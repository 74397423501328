/**
 * Created by ondrejzvara on 9.3.16.
 */

/* @ngInject */

export default function cfCropSelector() {
  const directive = {
    restrict: "E",
    template: require("./cf-crop-selector.html"),
    require: ["ngModel", "cfClassifierSelector"],
    bindToController: {
      model: "=ngModel",
      external: "=?",
      onStateChange: "=?", // EMPTY, TYPING, SELECTED, CREATE
      disabled: "=?",
    },
    controller: cfCropSelectorController,
    controllerAs: "cfCropSelectorCtrl",
  };

  return directive;
}

/* @ngInject */
function cfCropSelectorController(CropsResource) {
  const vm = this;

  vm.getCropsByName = getCropsByName;

  function getCropsByName(search) {
    return CropsResource.searchCrops(
      {},
      {
        page: 1,
        count: 500,
        search,
        external: vm.external,
      },
    ).then((result) => result.plain());
  }
}
