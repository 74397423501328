import { AnyAction } from "redux";

import * as farmTypes from "../farms.constants";

import { FarmsAdminApiState } from "./farmsAdmin.types";

export const NAMESPACE = "farms-admin-api";

const initialState: FarmsAdminApiState = {
  farms: [],
  isFetching: false,
  totalCount: 0,
};

const FarmsAdminReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case farmTypes.GET_FARMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case farmTypes.GET_FARMS_SUCCESS:
      return {
        ...state,
        farms: action.payload,
        isFetching: false,
        totalCount: Number(action.meta.headers.get("X-Total-Count")),
      };
    case farmTypes.GET_FARMS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default FarmsAdminReducer;
