/* eslint-disable indent */
import React from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styleSheet = {
  textField: {
    marginTop: 0,
  },
  label: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: "none",
    transform: "translate(0px, 16px) scale(1)",
  },
  labelShrink: {
    transform: "translate(0px, 0px) scale(0.75)",
  },
  hintText: {
    marginLeft: 0,
  },
};

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      visible: false,
    };
  }

  /**
   * Togles the visibility the password.
   * @public
   */
  toggleVisibility() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  handleInputFocus(event) {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  handleInputBlur(event) {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  render() {
    const { errorText, hasIconButton, hintText, name, value } = this.props;

    const { visible } = this.state;

    const classes = this.props.classes;
    const actualErrorText = errorText || hintText;

    return (
      <div className={classes.root}>
        <FormControl
          className={classes.textField}
          error={!!errorText}
          fullWidth={this.props.fullWidth}
          margin="dense"
        >
          <InputLabel
            classes={{
              root: classes.label,
              shrink: classes.labelShrink,
            }}
          >
            {this.props.label}
          </InputLabel>
          <Input
            autoComplete="new-password"
            autoFocus={this.props.autoFocus}
            fullWidth={this.props.fullWidth}
            name={name}
            onBlur={(e) => this.handleInputBlur(e)}
            onChange={(e) => this.props.onChange(e)}
            onFocus={(e) => this.handleInputFocus(e)}
            type={visible ? "text" : "password"}
            value={value}
            endAdornment={
              hasIconButton && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.toggleVisibility()}
                    onMouseDown={(e) => e.preventDefault()}
                    size="large"
                  >
                    {visible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          <FormHelperText classes={{ root: classes.hintText }}>
            {actualErrorText}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  errorText: PropTypes.any,
  hintText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hasIconButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
};

PasswordField.defaultProps = {
  name: "password",
  label: "",
  errorText: "",
  hintText: "",
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  hasIconButton: true,
  fullWidth: false,
  autoFocus: false,
  classes: {},
  value: "",
};

export default withStyles(styleSheet)(PasswordField);
