/**
 * Created by ondrejzvara on 2.12.16.
 */

export function ifFilter() {
  return function (input, dependent, patternF) {
    patternF = patternF ? patternF : "";
    return _.isUndefined(dependent) || _.isNull(dependent) ? patternF : input;
  };
}
