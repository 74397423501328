import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { StoresState } from "../../../../../reducers/stores.reducer.types";

export const getPlantProtection = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.plantProtection.items;
export const getIsFetching = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.plantProtection.isFetching;
export const getPlantProtectionCount = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.totalCount;

export const getActiveSubstances = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.activeSubstances;
export const getIsFetchingActiveSubstances = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingActiveSubstances;

export const getOrganisms = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.organisms;
export const getIsFetchingOrganisms = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingOrganisms;

export const getBioFunction = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.bioFunction;
export const getIsFetchingBioFunction = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingBioFunctions;
