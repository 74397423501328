import React, { Fragment } from "react";

import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import SensorsService from "../../../shared/services/Sensors.service";

const style = {
  icon: {
    marginRight: 3,
  },
};

function NodeLocationGroupsInfo(props) {
  const { classes, displayIcon, nodeLocation } = props;
  const isHistoric = SensorsService.isHistoric(nodeLocation);

  return (
    !isHistoric && (
      <Fragment>
        {displayIcon && <GroupWorkIcon className={classes.icon} />}
        {nodeLocation.groups && nodeLocation.groups.length
          ? nodeLocation.groups.map((group) => group.name).join(", ")
          : "-"}
      </Fragment>
    )
  );
}

NodeLocationGroupsInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeLocation: PropTypes.object,
  displayIcon: PropTypes.bool,
};

NodeLocationGroupsInfo.defaultProps = {
  nodeLocation: {},
  displayIcon: true,
};

export default withStyles(style)(NodeLocationGroupsInfo);
