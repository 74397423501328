import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import { Fill, Stroke, Style, Circle, Text } from "ol/style";

import * as sensorsStatus from "../../shared/constants/sensorsStatus.constants";

const NODE_LOCATIONS_COLORS = {
  HOVER_COLOR: "rgba(255, 255, 255, 0.40)",
  SELECT_COLOR: "rgba(255, 255, 255, 0.65)",
  TEXT_COLOR: "#000000",
  CLUSTER_FILL_COLOR: "#FFFFFF",
  HISTORIC_FILL_COLOR: "#5E15A7",
  HISTORIC_STROKE_COLOR: "#B172F1",
  ACTIVE_FILL_COLOR: "#7ED321",
  ACTIVE_STROKE_COLOR: "#BEF284",
  ERROR_FILL_COLOR: "#DB4437",
  ERROR_STROKE_COLOR: "#FF948B",
  TRANSFER_FILL_COLOR: "#EBA607",
  TRANSFER_STROKE_COLOR: "#FCD783",
};

const NODE_LOCATIONS_SIZES = {
  CIRCLE_RADIUS: 7,
  STROKE_WIDTH: 2,
  SELECTED_STROKE_WIDTH: 3,
  LABEL_TEXT_STROKE: 4,
  CLUSTER_CIRCLE_RADIUS: 15,
  CLUSTER_STROKE_WIDTH: 4,
  HOVER_RADIUS: 13,
  CLUSTER_HOVER_RADIUS: 23,
};

export default class SensorsMapStylesService {
  constructor() {
    this.hoveredFeatureId = null;
    this.selectedFeatureId = null;
  }

  setParcelsStyle() {
    const fill = new Fill({
      color: [255, 255, 255, 0.15],
    });
    const stroke = new Stroke({
      color: [255, 255, 255, 1],
      width: 1,
    });
    return new Style({
      fill,
      stroke,
    });
  }

  setHoverNodesStyle(cluster = false) {
    const hoverFill = new Fill({
      color: NODE_LOCATIONS_COLORS.HOVER_COLOR,
    });
    return new Style({
      image: new Circle({
        fill: hoverFill,
        radius: cluster
          ? NODE_LOCATIONS_SIZES.CLUSTER_HOVER_RADIUS
          : NODE_LOCATIONS_SIZES.HOVER_RADIUS,
      }),
    });
  }

  setSelectedNodesStyle() {
    const selectFill = new Fill({
      color: NODE_LOCATIONS_COLORS.SELECT_COLOR,
    });
    return new Style({
      image: new Circle({
        fill: selectFill,
        radius: NODE_LOCATIONS_SIZES.HOVER_RADIUS,
      }),
    });
  }

  getNodeLocationFillColor(status) {
    let color;
    switch (status) {
      case sensorsStatus.ACTIVE:
        color = NODE_LOCATIONS_COLORS.ACTIVE_FILL_COLOR;
        break;
      case sensorsStatus.HISTORICAL:
        color = NODE_LOCATIONS_COLORS.HISTORIC_FILL_COLOR;
        break;
      case sensorsStatus.ERROR:
        color = NODE_LOCATIONS_COLORS.ERROR_FILL_COLOR;
        break;
      case sensorsStatus.TRANSFER:
        color = NODE_LOCATIONS_COLORS.TRANSFER_FILL_COLOR;
        break;
      default:
        color = NODE_LOCATIONS_COLORS.ACTIVE_FILL_COLOR;
    }
    return color;
  }

  getNodeLocationStrokeColor(status) {
    let color;
    switch (status) {
      case sensorsStatus.ACTIVE:
        color = NODE_LOCATIONS_COLORS.ACTIVE_STROKE_COLOR;
        break;
      case sensorsStatus.HISTORICAL:
        color = NODE_LOCATIONS_COLORS.HISTORIC_STROKE_COLOR;
        break;
      case sensorsStatus.ERROR:
        color = NODE_LOCATIONS_COLORS.ERROR_STROKE_COLOR;
        break;
      case sensorsStatus.TRANSFER:
        color = NODE_LOCATIONS_COLORS.TRANSFER_STROKE_COLOR;
        break;
      default:
        color = NODE_LOCATIONS_COLORS.ACTIVE_STROKE_COLOR;
    }
    return color;
  }

  setDefaultNodesStyle(status, nodeLocationName, selected = false) {
    const fillColor = this.getNodeLocationFillColor(status);
    const strokeColor = this.getNodeLocationStrokeColor(status);

    const fill = new Fill({
      color: fillColor,
    });

    const stroke = new Stroke({
      color: strokeColor,
      width: selected
        ? NODE_LOCATIONS_SIZES.SELECTED_STROKE_WIDTH
        : NODE_LOCATIONS_SIZES.STROKE_WIDTH,
    });

    return new Style({
      image: new Circle({
        fill,
        stroke,
        radius: NODE_LOCATIONS_SIZES.CIRCLE_RADIUS,
      }),
      text: new Text({
        text: nodeLocationName,
        font: "500 12px Roboto, sans-serif",
        fill: new Fill({
          color: NODE_LOCATIONS_COLORS.TEXT_COLOR,
        }),
        offsetY: -18,
        stroke: new Stroke({
          color: strokeColor,
          width: NODE_LOCATIONS_SIZES.LABEL_TEXT_STROKE,
        }),
      }),
    });
  }

  getClusterStrokesStyles(features) {
    const featureGroups = groupBy(features, (feature) => feature.get("status"));
    const strokesStyles = [];
    let offset = 0;
    const CIRCUMFERENCE = Math.ceil(
      NODE_LOCATIONS_SIZES.CLUSTER_CIRCLE_RADIUS * 2 * Math.PI,
    );
    forEach(featureGroups, (value, key) => {
      const circleArcRatio = value.length / features.length;
      strokesStyles.push(
        new Style({
          image: new Circle({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0)",
            }),
            stroke: new Stroke({
              color: this.getNodeLocationFillColor(key),
              width: NODE_LOCATIONS_SIZES.CLUSTER_STROKE_WIDTH,
              lineDash: [
                Math.ceil(circleArcRatio * CIRCUMFERENCE),
                Math.ceil((1 - circleArcRatio) * CIRCUMFERENCE),
              ],
              lineCap: "butt",
              lineDashOffset: -Math.ceil(offset * CIRCUMFERENCE),
            }),
            radius: NODE_LOCATIONS_SIZES.CLUSTER_CIRCLE_RADIUS,
          }),
        }),
      );
      offset += circleArcRatio;
    });
    return strokesStyles;
  }

  setClusteredNodeLocationsStyle(features) {
    const strokesStyles = this.getClusterStrokesStyles(features);
    const styles = [
      new Style({
        image: new Circle({
          fill: new Fill({
            color: NODE_LOCATIONS_COLORS.CLUSTER_FILL_COLOR,
          }),
          stroke: new Stroke({
            color: NODE_LOCATIONS_COLORS.CLUSTER_FILL_COLOR,
            width: NODE_LOCATIONS_SIZES.CLUSTER_STROKE_WIDTH,
          }),
          radius: NODE_LOCATIONS_SIZES.CLUSTER_CIRCLE_RADIUS - 1,
        }),
        text: new Text({
          text: features.length.toString(),
          font: "500 13px Roboto, sans-serif",
          fill: new Fill({
            color: NODE_LOCATIONS_COLORS.TEXT_COLOR,
          }),
          offsetY: 1,
        }),
      }),
      ...strokesStyles,
    ];

    return styles;
  }

  setNodesStyle = (feature) => {
    const features = feature.get("features");
    const size = features.length;
    let styles;

    if (size > 1) {
      const featureIds = features.map((featureItem) => featureItem.get("id"));
      styles = this.setClusteredNodeLocationsStyle(features);
      if (this.hoveredFeatureId && this.isHovered(featureIds)) {
        styles = [this.setHoverNodesStyle(true), ...styles];
      }
    } else {
      const wrappedFeature = feature.get("features")[0];
      const featureId = wrappedFeature.get("id");
      const isFeatureSelected =
        this.selectedFeatureId && this.isSelected(featureId);
      const status = wrappedFeature.get("status");
      const name = wrappedFeature.get("name");
      styles = this.setDefaultNodesStyle(status, name, isFeatureSelected);

      if (isFeatureSelected) {
        styles = [this.setSelectedNodesStyle(), styles];
      } else if (this.hoveredFeatureId && this.isHovered([featureId])) {
        styles = [this.setHoverNodesStyle(), styles];
      }
    }

    return styles;
  };

  isHovered(featureIds) {
    return featureIds.includes(this.hoveredFeatureId);
  }

  isSelected(featureId) {
    return this.selectedFeatureId === featureId;
  }

  setHoveredFeatureId(id) {
    this.hoveredFeatureId = id;
  }

  setSelectedFeatureId(id) {
    this.selectedFeatureId = id;
  }
}
