import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import reducer from "../../reducers/signup.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";

import SignupWrapper from "./containers/SignupWrapper/SignupWrapper";

SignupController.$inject = [
  "$element",
  "$state",
  "$stateParams",
  "$scope",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Localization",
  "Auth",
];

export default function SignupController(
  $element,
  $state,
  $stateParams,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
) {
  const props = {
    ngChangeLanguage,
    ngGetCurrentLangIdAsync,
    ngRedirectToLogin,
    ngRedirectToFarm,
    ngLogin,
    ngRefreshLogin,
    ngLogoutNoRedirect,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      langId: null,
      beOpts: BACKEND_OPTIONS,
      displayErrorModal: false,
      logout: Auth.logout,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function ngGetCurrentLangIdAsync() {
    return Localization.getCurrentLangIdAsync();
  }

  function ngChangeLanguage(langId) {
    return Localization.setCurrLangId(langId);
  }

  function ngRedirectToLogin() {
    $state.go("login");
  }

  function ngRedirectToFarm() {
    $state.go("farm");
  }

  function ngLogin(credentials) {
    return Auth.login(credentials);
  }

  function ngRefreshLogin() {
    return Auth.refreshLogin();
  }

  function ngLogoutNoRedirect() {
    return Auth.clearAllUserData(false);
  }

  function renderWithLang() {
    ngGetCurrentLangIdAsync().then((langId) => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function render() {
    $element[0].click();
    ReactDOM.render(
      <CfApp {...props} render={(p) => <SignupWrapper {...p} />} />,
      $element[0],
    );
  }
}
