/* eslint-disable @typescript-eslint/no-explicit-any */

import { Action, AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";
import {
  call,
  put,
  putResolve,
  takeLatest,
  Effect,
  select,
  all,
  delay,
} from "redux-saga/effects";

import * as layersSelectors from "../core/map/selectors/layers.selectors";
import * as telMapSelectors from "../core/map/selectors/mainMapTelematics.selectors";
import * as mainMapSelectors from "../core/map/selectors/map.selectors";
import * as telMapApiSelectors from "../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors";

import { fetchCrops } from "../core/map/actions/crops/crops.actions";
import * as layersUiActions from "../core/map/actions/layersUI/layersUI.actions";
import * as telMapActions from "../core/map/actions/mainMapTelematics/mainMapTelematics.actions";
import * as mainMapActions from "../core/map/actions/map/map.actions";
import { fetchSeasonSowingPlansMainMap } from "../core/map/actions/sowingPlan/sowingPlan.actions";
import * as weatherActions from "../core/map/actions/weather/weather.actions";
import * as langActions from "../shared/actions/lang.actions";
import * as statusActions from "../shared/api/telematics/sectionStatus/sectionStatus.actions";

import * as telMapTypes from "../core/map/actions/mainMapTelematics/mainMapTelematics.constants";
import * as mainMapTypes from "../core/map/actions/map/map.constants";
import * as sowingPlanMapTypes from "../core/map/actions/sowingPlan/sowingPlan.constants";
import {
  PREVIEW,
  TELEMATICS,
  // WEATHER
} from "../core/map/constants/contexts.constants";
import * as sowingPlanTypes from "../shared/api/agroevidence/sowingPlan/sowingPlan.constants";

import {
  MAP_ID,
  MAP_SRID_ID,
  DATA_SRID_ID,
} from "../core/map/containers/MainMap/MainMap";
import EventListener from "../core/map/services/EventListener.service";
import Interaction from "../core/map/services/Interaction.service";
import Layers from "../core/map/services/Layers.service";
import MainMapTelematics from "../core/map/services/MainMapTelematics.service";
import MapService from "../core/map/services/Map.service";
import Marker from "../core/map/services/Marker.service";
import Overlay from "../core/map/services/Overlay.service";
import Style from "../core/map/services/Style.service";
// import Weather from '../core/map/services/Weather.service';
import { getLpisLayerName } from "../shared/api/api.helpers";
import { fetchLayersConfig } from "../shared/api/other/layers/layers.api";
import {
  isTelematicSectionAllowed,
  isTelematicSectionVerified,
} from "../shared/api/telematics/sectionStatus/sectionStatus.selector";
import LocalStorage from "../shared/services/LocalStorage.service";
import { Thunk } from "../types";

import { MainMapState } from "../reducers/map.reducer.types";
import {
  PositionDetailTo,
  DailyPositionTo,
  MachineTo,
  DriverTo,
} from "../shared/api/telematics/telematics.types";

export const MAP_CONTEXT_LS_KEY = "MAP_CONTEXT";
const TELEMATICS_UPDATE_INTERVAL = 90000;

const TRANSFORM_OPTIONS = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};
interface InitMapAction extends AnyAction {
  api: Record<string, string>;
  countryCode: string;
  environment: string;
  farmId: string;
  langId: string;
  parcelId?: string;
}

function* initMap(dispatch: Thunk<MainMapState>, action: InitMapAction) {
  const { api, countryCode, environment, farmId, langId, parcelId } = action;
  api.lpisLayerName = getLpisLayerName(countryCode, environment);
  yield put(
    // @ts-expect-error no clue how to type this
    mainMapActions.setMapFetching(true) as unknown as Action<RSAAAction>,
  );
  yield put(langActions.setLangId(langId));
  yield putResolve(
    // @ts-expect-error no clue how to type this
    statusActions.getTelematicsStatus() as unknown as Action<RSAAAction>,
  );
  // @ts-expect-error no clue how to type this
  yield put(fetchLayersConfig(countryCode) as unknown as Action<RSAAAction>);

  yield putResolve(
    // @ts-expect-error no clue how to type this
    mainMapActions.fetchBoundingBox(farmId) as unknown as Action<RSAAAction>,
  );
  const mapExtent: Record<string, unknown> = yield select(
    mainMapSelectors.getMapExtent,
  );

  const map = new MapService(MAP_ID, farmId, mapExtent, TRANSFORM_OPTIONS);
  const ia = new Interaction(map.getMap(), TRANSFORM_OPTIONS);
  const el = new EventListener(map.getMap());
  const overlay = new Overlay(map.getMap());
  const marker = new Marker(map.getMap(), TRANSFORM_OPTIONS);

  yield put(mainMapActions.storeServiceWrapper("main", map));
  yield put(mainMapActions.storeServiceWrapper("el", el));
  yield put(mainMapActions.storeServiceWrapper("ia", ia));
  yield put(mainMapActions.storeServiceWrapper("overlay", overlay));
  yield put(mainMapActions.storeServiceWrapper("marker", marker));

  // LAYERS
  const layersConfig: Record<string, unknown> = yield select(
    layersSelectors.getLayersConfig,
  );
  const layers = new Layers(map.getMap(), api, farmId, map.getFarmExtent());
  layers.setInitialLayers(layersConfig, (layers: Record<string, string>) =>
    dispatch(layersUiActions.storeInitialLayers(layers)),
  );

  yield put(mainMapActions.storeServiceWrapper("layers", layers));

  // STYLES
  const style = new Style(
    layers.getParcelLayer(),
    layers.getParcelLabelLayer(),
    countryCode,
  );
  yield put(mainMapActions.storeServiceWrapper("style", style));

  yield put(mainMapActions.zoomToFarm() as unknown as AnyAction);

  if (parcelId) {
    yield put(
      mainMapActions.activateParcelDetail(parcelId) as unknown as AnyAction,
    );
  }

  let contextLs: typeof TELEMATICS | typeof PREVIEW =
    LocalStorage.loadFromLocalStorage(MAP_CONTEXT_LS_KEY) ?? PREVIEW; // default value in case of no LS

  const isTelematicsAllowed: boolean = yield select(isTelematicSectionAllowed);
  const isTelematicsVerified: boolean = yield select(
    isTelematicSectionVerified,
  );
  const isTelematicsActive = isTelematicsAllowed && isTelematicsVerified;

  if (!isTelematicsActive) {
    contextLs = PREVIEW; // force to preview if the farm has no telematics
  }

  yield put(mainMapActions.setMapContext(contextLs) as unknown as AnyAction);
  yield put(mainMapActions.setMapFetching(false) as unknown as AnyAction);
  yield put(mainMapActions.setMapInitSuccess);
}

function* toggleLpisLayersVisibility(visible: boolean) {
  const lpisLayers: Layers[] = yield select(layersSelectors.getLpisLayers);
  yield all(
    lpisLayers.map((l) =>
      put(
        layersUiActions.setLayerVisibility(l, visible) as unknown as AnyAction,
      ),
    ),
  );
}

function* fetchTelematicsResources(withHistory: boolean) {
  yield putResolve(
    // @ts-expect-error no clue how to type this
    telMapActions.fetchMachinePositions() as unknown as Action<RSAAAction>,
  );
  if (withHistory) {
    yield putResolve(
      // @ts-expect-error no clue how to type this
      telMapActions.fetchMachineDrivesHistory() as unknown as Action<RSAAAction>,
    );
  }
}

function* setOrUpdateTelematicsLayers() {
  const machinePositions: PositionDetailTo[] = yield select(
    telMapApiSelectors.getMainMapTelematicsMachinePositions,
  );
  const machineDriveHistory: DailyPositionTo[] = yield select(
    telMapApiSelectors.getMainMapTelematicsMachineDrivesHistory,
  );
  yield put(
    // @ts-expect-error no clue how to type this
    telMapActions.setMachinePositions(
      machinePositions,
    ) as unknown as Action<RSAAAction>,
  );
  yield put(
    // @ts-expect-error no clue how to type this
    telMapActions.setMachineDrivesHistory(
      machineDriveHistory,
    ) as unknown as Action<RSAAAction>,
  );
}

function* handleTelematicsResources() {
  const machineFilter: MachineTo[] = yield select(
    telMapSelectors.getMainMapTelematicsMachineFilter,
  );
  const driverFilter: DriverTo[] = yield select(
    telMapSelectors.getMainMapTelematicsDriverFilter,
  );
  if (machineFilter.length || driverFilter.length) {
    yield call(fetchTelematicsResources, true);
  } else {
    yield call(fetchTelematicsResources, false);
  }
}

function* handleTelematicsFilter() {
  yield call(handleTelematicsResources);
  yield call(setOrUpdateTelematicsLayers);
}

function* handleTelematicsFiltersReset() {
  yield put(telMapActions.resetMachineDrivesHistory());
  yield call(fetchTelematicsResources, false);
  yield call(setOrUpdateTelematicsLayers);
}

function* enableTelematicsContext(dispatch: Thunk<MainMapState>) {
  const map: MapService = yield select(mainMapSelectors.getMainMap);
  const mainMapTelematics = new MainMapTelematics(
    map.getMap(),
    TRANSFORM_OPTIONS,
    (unit) => dispatch(telMapActions.setSelectedMachineGpsUnit(unit)),
  );
  yield put(
    mainMapActions.storeServiceWrapper("mainMapTelematics", mainMapTelematics),
  );

  // @ts-expect-error no clue how to type this
  yield putResolve(fetchCrops() as unknown as Action<RSAAAction>);
  yield put(telMapActions.enableTelematics() as unknown as AnyAction);
  yield call(toggleLpisLayersVisibility, true);
  yield call(handleTelematicsResources);
  yield call(setOrUpdateTelematicsLayers);
}

// function* enableWeatherContext() {
//   const map: MapService = yield select(mainMapSelectors.getMainMap);
//   const weather = new Weather(map.getMap(), TRANSFORM_OPTIONS);
//   yield put(mainMapActions.storeServiceWrapper('weather', weather));
//   yield put(weatherActions.enableWeather() as unknown as AnyAction);
// }

function* enablePreviewContext(selectedSeasonId: string) {
  if (selectedSeasonId === "0") {
    // @ts-expect-error no clue how to type this
    yield putResolve(fetchCrops() as unknown as Action<RSAAAction>);
    yield put(mainMapActions.enablePreview() as unknown as AnyAction);
  } else {
    yield putResolve(
      fetchSeasonSowingPlansMainMap(selectedSeasonId) as unknown as AnyAction,
    );
    yield put(mainMapActions.enableSeasonSowingPlan() as unknown as AnyAction);
  }
}

function* disableAllContexts() {
  yield put(telMapActions.disableTelematics() as unknown as AnyAction);
  yield put(mainMapActions.disablePreview() as unknown as AnyAction);
  yield put(weatherActions.disableWeather() as unknown as AnyAction);
  yield call(toggleLpisLayersVisibility, false);
}

function* setupContext(dispatch: Thunk<MainMapState>) {
  const isMapInitialized: boolean = yield select(
    mainMapSelectors.getIsInitialized,
  );
  if (!isMapInitialized) return;

  const context: string = yield select(mainMapSelectors.getContext);
  const selectedSeasonId: string = yield select(
    mainMapSelectors.getSelectedSeasonIdSowingPlan,
  );
  LocalStorage.saveToLocalStorage(context, MAP_CONTEXT_LS_KEY);

  yield call(disableAllContexts);

  if (context === PREVIEW) {
    yield call(enablePreviewContext, selectedSeasonId);
  } else if (context === TELEMATICS) {
    yield call(enableTelematicsContext, dispatch);
  }
  // else if (context === WEATHER) {
  //   yield call(enableWeatherContext);
  // }
}

function* updateSowingSeasonResources() {
  const context: string = yield select(mainMapSelectors.getContext);
  const isInitialized: boolean = yield select(
    mainMapSelectors.getIsInitialized,
  );
  if (context !== PREVIEW || !isInitialized) return;

  const selectedSeasonId: string = yield select(
    mainMapSelectors.getSelectedSeasonIdSowingPlan,
  );
  yield call(enablePreviewContext, selectedSeasonId);
}

function* updateTelematicsResources() {
  while (true) {
    yield delay(TELEMATICS_UPDATE_INTERVAL);
    const context: string = yield select(mainMapSelectors.getContext);
    if (context !== TELEMATICS) return;
    yield call(handleTelematicsFilter);
  }
}

export default function* MainMapSaga(
  dispatch: Thunk<MainMapState>,
): Generator<Effect, void, any> {
  yield takeLatest(mainMapTypes.INIT_MAP_SAGA, initMap, dispatch);
  yield takeLatest(mainMapTypes.MAP_INIT_SUCCESS, setupContext, dispatch);
  yield takeLatest(mainMapTypes.SET_MAP_CONTEXT, setupContext, dispatch);
  yield takeLatest(
    sowingPlanMapTypes.SET_SELECTED_SEASON,
    updateSowingSeasonResources,
  );
  yield takeLatest(
    sowingPlanTypes.RESET_SEASON_PLAN_SUCCESS,
    updateSowingSeasonResources,
  );
  yield takeLatest(
    sowingPlanTypes.PUT_PARCEL_PLAN_SUCCESS,
    updateSowingSeasonResources,
  );
  yield takeLatest(mainMapTypes.SET_MAP_CONTEXT, updateTelematicsResources);
  yield takeLatest(
    [
      telMapTypes.SET_DRIVER_FILTER,
      telMapTypes.SET_MACHINE_FILTER,
      telMapTypes.SET_DATE,
    ],
    handleTelematicsFilter,
  );
  yield takeLatest(telMapTypes.RESET_FILTERS, handleTelematicsFiltersReset);
}
