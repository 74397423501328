import CommonStyles, { STROKE_COLOR } from "./CommonStyles.service";

export default class CommonLabelStyle {
  static getDefault(color) {
    return CommonStyles.getLabelStyle(
      color ? [color.red, color.green, color.blue] : STROKE_COLOR.DEFAULT,
      "#000",
    );
  }

  static getDisabled() {
    return CommonStyles.getLabelStyle("rgba(79, 79, 79, 0.6)", "#000");
  }

  static getSelected() {
    return CommonStyles.getLabelStyle(STROKE_COLOR.SELECTED, "#000");
  }

  static getLabelContent(feature, resolution) {
    const blockNr = feature.get("block_nr") || "";
    const localName = feature.get("local_name") || "";
    return resolution < 15 ? `${blockNr} ${localName}` : localName;
  }
}
