import React, { FC, ReactNode } from "react";

import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { AdminDeviceTypeTo } from "../../admin.irrigation.types";

interface Props {
  deviceTypes: AdminDeviceTypeTo[];
  label: ReactNode;
  selectedDeviceTypes?: AdminDeviceTypeTo[];
  setDeviceTypeFilter: (filterPart: string, items: AdminDeviceTypeTo[]) => void;
}

const IrrigationFilterByDeviceType: FC<Props> = ({
  deviceTypes,
  label,
  selectedDeviceTypes = [],
  setDeviceTypeFilter,
}) => {
  const handleChange = (value: AdminDeviceTypeTo[]) =>
    setDeviceTypeFilter("deviceType", value);

  return (
    <CfAutocomplete
      defaultValues={selectedDeviceTypes}
      id="device-type-filter"
      isMultiple={true}
      label={label}
      onChange={handleChange}
      suggestions={deviceTypes}
      testId="node-type-filter"
    />
  );
};

export default IrrigationFilterByDeviceType;
