import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  custom: {},
};

function CfLoader({ classes, color, size }) {
  return (
    <div className={classes.wrapper}>
      <CircularProgress
        classes={{ root: classes.custom }}
        color={color}
        size={size}
      />
    </div>
  );
}

CfLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

CfLoader.defaultProps = {
  size: 40,
  color: "primary",
};

export default withStyles(styles)(CfLoader);
