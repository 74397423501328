import { AnyAction } from "redux";

import * as seasonStateTypes from "../constants/parcelSeasonState.constans";

import { SEASON_STATE } from "../../../../shared/api/agroevidence/parcels/parcels.types";

const initialState = SEASON_STATE.CURRENT;

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case seasonStateTypes.SET_SEASON_STATE:
        return state === SEASON_STATE.CURRENT
          ? SEASON_STATE.FUTURE
          : SEASON_STATE.CURRENT;
      default:
        return state;
    }
  };
