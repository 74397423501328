import React, { Fragment } from "react";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MapIcon from "@mui/icons-material/Map";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import withWidth from "../../../hocs/withWidth";

const styles = (theme) => ({
  buttonMobile: {
    position: "absolute",
    bottom: 15,
    borderRadius: 18,
    backgroundColor: theme.palette.common.white,
    margin: "auto",
    right: 0,
    left: 0,
    width: "max-content",
    zIndex: 9,
  },
  buttonDesktop: {
    position: "absolute",
    bottom: "calc(50% - 18px)",
    // these button styles are sometimes strangely overridden with default MUI styles
    backgroundColor: `${theme.palette.common.white} !important`,
    minWidth: "24px !important",
    padding: "0px !important",
    zIndex: 1200,
    height: 36,
    right: 0,
    "&:hover": {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  },
});

function ShowHideMap(props) {
  const { classes, handleClick, isMapHidden, width } = props;
  return (
    <Fragment>
      {["md", "lg", "xl"].indexOf(width) >= 0 ? (
        <Button
          className={classes.buttonDesktop}
          color="inherit"
          data-test="map-toggle"
          onClick={handleClick}
          variant="contained"
        >
          {isMapHidden ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </Button>
      ) : (
        <Button
          className={classes.buttonMobile}
          data-test="map-toggle"
          onClick={handleClick}
          startIcon={<MapIcon />}
          variant="contained"
        >
          {isMapHidden ? (
            <FormattedMessage id="ShowHideMap.showMap" />
          ) : (
            <FormattedMessage id="ShowHideMap.hideMap" />
          )}
        </Button>
      )}
    </Fragment>
  );
}

ShowHideMap.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  isMapHidden: PropTypes.bool,
  handleClick: PropTypes.func,
};

ShowHideMap.defaultProps = {
  isMapHidden: true,
  handleClick: () => {},
};

export default compose(withStyles(styles), withWidth())(ShowHideMap);
