import { RSAA } from "redux-api-middleware";

import * as types from "./crops.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../../api.types";

export const getCropsApi = (search?: string, hasSeed?: boolean) => ({
  [RSAA]: {
    endpoint: `catalogues/crops?${search ? `search=${search}&` : ""}&${
      hasSeed !== undefined ? `seed=${hasSeed}&` : ""
    }`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CROPS_REQUEST,
      types.GET_CROPS_SUCCESS,
      types.GET_CROPS_ERROR,
    ] as RsaaTypes,
  },
});

export const getCropsV2Api = (search?: string, validOn?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/crops?${search ? `search=${search}&` : ""}&${
      validOn ? `valid-on=${validOn}&` : ""
    }`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CROPS_REQUEST,
      types.GET_CROPS_SUCCESS,
      types.GET_CROPS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetCropsApi = () => ({
  type: types.RESET_CROPS,
});
