import React, { ReactNode, useEffect, useState } from "react";

import { Grid, Paper, Table, TableBody } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingSeasonDetailSowingPlan,
  getSeasonDetailSowingPlan,
} from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";

import { fetchSeasonSowingPlansMainMap } from "../../../map/actions/sowingPlan/sowingPlan.actions";

import CfCarousel from "../../../../shared/components/common/CfCarousel/CfCarousel";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import { Thunk } from "../../../../types";
import {
  StatsBody,
  StatsFooter,
  StatsHeader,
} from "../../list/components/ParcelZoneTable/ParcelZoneTable";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { SowingPlanSeasonTo } from "../../../../shared/api/agroevidence/agroevidence.types";

type ParcelSeasonCropStatisticsProps = OwnProps & ReduxProps;

const ParcelSeasonCropStatistics = ({
  fetchSeasonSowingPlansMainMap,
  isFetchingParcelSowingPlan,
  seasonDetailSowingPlan,
  shouldReloadData,
  sowingPlanList,
}: ParcelSeasonCropStatisticsProps) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (sowingPlanList.length > 0)
      fetchSeasonSowingPlansMainMap(sowingPlanList[currentIndex].id);
  }, [
    shouldReloadData,
    sowingPlanList,
    currentIndex,
    fetchSeasonSowingPlansMainMap,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <CfCarousel<ReactNode>
            initialIndex={currentIndex}
            onDataChange={(index: number) => setCurrentIndex(index)}
            data={sowingPlanList.map((plan) => (
              <span key={plan.id}>
                <FormattedMessage id="common.season" /> {plan.startYear}/
                {plan.endYear}
              </span>
            ))}
          />
          {seasonDetailSowingPlan?.statistics.length > 0 &&
          !isFetchingParcelSowingPlan ? (
            <>
              <Table data-test="crop-list">
                <StatsHeader />
                <TableBody>
                  {seasonDetailSowingPlan.statistics
                    .sort((a, b) => b.area - a.area)
                    .map((item, index) => (
                      <StatsBody
                        key={item.crop?.id ?? index}
                        item={{
                          name: item.crop?.name ?? "-",
                          count: item.parcelsCount,
                          totalArea: item.area,
                        }}
                      />
                    ))}
                  <StatsFooter
                    stats={{
                      count: seasonDetailSowingPlan.statistics.reduce(
                        (acc, res) => acc + res.parcelsCount,
                        0,
                      ),
                      totalArea:
                        seasonDetailSowingPlan.statistics.reduce(
                          (acc, res) => acc + res.area,
                          0,
                        ) || 0,
                    }}
                  />
                </TableBody>
              </Table>
            </>
          ) : (
            <CfLoader classes={{ wrapper: classes.wrapper }} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  isFetchingParcelSowingPlan: getIsFetchingSeasonDetailSowingPlan(state),
  seasonDetailSowingPlan: getSeasonDetailSowingPlan(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchSeasonSowingPlansMainMap,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  shouldReloadData: boolean;
  sowingPlanList: SowingPlanSeasonTo[];
};
export default connector(ParcelSeasonCropStatistics);

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 300,
    "&:before": {
      content: "",
      display: "block",
      paddingTop: "100%",
    },
  },
  firstCell: {
    width: "40%",
    border: "none",
  },
  cell: {
    width: "30%",
    border: "none",
    padding: "0 9px",
  },
}));
