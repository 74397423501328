import React from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import NodeLocationSelectGroups from "../NodeLocationSelectGroups/NodeLocationSelectGroups";

export const NodeLocationEditGroupDialog = ({
  onAccept,
  onCancel,
  opened = false,
  selectedGroups,
  setGroups,
}) => (
  <CfDialog
    acceptText={<FormattedMessage id="common.save" />}
    cancelText={<FormattedMessage id="common.cancel" />}
    data-test="edit-group"
    dialogHeight="95px"
    maxWidth="xs"
    onAccept={onAccept}
    onCancel={onCancel}
    onClose={onCancel}
    opened={opened}
    title={<FormattedMessage id="NodeGroups.edit" />}
  >
    <NodeLocationSelectGroups
      defaultGroups={selectedGroups}
      label={<FormattedMessage id="NodeGroups.chooseGroup" />}
      onChange={setGroups}
    />
  </CfDialog>
);

NodeLocationEditGroupDialog.propTypes = {
  opened: PropTypes.bool,
  selectedGroups: PropTypes.array.isRequired,
  setGroups: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};
