import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CfLabel from "../../../../../shared/components/common/CfLabel/CfLabel";

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    l: {
      padding: "3px 6px",
      backgroundColor: "#DEEFFF",
      color: theme.palette.text.primary,
      marginTop: 3,
      textAlign: "center",
    },
    [breakpoints.down("sm")]: {
      l: {
        width: "min-content",
      },
    },
  };
};

function SensorTypeLabel(props) {
  const { classes, intl, sensorType } = props;
  const { formatMessage } = intl;
  const label = `${formatMessage({
    id: "SensorsAdmin.rainfallType",
  })} ${sensorType}`;
  return <CfLabel classes={{ label: classes.l }} message={label} />;
}

SensorTypeLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  sensorType: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

SensorTypeLabel.defaultProps = {
  labelStyle: {},
  sensorType: "",
};

export default injectIntl(withStyles(styles)(SensorTypeLabel));
