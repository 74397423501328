import {
  LogbookAggregatedItemTo,
  LogbookItemTo,
} from "../../../shared/api/telematics/telematics.types";

const isAggregatedDrive = (
  drive: LogbookAggregatedItemTo | LogbookItemTo,
): drive is LogbookAggregatedItemTo => "duration" in drive;

export { isAggregatedDrive };
