import { MainMapState } from "../../../reducers/map.reducer.types";

export const getMainMapTelematicsDateFrom = (state: MainMapState) =>
  state.ui.mainMapTelematics.dateFrom;
export const getMainMapTelematicsDateTo = (state: MainMapState) =>
  state.ui.mainMapTelematics.dateTo;

export const getMainMapTelematicsMachineFilter = (state: MainMapState) =>
  state.ui.mainMapTelematics.machineFilter;
export const getMainMapTelematicsDriverFilter = (state: MainMapState) =>
  state.ui.mainMapTelematics.driverFilter;

export const getMainMapTelematicsSelectedMachineGpsUnit = (
  state: MainMapState,
) => state.ui.mainMapTelematics.selectedMachineGpsUnit;
