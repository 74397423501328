import React, { Component } from "react";

import { Tooltip } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import countBy from "lodash/countBy";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getLayers } from "../../selectors/layers.selectors";
import { getContext, getMainMap } from "../../selectors/map.selectors";

import {
  setLayerVisibility,
  setAllLayersVisibility,
} from "../../actions/layersUI/layersUI.actions";

import { TELEMATICS } from "../../constants/contexts.constants";

import { PUBLIC_LPIS_CONFIG } from "../../../../shared/services/LayersConfig.service";
import MapSwitcherBody from "../../components/MapSwitcherBody/MapSwitcherBody";
import MapSwitcherCheckbox from "../../components/MapSwitcherCheckbox/MapSwitcherCheckbox";
import MapSwitcherHeader from "../../components/MapSwitcherHeader/MapSwitcherHeader";

export class LayerSwitcher extends Component {
  getVisibleLayersCount = () =>
    countBy(this.props.layers, (layer) => layer.visible).true;

  getLayerLabel = (layer) => {
    const { formatMessage } = this.props.intl;
    return (
      <span>
        <FormattedMessage id={`LayerSwitcher.${layer.layerId}`} />
        {layer.linkedLayers.map((linkedLayer) => {
          if (linkedLayer.title) {
            return `/${formatMessage({
              id: `LayerSwitcher.${linkedLayer.layerId}`,
            })}`;
          }
          return "";
        })}
      </span>
    );
  };

  handleLayerSelect = (layer, visible) => {
    this.props.setLayerVisibility(layer, visible);
  };

  handleAllLayersSelect = (visible) => {
    this.props.setAllLayersVisibility(this.props.layers, visible);
  };

  render() {
    const { context, expanded, handleExpansion, intl, layers, testId } =
      this.props;
    const visibleCount = this.getVisibleLayersCount();
    const isTelematicsContext = context === TELEMATICS;
    const currentViewResolution = this.props.map.getResolution();
    const isLpisLayerVisible =
      currentViewResolution <= PUBLIC_LPIS_CONFIG.MAX_RESOLUTION;

    return (
      <div data-test={testId}>
        <MapSwitcherHeader
          expanded={expanded}
          handleExpansion={handleExpansion}
          testId={testId}
        >
          <MapSwitcherCheckbox
            checked={visibleCount > 0 && layers.length === visibleCount}
            header={true}
            indeterminate={visibleCount > 0 && visibleCount < layers.length}
            label={<FormattedMessage id="LayerSwitcher.layers" />}
            onChange={(event) =>
              this.handleAllLayersSelect(event.target.checked)
            }
          />
        </MapSwitcherHeader>
        <MapSwitcherBody expanded={expanded} testId={testId}>
          <FormGroup>
            {layers.map((layer) => {
              const isPublicLpisLayer =
                layer.layerId === PUBLIC_LPIS_CONFIG.LAYER_ID;
              if (!isTelematicsContext && isPublicLpisLayer) return null;
              const isOptionGrayed =
                isTelematicsContext && isPublicLpisLayer && !isLpisLayerVisible;
              return (
                <Tooltip
                  key={layer.layerId}
                  title={
                    isOptionGrayed
                      ? intl.formatMessage({
                          id: "LayerSwitcher.not_available",
                        })
                      : ""
                  }
                >
                  <div>
                    <MapSwitcherCheckbox
                      checked={layer.visible}
                      grayed={isOptionGrayed}
                      label={this.getLayerLabel(layer)}
                      onChange={(event) =>
                        this.handleLayerSelect(layer, event.target.checked)
                      }
                    />
                  </div>
                </Tooltip>
              );
            })}
          </FormGroup>
        </MapSwitcherBody>
      </div>
    );
  }
}

LayerSwitcher.propTypes = {
  layers: PropTypes.array,
  context: PropTypes.string,
  setLayerVisibility: PropTypes.func.isRequired,
  setAllLayersVisibility: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpansion: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

LayerSwitcher.defaultProps = {
  layers: [],
  testId: "",
  context: "",
};

const mapStateToProps = (state) => ({
  context: getContext(state),
  layers: getLayers(state),
  map: getMainMap(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLayerVisibility,
      setAllLayersVisibility,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LayerSwitcher),
);
