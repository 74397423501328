import React, { ReactNode } from "react";

import { Box, Paper, Stack, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  action?: ReactNode;
  text: ReactNode;
  title: ReactNode;
}

export function ParcelSourceMessageContainer({ action, text, title }: Props) {
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={0}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Typography>{text}</Typography>
        </Box>
        {!!action && <Box>{action}</Box>}
      </Stack>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.light,
    padding: 16,
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));
