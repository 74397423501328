import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import notificationListUI from "../notifications/reducer/notificationList.reducer";
import rulesApi from "../shared/api/automation/rules/rules.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import usersApi from "../shared/api/gateway/users/users.reducer";
import locationsApi from "../shared/api/iot/locations/locations.reducer";
import nodesApi from "../shared/api/iot/nodes/nodes.reducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      notificationList: notificationListUI(),
      cache: () => ["notificationList"],
    }),
    api: combineReducers({
      rules: rulesApi(),
      users: usersApi(),
      locations: locationsApi(),
      nodes: nodesApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
