const PREFIX = "PRECISION_MANAGEMENT";

export const GET_MANAGEMENT_ZONES = `${PREFIX}/GET_MANAGEMENT_ZONES`;
export const GET_MANAGEMENT_ZONES_SUCCESS = `${PREFIX}/GET_MANAGEMENT_ZONES_SUCCESS`;
export const GET_MANAGEMENT_ZONES_ERROR = `${PREFIX}/GET_MANAGEMENT_ZONES_ERROR`;

export const ACTIVATE_MANAGEMENT_ZONES = `${PREFIX}/ACTIVATE_MANAGEMENT_ZONES`;
export const ACTIVATE_MANAGEMENT_ZONES_SUCCESS = `${PREFIX}/ACTIVATE_MANAGEMENT_ZONES_SUCCESS`;
export const ACTIVATE_MANAGEMENT_ZONES_ERROR = `${PREFIX}/ACTIVATE_MANAGEMENT_ZONES_ERROR`;
export const RESET_MANAGEMENT_ZONES = `${PREFIX}/RESET_MANAGEMENT_ZONES`;
