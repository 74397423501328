import React, { FC, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTelematicsListAdvancedFilter } from "../../selectors/telematicsList.selectors";

import { setAdvancedFilter } from "../../../shared/actions/filter.actions";

import CfFilter from "../../../shared/containers/CfFilter/CfFilter";
import { Thunk } from "../../../types";

import CropSelector from "./CropSelector/CropSelector";
import DriverSelector from "./DriverSelector/DriverSelector";
import EquipmentSelector from "./EquipmentSelector/EquipmentSelector";
import MachineSelector from "./MachineSelector/MachineSelector";
import OperationSelector from "./OperationSelector/OperationSelector";
import ParcelAffiliationSelector from "./ParcelAffiliationSelector/ParcelAffiliationSelector";
import ParcelSelector from "./ParcelSelector/ParcelSelector";
import ParcelSubjectSelector from "./ParcelSubjectSelector/ParcelSubjectSelector";
import ProductionOperationSelector from "./ProductionOperationSelector/ProductionOperationSelector";
import StateSelector from "./StateSelector/StateSelector";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { ParcelSuggestionTo } from "../../../shared/api/agroevidence/agroevidence.types";
import {
  Affiliation,
  CropTo,
  DriveSubjectTo,
  DriverTo,
  EquipmentTo,
  MachineTo,
  OperationTo,
  ProductionOperationTo,
  State,
} from "../../../shared/api/telematics/telematics.types";
import { TelematicsAdvancedFilter as TelematicsAdvancedFilterType } from "../../telematics.types";

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

interface TelematicsAdvancedFilterProps {
  advancedFilter: TelematicsAdvancedFilterType;
  initMachineFilter?: MachineTo;
  langId: string;
  namespace: string;
  ngResetFilters: () => void;
  setAdvancedFilter: (
    values: TelematicsAdvancedFilterType,
    namespace: string,
  ) => void;
  setInitMachineFilter: (initMachineFilter?: MachineTo) => void;
}

export const TelematicsAdvancedFilter: FC<TelematicsAdvancedFilterProps> = ({
  advancedFilter,
  initMachineFilter,
  langId,
  namespace,
  ngResetFilters,
  setAdvancedFilter,
  setInitMachineFilter,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (initMachineFilter) {
      setAdvancedFilter({ machine: [initMachineFilter] }, namespace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [advancedFilterState, setAdvancedFilterState] =
    useState<TelematicsAdvancedFilterType>(advancedFilter);

  const handleChangeActionState = (items: State[]) => {
    setAdvancedFilterState({ ...advancedFilterState, state: items });
  };
  const handleChangeOperation = (items: OperationTo[]) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      operation: items.map((operation) => operation.code),
    });
  };
  const handleChangeProductionOperation = (items: ProductionOperationTo[]) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      productionOperation: items,
    });
  };
  const handleChangeParcel = (items: ParcelSuggestionTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, parcel: items });
  };
  const handleChangeParcelAffiliation = (items: Affiliation[]) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      parcelAffiliation: items,
    });
  };
  const handleChangeParcelSubject = (items: DriveSubjectTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, parcelSubject: items });
  };
  const handleChangeDriver = (items: DriverTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, driver: items });
  };
  const handleChangeMachine = (items: MachineTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, machine: items });
  };
  const handleChangeEquipment = (items: EquipmentTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, equipment: items });
  };
  const handleChangeCrop = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crop: items });
  };

  const resetInitFilter = () => {
    setInitMachineFilter(undefined);
    ngResetFilters();
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      onAccept={resetInitFilter}
      onReset={resetInitFilter}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <StateSelector
              defaultValues={advancedFilter.state}
              label={<FormattedMessage id="TelematicsList.filter.state" />}
              onChange={handleChangeActionState}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <OperationSelector
              defaultValues={advancedFilter.operation}
              label={<FormattedMessage id="TelematicsList.filter.operation" />}
              onChange={handleChangeOperation}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropSelector
              defaultValues={advancedFilter.crop}
              label={<FormattedMessage id="TelematicsList.filter.crop" />}
              onChange={handleChangeCrop}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ProductionOperationSelector
              defaultValues={advancedFilter.productionOperation}
              onChange={handleChangeProductionOperation}
              label={
                <FormattedMessage id="TelematicsList.filter.productionOperation" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ParcelSelector
              defaultValues={advancedFilter.parcel}
              label={<FormattedMessage id="TelematicsList.filter.parcel" />}
              onChange={handleChangeParcel}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ParcelAffiliationSelector
              defaultValues={advancedFilter.parcelAffiliation}
              onChange={handleChangeParcelAffiliation}
              label={
                <FormattedMessage id="TelematicsList.filter.parcelAffiliation" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ParcelSubjectSelector
              defaultValues={advancedFilter.parcelSubject}
              label={<FormattedMessage id="TelematicsList.filter.parcelUser" />}
              onChange={handleChangeParcelSubject}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <DriverSelector
              defaultValues={advancedFilter.driver}
              label={<FormattedMessage id="TelematicsList.filter.driver" />}
              onChange={handleChangeDriver}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <MachineSelector
              defaultValues={advancedFilter.machine}
              label={<FormattedMessage id="TelematicsList.filter.machine" />}
              onChange={handleChangeMachine}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <EquipmentSelector
              defaultValues={advancedFilter.equipment}
              label={<FormattedMessage id="TelematicsList.filter.equipment" />}
              onChange={handleChangeEquipment}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  advancedFilter: getTelematicsListAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setAdvancedFilter,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsAdvancedFilter);
