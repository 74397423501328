/**
 * Created by jenc on 11/23/15.
 */

import angular from "angular";

export default angular
  .module("app.classifiers.action", [])
  /** @constant
   * @type {object}
   * @default
   */
  .constant("ActionEnums", {
    ActionClass: {
      OTHER: { code: "OTHER" },
      EPH: { code: "EPH" },
      PASTURE: { code: "PASTURE" },
    },

    ActionType: {
      CULTIV: { code: "CULTIV" },
      DISK: { code: "DISK" },
      FOLIAR: { code: "FOLIAR" },
      GRIND: { code: "GRIND" },
      HARROW: { code: "HARROW" },
      HARVEST: { code: "HARVEST" },
      HILL: { code: "HILL" },
      HOE: { code: "HOE" },
      IRRIG: { code: "IRRIG" },
      MANIP: { code: "MANIP" },
      MANIP_BULKY_MAT: { code: "MANIP_BULKY_MAT" },
      MOWING: { code: "MOWING" },
      MULCHING: { code: "MULCHING" },
      OTHER: { code: "OTHER" },
      OUTSIDE: { code: "OUTSIDE" },
      PASTURE: { code: "PASTURE" },
      PLOW: { code: "PLOW" },
      PREP: { code: "PREP" },
      ROLL: { code: "ROLL" },
      SOWING: { code: "SOWING" },
      SOWING_PERENNIAL: { code: "SOWING_PERENNIAL" },
      SUBSOILING: { code: "SUBSOILING" },
      TILLAGE: { code: "TILLAGE" },
      TRAFFIC: { code: "TRAFFIC" },
      EPH: { code: "EPH" },
    },

    ActionState: {
      PLANNED: { id: "PLANNED" },
      UNCONFIRMED: { id: "UNCONFIRMED" },
      IN_PROGRESS: { id: "IN_PROGRESS" },
      CONFIRMED: { id: "CONFIRMED" },
      UNDEFINED: { id: "UNDEFINED" },
    },

    ActionRestriction: {
      ABSOLUTE: {},
    },

    DriftClass: {
      NONE: "bez redukce",
      DRIFT_50: "50 %",
      DRIFT_75: "75 %",
      DRIFT_90: "90 %",
    },

    Unit: {
      T: { id: "t", name: "t" },
      THA: { id: "t/ha", name: "t/ha" },
      KG: { id: "kg", name: "kg" },
      KGHA: { id: "kg/ha", name: "kg/ha" },
      VJ: { id: "VJ", name: "VJ" },
      VJHA: { id: "VJ/ha", name: "VJ/ha" },
      L: { id: "l", name: "l" },
      LHA: { id: "l/ha", name: "l/ha" },
      PCS: { id: "pcs", name: "ks" },
      PCSHA: { id: "pcs/ha", name: "ks/ha" },
      M3: { id: "m3", name: "m³" },
      M3HA: { id: "m3/ha", name: "m³/ha" },
      WHOLE: { id: "whole", name: "celek" },
    },

    Events: {
      ACTIONS_CONFIRMED: "actions.confirmed",
    },
  }).name;
