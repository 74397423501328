import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const BufferIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M4.4 3H19.6C20.4 3 21 3.6 21 4.4V19.6C21 20.4 20.4 21 19.6 21H4.4C3.6 21 3 20.4 3 19.6V4.4C3 3.6 3.6 3 4.4 3ZM5 19H19V16.5448C18.954 16.5532 18.9065 16.5576 18.858 16.5576C18.428 16.5576 18.083 16.2126 18.083 15.7826C18.083 15.3526 18.428 15.0076 18.858 15.0076C18.9065 15.0076 18.954 15.012 19 15.0204V5H8.96495C8.97363 5.04667 8.97816 5.09485 8.97816 5.14414C8.97816 5.57414 8.63316 5.91914 8.20316 5.91914C7.77816 5.91914 7.42816 5.56914 7.42816 5.14414C7.42816 5.09485 7.4327 5.04667 7.44137 5H5V19ZM14.533 15.7804C14.533 15.3504 14.878 15.0054 15.308 15.0054C15.738 15.0054 16.083 15.3504 16.083 15.7804C16.083 16.2104 15.738 16.5554 15.308 16.5554C14.878 16.5554 14.533 16.2104 14.533 15.7804ZM11 15.775C11 15.345 11.345 15 11.775 15C12.205 15 12.55 15.345 12.55 15.775C12.55 16.205 12.205 16.55 11.775 16.55C11.345 16.55 11 16.205 11 15.775ZM8.20469 15C7.77469 15 7.42969 15.345 7.42969 15.775C7.42969 16.205 7.77469 16.55 8.20469 16.55C8.63469 16.55 8.97969 16.205 8.97969 15.775C8.97969 15.345 8.63469 15 8.20469 15ZM8.97816 8.69419C8.97816 9.12419 8.63316 9.46919 8.20316 9.46919C7.77816 9.46919 7.42816 9.11919 7.42816 8.69419C7.42816 8.26419 7.77316 7.91919 8.20316 7.91919C8.63316 7.91919 8.97816 8.26419 8.97816 8.69419ZM8.97816 12.2442C8.97816 12.6742 8.63316 13.0192 8.20316 13.0192C7.77816 13.0192 7.42816 12.6742 7.42816 12.2442C7.42816 11.8142 7.77316 11.4692 8.20316 11.4692C8.63316 11.4692 8.97816 11.8142 8.97816 12.2442Z" />
    </svg>
  </SvgIcon>
);

export default BufferIcon;
