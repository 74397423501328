import queryString from "query-string";
import { RSAA, RSAAAction } from "redux-api-middleware";

import * as types from "./parcelsCropDetails.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { ParcelCrops } from "./parcelsCropDetails.types";
import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { RsaaMethods, RsaaTypes, ExportPayload } from "../../api.types";

interface ParcelsApiParamsCrop {
  centers?: string;
  context?: string;
  crops?: string;
  ids?: string;
  "land-use"?: string;
  "nitrate-vulnerable"?: boolean;
  page?: number;
  "per-page"?: number;
  search?: string;
  size: number;
  "sort-col"?: string;
  "sort-dir"?: string;
  sown?: boolean;
  startYear: number;
  status?: string[];
  "valid-from"?: string;
  "valid-to"?: string;
  zones?: string;
}

export const getParcelsCropApi = (params: ParcelsApiParamsCrop) => ({
  [RSAA]: {
    endpoint: `sowing-plan/parcels?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    context: params.context ?? null,
    types: [
      types.GET_PARCELS_CROP_REQUEST,
      types.GET_PARCELS_CROP_SUCCESS,
      types.GET_PARCELS_CROP_ERROR,
    ] as RsaaTypes,
  },
});

export const putParcelsCropApi = (body: ParcelCrops[]) => ({
  [RSAA]: {
    endpoint: "sowing-plan/parcels?",
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify({ parcelCrops: body }),
    types: [
      types.PUT_PARCELS_CROP_REQUEST,
      types.PUT_PARCELS_CROP_SUCCESS,
      types.PUT_PARCELS_CROP_ERROR,
    ] as RsaaTypes,
  },
});

export const exportParcelsCropApi = (startYear: number, size: number) => ({
  [RSAA]: {
    endpoint: `sowing-plan/seasons/excel?startYear=${startYear}&size=${size}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.EXPORT_PARCELS_CROP_REQUEST,
      {
        type: types.EXPORT_PARCELS_CROP_SUCCESS,
        payload: (_action: RSAAAction, _state: ParcelsState, res: Response) =>
          ({
            body: res.blob(),
            type: res.headers.get("Content-Type"),
            disposition: res.headers.get("Content-Disposition"),
          }) as ExportPayload,
      },
      types.EXPORT_PARCELS_CROP_ERROR,
    ] as RsaaTypes,
  },
});
