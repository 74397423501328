export enum SECTIONS {
  DRIVERS = "drivers",
  EQUIPMENT = "equipment",
  FERTILIZERS_REACT = "fertilizersReact",
  MACHINES = "machines",
  PLANT_PROTECTION_REACT = "plantProtectionReact",
  PORS = "pors",
  SEEDS = "seeds",
  SEEDS_REACT = "seedsReact",
}

export const LEGACY_SECTIONS = [SECTIONS.SEEDS, SECTIONS.PORS];

export const CATALOGUES_URLS = {
  [SECTIONS.SEEDS_REACT]: "catalogues/seedsReact",
  [SECTIONS.FERTILIZERS_REACT]: "catalogues/fertilizersReact",
  [SECTIONS.PLANT_PROTECTION_REACT]: "catalogues/plantProtectionReact",
  [SECTIONS.DRIVERS]: "catalogues/drivers",
  [SECTIONS.MACHINES]: "catalogues/machines",
  [SECTIONS.EQUIPMENT]: "catalogues/equipment",
  [SECTIONS.SEEDS]: "classifiers/private/seeds",
  [SECTIONS.PORS]: "classifiers/private/pors",
};
