import React, { Component } from "react";

import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CfAutosuggest from "../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";

class ParcelZoneSelector extends Component {
  getSuggestionValue = ({ blockNumber, localName, name }) =>
    localName ? `${blockNumber ? `${blockNumber},` : ""} ${localName}` : name;

  render() {
    const {
      intl: { formatMessage },
      placeholder,
    } = this.props;

    return (
      <CfAutosuggest
        autoFocus={this.props.autoFocus}
        clearSuggestions={this.props.onSuggestionsClearRequested}
        getSuggestions={this.props.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
        inputRef={this.props.inputRef}
        isDebounced
        onSuggestionSelected={this.props.onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={3}
        suggestions={this.props.suggestions}
        testData="parcel-zone-selector"
      />
    );
  }
}

ParcelZoneSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  onSuggestionSelected: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionsClearRequested: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
};

ParcelZoneSelector.defaultProps = {
  suggestions: [],
  onSuggestionSelected: () => {},
  onSuggestionsFetchRequested: () => {},
  onSuggestionsClearRequested: () => {},
  autoFocus: false,
  inputRef: () => {},
};

export default injectIntl(ParcelZoneSelector);
