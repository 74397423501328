import { createSelector } from "reselect";

import { getAccountFarms } from "../../../../shared/api/telematics/admin/telematicsAdmin.selectors";

import { AdminState } from "../../../../reducers/admin.reducer.types";
import { AccountTo } from "../../../../shared/api/telematics/telematics.types";

export const getTelematicsAdminOrder = (state: AdminState) =>
  state.ui.telematics.order;
export const getTelematicsAdminOrderBy = (state: AdminState) =>
  state.ui.telematics.orderBy;
export const getTelematicsAdminPage = (state: AdminState) =>
  state.ui.telematics.page;
export const getTelematicsAdminRowsPerPage = (state: AdminState) =>
  state.ui.telematics.rowsPerPage;
export const getTelematicsAdminSelected = (state: AdminState) =>
  state.ui.telematics.selected;
export const getTelematicsAdminAdvancedFilter = (state: AdminState) =>
  state.ui.telematics.advancedFilter;
export const getTelematicsAdminTextFilter = (state: AdminState) =>
  state.ui.telematics.textFilter;

export const getTelematicsAdminSelectedOnPage = createSelector(
  getAccountFarms,
  getTelematicsAdminSelected,
  (accountFarms, selected) =>
    accountFarms.reduce((acc, { farmId }) => {
      if (selected.includes(farmId)) {
        acc.push(farmId);
      }
      return acc;
    }, [] as Array<string>),
);

export const getTelematicsAdminSelectedFarms = createSelector(
  getAccountFarms,
  getTelematicsAdminSelected,
  (accountFarms, selected) =>
    accountFarms.reduce((acc, farm) => {
      if (selected.includes(farm.farmId)) {
        acc.push(farm);
      }
      return acc;
    }, [] as Array<AccountTo>),
);
