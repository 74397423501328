import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme) => ({
  detailWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    boxShadow: "0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)",
    backgroundColor: "#FFFFFF",
    zIndex: 19,
    maxWidth: 1000,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 36,
    height: 36,
    padding: 0,
  },
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    fontSize: 13,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
});

export function MapBottomPanel(props) {
  const { children, classes, onPanelClose } = props;
  return (
    <div className={classes.detailWrapper} data-test="map-bottom-panel">
      <div className={classes.content}>{children}</div>
      <IconButton
        aria-label="Close detail"
        className={classes.closeButton}
        data-test="map-bottom-close"
        onClick={onPanelClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}

MapBottomPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  onPanelClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(MapBottomPanel);
