import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfChartTooltipNoData from "../../../shared/components/charts/CfChartTooltipNoData/CfChartTooltipNoData";
import CfChartTooltipValue from "../../../shared/components/charts/CfChartTooltipValue/CfChartTooltipValue";
import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { FEATURE_TYPE_PREFIX } from "../../services/FeatureConfig.service";

const style = (theme) => ({
  valueItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  valueItemHeaders: {
    fontSize: 14,
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    margin: "0 0 0 10px",
  },
  tooltipValueWrapper: {},
});

const SensorChartTooltipValueItem = (props) => {
  const {
    children,
    classes,
    feature: { name, stroke, tooltipStroke, unit },
    langIdPrefix,
    noDataValue,
    payload,
  } = props;
  return (
    <div className={classes.valueItemWrapper}>
      {name && (
        <p className={classes.valueItemHeaders}>
          <FormattedMessage
            id={`${langIdPrefix}.${name.replace(FEATURE_TYPE_PREFIX, "")}`}
          />
        </p>
      )}

      <CfChartTooltipNoData
        item={payload}
        noDataValue={noDataValue}
        classes={{
          tooltipValueWrapper: classes.tooltipValueWrapper,
        }}
      >
        <CfChartTooltipValue
          sx={{ color: tooltipStroke || payload.stroke || stroke }}
        >
          {children || (
            <span>
              <CfFormattedNumber
                maximumFractionDigits={1}
                minimumFractionDigits={1}
                value={payload.value || 0}
              />{" "}
              {unit}
            </span>
          )}
        </CfChartTooltipValue>
      </CfChartTooltipNoData>
    </div>
  );
};

SensorChartTooltipValueItem.propTypes = {
  classes: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired,
  payload: PropTypes.object.isRequired,
  langIdPrefix: PropTypes.string,
  children: PropTypes.node,
  noDataValue: PropTypes.any,
};

SensorChartTooltipValueItem.defaultProps = {
  langIdPrefix: "NodeFeature",
  children: undefined,
  noDataValue: undefined,
};

export default withStyles(style)(SensorChartTooltipValueItem);
