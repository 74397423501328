import {
  CLEAR_REFRESH_TOKEN_PROMISE,
  SAVE_REFRESH_TOKEN_PROMISE,
  SET_ACCESS_TOKEN,
} from "./auth.constants";

import { JwtService } from "../../../services/Jwt.service";

const initialState = {
  refreshTokenPromise: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_REFRESH_TOKEN_PROMISE:
      return {
        ...state,
        refreshTokenPromise: initialState.refreshTokenPromise,
      };
    case SAVE_REFRESH_TOKEN_PROMISE:
      return {
        ...state,
        refreshTokenPromise: action.promise,
      };
    case SET_ACCESS_TOKEN:
      /*
       * At this point, we save the access token into local storage.
       * This is where the access token is assumed in both Angular
       * and React code.
       *
       * Storing into the redux global state is just for the debugging
       * purposes and outlines the place where it should be stored
       * when the whole app is converted to React
       */
      JwtService.storeToken(action.token);
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};
