import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { Card, CardContent, CardActions, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { ActionsPalette } from "../../palette/ActionsPalette";

import { ViolationTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface IActionRestrictionCardProps {
  actionInfoType: "SUCCESS" | "WARNING" | "ERROR";
  handleDialogOpen?: (violations: ViolationTo[]) => void;
  isAction?: boolean;
  violations?: ViolationTo[];
}

export const ActionRestrictionCard = ({
  actionInfoType,
  handleDialogOpen,
  isAction,
  violations = [],
}: IActionRestrictionCardProps) => {
  const classes = useStyles();

  const getCardInfo = () => {
    let cardBackgroundClass;
    let cardAccentClass;
    let cardMessageId;

    switch (actionInfoType) {
      case "ERROR":
        cardBackgroundClass = classes.cardError;
        cardAccentClass = classes.cardErrorAccent;
        cardMessageId = "ActionRestrictionsInfo.errorTitle";
        break;
      case "WARNING":
        cardBackgroundClass = classes.cardWarning;
        cardAccentClass = classes.cardWarningAccent;
        cardMessageId = "ActionRestrictionsInfo.warningTitle";
        break;
      case "SUCCESS":
        cardBackgroundClass = classes.cardSuccess;
        cardAccentClass = classes.cardSuccessAccent;
        cardMessageId = "ActionRestrictionsInfo.successTitle";
        break;
      default:
        cardBackgroundClass = "";
        cardAccentClass = "";
        cardMessageId = "";
    }

    return { cardBackgroundClass, cardAccentClass, cardMessageId };
  };

  const { cardAccentClass, cardBackgroundClass, cardMessageId } = getCardInfo();

  return (
    <Card className={classnames(classes.card, cardBackgroundClass)}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          {actionInfoType !== "SUCCESS" ? (
            <WarningIcon className={cardAccentClass} />
          ) : (
            <CheckCircleIcon className={cardAccentClass} />
          )}
        </div>
        <Box display="inline" fontWeight="400">
          <FormattedMessage id={cardMessageId} />
        </Box>
      </CardContent>
      {isAction && handleDialogOpen && (
        <CardActions>
          <Button
            className={cardAccentClass}
            onClick={() => handleDialogOpen(violations)}
            size="small"
          >
            <FormattedMessage id="common.show" />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  cardError: {
    backgroundColor: ActionsPalette.error.light,
  },
  cardErrorAccent: {
    color: ActionsPalette.error.main,
  },
  cardWarning: {
    backgroundColor: ActionsPalette.warning.light,
  },
  cardWarningAccent: {
    color: ActionsPalette.warning.main,
  },
  cardSuccess: {
    backgroundColor: ActionsPalette.success.light,
  },
  cardSuccessAccent: {
    color: ActionsPalette.success.main,
  },
  card: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    padding: "0 10px",
  },
  content: {
    flex: "1",
    display: "flex",
    gap: 8,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  header: {
    display: "inline-flex",
    color: ActionsPalette.neutral.main,
  },
}));
