import React, { FC } from "react";

import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  cell: {
    width: 90,
    border: "1px solid",
    textAlign: "center",
  },
  firstCell: {
    width: 120,
    textAlign: "left",
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));

interface TableBodyRowKMGProps {
  index: number;
  item: {
    compliant: string;
    good: string;
    name: string;
    nonCompliant: string;
  };
}

const TableBodyRowKMG: FC<TableBodyRowKMGProps> = ({ index, item }) => {
  const classes = useStyles();
  const { compliant, good, name, nonCompliant } = item;

  return (
    <TableRow key={index}>
      <TableCell
        classes={{ root: classNames(classes.cell, classes.firstCell) }}
      >
        <p className={classes.text}>{name}</p>
      </TableCell>
      <TableCell classes={{ root: classes.cell }}>{nonCompliant}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{good}</TableCell>
      <TableCell classes={{ root: classes.cell }}>{compliant}</TableCell>
    </TableRow>
  );
};

export default TableBodyRowKMG;
