import React from "react";

import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

type Props = {
  resetRedirect: () => void;
  signupRedirect: () => void;
};

const LoginLinks = (props: Props) => {
  const classes = useStyles();
  return (
    <Grid alignItems="center" container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <a
          className={classes.link}
          href="#"
          id="forgot-password"
          onClick={props.resetRedirect}
        >
          <FormattedMessage id="Login.forgot-password-question" />
        </a>
      </Grid>
      <Grid item xs={12}>
        <FormattedMessage id="Login.dont-have-account" />
        &nbsp;
        <a className={classes.link} href="#" onClick={props.signupRedirect}>
          <FormattedMessage id="Login.dont-have-account-register" />
        </a>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    "&:visited, &:hover, &:focus": {
      color: theme.palette.primary.main,
    },
  },
}));

export { LoginLinks };
