import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfLabel from "../../../../../shared/components/common/CfLabel/CfLabel";

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    l: {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
    },
    [breakpoints.down("sm")]: {
      l: {
        width: "min-content",
      },
    },
  };
};

function ActionLabel(props) {
  const { classes } = props;
  return (
    <CfLabel
      classes={{ label: classes.l }}
      message={<FormattedMessage id="ActionsList.variableApplication" />}
    />
  );
}

ActionLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};

ActionLabel.defaultProps = {
  labelStyle: {},
};

export default withStyles(styles)(ActionLabel);
