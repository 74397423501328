/**
 * Created by ondrejzvara on 9.4.16.
 */

/*@ngInject*/
export default function Logger(LogLevelManager) {
  var service = {
    setLogLevel: setLogLevel,
    getLogLevel: getLogLevel,
    enableAll: enableAll,
    disableAll: disableAll,
    error: error,
    warn: warn,
    printa: printa,
    info: info,
    log: log,
    debug: debug,
  };
  return service;

  /////////////////////////

  function setLogLevel(logLevelName) {
    LogLevelManager.setLogLevel(logLevelName, service);
  }

  function getLogLevel() {
    return LogLevelManager.getLogLevel();
  }

  function enableAll() {
    setLogLevel("debug");
  }

  function disableAll() {
    setLogLevel("off");
  }

  function error() {}

  function warn() {}

  function printa() {}

  function info() {}

  function log() {}

  function debug() {}
}
