import React, { useEffect, useContext, useState } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import { FormControlLabel, Switch, TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getPlantProtection,
  getPlantProtectionCount,
  getIsFetching,
} from "../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";
import {
  getSectionListAdvancedFilter,
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../selectors/sectionList.selectors";

import {
  fetchPlantProtection,
  resetPlantProtection,
  patchPlanProtection,
} from "../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../reducers/namespace.reducer";

import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { getColDesc } from "../../../shared/misc/helper";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../types";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";

import PlanProtectionAdvancedFilter from "./PlanProtectionAdvancedFilter/PlanProtectionAdvancedFilter";
import { COLUMN_NAMES } from "./plantProtection.columns";
import PlantProtectionListRow from "./PlantProtectionListRow";

import {
  CataloguesState,
  CATALOGUES_NAMESPACES,
} from "../../../reducers/catalogues.reducer.types";
import { PlantProtectionPatchTo } from "../../../shared/api/agroevidence/agroevidence.types";

export const columns = {
  [COLUMN_NAMES.IS_FAVORITE]: getColDesc(true, <StarBorderRoundedIcon />, {
    paddingLeft: 16,
    width: 30,
  }),
  [COLUMN_NAMES.NAME]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.planProtection.column.name" />,
  ),
  [COLUMN_NAMES.REG_NUMBER]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.planProtection.column.registrationNumber" />,
  ),
  [COLUMN_NAMES.AUTH_HOLDER]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.planProtection.column.authorizationHolder" />,
  ),
  [COLUMN_NAMES.VALID_TO]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.planProtection.column.validTo" />,
  ),
  [COLUMN_NAMES.CROP]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.planProtection.column.crop" />,
    { maxWidth: 100 },
  ),
  [COLUMN_NAMES.ORGANISMUS]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.planProtection.column.organismus" />,
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;

const PlantProtectionList = ({
  advancedFilter,
  count,
  fetchPlantProtection,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  patchPlanProtection,
  plantProtection,
  resetPlantProtection,
  rowsPerPage,
  search,
  setNamespace,
}: ReduxProps) => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  const [showHistorical, setShowHistorical] = useState(false);
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    resetPlantProtection();
    setNamespace(CATALOGUES_NAMESPACES.PLANT_PROTECTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const usableUntil = !showHistorical ? getShortDateString() : undefined;
    fetchPlantProtection(usableUntil);
    // eslint-disable-next-line max-len
  }, [
    page,
    order,
    orderBy,
    rowsPerPage,
    search,
    advancedFilter,
    fetchPlantProtection,
    langId,
    showHistorical,
    shouldReloadData,
  ]);

  if (namespace !== CATALOGUES_NAMESPACES.PLANT_PROTECTION) return null;

  const onUpdateIsFavorite = (
    planProtectionId: string,
    isFavorite: boolean,
  ) => {
    (patchPlanProtection as unknown as AsyncFn<string, PlantProtectionPatchTo>)(
      planProtectionId,
      { isFavorite: !isFavorite },
    ).then(() => setShouldReloadData((prevState) => !prevState));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="planProtection-list-text-filter"
              namespace={namespace}
              translId="Catalogues.table.planProtection.search"
            />
          </div>
          <div className={classes.advancedFilter}>
            <PlanProtectionAdvancedFilter
              langId={langId}
              namespace={namespace}
            />
          </div>
        </div>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          className={classes.switch}
          label={<FormattedMessage id="Catalogues.table.shared.showHistory" />}
          control={
            <Switch
              checked={showHistorical}
              color="primary"
              onChange={() => setShowHistorical((prevState) => !prevState)}
            />
          }
        />
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {plantProtection.length && !isFetching ? (
          <TableBody>
            {plantProtection.map((item) => (
              <PlantProtectionListRow
                data={item}
                key={item.id}
                onUpdateIsFavorite={onUpdateIsFavorite}
              />
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  plantProtection: getPlantProtection(state),
  isFetching: getIsFetching(state),
  count: getPlantProtectionCount(state),
  namespace: getSelectedNamespace(state),
  search: getSectionListSearch(state),
  advancedFilter: getSectionListAdvancedFilter(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchPlantProtection,
      resetPlantProtection,
      patchPlanProtection,
      setNamespace: setSelectedNamespace,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PlantProtectionList);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  switch: {
    marginLeft: "auto",
  },
  switchLabel: {
    fontSize: 14,
  },
}));
