import React, { FC, ReactNode, useMemo } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { ActionType } from "../../../../../../../shared/api/agroevidence/agroevidence.types";
import { ActivitiesState } from "../../../../../../../shared/api/agroevidence/parcels/parcels.types";

interface ActivitiesSelectorProps {
  defaultActivities: ActivitiesState[];
  label: ReactNode;
  onChange: (items: ActivitiesState[]) => void;
}

export const ActionTypeArray = Object.values(ActionType);

const ActivitiesSelector: FC<ActivitiesSelectorProps> = ({
  defaultActivities = [],
  label,
  onChange,
}) => {
  const intl = useIntl();

  const suggestions = useMemo(
    () =>
      ActionTypeArray.map((s) => ({
        label: intl.formatMessage({ id: `common.${s}` }),
        name: s,
      })).sort((a, b) => a.label.localeCompare(b.label)),
    [intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultActivities}
      id="activities-filter"
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId="activities-filter"
    />
  );
};

export default ActivitiesSelector;
