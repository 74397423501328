import React, { FC, useState, useMemo } from "react";

import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTelematicsAggregationsBulkEditMode } from "../../selectors/telematicsAggregations.selectors";

import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../shared/misc/helper";
import { Thunk } from "../../../types";
import DoubleLinedHeader from "../DoubleLinedHeader/DoubleLinedHeader";

import RideRow from "./RideRow";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  LogbookAggregatedItemTo,
  LogbookAggregatedTo,
} from "../../../shared/api/telematics/telematics.types";

interface Props {
  bulkEditMode: boolean;
  data: LogbookAggregatedItemTo[];
  isInit?: boolean;
  mainRowData: LogbookAggregatedTo;
  opened: boolean;
}

const generateColumns = (bulkEditMode: boolean) => ({
  id: getColDesc(true, <></>, !bulkEditMode ? { display: "none" } : undefined),
  duration: getColDesc(
    true,
    <span style={{ marginLeft: 16 }}>
      <FormattedMessage id="TelematicsAggregations.list.duration" />
    </span>,
  ),
  operation: getColDesc(
    true,
    <FormattedMessage id="TelematicsAggregations.list.operation" />,
  ),
  productionOperation: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.productionOperation" />,
  ),
  parcel: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.parcel"
      secondaryId="TelematicsAggregations.list.crop"
    />,
  ),
  area: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.operationArea"
      secondaryId="TelematicsAggregations.list.parcelArea"
    />,
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.distance" />,
  ),
  machine: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.machine" />,
  ),
  additionalEquipment: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.additionalEquipment" />,
  ),
  approvals: getColDesc(false, null),
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: (opened) => opened && theme.palette.grey[100],
  },
  paper: {
    boxShadow: "none",
    borderBotton: "none",
  },
}));

const AggregatedRidesList: FC<Props> = ({
  bulkEditMode,
  data,
  isInit,
  mainRowData,
  opened,
}) => {
  const [orderBy, setOrderBy] = useState("");
  const [ascOrdered, setAscOrdered] = useState(true);

  const classes = useStyles(opened);

  const columns = generateColumns(bulkEditMode);

  const handleSortClick = (colId: string) => () => {
    if (colId === orderBy) {
      setAscOrdered((prevState) => !prevState);
    } else {
      setOrderBy(colId);
      setAscOrdered(true);
    }
  };

  const sortedData = useMemo(() => {
    if (!orderBy) return data;
    return _.orderBy(data, orderBy, ascOrdered ? "asc" : "desc");
  }, [data, ascOrdered, orderBy]);

  return (
    <CfTableWrapper
      customPaperStyles={classes.paper}
      customTableStyles={classes.container}
      testId="aggregated-rides-detail-table"
    >
      <CfTableHead
        columns={columns}
        customSortHandler={handleSortClick}
        items={sortedData}
        order={ascOrdered ? "asc" : "desc"}
        orderBy={orderBy}
      />
      <TableBody className={classes.container}>
        {sortedData.map((drive) => (
          <RideRow
            data={drive}
            isInit={isInit}
            key={drive.id}
            mainRowData={mainRowData}
          />
        ))}
      </TableBody>
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AggregatedRidesList);
