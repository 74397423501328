import React, { ReactNode, CSSProperties } from "react";

import { TableCellProps } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { getColDesc } from "../../shared/misc/helper";
import TCDoubleLinedHeader from "../components/telematics/shared/TCDoubleLinedHeader";

type ColumnConfig = {
  id: string;
  primaryLabel: string;
  secondaryLabel?: string;
  sortable?: boolean;
  style?: CSSProperties;
};
export type ColumnData = {
  align: TableCellProps["align"];
  label: ReactNode;
  rightPadding: boolean;
  sortable: boolean;
  style?: CSSProperties;
};

export const createColumns = (config: ColumnConfig[]) =>
  config.reduce(
    (acc, col) => {
      acc[col.id] = getColDesc(
        !!col?.sortable,
        col.primaryLabel && col.secondaryLabel ? (
          <TCDoubleLinedHeader
            primaryId={col.primaryLabel}
            secondaryId={col.secondaryLabel}
          />
        ) : (
          <FormattedMessage id={col.primaryLabel} />
        ),
        col.style,
      );

      return acc;
    },
    {} as Record<string, ColumnData>,
  );
