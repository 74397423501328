import { IrrigationState } from "../../reducers/irrigation.reducer.types";

export const getAreaDevicesTextFilter = (state: IrrigationState) =>
  state.ui.areaDevicesList.textFilter;
export const getAreaDevicesPage = (state: IrrigationState) =>
  state.ui.areaDevicesList.page;
export const getAreaDevicesOrder = (state: IrrigationState) =>
  state.ui.areaDevicesList.order;
export const getAreaDevicesOrderBy = (state: IrrigationState) =>
  state.ui.areaDevicesList.orderBy;
export const getAreaDevicesRowsPerPage = (state: IrrigationState) =>
  state.ui.areaDevicesList.rowsPerPage;
export const getAreaDevicesSelected = (state: IrrigationState) =>
  state.ui.areaDevicesList.selected;
