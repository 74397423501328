import * as dataTypes from "../constants/dataTypes.constants";

import { getPredictionHistoryData as getPredictionHistoryDataApi } from "../../shared/api/iot/locations/locations.api";
import { setPredictionConfig as setPredictionConfigApi } from "../../shared/api/iot/predictions/predictions.api";

export const fetchPredictionData =
  (
    computedFeatureId,
    locationId,
    dateFrom = null,
    dateTo = null,
    dataType = dataTypes.NOW,
    occurrence = null,
  ) =>
  (dispatch) => {
    dispatch(
      getPredictionHistoryDataApi(
        computedFeatureId,
        locationId,
        dateFrom,
        dateTo,
        dataType,
        occurrence,
      ),
    );
  };

export const setPredictionConfig =
  (nodeLocationId, featureLocationId, configProps) => (dispatch) => {
    const dto = {
      ...("visible" in configProps ? { visible: configProps.visible } : {}),
      ...(configProps.day ? { validAfterDay: configProps.day } : {}),
      ...(configProps.month ? { validAfterMonth: configProps.month } : {}),
    };
    dispatch(setPredictionConfigApi(featureLocationId, dto));
  };
