import React, { useContext, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";

import {
  AS_APPLIED_IMPORT_FILES_ERROR,
  AS_APPLIED_VALIDATE_FILES_ERROR,
} from "../../api/sentinel/asAppliedTaskdata/asAppliedTaskdata.constants";

import { FileLoadItem } from "../../../telematics/components/FileUploader/FileUploader";
import { AsyncFn } from "../../../types";
import {
  ImportFilesApiType,
  ValidateFileApiType,
} from "../../api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import { SnackbarContext } from "../../containers/SnackbarProvider/SnackbarProvider";
import { insertArray } from "../../misc/helpers";

import { TaskDataImportResponseFileTo } from "../../api/satellite/satellite.types";

export const MAX_FILE_SIZE_MB = 10;
export const IMPORT_ERROR_TOO_LARGE = "TOO_LARGE";

export type FileImportStatus =
  | "validating"
  | "submitting"
  | "success"
  | undefined;

interface Props {
  importFilesApi: (params: ImportFilesApiType) => void;
  validateFileApi: (params: ValidateFileApiType) => void;
}

const useFileImport = ({ importFilesApi, validateFileApi }: Props) => {
  const showSnackbar = useContext(SnackbarContext);

  const [allFiles, setAllFiles] = useState<FileLoadItem[]>([]);
  const [status, setStatus] = useState<FileImportStatus>(undefined);

  const getSizeInMB = (item: FileLoadItem) => item.file.size / (1024 * 1024);
  const getRoundedSize = (sizeInMB: number) => Math.ceil(sizeInMB * 10) / 10;

  useEffect(() => {
    if (status === undefined) {
      const success =
        allFiles.length > 0 && !allFiles.find((f) => f.status !== "success");
      setStatus(success ? "success" : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleFilesValidationApi = async (newFiles: FileLoadItem[]) => {
    setStatus("validating");

    const validationPromises = newFiles.map((fileItem) => {
      const params: ValidateFileApiType = {
        file: fileItem.file,
      };

      // eslint-disable-next-line no-async-promise-executor
      return new Promise<FileLoadItem>(async (resolve) => {
        try {
          const res = await (validateFileApi as AsyncFn<ValidateFileApiType>)(
            params,
          );

          const data = res.payload as TaskDataImportResponseFileTo;

          const sizeMb = getRoundedSize(getSizeInMB(fileItem));
          const tooLarge = MAX_FILE_SIZE_MB <= sizeMb;

          if (
            res.type === AS_APPLIED_VALIDATE_FILES_ERROR ||
            data.error ||
            tooLarge
          ) {
            fileItem.status = "error";
            fileItem.errorCode = tooLarge ? IMPORT_ERROR_TOO_LARGE : data.error;
          } else {
            fileItem.status = "validated";
          }

          resolve(fileItem);
        } catch (error) {
          fileItem.status = "error";
          resolve(fileItem);
        }
      });
    });

    try {
      await Promise.all(validationPromises);
    } finally {
      setStatus(undefined);
    }
  };

  const handleFilesImportApi = async () => {
    setStatus("submitting");
    const allFilesUpdated = allFiles.filter((f) => f.status !== "success");
    setAllFiles(allFilesUpdated);

    let roundedSizeMB = 0;

    let lastSentIndex = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = lastSentIndex; i < allFilesUpdated.length; i++) {
      const isLast = i + 1 === allFilesUpdated.length;

      const item = allFilesUpdated[i];
      const nextItem = isLast ? undefined : allFilesUpdated[i + 1];

      roundedSizeMB += getRoundedSize(getSizeInMB(item));

      const nextItemRoundedSize =
        nextItem && !isLast ? getRoundedSize(getSizeInMB(nextItem)) : 0;

      const freeSpace = roundedSizeMB + nextItemRoundedSize < 9;
      if (!freeSpace || isLast) {
        roundedSizeMB = 0;
        const sliced = allFilesUpdated.slice(lastSentIndex, i + 1);
        sliced.forEach((f) => {
          f.status = "isImporting";
        });

        const updated = insertArray(allFilesUpdated, lastSentIndex, sliced);
        setAllFiles(updated as FileLoadItem[]);

        // eslint-disable-next-line no-await-in-loop
        await importApiCall(sliced, lastSentIndex, allFilesUpdated);
        lastSentIndex = i + 1;
      }
    }

    setStatus(undefined);
  };

  const importApiCall = async (
    files: FileLoadItem[],
    lastSentIndex: number,
    allFilesUpdated: FileLoadItem[],
  ) => {
    const params: ImportFilesApiType = {
      files: files.map((i) => i.file),
    };

    try {
      const res = await (importFilesApi as AsyncFn<ImportFilesApiType>)(params);

      const data = res.payload as { file: TaskDataImportResponseFileTo[] };

      const errorIndexes = data.file
        .map((item, index) => ({ error: item.error, index }))
        .filter((item) => !!item.error)
        .map((item) => item.index);

      const newFilesCopy = [...files];
      newFilesCopy.forEach((__, i) => {
        if (
          errorIndexes.includes(i) ||
          res.type === AS_APPLIED_IMPORT_FILES_ERROR
        ) {
          newFilesCopy[i].status = "error";
        } else {
          newFilesCopy[i].status = "success";
        }
      });

      const updated = insertArray(allFilesUpdated, lastSentIndex, newFilesCopy);
      setAllFiles(updated as FileLoadItem[]);
    } catch (error) {
      showSnackbar({
        message: <FormattedMessage id="FileUploader.modal.import.error" />,
        isError: true,
      });
    }
  };

  return {
    allFiles,
    setAllFiles,
    status,
    handleFilesImportApi,
    handleFilesValidationApi,
  };
};

export default useFileImport;
