import React from "react";

import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  helperText: {
    position: "absolute",
    bottom: -22,
  },
};

function CfFormikTextField(props) {
  const {
    children,
    classes,
    customClasses,
    disableValue,
    field,
    form,
    shrinkLabel,
    useDefaultHelperText,
    validateOnBlur,
    ...restProps
  } = props;

  const shrinkLabelProps = {
    InputLabelProps: { shrink: true },
  };

  const formHelperTextProps = {
    classes: {
      root: classes.helperText,
    },
  };

  const error = form.errors[field.name];
  return (
    <TextField
      classes={customClasses}
      error={Boolean(error)}
      helperText={error}
      name={field.name}
      onChange={field.onChange}
      value={disableValue ? "" : field.value}
      FormHelperTextProps={
        useDefaultHelperText ? undefined : formHelperTextProps
      }
      onBlur={(e) => {
        field.onBlur(e);
        if (validateOnBlur) {
          form.validateField(field.name);
        }
      }}
      {...(shrinkLabel && shrinkLabelProps)}
      {...restProps}
    >
      {children}
    </TextField>
  );
}

CfFormikTextField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  disableValue: PropTypes.bool,
  shrinkLabel: PropTypes.bool,
  useDefaultHelperText: PropTypes.bool,
};

CfFormikTextField.defaultProps = {
  children: null,
  validateOnBlur: false,
  disableValue: false,
  shrinkLabel: false,
  useDefaultHelperText: false,
};

export default withStyles(styles)(CfFormikTextField);
