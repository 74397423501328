import { AnyAction } from "redux";

import * as types from "./sectionStatus.constants";

import { TelematicsStatusState } from "./sectionStatus.types";

export default () =>
  (
    state: TelematicsStatusState = {
      telematics: false,
      verified: false,
      catalogue: undefined,
      economicSystem: undefined,
      economicSystemDate: undefined,
      automaticActions: false,
      externalId: "",
    },
    action: AnyAction,
  ) => {
    switch (action.type) {
      case types.GET_TELEMATIC_STATUS_SUCCESS:
        return {
          ...state,
          ...action.payload,
          verified: true,
        };
      default:
        return state;
    }
  };
