import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ValveFilledIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0C2.33625 4.095 0 7.632 0 10.62C0 15.102 3.325 18 7 18C10.675 18 14 15.102 14 10.62C14 7.632 11.6637 4.095 7 0Z" />
    </svg>
  </SvgIcon>
);

export default ValveFilledIcon;
