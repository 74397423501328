import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";

import { Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormikProps } from "formik";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcel,
  getParcelError,
  getParcel,
} from "../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getDriveDetail,
  getIsFetchingDriveDetail,
} from "../../../shared/api/telematics/drives/drives.selectors";
import {
  getTelematicsDetailFormSubmissionResult,
  getTelematicsDetailIsFormSubmitting,
} from "../../selectors/telematicsDetail.selectors";

import { setFocusedRow } from "../../../shared/api/telematics/drives/drives.actions";
import {
  createDriveSaga,
  editDriveSaga,
  resetFormSubmission,
} from "../../actions/telematicsDetail.actions";

import { TELEMATICS_URLS } from "../../telematics.constants";

import { getParcelApi } from "../../../shared/api/agroevidence/parcels/parcels.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import ShowHideMap from "../../../shared/components/common/ShowHideMap/ShowHideMap";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useFocusedTableRowSource from "../../../shared/hooks/useFocusedTableRowSource";
import useWidth from "../../../shared/hooks/useWidth";
import { Thunk } from "../../../types";
import TelematicsDetailContent from "../../components/TelematicsDetailContent/TelematicsDetailContent";
import TelematicsDetailMap from "../../components/TelematicsDetailMap/TelematicsDetailMap";

import TelematicsRideDetailHeader from "./TelematicsRideDetailHeader";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { ParcelDetailTo } from "../../../shared/api/agroevidence/agroevidence.types";
import { DrivePartDetailTo } from "../../../shared/api/telematics/telematics.types";
import { TelematicsItemFormValues } from "../../telematics.types";

export const useTelematicsRideDetailStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
  },
  spinnerWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #EFEFEF",
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  bodyWrapper: {
    position: "relative",
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  mapWrapper: {
    height: "100%",
  },
  badge: {
    width: "fit-content",
    margin: "0 auto",
  },
}));

interface Props {
  createDrive: (
    values: TelematicsItemFormValues,
    parcelId: string | null | undefined,
  ) => void;
  driveDetail?: DrivePartDetailTo;
  editDrive: (
    driveId: string,
    values: TelematicsItemFormValues,
    parcelId: string | null | undefined,
  ) => void;
  farmId: string;
  getParcelApi: (parcelId: string) => void;
  initialized: boolean;
  isFetchingDriveDetail: boolean;
  isFetchingParcelItem: boolean;
  isParcelError: boolean;
  isSubmitting: boolean;
  parcel: ParcelDetailTo;
  resetFormSubmission: () => void;
  setFocusedRow: (val: string) => void;
  submissionResult: number | null;
}

const TelematicsRideDetail: FC<Props> = ({
  createDrive,
  driveDetail,
  editDrive,
  farmId,
  getParcelApi,
  initialized,
  isFetchingDriveDetail,
  isFetchingParcelItem,
  isParcelError,
  isSubmitting,
  parcel,
  resetFormSubmission,
  setFocusedRow,
  submissionResult,
}) => {
  const classes = useTelematicsRideDetailStyles();
  const width = useWidth();

  const history = useHistory();
  const { telematicsId: driveId } = useParams<{ telematicsId: string }>();
  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: driveId,
    setFocusedRow,
  });

  const isNew = driveId === "new";
  const error = {};

  const checkIsDesktop = (currentWidth: string) =>
    ["md", "lg", "xl"].indexOf(currentWidth) >= 0;
  const [isDesktop, setIsDesktop] = useState(checkIsDesktop(width));
  const [displayMap, setDisplayMap] = useState(isDesktop);
  const displayContent = !displayMap || (width !== "xs" && width !== "sm");

  const [parcelId, setParcelId] = useState<string | null | undefined>();
  const [isEditing, setIsEditing] = useState(isNew);

  const formikRef = useRef<FormikProps<TelematicsItemFormValues>>(null);

  const [createMore, setCreateMore] = useState(false);
  const showSnackbar = useContext(SnackbarContext);

  const handleGoBack = useCallback(() => {
    doSetFocusedRow();
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.logbook}`);
  }, [doSetFocusedRow, history, farmId]);

  useEffect(() => {
    if (driveDetail?.parcelId && initialized) {
      setParcelId(driveDetail.parcelId);
    }
  }, [driveDetail, initialized]);

  useEffect(() => {
    if (parcelId) {
      getParcelApi(parcelId);
    }
  }, [getParcelApi, parcelId]);

  useEffect(() => {
    const currentIsDesktop = isDesktop;
    const newIsDesktop = checkIsDesktop(width);

    if (currentIsDesktop !== newIsDesktop && newIsDesktop !== displayMap) {
      setDisplayMap(!displayMap);
    }

    setIsDesktop(newIsDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (submissionResult === 200) {
      if (isNew) {
        showSnackbar({
          message: <FormattedMessage id="TelematicsDetail.createRideSuccess" />,
          isSuccess: true,
        });
        if (createMore) {
          formikRef?.current?.handleReset();
          setParcelId(null);
          setCreateMore(false);
        } else {
          handleGoBack();
        }
      } else {
        showSnackbar({
          message: <FormattedMessage id="TelematicsDetail.editRideSuccess" />,
          isSuccess: true,
        });
        handleGoBack();
      }
      resetFormSubmission();
    }
  }, [
    isNew,
    submissionResult,
    createMore,
    handleGoBack,
    showSnackbar,
    resetFormSubmission,
  ]);

  const handleToggleMap = () => {
    setDisplayMap(!displayMap);
  };

  const handleReset = () => {
    setParcelId(driveDetail?.parcelId);
    if (!isNew) {
      setIsEditing(false);
    }
  };

  const handleSubmit = (values: TelematicsItemFormValues) => {
    if (values.createMore) {
      setCreateMore(true);
    }
    if (isNew) {
      createDrive(values, parcelId);
    } else {
      editDrive(driveId, values, parcelId);
    }
  };

  if (isFetchingDriveDetail || (isNew && driveDetail)) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (!isNew && !driveDetail) return null;

  return (
    <CfErrorPage error={error}>
      <Grid className={classes.wrapper} container>
        {displayContent && (
          <Grid
            className={classes.bodyWrapper}
            item
            md={displayMap ? 6 : 12}
            xs={12}
          >
            <Scrollbars>
              <div className={classes.body}>
                <TelematicsRideDetailHeader
                  driveDetail={driveDetail}
                  handleGoBack={handleGoBack}
                  isNew={isNew}
                />
                <Grid className={classes.contentWrapper} container>
                  <Grid
                    item
                    lg={displayMap ? "auto" : 1}
                    xl={displayMap ? "auto" : 2}
                  />
                  <Grid
                    className={classes.content}
                    item
                    lg={displayMap ? 12 : 10}
                    xl={displayMap ? 12 : 8}
                  >
                    <TelematicsDetailContent
                      driveDetail={driveDetail}
                      formikRef={formikRef}
                      goBack={handleGoBack}
                      isEditing={isEditing}
                      isNew={isNew}
                      isSubmitting={isSubmitting}
                      onReset={handleReset}
                      onSubmit={handleSubmit}
                      parcel={parcelId ? parcel : null}
                      submitError={submissionResult === 500}
                      isFetchingParcel={
                        !!parcelId && isFetchingParcelItem && !isParcelError
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    lg={displayMap ? "auto" : 1}
                    xl={displayMap ? "auto" : 2}
                  />
                </Grid>
              </div>
            </Scrollbars>
            <ShowHideMap
              handleClick={handleToggleMap}
              isMapHidden={!displayMap}
            />
          </Grid>
        )}
        <Grid
          className={classes.mapWrapper}
          item
          md={6}
          style={{ display: displayMap ? "block" : "none" }}
          xs={12}
        >
          {!displayContent && (
            <ShowHideMap handleClick={handleToggleMap} isMapHidden={false} />
          )}
          <TelematicsDetailMap
            displayMap={displayMap}
            geometry={driveDetail?.geometry}
            geometryPerDay={driveDetail?.geometryPerDay}
            parcelId={parcelId}
            startAzimuth={driveDetail?.geometryStartAzimuth}
          />
        </Grid>
      </Grid>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  parcel: getParcel(state),
  isFetchingParcelItem: getIsFetchingParcel(state),
  isParcelError: getParcelError(state).isError,
  driveDetail: getDriveDetail(state),
  isFetchingDriveDetail: getIsFetchingDriveDetail(state),
  isSubmitting: getTelematicsDetailIsFormSubmitting(state),
  submissionResult: getTelematicsDetailFormSubmissionResult(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelApi,
      createDrive: createDriveSaga,
      editDrive: editDriveSaga,
      resetFormSubmission,
      setFocusedRow,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsRideDetail);
