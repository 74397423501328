import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  className?: string;
};

const LogoutIcon = ({ className }: Props) => (
  <SvgIcon className={className}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <polygon
        fill="currentColor"
        points="15.92 6.23 14.26 7.88 17.32 10.92 12.3 10.92 12.3 13.21 17.32 13.21 14.26 16.25 15.92 17.77 21.91 12.06 15.92 6.23"
      />
      <path
        d="M10,4.3H4.35A2.21,2.21,0,0,0,2.09,6.56V17.44A2.21,2.21,0,0,0,4.35,19.7H10a2.29,2.29,0,0,0,2.26-2.26V13.19H7.19V10.94H12.3V6.56A2.29,2.29,0,0,0,10,4.3Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export { LogoutIcon };
