import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  section: {
    display: "flex",
    position: "absolute",
  },
  custom: {},
};

const SectionWrapper = (props) => {
  const { bottom, children, classes, left, right, top, zIndex } = props;
  return (
    <div
      className={`${classes.section} ${classes.custom}`}
      style={{ top, right, bottom, left, zIndex }}
    >
      {children}
    </div>
  );
};

SectionWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.number,
};

SectionWrapper.defaultProps = {
  top: "unset",
  right: "unset",
  bottom: "unset",
  left: "unset",
  zIndex: 9,
};

export default withStyles(styles)(SectionWrapper);
