import { Location } from "history";

import { TELEMATICS_URLS } from "../../telematics.constants";

export enum TELEMATICS_TABS {
  MACHINES = "machines",
  RECORDS = "logbook",
  WORKERS = "drivers",
}

export const getPreviousTelematicsPage = (location: Location) => {
  if (location.pathname.includes(TELEMATICS_URLS.drivers)) {
    return TELEMATICS_TABS.WORKERS;
  } else if (location.pathname.includes(TELEMATICS_URLS.machines)) {
    return TELEMATICS_TABS.MACHINES;
  }
};
