import React, { FC } from "react";

import { LinearProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import {
  getOtherActionTypes,
  getIsFetchingOtherActionTypes,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { ActionTypeTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface ActionSelectorProp {
  isEditing: boolean;
  isFetchingOtherActionTypes: boolean;
  otherActionTypes: ActionTypeTo[];
}
const ActionSelector: FC<ActionSelectorProp> = ({
  isEditing,
  isFetchingOtherActionTypes,
  otherActionTypes,
}) => {
  if (isFetchingOtherActionTypes) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing}
        label={<FormattedMessage id="common.action" />}
        name="actionType"
        select
        validate={validators.required}
        validateOnBlur={true}
      >
        {otherActionTypes.map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {item.name}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  otherActionTypes: getOtherActionTypes(state),
  isFetchingOtherActionTypes: getIsFetchingOtherActionTypes(state),
});

export default connect(mapStateToProps)(ActionSelector);
