import ErrorService from "../../../services/Error.service";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

export const getParcelSowingPlan = (
  state: ParcelsState | MainMapState | TelematicsState,
) => state.api.sowingPlan.parcelSowingPlan;
export const getIsFetchingParcelSowingPlan = (
  state: ParcelsState | MainMapState | TelematicsState,
) => state.api.sowingPlan.isFetchingParcelSowingPlan;
export const getSeasonsSowingPlan = (
  state: ParcelsState | MainMapState | TelematicsState,
) => state.api.sowingPlan.seasonsSowingPlan;
export const getSeasonDetailSowingPlan = (state: ParcelsState | MainMapState) =>
  state.api.sowingPlan.seasonDetailSowingPlan;
export const getIsFetchingSeasonDetailSowingPlan = (
  state: ParcelsState | MainMapState,
) => state.api.sowingPlan.isFetchingSeasonDetailSowingPlan;

export const isExportingData = (state: MainMapState) =>
  state.api.sowingPlan.isExportingData;
export const getExportError = (state: MainMapState) =>
  ErrorService.getResErrorDto(state.api.sowingPlan.exportError);
