import React, { Component } from "react";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfTableCheckbox from "../../../containers/CfTableCheckbox/CfTableCheckbox";

const styles = (theme) => ({
  pointer: {
    cursor: "pointer",
  },
  row: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

class CfTableBody extends Component {
  getCell = (entry, columns) => {
    const key = entry[0];
    const val = entry[1];
    return (
      <TableCell
        align={columns[key].align}
        key={key}
        rowSpan={val.rowspan}
        size="small"
      >
        {typeof val.val === "function" ? val.val() : val.val}
      </TableCell>
    );
  };

  getCheckboxEl = (row) => {
    const { multiple, namespace, selected } = this.props;
    return (
      <CfTableCheckbox
        id={row.id}
        namespace={namespace}
        rowSpan={multiple ? row.multi.length + 1 : 1}
        selected={selected}
      />
    );
  };

  isSelected = (id) =>
    this.props.selected && this.props.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      columns,
      hover,
      multiple,
      onClick,
      refObj,
      row,
      selected,
    } = this.props;
    const isSelected = this.isSelected(row.id);
    return (
      <TableBody classes={{ root: classes.row }} key={row.id}>
        <TableRow
          aria-checked={isSelected}
          className={hover ? classes.pointer : {}}
          onClick={onClick}
          ref={refObj}
          selected={isSelected}
          tabIndex={-1}
        >
          {Array.isArray(selected) && this.getCheckboxEl(row, isSelected)}
          {Object.entries(row.single).map((entry) =>
            this.getCell(entry, columns),
          )}
        </TableRow>
        {multiple &&
          row.multi.map((record, index) => {
            const i = `${row.id}-${index}`;
            return (
              <TableRow
                aria-checked={isSelected}
                className={hover ? classes.pointer : {}}
                key={i}
                onClick={onClick}
                row={row}
                selected={isSelected}
                tabIndex={-1}
              >
                {Object.entries(record).map((entry) =>
                  this.getCell(entry, columns),
                )}
              </TableRow>
            );
          })}
      </TableBody>
    );
  }
}

CfTableBody.propTypes = {
  refObj: PropTypes.object,
  row: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  selected: PropTypes.array,
  namespace: PropTypes.string,
  multiple: PropTypes.bool,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

CfTableBody.defaultProps = {
  namespace: null,
  selected: null,
  multiple: true,
  hover: false,
  classes: {},
  onClick: null,
};

export default withStyles(styles)(CfTableBody);
