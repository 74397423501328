import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const BugIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 20 20" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M18.8,5.6h-3.1c-0.5-0.9-1.2-1.6-2-2.2l1.8-1.8L14,0.1l-2.4,2.4c-0.5-0.1-1-0.2-1.6-0.2S8.9,2.4,8.4,2.5L6,0.1L4.5,1.6l1.8,1.8C5.4,4,4.8,4.7,4.3,5.6H1.2v2.2h2.3C3.4,8.2,3.4,8.5,3.4,8.9V10H1.2v2.2h2.2v1.1c0,0.4,0,0.7,0.1,1.1H1.2v2.2h3.1c1.2,2,3.3,3.3,5.7,3.3c2.5,0,4.6-1.3,5.7-3.3h3.1v-2.2h-2.3c0.1-0.4,0.1-0.7,0.1-1.1v-1.1h2.2V10h-2.2V8.9c0-0.4,0-0.7-0.1-1.1h2.3V5.6z M14.4,10c0,1.1,0,2.2,0,3.3c0,2.4-2,4.4-4.4,4.4c-1.8,0-3.4-1.2-4.2-2.8c-0.2-0.5-0.3-1.1-0.3-1.6c0,0,0-4.4,0-4.4c0-0.3,0-0.5,0.1-0.8C5.7,7.7,5.8,7.4,6,7c0.5-0.8,1.2-1.6,2-2.1C8.6,4.6,9.4,4.5,10,4.5c0.9,0,1.8,0.3,2.5,0.8c1.2,0.8,1.8,2,1.9,3.4C14.4,9.1,14.4,9.6,14.4,10z M7.8,12.2h4.4v2.2H7.8V12.2z M7.8,7.8h4.4V10H7.8V7.8z" />
    </svg>
  </SvgIcon>
);

export default BugIcon;
