/**
 * Common wrapper utility extracted from redux-form-material-ui to plug in support of redux-form in own components.
 */

import { Component, createElement } from "react";

/**
 * Creates a component class that renders the given Material UI component
 *
 * @param MaterialUIComponent The material ui component to render
 * @param mapProps A mapping of props provided by redux-form to the props the Material UI
 * component needs
 */
export function createComponent(MaterialUIComponent, mapProps) {
  class InputComponent extends Component {
    // getRenderedComponent() {
    //   // eslint-disable-next-line react/no-string-refs
    //   return this.refs.component;
    // }

    render() {
      return createElement(MaterialUIComponent, {
        ...mapProps(this.props),
        // ref: 'component'
      });
    }
  }
  InputComponent.displayName = `CfReduxForm${MaterialUIComponent.name}`;
  return InputComponent;
}

export function mapError(
  { input, meta: { error, touched, warning } = {}, ...props },
  errorProp = "helperText",
) {
  return touched && (error || warning)
    ? {
        ...props,
        ...input,
        error: Boolean(error),
        [errorProp]: error || warning,
      }
    : { ...input, ...props };
}
