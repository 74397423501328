/**
 * Created by ondrejzvara on 1.11.16.
 */

import angular from "angular";

export default angular
  .module("app.classifiers.hours", [])
  /** @constant
   * @type {object}
   * @default
   */
  .constant("HoursEnums", {
    HOURS: {
      1: { value: "1,5", unit: "h" },
      3: { value: "3", unit: "h" },
      6: { value: "6", unit: "h" },
      9: { value: "9", unit: "h" },
      10: { value: "10,5", unit: "h" },
      12: { value: "12", unit: "h" },
      15: { value: "15", unit: "h" },
      18: { value: "18", unit: "h" },
      21: { value: "21", unit: "h" },
      22: { value: "22,5", unit: "h" },
      24: { value: "24", unit: "h" },
    },
  }).name;
