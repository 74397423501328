import React from "react";

import uniq from "lodash/uniq";
import { Dot } from "recharts";

import * as aggregations from "../../shared/constants/aggregations.constants";
import * as chartFeatureTypes from "../../shared/constants/chartFeatureTypes.constants";

import AtmosphericPressure from "../../shared/icons/sensorsFeatures/AtmosphericPressure";
import ElectricalConductivityIcon from "../../shared/icons/sensorsFeatures/ElectricalConductivityIcon";
import HumidityIcon from "../../shared/icons/sensorsFeatures/HumidityIcon";
import SoilMoistureIcon from "../../shared/icons/sensorsFeatures/SoilMoisture";
import SoilTemperatureIcon from "../../shared/icons/sensorsFeatures/SoilTemperatureIcon";
import SolarRadiation from "../../shared/icons/sensorsFeatures/SolarRadiation";
import SwpIcon from "../../shared/icons/sensorsFeatures/SwpIcon";
import TemperatureIcon from "../../shared/icons/sensorsFeatures/TemperatureIcon";
import WindGustIcon from "../../shared/icons/sensorsFeatures/WindGustIcon";
import WindIcon from "../../shared/icons/sensorsFeatures/WindIcon";
import CustomizedDot from "../components/CustomizedDot/CustomizedDot";

export const FEATURE_TYPE_PREFIX = "FEATURE_";

/* Definitions of configs */

export const FEATURE_TYPES = {
  FEATURE_TEMPERATURE: "FEATURE_TEMPERATURE",
  FEATURE_HUMIDITY: "FEATURE_HUMIDITY",
  FEATURE_RAINFALL: "FEATURE_RAINFALL",
  FEATURE_SWP: "FEATURE_SWP",
  FEATURE_SOIL_MOISTURE: "FEATURE_SOIL_MOISTURE",
  FEATURE_STORAGE: "FEATURE_STORAGE",
  FEATURE_LEAF_WETNESS: "FEATURE_LEAF_WETNESS",
  FEATURE_WIND_DIRECTION: "FEATURE_WIND_DIRECTION",
  FEATURE_WIND_GUST: "FEATURE_WIND_GUST",
  FEATURE_WIND_SPEED: "FEATURE_WIND_SPEED",
  FEATURE_VALVE: "FEATURE_VALVE",
  FEATURE_SOIL_TEMPERATURE: "FEATURE_SOIL_TEMPERATURE",
  FEATURE_BULK_ELECTRICAL_CONDUCTIVITY: "FEATURE_BULK_ELECTRICAL_CONDUCTIVITY",
  FEATURE_IRRADIATION: "FEATURE_IRRADIATION",
  FEATURE_PRESSURE: "FEATURE_PRESSURE",
  FEATURE_EVAPOTRANSPIRATION: "FEATURE_EVAPOTRANSPIRATION",
};

export const FEATURE_CONFIG = {
  [`${FEATURE_TYPES.FEATURE_TEMPERATURE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_TEMPERATURE,
    unit: "°C",
    stroke: "#D01D02",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    zeroLine: true,
    notifications: true,
    icon: TemperatureIcon,
  },
  [`${FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE,
    unit: "°C",
    stroke: "#DB6B35",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    zeroLine: true,
    notifications: true,
    icon: SoilTemperatureIcon,
  },
  [`${FEATURE_TYPES.FEATURE_HUMIDITY}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_HUMIDITY,
    unit: "%",
    stroke: "#0A4D9C",
    fill: "#0A4D9C",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: HumidityIcon,
  },
  [`${FEATURE_TYPES.FEATURE_RAINFALL}_${aggregations.INCREMENT}`]: {
    name: FEATURE_TYPES.FEATURE_RAINFALL,
    unit: "mm",
    stroke: "#6AA8EC",
    fill: "#6AA8EC",
    chartFeatureType: chartFeatureTypes.BAR,
    aggregation: aggregations.INCREMENT,
  },
  [`${FEATURE_TYPES.FEATURE_RAINFALL}_${aggregations.SUM}`]: {
    name: FEATURE_TYPES.FEATURE_RAINFALL,
    unit: "mm",
    stroke: "#b9d8ff",
    strokeWidth: 0,
    tooltipStroke: "#6AA8EC",
    fill: "#b9d8ff",
    fillOpacity: 0.5,
    chartFeatureType: chartFeatureTypes.AREA,
    aggregation: aggregations.SUM,
  },
  [`${FEATURE_TYPES.FEATURE_SWP}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_SWP,
    unit: "cbar",
    stroke: "#0067D7",
    fill: "#0067D7",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: SwpIcon,
  },
  [`${FEATURE_TYPES.FEATURE_SOIL_MOISTURE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_SOIL_MOISTURE,
    unit: "%",
    stroke: "#874100",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: SoilMoistureIcon,
  },
  [`${FEATURE_TYPES.FEATURE_STORAGE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_STORAGE,
    unit: "°C",
    stroke: "#D01D02",
    strokeWidth: 1,
    fill: "#D01D02",
    fillOpacity: 0.5,
    chartFeatureType: chartFeatureTypes.AREA,
    aggregation: aggregations.AVG,
    levels: true,
    icon: TemperatureIcon,
  },
  [`${FEATURE_TYPES.FEATURE_LEAF_WETNESS}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_LEAF_WETNESS,
    unit: null,
    stroke: "#7ED321",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
  },
  [`${FEATURE_TYPES.FEATURE_WIND_DIRECTION}_${aggregations.MAX_COUNT}`]: {
    name: FEATURE_TYPES.FEATURE_WIND_DIRECTION,
    unit: "m/s",
    stroke: "none",
    tooltipStroke: "#51AF98",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.MAX_COUNT,
    activeDot: <Dot r={0} />,
  },
  [`${FEATURE_TYPES.FEATURE_WIND_GUST}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_WIND_GUST,
    unit: "m/s",
    stroke: "#51AF98",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: WindGustIcon,
  },
  [`${FEATURE_TYPES.FEATURE_WIND_SPEED}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_WIND_SPEED,
    unit: "m/s",
    stroke: "#51AF98",
    strokeWidth: 0,
    fill: "#D8F1EB",
    fillOpacity: 0.5,
    chartFeatureType: chartFeatureTypes.AREA,
    aggregation: aggregations.AVG,
    dot: <CustomizedDot color="#51AF98" />,
    notifications: true,
    icon: WindIcon,
  },
  [`${FEATURE_TYPES.FEATURE_VALVE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_VALVE,
    unit: null,
    stroke: "#848484",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    levels: true,
  },
  [`${FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY}_${aggregations.AVG}`]:
    {
      name: FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY,
      unit: "dS/m",
      stroke: "#6A00B5",
      chartFeatureType: chartFeatureTypes.LINE,
      aggregation: aggregations.AVG,
      zeroLine: true,
      notifications: true,
      icon: ElectricalConductivityIcon,
    },
  [`${FEATURE_TYPES.FEATURE_IRRADIATION}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_IRRADIATION,
    unit: "W/m2",
    stroke: "#ED0097",
    fill: "#ED0097",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: SolarRadiation,
  },
  [`${FEATURE_TYPES.FEATURE_PRESSURE}_${aggregations.AVG}`]: {
    name: FEATURE_TYPES.FEATURE_PRESSURE,
    unit: "hPa",
    stroke: "#0FC396",
    fill: "#0FC396",
    chartFeatureType: chartFeatureTypes.LINE,
    aggregation: aggregations.AVG,
    notifications: true,
    icon: AtmosphericPressure,
  },
  [`${FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION}_${aggregations.INCREMENT}`]: {
    name: FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION,
    unit: "mm",
    stroke: "#39BCDC",
    fill: "#39BCDC",
    chartFeatureType: chartFeatureTypes.BAR,
    aggregation: aggregations.INCREMENT,
  },
  [`${FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION}_${aggregations.SUM}`]: {
    name: FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION,
    unit: "mm",
    stroke: "#A7E7FC",
    strokeWidth: 0,
    tooltipStroke: "#39BCDC",
    fill: "#A7E7FC",
    fillOpacity: 0.5,
    chartFeatureType: chartFeatureTypes.AREA,
    aggregation: aggregations.SUM,
  },
};

export default class FeatureConfig {
  static getSupportedFeatureNames() {
    return uniq(
      Object.values(FEATURE_CONFIG).map((feature) =>
        FeatureConfig.getFeatureNameWithoutPrefix(feature.name),
      ),
    );
  }

  static getFeatureNameWithoutPrefix(featureName) {
    return featureName.replace(FEATURE_TYPE_PREFIX, "");
  }

  static getNotificationsFeatureNames() {
    return uniq(
      Object.values(FEATURE_CONFIG)
        .filter((feature) => feature.notifications)
        .map((feature) =>
          FeatureConfig.getFeatureNameWithoutPrefix(feature.name),
        ),
    );
  }

  static getFeatureConfig(type, aggregation = aggregations.AVG) {
    if (!FEATURE_CONFIG[`${type}_${aggregation}`]) {
      throw new Error(
        `Unknown feature type ${type} with aggregation: ${aggregation}`,
      );
    }

    return {
      ...FEATURE_CONFIG[`${type}_${aggregation}`],
      name: `${type}_${aggregation}`,
    };
  }

  static isFeatureSupported(sensor) {
    return Boolean(
      Object.values(FEATURE_CONFIG).find(
        (feature) =>
          feature.name.replace(FEATURE_TYPE_PREFIX, "") ===
          sensor.feature.toUpperCase(),
      ),
    );
  }
}
