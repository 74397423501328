import React, { FC, useMemo, useContext } from "react";

import { MenuItem } from "@mui/material";
import { Field, Formik, Form, FieldProps } from "formik";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTelematicsAdminSelectedFarms } from "../selectors/telematicsAdmin.selectors";

import { fetchAccountFarms } from "../selectors/telematicsAdmin.actions";

import {
  UpdateFarmsPayload,
  updateTelematicsAccountFarmsApi,
} from "../../../../shared/api/telematics/admin/telematicsAdmin.api";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfFormikDatePicker from "../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikSwitch from "../../../../shared/components/form/CfFormikSwitch/CfFormikSwitch";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../../types";

import useTelematicsAdminStyles from "./styles/telematicsAdmin.styles";

import { AdminState } from "../../../../reducers/admin.reducer.types";
import {
  AccountTo,
  Catalogue,
  EconomicSystem,
} from "../../../../shared/api/telematics/telematics.types";

interface EditFormValues {
  automaticActions: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  economicSystemDate?: Moment;
  externalId?: string;
  farmId: string;
  name: string;
}

const validate = (values: EditFormValues) => {
  const errors: Record<string, string | React.ReactNode> = {};
  if (!values?.externalId) {
    errors.externalId = (
      <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />
    );
  }
  if (values.economicSystem && !values.economicSystemDate) {
    errors.economicSystemDate = (
      <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />
    );
  }
  if (values.economicSystemDate && !values.economicSystem) {
    errors.economicSystem = (
      <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />
    );
  }
  if (values.economicSystemDate && !values.economicSystemDate.isValid()) {
    errors.economicSystemDate = (
      <FormattedMessage id="TelematicsAdmin.edit.dialog.invalidDate" />
    );
  }
  return errors;
};

// in global scope to avoid redeclaration (prevent losing focus on input)
const renderExternalIdField = (fieldProps: FieldProps) => (
  <CfFormikTextField {...fieldProps} useDefaultHelperText />
);

interface Props {
  farm: AccountTo;
  fetchAccountFarms: () => void;
  handleClose: () => void;
  opened: boolean;
  updateFarms: (payload: UpdateFarmsPayload) => void;
}

const EditDialog: FC<Props> = ({
  farm,
  fetchAccountFarms,
  handleClose,
  opened,
  updateFarms,
}) => {
  const classes = useTelematicsAdminStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(
    () => ({
      farmId: farm.farmId,
      name: farm.name,
      externalId: farm?.externalId ?? "",
      catalogue: farm?.catalogue ?? ("" as Catalogue),
      economicSystem: farm?.economicSystem ?? ("" as EconomicSystem),
      economicSystemDate: farm?.economicSystemDate
        ? moment(farm?.economicSystemDate)
        : undefined,
      automaticActions: farm.automaticActions,
    }),
    [farm],
  );

  const handleSubmit = ({
    automaticActions,
    catalogue,
    economicSystem,
    economicSystemDate,
    externalId,
    farmId,
  }: EditFormValues) => {
    if (!externalId) return;
    const payload = [
      {
        farmId,
        externalId,
        catalogue: catalogue || undefined,
        economicSystem: economicSystem || undefined,
        economicSystemDate: economicSystemDate
          ? getShortDateString(economicSystemDate)
          : undefined,
        enabled: farm.enabled,
        automaticActions,
      },
    ];

    (updateFarms as AsyncFn<UpdateFarmsPayload>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({
          message: <FormattedMessage id="TelematicsAdmin.dialog.error" />,
          isError: true,
        });
        return;
      }
      fetchAccountFarms();
      showSnackbar({
        message: (
          <FormattedMessage
            id="TelematicsAdmin.edit.dialog.success"
            values={{ count: 1 }}
          />
        ),
        isSuccess: true,
      });
    });
    handleClose();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ errors, resetForm, setValues, submitForm, values }) => {
        const { farmId, name } = farm;
        const closeForm = () => {
          resetForm();
          handleClose();
        };

        return (
          <CfDialog
            cancelText={<FormattedMessage id="common.cancel" />}
            maxWidth="lg"
            onAccept={submitForm}
            onCancel={closeForm}
            opened={opened}
            title={<FormattedMessage id="TelematicsAdmin.edit.dialog.title" />}
            acceptText={
              <FormattedMessage id="TelematicsAdmin.edit.dialog.acceptBtn" />
            }
          >
            <div className={classes.formContainer}>
              <Form>
                <div className={classes.formRow} key={farmId}>
                  <div className={classes.farmInfo}>
                    <p className={classes.farmName}>{name}</p>
                    <p className={classes.farmId}>{farmId}</p>
                  </div>
                  <Field
                    component={renderExternalIdField}
                    customClasses={{ root: classes.companyId }}
                    fullWidth={false}
                    id="externalId"
                    name="externalId"
                    label={
                      <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.companyId" />
                    }
                  />
                  <Field
                    customClasses={{ root: classes.catalogue }}
                    name="catalogue"
                    select
                    component={(fieldProps: FieldProps) => (
                      <CfFormikTextField {...fieldProps} useDefaultHelperText />
                    )}
                    label={
                      <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.catalogue" />
                    }
                  >
                    <MenuItem
                      classes={{ root: classes.menuItem }}
                      value={Catalogue.WINFAS}
                    >
                      <span>{Catalogue.WINFAS}</span>
                    </MenuItem>
                    <MenuItem classes={{ root: classes.menuItem }} value="">
                      <span>-</span>
                    </MenuItem>
                  </Field>
                  <Field
                    customClasses={{ root: classes.economicSystem }}
                    name="economicSystem"
                    select
                    component={(fieldProps: FieldProps) => (
                      <CfFormikTextField {...fieldProps} useDefaultHelperText />
                    )}
                    label={
                      <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.economicSystem" />
                    }
                    onChange={(e: React.BaseSyntheticEvent) => {
                      setValues(
                        {
                          ...values,
                          economicSystem: e.target.value,
                          economicSystemDate:
                            e.target.value === ""
                              ? undefined
                              : values.economicSystemDate,
                        },
                        true,
                      );
                    }}
                  >
                    <MenuItem
                      classes={{ root: classes.menuItem }}
                      value={EconomicSystem.TOS}
                    >
                      <span>{EconomicSystem.TOS}</span>
                    </MenuItem>
                    <MenuItem classes={{ root: classes.menuItem }} value="">
                      <span>-</span>
                    </MenuItem>
                  </Field>
                  <Field
                    // use key to force re-render when economicSystemDate is changed
                    className={classes.economicSystemDate}
                    component={CfFormikDatePicker}
                    error={!!errors.economicSystemDate}
                    helperText={errors.economicSystemDate}
                    key={values.economicSystem ?? 0}
                    name="economicSystemDate"
                    label={
                      <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.economicSystemDate" />
                    }
                  />
                  <Field
                    component={CfFormikSwitch}
                    name="automaticActions"
                    label={
                      <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.automaticActions" />
                    }
                  />
                </div>
              </Form>
            </div>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selectedFarms: getTelematicsAdminSelectedFarms(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) =>
  bindActionCreators(
    {
      updateFarms: updateTelematicsAccountFarmsApi,
      fetchAccountFarms,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
