import React from "react";

import { selectUnit } from "@formatjs/intl-utils";
import PropTypes from "prop-types";
import { FormattedRelativeTime } from "react-intl";

function CfFormattedRelativeTime(props) {
  const { dateTime } = props;
  const dateTimeObject = new Date(dateTime);

  if (isNaN(dateTimeObject)) {
    return <span>-</span>;
  }

  const { unit, value } = selectUnit(dateTimeObject);
  return <FormattedRelativeTime numeric="auto" unit={unit} value={value} />;
}

CfFormattedRelativeTime.propTypes = {
  dateTime: PropTypes.string.isRequired,
};

export default CfFormattedRelativeTime;
