import React from "react";

import { FormikProps } from "formik";
import moment from "moment";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { editBatchSubject } from "../../actions/telematicsAggregations.actions";

import { stringDateToMoment } from "../../../shared/misc/timeHelpers";
import { CfFormikErrors } from "../../../types";
import WinfasClient from "../../components/TelematicsAggregationDetailContent/formComponents/WinfasClient";
import { MESSAGES } from "../../components/TelematicsAggregationDetailContent/validators";

import { useCustomStyles } from "./styles/useCustomStyles";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { WinfasClientTo } from "../../../shared/api/telematics/telematics.types";

const useClient = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const customClasses = useCustomStyles();

  const selectedRides = useSelector(
    (state: TelematicsState) => state.ui.telematicsAggregations.selectedRides,
  );
  const dateFrom = useSelector(
    (state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom,
  );
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers =
      dateFromMoment.toISOString() ?? moment().startOf("day").toISOString();
  }

  const initialValues: { client?: WinfasClientTo } = {
    client: undefined,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.client) {
      errors.client = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      drive: selectedRides,
      subject: values.client?.idNumber ?? "-1",
    };
    return editBatchSubject(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <WinfasClient
      customClasses={customClasses}
      date={dateForClassifiers}
      disabled={formikProps.isSubmitting}
      fieldName="client"
      helperText={(formikProps.errors.client as string) ?? ""}
      placeholder={intl.formatMessage({
        id: "TelematicsAggregations.list.bulk.secondary.placeholder.client",
      })}
    />
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useClient;
