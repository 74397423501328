import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useFertilizerDetailStyles = makeStyles((theme: Theme) => ({
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headWrapper: {
    padding: 24,
    marginBottom: 20,
  },
  heading: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  headingFontSize: {
    fontSize: 24,
    textAlign: "center",
  },
  headingContentConfirming: {
    color: theme.palette.grey[400],
  },
  headingValidationError: {
    color: theme.palette.error.main,
  },
  textField: {
    width: 600,
  },
  wrapper: {
    padding: "0px 24px 24px 24px",
  },
  eAgriWrapper: {
    backgroundColor: "#C2D86C",
  },
  spinnerWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionHeading: {
    marginBottom: 5,
    fontSize: 20,
  },
  paper: {
    padding: 20,
    fontSize: 13,
  },
  numericTextField: {
    width: 75,
    marginRight: 20,
    marginBottom: 20,
  },
  numericTextFieldWider: {
    width: 140,
    marginRight: 30,
    marginBottom: 20,
  },
  fieldFormik: {
    marginBottom: 20,
    marginRight: 20,
  },
  nitrogenCategorySelector: {
    width: 320,
    marginBottom: 20,
    marginRight: 20,
  },
  registrationTypeSelector: {
    width: 220,
    marginBottom: 20,
    marginRight: 20,
  },
  datePickerField: {
    padding: "10px 5px",
  },
  radioGroupLabel: {
    fontSize: 13,
    marginBottom: 0,
  },
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
  },
}));
