import React from "react";

import { styled } from "@mui/material";

type Props = React.PropsWithChildren<
  Pick<React.ComponentProps<typeof Wrapper>, "sx">
>;

const CfChartTooltipValue = (props: Props) => <Wrapper {...props} />;

const Wrapper = styled("p")(({ theme }) => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 14,
  color: theme.palette.secondary.dark,
}));

export default CfChartTooltipValue;
