import React, { useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getItems,
  getIsFetchingDrives,
  getTotalCount,
} from "../../../../shared/api/telematics/drives/drives.selectors";
import {
  getTelematicsListOrder,
  getTelematicsListOrderBy,
  getTelematicsListPage,
  getTelematicsListRowsPerPage,
  selectIsAggregated,
} from "../../../selectors/telematicsList.selectors";

import { setFocusedRow } from "../../../../shared/api/telematics/drives/drives.actions";

import { NAMESPACE as namespace } from "../../../reducer/telematicsList.reducer";

import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../shared/misc/helper";
import { Thunk } from "../../../../types";
import DoubleLinedHeader from "../../../components/DoubleLinedHeader/DoubleLinedHeader";
import { getDriveBadgeProps } from "../../../helpers";

import RecordRow from "./RecordRow";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import { State } from "../../../../shared/api/telematics/telematics.types";

type ReduxProps = Omit<ConnectedProps<typeof connector>, "dispatch">;

const TelematicsTable = ({
  count,
  drives,
  isAggregated,
  isFetching,
  order,
  orderBy,
  page,
  rowsPerPage,
  setFocusedRow,
}: ReduxProps) => {
  const intl = useIntl();
  useEffect(
    () => () => {
      setFocusedRow(undefined);
    },
    [setFocusedRow],
  );

  const columns = {
    ...(drives && drives[0] && isAggregated ? durationColumn : timeColumn),
    ...commonColumns,
  };

  const mapDrive = (drive: (typeof drives)[number]) => {
    let operationTooltip = intl.formatMessage({
      id: "TelematicsList.unApprovedRide",
    });

    if (drive.state === State.DEFERRED) {
      operationTooltip = intl.formatMessage({
        id: "TelematicsList.postponedRide",
      });
    }

    if (drive.state !== State.NOT_APPROVED) {
      operationTooltip = intl.formatMessage({
        id: "TelematicsList.approvedRide",
      });
    }
    const badgeProps = getDriveBadgeProps(drive.type, intl);

    return {
      ...drive,
      operationTooltip,
      badgeProps,
    };
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={drives}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && drives && drives.length > 0 && (
        <TableBody>
          {drives.map(mapDrive).map((drive) => (
            <RecordRow
              drive={drive}
              isInit={!isFetching && count > 0}
              key={drive.id}
            />
          ))}
        </TableBody>
      )}
      {!isFetching && !drives.length && (
        <CfTableBodyEmpty colLength={Object.keys(commonColumns).length + 1} />
      )}

      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const commonColumns = {
  "driver.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.driverName"
      secondaryId="TelematicsList.chipId"
    />,
  ),
  operation: getColDesc(
    false,
    <FormattedMessage id="TelematicsList.operation" />,
  ),
  "productionOperation.name": getColDesc(
    true,
    <FormattedMessage id="TelematicsList.productionOperation" />,
  ),
  parcelName: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsList.parcelName"
      secondaryId="TelematicsList.crop"
    />,
  ),
  cultivated: getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.cultivatedArea"
      secondaryId="TelematicsList.cultivatedPlot"
    />,
  ),
  distance: getColDesc(
    true,
    <FormattedMessage id="TelematicsList.traversedDistance" />,
  ),
  "machine.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.machine"
      secondaryId="TelematicsList.machineLicencePlate"
    />,
  ),
  additionalEquipment: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsList.additionalEquipment"
      secondaryId="TelematicsList.equipmentChipId"
    />,
  ),
};

const timeColumn = {
  from: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="common.date"
      secondaryId="common.time"
    />,
  ),
};

const durationColumn = {
  from: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="Telematics.logbook.duration"
      secondaryId="common.date"
    />,
  ),
  ...commonColumns,
};

const mapStateToProps = (state: TelematicsState) => ({
  page: getTelematicsListPage(state),
  order: getTelematicsListOrder(state),
  orderBy: getTelematicsListOrderBy(state),
  rowsPerPage: getTelematicsListRowsPerPage(state),
  isFetching: getIsFetchingDrives(state),
  count: getTotalCount(state),
  drives: getItems(state),
  isAggregated: selectIsAggregated(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(TelematicsTable);
