// namespace reducer as Redux duck

import { AnyAction } from "redux";

import {
  CataloguesState,
  CATALOGUES_NAMESPACES,
} from "../../reducers/catalogues.reducer.types";

const initialState = "";

const SET_SELECTED_NAMESPACE = "CATALOGUES/SET_SELECTED_NAMESPACE";

export const setSelectedNamespace = (namespace: CATALOGUES_NAMESPACES) => ({
  type: SET_SELECTED_NAMESPACE,
  payload: namespace,
});

export const getSelectedNamespace = (state: CataloguesState) =>
  state.ui.selectedNamespace;

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SELECTED_NAMESPACE:
      return action.payload;
    default:
      return state;
  }
};
