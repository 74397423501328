import React from "react";

import { Button, Switch, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";

import { AgIntegrationStatus } from "../../../shared/api/sentinel/agiStatus/agiStatus.types";

type Props = {
  buttonOnClick: () => void;
  data: AgIntegrationStatus;
  switchOnClick: () => void;
};

const FleetConnectorTableRow = ({
  buttonOnClick,
  data,
  switchOnClick,
}: Props) => {
  const classes = useStyles();

  return (
    <TableRow key={1}>
      <CfTableCell name="status">
        <Switch
          checked={data.enabled ?? false}
          color="primary"
          data-test="integration-toggle"
          disabled={!data?.enabled}
          name="enabled"
          onClick={switchOnClick}
        />
      </CfTableCell>
      <CfTableCell name="service">
        <FormattedMessage id="Agi.service" />
      </CfTableCell>
      <CfTableCell name="user_account">{data.email ?? "-"}</CfTableCell>
      <CfTableCell classes={{ root: classes.lastCell }} name="description">
        <FormattedMessage id="Agi.description" />
        <Button
          className={classes.button}
          color="primary"
          onClick={buttonOnClick}
          type="submit"
          variant={data.enabled ? "text" : "contained"}
        >
          <FormattedMessage
            id={
              data.enabled
                ? "IntegrationSettings.setConnection"
                : "IntegrationSettings.connect"
            }
          />
        </Button>
      </CfTableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  tableHeaderContainer: {
    paddingBottom: 8,
    alignItems: "center",
  },
  lastCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
  },
  button: {
    fontWeight: "500",
    fontSize: 14,
    margin: "5px 0px",
  },
}));

export default FleetConnectorTableRow;
