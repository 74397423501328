/**
 * Created by brasko on 24.7.2015.
 */

/*@ngInject*/

export default function cfClassThBtns() {
  var directive = {
    restrict: "E",
    template: require("./cf-class-th-btns.html"),
    require: ["?^ngController"],
    scope: {
      config: "=",
    },
    link: function (scope, element, attrs, required) {
      scope.ctrl = required[0].clsCtrl;
      scope.create = scope.config.create;
      scope.delete = scope.config.delete;

      scope.delAll = 0;
      scope.$watch(
        function () {
          return scope.ctrl.delAll;
        },
        function (newVal, oldVal) {
          if (newVal !== oldVal) {
            scope.delAll = newVal;
          }
        },
        true,
      );
    },
  };

  return directive;
}
