import React, { Component } from "react";

import IconButton from "@mui/material/IconButton";
import { createTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import createCftheme from "../../../../theme";
import { getAppVariant } from "../../../variants/getAppVariant";

const muiTheme = createTheme(createCftheme(getAppVariant()));

const primaryColor = muiTheme.palette.primary.main;
const defaultColor = muiTheme.palette.text.primary;
const whiteColor = muiTheme.palette.common.white;

const styles = (theme) => ({
  root: {
    position: "relative",
    top: "-3px",
    width: "34px",
    height: "34px",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  background: {
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  tooltip: {
    fontSize: "12px",
    margin: 4,
  },
});

class ToolbarIconBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  handleOnMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    const { hover } = this.state;
    const {
      active,
      callback,
      classes,
      icon,
      tooltipTitle,
      withBackground,
      ...rest
    } = this.props;
    const Icon = icon;
    const inactiveClassName = withBackground ? classes.background : "";

    let htmlColor = defaultColor;
    if (hover && !active) {
      htmlColor = primaryColor;
    } else if (active || (hover && active)) {
      htmlColor = whiteColor;
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        placement="bottom"
        title={<FormattedMessage id={tooltipTitle} />}
      >
        <IconButton
          classes={{ root: classes.root }}
          className={active ? classes.active : inactiveClassName}
          disableRipple={true}
          onClick={callback}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          {...rest}
          size="large"
        >
          <Icon htmlColor={htmlColor} />
        </IconButton>
      </Tooltip>
    );
  }
}

ToolbarIconBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  withBackground: PropTypes.bool,
};

ToolbarIconBtn.defaultProps = {
  withBackground: false,
};

export default withStyles(styles)(ToolbarIconBtn);
