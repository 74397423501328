/**
 * Created by ondrejzvara on 24.3.17.
 */

import angular from "angular";

import hours from "./Hours.classifiers";
import action from "./Action.classifiers";

export default angular.module("app.classifiers", [hours, action]).name;
