import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const DrawIcon = (props) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
      <path d="M16 2C13.4 2 11.2 3.7 10.3 6H4.4C3.6 6 3 6.6 3 7.4V19.6C3 20.4 3.6 21 4.4 21H16.6C17.4 21 18 20.4 18 19.6V13.7C20.3 12.9 22 10.7 22 8C22 4.7 19.3 2 16 2ZM16 19H5V8H10C10 11.3 12.7 14 16 14V19ZM19 8.8H16.7V11H15.2V8.8H13V7.3H15.3V5H16.8V7.3H19V8.8Z" />
    </svg>
  </SvgIcon>
);

export default DrawIcon;
