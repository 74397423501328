import React, { Component, Fragment } from "react";

import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import NodeExportDialog from "../../containers/NodeLocationExportDialog/NodeLocationExportDialog";

class NodeLocationExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpened: false,
    };
  }

  handleExportClick = () => {
    this.setState({ isDialogOpened: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpened: false });
  };

  handleDialogConfirm = () => {
    this.handleDialogClose();
  };

  render() {
    return (
      <Fragment>
        <MenuItem aria-label="export-sensor" onClick={this.handleExportClick}>
          <FormattedMessage id="NodeLocationExport.exportData" />
        </MenuItem>
        <NodeExportDialog
          nodeLocation={this.props.nodeLocation}
          onAccept={this.handleDialogConfirm}
          onCancel={this.handleDialogClose}
          opened={this.state.isDialogOpened}
        />
      </Fragment>
    );
  }
}

NodeLocationExport.propTypes = {
  nodeLocation: PropTypes.object.isRequired,
};

export default NodeLocationExport;
