export enum COLUMN_NAMES {
  IS_FAVORITE = "isFavorite",
  IS_ORGANIC = "isOrganic",
  K2O = "k2o",
  NAME = "name",
  NITROGEN = "n",
  NITROGEN_CATEGORY = "nitrogenCategory",
  P2O5 = "p2o5",
  SOURCE = "source",
  VALID_TO = "validTo",
}
