import { RSAA } from "redux-api-middleware";

import * as types from "./farmUsers.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getUsersByFarm = (farmId) => ({
  [RSAA]: {
    endpoint: `admin/farms/${farmId}/users?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_USERS_BY_FARM,
      types.GET_USERS_BY_FARM_SUCCESS,
      types.GET_USERS_BY_FARM_ERROR,
    ],
  },
});
