import React, { FC, Fragment, useEffect, useState } from "react";

import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getCenters } from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";

import {
  getCentersApi,
  resetCentersApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";
import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import { Thunk } from "../../../../../types";
import { ParcelsService } from "../../services/Parcels.service";

import CenterAutosuggest from "./CenterAutosuggest";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  CenterTo,
  ParcelDetailTo,
  ParcelTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles(() => ({
  paper: {
    overflow: "hidden",
    height: 250,
    width: 250,
  },
  switcherButtonLabel: {
    fontSize: 13,
  },
  button: {
    marginLeft: -9,
  },
}));

interface ParcelCenterAssignProps {
  anchorEl?: null | Element;
  getCentersApi: (search: string) => void;
  handlePopoverClose: () => void;
  handlePopoverOpen: () => void;
  isOpen: boolean;
  onCenterChange: (centerId: string, parcelId?: string) => void;
  parcel?: ParcelDetailTo | ParcelTo;
  resetCentersApi: () => void;
  suggestions: CenterTo[];
  withPopoverEl?: boolean;
}

const ParcelCenterAssign: FC<ParcelCenterAssignProps> = ({
  anchorEl = null,
  getCentersApi,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  onCenterChange,
  parcel = null,
  resetCentersApi,
  suggestions,
  withPopoverEl = true,
}) => {
  const classes = useStyles();

  const [mergedSuggestions, setMergedSuggestions] = useState<CenterTo[]>([]);

  useEffect(() => {
    setMergedSuggestions(suggestions);
  }, [suggestions]);

  const onSuggestionSelect = (centerToAssign: CenterTo) => {
    if (parcel?.center?.id === centerToAssign.id) {
      return;
    }
    onCenterChange(centerToAssign.id, parcel?.id);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
    resetCentersApi();
    setMergedSuggestions([]);
  };

  const isHistorical = ParcelsService.isParcelHistorical(parcel);

  return withPopoverEl ? (
    <Fragment>
      <CfSwitcherButton
        classes={{ custom: classes.button }}
        disabled={isHistorical}
        onClick={handlePopoverOpen}
        transparent={true}
      >
        <span className={classes.switcherButtonLabel}>
          {parcel?.center?.name ?? (
            <FormattedMessage id="ParcelDetailOverview.noCenterOption" />
          )}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={onSuggestionClear}
        open={isOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: 250,
          vertical: 0,
        }}
      >
        <CenterAutosuggest
          getSuggestions={getCentersApi}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={mergedSuggestions}
        />
      </Popover>
    </Fragment>
  ) : (
    <CenterAutosuggest
      getSuggestions={getCentersApi}
      onSuggestionClear={onSuggestionClear}
      onSuggestionSelect={onSuggestionSelect}
      suggestions={mergedSuggestions}
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getCenters(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getCentersApi,
      resetCentersApi,
    },
    dispatch,
  );

export default withPopover(
  connect(mapStateToProps, mapDispatchToProps)(ParcelCenterAssign),
);
