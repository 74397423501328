import React, { Component } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfReduxFormSelect from "../../../../../shared/components/form/CfReduxFormSelect/CfReduxFormSelect";
import CfReduxFormTextField from "../../../../../shared/components/form/CfReduxFormTextField/CfReduxFormTextField";
import PestsControl from "../../containers/PestsControl/PestsControl";
import driftClassesSource from "../../misc/driftClass.json";
import DoseDosageFields from "../DoseDosageFields/DoseDosageFields";

const styles = () => ({
  menuItem: {
    fontSize: 16,
  },
  doseFormControl: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "5px 0px 25px 0px",
  },
  inputLabel: {
    fontWeight: "normal",
  },
  errorText: {
    position: "absolute",
    bottom: "-10px",
  },
});

class ChemistryDialog extends Component {
  onFormSubmit = () => {
    this.onSubmit();
  };

  onSubmit = this.props.handleSubmit((values) => {
    this.props.onAccept(values);
  });

  initializeForm = () => {
    this.props.initialize(this.props.chemistry);
  };

  render() {
    const { driftClasses } = driftClassesSource;
    const { classes } = this.props;
    const chemistry = this.props.chemistry;
    const dialogTitle =
      chemistry && chemistry.material ? chemistry.material.name : "";
    const chemistryMaterialId =
      chemistry && chemistry.material ? chemistry.material.id : "";

    let acceptText = <FormattedMessage id="common.close" />;
    if (this.props.adding) {
      acceptText = <FormattedMessage id="common.add" />;
    } else if (this.props.editing) {
      acceptText = <FormattedMessage id="common.edit" />;
    }

    return (
      <CfDialog
        acceptText={acceptText}
        cancelText={<FormattedMessage id="common.cancel" />}
        onClose={this.props.onClose}
        onEnter={() => this.initializeForm()}
        opened={this.props.opened}
        title={dialogTitle}
        onAccept={
          this.props.editing || this.props.adding
            ? this.onFormSubmit
            : this.props.onClose
        }
        onCancel={
          this.props.editing || this.props.adding
            ? this.props.onClose
            : undefined
        }
      >
        <form>
          <CfFormControl>
            <FieldArray
              chemistryMaterialId={chemistryMaterialId}
              component={PestsControl}
              editing={this.props.editing}
              name="pests"
              targetCrop={this.props.targetCrop}
            />
          </CfFormControl>
          <CfFormControl>
            <InputLabel className={classes.inputLabel}>
              <FormattedMessage id="ChemistryDialog.driftClass" />
            </InputLabel>
            <Field
              component={CfReduxFormSelect}
              disabled={!this.props.editing}
              name="driftClass"
            >
              {driftClasses.map((drift) => (
                <MenuItem
                  className={classes.menuItem}
                  key={drift.code}
                  value={drift.code}
                >
                  {drift.name}
                </MenuItem>
              ))}
            </Field>
          </CfFormControl>
          <DoseDosageFields
            change={this.props.change}
            editing={this.props.editing}
            material={this.props.chemistry}
            name="chemistry"
            parcelsArea={this.props.parcelsArea}
          />
          <CfFormControl>
            <Field
              component={CfReduxFormTextField}
              disabled={!this.props.editing}
              fullWidth
              label={<FormattedMessage id="common.note" />}
              multiline
              name="note"
              InputLabelProps={{
                style: styles.inputLabel,
              }}
            />
          </CfFormControl>
        </form>
      </CfDialog>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.pests || !values.pests.length) {
    errors.pests = {
      _error: <FormattedMessage id="ChemistryDialog.choosePest" />,
    };
  }
  return errors;
};

ChemistryDialog.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  classes: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  chemistry: PropTypes.object,
  adding: PropTypes.bool,
  handleSubmit: PropTypes.func,
  parcelsArea: PropTypes.number,
  editing: PropTypes.bool,
  targetCrop: PropTypes.object,
};

ChemistryDialog.defaultProps = {
  opened: false,
  classes: {},
  change: () => {},
  initialize: () => {},
  chemistry: null,
  adding: true,
  handleSubmit: () => {},
  parcelsArea: 0,
  editing: false,
  targetCrop: {},
};

export default connect()(
  reduxForm({
    form: "chemistry",
    validate,
  })(withStyles(styles)(injectIntl(ChemistryDialog))),
);
