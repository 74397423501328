import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const RainfallIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.53955,8.60541A6.004,6.004,0,0,0,9.59387,4.278q-.21359-.02078-.42957-.02081A4.55769,4.55769,0,0,0,4.69525,7.94989,4.91153,4.91153,0,0,0,7.34283,17h8.74286a4.9033,4.9033,0,0,0,3.45386-8.39459ZM16.08569,15H7.34283A2.91,2.91,0,0,1,6.66,9.26025a2.53382,2.53382,0,0,1,3.59485-2.74957,4.00181,4.00181,0,0,1,7.21838,3.0271A2.905,2.905,0,0,1,16.08569,15Z" />
    <rect
      height="1.49971"
      transform="translate(-14.08722 19.69813) rotate(-68.21245)"
      width="2.69258"
      x="6.15371"
      y="19.50015"
    />
    <rect
      height="1.49971"
      transform="translate(-11.57188 23.4124) rotate(-68.21245)"
      width="2.69258"
      x="10.15371"
      y="19.50015"
    />
    <rect
      height="1.49971"
      transform="translate(-9.05655 27.12667) rotate(-68.21245)"
      width="2.69258"
      x="14.15371"
      y="19.50015"
    />
  </SvgIcon>
);

export default RainfallIcon;
