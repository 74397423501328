import { getCenter as getExtentCenter } from "ol/extent";
import { transform } from "ol/proj";

import { getMap } from "../selectors/map.selectors";

import * as types from "./map.constants";
import * as sensorsStatus from "../../shared/constants/sensorsStatus.constants";

import SensorsMapService from "../services/SensorsMap.service";

export const storeMap = (map) => ({
  type: types.STORE_MAP,
  map,
});

export const storeHoveredFeatureId = (featureId) => ({
  type: types.STORE_HOVERED_FEATURE_ID,
  hoveredFeatureId: featureId,
});

export const storeSelectedFeatureId = (featureId) => ({
  type: types.STORE_SELECTED_FEATURE_ID,
  selectedFeatureId: featureId,
});

export const setEnlargedVariant = (enlarged) => ({
  type: types.SET_VARIANT_ENLARGED,
  enlarged,
});

export const setMapColumns = (mapColumns) => ({
  type: types.SET_MAP_COLUMNS,
  mapColumns,
});

export const onNodeListItemHover = (nodeId) => (dispatch) =>
  dispatch(storeHoveredFeatureId(nodeId));

export const onNodeListItemLeave = () => (dispatch) =>
  dispatch(storeHoveredFeatureId(null));

export const zoomToCoords =
  (coords, destinationZoomLevel) => (dispatch, getState) => {
    const state = getState();
    const map = getMap(state);
    const view = map.getView();
    const duration = 1000;

    view.animate({
      center: coords,
      zoom: destinationZoomLevel,
      duration,
    });
  };

export const zoomToNodeLocation = (nodeLocation) => (dispatch) => {
  if (nodeLocation.status !== sensorsStatus.ACTIVE || !nodeLocation.geometry) {
    return;
  }
  dispatch(
    zoomToCoords(
      transform(nodeLocation.geometry.coordinates, "EPSG:4326", "EPSG:3857"),
      18,
    ),
  );
};

export const zoomToFarm = (farmBbox) => (dispatch, getState) => {
  const state = getState();
  const map = getMap(state);
  if (map) {
    const view = map.getView();

    const farmExtent = SensorsMapService.getFarmExtent(farmBbox);

    const resolution = view.getResolutionForExtent(farmExtent);
    const zoom = view.getZoomForResolution(resolution);
    const center = getExtentCenter(farmExtent);

    dispatch(zoomToCoords(center, zoom));
  }
};

export const setCoordsSelection = (isSelecting, selectionCoords) => ({
  type: types.SET_COORDS_SELECTION,
  isSelecting,
  selectionCoords,
});
