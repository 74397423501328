/**
 * Created by ondrejzvara on 29.3.17.
 */

import angular from "angular";
import porPublic from "./por/por.public.module";

import tpl from "./pors.public.html";
import PorsPublicController from "./pors.public.controller";

export default angular
  .module("app.components.farm.classifiers.public.pors", [porPublic])
  .controller("PorsPublicController", PorsPublicController)
  .config(config).name;

/* @ngInject */
function config($stateProvider) {
  $stateProvider.state("farm.active.classifiers.public.pors", {
    url: "/pors",
    views: {
      porsPublic: {
        controller: "PorsPublicController as PorsCtrl",
        template: tpl,
      },
    },
  });
}
