import React, { useState, useEffect, FC } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Tab, Tabs, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getApiError,
  getAreaDetail,
} from "../../../shared/api/irrigation/areas/areas.selectors";
import {
  getDevicesTotalCount,
  getInactiveDevicesTotalCount,
} from "../../../shared/api/irrigation/devices/devices.selectors";

import { fetchInactiveDevices } from "../../actions/createOrEditArea.actions";
import { fetchDevices } from "../../actions/devices.actions";

import { NAMESPACE } from "../../reducer/areaDevices.reducer";

import {
  resetApiError,
  updateAreaName,
} from "../../../shared/api/irrigation/areas/areas.api";
import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import EditableText from "../../../shared/components/common/EditableText/EditableText";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { Thunk } from "../../../types";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";
import IrrigationPlan from "../IrrigationPlan/IrrigationPlan";

import IrrigationAreaDetailList from "./IrrigationAreaDetailList";

import { IrrigationState } from "../../../reducers/irrigation.reducer.types";
import { AreaDetail } from "../../../shared/api/irrigation/areas/area.types";

interface Props {
  apiError: boolean;
  areaDetail: AreaDetail;
  devicesCount: number;
  fetchDevices: (namespace: string, areaId: string) => void;
  fetchInactiveDevices: () => void;
  history: History;
  inactiveDevicesCount: number;
  langId: string;
  match: matchType<{ areaId: string }>;
  resetApiError: () => void;
  tabDevices: boolean;
  tabPlan: boolean;
  updateAreaName: (areadId: string, name: string) => void;
}

type dialogTypes = "plan" | "devices";

const TABS = {
  plan: {
    id: 0,
    label: <FormattedMessage id="Irrigation.area.tabs.plan" />,
    url: "./plan",
    testId: "irrigation-areas",
  },
  devices: {
    id: 1,
    label: <FormattedMessage id="Irrigation.area.tabs.devices" />,
    url: "./devices",
    testId: "irrigation-unit",
  },
};

const FAB_TOOLTIP = {
  plan: <FormattedMessage id="Irrigation.area.fab.plan" />,
  devices: <FormattedMessage id="Irrigation.area.fab.devices" />,
};

const WARNING_DIALOG = {
  plan: {
    title: <FormattedMessage id="Irrigation.area.warningDialog.plan.title" />,
    acceptText: (
      <FormattedMessage id="Irrigation.area.warningDialog.acceptText" />
    ),
    message: (
      <FormattedMessage id="Irrigation.area.warningDialog.plan.message" />
    ),
  },
  devices: {
    title: (
      <FormattedMessage id="Irrigation.area.warningDialog.devices.title" />
    ),
    acceptText: (
      <FormattedMessage id="Irrigation.area.warningDialog.acceptText" />
    ),
    message: (
      <FormattedMessage id="Irrigation.area.warningDialog.devices.message" />
    ),
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapperStyle: {
    height: "100%",
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  fabContainer: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  fab: {
    color: "#fff",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
  },
}));

const IrrigationAreaDetail: FC<Props> = ({
  apiError,
  areaDetail,
  devicesCount,
  fetchDevices,
  fetchInactiveDevices,
  history,
  inactiveDevicesCount,
  match,
  resetApiError,
  tabDevices,
  tabPlan,
  updateAreaName,
  ...props
}) => {
  useEffect(() => {
    // fetch devices for this area (updates deviceCount)
    fetchDevices(NAMESPACE, match.params.areaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // dependency: updates inactiveDevicesCount when some devices are unassigned from area
    fetchInactiveDevices();
  }, [fetchInactiveDevices, devicesCount]);

  const classes = useStyles();

  const [showDialogFromFab, setShowDialogFromFab] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningDialogType, setWarningDialogType] =
    useState<dialogTypes>("plan");

  const handleGoToAreasList = () => history.push("../../areas");

  const handleFabClick = () => {
    if (tabDevices) {
      if (inactiveDevicesCount > 0) {
        history.push("./add");
      } else {
        setWarningDialogType("devices");
        setShowWarningDialog(true);
      }
    }
    if (tabPlan) {
      if (devicesCount > 0) {
        setShowDialogFromFab(true);
      } else {
        setWarningDialogType("plan");
        setShowWarningDialog(true);
      }
    }
  };

  const handleCloseModal = () => setShowDialogFromFab(false);
  const handleCloseWarningDialog = () => setShowWarningDialog(false);

  return (
    <div className={classes.wrapperStyle}>
      <div className={classes.headerWrapper}>
        <PageHeader
          backButton={
            <CfBackButton
              onClick={handleGoToAreasList}
              translId="IrrigationDetail.goBack"
            />
          }
          heading={
            <PageHeading
              customClass={classes.heading}
              value={
                <div>
                  <EditableText
                    initialValue={areaDetail.name}
                    labelTestId="irrigation-device-name"
                    onConfirmEditing={(value: string) =>
                      updateAreaName(areaDetail.id, value)
                    }
                  />
                </div>
              }
            />
          }
        />
        <Box className={classes.fabContainer}>
          <Tooltip title={tabPlan ? FAB_TOOLTIP.plan : FAB_TOOLTIP.devices}>
            <Fab
              aria-label="add"
              className={classes.fab}
              color="secondary"
              onClick={handleFabClick}
              size="small"
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      </div>
      <Box className={classes.tabs}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabPlan ? TABS.plan.id : TABS.devices.id}
        >
          {Object.keys(TABS).map((key: keyof typeof TABS) => (
            <Tab
              data-test={TABS[key].testId}
              key={key}
              label={TABS[key].label}
              onClick={() => history.push(TABS[key].url)}
            />
          ))}
        </Tabs>
      </Box>
      <ErrorSnackbar handleClose={resetApiError} open={apiError} />
      <CfDialog
        acceptText={WARNING_DIALOG[warningDialogType].acceptText}
        onAccept={handleCloseWarningDialog}
        opened={showWarningDialog}
        title={WARNING_DIALOG[warningDialogType].title}
      >
        {WARNING_DIALOG[warningDialogType].message}
      </CfDialog>

      {tabPlan && (
        <IrrigationPlan
          {...props}
          handleCloseModal={handleCloseModal}
          match={match}
          showDialogFromFab={showDialogFromFab}
        />
      )}

      {tabDevices && <IrrigationAreaDetailList match={match} />}
    </div>
  );
};

const mapStateToProps = (state: IrrigationState) => ({
  areaDetail: getAreaDetail(state),
  apiError: getApiError(state),
  devicesCount: getDevicesTotalCount(state),
  inactiveDevicesCount: getInactiveDevicesTotalCount(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationState>) =>
  bindActionCreators(
    {
      updateAreaName,
      resetApiError,
      fetchInactiveDevices,
      fetchDevices,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IrrigationAreaDetail);
