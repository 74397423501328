import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfChartTooltipDaytime from "../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime";
import CfChartTooltipLabelWrapper from "../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper";
import { CfChartTooltipWrapper } from "../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper";
import SensorChartTooltipValueItem from "../SensorChartTooltipValueItem/SensorChartTooltipValueItem";

const style = () => ({
  tooltipValueWrapper: {
    padding: "2px 10px 2px 10px",
  },
});

class SensorChartTooltip extends Component {
  getTuples = () => {
    const {
      features: [feature],
      payload: [payload],
    } = this.props;
    const { name, stroke } = feature;
    const value = payload.payload[name] || payload.value;
    let tuples = payload.payload.tuples || [];

    if (tuples.length) {
      tuples = tuples.map((item) => {
        let color;
        switch (item[name]) {
          case value[0]:
            color = "#EBA607";
            break;
          case value[1]:
            color = "#941300";
            break;
          default:
            color = stroke;
        }

        return {
          value: item[name],
          level: item.level,
          stroke: color,
        };
      });
    } else {
      tuples.push({
        value,
        level: payload.level,
        stroke,
      });
    }

    const allNulls = tuples.every((item) => item.value === null);
    if (allNulls) {
      tuples = [{ value: null }];
      return tuples;
    }
    return tuples.filter((item) => item.value !== null);
  };

  render() {
    const {
      active,
      classes,
      features: [feature],
      granularity,
      payload,
    } = this.props;
    if (active && payload && payload[0]) {
      const tuples = this.getTuples();
      const { name, ...restFeatureProps } = feature;
      const dateTime = payload[0].payload?.dateTime || "";

      return (
        <CfChartTooltipWrapper>
          <CfChartTooltipLabelWrapper>
            <CfChartTooltipDaytime granularity={granularity} label={dateTime} />
          </CfChartTooltipLabelWrapper>

          {tuples.map((item) => (
            <SensorChartTooltipValueItem
              feature={restFeatureProps}
              key={`${item.value}_${item.level}`}
              payload={item}
              classes={
                tuples.length > 1
                  ? {
                      tooltipValueWrapper: classes.tooltipValueWrapper,
                    }
                  : {}
              }
            />
          ))}
        </CfChartTooltipWrapper>
      );
    }
    return null;
  }
}

SensorChartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  granularity: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
};

SensorChartTooltip.defaultProps = {
  payload: [],
  active: false,
};

export default withStyles(style)(SensorChartTooltip);
