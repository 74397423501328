import React, { Component } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import LicensePdf from "../LicensePdf/LicensePdf";

const styleSheet = (theme) => ({
  licenseTextSpan: {
    position: "relative",
    fontWeight: "normal",
    float: "left",
    fontSize: "14px",
  },

  licenseAnchor: {
    color: theme.palette.primary.main,
    "&:visited, &:hover, &:focus": {
      color: theme.palette.primary.main,
    },
    fontSize: "14px",
  },
  error: {
    fontSize: "12px",
    color: theme.palette.error.main,
    position: "relative",
    top: -15,
  },
  licenceAgreement: {
    position: "relative",
  },
});

class LicenseAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onLicenseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.openModal();
    return false;
  };

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  render() {
    const classes = this.props.classes;

    return (
      <FormGroup className={classes.licenceAgreement} id="license-agreement">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={this.props.onLicenseCheck}
              value="checkedA"
            />
          }
          label={
            <span className={classes.licenseTextSpan}>
              <FormattedMessage id="LicenseAgreement.iAgree" />{" "}
              <a
                className={classes.licenseAnchor}
                href="#"
                onClick={this.onLicenseClick}
              >
                <FormattedMessage id="LicenseAgreement.licenseAgreement" />
              </a>
            </span>
          }
        />
        <FormHelperText className={classes.error}>
          {this.props.error}
        </FormHelperText>

        <LicensePdf handleClose={this.closeModal} open={this.state.open} />
      </FormGroup>
    );
  }
}

LicenseAgreement.propTypes = {
  classes: PropTypes.object.isRequired,
  onLicenseCheck: PropTypes.func,
  error: PropTypes.any,
};

LicenseAgreement.defaultProps = {
  onLicenseCheck: () => {},
  error: "",
};

export default withStyles(styleSheet)(LicenseAgreement);
