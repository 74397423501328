import { createSelector } from "reselect";

import {
  getFarms,
  getCountries,
} from "../../../../shared/api/agroevidence/farms/farms.selectors";

export const getFarmsSuggestions = createSelector(getFarms, (farms) => farms);

export const getCountriesSuggestions = createSelector(
  getCountries,
  (countries) => countries,
);

export const isDialogOpen = (state) => state.ui.manager.dialogOpen;
export const isActionButtonEnabled = (state) =>
  state.ui.manager.actionButtonEnabled;
export const isActionButtonHidden = (state) =>
  state.ui.manager.actionButtonHidden;
export const isSensorsTabActive = (state) => state.ui.manager.sensorsTabActive;
export const isIrrigationTabActive = (state) =>
  state.ui.manager.irrigationTabActive;
