import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";

export const getCropUsage = (state: ActionsState) =>
  state.api.agroevidence.eagri.cropUsage;
export const getIsFetchingCropUsage = (state: ActionsState) =>
  state.api.agroevidence.eagri.isFetchingCropUsage;

export const getHarvestProducts = (state: ActionsState) =>
  state.api.agroevidence.eagri.harvestProducts;
export const getIsFetchingHarvestProducts = (state: ActionsState) =>
  state.api.agroevidence.eagri.isFetchingHarvestProducts;

export const getNitrogenCategory = (state: CataloguesState) =>
  state.api.agroevidence.eagri.nitrogenCategory;
export const getIsFetchingNitrogenCategory = (state: CataloguesState) =>
  state.api.agroevidence.eagri.isFetchingNitrogenCategory;

export const getFertilizerKind = (state: CataloguesState) =>
  state.api.agroevidence.eagri.fertilizerKind;
export const getIsFetchingFertilizerKind = (state: CataloguesState) =>
  state.api.agroevidence.eagri.isFetchingFertilizerKind;

export const getFertilizerRegistrationType = (state: CataloguesState) =>
  state.api.agroevidence.eagri.fertilizerRegistrationType;
export const getIsFetchingFertilizerRegistrationType = (
  state: CataloguesState,
) => state.api.agroevidence.eagri.isFetchingFertilizerRegistrationType;
