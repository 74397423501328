import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./locations.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getLocations = (params) => ({
  [RSAA]: {
    endpoint: `locations?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.GET_LOCATIONS,
      types.GET_LOCATIONS_SUCCESS,
      types.GET_LOCATIONS_ERROR,
    ],
  },
});

export const resetLocations = () => ({
  type: types.RESET_LOCATIONS,
});

export const getLocationsPoints = (transformedFilter) => ({
  [RSAA]: {
    endpoint: `locations/points?${queryString.stringify(transformedFilter)}&`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.GET_LOCATIONS_POINTS,
      types.GET_LOCATIONS_POINTS_SUCCESS,
      types.GET_LOCATIONS_POINTS_ERROR,
    ],
  },
});

export const getLocation = (locationId) => ({
  [RSAA]: {
    endpoint: `locations/${locationId}?`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.GET_LOCATION,
      types.GET_LOCATION_SUCCESS,
      types.GET_LOCATION_ERROR,
    ],
  },
});

export const resetLocation = () => ({
  type: types.RESET_LOCATION,
});

export const patchLocation = (id, name) => {
  const dto = {
    name,
  };

  return {
    [RSAA]: {
      endpoint: `locations/${id}?`,
      method: methods.PATCH,
      module: modules.IOT,
      body: JSON.stringify(dto),
      types: [
        types.PATCH_LOCATION,
        types.PATCH_LOCATION_SUCCESS,
        types.PATCH_LOCATION_ERROR,
      ],
    },
  };
};

export const assignGroups = (groups, id) => ({
  [RSAA]: {
    endpoint: `locations/${id}/groups?`,
    method: methods.PUT,
    module: modules.IOT,
    body: JSON.stringify(groups),
    types: [
      types.PUT_LOCATION,
      types.PUT_LOCATION_SUCCESS,
      types.PUT_LOCATION_ERROR,
    ],
  },
});

export const removeLocation = (id) => ({
  type: types.REMOVE_LOCATION,
  id,
});

export const exportLocation = (locationId, from, to) => ({
  [RSAA]: {
    endpoint: `locations/${locationId}/export?duration=${from.toISOString()}/${to.toISOString()}&`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.EXPORT_LOCATION,
      {
        type: types.EXPORT_LOCATION_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.EXPORT_LOCATION_ERROR,
    ],
  },
});

export const getLocationPestPredictions = (locationId) => ({
  [RSAA]: {
    endpoint: `locations/${locationId}/pest-predictions?`,
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.GET_LOCATION_PREDICTIONS,
      types.GET_LOCATION_PREDICTIONS_SUCCESS,
      types.GET_LOCATION_PREDICTIONS_ERROR,
    ],
  },
});

export const getPredictionHistoryData = (
  computedFeatureId,
  locationId,
  from,
  to,
  dataType,
  occurrence,
) => {
  const params = {
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
    ...(occurrence ? { occurrence } : {}),
  };

  const allData = !!window.allData;

  return {
    [RSAA]: {
      endpoint: `locations/${locationId}/pest-predictions/${computedFeatureId}/data?${queryString.stringify(
        params,
      )}&all-data=${allData}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        {
          type: types.GET_PREDICTION_DATA,
          payload: () => ({
            dataType,
            computedFeatureId,
          }),
        },
        {
          type: types.GET_PREDICTION_DATA_SUCCESS,
          payload: (action, state, res) =>
            res.json().then((data) => ({
              items: data,
              dataType,
              computedFeatureId,
            })),
        },
        types.GET_PREDICTION_DATA_ERROR,
      ],
    },
  };
};

export const getEvapotranspirationData = (
  locationId,
  dateFrom,
  dateTo,
  gran,
  elevation,
) => {
  const params = {
    duration: `${dateFrom}/${dateTo}`,
    granularity: gran,
    elevation,
  };

  return {
    [RSAA]: {
      endpoint: `locations/${locationId}/evapotranspiration?${queryString.stringify(
        params,
      )}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        types.GET_EVAPOTRANSPIRATION_DATA_REQUEST,
        types.GET_EVAPOTRANSPIRATION_DATA_SUCCESS,
        types.GET_EVAPOTRANSPIRATION_DATA_ERROR,
      ],
    },
  };
};

export const resetEvapotranspirationData = () => ({
  type: types.RESET_EVAPOTRANSPIRATION_DATA,
});
