import React, { Component } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../common/CfDialog/CfDialog";

const styles = (theme) => ({
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
  },
  button: {
    minWidth: 48,
    paddingLeft: 12,
    paddingRight: 12,
  },
  buttonsActive: {
    marginRight: 8,
  },
});

class TableActionButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  onClose = () => {
    this.setState({
      opened: false,
    });
  };

  onSubmit = () => {
    this.onClose();
    this.props.onDeleteActions();
  };

  onDeleteBtnClick = () => {
    this.setState({
      opened: true,
    });
  };

  render() {
    const { classes, content, selected, title } = this.props;

    return (
      <div className={selected.length ? classes.buttonsActive : null}>
        <Button
          disabled={selected.length < 1}
          id="table-delete-items-btn"
          onClick={this.onDeleteBtnClick}
          className={`${classes.button} ${
            selected.length ? classes.activeButton : ""
          }`}
        >
          <DeleteIcon />
          {selected.length > 0 && `\u00A0(${selected.length})`}
        </Button>

        <CfDialog
          acceptText={<FormattedMessage id="common.yes" />}
          cancelText={<FormattedMessage id="common.no" />}
          onAccept={this.onSubmit}
          onCancel={this.onClose}
          onClose={this.onClose}
          opened={this.state.opened}
          title={title}
        >
          <div>{content}</div>
        </CfDialog>
      </div>
    );
  }
}

TableActionButtons.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array.isRequired,
  onDeleteActions: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.element,
};

TableActionButtons.defaultProps = {
  classes: {},
  title: "",
};

export default withStyles(styles)(TableActionButtons);
