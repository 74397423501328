import {
  getGroupsOrder,
  getGroupsOrderBy,
} from "../selectors/groups.selectors";

import { getGroupsApi } from "../../shared/api/iot/groups/groups.api";

export const fetchGroups = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    order: getGroupsOrder(state),
    orderBy: getGroupsOrderBy(state),
  };
  dispatch(getGroupsApi(params));
};
