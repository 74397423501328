/**
 * Created by ondrejzvara on 9.3.16.
 */

/* @ngInject */

export default function cfClassifierSelector() {
  const directive = {
    restrict: "E",
    template: require("./cf-classifier-selector.html"),
    require: ["ngModel"],
    scope: {},
    bindToController: {
      model: "=ngModel",
      farmId: "=?",
      options: "=?",
      optionFormat: "=?",
      createOptionTemplateUrl: "=?",
      optionTemplateUrl: "=?",
      onStateChange: "=", // EMPTY, TYPING, SELECTED, CREATE
      placeholder: "@",
      disabled: "=?",
      focus: "=?",
      errId: "@?",
      name: "@?",
      validators: "@?",
    },
    controller: cfClassifierSelectorController,
    controllerAs: "selectorCtrl",
  };

  return directive;
}

/* @ngInject */
function cfClassifierSelectorController() {
  const vm = this;
  vm.state = "EMPTY";
  vm.optionFormat = vm.optionFormat
    ? vm.optionFormat
    : (item) => (item ? item.name : null);
  vm.optionTemplateUrl = vm.optionTemplateUrl
    ? vm.optionTemplateUrl
    : "cf-classifier-selector-default-option-template.html";
  vm.onStateChange = vm.onStateChange || (() => {});
  vm.errId = vm.errId ? vm.errId : null;
  vm.name = vm.name ? vm.name : null;
  vm.validators = vm.validators ? vm.validators : "none";
  vm.onCreateNew = onCreateNew;
  vm.onSelect = onSelect;
  vm.onChange = onChange;
  vm.onEsc = onEsc;

  function onCreateNew(model) {
    if (vm.onStateChange) {
      vm.model = model;
      vm.onStateChange("CREATE", model);
    }
  }

  function onSelect(model) {
    if (vm.createOptionTemplateUrl && !model.id && !model.legislativeCode) {
      onCreateNew(model);
    } else if (vm.onStateChange) {
      vm.model = model;
      vm.onStateChange("SELECTED", model);
    }
  }

  function onChange() {
    if (vm.onStateChange) {
      vm.onStateChange(vm.model ? "TYPING" : "EMPTY", vm.model);
    }
  }

  function onEsc() {
    vm.model = null;
    vm.onStateChange("EMPTY", vm.model);
  }
}
