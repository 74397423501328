import React from "react";

import { Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, useField } from "formik";

import { COLOR_FONT } from "../../../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    color: COLOR_FONT.main,
    margin: 0,
    gap: 8,
    alignItems: "flex-end",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  checkbox: {
    padding: 0,
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
}));

type Props = {
  label: React.ReactElement | string;
  name: string;
};

const FormCheckbox: React.FC<Props> = (props) => {
  const { label, ...settings } = props;
  const fieldProps = useField(props);
  const field = fieldProps[0];
  const form = fieldProps[2];

  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        classes={{ root: classes.formControlLabel }}
        label={label}
        labelPlacement="end"
        control={
          <Checkbox
            {...field}
            checked={field.value}
            classes={{ root: classes.checkbox }}
            onChange={(e) => {
              form.setValue(e.target.checked);
            }}
            {...settings}
          />
        }
      />
      <div className={classes.error}>
        <ErrorMessage name={props.name} />
      </div>
    </>
  );
};

export default FormCheckbox;
