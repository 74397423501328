interface Params {
  coordinates: number[];
}

export function useGetElevation({ key }: { key: string }) {
  const getElevation = async (params: Params) => {
    const { coordinates } = params;
    const urlParams = {
      points: [coordinates[1], coordinates[0]],
      key,
    };

    try {
      const response = await fetch(
        // https://github.com/sindresorhus/got/issues/1559
        `http://dev.virtualearth.net/REST/v1/Elevation/List?${new URLSearchParams(
          // @ts-ignore
          urlParams,
        )}`,
      );
      const responseJson = await response.json();
      // For now get elevation for just one node
      if (!responseJson?.resourceSets[0]?.resources[0]?.elevations[0]) {
        throw new Error("Unexpected format error");
      }

      const elevation =
        responseJson?.resourceSets[0]?.resources[0]?.elevations[0];
      return elevation;
    } catch (e) {
      throw new Error("Cannot fetch elevation");
    }
  };

  return { getElevation };
}
