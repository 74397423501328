import { RSAAAction } from "redux-api-middleware";

import {
  getTelematicsListAdvancedFilter,
  getTelematicsListTextFilter,
} from "../selectors/telematicsList.selectors";
import { selectDateRange } from "../selectors/telematicsTabs.selectors";

import * as types from "./telematicsList.constants";

import {
  exportDrivesApi,
  getCropsApi,
  getDriveDetailApi,
  getDriveSubjectApi,
  getDriversApi,
  getEquipmentsApi,
  getMachinesApi,
  getOperationsApi,
  getProductionOperationsApi,
} from "../../shared/api/telematics/drives/drives.api";
import { getShortDateString } from "../../shared/misc/timeHelpers";
import FileService from "../../shared/services/File.service";

import { TelematicsState } from "../../reducers/telematics.reducer.types";
import { ExportPayload } from "../../shared/api/api.types";
import { ExportDrivesApiParams } from "../../shared/api/telematics/drives/drives.types";
import { State } from "../../shared/api/telematics/telematics.types";

export const fetchDrivesSaga = () => ({
  type: types.SAGA_FETCH_DRIVES,
});

export const changeDriveStateSaga = (state: State) => ({
  type: types.SAGA_CHANGE_DRIVE_STATE,
  state,
});

export const fetchDriveDetail =
  (id: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getDriveDetailApi(id));
  };

export const fetchOperations =
  () => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getOperationsApi());
  };

export const fetchProductionOperations =
  (dateFrom: string, dateTo: string, handwork?: boolean) =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(dateFrom),
      dateTo: getShortDateString(dateTo),
      handwork,
    };
    dispatch(getProductionOperationsApi(params));
  };

export const fetchDrivers =
  (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(dateFrom),
      dateTo: getShortDateString(dateTo),
      "sort-col": sortCol,
      "sort-dir": sortDir,
    };
    dispatch(getDriversApi(params));
  };

export const fetchMachines =
  (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(dateFrom),
      dateTo: getShortDateString(dateTo),
      "sort-col": sortCol,
      "sort-dir": sortDir,
    };

    dispatch(getMachinesApi(params));
  };

export const fetchEquipments =
  (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(dateFrom),
      dateTo: getShortDateString(dateTo),
      "sort-col": sortCol,
      "sort-dir": sortDir,
    };
    dispatch(getEquipmentsApi(params));
  };

export const fetchCrops =
  (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(dateFrom),
      dateTo: getShortDateString(dateTo),
      "sort-col": sortCol,
      "sort-dir": sortDir,
    };
    dispatch(getCropsApi(params));
  };

export const fetchDriveSubject =
  () => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getDriveSubjectApi());
  };

export const exportDrives =
  () =>
  (
    dispatch: (action: RSAAAction) => Promise<ExportPayload>,
    getState: () => TelematicsState,
  ) => {
    const state = getState();

    const params: ExportDrivesApiParams = {
      ...selectDateRange(state),
      state: getTelematicsListAdvancedFilter(state).state,
      affiliation: getTelematicsListAdvancedFilter(state).parcelAffiliation,
      operation: getTelematicsListAdvancedFilter(state).operation,
      productionOperation: getTelematicsListAdvancedFilter(
        state,
      ).productionOperation?.map((pOper) => pOper.code),
      parcel: getTelematicsListAdvancedFilter(state).parcel?.map(
        (parcel) => parcel.id,
      ),
      driver: getTelematicsListAdvancedFilter(state).driver?.map(
        (driver) => driver.code,
      ),
      equipment: getTelematicsListAdvancedFilter(state).equipment?.map(
        (equipment) => equipment.code,
      ),
      parcelSubjectId: getTelematicsListAdvancedFilter(
        state,
      ).parcelSubject?.map((parcelSubject) => parcelSubject.id),
      gpsUnit: getTelematicsListAdvancedFilter(state).machine?.map(
        (machine) => machine.gpsUnit,
      ),
      search: getTelematicsListTextFilter(state),
    };

    dispatch(exportDrivesApi(params)).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };

export const aggregatedSet = (payload: boolean) => ({
  type: types.SET_AGGREGATED,
  payload,
});
