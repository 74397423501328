import React, { useState } from "react";

import { List, ListItem, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IrrigationValve from "./IrrigationValve";

const useStyles = makeStyles(() => ({
  deviceContainer: {
    display: "flex",
    alignItems: "flex-start",
    textAlign: "center",
    padding: "0.3125rem 0rem",
  },
  deviceName: {
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    padding: "0.3125rem 0.3125rem 0rem 0.3125rem",
  },
  numberValves: {
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    paddingTop: "0.3125rem",
  },
  list: {
    padding: "0rem 0.9375rem",
  },
}));

const IrrigationDevice = ({
  device,
  idDevice,
  onChangeDurations,
  onCheckedDurations,
}) => {
  const classes = useStyles();
  const [showDeviceSwitch, setShowDeviceSwitch] = useState(device.checked);

  const handleValvesVisibility = () => {
    // if client will hide device all durationSeconds on its ventils will set to 0
    if (!showDeviceSwitch === false) {
      device.valves.forEach((valve, id) => onChangeDurations(idDevice, id, 0));
    }
    onCheckedDurations(idDevice, !showDeviceSwitch);
    setShowDeviceSwitch(!showDeviceSwitch);
  };

  const handleChangeDurations = (idVal, durationSeconds) => {
    onChangeDurations(idDevice, idVal, durationSeconds);
  };

  return (
    <ListItem className={classes.deviceContainer} key={device.id}>
      <Switch
        checked={showDeviceSwitch}
        color="primary"
        id="programValve"
        onChange={handleValvesVisibility}
        size="small"
      />
      <span className={classes.deviceName}>{device.name}</span>
      {showDeviceSwitch ? (
        <List className={classes.list}>
          {device.valves.map((valve, id) => (
            <IrrigationValve
              handleChangeDurations={handleChangeDurations}
              idValve={id}
              key={valve.valveId}
              valve={valve}
            />
          ))}
        </List>
      ) : (
        <span className={classes.numberValves}>
          <FormattedMessage
            id="Irrigation.planCalendarModal.valves"
            values={{ count: device.valves.length }}
          />
        </span>
      )}
    </ListItem>
  );
};

IrrigationDevice.propTypes = {
  device: PropTypes.object.isRequired,
  idDevice: PropTypes.number.isRequired,
  onChangeDurations: PropTypes.func.isRequired,
  onCheckedDurations: PropTypes.func.isRequired,
};

export default IrrigationDevice;
