import { AnyAction } from "redux";

import * as types from "./seeds.constants";

const initialState = {
  items: [],
  isFetching: false,
  totalCount: 0,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_SEEDS_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_SEEDS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_SEEDS_ERROR:
        return {
          ...state,
          isFetching: false,
          totalCount: 0,
        };

      case types.RESET_SEEDS:
        return {
          ...state,
          items: [],
          totalCount: 0,
        };

      default:
        return state;
    }
  };
