import trim from "lodash/trim";
import { AnyAction } from "redux";

import * as types from "./signup.constants";

import { setEagriAuth as setEagriAuthApi } from "../../../shared/api/agroevidence/eagriAuth/eagriAuth.api";
import {
  createEmptyFarmApi,
  createEagriFarmApi,
  validateFarmFileApi,
  createFarmByFileApi,
} from "../../../shared/api/agroevidence/farms/farms.api";
import {
  getCountries as getCountriesApi,
  resetCountries as resetCountriesApi,
} from "../../../shared/api/core/countries/countries.api";
import { registerAccount } from "../../../shared/api/gateway/accounts/accounts.api";
import { handleEagriError } from "../signup.helpers";

import { CountryFrom } from "../../../reducers/signup.reducer.types";

export const changeLanguage = (langId: string) => ({
  type: types.LANG_CHANGE,
  langId,
});

export const setFetch = (isFetching: boolean) => ({
  type: types.SET_FETCH,
  isFetching,
});

export const setFarmName = (farmName: string) => ({
  type: types.SET_FARM_NAME,
  farmName,
});

export const setShpValidation = (value: number) => ({
  type: types.SET_SHP_VALIDATION,
  farmShpValidationResult: value,
});

export const getCountries =
  (search?: string) => (dispatch: (action: unknown) => void) =>
    dispatch(getCountriesApi({ search }));

export const resetCountries = () => (dispatch: (action: AnyAction) => void) => {
  dispatch(resetCountriesApi());
};

export const setCountry = (country: CountryFrom) => ({
  type: types.SET_COUNTRY,
  country,
});

type EagriAuthRes = {
  error: boolean;
  payload: unknown;
};

export const setEagriAuth =
  (username: string, wsKey: string, szrid: string) =>
  (dispatch: (action: unknown) => Promise<EagriAuthRes>) => {
    dispatch(setFetch(true));

    const eagri = {
      login: username,
      secret: wsKey,
      szrid,
    };

    return dispatch(setEagriAuthApi(eagri))
      .then((response) => {
        if (response.error) {
          const { payload } = response;
          throw handleEagriError(payload) || payload;
        }
      })
      .finally(() => dispatch(setFetch(false)));
  };

export const validateFarmShapefile =
  (country: string, file: File) =>
  (dispatch: (action: unknown) => Promise<unknown>) => {
    dispatch(setShpValidation(0));
    if (file) {
      const formData = new FormData();
      formData.append("lpisFile", file);

      dispatch(setFetch(true));
      dispatch(validateFarmFileApi(country, formData)).then(
        (res: { error: number }) => {
          dispatch(setFetch(false));
          let result = 1;
          if (res.error) {
            result = -1;
          }
          dispatch(setShpValidation(result));
        },
      );
    }
  };

export const importFarmShapefile =
  (
    farmname: string,
    country: string,
    file: File,
    refreshLoginCallback: () => void,
    redirectCallback: () => void,
  ) =>
  (dispatch: (action: unknown) => Promise<unknown>) => {
    const formData = new FormData();
    formData.append("lpisFile", file);

    dispatch(setFetch(true));
    dispatch(createFarmByFileApi(country, farmname, formData))
      .then(() => refreshLoginCallback())
      .finally(() => {
        dispatch(setFetch(false));
        redirectCallback();
      });
  };

export const registerAccountAndLogin =
  (
    email: string,
    password: string,
    loginCallback: (credentials: {
      password: string;
      username: string;
    }) => Promise<unknown>,
    redirectCallback: () => void,
  ) =>
  (dispatch: (action: unknown) => Promise<unknown>) => {
    dispatch(setFetch(true));
    const credentials = { email: trim(email), password: trim(password) };
    return dispatch(registerAccount(credentials))
      .then((res: Record<string, unknown>) => {
        if (res.error) {
          throw res.payload;
        }
        const authCredentials = {
          username: credentials.email,
          password: credentials.password,
        };
        return loginCallback(authCredentials).then(() => {
          setTimeout(() => redirectCallback(), 500);
        });
      })
      .finally(() => {
        dispatch(setFetch(false));
      });
  };

export const createEmptyFarmAndRedirect =
  (
    farmName: string,
    countryCode: string,
    refreshLoginCallback: () => Promise<unknown>,
    redirectCallback: () => void,
  ) =>
  (dispatch: (action: unknown) => Promise<unknown>) => {
    dispatch(setFetch(true));
    return dispatch(createEmptyFarmApi(farmName, countryCode))
      .then((res: Record<string, unknown>) => {
        if (res.error) {
          throw res.payload;
        }
        return refreshLoginCallback().then(() => {
          redirectCallback();
        });
      })
      .finally(() => {
        dispatch(setFetch(false));
      });
  };

export const createEagriFarmAndRedirect =
  (farmName: string, successCallback: () => void, errorCallback: () => void) =>
  (dispatch: (action: unknown) => Promise<unknown>) => {
    dispatch(setFetch(true));
    return dispatch(createEagriFarmApi(farmName))
      .then((res: Record<string, unknown>) => {
        if (res.error) {
          errorCallback();
        }
      })
      .finally(() => {
        dispatch(setFetch(false));
        successCallback();
      });
  };
