import React, { Component } from "react";

import { getDisplayName } from "./hocHelpers";

/**
 * @deprecated
 * Use `useToggle` hook instead
 */
const withOpen = (WrappedComponent) => {
  class WithOpen extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
      };
    }

    handleOpen = () => {
      this.setState({
        isOpen: true,
      });
    };

    handleClose = () => {
      this.setState({
        isOpen: false,
      });
    };

    render() {
      const { isOpen } = this.state;
      return (
        <WrappedComponent
          isOpen={isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          {...this.props}
        />
      );
    }
  }

  WithOpen.displayName = `WithOpen(${getDisplayName(WrappedComponent)})`;

  return WithOpen;
};

export default withOpen;
