import angular from "angular";

import TelematicsController from "./telematics.controller";

import { PositionMachineTo } from "../shared/api/telematics/telematics.types";

type StateProvider = {
  state: (arg0: string, arg1: Record<string, unknown>) => StateProvider;
};

type StateParams = {
  dateFrom: string;
  dateTo: string;
  machine: PositionMachineTo;
};

const telematicsComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
    dateFrom: "<",
    dateTo: "<",
    machine: "<",
  },
  controller: TelematicsController,
};

export default angular
  .module("app.telematics", [])
  .config(config)
  .component("telematicsComponent", telematicsComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider: StateProvider) {
  $stateProvider
    .state("farm.active.telematics", {
      // params has to be passed to the parent "abstract" component, otherwise they are not resolved
      url: "/telematics",
      abstract: true,
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
      params: {
        dateFrom: null,
        dateTo: null,
        machine: null,
      },
      resolve: {
        dateFrom: [
          "$stateParams",
          function ($stateParams: StateParams) {
            return $stateParams.dateFrom;
          },
        ],
        dateTo: [
          "$stateParams",
          function ($stateParams: StateParams) {
            return $stateParams.dateTo;
          },
        ],
        machine: [
          "$stateParams",
          function ($stateParams: StateParams) {
            return $stateParams?.machine;
          },
        ],
      },
    })
    .state("farm.active.telematics.machines", {
      url: "/machines",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.drivers", {
      url: "/drivers",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.logbook", {
      url: "/logbook",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.handwork", {
      url: "/handwork",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.handwork.new", {
      url: "/new",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.logbook.driveDetail", {
      url: "/:driveId",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.drivers.driveDetail", {
      url: "/:driveId",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.machines.driveDetail", {
      url: "/:driveId",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    })
    .state("farm.active.telematics.drivers.new", {
      url: "/new",
      views: {
        farm: {
          component: "telematicsComponent",
        },
      },
    });
}
