import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  setFetch,
  changeLanguage,
  setEagriAuth,
  registerAccountAndLogin,
  createEmptyFarmAndRedirect,
  createEagriFarmAndRedirect,
  validateFarmShapefile,
  importFarmShapefile,
} from "../../actions/signup.actions";

import { checkEmailExists } from "../../../../shared/api/gateway/accounts/accounts.api";
import EntryContainer from "../../../../shared/containers/EntryContainer/EntryContainer";
import { Thunk } from "../../../../types";
import EagriImportNotification from "../../components/EagriImportNotification/EAgriImportNotification";
import FarmImport from "../../components/FarmImport/FarmImport";
import NewAccount from "../../components/NewAccount/NewAccount";
import { SignupToLogin } from "../../components/SignupToLogin/SignupToLogin";

import { SignupState } from "../../../../reducers/signup.reducer.types";

interface Props extends RouteComponentProps {
  changeLanguage: (langId: string) => void;
  checkEmailExists: () => void;
  createEagriFarmAndRedirect: (
    farmName: string,
    ngRefreshLogin: () => void,
    goToImportProgressMessagePage: () => void,
  ) => void;
  createEmptyFarmAndRedirect: (
    farmName: string,
    countryCode: string,
    ngRefreshLogin: () => Promise<unknown>,
    ngRedirectToFarm: () => void,
  ) => void;
  farmShpValidationResult: number;
  history: History;
  importFarmShapefile: (
    farmname: string,
    country: string,
    file: File,
    ngRefreshLogin: () => void,
    ngRedirectToFarm: () => void,
  ) => void;
  isFetching: boolean;
  langId: string;
  ngChangeLanguage: (langId: string) => void;
  ngLogin: (credentials: {
    password: string;
    username: string;
  }) => Promise<unknown>;
  ngLogoutNoRedirect: () => void;
  ngRedirectToFarm: () => void;
  ngRedirectToLogin: () => void;
  ngRefreshLogin: () => Promise<unknown>;
  registerAccountAndLogin: (
    email: string,
    password: string,
    ngLogin: (credentials: {
      password: string;
      username: string;
    }) => Promise<unknown>,
    goToFarmImport: () => void,
  ) => void;
  setEagriAuth: (username: string, wsKey: string, szrid: string) => void;
  validateFarmShapefile: (country: string, file: File) => void;
}

class Signup extends Component<Props> {
  componentDidMount = () => {
    this.props.changeLanguage(this.props.langId as string);
  };

  onLangChange = (langId: string) => {
    this.props.changeLanguage(langId);
    this.props.ngChangeLanguage(langId);
  };

  onCreateAccount = (email: string, password: string) => {
    const { history, ngLogin } = this.props;
    const goToFarmImport = () => history.push("/signup/import");
    return this.props.registerAccountAndLogin(
      email,
      password,
      ngLogin,
      goToFarmImport,
    );
  };

  onImportShapefile = (farmname: string, country: string, file: File) => {
    const { ngRedirectToFarm, ngRefreshLogin } = this.props;
    return this.props.importFarmShapefile(
      farmname,
      country,
      file,
      ngRefreshLogin,
      ngRedirectToFarm,
    );
  };

  validateFarmShapefile = (country: string, file: File) => {
    this.props.validateFarmShapefile(country, file);
  };

  createEagriFarm = (farmName: string) => {
    const { history } = this.props;
    const goToSuccessPage = () => history.push("/signup/import-in-progress");
    const goErrorPage = () => history.push("/signup/import-failed");
    return this.props.createEagriFarmAndRedirect(
      farmName,
      goToSuccessPage,
      goErrorPage,
    );
  };

  createEmptyFarm = (countryCode: string, farmName: string) => {
    const { ngRedirectToFarm, ngRefreshLogin } = this.props;
    return this.props.createEmptyFarmAndRedirect(
      farmName,
      countryCode,
      ngRefreshLogin,
      ngRedirectToFarm,
    );
  };

  render() {
    const {
      farmShpValidationResult,
      isFetching,
      langId,
      ngLogoutNoRedirect,
      ngRedirectToLogin,
    } = this.props;

    return (
      <EntryContainer isFetching={isFetching} onLangChange={this.onLangChange}>
        <Switch>
          <Route
            exact
            path="/signup"
            render={() => (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <NewAccount
                    checkEmailExists={this.props.checkEmailExists}
                    onCreateAccount={this.onCreateAccount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SignupToLogin redirect={ngRedirectToLogin} />
                </Grid>
              </Grid>
            )}
          />
          <Route
            exact
            path="/signup/import"
            render={() => (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={2}
              >
                <FarmImport
                  farmShpValidationResult={farmShpValidationResult}
                  langId={langId}
                  onCreateEagriFarm={this.createEagriFarm}
                  onEmptyFarmCreate={this.createEmptyFarm}
                  onImportShapefile={this.onImportShapefile}
                  onSetEagriAuthData={this.props.setEagriAuth}
                  onValidateShapefile={this.validateFarmShapefile}
                />
              </Grid>
            )}
          />
          <Route
            exact
            path="/signup/import-in-progress"
            render={() => (
              <EagriImportNotification
                handleLogout={ngLogoutNoRedirect}
                buttonCopy={
                  <FormattedMessage id="FarmImport.notification.button" />
                }
                description={
                  <FormattedMessage
                    id="FarmImport.notification.description"
                    values={{
                      str: (chunks: string) => (
                        <span style={{ fontWeight: "bold" }}>{chunks}</span>
                      ),
                    }}
                  />
                }
                heading={
                  <FormattedMessage id="FarmImport.notification.heading" />
                }
              />
            )}
          />
          <Route
            exact
            path="/signup/import-failed"
            render={() => (
              <EagriImportNotification
                handleLogout={ngLogoutNoRedirect}
                type="error"
                buttonCopy={
                  <FormattedMessage id="FarmImport.notification.error.button" />
                }
                description={
                  <FormattedMessage id="FarmImport.notification.error.description" />
                }
                heading={
                  <FormattedMessage id="FarmImport.notification.error.heading" />
                }
              />
            )}
          />
        </Switch>
      </EntryContainer>
    );
  }
}

const mapStateToProps = (state: SignupState) => ({
  isFetching: state.ui.signup.isFetching,
  farmShpValidationResult: state.ui.signup.farmShpValidationResult,
});

const mapDispatchToProps = (dispatch: Thunk<SignupState>) =>
  bindActionCreators(
    {
      setFetch,
      changeLanguage,
      setEagriAuth,
      checkEmailExists,
      registerAccountAndLogin,
      createEmptyFarmAndRedirect,
      createEagriFarmAndRedirect,
      validateFarmShapefile,
      importFarmShapefile,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
