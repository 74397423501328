import { AnyAction } from "redux";

import * as types from "./precision.constants";

import { PrecisionParcels } from "./precision.types";

const initialState: PrecisionParcels = {
  error: undefined,
  isFetchingItem: false,
  isFetchingItems: false,
  item: undefined,
  items: [],
  focusedRow: undefined,
  isInit: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_PRECISION_PARCELS_REQUEST:
        return {
          ...state,
          isFetchingItems: true,
          error: {},
        };
      case types.GET_PRECISION_PARCELS_SUCCESS:
        return {
          ...state,
          isFetchingItems: false,
          items: action.payload,
        };
      case types.GET_PRECISION_PARCELS_ERROR:
        return {
          ...state,
          isFetchingItems: false,
          error: action.payload,
        };
      case types.RESET_PRECISION_PARCELS:
        return {
          ...state,
          items: [],
        };
      case types.GET_PRECISION_PARCEL_REQUEST:
        return {
          ...state,
          isFetchingItem: true,
          error: {},
        };
      case types.GET_PRECISION_PARCEL_SUCCESS:
        return {
          ...state,
          isFetchingItem: false,
          item: action.payload,
        };
      case types.GET_PRECISION_PARCEL_ERROR:
        return {
          ...state,
          isFetchingItem: false,
          error: action.payload,
        };
      case types.RESET_PRECISION_PARCEL:
        return {
          ...state,
          item: undefined,
        };
      case types.GET_PRECISION_PAGE_IS_INIT:
        return {
          ...state,
          isInit: action.payload,
        };
      case types.SET_FOCUSED_ROW: {
        return {
          ...state,
          focusedRow: action.payload,
        };
      }
      default:
        return state;
    }
  };
