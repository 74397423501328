import React, { FC, useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSeasonsSowingPlan } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";
import { getTool } from "../../selectors/editor.selectors";

import { setSelectedSeasonSowingPlan } from "../../actions/sowingPlan/sowingPlan.actions";

import CfSwitcher from "../../../../shared/components/common/CfSwitcher/CfSwitcher";
import { Thunk } from "../../../../types";
import EditorToolbar from "../EditorToolbar/EditorToolbar";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import { SowingPlanSeasonTo } from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#FFFFFF",
    height: "28px",
  },
  cfSwitcherWrapper: {
    justifyContent: "flex-start",
    marginBottom: "5px",
  },
  cfSwitcherButtonPanel: {
    minHeight: "unset",
  },
});

interface PreviewToolbarProps {
  countryCode: string;
  isCurrentStateSowingPlanSelected: boolean;
  langId: string;
  seasons: SowingPlanSeasonTo[];
  setSelectedSeasonSowingPlan: (
    selectedSeasonId: string,
    selectedSeasonYears: string,
  ) => void;
  tool: string;
}

const PreviewToolbar: FC<PreviewToolbarProps> = ({
  countryCode,
  isCurrentStateSowingPlanSelected,
  langId,
  seasons,
  setSelectedSeasonSowingPlan,
  tool,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const seasonsArray = useMemo(
    () => [
      {
        id: "0",
        season: intl.formatMessage({
          id: "PreviewToolbar.sowingSeason.current",
        }),
        years: "0",
      },
      ...seasons.map((season) => ({
        id: season.id,
        season: `${intl.formatMessage({
          id: "PreviewToolbar.sowingSeason.prefix",
        })} ${season.startYear}/${season.endYear.toString().slice(-2)}`,
        years: `${season.startYear}/${season.endYear.toString().slice(-2)}`,
      })),
    ],
    [intl, seasons],
  );

  const [selectedSeason, setSelectedSeason] = useState<{
    id: string;
    season: string;
    years: string;
  }>(seasonsArray[0]);

  const isDisabledSeasonSelector = !!tool;

  useEffect(() => {
    setSelectedSeasonSowingPlan(selectedSeason.id, selectedSeason.years);
  }, [selectedSeason, setSelectedSeasonSowingPlan]);

  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item sm="auto" xs={12}>
        <CfSwitcher
          disabled={isDisabledSeasonSelector}
          getItemId={(item) => item.id}
          getItemValue={(item) => item.season}
          items={seasonsArray}
          onMenuItemClick={setSelectedSeason}
          selectedItemId={selectedSeason.id}
          classes={{
            wrapper: classes.cfSwitcherWrapper,
            button: classes.button,
            buttonPanel: classes.cfSwitcherButtonPanel,
          }}
        />
      </Grid>
      {isCurrentStateSowingPlanSelected && (
        <Grid item sm="auto" xs={12}>
          <EditorToolbar countryCode={countryCode} langId={langId} />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: MainMapState) => ({
  seasons: getSeasonsSowingPlan(state),
  tool: getTool(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      setSelectedSeasonSowingPlan,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreviewToolbar);
