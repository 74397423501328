import React, { FC, useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getMainMapTelematicsDateFrom,
  getMainMapTelematicsDateTo,
  getMainMapTelematicsMachineFilter,
  getMainMapTelematicsDriverFilter,
} from "../../selectors/mainMapTelematics.selectors";

import {
  setDate,
  setDriverFilter,
  setMachineFilter,
  setMachineSelectorFilter,
  resetFilters,
} from "../../actions/mainMapTelematics/mainMapTelematics.actions";

import { DUPLICATED_TAB_KEY } from "../../constants/localStorage.constants";

import { resetMachinesGroups } from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.api";
import DateRangeSelector from "../../../../shared/components/common/DateRangeSelector/DateRangeSelector";
import CfSingleFilter from "../../../../shared/containers/CfSingleFilter/CfSingleFilter";
import LocalStorage from "../../../../shared/services/LocalStorage.service";
import { Thunk } from "../../../../types";
import MainMapTelematicsDriverSelector from "../MainMapTelematicsDriverSelector/MainMapTelematicsDriverSelector";
import MainMapTelematicsMachineSelector from "../MainMapTelematicsMachineSelector/MainMapTelematicsMachineSelector";

import { MainMapState } from "../../../../reducers/map.reducer.types";
import {
  MachineTo,
  DriverTo,
} from "../../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles({
  toolbarElements: {
    display: "flex",
    gap: "0.5rem",
    marginBottom: "5px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#424242",
    color: "#FFFFFF",
    padding: "2px 10px",
    borderRadius: "28px",
    "&:hover": {
      backgroundColor: "#212121",
    },
    "&:active": {
      backgroundColor: "#212121",
    },
  },
});

interface TelematicsToolbarProps {
  dateFrom: string;
  dateTo: string;
  driverFilter: DriverTo[];
  initDateFrom: string;
  initDateTo: string;
  initDriverFilter: DriverTo[];
  initMachineFilter: MachineTo[];
  langId: string;
  machineFilter: MachineTo[];
  resetFilters: () => void;
  resetMachinesGroups(): void;
  setDate: (dateFrom: string, dateTo: string) => void;
  setDriverFilter: (driverFilter: DriverTo[]) => void;
  setMachineFilter: (machineFilter: MachineTo[]) => void;
  setMachineSelectorFilter: (machineSelectorFilter: string) => void;
}

const TelematicsToolbar: FC<TelematicsToolbarProps> = ({
  dateFrom,
  dateTo,
  driverFilter,
  initDateFrom,
  initDateTo,
  initDriverFilter,
  initMachineFilter,
  langId,
  machineFilter,
  resetFilters,
  resetMachinesGroups,
  setDate,
  setDriverFilter,
  setMachineFilter,
  setMachineSelectorFilter,
}) => {
  const classes = useStyles();

  const [machineFilterState, setMachineFilterState] =
    useState<MachineTo[]>(machineFilter);
  const [driverFilterState, setDriverFilterState] =
    useState<DriverTo[]>(driverFilter);
  const isInitialLoad = useRef<boolean>();

  useEffect(() => {
    isInitialLoad.current = true;
    if (initDateFrom && initDateTo) setDate(initDateFrom, initDateTo);
    if (initDriverFilter) setDriverFilter(initDriverFilter);
    if (initMachineFilter) {
      setMachineFilter(initMachineFilter);
      setMachineFilterState(initMachineFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }
    setMachineFilter(machineFilter);
    setMachineFilterState(machineFilter);
  }, [machineFilter, setMachineFilter, setMachineFilterState]);

  useEffect(() => {
    const loadLocalStorageData = () => {
      const ls = LocalStorage.loadFromLocalStorage(DUPLICATED_TAB_KEY);
      if (ls?.duplicatedTab?.filters) {
        const { dateFrom, dateTo, driver, machine } = ls.duplicatedTab.filters;
        if (dateFrom && dateTo) {
          setDate(dateFrom, dateTo);
        }
        if (driver) {
          setDriverFilter(Array.isArray(driver) ? driver : [driver]);
        }
        if (machine) {
          setMachineFilter(Array.isArray(machine) ? machine : [machine]);
        }
        LocalStorage.removeFromLocalStorage(DUPLICATED_TAB_KEY);
      }
    };
    loadLocalStorageData();
  }, [setDate, setDriverFilter, setMachineFilter]);

  const resetMainMapMachinesDriversFilter = () => {
    setDriverFilter([]);
    setMachineFilter([]);
    setMachineFilterState([]);
    setMachineSelectorFilter("");
    resetMachinesGroups();
    resetFilters();
  };

  const onChangeMachineFilterState = (
    machines: MachineTo[],
    checked: boolean,
  ) => {
    const prevMachineFilterState = [...machineFilterState];
    if (checked) {
      const newMachineFilterState = prevMachineFilterState.concat(
        machines.filter((item) => prevMachineFilterState.indexOf(item) < 0),
      );
      setMachineFilterState(newMachineFilterState);
      setMachineFilter(newMachineFilterState);
    } else {
      const newMachineFilterState = prevMachineFilterState.filter(
        (item) =>
          machines.map((machine) => machine.gpsUnit).indexOf(item.gpsUnit) < 0,
      );
      setMachineFilterState(newMachineFilterState);
      setMachineFilter(newMachineFilterState);
    }
  };

  const isFilterSelected = !!(machineFilter.length || driverFilter.length);

  return (
    <div className={classes.toolbarElements}>
      <DateRangeSelector
        dateFrom={dateFrom}
        dateTo={dateTo}
        langId={langId}
        lightStyle
        setDate={setDate}
      />
      <CfSingleFilter
        filter="-machines"
        filterItems={machineFilter}
        filterItemsState={machineFilterState}
        formattedMessageId="MainMapTelematics.filter.machineWithCount"
        mainPage
        setFilterItems={setMachineFilter}
        setFilterItemsState={setMachineFilterState}
        withButton={false}
      >
        <MainMapTelematicsMachineSelector
          langId={langId}
          onChangeMachineFilter={onChangeMachineFilterState}
          selectedValues={machineFilterState}
        />
      </CfSingleFilter>
      <CfSingleFilter
        filter="-drivers"
        filterItems={driverFilter}
        filterItemsState={driverFilterState}
        formattedMessageId="MainMapTelematics.filter.driverWithCount"
        mainPage
        setFilterItems={setDriverFilter}
        setFilterItemsState={setDriverFilterState}
      >
        <div className={classes.container}>
          <Grid container>
            <Grid className={classes.select} item xs={12}>
              <MainMapTelematicsDriverSelector
                defaultValues={driverFilter}
                onChange={setDriverFilterState}
                label={
                  <FormattedMessage id="MainMapTelematics.filter.driver" />
                }
              />
            </Grid>
          </Grid>
        </div>
      </CfSingleFilter>
      {isFilterSelected && (
        <Button
          className={classes.button}
          onClick={resetMainMapMachinesDriversFilter}
          size="small"
          variant="contained"
        >
          <FormattedMessage id="CfFilter.cancelFilter" />
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: MainMapState) => ({
  dateFrom: getMainMapTelematicsDateFrom(state),
  dateTo: getMainMapTelematicsDateTo(state),
  machineFilter: getMainMapTelematicsMachineFilter(state),
  driverFilter: getMainMapTelematicsDriverFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      setDate,
      setMachineFilter,
      setDriverFilter,
      setMachineSelectorFilter,
      resetMachinesGroups,
      resetFilters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsToolbar);
